import React from 'react';
import PropTypes from 'prop-types';

import { VoteTypes } from 'common';
import AnswerButton from '../answer-button/AnswerButton';

/**
 * @param {Object} question object.
 * @param {number} answering value.
 * @param {function} answerQuestion func to save
 * @param {number} answer set value to answer
 * @param {number} clickedAnswer clicked answer
 * @param {bool} disabled is disabled
 * @param {bool} hideAnswer hide answer
 * @returns {number} answer to save.
 * @constructor
 */
function LikeDislikeButtons({
  question,
  answering,
  answerQuestion,
  answer,
  clickedAnswer,
  disabled,
  hideAnswer,
  ...props
}) {
  return (
    <AnswerButton
      isLoading={answering === question.user_vote.id && clickedAnswer === answer}
      type={answer}
      active={!hideAnswer && question.user_vote.vote === answer}
      onClick={() => {
        answerQuestion(
          {
            type_id: question.type_id,
            user_vote_id: question.user_vote.id,
            vote: question.user_vote.vote === answer ? VoteTypes.undefined : answer,
          },
          answer
        );
      }}
      disabled={disabled}
      {...props}
    />
  );
}

LikeDislikeButtons.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  answering: PropTypes.number,
  answer: PropTypes.number,
  clickedAnswer: PropTypes.number,
  disabled: PropTypes.bool,
};

export default LikeDislikeButtons;
