import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './Spinner.scss';

/**
 * @returns {JSX.Element}
 */
const Spinner = ({ size, className, blue }) => {
  return (
    <img
      src={blue ? require('assets/img/blue-progress.svg') : require('assets/img/progress.svg')}
      className={classNames('spinner', size, className)}
      alt="spinner"
    />
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  className: PropTypes.string,
  blue: PropTypes.bool,
};

Spinner.defaultProps = {
  size: 'large',
};

export default withStyles(styles)(Spinner);
