import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getSentimentTracker,
  saveSentimentTracker,
} from 'modules/analyst/actions/sentiment-tracker';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import SentimentTracker from './SentimentTracker';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst }) => {
  return {
    sentimentTracker: analyst.sentimentTracker,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSentimentTracker, saveSentimentTracker, showLoader, hideLoader },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SentimentTracker);
