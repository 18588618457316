import * as WalkThroughService from 'modules/analyst/services/walk-through';

export const GET_WALK_THROUGH = 'GET_WALK_THROUGH';
export const SAVE_WALK_THROUGH = 'SAVE_WALK_THROUGH';

/**
 * @description Gets Walk Through.
 * @returns {Function}
 */
export const getWalkThrough = () => async (dispatch) => {
  const response = await WalkThroughService.getWalkThrough();

  dispatch({
    type: GET_WALK_THROUGH,
    payload: response,
  });

  return response;
};

/**
 *  @param {Object} data Walk Through object
 * @description Save Walk Through.
 * @returns {Function}
 */
export const saveWalkThrough = (data) => async (dispatch) => {
  const response = await WalkThroughService.saveWalkThrough(data);

  dispatch({
    type: SAVE_WALK_THROUGH,
    payload: response,
  });

  return response;
};
