import request from 'modules/shared/util/request';
import { Methods } from 'modules/shared/util/http';

const defaultBase = 'press';

/**
 * @description Get the list of available blog posts.
 * @param {Object} param Pagination param
 * @param {string} base baseUrl
 * @returns {Promise}
 */
export const getPresses = async (
  {
    nopaginate = true,
    search = '',
    nofilter = false,
    section,
    per_page,
    page,
    status,
    order = '',
    desc = true,
  } = {},
  base = defaultBase
) => {
  const orderDirection = desc ? 'desc' : 'asc';
  let resource = base;
  resource += `?search=${search}`;
  resource += order ? `&order=${order}:${orderDirection}` : '';
  resource += nopaginate ? '&nopaginate' : '';
  resource += nofilter ? '&nofilter' : '';
  resource += status ? `&status=${status}` : '';
  resource += section ? `&section=${section}` : '';
  resource += per_page ? `&per_page=${per_page}` : '';
  resource += page ? `&page=${page}` : '';
  return await request({ resource });
};

/**
 * @param {number} id Press Post ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const deletePress = async (id, base = defaultBase) => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.DELETE,
  });
};

/**
 * @param {number} id Press ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const getPress = async (id, base = defaultBase) => {
  return await request({
    resource: `${base}/${id}`,
  });
};

/**
 * @param {Object} data press object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const savePress = async (data, base = defaultBase) => {
  return await request({
    resource: `${base}`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Press ID
 * @param {Object} data Company object
 * @param {string} base baseUrl
 * @returns {Promise}
 */
export const modifyPress = async (id, data, base = defaultBase) => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id Press ID
 * @param {Object} data press object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const saveFeaturedImage = async (id, data, base = defaultBase) => {
  return await request({
    resource: `${base}/${id}/featured_image`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Post ID
 * @param {Object} data post object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const saveContentImage = async (id, data, base = defaultBase) => {
  return await request({
    resource: `${base}/${id}/content`,
    method: Methods.POST,
    data,
  });
};
