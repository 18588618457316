import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  withPostUpdate,
  defaultPostUpdateParams,
  FeedbackAreaChartContainer,
} from 'modules/shared';
import { match } from 'common';
import * as companiesService from 'modules/shared/services/companies';

import styles from './CompanyFeedbackChart.scss';

class CompanyFeedbackChart extends Component {
  static propTypes = {
    post: PropTypes.object,
    match,
  };

  state = {
    company: null,
  };

  async componentDidMount() {
    if (this.props.match.params.companyId) {
      const company = await companiesService.getCompany(this.props.match.params.companyId);
      this.setState({ company });
    }
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { post, showLoader, hideLoader } = this.props;
    const { company } = this.state;

    return (
      <div className="company-feedback-container">
        <FormattedMessage id="ANALYST.COMPANY_FEEDBACK_CHART">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <Grid>
          {post && company ? (
            <Row className="text-center">
              <Col xs={12}>
                <FeedbackAreaChartContainer
                  post={post}
                  company={{
                    id: company.id,
                    name: company.title,
                  }}
                  showLoader={showLoader}
                  hideLoader={hideLoader}
                  mobile
                />
              </Col>
            </Row>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default withPostUpdate({ ...defaultPostUpdateParams, idKey: 'postId' })(
  withStyles(styles)(injectIntl(CompanyFeedbackChart))
);
