import { GET_USER_SETTINGS } from '../actions/users';

/**
 * @param {Object} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Object} Modified state.
 */
const userSettings = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_SETTINGS:
      return action.payload;
    default:
      return state;
  }
};

export default userSettings;
