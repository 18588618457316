import { BaseQueryParams } from './base-query-params.model';
import { BaseBuys } from './buys.model';

export interface PostsQueryParams extends BaseQueryParams {
  onlyvideo?: boolean;
  specialtopics?: boolean;
  onlyshortscreen?: boolean;
  onlylongscreen?: boolean;
  region?: string;
  category?: string;
  topic?: PostsQueryTopic;
  recommendations?: BaseBuys;
}

export enum PostsQueryTopic {
  ALL_RESEARCH = 'all_research',
  VIDEO = 'video',
  SPECIAL_TOPICS = 'special_topics',
  INITIATION_NOTE = 'initiation_note',
  DROP = 'drop',
}
