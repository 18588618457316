import React, { Component } from 'react';
import AppendToBody from './AppendToBody';

class Backdrop extends Component {
  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <AppendToBody>
        <div id="app-backdrop" {...this.props} />
      </AppendToBody>
    );
  }
}

export default Backdrop;
