import request, { Methods } from 'modules/shared/util/request';

const BASE_URL = 'pages';

/**
 * @param {string} site is name of page parent.
 * @param {string} slug is page name.
 * @param {Object} page content of page.
 *
 * @returns {Promise} Page update
 */
export const updatePage = async (site, slug, page) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${site}/${slug}`,
    data: page,
  });
};

/**
 * @param {string} site is name of page parent.
 * @param {string} slug is page name.
 *
 * @returns {Promise} get Page
 */
export const getPage = async (site, slug) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${site}/${slug}`,
  });
};
