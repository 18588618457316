import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './TextEditor.scss';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { commonFileUpload } from 'common';
import { commonUploadFile, deleteFile } from 'modules/admin/main/actions/file-upload';
import Env from 'env';
import { FormattedMessage } from 'react-intl';
import { isValidSchema, urlSchema } from 'common';

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png'];
const HTTP = 'http://';

class TextEditor extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    commonUploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    setReadyState: PropTypes.func,
    zIndex: PropTypes.number,
    name: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    toolbar: PropTypes.array,
    error: PropTypes.any,
    loaded: PropTypes.bool,
  };

  static defaultProps = {
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'italic', 'clear']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'hr']],
      ['view', ['codeview']],
      ['custom', ['frame', 'embedVideo', 'insertImage']],
      ['height', ['height']],
    ],
    loaded: true,
  };

  state = { fileLimitError: false, fileTypeError: false };

  /**
   * @description Import quill. Its a workound for SSR.
   *
   * @param {Object} props Component property object.
   */
  constructor(props) {
    super(props);
    if (global.document) {
      this.reactSummernote = require('../react-summernote/ReactSummernote');
      require('bootstrap/js/modal');
      require('bootstrap/js/dropdown');
      require('bootstrap/js/tooltip');
    }
  }

  onChange = async (text) => {
    const e = { persist: () => {}, target: { name: this.props.name, value: text } };
    this.props.handleChange(e);
    this.replaceURL(text);
  };

  /**
   * @param {string} text content
   * @description check all url, and trim 'http://', if url invalid, invalid means that url is relative
   * */
  async replaceURL(text) {
    const foundLinks = text.match(Env.HREF_PATTERN);

    if (foundLinks && foundLinks.length) {
      const validators = [];
      for (const link of foundLinks) {
        validators.push(
          isValidSchema(urlSchema('url'), {
            url: link.replace('href="', '').replace('"', ''),
          })
        );
      }

      const checkedLinks = await Promise.all(validators);
      const invalidLinks = checkedLinks.filter((x) => !x.isValid);

      for (const invalidLink of invalidLinks) {
        const pureUrl = invalidLink.error.value.url;
        if (pureUrl.indexOf(HTTP) > -1) {
          const pureUrlPosition = text.indexOf(pureUrl);
          text =
            text.substr(0, pureUrlPosition) +
            text.substr(pureUrlPosition + HTTP.length, text.length);
        }
      }

      const e = { persist: () => {}, target: { name: this.props.name, value: text } };
      this.props.handleChange(e);
    }
  }

  /**
   * @param {Object} file that user uploaded
   * @returns {boolean} check file size is it under limit
   * */
  isFileOfSizeOverLimit = (file) => {
    const fileLimitError = file ? file.size > Env.MAX_FILE_SIZE * 1024 * 1024 : false;
    this.setState({ fileLimitError });
    return fileLimitError;
  };

  isFileTypeInCorrect = (file) => {
    const fileTypeError = file ? !ACCEPTED_FILE_TYPES.find((x) => x === file.type) : false;
    this.setState({ fileTypeError });
    return fileTypeError;
  };

  /**
   * @param {string} key of error message
   * */
  emptyErrorMessage = (key) => {
    setTimeout(() => {
      this.setState({ [key]: false });
    }, 10000);
  };

  onImageUpload = async ({ files, headNote, footNote }, insertNode) => {
    if (this.isFileOfSizeOverLimit(files[0])) {
      this.emptyErrorMessage('fileLimitError');
      return;
    }

    if (this.isFileTypeInCorrect(files[0])) {
      this.emptyErrorMessage('fileTypeError');
      return;
    }

    if (this.props.setReadyState) {
      this.props.setReadyState(false);
    }
    // save image
    const formData = new FormData();
    formData.append('content', files[0]);
    const { path } = await commonFileUpload(files, this.props.commonUploadFile);

    // create image with caption and footernote
    // create postAssets
    const postAssets = document.createElement('div');
    postAssets.className = 'postAssets';

    // create figure and figureCaption
    const figure = document.createElement('figure');
    const figCaption = document.createElement('figcaption');
    figCaption.innerHTML = 'Write here caption of picture.';

    //create a tag
    const a = document.createElement('a');
    a.href = Env.S3_URL + path;
    a.target = '_blank';

    //create image
    const image = document.createElement('img');
    image.src = Env.S3_URL + path;
    image.alt = files[0].name;
    image.title = files[0].name;

    //create footer
    const footer = document.createElement('p');
    footer.className = 'wp-caption-text';
    footer.innerHTML = 'Write here footnote of picture';

    // append figCaption and image under figure
    if (headNote) {
      figure.appendChild(figCaption);
    }
    a.appendChild(image);
    figure.appendChild(a);

    // append figure and footer under postAssets
    postAssets.appendChild(figure);
    if (footNote) {
      postAssets.appendChild(footer);
    }

    insertNode(postAssets);

    if (this.props.setReadyState) {
      this.props.setReadyState(true);
    }
  };

  onImageRemove = async (imagePath) => {
    await this.props.deleteFile({
      path: `/${imagePath.substr(Env.S3_URL.length, imagePath.length)}`,
    });
  };

  /**
   * @returns {JSX.Element} Quill Editor component.
   */
  render() {
    if (this.reactSummernote && this.props.loaded) {
      const ReactSummernote = this.reactSummernote.default;
      return (
        <div
          className={classNames('text-editor-container', {
            error: this.props.error || this.state.fileLimitError || this.state.fileTypeError,
          })}>
          <ReactSummernote
            className={classNames('text-editor', this.props.className)}
            name={this.props.name}
            value={this.props.text}
            zIndex={this.props.zIndex}
            options={{ height: 350, dialogsInBody: true, toolbar: this.props.toolbar }}
            onImageUpload={this.onImageUpload}
            onImageRemove={this.onImageRemove}
            onChange={this.onChange}
          />
          {this.state.fileLimitError ? (
            <div className="error-message p-0">
              <FormattedMessage id="ADMIN.FILE_SIZE_ERROR" />
            </div>
          ) : null}
          {this.state.fileTypeError ? (
            <div className="error-message p-0">
              <FormattedMessage
                id="ADMIN.FILE_TYPE_ERROR"
                values={{ types: ACCEPTED_FILE_TYPES.join(', ') }}
              />
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      commonUploadFile,
      deleteFile,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(style)(TextEditor));
