import React, { Fragment, useState, useRef } from 'react';
import { Tooltip as RBTooltip, Overlay } from 'react-bootstrap';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';

import style from './Tooltip.scss';

/**
 * @param {JSX.Element} icon icon component.
 * @param {string} placement place of tooltip.
 * @param {JSX.Element | string} tooltipText Text to show in tooltip.
 * @param {JSX.Element} overlay note to show as tooltip.
 * @returns {JSX.Element}
 */
const Tooltip = ({ tooltipText, icon, overlay, placement }) => {
  const containerRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const showTooltip = () => {
    setTooltipOpen(true);
  };

  const hideTooltip = () => {
    setTooltipOpen(false);
  };

  return (
    <Fragment>
      <span
        className="icon-toggle"
        ref={containerRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}>
        {icon}
      </span>

      <Overlay
        placement={placement}
        show={tooltipOpen}
        onHide={hideTooltip}
        target={containerRef.current}>
        {overlay ? (
          <span className={classNames('tooltip', placement)}>
            {overlay}
            <span className="tooltip-text">{tooltipText}</span>
          </span>
        ) : (
          <RBTooltip className={classNames('tooltip', placement)}>{tooltipText}</RBTooltip>
        )}
      </Overlay>
    </Fragment>
  );
};

Tooltip.propTypes = {
  icon: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  overlay: PropTypes.node,
};

export default withStyles(style)(Tooltip);
