import React, { Component } from 'react';
import { PageEditor } from 'modules/admin/shared';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class TermsOfUse extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  /**
   * @returns {JSX.Element} Terms of use component.
   */
  render() {
    return (
      <PageEditor
        site="analyst"
        viewLink="/terms-of-use"
        slug="terms-of-use"
        title={<FormattedMessage id="ANALYST.TERMS_OF_USE" />}
      />
    );
  }
}

export default TermsOfUse;
