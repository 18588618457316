import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import { PostsList } from 'modules/shared';
import styles from './RelatedPost.scss';

class RelatedPosts extends Component {
  static propTypes = {
    posts: PropTypes.array,
    total: PropTypes.number,
    fetchData: PropTypes.func,
    showTotal: PropTypes.bool,
    fetching: PropTypes.bool,
    noMarginOnRow: PropTypes.bool,
    hasElements: PropTypes.bool,
    hideTitle: PropTypes.bool,
    titleMarginLeft: PropTypes.bool,
    noTitleMarginRight: PropTypes.bool,
    hide: PropTypes.bool,
    noDidMountFetch: PropTypes.bool,
    moreWidth: PropTypes.bool,
    updateData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node]),
    emptyMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    companyName: PropTypes.string,
    intl: PropTypes.object,
  };

  state = {
    postItems: [],
    pagingParams: {},
    fetchingItems: false,
  };

  formatMessage = (id) => this.props.intl.formatMessage({ id });

  /**
   * @description fetch data
   */
  componentDidMount() {
    const { fetchData, posts, noDidMountFetch } = this.props;

    if (fetchData && !posts && !noDidMountFetch) {
      this.getPosts(true);
    }
  }

  /**
   * @param {Object} prevProps previous props
   * @description fetch data
   */
  componentDidUpdate(prevProps) {
    const { fetchData, updateData } = this.props;

    if (fetchData && !!updateData && prevProps.updateData !== updateData) {
      this.getPosts(true);
    }
  }

  /**
   * @param {boolean} init is init fetching
   * @returns {void}
   */
  getPosts = async (init) => {
    this.setState(
      {
        fetchingItems: true,
      },
      async () => {
        try {
          const { data, ...params } = await this.props.fetchData({
            page: init ? 1 : this.state.pagingParams.current_page + 1 || 1,
            nopaginate: false,
          });

          this.setState((prevState) => ({
            postItems: init ? data : [...prevState.postItems, ...data],
            pagingParams: params || {},
          }));
        } finally {
          this.setState({ fetchingItems: false });
        }
      }
    );
  };

  /**
   * @param {Object} data updated posts data.
   * @returns {void}
   */
  afterBookmark = (data) => {
    const { afterBookmark, posts } = this.props;

    if (posts) {
      afterBookmark(data);
    } else {
      this.setState({ postItems: data });
    }
  };

  getTotalCounts = () => {
    const { showTotal, total } = this.props;

    return showTotal ? this.state.pagingParams.total : total;
  };

  getPostsData = () => {
    const { posts } = this.props;
    return this.state.postItems.length > 0 ? this.state.postItems : posts || [];
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      total,
      posts,
      fetchData,
      showTotal,
      fetching,
      noMarginOnRow,
      hideTitle,
      title,
      titleMarginLeft,
      noTitleMarginRight,
      emptyMessage,
      hasElements,
      hide,
      moreWidth,
      companyName,
      ...props
    } = this.props;
    const { fetchingItems, pagingParams } = this.state;

    const totalCounts = this.getTotalCounts();
    const postsData = this.getPostsData();
    const isFetching = fetchingItems || fetching;
    const postsTitle = typeof title === 'function' ? title(postsData) : title;

    return !hide ? (
      <Fragment>
        {!hideTitle ? (
          <Col
            className={classNames('related-post', {
              'margin-left': titleMarginLeft,
              'no-margin-right': noTitleMarginRight,
            })}
            xs={12}>
            {title ? (
              postsTitle
            ) : companyName ? (
              <Fragment>
                <FormattedMessage id="MAIN.READ_MORE_ABOUT_COMPANY" /> {companyName}
                {totalCounts
                  ? ` (${totalCounts} ${
                      totalCounts > 1
                        ? this.formatMessage('MAIN.POSTS')
                        : this.formatMessage('MAIN.POST')
                    })`
                  : null}
              </Fragment>
            ) : (
              <Fragment>
                <FormattedMessage id="MAIN.RELATED_POSTS" />{' '}
                {totalCounts ? `(${totalCounts})` : null}
              </Fragment>
            )}
          </Col>
        ) : null}
        <Row
          className={classNames('post-list-container', {
            'm-0': noMarginOnRow,
            'more-width': moreWidth,
          })}>
          {(Array.isArray(postsData) && postsData.length > 0) || isFetching ? (
            <PostsList
              posts={postsData}
              fetching={isFetching}
              fetchData={!posts ? this.getPosts : fetchData}
              hasElements={
                typeof hasElements !== 'undefined'
                  ? hasElements
                  : pagingParams.current_page < pagingParams.last_page
              }
              {...props}
              afterBookmark={this.afterBookmark}
            />
          ) : (
            <div className="no-posts">
              {emptyMessage || <FormattedMessage id="MAIN.POST_EMPTY" />}
            </div>
          )}
        </Row>
      </Fragment>
    ) : null;
  }
}

export default withStyles(styles)(injectIntl(RelatedPosts));
