import React, { Component } from 'react';
import metadataRoutes from 'modules/admin/main/routes/metadata';
import renderRoutes from 'common/renderRoutes';

class Metadata extends Component {
  /**
   * @returns {JSX.Element} Metadata component.
   */
  render() {
    return <div className="admin-content">{renderRoutes(metadataRoutes, { basePath: `` })}</div>;
  }
}

export default Metadata;
