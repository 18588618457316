import React from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import { Login } from 'modules/main/components';
import Env from 'env';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getEdgeLanding } from 'common';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    settings: app.userSettings,
  };
};

/**
 * @param {node} WrappedComponent is loaded component
 *
 * @returns {Object} - headerContent component
 */
export default function headerContent(WrappedComponent) {
  return connect(mapStateToProps)(
    class HeaderContent extends React.Component {
      static propTypes = {
        onLoginToggle: PropTypes.func,
        settings: PropTypes.object,
        showAnalystLoginForm: PropTypes.bool,
        showEdgeLoginForm: PropTypes.bool,
      };

      state = {
        showMegaMenu: false,
        showAnalystLoginForm: false,
        showEdgeLoginForm: false,
      };

      onToggle = () => {
        this.setState((prevState) => ({ showMegaMenu: !prevState.showMegaMenu }));
      };

      toggleLoginForm = (key) => {
        this.setState(
          (prevState) => {
            const value = prevState[key] || this.props[key];
            return { [key]: !value };
          },
          () => {
            if (this.props.onLoginToggle && this.props[key]) {
              this.props.onLoginToggle(this.state[key]);
            }
          }
        );
      };

      /**
       * @description Prevents body scroll on open popup.
       */
      componentDidUpdate() {
        if (
          this.state.showAnalystLoginForm ||
          this.props.showAnalystLoginForm ||
          this.state.showEdgeLoginForm ||
          this.props.showEdgeLoginForm
        ) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');
        }
      }

      /**
       * @returns {JSX.Element} - HeaderContent component
       */
      render() {
        return (
          <div>
            <WrappedComponent
              onToggle={this.onToggle}
              toggleLoginForm={this.toggleLoginForm}
              showMegaMenu={this.state.showMegaMenu}
              {...this.props}
            />
            {this.state.showAnalystLoginForm || this.props.showAnalystLoginForm ? (
              <div className="login-popup-container" key="login-popup-container-analyst">
                <Grid>
                  <Row>
                    <Col md={12}>
                      <Login
                        showLoginForm={() => this.toggleLoginForm('showAnalystLoginForm')}
                        showForm={true}
                        title="the-analyst-logo-light"
                        appPath={`/app`}
                        readMore="/"
                        site={Env.ANALYST_BASE_PATH}
                        info={<FormattedMessage id="MAIN.ANALYST_TAGLINE" />}
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : null}
            {this.state.showEdgeLoginForm || this.props.showEdgeLoginForm ? (
              <div className="login-popup-container" key="login-popup-container-edge">
                <Grid>
                  <Row>
                    <Col md={12}>
                      <Login
                        showLoginForm={() => this.toggleLoginForm('showEdgeLoginForm')}
                        showForm={true}
                        type="edge"
                        title="theanalystedge-logo-blue"
                        appPath={`/${getEdgeLanding(this.props.settings)}/app`}
                        readMore={`/${Env.EDGE_BASE_PATH}`}
                        site={Env.EDGE_BASE_PATH}
                        info={<FormattedMessage id="MAIN.EDGE_TAGLINE" />}
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : null}
          </div>
        );
      }
    }
  );
}
