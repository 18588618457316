import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';

import styles from './PostDetailContainer.scss';

class PostDetailContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.bool,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="post-detail-container">
        {this.props.error ? <FormattedMessage id="MAIN.POST_NOT_FOUND" /> : this.props.children}
      </div>
    );
  }
}
export default withStyles(styles)(PostDetailContainer);
