import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';

import HeaderNavLinks from 'modules/admin/shared/components/header/HeaderNavLinks';
import { routeProps } from 'common/propTypes';
import logo from 'assets/img/app-icon.png';
import styles from './Sidebar.scss';
import withStyles from 'isomorphic-style-loader/withStyles';

import homeRoutes from 'modules/admin/main/routes/home.jsx';
import { Img, Roles } from 'modules/shared';

class Sidebar extends Component {
  static propTypes = {
    ...routeProps,
  };

  state = {
    sections: this.getSectionsState(),
  };

  /**
   * @returns {Array}
   */
  getSectionsState() {
    const active = homeRoutes.findIndex((route) => this.activeRoute(route.path));
    const sections = [];

    for (let index = 0; index < homeRoutes.length - 1; index++) {
      sections.push({ collapsed: true });
    }

    if (active !== -1) {
      sections[active || 0].collapsed = false;
    }

    return sections;
  }

  /**
   * @description Opens active routes parent container
   */
  componentDidUpdate() {
    if (!this.collapseSectionEvent) {
      const active = homeRoutes.findIndex((route) => this.activeRoute(route.path));

      if (active !== -1 && this.state.sections[active].collapsed === true) {
        this.setState({
          sections: Object.assign([], this.state.sections, { [active]: { collapsed: false } }),
        });
      }
    }
  }

  /**
   * @param {string} routeName Nem of the current route.
   *
   * @returns {string} active or empty string.
   */
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  /**
   * @param {number} index of section.
   */
  collapseSection(index) {
    this.collapseSectionEvent = true;
    const sections = this.state.sections.map((data) => data);
    sections[index].collapsed = !sections[index].collapsed;
    this.setState({ sections });
  }

  getListItems = (routes, key) => {
    return routes.map((propInner, keyInner) => {
      const isAccess = propInner.role
        ? this.props.profile.roles.filter((role) => propInner.role.includes(role.role_slug))
            .length > 0
        : true;
      if (!propInner.redirect && isAccess) {
        return (
          <li className={classNames(this.activeRoute(propInner.path))} key={`${key}-${keyInner}`}>
            <NavLink to={propInner.path} className="nav-link" activeClassName="active">
              <p>{propInner.name}</p>
            </NavLink>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  /**
   * @returns {JSX.Element} Sidebar component.
   */
  render() {
    return (
      <div id="sidebar" className="sidebar">
        <div className="logo">
          <div className="logo-img logo-mini">
            <NavLink to="/app">
              <Img src={logo} alt="logo_image" />
            </NavLink>
          </div>
          <a className="simple-text logo-normal">Dashboard</a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            <HeaderNavLinks className="header-links" />
            {homeRoutes.map((prop, key) => {
              if (!prop.redirect) {
                return (
                  <li key={key}>
                    <div className="nav-section">
                      <p
                        onClick={() => {
                          this.collapseSection(key);
                        }}>
                        {prop.name}
                      </p>
                      <i
                        className={
                          this.state.sections[key].collapsed
                            ? 'fas fa-angle-down'
                            : 'fas fa-angle-up'
                        }
                        onClick={() => {
                          this.collapseSection(key);
                        }}
                      />
                    </div>
                    <ul
                      className={
                        this.state.sections[key].collapsed
                          ? 'nav-section-list collapsed'
                          : 'nav-section-list'
                      }>
                      {this.getListItems(prop.subRoutes, prop.key)}
                    </ul>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { profile: app.profile };
};

export default connect(mapStateToProps)(withStyles(styles)(Sidebar));
