/**
 * @description Returns a function, that, as long as it continues to be invoked, will not
 *              be triggered. The function will be called after it stops being called for
 *              N milliseconds. If `immediate` is passed, trigger the function on the
 *              leading edge, instead of the trailing.
 *
 * @param {Function} func Function to be called.
 * @param {number} wait Debounce time in milliseconds.
 * @param {boolean} immediate Whether it should be called on the leading edge.
 * @returns {Function}
 */
export const debounce = (func, wait, immediate = false) => {
  let timeout;
  return function(...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    /**
     * @description Function to be called after timeout
     */
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};
