import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import {
  Footer,
  Content,
  ContentSubTitle,
  TextInput,
  Button,
  Svg,
  // RadioButton,
} from 'modules/shared';
// import { MainHeader } from 'modules/main';
import bg from 'assets/img/contact-cover.jpg';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './Contact.scss';
import { withFormikValidation } from 'common/propTypes';
import Reaptcha from 'reaptcha';
import PropTypes from 'prop-types';
import Env from 'env';
import { PublicHeader } from 'modules/analyst/modules/shared';
import { analystFooterLinks } from 'common';
import classNames from 'classnames';

class Contact extends Component {
  static propTypes = {
    sendMessage: PropTypes.func.isRequired,
  };

  state = {
    recaptchaInstance: null,
    messageSent: false,
  };

  clearForm = (resetForm) => {
    resetForm({
      name: '',
      email: '',
      company: '',
      message: '',
      recaptha: '',
      contact: '',
    });
    this.setState({ messageSent: true });
    this.state.recaptchaInstance.reset();
  };

  setRecaptchaRef = (e) => {
    this.setState({ recaptchaInstance: e });
  };

  /**
   * @returns {JSX.Element} - Contact component
   */
  render() {
    return (
      <div className="analyst-home contact white-background">
        <FormattedMessage id="MAIN.HEADER.CONTACT">
          {(title) => (
            <Helmet defer={false}>
              <title itemProp="name">{title}</title>
              <link rel="canonical" href={`${Env.SITE_URL}contact`} itemProp="url" />
              <script type="application/ld+json">
                {`{
                  "@context": "http://schema.org",
                  "@type": "Organization",
                  "url": "${Env.SITE_URL}",
                  "contactPoint": [
                    { "@type": "ContactPoint",
                      "telephone": "+44 020 7498 6593",
                      "contactType": "customer service"
                    }
                  ]
                }`}
              </script>
            </Helmet>
          )}
        </FormattedMessage>
        {/*<MainHeader />*/}
        <PublicHeader />

        <Content mediumHeight={true}>
          <ContentSubTitle
            data={{ background: bg, title: <FormattedMessage id="MAIN.CONTACT.COVER_TITLE" /> }}
          />
          <Grid>
            <Row className={classNames({ 'd-flex': this.state.messageSent })}>
              <Col lg={6} md={6} sm={12} className="contact-section">
                <div className="logo analyst">
                  <Svg name="the-analyst-logo-dark" />
                  <div className="tagline">
                    <FormattedMessage id="MAIN.ANALYST_TAGLINE" />
                  </div>
                </div>
                {/*<div className="get-in-touch analyst">*/}
                {/*<FormattedMessage id="MAIN.CONTACT.GET_IN_TOUCH_ANALYST" />{' '}*/}
                {/*<FormattedMessage id="MAIN.MEGA_MENU.THE_ANALYST" />*/}
                {/*</div>*/}
                <div className="description">
                  <FormattedMessage id="MAIN.CONTACT.GET_IN_TOUCH_DESCRIPTION" />
                </div>
                <div className="phone">
                  <FormattedMessage id="MAIN.CONTACT.TEL" />
                  <span>: </span>
                  <FormattedMessage id="MAIN.CONTACT.PHONE_NUMBER_ANALYST" />
                </div>
                <div className="email analyst">
                  <FormattedMessage id="MAIN.CONTACT.EMAIL" />
                  <span>: </span>
                  <FormattedHTMLMessage id="MAIN.CONTACT.ANALYST_EMAIL" />
                </div>

                {/*<hr />*/}

                {/*<div className="logo edge">*/}
                {/*<Svg name="theanalystedge-logo-blue" />*/}
                {/*<div className="tagline">*/}
                {/*<FormattedMessage id="MAIN.EDGE_TAGLINE" />*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className="get-in-touch edge">*/}
                {/*<FormattedMessage id="MAIN.CONTACT.GET_IN_TOUCH_EDGE" />{' '}*/}
                {/*<FormattedMessage id="MAIN.MEGA_MENU.THE_ANALYST_EDGE" />*/}
                {/*</div>*/}
                {/*<div className="description">*/}
                {/*<FormattedMessage id="MAIN.CONTACT.GET_IN_TOUCH_DESCRIPTION" />*/}
                {/*</div>*/}
                {/*<div className="phone">*/}
                {/*<FormattedMessage id="MAIN.CONTACT.TEL" />*/}
                {/*<span>: </span>*/}
                {/*<FormattedMessage id="MAIN.CONTACT.PHONE_NUMBER_EDGE" />*/}
                {/*</div>*/}
                {/*<div className="email edge">*/}
                {/*<FormattedMessage id="MAIN.CONTACT.EMAIL" />*/}
                {/*<span>: </span>*/}
                {/*<FormattedHTMLMessage id="MAIN.CONTACT.EDGE_EMAIL" />*/}
                {/*</div>*/}
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className={classNames('contact-section', {
                  'd-flex align-center justify-center': this.state.messageSent,
                })}>
                {!this.state.messageSent ? (
                  <ContactForm
                    sendMessage={this.props.sendMessage}
                    clearForm={this.clearForm}
                    setRecaptchaRef={this.setRecaptchaRef}
                  />
                ) : (
                  <FormattedMessage id="MAIN.CONTACT.THANKS_MESSAGE" />
                )}
              </Col>
            </Row>
          </Grid>
        </Content>

        {/*<Footer header={{ toTop: true }} />*/}
        <Footer
          className="analyst analyst-link-hover"
          header={{
            toTop: true,
            icons: true,
            logoAnalyst: true,
            links: analystFooterLinks,
          }}
        />
      </div>
    );
  }
}

export default withStyles(style)(Contact);

/**
 * @param {Object} Props Injected Form Props.
 * @returns {JSX.Element}
 */
const InnerContactForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  isValid,
  setRecaptchaRef,
}) => {
  /**
   * @param {response} response recaptha value
   *
   * @description set recaptha value
   */
  const verifyCallback = (response) => {
    values.recaptha = response;
    setFieldValue('recaptha', values.recaptha);
  };

  /**
   * @description reset recaptha value
   */
  const expiredCallback = () => {
    values.recaptha = '';
    setFieldValue('recaptha', values.recaptha);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextInput
        type="text"
        name="name"
        white
        label={<FormattedMessage id="MAIN.CONTACT.NAME" />}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
        placeholderId="MAIN.CONTACT.NAME"
        touched={touched.name}
        className="square-style"
      />
      <TextInput
        type="email"
        name="email"
        white
        label={<FormattedMessage id="MAIN.CONTACT.EMAIL" />}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        placeholderId="MAIN.CONTACT.EMAIL"
        touched={touched.email}
        className="square-style"
      />
      <TextInput
        type="text"
        name="company"
        white
        label={<FormattedMessage id="MAIN.COMPANY" />}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.company}
        placeholderId="MAIN.COMPANY"
        touched={touched.company}
        className="square-style"
      />
      <TextInput
        type="text"
        name="message"
        white
        label={<FormattedMessage id="MAIN.CONTACT.MESSAGE" />}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.message}
        placeholderId="MAIN.CONTACT.MESSAGE"
        touched={touched.message}
        className="square-style"
        componentClass="textarea"
      />
      {/*<div className="contact control-label">*/}
      {/*<FormattedMessage id="MAIN.CONTACT.CONTACT" />*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*<RadioButton*/}
      {/*name="contact"*/}
      {/*value="analyst"*/}
      {/*label={<FormattedMessage id="MAIN.MEGA_MENU.THE_ANALYST" />}*/}
      {/*className="analyst"*/}
      {/*onChange={handleChange}*/}
      {/*/>*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*<RadioButton*/}
      {/*name="contact"*/}
      {/*value="edge"*/}
      {/*label={<FormattedMessage id="MAIN.MEGA_MENU.THE_ANALYST_EDGE" />}*/}
      {/*className="edge"*/}
      {/*onChange={handleChange}*/}
      {/*/>*/}
      {/*</div>*/}
      <div className="recaptcha-container">
        <Reaptcha
          ref={setRecaptchaRef}
          sitekey={Env.RECAPTCHA_SITE_KEY}
          onVerify={verifyCallback}
          onExpire={expiredCallback}
        />
      </div>
      <Button onClick={handleSubmit} disabled={isSubmitting || !isValid} moduleType="analyst">
        <FormattedMessage id="MAIN.CONTACT.SEND_MESSAGE" />
      </Button>
    </form>
  );
};

InnerContactForm.propTypes = {
  setFieldValue: PropTypes.func,
  resetForm: PropTypes.func,
  sendMessage: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  setRecaptchaRef: PropTypes.func.isRequired,
  ...withFormikValidation,
};

const ContactForm = withFormik({
  mapPropsToValues: (props) => {
    return {
      name: '',
      email: '',
      company: '',
      message: '',
      recaptha: '',
      contact: '',
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string()
      .email('Invalid email address')
      .required(),
    company: Yup.string().required(),
    message: Yup.string().required(),
    recaptha: Yup.string().required(),
    // contact: Yup.string().required(),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors, resetForm }) => {
    try {
      await props.sendMessage(values);
      setSubmitting(false);
      props.clearForm(resetForm);
    } catch (e) {
      setErrors({ contact: e });
      props.clearForm(resetForm);
    }
  },
})(InnerContactForm);
