import React, { Component } from 'react';
import { Header, Sidebar } from 'modules/admin/shared/components';
import renderRoutes from 'common/renderRoutes';
import homeRoutes from 'modules/admin/main/routes/home.jsx';

class Home extends Component {
  /**
   * @returns {JSX.Element} Home component.
   */
  render() {
    return (
      <div className="wrapper">
        <Sidebar {...this.props} />

        <div id="main-panel" className="main-panel">
          <Header {...this.props} />
          {renderRoutes(homeRoutes)}
        </div>
      </div>
    );
  }
}

export default Home;
