import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Grid, Row, Col } from 'react-bootstrap';

import { Button, EditorTitle, FieldGroup } from 'modules/admin/shared';
import { isValidSchema, textSchema, urlSchema } from 'common';
import * as Yup from 'yup';
import Env from 'env';

class SentimentTracker extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getSentimentTracker: PropTypes.func.isRequired,
    saveSentimentTracker: PropTypes.func.isRequired,
    sentimentTracker: PropTypes.object.isRequired,
  };

  state = {
    sentimentTracker: {
      title: '',
      video_url: '',
      description: '',
      star_description: '',
      vote_title: '',
    },
    errors: {},
    isSubmitting: false,
    loading: true,
    isValid: false,
  };

  schema = {
    ...textSchema('title', { required: true }),
    ...urlSchema('video_url', { required: true }),
    ...textSchema('description', { required: true, max: Env.MAX_TEXT_EDITOR_LENGTH }),
    ...textSchema('star_description', { required: true }),
    ...textSchema('vote_title', { required: true }),
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();

    const sentimentTracker = await this.props.getSentimentTracker();
    const isValid = await this.isValidForm(sentimentTracker);

    const state = {
      loading: false,
      sentimentTracker,
      isValid,
    };
    this.setState(state);

    this.props.hideLoader();
  }

  /**
   * @description save baseData changes
   */
  save = async () => {
    this.setState({ isSubmitting: true });

    try {
      await this.props.saveSentimentTracker(this.state.sentimentTracker);
      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  /**
   * @returns {boolean} editor is valid.
   * @param {Object} sentimentTracker Data object.
   */
  isValidForm = async (sentimentTracker = this.state.sentimentTracker) => {
    const schema = Yup.object().shape(this.schema);

    return await schema.isValid(sentimentTracker);
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    const errors = { ...this.state.errors };
    let isValidForm = { ...this.state.isValid };

    const name = e.target.name;
    const value = e.target.value;

    this.setState(
      {
        sentimentTracker: {
          ...this.state.sentimentTracker,
          [name]: value,
        },
      },
      async () => {
        isValidForm = await this.isValidForm();
        const { error } = await isValidSchema(
          name === 'video_url' ? urlSchema(name) : textSchema(name),
          {
            [name]: value,
          }
        );
        errors[name] = error;

        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { sentimentTracker, errors, loading, isSubmitting } = this.state;

    return (
      <div>
        {!loading ? (
          <form noValidate>
            <EditorTitle
              title={<FormattedMessage id="ADMIN.SENTIMENT_TRACKER.TITLE" />}
              viewLink="/app/sentiment-tracker">
              <Button
                loading={isSubmitting}
                onClick={this.save}
                type="button"
                disabled={!this.state.isValid || isSubmitting}>
                <FormattedMessage id="ADMIN.SAVE" />
              </Button>
            </EditorTitle>
            <Grid fluid className="editor-content">
              <Row>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="title"
                    placeholderId="ADMIN.NAME"
                    label={<FormattedMessage id="ADMIN.NAME" />}
                    value={sentimentTracker.title}
                    error={errors.title ? errors.title.message : null}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="video_url"
                    placeholderId="ADMIN.VIDEO_LINK"
                    label={<FormattedMessage id="ADMIN.VIDEO_LINK" />}
                    value={sentimentTracker.video_url}
                    error={errors.video_url ? errors.video_url.message : null}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    componentClass="textarea"
                    placeholderId="ADMIN.DESCRIPTION"
                    label={<FormattedMessage id="ADMIN.DESCRIPTION" />}
                    name="description"
                    value={sentimentTracker.description}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    componentClass="textarea"
                    placeholderId="ADMIN.STAR_DESCRIPTION"
                    label={<FormattedMessage id="ADMIN.STAR_DESCRIPTION" />}
                    name="star_description"
                    value={sentimentTracker.star_description}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    componentClass="textarea"
                    placeholderId="ADMIN.VOTE_TITLE"
                    label={<FormattedMessage id="ADMIN.VOTE_TITLE" />}
                    name="vote_title"
                    value={sentimentTracker.vote_title}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        ) : null}
      </div>
    );
  }
}

export default SentimentTracker;
