import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as BsButton } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './style.scss';
import { Spinner } from 'modules/shared';

class Button extends Component {
  static propTypes = {
    moduleType: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    textColor: PropTypes.string,
    filled: PropTypes.bool,
    loading: PropTypes.bool,
    wrap: PropTypes.bool,
    thin: PropTypes.bool,
    sm: PropTypes.bool,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      children,
      moduleType,
      textColor,
      className,
      filled,
      loading,
      wrap,
      thin,
      sm,
      ...props
    } = this.props;
    return (
      <BsButton
        {...props}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className={classNames('button', className, moduleType, `${textColor}-text-color`, {
          filled,
          wrap,
          thin,
          sm,
        })}>
        {loading ? <Spinner size="small" /> : null}
        {children}
      </BsButton>
    );
  }
}

export default withStyles(style)(Button);
