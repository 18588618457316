import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Position, SectorAccess, SvgIcon } from 'modules/shared';
import { BuyTypes } from 'common';

class RecommendationDrawer extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    companies: PropTypes.array,
    open: PropTypes.bool,
    style: PropTypes.object,
    toggleDrawer: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    recommendationType: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    companies: [],
  };

  /**
   * @description Disables background scrolling, if the drawer is open.
   */
  componentDidUpdate() {
    this.props.open
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div
        style={this.props.style}
        className={classNames(this.props.className, 'recommendation-drawer', {
          open: this.props.open,
        })}>
        <div className="header">
          {this.props.title}
          {this.props.recommendationType ? (
            <SvgIcon name={BuyTypes[this.props.recommendationType].icon} height={32} width={32} />
          ) : null}
        </div>
        <div className="companies">
          {this.props.companies.map(({ title, id, company_id, slug, has_sector_access }) => (
            <SectorAccess key={id} hasAccess={has_sector_access} position={Position.RIGHT}>
              <NavLink
                key={id}
                className={classNames('company company-link', { disabled: !has_sector_access })}
                to={`${this.props.basePath}/company/${company_id}/${slug}`}
                title={title}
                onClick={this.props.toggleDrawer}>
                {title}
              </NavLink>
            </SectorAccess>
          ))}
          {!this.props.companies.length && (
            <div className="empty">
              <FormattedMessage id="MAIN.NO_RECOMMENDATION" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RecommendationDrawer;
