import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { Bookmark, Html } from 'modules/shared';
import { Buys } from 'common';

class CompanyDetailHeader extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    bookmarkCompany: PropTypes.func.isRequired,
    bookmarking: PropTypes.bool,
    latestPost: PropTypes.object,
    className: PropTypes.string,
    companyInfo: PropTypes.node,
    bottomBar: PropTypes.node,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      company,
      bookmarking,
      bookmarkCompany,
      latestPost,
      companyInfo,
      className,
      bottomBar,
    } = this.props;
    return (
      <Row className={classNames('company-header d-flex', className)}>
        <Col xs={12} md={6} lg={7}>
          <Grid fluid>
            <Row className="company-detail d-flex align-center">
              <Col xs={12} className="company-title">
                {company.title}
              </Col>
              <Bookmark
                bookmarked={company.bookmarks.length}
                bookmarking={bookmarking}
                onClick={bookmarkCompany}
              />
            </Row>
            {companyInfo ? companyInfo : null}
          </Grid>
        </Col>
        <Col xs={12} md={6} lg={5}>
          {company.buy_type !== Buys.NO_RECOMMENDATION ? (
            company.video_url ? (
              <Html className="video-container" content={company.vimeo_data.embed} />
            ) : latestPost && latestPost.investment_thesis ? (
              <div>
                <h1 className="investment-thesis">
                  <FormattedMessage id="ANALYST.INVESTMENT_THESIS" />
                </h1>
                <Html className="text-editor-view" content={latestPost.investment_thesis} />
              </div>
            ) : null
          ) : null}
        </Col>
        {bottomBar ? bottomBar : null}
      </Row>
    );
  }
}

export default CompanyDetailHeader;
