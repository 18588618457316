import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { Status, Methods } from './http';
import Env from 'env';
import { setRefreshToken, setToken } from 'common';
export { Status, Methods } from './http';

export const requestCreator = axios.create({});

export interface RequestConfig extends AxiosRequestConfig {
  resource?: string;
}

let refreshPromise: Promise<void> | null = null;

const request = async <T = void>({
  resource = '/',
  method = Methods.GET,
  data,
  params,
}: RequestConfig = {}) => {
  const url = `${Env.API_URL}${resource}`;
  const headers = {};
  const token = Cookies.get(Env.AUTH_TOKEN_ID);

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const refresh = async () => {
    return await axios.request({
      method: Methods.POST,
      url: `${Env.API_URL}auth/refresh`,
      data: {
        refresh_token: Cookies.get(Env.REFRESH_TOKEN_ID),
      },
      headers,
    });
  };

  const refreshAndSetToken = async () => {
    const newToken = await refresh();
    setToken(newToken.data);
    setRefreshToken(newToken.data);
    headers['Authorization'] = `Bearer ${newToken.data.access_token}`;
  };

  const ignoreUrls = ['/refresh'];

  requestCreator.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      let originalRequest;
      if (!ignoreUrls.some((ignoreUrl) => error.config.url.includes(ignoreUrl))) {
        originalRequest = error.config;
      }
      if (error.response && error.config) {
        if (
          error.response.status === Status.UNAUTHORIZIED &&
          !ignoreUrls.some((ignoreUrl) => error.config.url.includes(ignoreUrl)) &&
          !originalRequest._retry &&
          !!Cookies.get(Env.REFRESH_TOKEN_ID)
        ) {
          originalRequest._retry = true;

          try {
            if (!refreshPromise) {
              refreshPromise = refreshAndSetToken();
            }

            await refreshPromise;

            refreshPromise = null;

            return await requestCreator.request({
              ...originalRequest,
              headers: { ...originalRequest.headers, ...headers },
            });
          } catch (e) {
            return Promise.reject(e);
          }
        }
      }

      return Promise.reject(error);
    }
  );

  const { data: response } = await requestCreator.request<T>({
    method,
    url,
    data,
    headers,
    params,
  });

  return response;
};

export default request;
