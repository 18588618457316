import Analytics from '../components/analytics/analytics/Analytics';
import System from '../components/system/System';
import Content from '../components/content/Content';
import WebsiteStructure from '../components/website-structure/WebsiteStructure';
import Metadata from '../components/metadata/Metadata';
import PublicWebsite from '../components/public-website/PublicWebsite';
import Compliance from '../components/compliance/Compliance';

import contentRoutes from 'modules/admin/main/routes/content';
import websiteStructureRoutes from 'modules/admin/main/routes/website-structure';
import metadataRoutes from 'modules/admin/main/routes/metadata';
import publicWebsiteRoutes from 'modules/admin/main/routes/public-website';
import complianceRoutes from 'modules/admin/main/routes/compliance';
import systemRoutes from 'modules/admin/main/routes/system.jsx';
import analyticsRoutes from 'modules/admin/main/routes/analytics.jsx';

const homeRoutes = [
  {
    path: '/admin/analytics',
    name: 'Analytics',
    component: Analytics,
    key: 'analytics-',
    subRoutes: analyticsRoutes,
  },
  {
    path: '/admin/system',
    name: 'System',
    component: System,
    key: 'system-',
    subRoutes: systemRoutes,
  },
  {
    path: '/admin/content',
    name: 'Content Pages',
    component: Content,
    key: 'content-',
    subRoutes: contentRoutes,
  },
  {
    path: '/admin/website-structure',
    name: 'Website Structure',
    component: WebsiteStructure,
    key: 'website-structure-',
    subRoutes: websiteStructureRoutes,
  },
  {
    path: '/admin/metadata',
    name: 'Metadata',
    component: Metadata,
    key: 'metadata-',
    subRoutes: metadataRoutes,
  },
  {
    path: '/admin/public-website',
    name: 'Public Website',
    component: PublicWebsite,
    key: 'public-website-',
    subRoutes: publicWebsiteRoutes,
  },
  {
    path: '/admin/compliance',
    name: 'Compliance',
    component: Compliance,
    key: 'compliance-',
    subRoutes: complianceRoutes,
  },
  { redirect: true, from: '/admin', to: '/admin/content/posts', key: 'redirect' },
];

export default homeRoutes;
