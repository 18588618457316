import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { match } from 'common';
import SettingsList from './SettingsList';
import SettingsEditor from './SettingsEditor';

class Settings extends Component {
  static propTypes = {
    match,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="admin-table">
        <Switch>
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/`}
            render={(props) => <SettingsList {...props} />}
          />
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/new`}
            render={(props) => <SettingsEditor {...props} />}
          />
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/:id/edit`}
            render={(props) => <SettingsEditor {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default Settings;
