import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import { Row, Col, Grid, Carousel } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Player from '@vimeo/player';

import { Button, Html, ContactModal } from 'modules/shared';
import { location } from 'common';
import Env from 'env';

import style from './Videos.scss';

class Videos extends React.Component {
  static componentType = 'videos';
  static propTypes = {
    data: PropTypes.object.isRequired,
    handleShow: PropTypes.func,
    hideGrid: PropTypes.bool,
    location,
  };

  static defaultProps = {
    md: 12,
  };

  videoRefs = [];
  state = {
    prevActive: 0,
  };

  onSelect = (activeIndex) => {
    if (
      this.videoRefs[this.state.prevActive] &&
      this.videoRefs[this.state.prevActive].children &&
      this.videoRefs[this.state.prevActive].children[0]
    ) {
      const player = new Player(this.videoRefs[this.state.prevActive].children[0]);
      player.pause();

      this.setState({ prevActive: activeIndex });
    }
  };

  /**
   * @returns {JSX.Element} carousel content
   **/
  getCarouselContent() {
    return (
      <Row
        className={classNames('videos', {
          edge: this.props.location && this.props.location.pathname.includes('edge'),
        })}>
        <Col sm={12}>
          {this.props.data.videos && !!this.props.data.videos.length ? (
            <ContactModal
              information={<FormattedMessage id="MAIN.CONTACT_MODAL.INFORMATION" />}
              containerProtectWithContactModal={this.props.data.protectWithContactModal}>
              <Carousel
                interval={null}
                controls={false}
                indicators={this.props.data.showCarousel}
                onSelect={this.onSelect}>
                {this.props.data.videos.map((video, index) => (
                  <Carousel.Item key={video.videoUrl}>
                    <ContactModal
                      information={<FormattedMessage id="MAIN.CONTACT_MODAL.INFORMATION" />}
                      containerProtectWithContactModal={this.props.data.protectWithContactModal}
                      protectWithContactModal={video.protectWithContactModal}>
                      {video.videoEmbed ? (
                        <div
                          className="video"
                          itemProp="video"
                          itemScope
                          itemType="http://schema.org/VideoObject">
                          <h2 className="d-none">
                            <FormattedMessage id="MAIN.VIDEO_LABEL" />
                            <span itemProp="name"> {video.metadata && video.metadata.name}</span>
                          </h2>
                          <meta
                            itemProp="uploadDate"
                            content={video.metadata && video.metadata.release_time}
                          />
                          <meta
                            itemProp="thumbnailURL"
                            content={
                              video.thumbnails &&
                              video.thumbnails.length &&
                              video.thumbnails[0].link
                            }
                          />
                          <meta itemProp="embedURL" content={video.videoUrl} />
                          <div
                            id="schema-videoobject"
                            ref={(ref) => {
                              if (this.videoRefs.length > index) {
                                this.videoRefs[index] = ref;
                              } else {
                                this.videoRefs.push(ref);
                              }
                            }}
                            className="video-container"
                            dangerouslySetInnerHTML={{ __html: video.videoEmbed }}
                          />
                          <span itemProp="description" className="d-none">
                            {video.metadata && video.metadata.description}
                          </span>
                        </div>
                      ) : null}
                      {(video.link && video.linkTitle) || video.text ? (
                        <Carousel.Caption
                          className={classNames({
                            'show-carousel': this.props.data.showCarousel,
                          })}>
                          {video.link && video.linkTitle ? (
                            <div className="link">
                              <FormattedMessage id="EDGE.SIGN_UP_NOW">
                                {(title) =>
                                  Env.EDGE_FREE_TRIAL_UNDER_CONSTRUCTION &&
                                  video.linkTitle === title ? (
                                    <Button onClick={this.props.handleShow}>
                                      {video.linkTitle}
                                    </Button>
                                  ) : (
                                    <NavLink to={video.link}>
                                      <Button>{video.linkTitle}</Button>
                                    </NavLink>
                                  )
                                }
                              </FormattedMessage>
                            </div>
                          ) : null}
                          {video.text ? (
                            <Html className="text-editor-view" content={video.text} />
                          ) : null}
                        </Carousel.Caption>
                      ) : null}
                    </ContactModal>
                  </Carousel.Item>
                ))}
              </Carousel>
            </ContactModal>
          ) : null}
        </Col>
      </Row>
    );
  }

  /**
   * @returns {JSX.Element} Videos component.
   */
  render() {
    return this.props.hideGrid ? (
      this.getCarouselContent()
    ) : (
      <Grid> {this.getCarouselContent()}</Grid>
    );
  }
}

export default withStyles(style)(Videos);
