import moment from 'moment';

/**
 * @param {number} percent Percentage.
 *
 * @returns {string|number} Formatted percentage.
 */
export const formatPercent = (percent) => {
  if (!percent && percent !== 0) {
    return '';
  }

  const rounded = Math.round(percent);

  return rounded <= 0 ? `${rounded}%` : `+${rounded}%`;
};

/**
 * @param {Date} date Date to format.
 * @param {string} format Format specifier.
 * @param {string | boolean} timeFormat Time format specifier.
 *
 * @returns {string} Formatted string.
 */
export const formatDate = (date, { format = 'DD MMM YYYY', timeFormat } = {}) => {
  timeFormat = typeof timeFormat === 'boolean' ? 'LT' : timeFormat;
  return date
    ? moment(date)
        .format(`${format}${timeFormat ? ` ${timeFormat}` : ''}`)
        .toUpperCase()
    : '';
};

/**
 * @param {string|null} firstname Users first name
 * @param {string|null} lastname Users last name
 * @returns {string}
 */
export const formatUsername = ({ firstname, lastname } = {}) => {
  firstname = firstname ? firstname : '';
  lastname = lastname ? lastname : '';
  return `${firstname} ${lastname}`.trim() || '';
};
