import { GET_VIMEO_DATA } from '../actions/vimeo';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const vimeo = (state = [], action) => {
  switch (action.type) {
    case GET_VIMEO_DATA: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default vimeo;
