import { connect } from 'react-redux';

import ModelPortfolioHistory from './ModelPortfolioHistory';

const mapStateToProps = ({ app }) => {
  return {
    systemSettings: app.systemSettings.all,
  };
};

export default connect(mapStateToProps)(ModelPortfolioHistory);
