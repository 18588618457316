import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './SubscriptionPricing.scss';
import Env from 'env';
import { Button } from 'modules/shared';
import { SignUpModal, LaunchToast } from 'modules/edge/modules/shared';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

/**
 * @returns {JSX.Element} - ContentSubTitle component
 */
class SubscriptionPricing extends Component {
  static componentType = 'subscription-pricing';
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    isExpand: false,
  };

  /**
   * @returns {number} col value of layout
   */
  getColValue() {
    return Math.max(Env.BOOTSTRAP_MAX_WIDTH / this.props.data.subscriptions.length, 2);
  }

  /**
   * @description expand/collapse page
   * */
  expand = () => {
    this.setState((prevState) => {
      return { isExpand: !prevState.isExpand };
    });
  };

  /**
   * @description open sign up modal
   * */
  handleShow = () => {
    this.signUpModalRef.handleShow();
  };

  /**
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    return (
      <div
        className="subscription-pricing"
        style={{ backgroundImage: `url(${this.props.data.image})` }}>
        <SignUpModal backdropClassName="edge" onRef={(r) => (this.signUpModalRef = r)} />
        <LaunchToast textClick={this.handleShow} />
        <Grid className="pricing-content">
          {this.props.data.title ? (
            <Row className="subscription-pricing-label">
              <Col sm={12}>
                <h1>{this.props.data.title}</h1>
                <h4 className="pricing-sub-title">{this.props.data.subTitle}</h4>
              </Col>
            </Row>
          ) : null}

          <Row className="d-flex flex-wrap mt-5">
            {this.props.data.subscriptions
              ? this.props.data.subscriptions.map((data, index) => {
                  return (
                    <Col
                      key={index}
                      className="d-flex justify-center mb-3 subscription-tile-container"
                      xs={12}
                      sm={6}
                      md={this.getColValue()}>
                      <div>
                        <div className={classNames('subscription-tile', data.style)}>
                          <div className="corner-ribbon">
                            <FormattedMessage id="EDGE.PRICING.BEST_VALUE" />
                          </div>
                          <div className="subscription-title">{data.title}</div>
                          <div className="subscription-content">
                            {data.descriptionTitle ? <h4>{data.descriptionTitle}</h4> : null}
                            <div className="subscription-description">{data.description}</div>

                            {data.detailLabel ? (
                              <h5 className="subscription-detail-label">{data.detailLabel}</h5>
                            ) : null}
                            <h3
                              className={classNames('subscription-detail-title', {
                                'has-label': data.detailLabel,
                              })}>
                              {data.detailTitle}
                              <small>{data.detailTitleExtend}</small>
                            </h3>
                            {data.subscriptionLink ? (
                              <NavLink to={data.subscriptionLink}>
                                <Button className="white-bg" moduleType={data.style}>
                                  <span>{data.subscriptionLinkTitle}</span>
                                </Button>
                              </NavLink>
                            ) : (
                              <Button
                                className="white-bg"
                                moduleType={data.style}
                                onClick={this.handleShow}>
                                <span>{data.subscriptionLinkTitle}</span>
                              </Button>
                            )}
                            <div className="subscription-detail">{data.subscriptionLinkDetail}</div>

                            <div
                              className={classNames('expandable-content', {
                                expand: this.state.isExpand,
                              })}>
                              <div className="subscription-options-title">
                                {data.subscriptionOptionsTitle}
                                <strong> {data.subscriptionOptionsTitleExtend}</strong>
                              </div>

                              <div className="subscription-options-content">
                                {data.subscriptionOptionsList
                                  ? data.subscriptionOptionsList.map((option, optionIndex) => {
                                      return (
                                        <Row key={optionIndex} className="subscription-options">
                                          {option.price ? (
                                            <div>
                                              <Col xs={7} sm={7}>
                                                {option.label}
                                              </Col>
                                              <Col xs={5} sm={5} className="price">
                                                {option.price}
                                              </Col>
                                            </div>
                                          ) : null}
                                          {!option.price ? (
                                            <div key={optionIndex}>
                                              <Col
                                                xs={12}
                                                sm={12}
                                                className="d-flex justify-center">
                                                {option.label}
                                              </Col>
                                            </div>
                                          ) : null}
                                        </Row>
                                      );
                                    })
                                  : null}
                              </div>

                              <div className="subscription-options-detail">
                                {data.subscriptionOptionsDetail}
                              </div>

                              <div className="subscription-read-more">
                                <NavLink to={`/${Env.EDGE_BASE_PATH}/faq`}>
                                  <span>{data.subscriptionReadMore}</span>
                                </NavLink>
                              </div>

                              <div className="subscription-policy-link mb-3">
                                <NavLink to={`/${Env.EDGE_BASE_PATH}/subscription-cancellation`}>
                                  <FormattedMessage id="EDGE.PRICING.LINK_SUBSCRIPTION_POLICY" />
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
              : null}
          </Row>

          <Row className="mt-4 mb-4">
            <Col sm={12} className="d-flex justify-center">
              <Button className="expand-btn" moduleType="edge" onClick={this.expand}>
                <FormattedMessage id="EDGE.PRICING.LEARN_MORE_OPTIONS" />
                {this.state.isExpand ? <i className="fas fa-sort-up" /> : null}
                {!this.state.isExpand ? <i className="fas fa-sort-down" /> : null}
              </Button>
            </Col>
          </Row>
        </Grid>

        <div className="subscription-pricing-bottom-margin" />
      </div>
    );
  }
}

export default withStyles(style)(SubscriptionPricing);
