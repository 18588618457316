import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './TextInput.scss';

class TextInput extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
    className: PropTypes.string,
    intl: PropTypes.object,
    inlineError: PropTypes.bool,
    required: PropTypes.bool,
    white: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
  };

  /**
   * @description Gets the placeholder from translation or from raw text as fallback.
   *
   * @returns {string}
   */
  getPlaceholder() {
    const { placeholder, placeholderId, intl } = this.props;
    return placeholderId ? intl.formatMessage({ id: placeholderId }) : placeholder || null;
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      label,
      touched,
      error,
      placeholder,
      placeholderId,
      intl,
      className,
      inlineError,
      required,
      white,
      ...props
    } = this.props;
    return (
      <FormGroup
        className={classNames('text-field', className, { error: touched && error, white })}>
        {label && (
          <ControlLabel>
            {label} {required && '*'}
          </ControlLabel>
        )}
        <FormControl placeholder={this.getPlaceholder()} {...props} />
        {inlineError ? (
          <div className="error-message">{touched && error ? error : null}</div>
        ) : null}
      </FormGroup>
    );
  }
}

export default withStyles(style)(injectIntl(TextInput));
