import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Grid } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';

import style from './TextEditorView.scss';
import { Html, LightBox } from '../index';

class TextEditorView extends React.Component {
  static componentType = 'text-editor';
  static propTypes = {
    data: PropTypes.object.isRequired,
    fluid: PropTypes.bool,
    width: PropTypes.number,
    gridClass: PropTypes.string,
    breakWord: PropTypes.bool,
  };

  static defaultProps = {
    width: 12,
  };

  /**
   * @description Wraps tables to be able to scroll horizontally on mobile
   * @param {string} text HTML from CMS
   * @returns {string}
   */
  static wrapTables(text) {
    return text
      ? text.replace(/(<table.*>[.\s\S]*<\/table>)/gi, `<div class="table-wrapper">$1</div>`)
      : text;
  }

  state = {
    renewLink: null,
  };

  /**
   * @description detect renew href to get onClick event and show cookie renew form
   * */
  componentDidMount() {
    const renewLink = global.window.document.querySelectorAll(
      "a[href='manage-cookiebot-preferences']"
    );

    if (renewLink && renewLink[0]) {
      this.setState({ renewLink: renewLink[0] }, () => {
        this.state.renewLink.addEventListener('click', (e) => {
          e.preventDefault();
          global.window.Cookiebot.renew();
        });
      });
    }
  }

  /**
   * @description remove renew event listener
   * */
  componentWillUnmount() {
    if (this.state.renewLink) {
      this.state.renewLink.removeEventListener('click', () => {});
    }
  }

  /**
   * @returns {JSX.Element} TextEditorView component.
   */
  render() {
    return (
      <div className="text-editor-view-container">
        <Grid
          fluid={this.props.fluid}
          className={classNames(this.props.gridClass, { 'break-word': this.props.breakWord })}>
          <Row>
            <Html
              component={Col}
              sm={this.props.width}
              className="text-editor-view"
              content={TextEditorView.wrapTables(this.props.data.text)}
            />
            {global.window ? <LightBox containerClassName="text-editor-view" /> : null}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(TextEditorView);
