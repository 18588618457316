import $ from 'jquery';

/**
 * @description Prevents opening the context menu
 * @param {Event} e Event object
 */
export const handleDisableContextMenuEvent = (e) => {
  e.preventDefault();
};

/**
 * @description Adds event handler to disable the context menu on images in the given container
 * @param {string|Object} selector Selector string or ref to define the container in which the context menu is disabled for the images
 */
export const disableContextMenuOnImg = (selector) => {
  if (selector) {
    $(selector).on('contextmenu', 'img', handleDisableContextMenuEvent);
  }
};
