import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import CompanyCardRow from './CompanyCardRow';
import styles from './CompaniesCard.scss';

class CompaniesCard extends PureComponent {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    isBookmarked: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    basePath: PropTypes.string.isRequired,
    subPath: PropTypes.string,
    bookmark: PropTypes.func.isRequired,
    emptyMessage: PropTypes.node,
    rounded: PropTypes.bool,
    isHighlighted: PropTypes.func,
  };

  static defaultProps = {
    companies: [],
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className={classNames('companies-card', { rounded: this.props.rounded })}>
        <div className="header">{this.props.title}</div>
        <div className="content">
          {this.props.companies.length ? (
            <ul className="company-list">
              {this.props.companies.map((company) => {
                return (
                  <CompanyCardRow
                    key={company.id}
                    basePath={this.props.basePath}
                    bookmark={this.props.bookmark}
                    subPath={this.props.subPath}
                    isBookmarked={this.props.isBookmarked(company)}
                    highlighted={this.props.isHighlighted && this.props.isHighlighted(company)}
                    company={company}
                  />
                );
              })}
            </ul>
          ) : null}
          {!this.props.companies.length && this.props.emptyMessage ? this.props.emptyMessage : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CompaniesCard);
