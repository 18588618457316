import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import SurveyQuestions from 'modules/admin/main/components/public-website/surveys/SurveyQuestions/SurveyQuestions';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getSurvey, saveSurvey, editSurvey } from 'modules/analyst/actions/surveys';
import {
  match,
  history,
  textSchema,
  dateSchema,
  validateSchema,
  numberSchema,
  QuestionTypes,
  getDSTDate,
  setDSTDateString,
} from 'common';
import { Button, EditorTitle, FieldGroup } from 'modules/admin/shared';
import { DateTimePicker } from 'modules/shared';
import Env from 'env';
import { arrayMove } from 'react-sortable-hoc';

const questionsSchema = Yup.array().of(
  Yup.object().shape({
    ...textSchema('question', { required: true }),
    ...numberSchema('type', { required: true }),
    ...numberSchema('min_result', { required: true }),
    ...numberSchema('max_result', { required: true }),
    ...textSchema('min_label'),
    ...textSchema('max_label'),
  })
);

class SurveysEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getSurvey: PropTypes.func.isRequired,
    saveSurvey: PropTypes.func.isRequired,
    editSurvey: PropTypes.func.isRequired,
    basePath: PropTypes.string,
    match: match.isRequired,
    history: history.isRequired,
  };

  edit = !!this.props.match.params.id;
  state = {
    survey: {
      name: '',
      description: '',
      is_active: false,
      published_at: null,
      questions: [],
    },
    active: false,
    errors: {},
    isSubmitting: false,
    loading: true,
    isValid: false,
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();

    if (this.edit) {
      const survey = await this.props.getSurvey(this.props.match.params.id);
      const state = {
        loading: false,
        survey: {
          ...survey,
          published_at: getDSTDate(survey.published_at),
        },
        active: survey.is_active,
        isValid: await this.isValidForm(),
      };
      this.setState(state);
    } else {
      this.setState({ loading: false });
    }
    this.props.hideLoader();
  }

  isPublishDisabled = () => {
    return !this.state.isValid || !this.state.survey.id || this.state.survey.is_active === true;
  };

  /**
   * @description save user changes
   */
  save = async () => {
    this.setState({ isSubmitting: true });

    const survey = { ...this.state.survey };
    survey.published_at = survey.published_at ? setDSTDateString(survey.published_at) : null;

    try {
      if (survey.id) {
        const { published_at } = await this.props.editSurvey(survey);
        this.setState({
          survey: {
            ...this.state.survey,
            published_at: getDSTDate(published_at),
          },
        });
      } else {
        const { position, ...saveData } = survey;
        const res = await this.props.saveSurvey(saveData);
        this.setState({
          survey: {
            ...this.state.survey,
            id: res.id,
            published_at: getDSTDate(res.published_at),
          },
        });
        this.props.history.push(`/admin/analyst/surveys/${res.id}/edit`);
      }
      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  /**
   * @returns {boolean} editor is valid.
   */
  isValidForm = async () => {
    const schema = Yup.object().shape({
      ...textSchema('name', { required: true }),
      ...textSchema('description', { max: Env.MAX_TEXT_EDITOR_LENGTH }),
      ...dateSchema('published_at', { required: !this.state.survey.is_active }),
      questions: questionsSchema,
    });

    return await schema.isValid(this.state.survey);
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    const type = e.target.type;

    this.setState(
      {
        survey: {
          ...this.state.survey,
          [name]: type === 'checkbox' ? checked : value,
          published_at: name === 'is_active' && checked ? null : this.state.survey.published_at,
        },
      },
      () => this.validateForm(name, value, checked, type)
    );
  };

  addRow = () => {
    this.setState((prevState) => ({
      survey: {
        ...prevState.survey,
        questions: [
          ...prevState.survey.questions,
          {
            id: null,
            question: '',
            type: QuestionTypes.radioButtons,
            min_result: 1,
            max_result: 10,
            min_label: 'Low',
            max_label: 'High',
          },
        ],
      },
    }));
  };

  deleteRow = (index) => {
    this.setState((prevState) => ({
      survey: {
        ...prevState.survey,
        questions: prevState.survey.questions.filter(
          (question, questionIndex) => questionIndex !== index
        ),
      },
    }));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState((prevState) => ({
      survey: {
        ...prevState.survey,
        questions: arrayMove(prevState.survey.questions, oldIndex, newIndex).map((question, i) => ({
          ...question,
          position: i + 1,
        })),
      },
    }));
  };

  onChangeQuestions = (result) => {
    result.event.persist();

    const name = result.event.target.name;
    const value = result.event.target.value;
    const questions = [...this.state.survey.questions];

    const question = { ...questions[result.index] };
    if (name === 'type' && value.value === QuestionTypes.freeText) {
      question.min_result = 1;
      question.max_result = 1;
    } else if (name === 'type' && value.value === QuestionTypes.radioButtons) {
      question.min_result = '';
      question.max_result = '';
    }

    questions[result.index] = {
      ...question,
      [name]:
        name === 'type'
          ? value.value
          : name === 'min_result' || name === 'max_result'
          ? +value
          : value,
    };

    this.setState(
      {
        survey: {
          ...this.state.survey,
          questions,
        },
      },
      () => this.validateForm(name, value, null, null, result.index)
    );
  };

  /**
   * @param {string} name of survey key
   * @param {Object} value of survey key
   * @param {boolean} checked of survey key
   * @param {string} type of key
   * @param {number} index of question
   * @description check is form valid
   * */
  validateForm = async (name, value, checked, type, index) => {
    const errors = { ...this.state.errors };
    let isValidForm = { ...this.state.isValid };
    const hasIndex = typeof index !== 'undefined';

    this.setState({ isValid: isValidForm, errors });

    isValidForm = await this.isValidForm();
    try {
      const errorFieldName = hasIndex ? `questions[${index}].${name}` : name;
      await validateSchema(this.getValidationSchemaByField(errorFieldName), {
        [errorFieldName]: type === 'checkbox' ? checked : name === 'type' ? value.value : value,
      });
      errors[errorFieldName] = '';
    } catch (validationError) {
      const errorMessage = hasIndex
        ? this.transformQuestionsError(validationError.errors && validationError.errors[0])
        : validationError.errors && validationError.errors[0];
      errors[validationError.path] = errorMessage;
    }

    this.setState({ isValid: isValidForm, errors });
  };

  /**
   * @param {Date} date published_at date
   * @description set published_at property
   * */
  onChangePublishedDate = (date) => {
    this.setState(
      {
        survey: {
          ...this.state.survey,
          published_at: date,
        },
      },
      () => this.validateForm('published_at', date)
    );
  };

  /**
   * @param {string} data value
   * @returns {string} transform questions field error key/message
   * */
  transformQuestionsError = (data) => {
    return data.includes('questions') ? data.split('].')[1] : data;
  };

  /**
   * @param {string} field name of object
   *
   * @returns {Function} return schema by field
   * */
  getValidationSchemaByField = (field) => {
    const transformedField = this.transformQuestionsError(field);
    switch (transformedField) {
      case 'published_at': {
        return dateSchema(field, { required: !this.state.survey.is_active });
      }
      case 'question': {
        return textSchema(field, { required: true });
      }
      case 'type': {
        return numberSchema(field, { required: true });
      }
      case 'min_result': {
        return numberSchema(field, { required: true });
      }
      case 'max_result': {
        return numberSchema(field, { required: true });
      }
      case 'description': {
        return textSchema(field, { max: Env.MAX_TEXT_EDITOR_LENGTH });
      }
      default: {
        return textSchema(field, { required: true });
      }
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        {!this.state.loading ? (
          <form noValidate>
            <EditorTitle
              title={
                typeof this.state.survey.id !== 'undefined' ? (
                  <FormattedMessage id="ADMIN.SURVEYS.EDIT_SURVEY" />
                ) : (
                  <FormattedMessage id="ADMIN.SURVEYS.NEW_SURVEY" />
                )
              }>
              <div className="d-inline-flex">
                <Button
                  loading={this.state.saving}
                  type="button"
                  onClick={async (e) => {
                    this.setState({ saving: true });
                    try {
                      await this.save(e);
                    } finally {
                      this.setState({ saving: false });
                    }
                  }}
                  disabled={!this.state.isValid || this.state.active}>
                  <FormattedMessage id="ADMIN.SAVE" />
                </Button>
              </div>
            </EditorTitle>
            <Grid fluid className="editor-content">
              <Row>
                <Col xs={12}>
                  <FieldGroup
                    name="name"
                    placeholderId="ADMIN.NAME"
                    label={<FormattedMessage id="ADMIN.NAME" />}
                    error={this.state.errors.name}
                    value={this.state.survey.name}
                    required
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs={12}>
                  <FieldGroup
                    componentClass="textarea"
                    placeholderId="ADMIN.DESCRIPTION"
                    label={<FormattedMessage id="ADMIN.DESCRIPTION" />}
                    name="description"
                    error={this.state.errors.description}
                    value={this.state.survey.description}
                    onChange={this.onChange}
                  />
                </Col>

                <Col xs={12}>
                  <ControlLabel>
                    <FormattedMessage id="ADMIN.POSTS.PUBLISHED_AT" />
                  </ControlLabel>
                  <DateTimePicker
                    selected={this.state.survey.published_at}
                    onChange={this.onChangePublishedDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={Env.PUBLISH_DATE_TIME_INTERVAL}
                    dateFormat="LLL"
                    timeCaption="time"
                    disabled={this.state.survey.is_active}
                    minDate={new Date()}
                  />
                </Col>

                <Col xs={12}>
                  <FieldGroup
                    className="checkbox-form-group"
                    label={<FormattedMessage id="ADMIN.SURVEYS.IS_ACTIVE" />}
                    inline
                    type="checkbox"
                    name="is_active"
                    checked={this.state.survey.is_active}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
            </Grid>
            <SurveyQuestions
              onChange={this.onChangeQuestions}
              addRow={this.addRow}
              deleteRow={this.deleteRow}
              onSortEnd={this.onSortEnd}
              questions={this.state.survey.questions}
              errors={this.state.errors}
            />
          </form>
        ) : null}
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getSurvey,
      saveSurvey,
      editSurvey,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SurveysEditor);
