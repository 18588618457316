import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Col, Row } from 'react-bootstrap';
import { FieldGroup } from 'modules/admin/shared';
import styles from './FileUpload.scss';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Env from 'env';
import { Spinner } from 'modules/shared';
import { Delete } from 'modules/admin/shared';
import { getFileIcon, isFileImage } from 'common';
import { FormattedMessage } from 'react-intl';
import { deleteFile as deleteFileApi } from 'modules/admin/main/actions/file-upload';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class FileUpload extends Component {
  static propTypes = {
    file: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    fileId: PropTypes.number,
    fileTitle: PropTypes.string,
    fileAlt: PropTypes.string,
    onChange: PropTypes.func,
    onDropFile: PropTypes.func.isRequired,
    deleteFileApi: PropTypes.func.isRequired,
    deleteFile: PropTypes.func,
    className: PropTypes.string,
    error: PropTypes.object,
    accept: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    accept: 'image/jpeg, image/png',
  };

  state = {
    fileLimitError: false,
    fileTypeError: false,
  };

  hasImage = () => {
    return (
      (this.props.file && this.props.file.preview) || (this.props.file && this.props.file.length)
    );
  };

  deleteFile = async (file) => {
    if (this.props.fileId) {
      await this.props.deleteFileApi({
        media_id: this.props.fileId,
      });
    } else if (file && file.id) {
      await this.props.deleteFileApi({
        media_id: file.id,
      });
    }
    this.props.deleteFile();
  };

  hasFile = () => {
    return (
      this.props.file &&
      Object.keys(this.props.file).length > 0 &&
      !this.props.file.preview &&
      (this.props.file.file_name || !isFileImage(this.props.file))
    );
  };

  /**
   * @param {Object} file that user uploaded
   * @returns {boolean} check file size is it under limit
   * */
  isFileOfSizeOverLimit = (file) => {
    const fileLimitError = file ? file.size > Env.MAX_FILE_SIZE * 1024 * 1024 : false;
    this.setState({ fileLimitError });
    return fileLimitError;
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div
        className={classNames('image-preview-container', this.props.className, {
          'has-image': this.hasImage(),
          'has-file': this.hasFile(),
          error: this.props.error || this.state.fileLimitError || this.state.fileTypeError,
        })}>
        <Dropzone
          onDrop={(acceptedFiles, rejectedFiles) => {
            if (
              acceptedFiles &&
              acceptedFiles.length > 0 &&
              !this.isFileOfSizeOverLimit(acceptedFiles[0])
            ) {
              this.props.onDropFile(acceptedFiles, rejectedFiles);
            }
            this.setState({ fileTypeError: rejectedFiles && !!rejectedFiles.length });
          }}
          accept={this.props.accept}>
          {!this.props.file ||
          !Object.keys(this.props.file).length ||
          this.props.file.preview ||
          (this.props.file && (!this.props.file.file_name || isFileImage(this.props.file))) ? (
            <span className="fa fa-plus add-icon" />
          ) : null}
          {this.props.file && this.props.file.preview ? (
            <div
              className="image-preview"
              style={{ backgroundImage: `url(${this.props.file.preview})` }}
            />
          ) : null}
          {this.props.file && this.props.file.length && isFileImage(this.props.file) ? (
            <div
              className="image-preview"
              style={{
                backgroundImage: `url(${Env.S3_URL + escape(this.props.file)})`,
              }}
            />
          ) : null}
          {this.props.file && this.props.file.file_name ? (
            <i
              className={classNames(
                'file-type',
                getFileIcon({
                  mime_type: this.props.file.mime_type,
                  file_name: this.props.file.file_name,
                })
              )}
            />
          ) : null}
          {this.props.file &&
          !isFileImage(this.props.file) &&
          !this.props.file.file_name &&
          typeof this.props.file === 'string' &&
          !this.props.file.preview ? (
            <i
              className={classNames(
                'file-type',
                getFileIcon({
                  file_name: this.props.file,
                })
              )}
            />
          ) : null}
          {this.props.loading ? <Spinner size="small" /> : null}
        </Dropzone>
        {this.props.onChange ? (
          <Row className="mt-2">
            <Col xs={12} sm={12}>
              <FieldGroup
                name="fileTitle"
                placeholderId="ADMIN.IMAGE_TITLE"
                label={<FormattedMessage id="ADMIN.IMAGE_TITLE" />}
                value={this.props.fileTitle}
                onChange={this.props.onChange}
              />
            </Col>
            <Col xs={12} sm={12}>
              <FieldGroup
                name="fileAlt"
                placeholderId="ADMIN.IMAGE_ALT"
                label={<FormattedMessage id="ADMIN.IMAGE_ALT" />}
                value={this.props.fileAlt}
                onChange={this.props.onChange}
              />
            </Col>
          </Row>
        ) : null}
        {(this.hasImage() || this.hasFile()) && this.props.deleteFile ? (
          <Delete className="delete-image" onSubmit={() => this.deleteFile(this.props.file)} />
        ) : null}
        {this.props.file ? <small>{this.props.file.file_name}</small> : null}
        {this.state.fileLimitError ? (
          <div className="error-message">
            <FormattedMessage id="ADMIN.FILE_SIZE_ERROR" />
          </div>
        ) : null}
        {this.state.fileTypeError ? (
          <div className="error-message">
            <FormattedMessage id="ADMIN.FILE_TYPE_ERROR" values={{ types: this.props.accept }} />
          </div>
        ) : null}
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteFileApi,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(FileUpload));
