import request from 'modules/shared/util/request';
import { Methods } from 'modules/shared/util/http';
import qs from 'query-string';

const BASE_URL = 'companies';

/**
 * @description Get the list of available companies.
 * @param {Object} options Pagination options.
 * @param {boolean} options.nofilter No status filter
 * @param {boolean} options.nopaginate get all company without pagination
 * @param {string} options.search search string
 * @param {string} base baseUrl
 * @returns {Promise}
 */
export const getCompanies = async (
  { nofilter = true, nopaginate = true, search = '' } = {},
  base = BASE_URL
) => {
  let resource = base;
  resource += `?search=${search}`;
  resource += nofilter ? '&nofilter' : '';
  resource += nopaginate ? '&nopaginate' : '';
  return await request({ resource });
};

/**
 * @param {Object} options Pagination options.
 * @param {string} options.order Field to order the list
 * @param {string} options.search Search string
 * @param {boolean} options.desc {boolean} Descending order
 * @param {number} options.page {number} Page number
 * @param {string} base baseUrl
 * @returns {Promise<*>}
 */
export const getCompanyPage = async (
  { order = 'title', search = '', nofilter, desc, page, per_page },
  base = BASE_URL
) => {
  const orderDirection = desc ? 'desc' : 'asc';
  let requestResource = `${base}?order=${order}:${orderDirection}&page=${page}&search=${search}`;
  requestResource += per_page ? `&per_page=${per_page}` : '';
  requestResource += nofilter ? '&nofilter' : '';
  return await request({
    resource: requestResource,
  });
};

/**
 * @param {number} id Company ID.
 * @param {Object} options Pagination options.
 * @param {string} options.order Field to order the list
 * @param {string} options.search Search string
 * @param {boolean} options.desc {boolean} Descending order
 * @param {number} options.page {number} Page number
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const getCompanyPostPage = async (
  id,
  { order = '', search = '', desc, page } = {},
  base = BASE_URL
) => {
  const orderDirection = desc ? 'desc' : 'asc';
  const query = [];
  order && query.push(`order=${order}:${orderDirection}`);
  page && query.push(`page=${page}`);
  search && query.push(`search=${search}`);
  return await request({
    resource: `${base}/${id}/posts${query.length ? `?${query.join('&')}` : ''}`,
  });
};

/**
 * @param {Object} search Parsed query parameters.
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const getAZCompanies = async (search, base = BASE_URL) => {
  return await request({ resource: `${base}/az?${qs.stringify(search)}` });
};

/**
 * @param {number} id Company id
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const getCompany = async (id, base = BASE_URL) => {
  return await request({
    resource: `${base}/${id}`,
  });
};

/**
 * @param {Object} data Company object
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const saveCompany = async (data, base = BASE_URL) => {
  return await request({
    resource: `${base}`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Company id
 * @param {Object} data Company object
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const modifyCompany = async (id, data, base = BASE_URL) => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id Company id
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const deleteCompany = async (id, base = BASE_URL) => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.DELETE,
  });
};

/**
 * @param {number} id Company id
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const bookmarkCompany = async (id, base = BASE_URL) => {
  return await request({
    resource: `${base}/${id}/bookmark`,
    method: Methods.POST,
  });
};

/**
 * @param {number} id Company id
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const refreshPercent = async (id, base = BASE_URL) => {
  return await request({
    resource: `${base}/${id}/refreshstock`,
  });
};

/**
 * @param {number} id Company id
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const getRecommendationHistory = async (id, base = BASE_URL) => {
  return await request({
    resource: `${base}/${id}/recommendation_history`,
  });
};

/**
 * @param {number} id Company id
 * @param {string} base baseUrl
 *
 * @returns {Promise<*>}
 */
export const getCompanyStatistics = (id, base = BASE_URL) => {
  return request({
    resource: `${base}/${id}/statistics`,
  });
};

/**
 * @returns {Promise}
 */
export const getAdminCompanies = async () => {
  return request({
    resource: 'admin/list/companies',
  });
};

/**
 * @param {string} id company id
 *
 * @returns {Promise<*>}
 */
export const sendDropEmail = async (id) => {
  return await request({
    resource: `${BASE_URL}/${id}/drop-coverage`,
    method: Methods.POST,
  });
};

/**
 * @returns {Promise<*>}
 */
export const fetchCompanyCountries = () => {
  return request({
    resource: 'admin/list/countries',
  });
};
