import request from 'modules/shared/util/request';
import { Methods } from 'modules/shared/util/http';
import { AllResearchFilter, PostsQueryParams } from 'modules/shared/models';
import { getPaginationQueryParams } from 'common';

/**
 * @description Get the list of available posts.
 * @param {Object} param Pagination param
 * @param {string} base baseUrl
 * @returns {Promise}
 */
export const getPosts = async (
  { nopaginate = true, search = '', page = 1, per_page = 10 } = {},
  base = 'posts'
) => {
  const query = [];
  query.push(`search=${search}`);
  nopaginate && query.push('&nopaginate');
  page && query.push(`page=${page}`);
  per_page && query.push(`per_page=${per_page}`);

  return await request({ resource: `${base}${query.length ? `?${query.join('&')}` : ''}` });
};

export const getPostsPage = async (queryParams: PostsQueryParams, base = 'posts') =>
  request({
    resource: `${base}${getPaginationQueryParams(queryParams)}`,
  });

/**
 * @param {number} id Post ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const deletePost = async (id, base = 'posts') => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.DELETE,
  });
};

/**
 * @param {number} id Post ID
 * @param {number} mediaId download File ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const deleteDownloadFile = async (id, mediaId, base = 'posts') => {
  return await request({
    resource: `${base}/${id}/media/${mediaId}`,
    method: Methods.DELETE,
  });
};

/**
 * @param {number} id Post ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const getPost = async (id, base = 'posts') => {
  return request({
    resource: `${base}/${id}`,
  });
};

export const getAdminPost = async (id, base = 'admin/posts') => {
  return request({
    resource: `${base}/${id}`,
  });
};

/**
 * @param {Object} data post object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const savePost = async (data, base = 'posts') => {
  return await request({
    resource: `${base}`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Post ID
 * @param {Object} data post object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const saveFeaturedImage = async (id, data, base = 'posts') => {
  return await request({
    resource: `${base}/${id}/featured_image`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Post ID
 * @param {Object} data post object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const saveContentImage = async (id, data, base = 'posts') => {
  return await request({
    resource: `${base}/${id}/content`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Post ID
 * @param {Object} data Company object
 * @param {string} base baseUrl
 * @returns {Promise}
 */
export const modifyPost = async (id, data, base = 'posts') => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id Post ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const bookmarkpost = async (id, base = 'posts') => {
  return await request({ resource: `${base}/${id}/bookmark`, method: Methods.POST });
};

/**
 * @param {number} id Post ID
 * @param {string} base baseUrl
 * @returns {Promise<*>}
 */
export const getOtherVideos = async (id, base = 'posts') => {
  return await request({ resource: `${base}/${id}/othervideos` });
};

/**
 * @param {number} id Post ID
 * @param {string} base baseUrl
 * @returns {Promise<*>}
 */
export const getMoreIdeas = async (id, base = 'posts') => {
  return await request({ resource: `${base}/${id}/moreideas` });
};

/**
 * @param {Object} options filter and order options.
 * @param {string} base baseUrl
 * @returns {Promise<*>}
 */
export const exportPosts = async (
  { orderBy = '', desc = '', search = '' } = {},
  base = 'posts'
) => {
  const orderDirection = desc ? 'desc' : 'asc';
  const query = [];
  orderBy && query.push(`orderBy=${orderBy}`);
  orderDirection && query.push(`sort=${orderDirection}`);
  search && query.push(`search=${search}`);

  return await request({ resource: `${base}/export${query.length ? `?${query.join('&')}` : ''}` });
};

/**
 * @param {Object} data answer with question id, user vote id
 * @description answer question at post
 * @returns {Promise}
 */
export const answerQuestion = async (data) => {
  return await request({ method: Methods.POST, resource: `likes/post`, data });
};

export const fetchPostsFilters = () =>
  request<AllResearchFilter>({ resource: `settings/filter/allresearch` });
