import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AnalystArticleAnalytics from './analyst/AnalystArticleAnalytics';
import EdgeArticleAnalytics from './edge/EdgeArticleAnalytics';
import EdgePlusArticleAnalytics from './edge-plus/EdgePlusArticleAnalytics';

import { match, location } from 'common';
import { LiNavLink } from 'modules/shared/components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderSection from '../analytics/HeaderSection';

class ArticleAnalytics extends Component {
  static propTypes = {
    match,
    location,
    analytics: PropTypes.object,
  };

  /**
   * @returns {bool} is actual page list view
   * */
  isListView = () => {
    return this.props.location.pathname.includes('list');
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { summary = {} } = this.props.analytics;
    return (
      <React.Fragment>
        {this.isListView() ? <HeaderSection summary={summary} /> : null}
        <ul className="nav nav-tabs">
          <LiNavLink to={`${this.props.match.path}/analyst`}>
            <FormattedMessage id="MAIN.ANALYST" />
          </LiNavLink>
          <LiNavLink to={`${this.props.match.path}/edge`}>
            <FormattedMessage id="MAIN.EDGE" />
          </LiNavLink>
          <LiNavLink to={`${this.props.match.path}/edge-plus`}>
            <FormattedMessage id="MAIN.EDGE_PLUS" />
          </LiNavLink>
        </ul>
        <Switch>
          <Redirect
            exact
            from={`${this.props.match.path}/`}
            to={`${this.props.match.path}/analyst`}
          />
          <Route
            sensitive
            path={`${this.props.match.path}/analyst`}
            render={(props) => <AnalystArticleAnalytics {...props} />}
          />
          <Route
            sensitive
            path={`${this.props.match.path}/edge`}
            render={(props) => <EdgeArticleAnalytics {...props} />}
          />
          <Route
            sensitive
            path={`${this.props.match.path}/edge-plus`}
            render={(props) => <EdgePlusArticleAnalytics {...props} />}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ admin }) => {
  return {
    analytics: admin.analytics || {},
  };
};

export default connect(mapStateToProps)(ArticleAnalytics);
