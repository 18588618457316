import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CustomCheckbox extends Component {
  static propTypes = {
    isChecked: PropTypes.bool,
    number: PropTypes.string,
    label: PropTypes.string,
    inline: PropTypes.bool,
  };

  state = {
    is_checked: this.props.isChecked ? true : false,
  };

  handleClick = () => {
    this.setState({ is_checked: !this.state.is_checked });
  };

  /**
   * @returns {JSX.Element} - Checkbox component
   */
  render() {
    const { isChecked, number, label, inline, ...rest } = this.props;
    const classes = inline !== undefined ? 'checkbox checkbox-inline' : 'checkbox';
    return (
      <div className={classes}>
        <input
          id={number}
          type="checkbox"
          onChange={this.handleClick}
          checked={this.state.is_checked}
          {...rest}
        />
        <label htmlFor={number}>{label}</label>
      </div>
    );
  }
}

export default CustomCheckbox;
