import * as ModelPortfolioService from '../services/model-portfolio';

export const GET_MODEL_PORTFOLIOS = 'GET_MODEL_PORTFOLIOS';
export const GET_MODEL_PORTFOLIO = 'GET_MODEL_PORTFOLIO';
export const SAVE_MODEL_PORTFOLIO = 'SAVE_MODEL_PORTFOLIO';
export const MODIFY_MODEL_PORTFOLIO = 'MODIFY_MODEL_PORTFOLIO';
export const DELETE_MODEL_PORTFOLIO = 'DELETE_MODEL_PORTFOLIO';
export const GET_MODEL_PORTFOLIO_HISTORY = 'GET_MODEL_PORTFOLIO_HISTORY';

/**
 * @description Gets the available Model Portfolios.
 * @param {Object} options Query options.
 * @returns {Function}
 */
export const getModelPortfolios = (options) => async (dispatch) => {
  const modelPortfolios = await ModelPortfolioService.getModelPortfolios(options);

  dispatch({
    type: GET_MODEL_PORTFOLIOS,
    payload: modelPortfolios,
  });

  return modelPortfolios;
};

/**
 * @description Gets the model portfolio for the ID.
 * @param {number} id model portfolio ID.
 * @returns {Function}
 */
export const getModelPortfolio = (id) => async (dispatch) => {
  const modelPortfolio = await ModelPortfolioService.getModelPortfolio(id);

  dispatch({
    type: GET_MODEL_PORTFOLIO,
    payload: modelPortfolio,
  });

  return modelPortfolio;
};

/**
 * @description Gets the model portfolio history.

 * @returns {Function}
 */
export const getModelPortfolioHistory = () => async (dispatch) => {
  const modelPortfolioHistory = await ModelPortfolioService.getModelPortfolioHistory();

  dispatch({
    type: GET_MODEL_PORTFOLIO_HISTORY,
    payload: modelPortfolioHistory,
  });

  return modelPortfolioHistory;
};

/**
 * @param {Object} data ModelPortfolio data.
 * @returns {Function}
 */
export const saveModelPortfolio = (data) => async (dispatch) => {
  const modelPortfolio = await ModelPortfolioService.saveModelPortfolio(data);

  dispatch({
    type: SAVE_MODEL_PORTFOLIO,
    payload: modelPortfolio,
  });

  return modelPortfolio;
};

/**
 * @param {number} id model portfolio Id
 * @param {Object} data model portfolio data.
 * @returns {Function}
 */
export const modifyModelPortfolio = (id, data) => async (dispatch) => {
  const modelPortfolio = await ModelPortfolioService.modifyModelPortfolio(id, data);

  dispatch({
    type: MODIFY_MODEL_PORTFOLIO,
    payload: modelPortfolio,
  });

  return modelPortfolio;
};

/**
 * @param {number} id modelPortfolio Id
 * @returns {Function}
 */
export const deleteModelPortfolio = (id) => async (dispatch) => {
  const modelPortfolio = await ModelPortfolioService.deleteModelPortfolio(id);

  dispatch({
    type: DELETE_MODEL_PORTFOLIO,
    payload: modelPortfolio,
  });

  return modelPortfolio;
};
