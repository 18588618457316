import * as CompaniesService from 'modules/shared/services/companies';
import { getEdgePlusBookmarks } from 'modules/shared/actions/bookmarks';

export const GET_EDGE_PLUS_COMPANIES = 'GET_EDGE_PLUS_COMPANIES';
export const GET_EDGE_PLUS_COMPANIES_PAGE = 'GET_EDGE_PLUS_COMPANIES_PAGE';
export const GET_EDGE_PLUS_AZ_COMPANIES = 'GET_EDGE_PLUS_AZ_COMPANIES';
export const GET_EDGE_PLUS_COMPANY = 'GET_EDGE_PLUS_COMPANY';
export const SAVE_EDGE_PLUS_COMPANY = 'SAVE_EDGE_PLUS_COMPANY';
export const MODIFY_EDGE_PLUS_COMPANY = 'MODIFY_EDGE_PLUS_COMPANY';
export const DELETE_EDGE_PLUS_COMPANY = 'DELETE_EDGE_PLUS_COMPANY';
export const EDGE_PLUS_BOOKMARK_COMPANY = 'EDGE_PLUS_BOOKMARK_COMPANY';
export const GET_RECOMMENDATION_HISTORY = 'GET_RECOMMENDATION_HISTORY';

const base = 'edgeplus/companies';
/**
 * @description Gets the available companies.
 * @param {Object} options Query options.
 * @returns {Function}
 */
export const getCompanies = (options) => async (dispatch) => {
  const companies = await CompaniesService.getCompanies(options, base);

  dispatch({
    type: GET_EDGE_PLUS_COMPANIES,
    payload: companies,
  });

  return companies;
};

/**
 * @description Gets the available companies.
 * @param {Object} search Parsed query params.
 * @returns {Function}
 */
export const getAzCompanies = (search) => async (dispatch) => {
  const companies = await CompaniesService.getAZCompanies(search, base);

  dispatch({
    type: GET_EDGE_PLUS_AZ_COMPANIES,
    payload: companies,
  });

  return companies;
};

/**
 * @description Gets the company page for the given options.
 * @param {Object} options Get page options.
 * @returns {Function}
 */
export const getCompanyPage = (options) => async (dispatch) => {
  const page = await CompaniesService.getCompanyPage(options, base);

  dispatch({
    type: GET_EDGE_PLUS_COMPANIES_PAGE,
    payload: page,
  });

  return page;
};

/**
 * @description Gets the company for the ID.
 * @param {number} id Company ID.
 * @returns {Function}
 */
export const getCompany = (id) => async (dispatch) => {
  const company = await CompaniesService.getCompany(id, base);

  dispatch({
    type: GET_EDGE_PLUS_COMPANY,
    payload: company,
  });

  return company;
};

/**
 * @param {number} id Company Id.
 * @param {Object} options Paging options.
 *
 * @returns {Function}
 */
export const getCompanyPostPage = (id, options) => async () => {
  return await CompaniesService.getCompanyPostPage(id, options, base);
};

/**
 * @param {Object} data Company data.
 * @returns {Function}
 */
export const saveCompany = (data) => async (dispatch) => {
  const company = await CompaniesService.saveCompany(data, base);

  dispatch({
    type: SAVE_EDGE_PLUS_COMPANY,
    payload: company,
  });

  return company;
};

/**
 * @param {number} id Company Id
 * @param {Object} data Company data.
 * @returns {Function}
 */
export const modifyCompany = (id, data) => async (dispatch) => {
  const company = await CompaniesService.modifyCompany(id, data, base);

  dispatch({
    type: MODIFY_EDGE_PLUS_COMPANY,
    payload: company,
  });

  return company;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const deleteCompany = (id) => async (dispatch) => {
  const company = await CompaniesService.deleteCompany(id, base);

  dispatch({
    type: DELETE_EDGE_PLUS_COMPANY,
    payload: company,
  });

  return company;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const bookmarkCompany = (id) => async (dispatch) => {
  const bookmark = await CompaniesService.bookmarkCompany(id, base);
  await dispatch(getEdgePlusBookmarks());

  dispatch({
    type: EDGE_PLUS_BOOKMARK_COMPANY,
    payload: bookmark,
  });

  return bookmark;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const refreshPercent = (id) => async () => {
  return await CompaniesService.refreshPercent(id, base);
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const getRecommendationHistory = (id) => async (dispatch) => {
  const history = await CompaniesService.getRecommendationHistory(id, base);
  dispatch({
    type: GET_RECOMMENDATION_HISTORY,
    payload: history,
  });

  return history;
};
