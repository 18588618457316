import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Img, VideoModal } from 'modules/shared';
import styles from './VideoThumbnail.scss';

class VideoThumbnail extends Component {
  static propTypes = {
    video: PropTypes.object,
  };

  static defaultProps = {
    video: {},
  };

  state = {
    hover: {
      icon: false,
      overlay: false,
    },
    visible: false,
    showModal: false,
  };

  setHover = (type, value) => {
    const state = { hover: { icon: false, overlay: false, [type]: value }, visible: false };

    if (
      (!this.state.hover.icon && state.hover.icon) ||
      (this.state.hover.icon && state.hover.overlay)
    ) {
      state.visible = true;
    }

    this.setState(state);
  };

  /**
   * @description Determines if the element is overflowed on the right.
   * @returns {boolean}
   */
  isOverflowRight() {
    if (this.node) {
      const overlay = this.node.getElementsByClassName('thumbnail-overlay')[0];
      const icon = this.node.getElementsByClassName('video-thumbnail-icon')[0];
      const overlayRect = overlay.getBoundingClientRect();
      const iconRect = icon.getBoundingClientRect();

      return iconRect.left + overlayRect.width + 2 > window.innerWidth;
    }

    return false;
  }

  /**
   * @param {Event} e Event object
   */
  openVideoModal = (e) => {
    // TODO Uncomment if Video popup playing is needed.
    // e.stopPropagation();
    // e.preventDefault();
    //
    // this.setState({ showModal: true });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { video } = this.props;
    return (
      <Fragment>
        <span className="video-thumbnail" ref={(r) => (this.node = r)}>
          <i
            className="icon-video-outline video-thumbnail-icon"
            onMouseEnter={() => this.setHover('icon', true)}
            onMouseLeave={() => this.setHover('icon', false)}
            onClick={this.openVideoModal}
          />
          {video && video.thumbnails ? (
            <div
              ref={(r) => (this.overlay = r)}
              className={classNames('thumbnail-overlay', {
                visible: this.state.visible,
                'overflow-right': this.isOverflowRight(),
              })}
              onClick={this.state.visible ? this.openVideoModal : undefined}
              onMouseEnter={() => this.setHover('overlay', true)}
              onMouseLeave={() => this.setHover('overlay', false)}>
              <Img src={video.thumbnails[2].link} alt="" />
            </div>
          ) : null}
        </span>
        {video && video.thumbnails ? (
          <div>
            {/* TODO Uncomment if video popup playing is needed.
          <div onClick={(e) => e.stopPropagation()}>
          */}
            <VideoModal
              handleClose={this.handleClose}
              show={this.state.showModal}
              video={video.embed}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default withStyles(styles)(VideoThumbnail);
