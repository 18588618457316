import * as QuestionsService from 'modules/shared/services/questions';
import { Status } from 'modules/shared/util/http';

export const GET_QUESTIONS = 'GET_QUESTIONS';

/**
 * @description Get the list of available questions.
 * @returns {Function}
 */
export const getQuestions = () => async (dispatch) => {
  try {
    const questions = await QuestionsService.getQuestions();

    dispatch({
      type: GET_QUESTIONS,
      payload: questions,
    });

    return questions;
  } catch (e) {
    if (e.response && e.response.status === Status.NOT_FOUND) {
      e.ignoreToast = true;
    }
    throw e;
  }
};
