import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './VideoModal.scss';
import { Html, Modal } from 'modules/shared/components';
import { Modal as BsModal } from 'react-bootstrap';

class VideoModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    video: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    handleClose: PropTypes.func.isRequired,
  };

  /**
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    const { handleClose, ...props } = this.props;
    return (
      <Modal className="video-modal" onHide={handleClose} onSubmit={handleClose} {...props}>
        <BsModal.Header closeButton />
        <BsModal.Body>
          <div itemProp="video" itemScope itemType="http://schema.org/VideoObject">
            {typeof props.video === 'object' ? (
              <div>
                <h2 style={{ display: 'none' }}>
                  Video:{' '}
                  <span itemProp="name">{props.video.metadata && props.video.metadata.name}</span>
                </h2>
                <meta
                  itemProp="uploadDate"
                  content={props.video.metadata && props.video.metadata.release_time}
                />
                <meta
                  itemProp="thumbnailURL"
                  content={
                    props.video.thumbnails &&
                    props.video.thumbnails.length &&
                    props.video.thumbnails[0].link
                  }
                />
                <meta itemProp="embedURL" content={props.video.videoUrl || props.video.videoLink} />
                <Html
                  id="schema-videoobject"
                  className="video-container"
                  content={props.video.videoEmbed}
                />
                <span itemProp="description" style={{ display: 'none' }}>
                  {props.video.metadata && props.video.metadata.description}
                </span>
              </div>
            ) : (
              <Html
                id="schema-videoobject"
                className="video-container"
                content={this.props.video}
              />
            )}
          </div>
        </BsModal.Body>
      </Modal>
    );
  }
}

export default withStyles(style)(VideoModal);
