import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'common';

import { Bookmark, PostDetailDivider } from 'modules/shared';

/**
 * @param {Object} post Post Object
 * @param {string} buy_type string
 * @param {string} price string
 * @param {boolean} bookmarked If the post is bookmarked
 * @param {Function} bookmark Bookmark function
 * @param {boolean} bookmarking Bookmarking is in progress
 * @param {boolean} bottomDivider show divider
 *
 * @returns {JSX.Element}
 */
const Header = ({
  post,
  buy_type,
  price,
  bookmarked,
  bookmark,
  bookmarking,
  bottomDivider = true,
  bottomDividerAnalystColor,
}) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-between align-center">
        <div className="date">{formatDate(post.published_at)}</div>
        <Bookmark bookmarking={bookmarking} bookmarked={bookmarked} onClick={bookmark} />
      </div>
      <div className="header">
        <div className="title">{post.title}</div>
        {buy_type ? (
          <div className="buy-type">
            {buy_type}
            {price ? ` @ ${price}` : null}
          </div>
        ) : null}
      </div>
      {bottomDivider ? <PostDetailDivider thickAnalystBorder={bottomDividerAnalystColor} /> : null}
    </React.Fragment>
  );
};

Header.propTypes = {
  post: PropTypes.object,
  buy_type: PropTypes.string,
  price: PropTypes.string,
  bookmarked: PropTypes.bool,
  bookmark: PropTypes.func.isRequired,
  bookmarking: PropTypes.bool,
  bottomDivider: PropTypes.bool,
  bottomDividerAnalystColor: PropTypes.bool,
};

export default Header;
