import * as BookmarksService from 'modules/shared/services/bookmarks';

export const GET_ANALYST_BOOKMARKS = 'GET_ANALYST_BOOKMARKS';
export const GET_EDGE_BOOKMARKS = 'GET_EDGE_BOOKMARKS';
export const GET_EDGE_PLUS_BOOKMARKS = 'GET_EDGE_PLUS_BOOKMARKS';

/**
 * @param {string | number} id BookmarkId
 * @returns {Function}
 */
export const deleteAnalystBookmark = (id) => async () => {
  return BookmarksService.deleteBookmark(id);
};

/**
 * @param {string | number} id BookmarkId
 * @returns {Function}
 */
export const deleteEdgeBookmark = (id) => async (dispatch) => {
  const res = await BookmarksService.deleteBookmark(id);
  await dispatch(getEdgeBookmarks());
  return res;
};

/**
 * @param {string | number} id BookmarkId
 * @returns {Function}
 */
export const deleteEdgePlusBookmark = (id) => async (dispatch) => {
  const res = await BookmarksService.deleteBookmark(id);
  await dispatch(getEdgePlusBookmarks());
  return res;
};

/**
 * @description Gets all of the user's bookmarks.
 * @returns {function}
 */
export const getBookmarks = () => async (dispatch) => {
  const bookmarks = await BookmarksService.getAllBookmarks();

  dispatch({
    type: GET_ANALYST_BOOKMARKS,
    payload: bookmarks,
  });

  return bookmarks;
};

/**
 * @description Gets all of the user's bookmarks.
 * @returns {function}
 */
export const getEdgeBookmarks = () => async (dispatch) => {
  const bookmarks = await BookmarksService.getEdgeBookmarks();

  dispatch({
    type: GET_EDGE_BOOKMARKS,
    payload: bookmarks,
  });

  return bookmarks;
};

/**
 * @description Gets all of the user's bookmarks.
 * @returns {function}
 */
export const getEdgePlusBookmarks = () => async (dispatch) => {
  const bookmarks = await BookmarksService.getEdgePlusBookmarks();

  dispatch({
    type: GET_EDGE_PLUS_BOOKMARKS,
    payload: bookmarks,
  });

  return bookmarks;
};
