import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { match } from 'common';

import styles from './ModelPortfolio.scss';

/**
 * @param {Function} WrappedComponent Component to wrap
 *
 * @returns {Function}
 */
const withModelPortfolio = (WrappedComponent) => {
  return withStyles(styles)(
    class WithModelPortfolio extends Component {
      static propTypes = {
        showLoader: PropTypes.func.isRequired,
        hideLoader: PropTypes.func.isRequired,
        getHistory: PropTypes.func.isRequired,
        getItem: PropTypes.func.isRequired,
        match,
      };

      state = {
        modelPortfolio: null,
        transformedModelPortfolio: {},
        history: {},
        latest_id: 0,
        currentId: null,
        loading: true,
      };

      /**
       * @description Saves the company id if it is changed.
       *              It happens on route param change -> the component won't remount.
       * @param {Object} nextProps Incoming props.
       * @param {Object} prevState Previous state.
       * @returns {Object | null}
       */
      static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.id !== prevState.currentId) {
          return {
            currentId: nextProps.match.params.id ? +nextProps.match.params.id : null,
          };
        }

        return null;
      }

      shouldGetData = () => {
        if (!this.state.currentId) {
          return (
            this.state.modelPortfolio &&
            this.state.modelPortfolio.id !== this.state.latest_id &&
            !this.state.loading
          );
        }
        return (
          this.state.modelPortfolio &&
          this.state.modelPortfolio.id !== this.state.currentId &&
          !this.state.loading
        );
      };

      /**
       * @param {Object} column each column in ideas
       *
       * @returns {number} subTotal of column
       * */
      getSubTotalByColumns = (column) => {
        let subTotal = 0;
        column.companies.forEach((company) => {
          subTotal += +company.value;
        });

        return subTotal;
      };

      getTotalsByType = (ideasType) => {
        ideasType.total = 0;
        ideasType.columnsSubTotal = [];
        ideasType.modelIdeas.forEach((modelIdea) => {
          modelIdea.columns.forEach((column, index) => {
            if (!ideasType.columnsSubTotal[index]) {
              ideasType.columnsSubTotal.push(0);
            }
            ideasType.columnsSubTotal[index] += this.getSubTotalByColumns(column);
          });
        });

        ideasType.columnsSubTotal.forEach((subTotal) => {
          ideasType.total += subTotal;
        });
      };

      /**
       * @param {Array<string>} ideaTypes Idea Types key array
       * @returns {Object} Empty Model Portfolio Content
       */
      getBaseModelPortfolio(ideaTypes) {
        const content = ideaTypes.reduce((prev, curr) => {
          prev[curr] = { columns: [] };
          return prev;
        }, {});

        return { content };
      }

      /**
       * @param {Object} content original data
       * @param {Object} baseModelPortfolio Transformed Model Portfolio Base
       * @param {Array} transformKeys Keys to apply the transform
       *
       * @returns {Object} transformed modelPortfolio, new structure in column will be ideas array and companies,
       *                   in old ideas array was columns and companies
       *
       * */
      getTransformedModelPortfolio(
        content,
        baseModelPortfolio,
        transformKeys = ['shortIdeas', 'longIdeas']
      ) {
        const transformedModelPortfolio = baseModelPortfolio;

        /**
         * @param {string} key original data
         *
         * @description {Object} transformed modelPortfolio, new structure in column will be ideas array and companies, in old ideas array was columns and companies
         * */
        const transform = (key) => {
          content[key].modelIdeas.map((modelIdea, modelIdeaIndex) => {
            modelIdea.columns.map((column, columnIndex) => {
              if (!transformedModelPortfolio.content[key].columns[columnIndex]) {
                transformedModelPortfolio.content[key].columns.push({
                  columnName: column.columnName,
                  columnSubTotal: content[key].columnsSubTotal[columnIndex],
                });
              }

              if (!transformedModelPortfolio.content[key].columns[columnIndex].modelIdeas) {
                transformedModelPortfolio.content[key].columns[columnIndex].modelIdeas = [];
              }

              if (
                !transformedModelPortfolio.content[key].columns[columnIndex].modelIdeas[
                  modelIdeaIndex
                ]
              ) {
                transformedModelPortfolio.content[key].columns[columnIndex].modelIdeas.push({
                  companies: column.companies,
                });
              }
            });
          });
        };

        transformKeys.forEach((key) => {
          transform(key);
        });

        return transformedModelPortfolio;
      }

      fetchData = async (ideaTypes) => {
        this.props.showLoader();

        try {
          const modelPortfolio = await this.props.getHistory();
          if (modelPortfolio.latest_post) {
            const content =
              typeof modelPortfolio.latest_post.content === 'string'
                ? JSON.parse(modelPortfolio.latest_post.content)
                : modelPortfolio.latest_post.content;

            ideaTypes.forEach((type) => {
              this.getTotalsByType(content[type]);
            });

            this.props.hideLoader();
            this.setState({
              latest: modelPortfolio.latest_post,
              modelPortfolio: {
                ...modelPortfolio.latest_post,
                content,
              },
              transformedModelPortfolio: this.getTransformedModelPortfolio(
                content,
                this.getBaseModelPortfolio(ideaTypes),
                ideaTypes
              ),
              historyItems: modelPortfolio.history,
              latest_id: modelPortfolio.latest_post.id,
            });
            if (this.props.match.params.id) {
              await this.getModelPortfolioData(this.props.match.params.id, ideaTypes);
            } else {
              this.setState({ loading: false });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        } catch (e) {
          this.props.hideLoader();
        }
        this.props.hideLoader();
      };

      /**
       * @param {number} id of model portfolio
       * @param {Array<string>} ideaTypes Keys of idea types
       *
       * @description get model portfolio data by id.
       */
      getModelPortfolioData = async (id, ideaTypes) => {
        this.props.showLoader();
        this.setState({ loading: true });
        const modelPortfolio = !id
          ? await this.props.getItem(this.state.latest_id)
          : await this.props.getItem(id);
        const content =
          typeof modelPortfolio.content === 'string'
            ? JSON.parse(modelPortfolio.content)
            : modelPortfolio.content;

        ideaTypes.forEach((type) => {
          this.getTotalsByType(content[type]);
        });

        this.props.hideLoader();
        this.setState({
          modelPortfolio: {
            ...modelPortfolio,
            content,
          },
          transformedModelPortfolio: this.getTransformedModelPortfolio(
            content,
            this.getBaseModelPortfolio(ideaTypes),
            ideaTypes
          ),
          loading: false,
        });

        this.props.hideLoader();
      };
      /**
       * @returns {JSX.Element}
       */
      render() {
        return (
          <WrappedComponent
            {...this.props}
            {...this.state}
            getTotalsByType={this.getTotalsByType}
            getTransformedModelPortfolio={this.getTransformedModelPortfolio}
            fetchData={this.fetchData}
            shouldGetData={this.shouldGetData}
            getModelPortfolioData={this.getModelPortfolioData}
          />
        );
      }
    }
  );
};

export default withModelPortfolio;
