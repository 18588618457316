import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { PostDetailDivider } from 'modules/shared';

/**
 * @param {boolean} small Collection of downloadable files.
 * @param {boolean} large Collection of downloadable files.
 * @param {boolean} topDivider top divider should be shown.
 * @param {string | Object} message Footer message.
 * @returns {null | JSX.Element}
 * @constructor
 */
const Footer = ({ small, large, message, topDivider = true, topDividerAnalystColor = true }) => {
  return (
    <div className={classNames('footer', { 'footer-small': small, 'footer-large': large })}>
      {topDivider ? <PostDetailDivider thickAnalystBorder={topDividerAnalystColor} /> : null}
      {message}
    </div>
  );
};

Footer.propTypes = {
  small: PropTypes.bool,
  large: PropTypes.bool,
  topDivider: PropTypes.bool,
  topDividerAnalystColor: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Footer;
