import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Footer, withSSR, Content, SearchInput } from 'modules/shared';
import { ComponentLoader } from 'modules/main';
import PropTypes from 'prop-types';
import { getPresses } from 'modules/main/actions/press';
import { getPage } from 'modules/shared/actions/pages';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './Press.scss';
import { Grid, Row, Col } from 'react-bootstrap';
import { blogSectionOptions, history, location } from 'common';
import Env from 'env';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import qs from 'query-string';
import { connect } from 'react-redux';
import PressList from './PressList';
import { PublicHeader } from 'modules/analyst/modules/shared';
import { analystFooterLinks } from 'common';

const GET_PRESSES_DEFAULT_PARAMS = {
  nopaginate: false,
  per_page: Env.PRESS_ITEM_PER_PAGE,
  order: 'published_at',
};

class Press extends Component {
  static propTypes = {
    pressSectionFirst: PropTypes.object,
    pressSectionSecond: PropTypes.object,
    content: PropTypes.array,
    isLoading: PropTypes.bool,
    dispatch: PropTypes.func,
    history,
    location,
  };

  state = {
    pressSectionFirst: {},
    pressSectionSecond: {},
    searchText: '',
    per_page: [],
  };

  /**
   * @param {Object} params get params from ssr
   * @returns {Promise}
   */
  static async getInitialData(params) {
    const queryParams = params.location ? qs.parse(params.location.search) : {};
    const apiList = [params.dispatch(getPage('landing', 'press'))];
    for (const section of blogSectionOptions) {
      let per_page;
      if (queryParams.per_page) {
        const per_page_data = queryParams.per_page.split(',');
        per_page = per_page_data[section.id - 1]
          ? per_page_data[section.id - 1]
          : Env.PRESS_ITEM_PER_PAGE;
      } else {
        per_page = Env.PRESS_ITEM_PER_PAGE;
      }
      apiList.push(
        params.dispatch(
          getPresses({
            ...GET_PRESSES_DEFAULT_PARAMS,
            section: section.id,
            per_page,
            search: queryParams.search ? queryParams.search : '',
          })
        )
      );
    }
    return await Promise.all(apiList);
  }

  /**
   * @param {Object} prevProps Props before update.
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.pressSectionFirst !== prevProps.pressSectionFirst &&
      !Object.keys(this.state.pressSectionFirst).length
    ) {
      this.setState({ pressSectionFirst: this.props.pressSectionFirst }, () => {
        const queryParams = this.props.location ? qs.parse(this.props.location.search) : {};
        this.setState({ searchText: queryParams.search || '' });
      });
    }
    if (
      this.props.pressSectionSecond !== prevProps.pressSectionSecond &&
      !Object.keys(this.state.pressSectionSecond).length
    ) {
      this.setState({ pressSectionSecond: this.props.pressSectionSecond }, () => {
        const queryParams = this.props.location ? qs.parse(this.props.location.search) : {};
        this.setState({ searchText: queryParams.search || '' });
      });
    }
  }

  /**
   * @param {Object} blogs data
   * @param {number} id of section
   * @param {string} keyId of section in state
   * @description get next 3 blogs
   * */
  loadMoreData = async (blogs, id, keyId) => {
    this.props.dispatch(showLoader());
    const currentBlogs = { ...blogs };
    const nexPageData = await new Promise(
      getPresses({
        ...GET_PRESSES_DEFAULT_PARAMS,
        section: id,
        search: this.state.searchText || '',
        page: +(blogs.data.length / Env.PRESS_ITEM_PER_PAGE) + 1,
      })
    );

    currentBlogs.data.push(...nexPageData.payload.data);
    this.setState(
      {
        [keyId]: {
          ...nexPageData.payload,
          data: currentBlogs.data,
        },
      },
      () => {
        const per_page = [...this.state.per_page];
        per_page[id - 1] = this.state[keyId].data.length;
        this.setState({ per_page }, () => {
          this.setQueryParams();
        });
      }
    );
    this.props.dispatch(hideLoader());
  };

  filterData = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.timeout = setTimeout(async () => {
        this.props.dispatch(showLoader());
        const apiList = [];
        for (const section of blogSectionOptions) {
          apiList.push(
            this.props.dispatch(
              getPresses({
                ...GET_PRESSES_DEFAULT_PARAMS,
                section: section.id,
                search: this.state.searchText,
              })
            )
          );
        }
        const nextData = await Promise.all(apiList);

        this.setState({ pressSectionFirst: nextData[0], pressSectionSecond: nextData[1] }, () => {
          this.setQueryParams();
          this.props.dispatch(hideLoader());
        });
      }, 1000);
    });
  };

  setQueryParams = () => {
    this.props.history.replace({
      path: `/press`,
      search: `per_page=${this.state.per_page.join() || Env.PRESS_ITEM_PER_PAGE}&search=${this.state
        .searchText || ''}`,
    });
  };

  /**
   * @param {Object} blogs data
   * @returns {boolean} is ther more data
   * */
  showLoadMoreData = (blogs) => {
    return blogs.current_page !== blogs.last_page;
  };

  /**
   * @returns {JSX.Element} - Our People component
   */
  render() {
    return (
      <div className="analyst-home press white-background">
        <FormattedMessage id="MAIN.HEADER.PRESS">
          {(title) => (
            <Helmet defer={false}>
              <title itemProp="name">{title}</title>
              <link rel="canonical" href={`${Env.SITE_URL}press`} itemProp="url" />
            </Helmet>
          )}
        </FormattedMessage>

        {/*<MainHeader />*/}
        <PublicHeader />

        <Content>
          <ComponentLoader components={this.props.content} loading={this.props.isLoading} />
          {!this.props.isLoading ? (
            <Grid className="press-blog-posts">
              <Row>
                <Col xs={12} sm={12}>
                  <SearchInput
                    onChange={this.filterData}
                    value={this.state.searchText}
                    className="analyst"
                  />
                </Col>
              </Row>

              {!Object.keys(this.state.pressSectionFirst).length ? (
                <PressList
                  blogPosts={this.props.pressSectionFirst}
                  id="1"
                  keyId="pressSectionFirst"
                  titleId={blogSectionOptions[0].name}
                  showLoadMoreData={this.showLoadMoreData}
                  loadMoreData={this.loadMoreData}
                />
              ) : (
                <PressList
                  blogPosts={this.state.pressSectionFirst}
                  id="1"
                  keyId="pressSectionFirst"
                  titleId={blogSectionOptions[0].name}
                  showLoadMoreData={this.showLoadMoreData}
                  loadMoreData={this.loadMoreData}
                />
              )}

              {!Object.keys(this.state.pressSectionSecond).length ? (
                <PressList
                  blogPosts={this.props.pressSectionSecond}
                  id="2"
                  keyId="pressSectionSecond"
                  titleId={blogSectionOptions[1].name}
                  showLoadMoreData={this.showLoadMoreData}
                  loadMoreData={this.loadMoreData}
                />
              ) : (
                <PressList
                  blogPosts={this.state.pressSectionSecond}
                  id="2"
                  keyId="pressSectionSecond"
                  titleId={blogSectionOptions[1].name}
                  showLoadMoreData={this.showLoadMoreData}
                  loadMoreData={this.loadMoreData}
                />
              )}

              <Row>
                <Col xs={12} sm={12} className="blog-section-title">
                  <h4 className="mt-3 mb-3">
                    <FormattedMessage id="MAIN.PRESS.PRESS_CONTACT" />
                  </h4>
                  <hr />
                  <div className="press-contact-info">
                    <FormattedMessage id="MAIN.PRESS.PRESS_WITH_US" />
                  </div>
                  <div className="press-phone">
                    <FormattedMessage id="MAIN.PRESS.PRESS_PHONE_LABEL" />
                    <FormattedMessage id="MAIN.PRESS.PRESS_PHONE" />
                  </div>
                  <div className="press-email">
                    <FormattedMessage id="MAIN.PRESS.PRESS_EMAIL_LABEL" />
                    <FormattedHTMLMessage id="MAIN.PRESS.PRESS_EMAIL" />
                  </div>
                  <hr />
                  <div className="press-informations">
                    <FormattedMessage id="MAIN.PRESS.PRESS_INFORMATION" />
                    <FormattedHTMLMessage id="MAIN.PRESS.PRESS_INFORMATION_LINK" />
                  </div>
                </Col>
              </Row>
            </Grid>
          ) : null}
        </Content>

        {/*<Footer header={{ toTop: true }} />*/}
        <Footer
          className="analyst analyst-link-hover"
          header={{
            toTop: true,
            icons: true,
            logoAnalyst: true,
            links: analystFooterLinks,
          }}
        />
      </div>
    );
  }
}

/**
 * @param {Object} state Core state object.
 *
 * @returns {Object} Injected props from state.
 */
const mapStateToProps = (state) => {
  return {
    content: state.shared.pages || [],
    pressSectionFirst: state.app.pressSectionFirst,
    pressSectionSecond: state.app.pressSectionSecond,
  };
};

export default connect(mapStateToProps)(withSSR(withStyles(style)(Press)));
