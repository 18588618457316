import Cookies from 'js-cookie';

import Env from 'env';
import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';
/**
 * @param {Object} credentials Email Password.
 *
 * @returns {Promise<Object>}
 */
export const login = async (credentials) => {
  return await request({ resource: 'auth/login', method: Methods.POST, data: credentials });
};

/**
 * @param {Object} data Email and Site.
 *
 * @returns {Promise}
 */
export const forgotPassword = async (data) => {
  return await request({
    resource: 'auth/password/email',
    method: Methods.POST,
    data,
  });
};

/**
 * @param {Object} credentials Email.
 *
 * @returns {Promise}
 */
export const createPassword = async (credentials) => {
  return await request({
    resource: 'auth/password/reset',
    method: Methods.POST,
    data: credentials,
  });
};

/**
 * @returns {Promise}
 */
export const getProfile = async () => {
  return request({ resource: 'auth/me' });
};

/**
 * @param {Object} data user data
 * @returns {Promise}
 */
export const saveProfile = async (data) => {
  return await request({
    method: Methods.POST,
    resource: 'auth/me',
    data,
  });
};

/**
 * @returns {Promise}
 */
export const exportProfile = async () => {
  return await request({
    method: Methods.GET,
    resource: 'gdpr/download',
  });
};

/**
 * @returns {Promise}
 */
export const deleteProfile = async () => {
  return await request({
    method: Methods.DELETE,
    resource: 'auth/me',
  });
};

/**
 * @returns {Promise}
 */
export const logout = () => {
  return request({
    resource: 'auth/logout',
    method: Methods.POST,
    data: { refresh_token: Cookies.get(Env.REFRESH_TOKEN_ID) },
  });
};

/**
 * @returns {Promise}
 */
export const getEmployees = async () => {
  return await request({ resource: 'users/employees' });
};

/**
 * @description Get the list of all users.
 * @param {Object} options Pagination param
 * @returns {Promise}
 */
export const getUsers = async (options) => {
  return await request({
    resource: `users${getPaginationQueryParams({ ...options, nopaginate: false })}`,
  });
};

/**
 * @param {number} id user ID
 *
 * @returns {Promise}
 */
export const deleteUser = async (id) => {
  return await request({
    resource: `users/${id}`,
    method: Methods.DELETE,
  });
};

/**
 * @param {number} id user ID
 *
 * @returns {Promise}
 */
export const getUser = async (id) => {
  return await request({
    resource: `users/${id}`,
  });
};

/**
 * @param {Object} data user object
 *
 * @returns {Promise}
 */
export const saveUser = async (data) => {
  return await request({
    resource: `users`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id user ID
 * @param {Object} data user object
 * @returns {Promise}
 */
export const modifyUser = async (id, data) => {
  return await request({
    resource: `users/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id user ID
 * @param {Object} data avatar image
 * @returns {Promise}
 */
export const saveAvatar = async (id, data) => {
  return await request({
    resource: `users/${id}/avatar`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id user ID
 * @returns {Promise}
 */
export const resendEmail = async (id) => {
  return await request({
    resource: `users/${id}/resend-welcome-email`,
    method: Methods.POST,
  });
};

/**
 * @returns {Promise}
 */
export const getUserSettings = async () => {
  return await request({
    resource: `settings`,
    method: Methods.GET,
  });
};

/**
 * @param {Object} data Preference value
 * @returns {Promise}
 */
export const createSetting = async (data) => {
  return await request({
    resource: `settings`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {string} key Setting key
 * @param {Object} data Setting value
 * @returns {Promise}
 */
export const modifySetting = async (key, data) => {
  return await request({
    resource: `settings/${key}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @returns {Promise}
 */
export const getUserPreferences = async () => {
  return await request({
    resource: `preferences`,
    method: Methods.GET,
  });
};

/**
 * @param {Object} data Preference value
 * @returns {Promise}
 */
export const createUserPreference = async (data) => {
  return await request({
    resource: `preferences`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {string} key Preference key
 * @param {Object} data Preference value
 * @returns {Promise}
 */
export const modifyUserPreference = async (key, data) => {
  return await request({
    resource: `preferences/${key}`,
    method: Methods.PUT,
    data,
  });
};
