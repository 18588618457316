import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Post from './Post';
import Env from 'env';
import {
  RelatedPosts,
  withPostUpdate,
  defaultPostUpdateParams,
  PostDetail as PostDetailContainer,
} from 'modules/shared';
import { FormattedMessage } from 'react-intl';

class PostDetail extends Component {
  static propTypes = {
    post: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.bool,
    bookmark: PropTypes.func.isRequired,
    updateDataByAnswer: PropTypes.func.isRequired,
    bookmarkPost: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    getMoreIdeas: PropTypes.func.isRequired,
    isVideoPost: PropTypes.func,
    bookmarking: PropTypes.bool,
    otherVideos: PropTypes.array,
    otherVideosLoading: PropTypes.bool,
    error: PropTypes.bool,
    basePath: PropTypes.string,
  };

  /**
   * @param {Array} moreIdeas more posts array
   * @returns {JSX.Element}
   */
  getMoreIdeasTitle = (moreIdeas) => {
    const { post } = this.props;

    const isSpecialTopic = post
      ? post.categories && post.categories.find(({ slug }) => slug === 'special_topic')
      : false;

    return moreIdeas && moreIdeas.length > 0 ? (
      isSpecialTopic ? (
        <FormattedMessage id="MAIN.READ_MORE_FROM_SPECIAL_TOPICS" />
      ) : post && post.article_companies.length > 1 ? (
        <FormattedMessage id="MAIN.READ_MORE_FROM_ANALYST" />
      ) : post && post.article_companies.length > 0 ? (
        <FormattedMessage
          id="MAIN.READ_MORE_ON"
          values={{ name: post.article_companies[0].title }}
        />
      ) : null
    ) : null;
  };

  /**
   * @returns {Promise}
   */
  fetchMoreIdeas = async () => {
    const { getMoreIdeas, match } = this.props;

    const data = await getMoreIdeas(match.params.id);
    return { data };
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      post,
      basePath,
      bookmarkPost,
      deleteBookmark,
      match,
      otherVideos,
      otherVideosLoading,
    } = this.props;

    return (
      <div>
        <Helmet defer={false}>
          <title>{post ? post.title : ''}</title>
        </Helmet>
        <PostDetailContainer error={this.props.error}>
          {!this.props.loading && post ? (
            <Post
              post={post}
              otherVideos={otherVideos}
              otherVideosLoading={otherVideosLoading}
              bookmark={this.props.bookmark}
              updateDataByAnswer={this.props.updateDataByAnswer}
              isVideoPost={this.props.isVideoPost}
              bookmarking={this.props.bookmarking}
              basePath={this.props.basePath}
            />
          ) : null}
        </PostDetailContainer>

        <div className="d-flex flex-column container-margin">
          <RelatedPosts
            noMarginOnRow
            titleMarginLeft
            fetchData={this.fetchMoreIdeas}
            title={this.getMoreIdeasTitle()}
            bookmarkPost={bookmarkPost}
            deleteBookmark={deleteBookmark}
            hasElements={false}
            basePath={basePath}
            defaultImage={Env.EDGE_DEFAULT_IMAGE}
            updateData={match.params && match.params.id}
            hide={this.props.loading}
          />
        </div>
      </div>
    );
  }
}

export default withPostUpdate({
  ...defaultPostUpdateParams,
  editItem: { link: `/admin/content/posts/:id/edit` },
})(PostDetail);
