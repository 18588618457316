import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Dropdown as RBDropdown } from 'react-bootstrap';
import styles from './Dropdown.scss';

class Dropdown extends Component {
  static propTypes = {
    toggle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { toggle, children, ...props } = this.props;
    return (
      <RBDropdown pullRight className="analyst-public-dropdown" {...props}>
        <RBDropdown.Toggle noCaret>{toggle}</RBDropdown.Toggle>
        <RBDropdown.Menu>{children}</RBDropdown.Menu>
      </RBDropdown>
    );
  }
}

export default withStyles(styles)(Dropdown);
