import { GET_ALL_CATEGORIES } from '../actions/categories';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const categories = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default categories;
