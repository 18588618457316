import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { EditorTitle, FieldGroup, Delete, Button } from 'modules/admin/shared';
import { match, getDSTDateString } from 'common';

class ModelPortfolioList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    viewLink: PropTypes.string,
  };

  getLatestId = () => {
    return this.props.data && this.props.data.length > 0 ? this.props.data[0].id : null;
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { data, pages, loading, search, fetchData, onSearch, deleteItem } = this.props;
    return (
      <div>
        <EditorTitle
          viewLink={this.props.viewLink}
          title={<FormattedMessage id="ADMIN.MODEL_PORTFOLIO.TITLE" />}>
          <div>
            <NavLink to={`${this.props.match.path}new`}>
              <div className="d-inline-flex">
                <Button>
                  <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.NEW_MODEL_PORTFOLIO" />
                </Button>
              </div>
            </NavLink>

            {this.getLatestId() ? (
              <NavLink to={`${this.props.match.path}${this.getLatestId()}/new`}>
                <div className="d-inline-flex">
                  <Button className="ml-2">
                    <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.COPY_MODEL_PORTFOLIO" />
                  </Button>
                </div>
              </NavLink>
            ) : null}
          </div>
        </EditorTitle>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <FieldGroup onChange={onSearch} placeholderId="MAIN.SEARCH" value={search} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.TITLE" />,
                    accessor: 'title',
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.PUBLISHED_AT" />,
                    accessor: 'published_at',
                    Cell: ({ original }) => getDSTDateString(original.published_at),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.STATUS" />,
                    accessor: 'status',
                  },
                  {
                    Header: '',
                    maxWidth: 100,
                    Cell: ({ original }) => (
                      <div className="d-flex justify-evenly">
                        <div>
                          <NavLink to={`${this.props.match.path}${original.id}/edit`}>
                            <span className="action-icon fa fa-edit" />
                          </NavLink>
                        </div>
                        <Delete onSubmit={() => deleteItem(original.id)} />
                      </div>
                    ),
                    sortable: false,
                  },
                ]}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                resizable={false}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={fetchData} // Request new data when things change
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                style={{
                  height: 'calc(100vh - 241px)',
                }}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ModelPortfolioList;
