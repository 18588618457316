import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * @param {Function} WrappedComponent Component to wrap infinite scroll functionality.
 * @returns {Function}
 */
const withInfiniteScroll = (WrappedComponent) => {
  return class WithInfiniteScroll extends Component {
    static propTypes = {
      fetchData: PropTypes.func,
      hasElements: PropTypes.bool,
      fetching: PropTypes.bool,
      offset: PropTypes.number,
    };

    static defaultProps = {
      offset: 500,
    };

    /**
     * @description Adds scroll event listener.
     */
    componentDidMount() {
      window.addEventListener('scroll', this.onScroll, false);
    }

    /**
     * @description Removes scroll event listener.
     */
    componentWillUnmount() {
      window.removeEventListener('scroll', this.onScroll, false);
    }

    /**
     * @description Fetches data if we are on the bottom of the page and a fetch is not pending.
     */
    onScroll = () => {
      const { hasElements, fetchData, fetching, offset } = this.props;
      const onBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - offset;
      if (onBottom && hasElements && !fetching && fetchData) {
        fetchData();
      }
    };

    /**
     * @returns {JSX.Element}
     */
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withInfiniteScroll;
