import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { match, history } from 'common';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import {
  getUser,
  saveUser,
  modifyUser,
  saveAvatar,
  forgotPassword,
} from 'modules/main/actions/users';
import { getRoles } from 'modules/main/actions/roles';
import { getSectors } from 'modules/analyst/actions/sectors';
import { EditorTitle, Button, FieldGroup, FileUpload, Select } from 'modules/admin/shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  validateSchema,
  textSchema,
  emailSchema,
  phoneSchema,
  numberSchema,
} from 'common/shemaValidator';
import { resendEmail } from 'modules/main/services';

class UsersEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    saveUser: PropTypes.func.isRequired,
    modifyUser: PropTypes.func.isRequired,
    saveAvatar: PropTypes.func.isRequired,
    getRoles: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    match: match.isRequired,
    history: history.isRequired,
    getSectors: PropTypes.func.isRequired,
    sectors: PropTypes.array.isRequired,
  };

  edit = !!this.props.match.params.id;
  state = {
    user: {
      name: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      avatar_path: '',
      avatar_id: null,
      roles: [],
      analytics_exclude: false,
      sectors: [],
      full_access: false,
      analyst_code: null,
    },
    errors: {},
    isSubmitting: false,
    loading: true,
    isValid: false,
    changedAvatar: false,
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();

    if (this.edit) {
      const [user] = await Promise.all([
        this.props.getUser(this.props.match.params.id),
        this.props.getRoles(),
        this.props.getSectors(),
      ]);
      const state = {
        loading: false,
        user: {
          ...user,
          roles: this.getMultiSelectData(user.roles, 'role_title'),
          avatar_id: user.avatar_id || null,
          sectors: this.getMultiSelectData(user.sectors, 'name'),
        },
        isValid: await this.isValidForm(),
      };
      this.setState(state);
    } else {
      await Promise.all([this.props.getRoles(), this.props.getSectors()]);
      this.setState({ loading: false });
    }
    this.props.hideLoader();
  }

  /**
   * @description save user changes
   */
  save = async () => {
    this.setState({ isSubmitting: true });

    const user = { ...this.state.user };
    user.role_id = this.getIds(user.roles);
    user.sectors = !user.full_access ? this.getIds(user.sectors) : [];
    user.analyst_code = parseInt(user.analyst_code, 10);

    delete user.avatar_path;
    delete user.avatar_id;
    delete user.avatar_url;
    delete user.changedAvatar;
    delete user.roles;
    try {
      if (user.id) {
        await this.props.modifyUser(user.id, user);
        if (this.state.changedAvatar && user) {
          const avatarResponse = await this.saveAvatar(user.id);
          this.setState({
            user: { ...this.state.user, avatar_id: avatarResponse.avatar_id },
          });
        }
      } else {
        const res = await this.props.saveUser(user);
        let avatarResponse;
        if (this.state.changedAvatar) {
          avatarResponse = await this.saveAvatar(res.id);
        }
        this.setState({
          user: { ...this.state.user, id: res.id, avatar_id: avatarResponse?.avatar_id },
        });
        this.props.history.push(`/admin/system/users/${res.id}/edit`);
      }
      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  getMultiSelectData = (data, nameKey) => {
    return data ? data.map((item) => ({ label: item[nameKey], value: item.id })) : [];
  };

  /**
   * @returns {boolean} editor is valid.
   */
  isValidForm = async () => {
    const schema = Yup.object().shape({
      ...textSchema('firstname', { required: true }),
      ...textSchema('lastname', { required: true }),
      ...numberSchema('analyst_code', { min: 8, max: 8 }),
      ...emailSchema('email', { required: true }),
      ...phoneSchema('phone'),
      sectors: Yup.array().when('full_access', {
        is: false,
        then: Yup.array().required(<FormattedMessage id="ADMIN.REQUIRED" />),
      }),
    });

    return await schema.isValid(this.state.user);
  };

  deleteFile = async () => {
    const isValid = await this.isValidForm();

    this.setState({
      user: {
        ...this.state.user,
        avatar_path: null,
        avatar_id: null,
      },
      changedAvatar: true,
      isValid,
    });
  };

  onDropFile = async (files) => {
    const isValid = await this.isValidForm();

    this.setState({
      user: {
        ...this.state.user,
        avatar_path: files.length > 0 ? files[0] : null,
      },
      changedAvatar: true,
      isValid,
    });
  };

  saveAvatar = (id) => {
    const formData = new FormData();
    if (this.state.user.avatar_path) {
      formData.append('avatar', this.state.user.avatar_path);
    }

    return this.props.saveAvatar(id, formData);
  };

  /**
   * @param {Event} e Event data.
   */
  onChangeMultiSelect = async (e) => {
    this.setState(
      {
        user: {
          ...this.state.user,
          [e.target.name]: e.target.value,
        },
      },
      async () => {
        const isValid = await this.isValidForm();
        this.setState({ isValid });
      }
    );
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    const errors = { ...this.state.errors };
    let isValidForm = { ...this.state.isValid };

    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    const type = e.target.type;

    this.setState(
      {
        user: {
          ...this.state.user,
          [name]: type === 'checkbox' ? checked : value,
        },
      },
      async () => {
        isValidForm = await this.isValidForm();
        try {
          await validateSchema(this.getValidationSchemaByField(name), {
            [name]: type === 'checkbox' ? checked : value,
          });
          errors[name] = '';
        } catch (validationError) {
          errors[name] = validationError.errors[0];
        }

        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  /**
   * @param {string} field name of object
   *
   * @returns {Function} return schema by field
   * */
  getValidationSchemaByField = (field) => {
    switch (field) {
      case 'email': {
        return emailSchema(field);
      }
      case 'phone': {
        return phoneSchema(field);
      }
      case 'analyst_code': {
        return numberSchema(field, { min: 8, max: 8 });
      }
      default: {
        return textSchema(field, { required: true });
      }
    }
  };

  getIds = (data) => {
    return data ? data.map((item) => item.value) : [];
  };

  forgotPassword = async () => {
    try {
      this.setState({ sendingPassword: true });
      await this.props.forgotPassword({ email: this.state.user.email, site: 'analyst' });
      this.setState({ sendingPassword: false });
      toast.success(
        <FormattedMessage
          id="MAIN.FORGOT_PASSWORD_SUCCESS"
          values={{ email: this.state.user.email }}
        />
      );
    } catch (e) {
      this.setState({ sendingPassword: false });
    }
  };

  resendWelcomeEmail = async () => {
    try {
      this.setState({ sendingEmail: true });
      await resendEmail(this.state.user.id);
      this.setState({ sendingEmail: false });
      toast.success(
        <FormattedMessage
          id="MAIN.RESEND_EMAIL_SUCCESS"
          values={{ email: this.state.user.email }}
        />
      );
    } catch (e) {
      this.setState({ sendingEmail: false });
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { user, errors, loading, isSubmitting, sendingPassword, sendingEmail } = this.state;
    return (
      <div>
        {!loading ? (
          <form noValidate>
            <EditorTitle
              title={
                user.id ? (
                  <FormattedMessage id="ADMIN.USERS.EDIT_USER" />
                ) : (
                  <FormattedMessage id="ADMIN.USERS.CREATE_USER" />
                )
              }>
              <div className="d-inline-flex">
                {user.id ? (
                  <Button
                    loading={sendingEmail}
                    onClick={this.resendWelcomeEmail}
                    type="button"
                    className="mr-2"
                    disabled={sendingEmail}>
                    <FormattedMessage id="MAIN.RESEND_EMAIL" />
                  </Button>
                ) : null}
                {user.id ? (
                  <Button
                    loading={sendingPassword}
                    onClick={this.forgotPassword}
                    type="button"
                    className="mr-2"
                    disabled={sendingPassword}>
                    <FormattedMessage id="MAIN.NEW_PASSWORD" />
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  onClick={this.save}
                  type="button"
                  disabled={!this.state.isValid || isSubmitting}>
                  <FormattedMessage id="ADMIN.SAVE" />
                </Button>
              </div>
            </EditorTitle>
            <Grid fluid className="editor-content">
              <Row>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="firstname"
                    className="touched"
                    placeholderId="ADMIN.USERS.FIRST_NAME"
                    label={<FormattedMessage id="ADMIN.USERS.FIRST_NAME" />}
                    error={errors.firstname}
                    value={user.firstname}
                    onChange={this.onChange}
                    required
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="lastname"
                    className="touched"
                    placeholderId="ADMIN.USERS.LAST_NAME"
                    label={<FormattedMessage id="ADMIN.USERS.LAST_NAME" />}
                    error={errors.lastname}
                    value={user.lastname}
                    onChange={this.onChange}
                    required
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="email"
                    className="touched"
                    placeholderId="ADMIN.USERS.EMAIL"
                    label={<FormattedMessage id="ADMIN.USERS.EMAIL" />}
                    error={errors.email}
                    value={user.email}
                    onChange={this.onChange}
                    required
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="phone"
                    className="touched"
                    placeholderId="ADMIN.USERS.PHONE"
                    label={<FormattedMessage id="ADMIN.USERS.PHONE" />}
                    error={errors.phone}
                    value={user.phone}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="analyst_code"
                    className="touched"
                    placeholderId="ADMIN.USERS.CODE"
                    label={<FormattedMessage id="ADMIN.USERS.CODE" />}
                    error={errors.analyst_code}
                    value={user.analyst_code}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Select
                    name="roles"
                    label={<FormattedMessage id="ADMIN.ROLES" />}
                    clearable={false}
                    value={user.roles}
                    bottomMargin
                    multi
                    onChange={(e) => this.onChangeMultiSelect(e)}
                    options={
                      this.props.roles &&
                      this.props.roles.map(({ role_title, id }) => ({
                        label: role_title,
                        value: id,
                      }))
                    }
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="description"
                    componentClass="textarea"
                    placeholderId="ADMIN.DESCRIPTION"
                    label={<FormattedMessage id="ADMIN.DESCRIPTION" />}
                    value={user.description}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    className="checkbox-form-group"
                    label={<FormattedMessage id="ADMIN.SECTORS.FULL_ACCESS" />}
                    inline
                    type="checkbox"
                    name="full_access"
                    checked={user.full_access}
                    onChange={this.onChange}
                  />
                  <Select
                    name="sectors"
                    label={<FormattedMessage id="ADMIN.SECTORS.ACCESS" />}
                    disabled={user.full_access}
                    value={!user.full_access ? user.sectors : []}
                    bottomMargin
                    required
                    multi
                    onChange={(e) => this.onChangeMultiSelect(e)}
                    options={
                      this.props.sectors &&
                      this.props.sectors.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))
                    }
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    className="checkbox-form-group"
                    label={<FormattedMessage id="ADMIN.USERS.LEAVE_FROM_ANALYTICS" />}
                    inline
                    type="checkbox"
                    name="analytics_exclude"
                    checked={user.analytics_exclude}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={6} className="image-header">
                  <FormGroup className="image-preview-container">
                    <ControlLabel>
                      <FormattedMessage id="ADMIN.USERS.AVATAR_IMAGE" />
                    </ControlLabel>
                    <FileUpload
                      file={user.avatar_path}
                      fileId={user.avatar_id}
                      deleteFile={this.deleteFile}
                      onDropFile={(files) => this.onDropFile(files)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Grid>
          </form>
        ) : null}
      </div>
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app, analyst }) => {
  return {
    roles: app.roles,
    sectors: analyst.sectors,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getUser,
      saveUser,
      modifyUser,
      saveAvatar,
      getRoles,
      getSectors,
      forgotPassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersEditor);
