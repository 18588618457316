import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { match } from 'common';

export const defaultPostUpdateParams = { editItem: {}, idKey: 'id' };

/**
 * @param {Object} state,
 *
 * @returns {Object}
 */
const mapStateToProps = (state) => {
  return {
    systemSettings: state.app.systemSettings.all || {},
  };
};

/**
 * @param {Object} defaultParams Default params for the component
 *
 * @returns {Function}
 */

const withPostUpdate = (defaultParams = defaultPostUpdateParams) => (WrappedComponent) => {
  return connect(mapStateToProps)(
    injectIntl(
      class WithPostUpdate extends Component {
        static propTypes = {
          showLoader: PropTypes.func.isRequired,
          hideLoader: PropTypes.func.isRequired,
          getPost: PropTypes.func.isRequired,
          getOtherVideos: PropTypes.func,
          setEditItem: PropTypes.func,
          bookmarkPost: PropTypes.func,
          deleteBookmark: PropTypes.func,
          answerQuestion: PropTypes.func,
          intl: PropTypes.object,
          match,
        };

        state = {
          post: null,
          postId: null,
          loading: true,
          bookmarking: false,
          otherVideosLoading: false,
        };

        /**
         * @description Saves the post id if it is changed.
         *              It happens on route param change -> the component won't remount.
         * @param {Object} nextProps Incoming props.
         * @param {Object} prevState Previous state.
         * @returns {Object | null}
         */
        static getDerivedStateFromProps(nextProps, prevState) {
          const { idKey } = defaultParams;
          if (nextProps.match.params[idKey] !== prevState.postId) {
            return {
              postId: +nextProps.match.params[idKey],
            };
          }

          return null;
        }

        /**
         * @description Updates the post data if the id is changed.
         * @returns {Promise<void>}
         */
        componentDidUpdate() {
          const needsPost = this.state.post && this.state.post.id !== this.state.postId;
          if (needsPost && !this.state.loading) {
            return this.fetchData();
          }

          return null;
        }

        /**
         * @description Calls fetch on mount.
         * @returns {Promise<void>}
         */
        componentDidMount() {
          return this.fetchData();
        }

        isVideoPost = (post) =>
          post.categories && post.categories.find(({ slug }) => slug === 'video');

        /**
         * @description Fetches the company data by id.
         */
        async fetchData() {
          const { setEditItem, showLoader, hideLoader, getPost, getOtherVideos } = this.props;
          const {
            editItem: { textId, link },
            idKey,
          } = defaultParams;
          const id = this.props.match.params[idKey];

          showLoader();
          this.setState({ loading: true });
          let post;
          try {
            post = await getPost(id);
            this.setState({ post, error: false });
            setEditItem &&
              setEditItem({
                text: this.props.intl.formatMessage({
                  id: textId ? textId : 'ANALYST.EDIT_POST',
                }),
                link: link ? link.replace(':id', this.props.match.params[idKey]) : '',
              });
          } catch (e) {
            this.setState({ error: true });
          } finally {
            hideLoader();
            this.setState({ loading: false });
          }

          if (getOtherVideos && this.isVideoPost(post)) {
            this.setState({ otherVideosLoading: true });
            const otherVideos = await getOtherVideos(id);
            this.setState({ otherVideos, otherVideosLoading: false });
          }
        }

        /**
         * @description Bookmarks - UnBookmarks the current Post.
         */
        bookmark = async () => {
          this.setState({ bookmarking: true });
          if (this.state.post.bookmarks.length) {
            await this.props.deleteBookmark(this.state.post.bookmarks[0].id);
            this.setState({ post: { ...this.state.post, bookmarks: [] }, bookmarking: false });
          } else {
            const bookmark = await this.props.bookmarkPost(this.state.post.id);
            this.setState({
              post: { ...this.state.post, bookmarks: [bookmark] },
              bookmarking: false,
            });
          }
        };

        /**
         * @param {Object} post post data Object.
         * @returns {void}
         */
        updateDataByAnswer = async (post) => {
          this.setState({
            post,
          });
        };

        /**
         * @description Unsets the edit item in the Header Dropdown.
         */
        componentWillUnmount() {
          this.props.setEditItem && this.props.setEditItem(null);
        }

        /**
         * @returns {JSX.Element}
         */
        render() {
          return (
            <WrappedComponent
              {...this.props}
              {...this.state}
              updateDataByAnswer={this.updateDataByAnswer}
              bookmark={this.bookmark}
              isVideoPost={this.isVideoPost}
            />
          );
        }
      }
    )
  );
};

export default withPostUpdate;
