'use strict';

import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './ColorPicker.scss';
import { SketchPicker } from 'react-color';
import { ControlLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';

class ColorPicker extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  };

  static defaultProps = {
    color: '',
  };

  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="d-flex color-picker">
        <ControlLabel className="mr-4">{this.props.label}</ControlLabel>
        <div className="color-picker-swatch" onClick={this.handleClick}>
          <div
            className="color-picker-color"
            style={{
              background: `${this.props.color}`,
            }}
          />
        </div>
        {this.state.displayColorPicker ? (
          <div className="color-picker-popover">
            <div className="color-picker-cover" onClick={this.handleClose} />
            <SketchPicker color={this.props.color || {}} onChange={this.props.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(style)(ColorPicker);
