import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Switch, Route } from 'react-router-dom';
import { match } from 'common';
import AnalystInvestmentStylesList from './AnalystInvestmentStylesList';
import AnalystInvestmentStylesEditor from './AnalystInvestmentStylesEditor';
import { BasePaths } from 'config/basePaths';

class AnalystInvestmentStyles extends Component {
  static propTypes = {
    match,
    basePath: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="admin-table">
        <Switch>
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/`}
            render={(props) => (
              <AnalystInvestmentStylesList {...props} basePath={BasePaths.METADATA} />
            )}
          />
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/new`}
            render={(props) => (
              <AnalystInvestmentStylesEditor {...props} basePath={BasePaths.METADATA} />
            )}
          />
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/:id/edit`}
            render={(props) => (
              <AnalystInvestmentStylesEditor {...props} basePath={BasePaths.METADATA} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default AnalystInvestmentStyles;
