import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TRANSITION_DELAY = 100;
const MIN_WIDTH = 16;

class FeedbackBar extends Component {
  static propTypes = {
    percent: PropTypes.number,
    containerClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    maxRatio: PropTypes.number,
    minRatio: PropTypes.number,
    loading: PropTypes.bool,
  };

  state = {
    transition: false,
  };

  /**
   * @description trigger transition
   */
  componentDidMount() {
    this.triggerTransition();
  }

  /**
   * @param {Object} prevProps Props before update.
   * @description update percent trigger transition
   */
  componentDidUpdate(prevProps) {
    if (this.props.percent !== prevProps.percent) {
      this.setState({ transition: false }, () => {
        this.triggerTransition();
      });
    }
  }

  /**
   * @description trigger transition
   */
  triggerTransition() {
    setTimeout(() => {
      this.setState({ transition: true });
    }, TRANSITION_DELAY);
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { percent, containerClassName, iconClassName, maxRatio, minRatio, loading } = this.props;
    const { transition } = this.state;
    const formattedPercent = `${percent}%`;
    const widthPercent = `${percent > MIN_WIDTH || !percent ? percent : MIN_WIDTH}%`;

    return (
      <div
        className={classNames(containerClassName, {
          'no-other-bar': percent === minRatio || percent === maxRatio,
        })}
        style={{ width: widthPercent }}>
        <div className={classNames('bar-transition', { 'full-width': transition && !loading })}>
          <i className={classNames('no-hover', iconClassName)} />
          {formattedPercent}
        </div>
      </div>
    );
  }
}

export default FeedbackBar;
