import { connect } from 'react-redux';
import Careers from './Careers';

/**
 * @param {Object} state Core state object.
 *
 * @returns {Object} Injected props from state.
 */
const mapStateToProps = (state) => {
  return {
    content: state.shared.pages || [],
  };
};

export default connect(mapStateToProps)(Careers);
