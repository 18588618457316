import Cookies from 'js-cookie';
import Env from 'env';

/**
 * @param {string} page which page to save
 * @param {Object} data to save
 *
 * @description set setContactModalFilledStore to cookie
 * */
export const setContactModalFilledStore = (page, data) => {
  const savedData = getContactModalFilledStore();
  Cookies.set(Env.CONTACT_MODAL_FILLED, JSON.stringify({ ...(savedData || {}), [page]: data }));
};

/**
 * @param {string} page which page to get
 *
 * @description get getContactModalFilledStore from cookie
 *
 * @returns {string} is contactModalFilled
 * */
export const getContactModalFilledStore = (page) => {
  const data = Cookies.get(Env.CONTACT_MODAL_FILLED);

  if (!data) {
    return false;
  }

  if (page && data) {
    const parsedData = JSON.parse(data);
    return parsedData[page];
  }

  return JSON.parse(data);
};
