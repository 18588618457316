import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getShortScreen } from 'modules/analyst/actions/short-screen';
import { getPostsPage } from 'modules/analyst/actions/posts';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { bookmarkPost } from 'modules/analyst/actions/posts';
import { deleteAnalystBookmark as deleteBookmark } from 'modules/shared/actions/bookmarks';

import ShortScreen from './ShortScreen';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst, app }) => {
  return {
    shortScreen: analyst.shortScreen,
    loading: !!app.loader.showCount,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showLoader, hideLoader, getShortScreen, getPostsPage, bookmarkPost, deleteBookmark },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortScreen);
