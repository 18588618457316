import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  FormInputs,
  getErrorState,
  AddPlaceholder,
  DragHandle,
  FileUpload,
  FieldGroup,
  Delete,
} from 'modules/admin/shared';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import styles from './SectionDropdown.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pagesFileUpload } from 'common';
import { pagesUploadFile } from 'modules/admin/main/actions/file-upload';

const SortableBlock = SortableElement(
  ({ block, onChange, removesection, sectionIndex, onDropFile, deleteFile }) => (
    <Col lg={4} md={4} sm={6} xs={12} className="section">
      <DragHandle />
      <Delete onSubmit={() => removesection(sectionIndex)} />
      <FormInputs
        ncols={['col-md-12', 'col-md-12']}
        properties={[
          {
            label: 'Title',
            type: 'text',
            error: getErrorState(block.title),
            bsClass: 'form-control',
            placeholder: 'Title',
            name: 'title',
            value: block.title,
            onChange: (e) => {
              onChange(e, sectionIndex);
            },
          },
          {
            label: 'Description',
            type: 'text',
            error: getErrorState(block.description),
            bsClass: 'form-control',
            componentClass: 'textarea',
            placeholder: 'Description',
            name: 'description',
            value: block.description,
            onChange: (e) => {
              onChange(e, sectionIndex);
            },
          },
        ]}
      />
      <FormGroup className="image-preview-container">
        <ControlLabel>
          <FormattedMessage id="ADMIN.POSTS.FILE" />
        </ControlLabel>
        <FileUpload
          accept="application/pdf"
          name="file"
          file={block.pdf}
          loading={block.loading}
          onDropFile={(files) => onDropFile(files, sectionIndex)}
          deleteFile={() => {
            deleteFile(sectionIndex);
          }}
        />
      </FormGroup>
    </Col>
  )
);

const SortableBlockContainer = SortableContainer(
  ({
    sectionsDropdown,
    componentTitle,
    enableMultipleExpand,
    onChange,
    addSection,
    removesection,
    onDropFile,
    deleteFile,
  }) => {
    return (
      <div>
        <FormInputs
          ncols={['col-md-12']}
          properties={[
            {
              label: 'Component Title',
              type: 'text',
              error: getErrorState(componentTitle),
              bsClass: 'form-control',
              placeholder: 'Component Title',
              name: 'componentTitle',
              value: componentTitle,
              onChange,
            },
          ]}
        />
        <FieldGroup
          className="checkbox-form-group"
          label={<FormattedMessage id="ADMIN.MULTIPLE_EXPAND" />}
          inline
          type="checkbox"
          name="enableMultipleExpand"
          checked={enableMultipleExpand}
          value={enableMultipleExpand}
          onChange={onChange}
        />
        <Row className="sections-dropdown">
          {[
            ...sectionsDropdown.map((block, sectionIndex) => (
              <SortableBlock
                index={sectionIndex}
                key={sectionIndex}
                onDropFile={onDropFile}
                deleteFile={deleteFile}
                sectionIndex={sectionIndex}
                block={block}
                onChange={onChange}
                removesection={removesection}
              />
            )),
          ]}
          <Col lg={4} md={4} sm={6} xs={12} key="add-person">
            <AddPlaceholder onClick={addSection} />
          </Col>
        </Row>
      </div>
    );
  }
);

class SectionDropdown extends Component {
  static componentType = 'section-dropdown';
  static blockName = 'Section Dropdown';
  static icon = 'fa fa-handshake';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
    pagesUploadFile: PropTypes.func.isRequired,
  };

  state = {
    sectionsDropdown: [SectionDropdown.getSectionsDropdown()],
    componentTitle: '',
    enableMultipleExpand: false,
  };

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.setState(this.props.data);
  }

  /**
   * @returns {Object} Empty section object.
   */
  static getSectionsDropdown() {
    return { title: '', description: '', pdf: { link: '', file_name: '' }, loading: false };
  }

  /**
   * @returns {boolean} editor is valid.
   */
  isValidForm() {
    let valid =
      !!this.state.sectionsDropdown.length &&
      !!this.state.componentTitle &&
      this.state.componentTitle.length > 0;
    let sectionIndex = 0;
    while (valid && sectionIndex < this.state.sectionsDropdown.length) {
      const section = this.state.sectionsDropdown[sectionIndex];
      if (!section.title || !section.description) {
        valid = false;
      }
      sectionIndex++;
    }
    return valid;
  }

  onChange = (e, sectionIndex) => {
    if (!sectionIndex && sectionIndex !== 0) {
      const name = e.target.name;
      const value = e.target.value;
      const type = e.target.type;
      const checked = e.target.checked;
      this.setState({ [name]: type === 'checkbox' ? checked : value });
      this.props.onChange({
        [name]: type === 'checkbox' ? checked : value,
        valid: this.isValidForm(),
      });
    } else {
      const sectionsDropdown = this.state.sectionsDropdown.map((data) => data);
      sectionsDropdown[sectionIndex][e.target.name] = e.target.value;
      this.setState({ sectionsDropdown });
      this.props.onChange({ sectionsDropdown, valid: this.isValidForm() });
    }
  };

  addSection = () => {
    this.setState(
      (prevState) => ({
        sectionsDropdown: [...prevState.sectionsDropdown, SectionDropdown.getSectionsDropdown()],
      }),
      () => {
        this.props.onChange({
          sectionsDropdown: this.state.sectionsDropdown,
          valid: this.isValidForm(),
        });
      }
    );
  };

  removeSection = (index) => {
    const sectionsDropdown = this.state.sectionsDropdown.map((data) => data);
    sectionsDropdown.splice(index, 1);
    this.setState({ sectionsDropdown });
    this.props.onChange({ sectionsDropdown, valid: this.isValidForm() });
  };

  onDropFile = async (files, sectionIndex) => {
    const sectionsDropdown = [...this.state.sectionsDropdown];
    sectionsDropdown[sectionIndex].loading = true;
    this.setState({ sectionsDropdown });

    const { path } = await pagesFileUpload(
      this.props.site,
      this.props.slug,
      files,
      this.props.pagesUploadFile
    );

    if (!sectionsDropdown[sectionIndex].pdf) {
      sectionsDropdown[sectionIndex].pdf = {};
    }
    sectionsDropdown[sectionIndex].pdf.link = path;
    sectionsDropdown[sectionIndex].pdf.file_name = files[0].name;
    delete sectionsDropdown[sectionIndex].loading;
    this.setState({ sectionsDropdown }, () => {
      this.props.onChange({ sectionsDropdown, valid: this.isValidForm() });
    });
  };

  deleteFile = async (sectionIndex) => {
    const sectionsDropdown = [...this.state.sectionsDropdown];
    sectionsDropdown[sectionIndex].pdf.link = null;
    sectionsDropdown[sectionIndex].pdf.file_name = '';
    this.setState({ sectionsDropdown }, () => {
      this.props.onChange({ sectionsDropdown, valid: this.isValidForm() });
    });
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        sectionsDropdown: arrayMove(this.state.sectionsDropdown, oldIndex, newIndex),
      },
      () => {
        this.props.onChange({
          sectionsDropdown: this.state.sectionsDropdown,
          valid: this.isValidForm(),
        });
      }
    );
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <SortableBlockContainer
        helperClass=""
        sectionsDropdown={this.state.sectionsDropdown}
        componentTitle={this.state.componentTitle}
        enableMultipleExpand={this.state.enableMultipleExpand}
        onChange={this.onChange}
        removesection={this.removeSection}
        addSection={this.addSection}
        onSortEnd={this.onDragEnd}
        onDropFile={this.onDropFile}
        deleteFile={this.deleteFile}
        useWindowAsScrollContainer
        useDragHandle
        axis="xy"
        delay={100}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pagesUploadFile,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(SectionDropdown));
