import React, { FC, useState, ReactNode } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';

import { SectorAccessModal, SvgIcon, Position } from 'modules/shared';

import styles from './style.scss';

interface SectorAccessProps {
  hasAccess: boolean;
  position: Position;
  highlighted?: boolean;
  blurIcon?: boolean;
  children: ReactNode;
}

/**
 * @returns{JSX.Element}
 */
const SectorAccess: FC<SectorAccessProps> = ({
  hasAccess,
  position,
  highlighted,
  blurIcon,
  children,
}) => {
  const [show, setShow] = useState(false);

  const right = position === Position.RIGHT;
  const center = position === Position.CENTER;

  /**
   * @description Open modal.
   */
  const open = () => {
    setShow(true);
  };

  /**
   * @description Close modal.
   */
  const close = () => {
    setShow(false);
  };

  if (hasAccess) {
    return <>{children}</>;
  }

  return (
    <>
      <SectorAccessModal open={show} close={close} />
      <div
        className={classNames('blur-container', {
          right,
        })}
        onClick={open}>
        <div
          className={classNames('blur-filter', {
            center,
          })}>
          {children}
        </div>
        <div
          className={classNames('icon-container', {
            center,
            blur: blurIcon,
          })}>
          <SvgIcon
            className={classNames('icon-lock', {
              center,
              right,
            })}
            name={highlighted ? 'highlighted-lock' : 'lock'}
          />
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(SectorAccess);
