import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getArticleDownloads,
  getArticleHeader,
  getArticleUsers,
  resetCurrentArticle,
} from 'modules/admin/main/actions/analytics';
import { hideLoader, showLoader } from 'modules/main/actions/loader';

import ArticleAnalyticsDetail from '../../analytics/ArticleAnalyticsDetail';
/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ admin, app }) => {
  return {
    detail: admin.analytics.currentArticle || {},
    loading: app.loader.showCount > 0,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getArticleHeader,
      getArticleUsers,
      getArticleDownloads,
      resetCurrentArticle,
      showLoader,
      hideLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAnalyticsDetail);
