import * as AnalyticsService from '../services/analytics';
import fileDownload from 'js-file-download';
import { formatDate } from 'common';

export const GET_SUMMARY_ANALYTICS = 'GET_SUMMARY_ANALYTICS';
export const GET_ARTICLE_ANALYTICS = 'GET_ARTICLE_ANALYTICS';
export const GET_USER_ANALYTICS = 'GET_USER_ANALYTICS';

export const GET_USER_ANALYTICS_HEADER = 'GET_USER_ANALYTICS_HEADER';
export const GET_USER_ANALYTICS_LOGINS = 'GET_USER_ANALYTICS_LOGINS';
export const GET_USER_ANALYTICS_VIEWS = 'GET_USER_ANALYTICS_VIEWS';
export const GET_USER_ANALYTICS_DOWNLOADS = 'GET_USER_ANALYTICS_DOWNLOADS';
export const RESET_CURRENT_USER_ANALYTICS = 'RESET_CURRENT_USER_ANALYTICS';

export const GET_ARTICLE_ANALYTICS_USERS = 'GET_ARTICLE_ANALYTICS_USERS';
export const GET_ARTICLE_ANALYTICS_DOWNLOADS = 'GET_ARTICLE_ANALYTICS_DOWNLOADS';
export const GET_ARTICLE_ANALYTICS_HEADER = 'GET_ARTICLE_ANALYTICS_HEADER';
export const RESET_CURRENT_ARTICLE_ANALYTICS = 'RESET_CURRENT_ARTICLE_ANALYTICS';

/**
 * @returns {Function}
 */
export const getSummaryAnalytics = () => async (dispatch) => {
  const summary = await AnalyticsService.getSummaryAnalytics();

  dispatch({
    type: GET_SUMMARY_ANALYTICS,
    payload: summary,
  });

  return summary;
};

/**
 * @param {Object} options Query options
 *
 * @returns {Function}
 */
export const getArticleAnalytics = (options) => async (dispatch) => {
  const articleAnalytics = await AnalyticsService.getArticleAnalytics(options);

  dispatch({
    type: GET_ARTICLE_ANALYTICS,
    payload: articleAnalytics,
  });

  return articleAnalytics;
};

/**
 * @param {Object} options Query options
 *
 * @returns {Function}
 */
export const getEdgeArticleAnalytics = (options) => async (dispatch) => {
  const articleAnalytics = await AnalyticsService.getEdgeArticleAnalytics(options);

  dispatch({
    type: GET_ARTICLE_ANALYTICS,
    payload: articleAnalytics,
  });

  return articleAnalytics;
};

/**
 * @param {Object} options Query options
 *
 * @returns {Function}
 */
export const getEdgePlusArticleAnalytics = (options) => async (dispatch) => {
  const articleAnalytics = await AnalyticsService.getEdgePlusArticleAnalytics(options);

  dispatch({
    type: GET_ARTICLE_ANALYTICS,
    payload: articleAnalytics,
  });

  return articleAnalytics;
};

/**
 * @param {Object} options Query options
 *
 * @returns {Function}
 */
export const getUserAnalytics = (options) => async (dispatch) => {
  const userAnalytics = await AnalyticsService.getUserAnalytics(options);

  dispatch({
    type: GET_USER_ANALYTICS,
    payload: userAnalytics,
  });

  return userAnalytics;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getUserHeader = (id) => async (dispatch) => {
  const userHeader = await AnalyticsService.getUserHeader(id);

  dispatch({
    type: GET_USER_ANALYTICS_HEADER,
    payload: userHeader,
  });

  return userHeader;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getUserViews = (id) => async (dispatch) => {
  const userViews = await AnalyticsService.getUserViews(id);

  dispatch({
    type: GET_USER_ANALYTICS_VIEWS,
    payload: userViews,
  });

  return userViews;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getUserLogins = (id) => async (dispatch) => {
  const userLogins = await AnalyticsService.getUserLogins(id);

  dispatch({
    type: GET_USER_ANALYTICS_LOGINS,
    payload: userLogins,
  });

  return userLogins;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getUserDownloads = (id) => async (dispatch) => {
  const userDownloads = await AnalyticsService.getUserDownloads(id);

  dispatch({
    type: GET_USER_ANALYTICS_DOWNLOADS,
    payload: userDownloads,
  });

  return userDownloads;
};

/**
 * @returns {Object}
 */
export const resetCurrentUser = () => {
  return {
    type: RESET_CURRENT_USER_ANALYTICS,
  };
};

/**
 * @param {number} id User ID
 * @param {boolean} just_show_active query param

 * @returns {Function}
 */
export const getArticleHeader = (id, just_show_active) => async (dispatch) => {
  const articleHeader = await AnalyticsService.getArticleHeader(id, just_show_active);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_HEADER,
    payload: articleHeader,
  });

  return articleHeader;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getEdgeArticleHeader = (id) => async (dispatch) => {
  const articleHeader = await AnalyticsService.getEdgeArticleHeader(id);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_HEADER,
    payload: articleHeader,
  });

  return articleHeader;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getEdgePlusArticleHeader = (id) => async (dispatch) => {
  const articleHeader = await AnalyticsService.getEdgePlusArticleHeader(id);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_HEADER,
    payload: articleHeader,
  });

  return articleHeader;
};

/**
 * @param {number} id User ID
 * @param {boolean} just_show_active query param
 *
 * @returns {Function}
 */
export const getArticleUsers = (id, just_show_active) => async (dispatch) => {
  const articleUsers = await AnalyticsService.getArticleUsers(id, just_show_active);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_USERS,
    payload: articleUsers,
  });

  return articleUsers;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getEdgeArticleUsers = (id) => async (dispatch) => {
  const articleUsers = await AnalyticsService.getEdgeArticleUsers(id);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_USERS,
    payload: articleUsers,
  });

  return articleUsers;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getEdgePlusArticleUsers = (id) => async (dispatch) => {
  const articleUsers = await AnalyticsService.getEdgePlusArticleUsers(id);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_USERS,
    payload: articleUsers,
  });

  return articleUsers;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getArticleDownloads = (id) => async (dispatch) => {
  const articleDownloads = await AnalyticsService.getArticleDownloads(id);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_DOWNLOADS,
    payload: articleDownloads,
  });

  return articleDownloads;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getEdgeArticleDownloads = (id) => async (dispatch) => {
  const articleDownloads = await AnalyticsService.getEdgeArticleDownloads(id);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_DOWNLOADS,
    payload: articleDownloads,
  });

  return articleDownloads;
};

/**
 * @param {number} id User ID
 *
 * @returns {Function}
 */
export const getEdgePlusArticleDownloads = (id) => async (dispatch) => {
  const articleDownloads = await AnalyticsService.getEdgePlusArticleDownloads(id);

  dispatch({
    type: GET_ARTICLE_ANALYTICS_DOWNLOADS,
    payload: articleDownloads,
  });

  return articleDownloads;
};

/**
 * @returns {Object}
 */
export const resetCurrentArticle = () => {
  return {
    type: RESET_CURRENT_ARTICLE_ANALYTICS,
  };
};

/**
 * @param {Object} options Query options
 *
 * @returns {Function}
 */
export const exportUserAnalytics = (options) => async () => {
  const data = await AnalyticsService.exportUserAnalytics(options);
  fileDownload(data, `user-analytics-${formatDate(new Date(), { timeFormat: true })}.csv`);

  return data;
};

/**
 * @param {Object} options Query options
 *
 * @returns {Function}
 */
export const exportArticleAnalytics = (options) => async () => {
  const data = await AnalyticsService.exportArticleAnalytics(options);
  fileDownload(data, `article-analytics-${formatDate(new Date(), { timeFormat: true })}.csv`);

  return data;
};
