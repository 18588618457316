import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LightBox, Html } from 'modules/shared';

/**
 * @param {string} content HTML Content
 * @param {boolean} topDivider Should the top divider be shown
 * @param {boolean} bottomDivider Should the bottom divider be shown
 * @param {boolean} showBottomSmall Should the bottom divider be shown at small screen
 * @param {string | Object} title Content Title
 * @returns {JSX.Element | null}
 * @constructor
 */
const HtmlContent = ({ content, topDivider, bottomDivider, showBottomSmall, title }) => {
  return content ? (
    <div
      className={classNames('html-content-container', {
        'show-bottom-divider-small-screen': showBottomSmall,
      })}>
      {topDivider ? <hr /> : null}
      {title ? <h1>{title}</h1> : null}
      <div className="content post-html-content">
        <Html content={content} />
      </div>
      {bottomDivider || showBottomSmall ? <hr className="html-content-divider" /> : null}
      <LightBox containerClassName="post-html-content" />
    </div>
  ) : null;
};

HtmlContent.propTypes = {
  content: PropTypes.string,
  topDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
  showBottomSmall: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default HtmlContent;
