import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ImageComponent.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FileUpload, FieldGroup } from 'modules/admin/shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pagesFileUpload } from 'common';
import { pagesUploadFile } from 'modules/admin/main/actions/file-upload';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

class ImageComponent extends Component {
  static componentType = 'image-component';
  static blockName = 'Image';
  static icon = 'fa fa-image';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
    pagesUploadFile: PropTypes.func.isRequired,
  };

  state = {
    title: '',
    image: {},
    fileAlt: '',
    fileTitle: '',
    loading: false,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const { loading, ...state } = this.state;
      this.props.onChange({ ...state, valid: this.isValid() });
    });
  };

  onDropImage = (files) => {
    this.setState({ image: files.length > 0 ? files[0] : null, loading: true }, async () => {
      const { path } = await pagesFileUpload(
        this.props.site,
        this.props.slug,
        files,
        this.props.pagesUploadFile
      );
      this.setState({ loading: false });
      this.props.onChange({ image: path, valid: this.isValid() });
    });
  };

  deleteFile = async () => {
    this.setState(
      {
        image: null,
      },
      () => {
        const { image } = this.state;
        this.props.onChange({ image, valid: this.isValid() });
      }
    );
  };

  isValid = () => {
    return (
      (this.state.image && !isEmpty(this.state.image)) ||
      (this.state.title && this.state.title.length > 0)
    );
  };

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.setState(this.props.data);
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        <div>
          <FieldGroup
            type="text"
            name="title"
            label={<FormattedMessage id="ADMIN.TITLE" />}
            placeholderId="ADMIN.TITLE"
            value={this.state.title}
            onChange={this.onChange}
          />
        </div>
        <div>
          <FormGroup className="image-preview-container">
            <ControlLabel>
              <FormattedMessage id="ADMIN.IMAGE" />
            </ControlLabel>
            <FileUpload
              file={this.state.image}
              fileTitle={this.state.fileTitle}
              fileAlt={this.state.fileAlt}
              onChange={this.onChange}
              onDropFile={this.onDropImage}
              deleteFile={this.deleteFile}
              loading={this.state.loading}
            />
          </FormGroup>
        </div>
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pagesUploadFile,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(ImageComponent));
