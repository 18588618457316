import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { EditorTitle, Button } from 'modules/admin/shared';
import { table } from 'modules/shared';
import FilterSection from './FilterSection';

import { formatDate, getDSTDateString } from 'common';

class ArticleAnalyticsList extends Component {
  static propTypes = {
    getSummaryAnalytics: PropTypes.func.isRequired,
    exportArticleAnalytics: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string,
    basePath: PropTypes.string,
  };

  /**
   * @description Get summary analytics for header.
   */
  componentDidMount() {
    this.props.getSummaryAnalytics();
  }

  /**
   * @returns {string} type of app
   * */
  getApp() {
    const { basePath } = this.props;

    if (basePath.includes('analyst')) {
      return '';
    } else if (basePath.includes('edge-plus')) {
      return 'edgeplus/';
    } else if (basePath.includes('edge')) {
      return 'edge/';
    }

    return '';
  }

  export = () => {
    const { search, from, to } = this.props;
    this.props.exportArticleAnalytics({ search, from, to, app: this.getApp() });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { fetchData, data, loading, pages, onSearch, search, from, to } = this.props;
    return (
      <div>
        <EditorTitle title={<FormattedMessage id="ADMIN.ANALYTICS.ARTICLE_ANALYTICS" />}>
          <Button onClick={this.export} disabled={(data && !data.length) || !data}>
            <FormattedMessage id="ADMIN.ANALYTICS.EXPORT" />
          </Button>
        </EditorTitle>
        <FilterSection onSearch={onSearch} from={from} to={to} search={search} />
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.TITLE" />,
                    accessor: 'title',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    Cell: ({ original }) => (
                      <Link to={`${this.props.basePath}/article/${original.id}`}>
                        {original.title}
                      </Link>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.PUBLISH_DATE" />,
                    id: 'published_at',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    accessor: (d) => {
                      return formatDate(new Date(getDSTDateString(d.published_at)), {
                        timeFormat: true,
                      });
                    },
                    width: 200,
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_VIEWS_WEB" />,
                    accessor: 'web_views',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    maxWidth: 200,
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_VIEWS_MOBILE" />,
                    accessor: 'mobile_views',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    maxWidth: 200,
                  },
                ]}
                manual
                data={data}
                resizable={false}
                pages={pages}
                loading={loading}
                onFetchData={fetchData}
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default table()(ArticleAnalyticsList);
