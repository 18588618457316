import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Modal as BsModal, Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import withStyles from 'isomorphic-style-loader/withStyles';
import * as Yup from 'yup';

import { Modal, TextInputField, Button } from 'modules/shared';
import { ModalHeader, ModalGrid } from 'modules/shared';
import { sendSubscription } from 'modules/shared/services/sectors';

import styles from './style.scss';

/**
 * @returns {JSX.Element}
 */
const SectorAccessModal = ({ open, close, intl }) => {
  const formikRef = useRef(null);
  const initialValues = { message: '' };
  const validationSchema = Yup.object().shape({
    message: Yup.string(),
  });

  /**
   * @description submit.
   */
  const submitHandler = async ({ message }) => {
    try {
      await sendSubscription({ message });
      toast.success(<FormattedMessage id="MAIN.SECTOR_ACCESS_MODAL.SUCCESS" />);
      close();
    } catch (e) {
      toast.error(<FormattedMessage id="MAIN.SECTOR_ACCESS_MODAL.ERROR" />);
    }
  };

  /**
   * @description submit.
   */
  const handleOnSubmit = () => {
    formikRef.current.submitForm();
  };

  return (
    <Modal dialogClassName="sector-access-modal" show={open} bsSize="large" onHide={close}>
      <ModalHeader close={close} />
      <BsModal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          innerRef={formikRef}>
          {({ isSubmitting }) => (
            <Form>
              <div className="get-in-touch">
                <FormattedHTMLMessage id="MAIN.SECTOR_ACCESS_MODAL.GET_IN_TOUCH" />
              </div>
              <ModalGrid>
                <Col>
                  <Field
                    component={TextInputField}
                    componentClass="textarea"
                    name="message"
                    noResize
                    placeholder={intl.formatMessage({ id: 'MAIN.SECTOR_ACCESS_MODAL.MESSAGE' })}
                  />
                </Col>
                <Col>
                  <Button
                    onClick={handleOnSubmit}
                    loading={isSubmitting}
                    className="submit"
                    textColor="dark"
                    thin>
                    <FormattedMessage id="MAIN.SUBMIT" />
                  </Button>
                </Col>
              </ModalGrid>
            </Form>
          )}
        </Formik>
      </BsModal.Body>
    </Modal>
  );
};

SectorAccessModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  intl: PropTypes.object,
};

export default withStyles(styles)(injectIntl(SectorAccessModal));
