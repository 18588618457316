import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BsModal } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Svg, SvgIcon } from 'modules/shared';

import styles from './style.scss';

/**
 * @param {Function} close Close the modal.
 *
 * @returns {JSX.Element}
 */
const ModalHeader = ({ close }) => {
  return (
    <BsModal.Header>
      <BsModal.Title>
        <SvgIcon className="close" name="close" onClick={close} />
        <Svg className="logo" name="the-analyst-logo-dark" />
      </BsModal.Title>
    </BsModal.Header>
  );
};

ModalHeader.propTypes = {
  close: PropTypes.func,
};

export default withStyles(styles)(ModalHeader);
