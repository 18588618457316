import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';

import Env from 'env';
import { ModelPortfolioHistoryItemModel } from 'modules/analyst/models';
import styles from './ModelPortfolioHistory.scss';

interface ModelPortfolioHistoryProps {
  showLoader: () => void;
  hideLoader: () => void;
  label: string | React.ReactNode;
  historyItems: ModelPortfolioHistoryItemModel[];
  latest_id: number;
  style: string;
  systemSettings: any;
  basePath: string;
}

class ModelPortfolioHistory extends Component<ModelPortfolioHistoryProps> {
  static defaultProps = {
    basePath: '/app/model-portfolio',
  };

  render() {
    const { systemSettings, historyItems, basePath, label, style } = this.props;

    return (
      <Col xs={12} sm={12} className="p-0 model-portfolio-history">
        <div className="history-label">
          {systemSettings.model_portfolio_month_count}
          {label}
        </div>
        <div className="history-items">
          {historyItems
            ? historyItems.map((item) => (
                <div className="history-item" key={item.id}>
                  <Link to={`${basePath}/${item.id}`}>
                    <span className={style}>
                      <span className={style}>{item.title}</span>
                    </span>
                  </Link>
                </div>
              ))
            : null}
        </div>
        <div />
      </Col>
    );
  }
}

export default withStyles(styles)(ModelPortfolioHistory);
