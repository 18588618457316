import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { RadioButton, Button, CheckBox } from 'modules/shared';
import { appRoutes } from 'modules/analyst/appRoutes';
import * as Yup from 'yup';
import { withFormik } from 'formik';

/**
 * @param {Object} values Form values
 * @param {Object} errors Form errors
 * @param {Object} touched Form touched fields
 * @param {Function} handleChange Change handler
 * @param {Function} handle Blur handler
 * @param {Function} handleSubmit Submit function
 * @param {boolean} isSubmitting Form is submitting
 *
 * @returns {JSX.Element}
 */
const AnalystPreferencesForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  geographies,
  investmentStyles,
  marketcap,
}) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="tab-detail">
        <div className="title">
          <FormattedMessage id="MAIN.ANALYST_PREFERENCES" />
        </div>
        <div className="section">
          <div className="title">
            <FormattedMessage id="MAIN.PROFILE_MENU.HOME_SCREEN" />
          </div>
          {appRoutes.map((route) => {
            return route.path !== 'profile' ? (
              <div key={route.path}>
                <RadioButton
                  name="web_analyst_home_screen"
                  value={route.path}
                  label={route.title}
                  onChange={(e) => setFieldValue(e.target.name, e.target.checked && e.target.value)}
                  checked={values.web_analyst_home_screen === route.path}
                />
              </div>
            ) : null;
          })}
        </div>
        <div className="section">
          <div className="title">
            <FormattedMessage id="ADMIN.POSTS.GEOGRAPHY" />
          </div>
          {geographies.map((geography) => {
            return (
              <div key={geography.id}>
                <CheckBox
                  name="web_analyst_geography"
                  label={geography.title}
                  onChange={(e) =>
                    setFieldValue(
                      e.target.name,
                      e.target.checked
                        ? [...values.web_analyst_geography, geography]
                        : values.web_analyst_geography.filter((x) => x.id !== geography.id)
                    )
                  }
                  checked={values.web_analyst_geography.find((x) => x.id === geography.id) || false}
                />
              </div>
            );
          })}
        </div>
        <div className="section">
          <div className="title">
            <FormattedMessage id="ADMIN.POSTS.INVESTMENT_STYLES" />
          </div>
          {investmentStyles.map((investmentStyle) => {
            return (
              <div key={investmentStyle.id}>
                <CheckBox
                  name="web_analyst_investment_style"
                  label={investmentStyle.name}
                  onChange={(e) =>
                    setFieldValue(
                      e.target.name,
                      e.target.checked
                        ? [...values.web_analyst_investment_style, investmentStyle]
                        : values.web_analyst_investment_style.filter(
                            (x) => x.id !== investmentStyle.id
                          )
                    )
                  }
                  checked={
                    values.web_analyst_investment_style.find((x) => x.id === investmentStyle.id) ||
                    false
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="section">
          <div className="title">
            <FormattedMessage id="ADMIN.POSTS.MARKETCAPS" />
          </div>
          {marketcap.map((cap) => {
            return (
              <div key={cap.id}>
                <CheckBox
                  name="web_analyst_market_cap"
                  label={cap.name}
                  onChange={(e) =>
                    setFieldValue(
                      e.target.name,
                      e.target.checked
                        ? [...values.web_analyst_market_cap, cap]
                        : values.web_analyst_market_cap.filter((x) => x.id !== cap.id)
                    )
                  }
                  checked={values.web_analyst_market_cap.find((x) => x.id === cap.id) || false}
                />
              </div>
            );
          })}
        </div>
        <Button
          moduleType="main"
          textColor="dark"
          type="submit"
          className="short-width small"
          disabled={isSubmitting}>
          <FormattedMessage id="MAIN.UPDATE" />
        </Button>
      </div>
    </form>
  );
};

AnalystPreferencesForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  saveUserSettings: PropTypes.func,
  getUserSettings: PropTypes.func,
  getUserPreferences: PropTypes.func,
  isSubmitting: PropTypes.bool,
  geographies: PropTypes.array.isRequired,
  investmentStyles: PropTypes.array.isRequired,
  marketcap: PropTypes.array.isRequired,
  preferences: PropTypes.object.isRequired,
  getGeographies: PropTypes.func.isRequired,
  getInvestmentStyles: PropTypes.func.isRequired,
  getMarketcaps: PropTypes.func.isRequired,
};

const AnalystPreferences = withFormik({
  mapPropsToValues: ({ settings, preferences }) => {
    return {
      web_analyst_home_screen: settings.web_analyst_home_screen || appRoutes[0].path,
      web_analyst_geography: preferences.web_analyst_geography || [],
      web_analyst_investment_style: preferences.web_analyst_investment_style || [],
      web_analyst_market_cap: preferences.web_analyst_market_cap || [],
    };
  },
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const {
      web_analyst_home_screen,
      web_analyst_geography,
      web_analyst_investment_style,
      web_analyst_market_cap,
    } = values;

    setSubmitting(true);

    try {
      await Promise.all([
        props.saveUserSettings({ key: 'web_analyst_home_screen', value: web_analyst_home_screen }),
        props.saveUserPreferences({ key: 'web_analyst_geography', value: web_analyst_geography }),
        props.saveUserPreferences({
          key: 'web_analyst_investment_style',
          value: web_analyst_investment_style,
        }),
        props.saveUserPreferences({ key: 'web_analyst_market_cap', value: web_analyst_market_cap }),
      ]);

      await Promise.all([props.getUserSettings(), props.getUserPreferences()]);
    } finally {
      setSubmitting(false);
    }
  },
})(AnalystPreferencesForm);

export default AnalystPreferences;
