import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from 'isomorphic-style-loader/withStyles';

import { Spinner } from 'modules/shared';

import style from './Bookmark.scss';
/**
 * @param {boolean} bookmarking Is the bookmark action in progress.
 * @param {boolean} bookmarked Is the current item in bookmarked state.
 * @param {Function} onClick Click handler.
 * @param {string} className Class name.
 * @returns {JSX.Element}
 */
const Bookmark = ({ bookmarking, bookmarked, onClick, className }) => {
  return (
    <div className={classNames('d-flex align-center justify-center bookmark-icon', className)}>
      {bookmarking ? <Spinner size={'small'} className="bookmark-spinner pull-right" /> : null}
      {!bookmarking ? (
        <i
          onClick={onClick}
          className={classNames(
            'bookmark-indicator',
            bookmarked ? 'icon-bookmark-filled' : 'icon-bookmark-outline'
          )}
        />
      ) : null}
    </div>
  );
};

Bookmark.propTypes = {
  bookmarking: PropTypes.bool,
  className: PropTypes.string,
  bookmarked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
};

export default withStyles(style)(Bookmark);
