import React, { Component } from 'react';
import { PageEditor } from 'modules/admin/shared';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class DisclaimersNDisclosures extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  /**
   * @returns {JSX.Element} Disclaimers And Disclosures component.
   */
  render() {
    return (
      <PageEditor
        site="analyst"
        viewLink="/disclaimers-n-disclosures"
        slug="disclaimers-n-disclosures"
        title={<FormattedMessage id="ANALYST.DISCLAIMERS_AND_DISCLOSURES" />}
      />
    );
  }
}

export default DisclaimersNDisclosures;
