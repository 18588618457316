import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextInput, Button } from 'modules/shared';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import Env from 'env';
import { DateTimePicker } from 'modules/shared';
import moment from 'moment';

/**
 * @param {Object} values Form values
 * @param {Object} errors Form errors
 * @param {Object} touched Form touched fields
 * @param {Function} handleChange Change handler
 * @param {Function} handle Blur handler
 * @param {Function} handleSubmit Submit function
 * @param {boolean} isSubmitting Form is submitting
 *
 * @returns {JSX.Element}
 */
const InnerEditProfileForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  exportProfile,
  deleteProfile,
}) => {
  return (
    <form onSubmit={handleSubmit} noValidate className="edit-profile-form">
      <div className="edit-profile tab-detail">
        <div className="d-flex flex-wrap align-center profile-info-container">
          <div className="title">
            <FormattedMessage id="MAIN.PROFILE_MENU.EDIT_PROFILE" />
          </div>

          <div className="profile-actions">
            <div className="action" onClick={exportProfile}>
              <FormattedMessage id="MAIN.PROFILE_MENU.EXPORT_PROFILE" />
              <i className="icon-open-outline" />
            </div>
            <div className="action" onClick={deleteProfile}>
              <FormattedMessage id="MAIN.PROFILE_MENU.DELETE_PROFILE" />
              <i className="icon-delete-outline" />
            </div>
          </div>
        </div>

        <div className="your-details section">
          <div className="title">
            <FormattedMessage id="MAIN.PROFILE_MENU.YOUR_DETAILS" />
          </div>
          <TextInput
            name="firstname"
            label={<FormattedMessage id="MAIN.FIRST_NAME" />}
            className="square-style small"
            required
            white
            inlineError
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstname}
            touched={touched.firstname}
            error={errors.firstname}
          />
          <TextInput
            name="lastname"
            label={<FormattedMessage id="MAIN.LAST_NAME" />}
            className="square-style small"
            required
            white
            inlineError
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastname}
            touched={touched.lastname}
            error={errors.lastname}
          />
          <DateTimePicker
            name="birthdate"
            className="square-style"
            label={<FormattedMessage id="ADMIN.USERS.BIRTH_DATE" />}
            selected={values.birthdate}
            onChange={(date, e) => {
              handleChange({
                ...e,
                target: { ...e.target, name: 'birthdate', value: date },
              });
            }}
            inlineError
            touched={touched.birthdate}
            error={errors.birthdate}
          />
          <TextInput
            name="phone"
            label={<FormattedMessage id="ADMIN.USERS.PHONE" />}
            className="square-style small"
            white
            inlineError
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            touched={touched.phone}
            error={errors.phone}
          />
        </div>
        <div className="change-password section">
          <div className="title">
            <FormattedMessage id="MAIN.PROFILE_MENU.CHANGE_PASSWORD" />
          </div>
          <TextInput
            type="password"
            name="password"
            label={<FormattedMessage id="MAIN.PASSWORD" />}
            className="square-style small"
            white
            inlineError
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            touched={touched.password}
            error={errors.password}
          />
          <TextInput
            type="password"
            name="repeatPassword"
            label={<FormattedMessage id="MAIN.REPEAT_PASSWORD" />}
            className="square-style small"
            white
            inlineError
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.repeatPassword}
            touched={touched.repeatPassword}
            error={errors.repeatPassword}
          />
        </div>
        <Button
          moduleType="main"
          textColor="dark"
          type="submit"
          className="short-width small"
          loading={isSubmitting}>
          <FormattedMessage id="MAIN.UPDATE" />
        </Button>
      </div>
    </form>
  );
};

InnerEditProfileForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  exportProfile: PropTypes.func,
  deleteProfile: PropTypes.func,
};

const EditProfile = withFormik({
  mapPropsToValues: (props) => {
    const { profile } = props;

    profile.firstname = profile.firstname ? profile.firstname : '';
    profile.lastname = profile.lastname ? profile.lastname : '';

    return { ...profile, password: '', repeatPassword: '' };
  },
  validationSchema: Yup.lazy((values) => {
    return Yup.object().shape({
      firstname: Yup.string().required(<FormattedMessage id="MAIN.REQUIRED_FIELD" />),
      lastname: Yup.string().required(<FormattedMessage id="MAIN.REQUIRED_FIELD" />),
      password: Yup.string().matches(
        Env.PASSWORD_PATTERN,
        <FormattedMessage id="MAIN.PASSWORD_REQUIREMENT" />
      ),
      repeatPassword: values.password
        ? Yup.string()
            .oneOf([Yup.ref('password')], <FormattedMessage id="MAIN.PASSWORDS_DONT_MATCH" />)
            .required(<FormattedMessage id="MAIN.REQUIRED_FIELD" />)
        : Yup.string().oneOf(
            [Yup.ref('password')],
            <FormattedMessage id="MAIN.PASSWORDS_DONT_MATCH" />
          ),
      phone: Yup.string()
        .matches(Env.PHONE_PATTERN, <FormattedMessage id="MAIN.PHONE_REQUIREMENT" />)
        .nullable(),
    });
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(true);
    const { firstname, lastname, password, phone, birthdate } = values;

    const profile = {
      firstname,
      lastname,
      phone,
      birthdate: birthdate ? moment(birthdate).format(Env.API_DATE_FORMAT) : null,
    };

    if (password) {
      profile.password = password;
    }

    await props.handleSubmit(profile);
    setSubmitting(false);
  },
})(InnerEditProfileForm);

export default EditProfile;
