import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import StarIcon from '../star-icon/StarIcon';

import styles from './StarDescription.scss';

export interface StarDescriptionProps {
  star_description?: string | null;
}

function StarDescription({ star_description }: StarDescriptionProps) {
  return star_description ? (
    <p className="d-flex align-baseline star-description-container">
      <StarIcon className="star-description-star" />
      <span className="mr-1">=</span>
      <span>{star_description}</span>
    </p>
  ) : null;
}

export default withStyles(styles)(StarDescription);
