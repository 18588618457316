import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { v1 as uuid } from 'uuid';
import withStyles from 'isomorphic-style-loader/withStyles';

import * as Blocks from '../blocks';
import Toolbox from './Toolbox';
import { Delete, DragHandle, EditorTitle, Button } from 'modules/admin/shared';
import { EmptyState } from 'modules/shared';

import style from './PageEditor.scss';

const SortableBlock = SortableElement(({ block, remove, onChange, setReady, site, slug }) => (
  <div className="component-wrapper">
    <DragHandle />
    <div className="main">
      <div className="header">
        {block.blockName}
        <Delete onSubmit={() => remove(block.id)} />
      </div>
      <block.Component
        data={block.data}
        onChange={onChange(block.id)}
        setReady={setReady}
        site={site}
        slug={slug}
      />
    </div>
  </div>
));

const SortableBlockContainer = SortableContainer(
  ({ layout, remove, onChange, setReady, site, slug }) => {
    return (
      <div>
        {layout.map((block, index) => (
          <SortableBlock
            key={block.id}
            index={index}
            block={block}
            remove={remove}
            onChange={onChange}
            site={site}
            slug={slug}
            setReady={setReady}
          />
        ))}
      </div>
    );
  }
);

class PageEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func,
    hideLoader: PropTypes.func,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    updatePage: PropTypes.func.isRequired,
    getPage: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    viewLink: PropTypes.string,
  };

  state = {
    layout: [],
    valid: false,
    toolboxOpen: true,
    dataLoading: true,
    saving: false,
    isFormReady: true,
  };

  /**
   * @description Component loaded
   * */
  async componentDidMount() {
    this.props.showLoader();
    const data = await this.props.getPage(this.props.site, this.props.slug);

    let layout;
    if (data.length) {
      layout = data.map((item) => {
        const component = Object.values(Blocks).find(
          (cmp) => cmp.componentType === item.componentType
        );
        item.blockName = component.blockName;
        return {
          ...item,
          Component: component,
        };
      });
    } else {
      layout = [];
    }

    layout.map((block) => {
      block.data.valid = true;
    });

    this.setState({ layout, valid: true, dataLoading: false });
    this.props.hideLoader();
  }

  /**
   * @description Toggles the state of the toolbox drawer.
   */
  toggleToolbox = () => {
    this.setState((prevState) => {
      return { toolboxOpen: !prevState.toolboxOpen };
    });
  };

  /**
   * @description Removes the element from the given index.
   * @param {string} id ID of the element.
   */
  remove = (id) => {
    const layout = this.state.layout.filter((block) => block.id !== id);
    this.setState(() => ({
      layout,
      valid: this.checkValidation(layout),
    }));
  };

  /**
   * @description Appends a new block to the layout array.
   * @param {block} block The new block to be appended.
   */
  add = (block) => {
    this.setState({
      layout: [
        ...this.state.layout,
        {
          Component: block,
          blockName: block.blockName,
          data: {},
          id: uuid(),
          componentType: block.componentType,
        },
      ],
      valid: false,
    });
  };

  onChange = (id) => (data) => {
    const index = this.state.layout.findIndex((block) => block.id === id);
    const currentItem = this.state.layout[index];
    const item = {
      ...currentItem,
      data: {
        ...currentItem.data,
        ...data,
      },
    };

    const layout = Object.assign([], this.state.layout, { [index]: item });
    this.setState({ layout, valid: this.checkValidation(layout) });
  };

  setReady = (isFormReady) => {
    this.setState({ isFormReady });
  };

  save = async () => {
    const saved = this.state.layout.map((item) => {
      return {
        data: item.data,
        componentType: item.componentType,
        id: item.id,
      };
    });

    this.setState({ saving: true });
    await this.props.updatePage(this.props.site, this.props.slug, {
      content: saved,
    });
    this.setState({ saving: false });
    toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      layout: arrayMove(this.state.layout, oldIndex, newIndex),
    });
  };

  /**
   * @param {array} layout all components.
   *
   * @returns {boolean} validations
   */
  checkValidation = (layout) => {
    return !layout.find((block) => !block.data.valid);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className={classNames('page-editor', { 'toolbox-open': this.state.toolboxOpen })}>
        {!this.state.dataLoading && (
          <div className="editor-content">
            {!!this.state.layout.length && (
              <EditorTitle
                title={this.props.title}
                viewLink={this.props.viewLink}
                toolboxOpen={this.state.toolboxOpen}>
                <Button
                  onClick={this.save}
                  disabled={!this.state.valid || !this.state.isFormReady}
                  loading={this.state.saving}>
                  <FormattedMessage id="ADMIN.SAVE" />
                </Button>
              </EditorTitle>
            )}
            <SortableBlockContainer
              helperClass="sortable-page-editor-block"
              layout={this.state.layout}
              onChange={this.onChange}
              setReady={this.setReady}
              remove={this.remove}
              site={this.props.site}
              slug={this.props.slug}
              useWindowAsScrollContainer
              onSortEnd={this.onDragEnd}
              useDragHandle
              delay={100}
            />
            {!this.state.layout.length && (
              <EmptyState
                title={<FormattedMessage id="ADMIN.PAGE_EDITOR.EMPTY_TITLE" />}
                subTitle={<FormattedMessage id="ADMIN.PAGE_EDITOR.EMPTY_INFO" />}
              />
            )}
          </div>
        )}
        <Toolbox
          blocks={Object.values(Blocks)}
          addItem={this.add}
          open={this.state.toolboxOpen}
          toggle={this.toggleToolbox}
        />
      </div>
    );
  }
}

export default withStyles(style)(PageEditor);
