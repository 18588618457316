import { SAVE_PROFILE, GET_PROFILE, LOGOUT } from '../actions/users';

/**
 * @param {Object} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Object} Modified state.
 */
const profile = (state = null, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return action.payload;
    case SAVE_PROFILE:
      return action.payload;
    case LOGOUT:
      return null;
    default:
      return state;
  }
};

export default profile;
