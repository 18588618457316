export const CompanyStatusRaw = {
  active: 'active',
  inactive: 'inactive',
};

export const CompanyStatus = {
  [CompanyStatusRaw.active]: {
    name: 'Active',
  },
  [CompanyStatusRaw.inactive]: {
    name: 'Inactive',
  },
};
