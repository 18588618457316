import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Footer, Content, withSSR } from 'modules/shared';
import { ComponentLoader } from 'modules/main';
import PropTypes from 'prop-types';
import { getPage } from 'modules/shared/actions/pages';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './OurPeople.scss';
import Env from 'env';
import { PublicHeader } from 'modules/analyst/modules/shared';
import { analystFooterLinks } from 'common';

class OurPeople extends Component {
  static propTypes = {
    content: PropTypes.array,
    isLoading: PropTypes.bool,
  };

  /**
   * @param {Function} param.dispatch Action dispatcher
   * @returns {Promise}
   */
  static async getInitialData({ dispatch }) {
    return await dispatch(getPage('landing', 'our-people'));
  }

  /**
   * @returns {JSX.Element} - Our People component
   */
  render() {
    return (
      <div className="analyst-home our-people white-background">
        <FormattedMessage id="MAIN.HEADER.OUR_PEOPLE">
          {(title) => (
            <Helmet defer={false}>
              <title itemProp="name">{title}</title>
              <link rel="canonical" href={`${Env.SITE_URL}people`} itemProp="url" />
            </Helmet>
          )}
        </FormattedMessage>
        {/*<MainHeader />*/}
        <PublicHeader />

        <Content>
          <ComponentLoader components={this.props.content} loading={this.props.isLoading} />
        </Content>

        {/*<Footer header={{ toTop: true }} />*/}
        <Footer
          className="analyst analyst-link-hover"
          header={{
            toTop: true,
            icons: true,
            logoAnalyst: true,
            links: analystFooterLinks,
          }}
        />
      </div>
    );
  }
}

export default withSSR(withStyles(style)(OurPeople));
