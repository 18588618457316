import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getSummaryAnalytics,
  getArticleAnalytics,
  exportArticleAnalytics,
} from 'modules/admin/main/actions/analytics';

import ArticleAnalyticsList from '../../analytics/ArticleAnalyticsList';

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSummaryAnalytics,
      exportArticleAnalytics,
      fetchDataWs: getArticleAnalytics,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ArticleAnalyticsList);
