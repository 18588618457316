import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { VoteTypes } from 'common';
import {
  LikeDislikeButtons,
  PostDetailDivider,
  FeedbackPopup,
  MoreFeedbackText,
  QuestionLabel,
  StarIcon,
  InViewport,
  SentimentTrackerLogo,
  Roles,
  checkPermission,
  CanAccess,
  SectorAccess,
  Position,
} from 'modules/shared';

import Env from 'env';
import { debounce } from 'common';
import FeedbackCharts from './FeedbackCharts';

import styles from './LikeQuestions.scss';

const ANSWERING_DEBOUNCE = 500;

class LikeQuestions extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    systemSettings: PropTypes.object.isRequired,
    data: PropTypes.object,
    topDivider: PropTypes.bool,
    bottomDivider: PropTypes.bool,
    feedbackHorizontalBarChartProps: PropTypes.object,
    labelsNoTopMargin: PropTypes.bool,
    questionDescription: PropTypes.node,
    questionLabelMedium: PropTypes.bool,
    updateDataByAnswer: PropTypes.func,
    answerQuestion: PropTypes.func,
    hideFeedbackPopup: PropTypes.bool,
    showOnlyButtonAndChart: PropTypes.bool,
    showOnlyFirstQuestion: PropTypes.bool,
    inline: PropTypes.bool,
    redirectTo: PropTypes.string,
    titleHoverColor: PropTypes.string,
    starIconAtQuestionDescription: PropTypes.bool,
    starIconNextToVotingButtons: PropTypes.bool,
    showFeedbackAreaChart: PropTypes.bool,
    page: PropTypes.string.isRequired,
  };

  static defaultProps = {
    topDivider: true,
    bottomDivider: true,
  };

  state = {
    inViewport: false,
    showFeedbackPopup: true,
    answering: null,
    clickedAnswer: null,
  };

  /**
   * @param {boolean} inViewport is in viewport
   *
   * @description change inViewport state value
   */
  onViewportChange = (inViewport) => {
    this.setState({ inViewport });
    this.checkFirstLikeQuestionAnswered(inViewport);
  };

  /**
   *  @param {boolean} inViewport is in viewport
   */
  checkFirstLikeQuestionAnswered(inViewport) {
    const { data } = this.props;
    if (inViewport) {
      const firstQuestion = data?.like_questions?.find(
        (item) => item.type_id === Env.SHOW_FEEDBACK_CHARTS_ID
      );

      if (firstQuestion?.user_vote?.vote !== VoteTypes.undefined) {
        this.hideFeedbackPopup();
      }
    }
  }

  /**
   * @returns {boolean} display popup or not
   */
  isDisplayFeedbackPopup = () => {
    const { hideFeedbackPopup } = this.props;
    const { inViewport, showFeedbackPopup } = this.state;

    return !hideFeedbackPopup && showFeedbackPopup && !inViewport;
  };

  /**
   * @description countdown to hide feedback popup
   */
  hideFeedbackPopupWithTimeout = () => {
    setTimeout(() => {
      this.hideFeedbackPopup();
    }, Env.FEEDBACK_POPUP_DISPLAY_DURATION);
  };

  /**
   * @description hide feedback popup
   */
  hideFeedbackPopup = () => {
    this.setState({ showFeedbackPopup: false });
  };

  /**
   * @param {number} id of question
   * @param {Object} user_vote user vote of question
   *
   * @returns {boolean} show feedback text
   */
  showMoreFeedbackText = (id, user_vote) => {
    const { data } = this.props;

    return (
      !data.survey_taken &&
      id === Env.SHOW_MORE_FEEDBACK_SURVEY_ID &&
      user_vote &&
      user_vote.vote !== VoteTypes.undefined
    );
  };

  /**
   * @param {number} type_id of question
   *
   * @returns {boolean} show feedback charts
   */
  showChartsToQuestion = (type_id) => type_id === Env.SHOW_FEEDBACK_CHARTS_ID;

  /**
   * @param {number} type_id of question
   * @param {Object} user_vote user vote of question
   *
   * @returns {boolean} show feedback text
   */
  showCharts = (type_id, user_vote) =>
    this.showChartsToQuestion(type_id) && user_vote && user_vote.vote !== VoteTypes.undefined;

  /**
   *
   * @param {Object} item data Object.
   * @param {Object} clickedAnswer clickedAnswer Object.
   * @returns {void}
   */
  answerQuestion = async (item, clickedAnswer) => {
    const { answerQuestion, page } = this.props;

    this.setState({ answering: item.user_vote_id, clickedAnswer });

    const voteResults = await answerQuestion({
      user_vote_id: item.user_vote_id,
      vote: item.vote,
      source: page,
    });

    await this.updateData(item, voteResults);
    this.setFinishAnswer();
    this.checkFirstLikeQuestionAnswered(this.state.inViewport);
  };

  /**
   *
   * @param {Object} item data Object.
   * @param {Object} voteResults voteResults Object.
   * @returns {Promise | null}
   */
  updateData = async (item, voteResults) => {
    const { data, updateDataByAnswer } = this.props;

    if (updateDataByAnswer) {
      const saveData = JSON.parse(JSON.stringify({ ...data }));
      const questionIndex = saveData.like_questions.findIndex(
        (question) => question.user_vote.id === item.user_vote_id
      );

      saveData.like_questions[questionIndex].user_vote.vote = item.vote;
      saveData.like_questions[questionIndex].vote_results = this.mapDataToObject(
        this.showChartsToQuestion(item.type_id)
          ? voteResults
          : saveData.like_questions[questionIndex].vote_results
      );
      return updateDataByAnswer(saveData);
    }

    return null;
  };

  /**
   *
   * @param {Object} voteResults voteResults Object.
   * @param {number} index voteResults index.
   * @returns {Object}
   */
  mapDataToObject = (voteResults, index = 0) =>
    voteResults && Array.isArray(voteResults) && voteResults.length > index
      ? voteResults[index]
      : voteResults;

  /**
   * @description set answering to finish
   * @returns {void}
   */
  setFinishAnswer = debounce(
    () => this.setState({ answering: null, clickedAnswer: null }),
    ANSWERING_DEBOUNCE
  );

  /**
   *
   * @param {Object} user_vote user vote Object.
   * @param {bool} nosAccessToVote user has no access to vote.
   * @description show star icon
   * @returns {bool}
   */
  showStarIcon = ({ user_vote, vote_results }, nosAccessToVote) => {
    const sumVotes = vote_results ? vote_results.dislikeCount + vote_results.likeCount : 0;

    return (
      (user_vote.vote !== VoteTypes.undefined || nosAccessToVote) &&
      sumVotes <= Env.SENTIMENT_TRACKER_MAX_VOTE_TO_SHOW_STAR
    );
  };

  /**
   *
   * @description check user permission to access vote
   * @returns {bool}
   */
  hasAccessToVote = () => {
    return checkPermission([Roles.AnalystSubscriber], [], this.props.profile);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      data,
      topDivider,
      bottomDivider,
      feedbackHorizontalBarChartProps,
      labelsNoTopMargin,
      questionDescription,
      questionLabelMedium,
      showOnlyButtonAndChart,
      showOnlyFirstQuestion,
      inline,
      redirectTo,
      titleHoverColor,
      starIconAtQuestionDescription,
      starIconNextToVotingButtons,
      showFeedbackAreaChart,
      systemSettings,
    } = this.props;
    const { answering, clickedAnswer } = this.state;
    const hasAccessToVote = this.hasAccessToVote();

    return (
      <>
        <InViewport onViewportChange={this.onViewportChange}>
          <div className="like-question" id={Env.FEEDBACK_LINK}>
            {!showOnlyButtonAndChart &&
            topDivider &&
            ((data.like_questions && data.like_questions.length) || !data.survey_taken) ? (
              <PostDetailDivider className="like-top-divider" thickAnalystBorder />
            ) : null}

            {!showOnlyButtonAndChart && data.like_questions && data.like_questions.length ? (
              <>
                <SentimentTrackerLogo
                  textAlign="left"
                  verticalMargin
                  noTopMargin={labelsNoTopMargin}
                />
                <QuestionLabel
                  question={
                    <>
                      {hasAccessToVote
                        ? questionDescription || (
                            <FormattedMessage id="ANALYST.SENTIMENT_TRACKER_DESCRIPTION" />
                          )
                        : systemSettings.voting_button_note}
                      {starIconAtQuestionDescription &&
                      data.like_questions &&
                      Array.isArray(data.like_questions) &&
                      data.like_questions.length > 0 ? (
                        <StarIcon
                          className={classNames(
                            'star-icon-question-description',
                            this.showStarIcon(data.like_questions[0], !hasAccessToVote)
                              ? 'visible'
                              : 'invisible'
                          )}
                        />
                      ) : null}
                    </>
                  }
                  className={classNames('analyst-main-color mt-3 mb-3', {
                    'medium-color': questionLabelMedium,
                  })}
                />
              </>
            ) : null}

            {data.like_questions &&
              data.like_questions.map((likeQuestion, index) =>
                !showOnlyFirstQuestion || (showOnlyFirstQuestion && index === 0) ? (
                  <div
                    key={`${likeQuestion.type_id}_${likeQuestion.company_name || ''}`}
                    className={classNames({
                      'inline-like-question d-flex': showOnlyButtonAndChart && inline,
                    })}>
                    {!showOnlyButtonAndChart ? (
                      <QuestionLabel question={likeQuestion.question} />
                    ) : null}
                    {showOnlyButtonAndChart && redirectTo ? (
                      <span className="like-question-title">
                        <SectorAccess position={Position.RIGHT} hasAccess={data.has_sector_access}>
                          <Link
                            className={classNames('like-question-link', titleHoverColor, {
                              disabled: !data.has_sector_access,
                            })}
                            to={redirectTo}>
                            {data.title}
                          </Link>
                        </SectorAccess>
                        <StarIcon
                          className={classNames(
                            this.showStarIcon(likeQuestion, !hasAccessToVote)
                              ? 'visible'
                              : 'invisible'
                          )}
                        />
                      </span>
                    ) : null}
                    <div
                      className={classNames('d-flex align-center like-buttons-contianer', {
                        inline,
                      })}>
                      <LikeDislikeButtons
                        question={{
                          user_vote: likeQuestion.user_vote,
                          type_id: likeQuestion.type_id,
                        }}
                        iconClass={'icon-like'}
                        answerQuestion={this.answerQuestion}
                        answer={VoteTypes.like}
                        answering={answering}
                        clickedAnswer={clickedAnswer}
                        disabled={!hasAccessToVote || !data.has_sector_access}
                        hideAnswer={!data.has_sector_access}
                      />
                      <LikeDislikeButtons
                        question={{
                          user_vote: likeQuestion.user_vote,
                          type_id: likeQuestion.type_id,
                        }}
                        iconClass={'icon-dislike'}
                        answerQuestion={this.answerQuestion}
                        answer={VoteTypes.dislike}
                        answering={answering}
                        clickedAnswer={clickedAnswer}
                        disabled={!hasAccessToVote || !data.has_sector_access}
                        hideAnswer={!data.has_sector_access}
                      />
                      {starIconNextToVotingButtons &&
                      this.showChartsToQuestion(likeQuestion.type_id) ? (
                        <StarIcon
                          className={classNames(
                            'like-questions-voting-buttons-star-icon',
                            this.showStarIcon(likeQuestion, !hasAccessToVote)
                              ? 'visible'
                              : 'invisible'
                          )}
                        />
                      ) : null}
                    </div>
                    {this.showMoreFeedbackText(likeQuestion.type_id, likeQuestion.user_vote) ? (
                      <MoreFeedbackText />
                    ) : null}
                    <CanAccess
                      allowedRoles={[Roles.AnalystSubscriber]}
                      unauthenticatedComponent={
                        this.showChartsToQuestion(likeQuestion.type_id) ? (
                          <FeedbackCharts
                            likeQuestion={likeQuestion}
                            inline={inline}
                            showOnlyButtonAndChart={showOnlyButtonAndChart}
                            answering={answering}
                            feedbackHorizontalBarChartProps={feedbackHorizontalBarChartProps}
                            showFeedbackAreaChart={showFeedbackAreaChart}
                            data={data}
                          />
                        ) : null
                      }>
                      {this.showCharts(likeQuestion.type_id, likeQuestion.user_vote) ? (
                        <FeedbackCharts
                          likeQuestion={likeQuestion}
                          inline={inline}
                          showOnlyButtonAndChart={showOnlyButtonAndChart}
                          answering={answering}
                          feedbackHorizontalBarChartProps={feedbackHorizontalBarChartProps}
                          showFeedbackAreaChart={showFeedbackAreaChart}
                          data={data}
                        />
                      ) : null}
                    </CanAccess>
                  </div>
                ) : null
              )}
            {bottomDivider && data.like_questions && data.like_questions.length ? (
              <PostDetailDivider
                className={classNames('like-bottom-divider', { inline })}
                thickAnalystBorder
              />
            ) : null}
          </div>
        </InViewport>
        <FeedbackPopup
          open={this.isDisplayFeedbackPopup()}
          data={data}
          answering={answering}
          answerQuestion={this.answerQuestion}
          clickedAnswer={clickedAnswer}
          onClose={this.hideFeedbackPopup}
          hasAccessToVote={hasAccessToVote}
        />
      </>
    );
  }
}

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    profile: app.profile,
    systemSettings: app.systemSettings.all,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(LikeQuestions));
