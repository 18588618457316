import React, { Component } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { history, match, location } from 'common';
import Env from 'env';

export const injectedAppProps = {
  sidebarOpen: PropTypes.bool,
  searchOpen: PropTypes.bool,
  searchValue: PropTypes.string,
  onSearch: PropTypes.func,
  onSearchChange: PropTypes.func,
  toggleSearch: PropTypes.func,
  toggleSidebar: PropTypes.func,
  setEditItem: PropTypes.func,
  profile: PropTypes.object,
  intl: PropTypes.object,
};

/**
 * @param {Object} state - state object.
 *
 * @returns {Object} Profile.
 */
const mapProfile = ({ app }) => {
  return { profile: app.profile };
};

/**
 * @param {Function} WrappedComponent Component to wrap
 *
 * @returns {Function}
 */
const appWrapper = (WrappedComponent) => {
  return injectIntl(
    connect(mapProfile)(
      class AppWrapper extends Component {
        static propTypes = {
          history,
          match,
          location,
        };

        state = {
          sidebarOpen: true,
          searchOpen: false,
          searchValue: '',
          editItem: null,
        };

        /**
         * @description Closes the sidebar on mobile and closes the search bar on state change.
         * @param {Object} prevProps Props before update.
         */
        componentDidUpdate(prevProps) {
          const search = qs.parse(this.props.location.search);
          if (this.props.location !== prevProps.location) {
            if (window.innerWidth < Env.BREAKPOINTS.MOBILE_SCREEN) {
              this.toggleSidebar(null, false);
            }

            if (!search.s) {
              this.toggleSearch(null, false);
              this.onSearchChange();
            }
          }
        }

        toggleSidebar = (e, state) => {
          this.setState((prevState) => ({
            sidebarOpen: state === undefined ? !prevState.sidebarOpen : state,
          }));
        };

        toggleSearch = (e, state) => {
          this.setState((prevState) => ({
            searchOpen: state === undefined ? !prevState.searchOpen : state,
          }));
        };

        onSearchChange = (value = '') => {
          this.setState({ searchValue: value });
        };

        onSearch = () => {
          if (this.state.searchValue) {
            this.props.history.push(
              `${this.props.match.path}/search?${qs.stringify({
                s: this.state.searchValue,
              })}`
            );
          }
        };

        setEditItem = (item) => {
          this.setState({ editItem: item });
        };

        /**
         * @returns {JSX.Element}
         */
        render() {
          return (
            <WrappedComponent
              {...this.props}
              {...this.state}
              onSearch={this.onSearch}
              onSearchChange={this.onSearchChange}
              toggleSearch={this.toggleSearch}
              toggleSidebar={this.toggleSidebar}
              setEditItem={this.setEditItem}
            />
          );
        }
      }
    )
  );
};

export default appWrapper;
