import React, { Component } from 'react';

/**
 * @param {Function} WrappedComponent Component to wrap
 *
 * @returns {Function}
 */
const withDimensions = (WrappedComponent) => {
  return class WithDimensions extends Component {
    state = {
      windowWidth: 0,
      windowHeight: 0,
    };

    onResize = () => {
      this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    /**
     * @description Sets the window width and adds event listener to window resize.
     */
    componentDidMount() {
      this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });

      window.addEventListener('resize', this.onResize);
    }

    /**
     * @description Removes window resize event listener.
     */
    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }
    /**
     * @returns {JSX.Element}
     */
    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
};

export default withDimensions;
