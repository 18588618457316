import { GET_EDGE_BOOKMARKS } from 'modules/shared/actions/bookmarks';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const bookmarks = (state = [], action) => {
  switch (action.type) {
    case GET_EDGE_BOOKMARKS: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default bookmarks;
