import request, { Methods } from 'modules/shared/util/request';

const BASE_URL = 'walkthrough';

/**
 * @description Gets walk through
 * @returns {Promise<*>}
 */
export const getWalkThrough = async () => {
  return await request({
    resource: BASE_URL,
  });
};

/**
 * @param {Object} data walk through object
 * @returns {Promise<*>}
 */
export const saveWalkThrough = async (data) => {
  return await request({
    resource: BASE_URL,
    method: Methods.POST,
    data,
  });
};
