import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'common';

class InViewport extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onViewportChange: PropTypes.func,
  };

  state = {
    inViewport: false,
  };

  /**
   * @description Adds scroll event listener.
   */
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
    this.onViewportChange(this.elementInViewport());
  }

  /**
   * @description Removes scroll event listener.
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  /**
   * @description Fetches data if we are on the bottom of the page and a fetch is not pending.
   */
  onScroll = debounce(() => {
    this.onViewportChange(this.elementInViewport());
  }, 100);

  /**
   * @description check is view in viewport
   * @returns {boolean}
   */
  elementInViewport = () => {
    if (!this.view) {
      return false;
    }

    const top = this.view.offsetTop;
    const left = this.view.offsetLeft;
    const width = this.view.offsetWidth;
    const height = this.view.offsetHeight;

    return (
      window.pageYOffset + window.innerHeight >= top &&
      left >= window.pageXOffset &&
      top + height >= window.pageYOffset &&
      left + width <= window.pageXOffset + window.innerWidth
    );
  };

  /**
   * @param {boolean} inViewport is in viewport
   * @description check inViewport changes, call props.onViewportChange if has changes
   */
  onViewportChange = (inViewport) => {
    const { onViewportChange } = this.props;

    if (inViewport !== this.state.inViewport) {
      this.setState({ inViewport });
      onViewportChange(inViewport);
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { children } = this.props;

    return <div ref={(r) => (this.view = r)}>{children}</div>;
  }
}

export default InViewport;
