import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getModelPortfolioHistory as getHistory,
  getModelPortfolio as getItem,
} from 'modules/analyst/actions/model-portfolio';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

import ModelPortfolio from './ModelPortfolio';

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showLoader, hideLoader, getHistory, getItem }, dispatch);
};

export default connect(null, mapDispatchToProps)(ModelPortfolio);
