import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import withStyles from 'isomorphic-style-loader/withStyles';
import Home from 'modules/admin/main/components/home/Home';
import style from '../../../styles/index.scss';

class AdminApp extends Component {
  /**
   * @returns {JSX.Element} Routes for the Admin app, Toast container for notifications.
   */
  render() {
    return (
      <div>
        <FormattedMessage id="ADMIN.DASHBOARD">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <Switch>
          <Route sensitive path="/admin" component={Home} />
          <Redirect from="/" to="/admin" />
        </Switch>
      </div>
    );
  }
}

export default withStyles(style)(AdminApp);
