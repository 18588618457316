import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './ModelPortfolio.scss';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import {
  ModelPortfolioTitle,
  ModelPortfolioHistory,
  ModelPortfolioDownloads,
  ModelPortfolioTable,
  withModelPortfolio,
} from 'modules/shared/components/model-portfolio';
import TextEditorView from 'modules/shared/components/text-editor-view/TextEditorView';
import { Helmet } from 'react-helmet';
import { match, history } from 'common';
import { Html } from 'modules/shared';

class ModelPortfolio extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    setEditItem: PropTypes.func,
    basePath: PropTypes.string,
    intl: PropTypes.object,
    getTotalsByType: PropTypes.func.isRequired,
    getTransformedModelPortfolio: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    getModelPortfolioData: PropTypes.func.isRequired,
    shouldGetData: PropTypes.func.isRequired,
    modelPortfolio: PropTypes.object,
    transformedModelPortfolio: PropTypes.object,
    historyItems: PropTypes.array,
    latest_id: PropTypes.number,
    loading: PropTypes.bool,
    match,
    history,
  };

  ideaTypes = ['longIdeas', 'shortIdeas'];

  /**
   * @description Gets the data for the page.
   */
  async componentDidMount() {
    await this.props.fetchData(this.ideaTypes);
    this.setEditModalPortfolioLink();
  }

  /**
   * @description set modal portfolio link
   * */
  setEditModalPortfolioLink = () => {
    this.props.setEditItem &&
      this.props.setEditItem({
        text: this.props.intl.formatMessage({ id: 'ANALYST.EDIT_MODEL_PORTFOLIO' }),
        link: `/admin/content/model-portfolio/${this.props.modelPortfolio.id}/edit`,
      });
  };

  /**
   * @description Updates the Model Portfolio data if the id is changed.
   * @returns {Promise<void>}
   */
  async componentDidUpdate() {
    if (this.props.shouldGetData()) {
      const response = await this.props.getModelPortfolioData(
        this.props.match.params.id,
        this.ideaTypes
      );
      this.setEditModalPortfolioLink();
      return response;
    }

    return null;
  }
  /**
   * @description get model portfolio data by id.
   * @param {number} id of model portfolio
   * @returns {Promise}
   */
  getModelPortfolioData = async (id) => {
    const response = await this.props.getModelPortfolioData(id, this.ideaTypes);
    this.setEditModalPortfolioLink();
    return response;
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { modelPortfolio, transformedModelPortfolio, historyItems, latest_id } = this.props;
    return (
      <div>
        <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.TITLE">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {modelPortfolio ? (
          <Grid fluid className="model-portfolio">
            <Row className="mb-3">
              <div className="d-flex flex-wrap">
                <Col xs={12} sm={12} md={9} lg={10} className="p-0">
                  <Col xs={12} sm={12} className="model-portfolio-content">
                    <Col xs={12} sm={12} className="p-0">
                      <Col
                        xs={12}
                        sm={12}
                        md={this.props.modelPortfolio.vimeo_data ? 8 : 12}
                        className={classNames('pl-0 model-portfolio-info', {
                          'pr-0': !this.props.modelPortfolio.vimeo_data,
                          'bottom-padding':
                            this.props.modelPortfolio.vimeo_data &&
                            !this.props.modelPortfolio.free_text,
                        })}>
                        <Col
                          xs={12}
                          sm={12}
                          className={classNames(
                            'pt-0 d-flex align-center title-row',
                            this.props.modelPortfolio.free_text ? 'pb-3' : 'pb-2'
                          )}>
                          <ModelPortfolioTitle
                            latest_id={this.props.latest_id}
                            modelPortfolio={this.props.modelPortfolio}
                            xs={6}
                            sm={6}
                          />
                          <ModelPortfolioDownloads
                            xs={6}
                            sm={6}
                            modelPortfolio={this.props.modelPortfolio}
                          />
                        </Col>
                        {this.props.modelPortfolio.free_text ? (
                          <Col
                            xs={12}
                            sm={12}
                            className={classNames('free-text pb-3 pt-3', {
                              'has-video': this.props.modelPortfolio.vimeo_data,
                            })}>
                            {this.props.modelPortfolio.free_text}
                          </Col>
                        ) : null}
                      </Col>
                      {this.props.modelPortfolio.vimeo_data ? (
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          className="model-portfolio-video-container pr-0">
                          <Html
                            className="video-container"
                            content={this.props.modelPortfolio.vimeo_data.embed}
                          />
                        </Col>
                      ) : null}
                    </Col>
                    <ModelPortfolioTable
                      className={this.props.modelPortfolio.vimeo_data ? 'negative-top-margin' : ''}
                      data={modelPortfolio.content.longIdeas}
                      transformedData={transformedModelPortfolio.content.longIdeas}
                      ideaType="long-ideas"
                      label={<FormattedMessage id="ANALYST.MODEL_PORTFOLIO.LONG_IDEAS" />}
                      topPadding={
                        !this.props.modelPortfolio.vimeo_data &&
                        !this.props.modelPortfolio.free_text
                      }
                      basePath={this.props.basePath}
                    />

                    <ModelPortfolioTable
                      data={modelPortfolio.content.shortIdeas}
                      transformedData={transformedModelPortfolio.content.shortIdeas}
                      ideaType="short-ideas"
                      topBorder
                      topPadding
                      label={<FormattedMessage id="ANALYST.MODEL_PORTFOLIO.SHORT_IDEAS" />}
                      basePath={this.props.basePath}
                    />
                  </Col>

                  <Col xs={12} sm={12} className="white-background pb-3">
                    <TextEditorView
                      fluid={true}
                      data={{ text: modelPortfolio.description }}
                      gridClass="pl-0"
                    />
                  </Col>
                </Col>
                <Col xs={12} sm={12} md={3} lg={2} className="model-portfolio-history">
                  <ModelPortfolioHistory
                    style="analyst"
                    label={<FormattedMessage id="ANALYST.MODEL_PORTFOLIO.HISTORY" />}
                    historyItems={historyItems}
                    history={this.props.history}
                    match={this.props.match}
                    latest_id={latest_id}
                    showLoader={this.props.showLoader}
                    hideLoader={this.props.hideLoader}
                    getModelPortfolioData={this.getModelPortfolioData}
                  />
                </Col>
              </div>
            </Row>
          </Grid>
        ) : !this.props.loading ? (
          <div className="d-flex justify-center align-center model-portfolio-empty-state">
            <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.EMPTY_STATE" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(withModelPortfolio(withRouter(ModelPortfolio))));
