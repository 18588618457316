import AnalystCategories from '../components/metadata/categories/AnalystCategories';
import AnalystTags from '../components/metadata/tags/AnalystTags';
import AnalystGeography from '../components/metadata/geography/AnalystGeography';
import AnalystInvestmentStyles from '../components/metadata/investment-styles/AnalystInvestmentStyles';
import AnalystMarketCaps from '../components/metadata/marketcaps/AnalystMarketCaps';
import AnalystSectors from '../components/metadata/sectors/AnalystSectors';

const metadataRoutes = [
  {
    path: '/admin/metadata/categories',
    name: 'Categories',
    component: AnalystCategories,
  },
  {
    path: '/admin/metadata/geography',
    name: 'Geography',
    component: AnalystGeography,
  },
  {
    path: '/admin/metadata/investment-styles',
    name: 'Investment Styles',
    component: AnalystInvestmentStyles,
  },
  {
    path: '/admin/metadata/marketcaps',
    name: 'Marketcaps',
    component: AnalystMarketCaps,
  },
  {
    path: '/admin/metadata/tags',
    name: 'Tags',
    component: AnalystTags,
  },
  {
    path: '/admin/metadata/sectors',
    name: 'Sectors',
    component: AnalystSectors,
  },
  {
    redirect: true,
    from: '/admin/metadata',
    to: '/admin/metadata/model-portfolio',
    key: 'redirect',
  },
];

export default metadataRoutes;
