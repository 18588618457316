import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './SingleTextBox.scss';
import { Col, Row, Grid } from 'react-bootstrap';
import Env from 'env';
import { Html, Img } from 'modules/shared';

class SingleTextBox extends React.Component {
  static componentType = 'single-text-box';
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  /**
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    return (
      <div className="single-text-box">
        <Grid>
          <Row>
            <Col sm={12} className="box-title">
              <h3>{this.props.data.title}</h3>
            </Col>
            <Col sm={6} className="box-image">
              <Img
                src={Env.S3_URL + this.props.data.image}
                alt={this.props.data.fileAlt}
                title={this.props.data.fileTitle}
              />
            </Col>
            <Html
              component={Col}
              sm={6}
              className="text-editor-view"
              content={this.props.data.description}
            />
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(SingleTextBox);
