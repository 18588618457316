import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { EditorTitle, FieldGroup } from 'modules/admin/shared';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
  match,
  formatDate,
  formatUsername,
  getDSTDateString,
  VoteTypes,
  VoteTypesLabel,
} from 'common';

const MAX_COL = 12;
const OVERFLOWED_MAX_COUNT = 4;
const MIN_LABEL_WIDTH = 4;
const MIN_VALUE_WIDTH = 2;
const VALUE_COUNT_UNDER_A_LABEL = 2;

class ArticleAnalyticsDetail extends Component {
  static propTypes = {
    detail: PropTypes.object.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getArticleHeader: PropTypes.func.isRequired,
    getArticleUsers: PropTypes.func.isRequired,
    getArticleDownloads: PropTypes.func.isRequired,
    resetCurrentArticle: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    match,
  };

  state = {
    showActive: true,
  };

  /**
   * @description Gets the User analytics data.
   */
  async componentDidMount() {
    const { id } = this.props.match.params;
    this.props.showLoader();
    await Promise.all([
      this.props.getArticleHeader(id, this.state.showActive),
      this.props.getArticleUsers(id, this.state.showActive),
      this.props.getArticleDownloads(id),
    ]);
    this.props.hideLoader();
  }

  /**
   * @description Resets current user data from store.
   */
  componentWillUnmount() {
    this.props.resetCurrentArticle();
  }

  /**
   * @description change userActive filter.
   */
  onChange = () => {
    this.setState(
      (prevState) => ({
        showActive: !prevState.showActive,
      }),
      async () => {
        this.props.showLoader();
        await Promise.all([
          this.props.getArticleHeader(this.props.match.params.id, this.state.showActive),
          this.props.getArticleUsers(this.props.match.params.id, this.state.showActive),
        ]);
        this.props.hideLoader();
      }
    );
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { header = {}, users = [], downloads = [] } = this.props.detail;

    const questionsCount = header.questions ? header.questions.length : 1;
    const calculatedLabelWidth = MAX_COL / questionsCount;
    const rest = (MAX_COL / questionsCount) % VALUE_COUNT_UNDER_A_LABEL;
    const questionLabelWidth = Math.max(
      header.questions ? (rest === 0 ? calculatedLabelWidth : calculatedLabelWidth - rest) : 0,
      MIN_LABEL_WIDTH
    );
    const questionValueWidth = Math.max(
      Math.floor(questionLabelWidth ? questionLabelWidth / VALUE_COUNT_UNDER_A_LABEL : 0),
      MIN_VALUE_WIDTH
    );
    const isOverflowed = questionsCount >= OVERFLOWED_MAX_COUNT;

    return header.post_id ? (
      <div>
        <EditorTitle
          title={<FormattedMessage id="ADMIN.ANALYTICS.TITLE" values={{ name: header.title }} />}
        />
        <Grid fluid className="analytics-header-section inner">
          <Row className="header">
            <Col xs={4}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.TITLE" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.PUBLISH_DATE" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.DOWNLOADS" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_VIEWS_WEB" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_VIEWS_MOBILE" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div className="value" title={header.title}>
                {header.title}
              </div>
            </Col>
            <Col xs={2}>
              <div
                className="value"
                title={formatDate(new Date(getDSTDateString(header.published_at)), {
                  timeFormat: true,
                })}>
                {formatDate(new Date(getDSTDateString(header.published_at)), { timeFormat: true })}
              </div>
            </Col>
            <Col xs={2}>
              <div className="value" title={header.downloads}>
                {header.downloads}
              </div>
            </Col>
            <Col xs={2}>
              <div className="value" title={header.web_views}>
                {header.web_views}
              </div>
            </Col>
            <Col xs={2}>
              <div className="value" title={header.mobile_views}>
                {header.mobile_views}
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid
          fluid
          className={classNames('analytics-header-section inner overflow-horizontal', {
            overflowed: isOverflowed,
          })}>
          <Row>
            {header.questions &&
              Array.isArray(header.questions) &&
              header.questions.map(({ question }) => (
                <Col key={question.id} xs={questionLabelWidth} className="overflow-item header">
                  <div className="title" title={question.name}>
                    {question.name}
                  </div>
                </Col>
              ))}
          </Row>
          <Row>
            {header.questions &&
              Array.isArray(header.questions) &&
              header.questions.map(({ question }) => (
                <Fragment key={question.id}>
                  <Col xs={questionValueWidth} className="overflow-item header">
                    <div className="title" title={VoteTypesLabel[VoteTypes.like].label}>
                      {VoteTypesLabel[VoteTypes.like].label}
                    </div>
                  </Col>
                  <Col
                    xs={questionValueWidth}
                    title={VoteTypesLabel[VoteTypes.dislike].label}
                    className="overflow-item header">
                    <div className="title">{VoteTypesLabel[VoteTypes.dislike].label}</div>
                  </Col>
                </Fragment>
              ))}
          </Row>
          <Row>
            {header.questions &&
              Array.isArray(header.questions) &&
              header.questions.map((question) => (
                <Fragment key={question.id}>
                  <Col xs={questionValueWidth} className="overflow-item">
                    <div className="value" title={question[VoteTypes.like]}>
                      {question[VoteTypes.like]}
                    </div>
                  </Col>
                  <Col xs={questionValueWidth} className="overflow-item">
                    <div className="value" title={question[VoteTypes.dislike]}>
                      {question[VoteTypes.dislike]}
                    </div>
                  </Col>
                </Fragment>
              ))}
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <FieldGroup
                className="checkbox-form-group"
                label={<FormattedMessage id="ADMIN.POSTS.SHOW_ACTIVE_QUESTIONS" />}
                inline
                type="checkbox"
                name="showActive"
                checked={this.state.showActive}
                onChange={this.onChange}
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <div className="navs">
                <NavLink to={`${this.props.match.url}/users`} className="tab">
                  <FormattedMessage id="ADMIN.ANALYTICS.USERS" />
                </NavLink>
                <NavLink to={`${this.props.match.url}/downloads`} className="tab">
                  <FormattedMessage id="ADMIN.ANALYTICS.DOWNLOADS" />
                </NavLink>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Switch>
                <Redirect
                  exact
                  from={`${this.props.match.path}/`}
                  to={`${this.props.match.path}/users`}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/users`}
                  render={() => {
                    const columns = [
                      {
                        Header: <FormattedMessage id="ADMIN.ANALYTICS.USER" />,
                        id: 'user',
                        sortable: false,
                        accessor: (d) => {
                          return (
                            <Link to={`/admin/analytics/user-analytics/user/${d.user.id}`}>
                              {formatUsername(d.user)}
                            </Link>
                          );
                        },
                      },
                      {
                        Header: <FormattedMessage id="ADMIN.ANALYTICS.PAGE_VIEWS" />,
                        accessor: 'views',
                        sortable: false,
                      },
                      {
                        Header: <FormattedMessage id="ADMIN.ANALYTICS.VIEWED_AT" />,
                        accessor: 'viewed_at',
                        sortable: false,
                        Cell: ({ original }) => {
                          const viewedAt = formatDate(original.viewed_at, { timeFormat: true });
                          return (
                            <div className="text-overflow-ellipsis" title={viewedAt}>
                              {viewedAt}
                            </div>
                          );
                        },
                      },
                    ];

                    if (header.questions && Array.isArray(header.questions)) {
                      columns.push(
                        ...header.questions.map(({ question }) => ({
                          Header: (
                            <div className="text-overflow-ellipsis" title={question.name}>
                              {question.name}
                            </div>
                          ),
                          id: question.id,
                          accessor: (d) => {
                            const vote = d.votes.find(
                              ({ question_id }) => question_id === question.id
                            );
                            return vote && !this.props.loading ? (
                              <i
                                className={classNames(
                                  `${VoteTypesLabel[vote.user_vote].icon}-active`,
                                  {
                                    'analyst-main-color': vote.user_vote === VoteTypes.dislike,
                                    'edge-main-color': vote.user_vote === VoteTypes.like,
                                  }
                                )}
                              />
                            ) : null;
                          },
                        }))
                      );
                    }
                    return (
                      <ReactTable
                        data={users}
                        resizable={false}
                        minRows={3}
                        showPageSizeOptions={false}
                        showPagination={users.length > 20}
                        defaultPageSize={20}
                        className="-striped -highlight"
                        columns={columns}
                      />
                    );
                  }}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/downloads`}
                  render={() => (
                    <ReactTable
                      data={downloads}
                      resizable={false}
                      minRows={3}
                      showPageSizeOptions={false}
                      showPagination={downloads.length > 20}
                      defaultPageSize={20}
                      className="-striped -highlight"
                      columns={[
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.FILE_NAME" />,
                          accessor: 'filename',
                          sortable: false,
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.SOURCE" />,
                          accessor: 'mobile',
                          sortable: false,
                          Cell: ({ original }) => {
                            return original.mobile ? (
                              <FormattedMessage id="ADMIN.ANALYTICS.MOBILE" />
                            ) : (
                              <FormattedMessage id="ADMIN.ANALYTICS.WEB" />
                            );
                          },
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.USER" />,
                          id: 'user',
                          sortable: false,
                          accessor: (d) => {
                            return (
                              <Link to={`/admin/analytics/user-analytics/user/${d.user.id}`}>
                                {formatUsername(d.user)}
                              </Link>
                            );
                          },
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.DATE_TIME" />,
                          id: 'created_at',
                          sortable: false,
                          Cell: ({ original }) => {
                            return formatDate(original.created_at, { timeFormat: true });
                          },
                        },
                      ]}
                    />
                  )}
                />
              </Switch>
            </Col>
          </Row>
        </Grid>
      </div>
    ) : null;
  }
}

export default ArticleAnalyticsDetail;
