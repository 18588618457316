import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Grid, Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';

import { Button, EditorTitle, FieldGroup, FileUpload, TextEditor } from 'modules/admin/shared';
import { isValidSchema, textSchema, urlSchema } from 'common';
import * as Yup from 'yup';
import Env from 'env';

class ShortScreen extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getShortScreen: PropTypes.func.isRequired,
    saveShortScreen: PropTypes.func.isRequired,
    saveShortScreenFeaturedImage: PropTypes.func.isRequired,
    shortScreen: PropTypes.object.isRequired,
  };

  state = {
    shortScreen: {
      media: [],
      featured_image_path: '',
      video_url: '',
      description: '',
    },
    errors: {},
    isSubmitting: false,
    loading: true,
    isValid: false,
    changedFeaturedImage: false,
  };

  schema = {
    ...textSchema('description', { required: true, max: Env.MAX_TEXT_EDITOR_LENGTH }),
    ...urlSchema('video_url'),
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();

    const shortScreen = await this.props.getShortScreen();
    const isValid = await this.isValidForm(shortScreen);

    const state = {
      loading: false,
      shortScreen,
      isValid,
    };
    this.setState(state);

    this.props.hideLoader();
  }

  /**
   * @description save baseData changes
   */
  save = async () => {
    this.setState({ isSubmitting: true });

    const { video_url, description } = this.state.shortScreen;

    try {
      await this.props.saveShortScreen({ video_url, description });
      if (this.state.changedFeaturedImage) {
        await this.saveFeatureImage();
      }
      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  /**
   * @returns {boolean} editor is valid.
   * @param {Object} shortScreen Data object.
   */
  isValidForm = async (shortScreen = this.state.shortScreen) => {
    const schema = Yup.object().shape(this.schema);

    return await schema.isValid(shortScreen);
  };

  /**
   * @param {Event} e Event data.
   */
  onChange = async (e) => {
    const errors = { ...this.state.errors };
    let isValidForm = { ...this.state.isValid };

    const name = e.target.name;
    const value = e.target.value;

    this.setState(
      {
        shortScreen: {
          ...this.state.shortScreen,
          [name]: value,
        },
      },
      async () => {
        isValidForm = await this.isValidForm();
        const { error } = await isValidSchema(
          name === 'video_url' ? urlSchema(name) : textSchema(name),
          {
            [name]: value,
          }
        );
        errors[name] = error;

        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  onDropFile = (files) => {
    this.setState({
      shortScreen: {
        ...this.state.shortScreen,
        featured_image_path: files.length > 0 ? files[0] : null,
      },
      changedFeaturedImage: true,
    });
  };

  deleteFile = async () => {
    this.setState({
      shortScreen: {
        ...this.state.shortScreen,
        featured_image_path: null,
        media: [],
      },
    });
  };

  saveFeatureImage = async () => {
    const formData = new FormData();
    formData.append('featured_image', this.state.shortScreen.featured_image_path);
    const response = await this.props.saveShortScreenFeaturedImage(formData);
    this.setState({ changedFeaturedImage: false });
    return response;
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { shortScreen, errors, loading, isSubmitting } = this.state;

    return (
      <div>
        {!loading ? (
          <form noValidate>
            <EditorTitle
              title={<FormattedMessage id="ADMIN.SHORT_SCREEN.TITLE" />}
              viewLink="/app/short-screen">
              <Button
                loading={isSubmitting}
                onClick={this.save}
                type="button"
                disabled={!this.state.isValid || isSubmitting}>
                <FormattedMessage id="ADMIN.SAVE" />
              </Button>
            </EditorTitle>
            <Grid fluid className="editor-content">
              <Row>
                <Col xs={12} sm={6} className="image-header">
                  <FormGroup className="image-preview-container">
                    <ControlLabel>
                      <FormattedMessage id="ADMIN.IMAGE" />
                    </ControlLabel>
                    <FileUpload
                      file={shortScreen.featured_image_path}
                      fileId={
                        shortScreen.media && shortScreen.media.length
                          ? shortScreen.media[0].id
                          : null
                      }
                      deleteFile={
                        shortScreen.media && shortScreen.media.length && shortScreen.media[0].id
                          ? this.deleteFile
                          : null
                      }
                      onDropFile={(files) => this.onDropFile(files)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    name="video_url"
                    placeholderId="ADMIN.VIDEO_LINK"
                    label={<FormattedMessage id="ADMIN.VIDEO_LINK" />}
                    value={shortScreen.video_url}
                    error={errors.video_url ? errors.video_url.message : null}
                    onChange={this.onChange}
                  />
                </Col>
                <Col xs={12} sm={12}>
                  <ControlLabel>
                    <FormattedMessage id="ADMIN.DESCRIPTION" />
                  </ControlLabel>
                  <TextEditor
                    name="description"
                    text={shortScreen.description}
                    setReadyState={this.setReadyState}
                    handleChange={this.onChange}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        ) : null}
      </div>
    );
  }
}

export default ShortScreen;
