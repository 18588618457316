import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ActionIcon } from 'modules/admin/shared';
class Toolbox extends Component {
  static propTypes = {
    blocks: PropTypes.array,
    addItem: PropTypes.func.isRequired,
    open: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    blocks: [],
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className={classNames('toolbox', { closed: !this.props.open })}>
        <div className="header">
          <ActionIcon className="fa fa-chevron-circle-right" onClick={this.props.toggle} />
        </div>
        {this.props.blocks.map((block, i) => {
          return (
            <div className="toolbar-item" key={i} onClick={() => this.props.addItem(block, i)}>
              <div className="d-flex align-center">
                <i className={block.icon} />
                {block.blockName}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Toolbox;
