import React from 'react';
import { debounce } from 'common';
import PropTypes from 'prop-types';
import Env from 'env';

/**
 * @param {Object} defaultFilter Default filter options
 *
 * @returns {Function} - table component
 */
export default (defaultFilter = {}) => (WrappedComponent) => {
  return class Table extends React.Component {
    static propTypes = {
      fetchDataWs: PropTypes.func.isRequired,
      deleteItemWs: PropTypes.func,
    };

    state = {
      data: [],
      pages: null,
      loading: true,
      query: {
        search: '',
      },
    };

    fetchData = async (state) => {
      this.setState({ loading: true });
      const { id: order, desc } = state.sorted[0] || {};
      const page = state.page + 1;
      const query = state.query || {};
      try {
        const response = await this.props.fetchDataWs({
          page,
          order: order || defaultFilter.order,
          nofilter: defaultFilter.nofilter,
          nopaginate: defaultFilter.nopaginate,
          per_page: defaultFilter.per_page || Env.LIST_ITEMS_PER_PAGE_ADMIN,
          desc,
          ...{ ...this.state.query, ...query },
        });

        this.setState({
          data: response.data,
          loading: false,
          pages: response.last_page,
          currentPage: page,
          order,
          desc,
        });
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    };

    delayedFetchData = debounce(this.fetchData, 500);

    onSearch = async (e, name = 'search', value) => {
      const val = e && e.target && e.target.value ? e.target.value : value;
      this.setState({ query: { ...this.state.query, [name]: val } });
      await this.delayedFetchData({
        page: this.state.currentPage - 1,
        sorted: [{ id: this.state.order, desc: this.state.desc }],
        query: {
          ...this.state.query,
          [name]: val,
        },
      });
    };

    deleteItem = async (id) => {
      if (this.props.deleteItemWs) {
        await this.props.deleteItemWs(id);

        await this.fetchData({
          page: this.state.currentPage - 1,
          sorted: [{ id: this.state.order, desc: this.state.desc }],
          search: this.state.query.search,
        });
      }
    };

    /**
     * @returns {JSX.Element} - Table component
     */
    render() {
      return (
        <WrappedComponent
          fetchData={this.fetchData}
          onSearch={this.onSearch}
          deleteItem={this.deleteItem}
          data={this.state.data}
          pages={this.state.pages}
          loading={this.state.loading}
          {...this.state.query}
          {...this.props}
        />
      );
    }
  };
};
