import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class AppendToBody extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    if (!global.document) {
      return null;
    }

    return ReactDOM.createPortal(this.props.children, document.body);
  }
}

export default AppendToBody;
