import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { DragHandle, AddPlaceholder } from 'modules/admin/shared';
import Question from './Question';

const SortableRow = SortableElement(({ row, i, onChange, deleteRow, errors }) => {
  return (
    <div className="question-row sortable-row d-flex align-center mb-2">
      <DragHandle />
      <Question row={row} index={i} onChange={onChange} deleteRow={deleteRow} errors={errors} />
    </div>
  );
});

const SortableRowContainer = SortableContainer(({ rows, onChange, addRow, deleteRow, errors }) => {
  return (
    <Grid fluid className="editor-grid">
      <div className="question-row d-flex mb-2">
        <span className="drag-handle-placeholder" />
        <Row className="d-flex w-100">
          <Col xs={3}>
            <strong>
              <FormattedMessage id="ADMIN.SURVEYS.QUESTION_REQUIRED" />
            </strong>
          </Col>
          <Col xs={2}>
            <strong>
              <FormattedMessage id="ADMIN.SURVEYS.QUESTION_TYPE_REQUIRED" />
            </strong>
          </Col>
          <Col xs={1}>
            <strong>
              <FormattedMessage id="ADMIN.SURVEYS.MIN_RESULT_REQUIRED" />
            </strong>
          </Col>
          <Col xs={1}>
            <strong>
              <FormattedMessage id="ADMIN.SURVEYS.MAX_RESULT_REQUIRED" />
            </strong>
          </Col>
          <Col xs={2}>
            <strong>
              <FormattedMessage id="ADMIN.SURVEYS.MIN_LABEL_REQUIRED" />
            </strong>
          </Col>
          <Col xs={2}>
            <strong>
              <FormattedMessage id="ADMIN.SURVEYS.MAX_LABEL_REQUIRED" />
            </strong>
          </Col>
          <Col xs={1} />
        </Row>
      </div>
      {rows.map((row, i) => (
        <SortableRow
          index={i}
          key={row.id || i}
          deleteRow={deleteRow}
          row={row}
          i={i}
          onChange={onChange}
          errors={errors}
        />
      ))}
      <AddPlaceholder inverse onClick={addRow} className="add" />
    </Grid>
  );
});

class SurveyQuestions extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func.isRequired,
    addRow: PropTypes.func.isRequired,
    deleteRow: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { questions, onChange, errors, addRow, deleteRow, onSortEnd } = this.props;
    return (
      <SortableRowContainer
        rows={questions}
        useWindowAsScrollContainer
        useDragHandle
        onSortEnd={onSortEnd}
        delay={100}
        onChange={onChange}
        addRow={addRow}
        deleteRow={deleteRow}
        errors={errors}
      />
    );
  }
}

export default SurveyQuestions;
