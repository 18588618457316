import * as CategoriesService from 'modules/shared/services/categories';

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const MODIFY_CATEGORY = 'MODIFY_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

const BASE_URL = 'edgeplus/categories';

/**
 * @param {Object} options of get
 * @description Gets all categories for edge plus.
 * @returns {function}
 */
export const getCategories = (options) => async (dispatch) => {
  const categories = await CategoriesService.getCategories({ ...options, base: BASE_URL });

  dispatch({
    type: GET_ALL_CATEGORIES,
    payload: categories,
  });

  return categories;
};

/**
 * @description Gets the category for the ID:
 * @param {number} id category ID.
 * @returns {Function}
 */
export const getCategory = (id) => async (dispatch) => {
  const category = await CategoriesService.getCategory(id, BASE_URL);

  dispatch({
    type: GET_CATEGORY,
    payload: category,
  });

  return category;
};

/**
 * @param {Object} data category data.
 * @returns {Function}
 */
export const saveCategory = (data) => async (dispatch) => {
  const category = await CategoriesService.saveCategory(data, BASE_URL);

  dispatch({
    type: SAVE_CATEGORY,
    payload: category,
  });

  return category;
};

/**
 * @param {number} id category Id
 * @param {Object} data category data.
 * @returns {Function}
 */
export const modifyCategory = (id, data) => async (dispatch) => {
  const category = await CategoriesService.modifyCategory(id, data, BASE_URL);

  dispatch({
    type: MODIFY_CATEGORY,
    payload: category,
  });

  return category;
};

/**
 * @param {number} id category Id
 * @returns {Function}
 */
export const deleteCategory = (id) => async (dispatch) => {
  const category = await CategoriesService.deleteCategory(id, BASE_URL);

  dispatch({
    type: DELETE_CATEGORY,
    payload: category,
  });

  return category;
};
