import React, { Component } from 'react';
import publicWebsiteRoutes from 'modules/admin/main/routes/public-website.jsx';
import renderRoutes from 'common/renderRoutes';

class PublicWebsite extends Component {
  /**
   * @returns {JSX.Element} PublicWebsite component.
   */
  render() {
    return (
      <div className="admin-content">{renderRoutes(publicWebsiteRoutes, { basePath: `` })}</div>
    );
  }
}

export default PublicWebsite;
