import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Env from 'env';
import CompanyInfo from './CompanyInfo';
import { withCompanyDetail, AnalystCompanyHeader, RelatedPosts } from 'modules/shared';

class CompanyDetail extends Component {
  static propTypes = {
    bookmarkCompany: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    bookmarkPost: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    company: PropTypes.object,
    posts: PropTypes.array,
    fetching: PropTypes.bool,
    pagingParams: PropTypes.object,
    bookmarking: PropTypes.bool,
    afterBookmark: PropTypes.func,
    fetchPosts: PropTypes.func,
    updateCompany: PropTypes.func,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { basePath, company, posts, fetching, pagingParams, updateCompany } = this.props;
    return (
      <div className="d-flex flex-column">
        <AnalystCompanyHeader
          company={company}
          bookmarkCompany={this.props.bookmarkCompany}
          bookmarking={this.props.bookmarking}
          basePath={basePath}
          companyInfo={
            <CompanyInfo
              company={this.props.company}
              basePath={basePath}
              updateCompany={updateCompany}
            />
          }
        />
        <RelatedPosts
          total={pagingParams.total}
          posts={posts}
          bookmarkPost={this.props.bookmarkPost}
          deleteBookmark={this.props.deleteBookmark}
          afterBookmark={this.props.afterBookmark}
          hasElements={this.props.pagingParams.current_page < this.props.pagingParams.last_page}
          fetching={fetching}
          fetchData={this.props.fetchPosts}
          basePath={basePath}
          defaultImage={Env.ANALYST_DEFAULT_IMAGE}
          updateData={company && company.id}
          moreWidth
          companyName={company.title}
        />
      </div>
    );
  }
}

export default withCompanyDetail({
  editItem: { link: '/admin/website-structure/companies/:id/edit' },
})(CompanyDetail);
