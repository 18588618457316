import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { FieldGroup } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';

class Recommendation extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };

  static defaultProps = {
    errors: {},
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        <Col xs={12}>
          <FieldGroup
            className="checkbox-form-group"
            label={<FormattedMessage id="ADMIN.POSTS.RECOMMENDATION" />}
            inline
            type="checkbox"
            name="recommendation_section"
            checked={this.props.post.recommendation_section}
            value={this.props.post.recommendation_section}
            onChange={this.props.onChange}
          />
        </Col>
        {this.props.post.recommendation_section ? (
          <div className="d-inline-block mb-4">
            <Col xs={6}>
              <FieldGroup
                name="recommendation_title"
                placeholderId="ADMIN.POSTS.RECOMMENDATION"
                label={<FormattedMessage id="ADMIN.POSTS.RECOMMENDATION" />}
                value={this.props.post.recommendation_title}
                error={
                  this.props.errors.recommendation_title
                    ? this.props.errors.recommendation_title.message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>

            <Col xs={6}>
              <FieldGroup
                name="investment_type"
                placeholderId="ADMIN.POSTS.INVESTMENT_TYPE"
                label={<FormattedMessage id="ADMIN.POSTS.INVESTMENT_TYPE" />}
                value={this.props.post.investment_type}
                error={
                  this.props.errors.investment_type
                    ? this.props.errors.investment_type.message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>

            <Col xs={12} md={6}>
              <FieldGroup
                name="timeframe"
                placeholderId="ADMIN.POSTS.TIMEFRAME"
                label={<FormattedMessage id="ADMIN.POSTS.TIMEFRAME" />}
                value={this.props.post.timeframe}
                error={this.props.errors.timeframe ? this.props.errors.timeframe.message : null}
                onChange={this.props.onChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FieldGroup
                name="ivps_upside_case"
                placeholderId="ADMIN.POSTS.INTRINSIC_VALUE_SHARE_UPSIDE"
                label={<FormattedMessage id="ADMIN.POSTS.INTRINSIC_VALUE_SHARE_UPSIDE" />}
                value={this.props.post.ivps_upside_case}
                error={
                  this.props.errors.ivps_upside_case
                    ? this.props.errors.ivps_upside_case.message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <FieldGroup
                name="ivps_downside_case"
                placeholderId="ADMIN.POSTS.INTRINSIC_VALUE_SHARE_DOWNSIDE"
                label={<FormattedMessage id="ADMIN.POSTS.INTRINSIC_VALUE_SHARE_DOWNSIDE" />}
                value={this.props.post.ivps_downside_case}
                error={
                  this.props.errors.ivps_downside_case
                    ? this.props.errors.ivps_downside_case.message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>

            <Col xs={12} md={6}>
              <FieldGroup
                name="updown_ratio"
                placeholderId="ADMIN.POSTS.UPSIDE_DOWNSIDE_RATIO"
                label={<FormattedMessage id="ADMIN.POSTS.UPSIDE_DOWNSIDE_RATIO" />}
                value={this.props.post.updown_ratio}
                error={
                  this.props.errors.updown_ratio ? this.props.errors.updown_ratio.message : null
                }
                onChange={this.props.onChange}
              />
            </Col>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Recommendation;
