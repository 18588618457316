import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useLocation } from 'react-router-dom';

import { Button, AllResearchFilterItem } from 'modules/shared';
import { FilterOnClickItem } from '../post-view-filter/PostViewFilter';
import { parse } from 'common/queryString';

import styles from './PostViewFilterItems.scss';

export enum FilterItemsType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

interface PostViewFilterItemsProps {
  title: React.ReactNode;
  filterKey: string;
  filterType: FilterItemsType;
  filters: AllResearchFilterItem[];
  onClickItem: (value: FilterOnClickItem) => void;
  className?: string;
}

const PostViewFilterItems: FC<PostViewFilterItemsProps> = ({
  title,
  filterKey,
  filterType,
  filters,
  onClickItem,
  className,
}) => {
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [onClicked, setOnClicked] = useState(false);

  useEffect(() => {
    const parsedSelectedItems = parse(location.search);
    if (parsedSelectedItems && parsedSelectedItems[filterKey]) {
      setSelectedItems(parsedSelectedItems[filterKey]);
    }
  }, []);

  useEffect(() => {
    if (onClicked) {
      onClickItem({
        key: filterKey,
        value:
          filterType === FilterItemsType.MULTIPLE
            ? selectedItems
            : selectedItems.length > 0
            ? selectedItems[0]
            : null,
      });
      setOnClicked(false);
    }
  }, [onClicked]);

  const onClick = (value: string) => {
    if (filterType === FilterItemsType.MULTIPLE) {
      if (selectedItems.includes(value)) {
        setSelectedItems(selectedItems.filter((item) => item != value));
      } else {
        setSelectedItems([...selectedItems, value]);
      }
    } else {
      setSelectedItems([value]);
    }
    setOnClicked(true);
  };

  return (
    <div className={classNames('post-view-filter-items', className)}>
      <div className="title">{title}</div>
      {filters.map((filter, index) => (
        <Button
          key={filter.value}
          onClick={() => onClick(filter.value)}
          className={classNames('post-view-filter-button xsmall', {
            'mr-3': index < filters.length - 1,
          })}
          textColor="analyst"
          filled={selectedItems.includes(filter.value)}>
          {filter.title}
        </Button>
      ))}
    </div>
  );
};

export default withStyles(styles)(PostViewFilterItems);
