import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { match } from 'common';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPresses, deletePress } from 'modules/main/actions/press';
import { table } from 'modules/shared';
import { BlogPostsList } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';
import { Nav, NavItem } from 'react-bootstrap';
import { PageEditor } from 'modules/admin/shared';

const BLOG_POST_TAB_KEY = 1;
const IMAGE_HEADER_TAB_KEY = 2;

class PressList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    basePath: PropTypes.string,
  };

  state = { activeTabKey: BLOG_POST_TAB_KEY };

  handleSelect = (activeTabKey) => {
    this.setState({ activeTabKey });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        <Nav
          bsStyle="tabs"
          className="pt-2"
          activeKey={this.state.activeTabKey}
          onSelect={this.handleSelect}>
          <NavItem
            eventKey={BLOG_POST_TAB_KEY}
            active={this.state.activeTabKey === BLOG_POST_TAB_KEY}>
            {<FormattedMessage id="ADMIN.PRESS_BLOG_POSTS.TITLE" />}
          </NavItem>
          <NavItem
            eventKey={IMAGE_HEADER_TAB_KEY}
            active={this.state.activeTabKey === IMAGE_HEADER_TAB_KEY}>
            {<FormattedMessage id="ADMIN.PRESS.OTHER_CONTENT" />}
          </NavItem>
        </Nav>

        {this.state.activeTabKey === IMAGE_HEADER_TAB_KEY ? (
          <PageEditor
            site="landing"
            slug="press"
            title={<FormattedMessage id="ADMIN.PRESS.OTHER_CONTENT" />}
          />
        ) : null}
        {this.state.activeTabKey === BLOG_POST_TAB_KEY ? (
          <BlogPostsList
            match={this.props.match}
            basePath="/press"
            data={this.props.data}
            loading={this.props.loading}
            listTitle={<FormattedMessage id="ADMIN.PRESS_BLOG_POSTS.TITLE" />}
            pages={this.props.pages}
            search={this.props.search}
            deleteItem={this.props.deleteItem}
            fetchData={this.props.fetchData}
            onSearch={this.props.onSearch}
          />
        ) : null}
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDataWs: getPresses, deleteItemWs: deletePress }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(
  table({
    nofilter: true,
    nopaginate: false,
  })(PressList)
);
