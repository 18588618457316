import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Modal as BsModal, Col } from 'react-bootstrap';

import { Modal, SvgIcon, ModalHeader, ModalGrid } from 'modules/shared';

import styles from './style.scss';

interface SuccessModalProps {
  show: boolean;
  hide: () => void;
}

const SuccessModal = ({ show, hide }: SuccessModalProps) => (
  <Modal dialogClassName="success-modal" show={show} onHide={hide}>
    <ModalHeader close={hide} />
    <BsModal.Body>
      <ModalGrid>
        <Col className="success-modal-thanks">
          <FormattedMessage id="MAIN.SUCCESS_MODAL.THANKS_MESSAGE" />
        </Col>
        <Col className="success-modal-gif-container">
          <img
            alt="Success"
            className="success-modal-gif"
            src={require('assets/img/success-arrow.gif')}
          />
        </Col>
        <Col className="success-modal-question">
          <FormattedMessage id="MAIN.SUCCESS_MODAL.QUESTION" />
        </Col>
        <Col xs={12} sm={4} className="success-modal-col">
          <NavLink to={'/about'}>
            <SvgIcon name="n-circle" className="success-modal-svg" />
            <div className="success-modal-title">
              <FormattedMessage id="MAIN.SUCCESS_MODAL.ABOUT_US" />
            </div>
            <div className="success-modal-description">
              <FormattedMessage id="MAIN.SUCCESS_MODAL.ABOUT_US_DESCRIPTION" />
            </div>
          </NavLink>
        </Col>
        <Col xs={12} sm={4} className="success-modal-col">
          <NavLink to={'/sample-research'}>
            <SvgIcon name="note-circle" className="success-modal-svg" />
            <div className="success-modal-title">
              <FormattedMessage id="MAIN.SUCCESS_MODAL.SAMPLE_RESOURCE" />
            </div>
            <div className="success-modal-description">
              <FormattedMessage id="MAIN.SUCCESS_MODAL.SAMPLE_RESOURCE_DESCRIPTION" />
            </div>
          </NavLink>
        </Col>
        <Col xs={12} sm={4} className="success-modal-col">
          <NavLink to={'/people'}>
            <SvgIcon name="people-circle" className="success-modal-svg" />
            <div className="success-modal-title">
              <FormattedMessage id="MAIN.SUCCESS_MODAL.OUR_PEOPLE" />
            </div>
            <div className="success-modal-description">
              <FormattedMessage id="MAIN.SUCCESS_MODAL.OUR_PEOPLE_DESCRIPTION" />
            </div>
          </NavLink>
        </Col>
      </ModalGrid>
    </BsModal.Body>
  </Modal>
);

export default withStyles(styles)(SuccessModal);
