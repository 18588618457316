import React, { Component } from 'react';
import { Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styles from './ModelIdeas.scss';
import ModelPortfolioCompany from './ModelPortfolioCompany';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
  Delete,
  DragHandle,
  AddPlaceholder,
  FieldGroup,
  FileUpload,
  EditorTitle,
  TextEditor,
  Button,
  Select,
} from 'modules/admin/shared';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  match,
  history,
  longTermColumnsOld,
  shortTermColumnsOld,
  longTermColumns,
  shortTermColumns,
} from 'common';
import * as Yup from 'yup';
import { isValidSchema, textSchema, urlSchema } from 'common/shemaValidator';
import { Ideas } from 'modules/analyst/models';

export const OLD_COLUMN_NUMBER = 3;

const SortableBlock = SortableElement(
  ({
    item,
    removeRow,
    rowIndex,
    onChangeCompanies,
    removeCompany,
    addCompany,
    onChange,
    companiesList,
    columnNames,
    onDragEnd,
    type,
    rateOptions,
    colNumber,
  }) => (
    <Col className="ideas-row">
      <div className="content">
        <Row>
          <Col className="drag-container" sm={1}>
            <DragHandle />
          </Col>
          <Col sm={11} className="ideas-row-header">
            <Select
              className="mr-3"
              name="rate"
              clearable={false}
              value={item.rate}
              onChange={(e) => onChange(e, rowIndex, type)}
              options={rateOptions}
            />
            <Delete onSubmit={() => removeRow(rowIndex, type)} />
          </Col>
        </Row>
        <Row>
          <div className="ideas-companies">
            {item.columns &&
              item.columns.map((column, columnIndex) => (
                <ModelPortfolioCompany
                  key={columnIndex}
                  columnIndex={columnIndex}
                  rowIndex={rowIndex}
                  rate={item.rate}
                  companiesList={companiesList}
                  onChangeCompanies={(event, companyIndex) =>
                    onChangeCompanies(event, rowIndex, columnIndex, companyIndex, type)
                  }
                  addCompany={() => addCompany(rowIndex, columnIndex, type)}
                  removeCompany={(index) => removeCompany(rowIndex, columnIndex, index, type)}
                  column={column}
                  columnName={columnNames[columnIndex]}
                  onDragEnd={onDragEnd}
                  colNumber={colNumber}
                />
              ))}
          </div>
        </Row>
      </div>
    </Col>
  )
);

const SortableBlockContainer = SortableContainer(
  ({
    modelIdeas,
    removeRow,
    addRow,
    onChangeCompanies,
    removeCompany,
    addCompany,
    onChange,
    onDragEnd,
    columnNames,
    companiesList,
    type,
    rateOptions,
  }) => {
    return (
      <div>
        <Row className="ideas-content">
          {modelIdeas.map((item, rowIndex) => (
            <SortableBlock
              collection={-1}
              key={rowIndex}
              index={rowIndex}
              item={item}
              type={type}
              removeRow={removeRow}
              columnNames={columnNames}
              companiesList={companiesList}
              rowIndex={rowIndex}
              onChangeCompanies={onChangeCompanies}
              removeCompany={removeCompany}
              addCompany={addCompany}
              onChange={onChange}
              onDragEnd={onDragEnd}
              rateOptions={rateOptions}
              colNumber={modelIdeas[0].columns.length}
            />
          ))}
        </Row>
        <Row>
          <Col sm={12}>
            <AddPlaceholder
              inverse
              onClick={() => {
                addRow(type);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
);

class ModelIdeas extends Component {
  static propTypes = {
    match: match.isRequired,
    history: history.isRequired,
    companiesList: PropTypes.array.isRequired,
    saveModelPortfolio: PropTypes.func.isRequired,
    modifyModelPortfolio: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    modelPortfolio: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    copied: PropTypes.bool.isRequired,
    rateOptions: PropTypes.array.isRequired,
    showShortIdeas: PropTypes.bool,
    hiddenFields: PropTypes.array,
    viewLink: PropTypes.string,
    columnNames: PropTypes.array,
    loading: PropTypes.bool,
    basePath: PropTypes.string,
  };

  static defaultProps = {
    columnNames: longTermColumns,
  };

  /**
   * @param {number} id is id of modelIdeas
   * @param {Object} rate is of modelIdeas
   * @param {Object} columns names of columns
   * @param {boolean} fourColVariant type of table
   *
   * @returns {Object} return new modelIdeas object.
   */
  getModelIdeas({ id, rate, columns, fourColVariant = true }) {
    const { columnNames } = columns ? columns : this.props;
    return {
      id,
      rate,
      columns: fourColVariant
        ? [
            { columnName: columnNames[0], companies: [{ company: {}, value: 3 }] },
            { columnName: columnNames[1], companies: [{ company: {}, value: 3 }] },
            { columnName: columnNames[2], companies: [{ company: {}, value: 3 }] },
            { columnName: columnNames[3], companies: [{ company: {}, value: 3 }] },
          ]
        : [
            { columnName: columnNames[0], companies: [{ company: {}, value: 3 }] },
            { columnName: columnNames[1], companies: [{ company: {}, value: 3 }] },
            { columnName: columnNames[2], companies: [{ company: {}, value: 3 }] },
          ],
    };
  }

  /**
   *  @param {number} value is of modelIdeas
   *
   * @returns {Object} return new company object.
   */
  static getCompany(value) {
    return {
      company: {},
      value,
    };
  }

  /**
   * @returns {Object} return new rate object.
   */
  static getRate() {
    return {
      label: '3',
      value: 3,
    };
  }

  /**
   * @param {Object} props contain properties
   */
  constructor(props) {
    super(props);

    this.state = {
      modelPortfolio: {
        title: '',
        summary: '',
        free_text: '',
        video_url: '',
        status: 'draft',
        downloads: [{}],
        longIdeas: {
          modelIdeas: [this.getModelIdeas({ id: 1, rate: ModelIdeas.getRate() })],
        },
        shortIdeas: {
          modelIdeas: [
            this.getModelIdeas({
              id: 1,
              rate: ModelIdeas.getRate(),
              columns: { columnNames: shortTermColumns },
            }),
          ],
        },
        description: '',
      },
      errors: {},
      isReady: true,
      isValid: this.props.edit,
      loading: false,
    };
  }

  /**
   * @returns {boolean} editor is valid.
   */
  isValidForm = async () => {
    const schema = Yup.object().shape({
      ...textSchema('title', { required: true }),
      ...urlSchema('video_url'),
    });

    return await schema.isValid(this.state.modelPortfolio);
  };

  /**
   * @param {Object} prevProps previous props
   *
   * @description Initialize component data.
   */
  componentDidUpdate(prevProps) {
    if (this.props.modelPortfolio !== prevProps.modelPortfolio) {
      const content =
        typeof this.props.modelPortfolio.content === 'string'
          ? JSON.parse(this.props.modelPortfolio.content)
          : this.props.modelPortfolio.content;
      this.setState({
        modelPortfolio: {
          ...this.props.modelPortfolio,
          longIdeas: content.longIdeas || content.positionSizing || { modelIdeas: [] },
          shortIdeas: content.shortIdeas || {
            modelIdeas: [],
          },
          downloads: this.props.modelPortfolio.downloads || [{}],
        },
        isValid: this.props.edit,
        errors: {},
      });
    }
  }

  /**
   * @param {Object} dragResult Result object after drag.
   * @param {string} type of model ideas.
   */
  onDragEnd = ({ oldIndex, newIndex, collection }, type) => {
    if (collection === -1) {
      this.setState({
        modelPortfolio: {
          ...this.state.modelPortfolio,
          [type]: {
            modelIdeas: arrayMove(this.state.modelPortfolio[type].modelIdeas, oldIndex, newIndex),
          },
        },
      });
    } else {
      const indicateIndex = collection.indexOf('-');
      const rowIndex = collection.substring(0, indicateIndex);
      const columnIndex = collection.substring(indicateIndex + 1, collection.length);

      const modelIdeas = this.state.modelPortfolio[type].modelIdeas.map((data) => data);
      modelIdeas[rowIndex].columns[columnIndex].companies = arrayMove(
        modelIdeas[rowIndex].columns[columnIndex].companies,
        oldIndex,
        newIndex
      );
      this.setState({
        modelPortfolio: { ...this.state.modelPortfolio, [type]: { modelIdeas } },
      });
    }
  };

  /**
   * @param {string} type of model ideas.
   */
  addRow = (type) => {
    const modelIdeas = this.state.modelPortfolio[type].modelIdeas.map((data) => data);

    modelIdeas.push(
      this.getModelIdeas({
        id: modelIdeas.length + 1,
        rate: ModelIdeas.getRate(),
        columns:
          this.state.modelPortfolio[type].modelIdeas[0].columns.length > OLD_COLUMN_NUMBER
            ? { columnNames: type === Ideas.LONG_IDEAS ? longTermColumns : shortTermColumns }
            : { columnNames: type === Ideas.LONG_IDEAS ? longTermColumnsOld : shortTermColumnsOld },
        fourColVariant:
          this.state.modelPortfolio[type].modelIdeas[0].columns.length > OLD_COLUMN_NUMBER,
      })
    );

    this.setState({
      modelPortfolio: {
        ...this.state.modelPortfolio,
        [type]: { modelIdeas },
      },
    });
  };

  /**
   * @param {number} index of model ideas.
   * @param {string} type of model ideas.
   */
  removeRow = (index, type) => {
    const modelIdeas = this.state.modelPortfolio[type].modelIdeas.map((data) => data);

    modelIdeas.splice(index, 1);

    this.setState({
      modelPortfolio: { ...this.state.modelPortfolio, [type]: { modelIdeas } },
    });
  };

  onChangeIdea = (event, index, type) => {
    const modelIdeas = JSON.parse(
      JSON.stringify(this.state.modelPortfolio[type].modelIdeas.map((data) => data))
    );

    const name = event.target.name;
    const value = event.target.value;

    // set value of all companies under this model ideas
    modelIdeas[index][name] = value;
    if (name === 'rate') {
      for (const column of modelIdeas[index].columns) {
        for (const company of column.companies) {
          company.value = value.value;
        }
      }
    }
    this.setState({
      modelPortfolio: { ...this.state.modelPortfolio, [type]: { modelIdeas } },
    });
  };

  onChange = async (event) => {
    const errors = { ...this.state.errors };
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        modelPortfolio: { ...this.state.modelPortfolio, [name]: value },
      },
      async () => {
        const isValidForm = await this.isValidForm();
        const { error } = await isValidSchema(
          name === 'video_url' ? urlSchema(name) : textSchema(name),
          {
            [name]: value,
          }
        );
        errors[name] = error;
        this.setState({ isValid: isValidForm, errors });
      }
    );
  };

  onChangeCompanies = (event, rowIndex, columnIndex, companyIndex, type) => {
    const modelIdeas = JSON.parse(JSON.stringify([...this.state.modelPortfolio[type].modelIdeas]));

    modelIdeas[rowIndex].columns[columnIndex].companies[companyIndex][event.target.name] =
      event.target.value;

    this.setState({
      modelPortfolio: { ...this.state.modelPortfolio, [type]: { modelIdeas } },
    });
  };

  addCompany = (rowIndex, columnIndex, type) => {
    const modelIdeas = JSON.parse(
      JSON.stringify(this.state.modelPortfolio[type].modelIdeas.map((data) => data))
    );

    modelIdeas[rowIndex].columns[columnIndex].companies.push(
      ModelIdeas.getCompany(this.state.modelPortfolio[type].modelIdeas[rowIndex].rate.value)
    );

    this.setState({
      modelPortfolio: { ...this.state.modelPortfolio, [type]: { modelIdeas } },
    });
  };

  removeCompany = (rowIndex, columnIndex, index, type) => {
    const modelIdeas = JSON.parse(
      JSON.stringify(this.state.modelPortfolio[type].modelIdeas.map((data) => data))
    );

    modelIdeas[rowIndex].columns[columnIndex].companies.splice(index, 1);

    this.setState({
      modelPortfolio: { ...this.state.modelPortfolio, [type]: { modelIdeas } },
    });
  };

  setReadyState = (isReady) => {
    this.setState({ isReady });
  };

  onDropFile = (files) => {
    const downloads = JSON.parse(JSON.stringify(this.state.modelPortfolio.downloads));
    if (files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        downloads[0].data = reader.result;
        downloads[0].file_name = files[0].name;
        downloads[0].custom_properties = {};
        downloads[0].custom_properties.title = files[0].name;
        downloads[0].custom_properties.description = files[0].name;
        delete downloads[0].id;

        this.setState({ modelPortfolio: { ...this.state.modelPortfolio, downloads } });
      };
    }
  };

  deleteFile = async () => {
    this.setState({ modelPortfolio: { ...this.state.modelPortfolio, downloads: [{}] } });
  };

  /**
   * @param {Object} e event
   * @param {boolean} publish immediately
   *
   * @description save form
   */
  save = async (e, publish = false) => {
    this.setState(publish ? { saveAndPublishing: true } : { saving: true });
    const content = {
      [this.props.showShortIdeas ? 'longIdeas' : 'positionSizing']: this.state.modelPortfolio
        .longIdeas,
    };
    if (this.props.showShortIdeas) {
      content.shortIdeas = this.state.modelPortfolio.shortIdeas;
    }

    const downloads = [...this.state.modelPortfolio.downloads];
    if (Object.keys(downloads[downloads.length - 1]).length === 0) {
      downloads.splice(-1, 1);
    }

    const state = {
      modelPortfolio: {
        ...this.state.modelPortfolio,
        status: publish ? 'active' : 'draft',
        content,
        downloads,
      },
    };

    try {
      const { longIdeas, shortIdeas, ...modelPortfolio } = state.modelPortfolio;
      if (typeof this.state.modelPortfolio.id !== 'undefined' && !this.props.copied) {
        await this.props.modifyModelPortfolio(modelPortfolio.id, modelPortfolio);
      } else {
        delete modelPortfolio.created_at;
        delete modelPortfolio.id;
        delete modelPortfolio.published_at;
        delete modelPortfolio.updated_at;
        delete modelPortfolio.slug;

        const { id } = await this.props.saveModelPortfolio({
          ...modelPortfolio,
          copyFromId: this.props.copied ? this.state.modelPortfolio.id : null,
        });
        this.setState({ modelPortfolio: { ...this.state.modelPortfolio, id } });
        this.props.history.push(`/admin/${this.props.basePath}/model-portfolio/${id}/edit`);
      }

      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    } finally {
      this.setState(publish ? { saveAndPublishing: false } : { saving: false });
    }
  };

  /**
   * @description publish portfolio
   */
  publish = async () => {
    try {
      this.setState({ publishing: true });
      await this.props.modifyModelPortfolio(this.state.modelPortfolio.id, { status: 'active' });
      toast.success(<FormattedMessage id="MAIN.PUBLISH_SUCCESS" />);
    } finally {
      this.setState({ publishing: false });
    }
  };

  /**
   * @returns {string} path of list
   * */
  getListPath() {
    if (this.props.edit) {
      const slicedPath = this.props.match.path.slice(0, this.props.match.path.lastIndexOf('/'));
      return slicedPath.slice(0, slicedPath.lastIndexOf('/'));
    } else {
      return this.props.match.path.slice(0, this.props.match.path.lastIndexOf('/'));
    }
  }

  isPublishDisabled = () => {
    return (
      !this.state.isValid ||
      !this.state.isReady ||
      !this.state.modelPortfolio.id ||
      this.state.modelPortfolio.status === 'active'
    );
  };

  /**
   * @returns {JSX.Element} ModelIdeas component, this contain rows, company rating in rows, modal for edit columns.
   */
  render() {
    return (
      <div>
        {!this.props.loading ? (
          <form noValidate>
            <EditorTitle
              viewLink={this.props.viewLink}
              title={
                typeof this.state.modelPortfolio.id !== 'undefined' && !this.props.copied ? (
                  <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.EDIT" />
                ) : (
                  <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.CREATE" />
                )
              }>
              <div className="d-inline-flex">
                <Button
                  loading={this.state.saving}
                  type="button"
                  onClick={this.save}
                  disabled={!this.state.isValid || !this.state.isReady}>
                  <FormattedMessage id="ADMIN.SAVE" />
                </Button>

                <Button
                  className="ml-2"
                  loading={this.state.saveAndPublishing}
                  type="button"
                  onClick={(e) => this.save(e, true)}
                  disabled={!this.state.isValid || !this.state.isReady}>
                  <FormattedMessage id="ADMIN.SAVE_AND_PUBLISH" />
                </Button>

                <Button
                  className="ml-2"
                  loading={this.state.publishing}
                  type="button"
                  onClick={this.publish}
                  disabled={this.isPublishDisabled()}>
                  <FormattedMessage id="ADMIN.PUBLISH" />
                </Button>
              </div>
            </EditorTitle>
            <Col xs={12} sm={6}>
              <FieldGroup
                name="title"
                placeholderId="ADMIN.TITLE"
                label={<FormattedMessage id="ADMIN.TITLE" />}
                error={this.state.errors.title ? this.state.errors.title.message : null}
                value={this.state.modelPortfolio.title}
                onChange={this.onChange}
                required
              />
            </Col>
            {!this.props.hiddenFields.includes('summary') ? (
              <Col xs={12} sm={6}>
                <FieldGroup
                  componentClass="textarea"
                  placeholderId="ADMIN.SUMMARY"
                  label={<FormattedMessage id="ADMIN.SUMMARY" />}
                  name="summary"
                  value={this.state.modelPortfolio.summary}
                  onChange={this.onChange}
                />
              </Col>
            ) : null}
            {!this.props.hiddenFields.includes('free_text') ? (
              <Col xs={12} sm={6}>
                <FieldGroup
                  componentClass="textarea"
                  placeholderId="ADMIN.SUMMARY"
                  label={<FormattedMessage id="ADMIN.SUMMARY" />}
                  name="free_text"
                  value={this.state.modelPortfolio.free_text}
                  onChange={this.onChange}
                />
              </Col>
            ) : null}
            {!this.props.hiddenFields.includes('video_url') ? (
              <Col xs={12} sm={6}>
                <FieldGroup
                  name="video_url"
                  placeholderId="ADMIN.COMPANIES.VIDEO_URL"
                  label={<FormattedMessage id="ADMIN.COMPANIES.VIDEO_URL" />}
                  value={this.state.modelPortfolio.video_url}
                  error={this.state.errors.video_url ? this.state.errors.video_url.message : null}
                  onChange={this.onChange}
                />
              </Col>
            ) : null}
            <Col xs={12} sm={6}>
              <FormGroup className="image-preview-container">
                <ControlLabel>
                  <FormattedMessage id="ADMIN.POSTS.FILE" />
                </ControlLabel>
                <FileUpload
                  className="file-type"
                  accept="application/pdf"
                  name="downloads"
                  file={this.state.modelPortfolio.downloads[0]}
                  onDropFile={this.onDropFile}
                  deleteFile={this.deleteFile}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mt-5">
              <ControlLabel>
                <FormattedMessage id="ADMIN.DESCRIPTION" />
              </ControlLabel>
              <TextEditor
                name="description"
                text={this.state.modelPortfolio.description}
                setReadyState={this.setReadyState}
                handleChange={this.onChange}
              />
            </Col>
            <Col xs={12} className="mt-3 mb-3">
              <h3>
                {this.props.showShortIdeas ? (
                  <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.LONG_IDEAS" />
                ) : (
                  <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.POSITION_SIZING" />
                )}
              </h3>
              <hr />
              <SortableBlockContainer
                helperClass="sorting"
                type="longIdeas"
                modelIdeas={this.state.modelPortfolio.longIdeas.modelIdeas}
                columnNames={
                  this.state.modelPortfolio.longIdeas.modelIdeas[0].columns.length >
                  OLD_COLUMN_NUMBER
                    ? longTermColumns
                    : longTermColumnsOld
                }
                removeRow={this.removeRow}
                addRow={this.addRow}
                onChangeCompanies={this.onChangeCompanies}
                removeCompany={this.removeCompany}
                companiesList={this.props.companiesList}
                addCompany={this.addCompany}
                onChange={this.onChangeIdea}
                onDragEnd={(data) => {
                  this.onDragEnd(data, 'longIdeas');
                }}
                onSortEnd={(data) => {
                  this.onDragEnd(data, 'longIdeas');
                }}
                delay={100}
                useDragHandle
                rateOptions={this.props.rateOptions}
              />
            </Col>
            {this.props.showShortIdeas ? (
              <Col xs={12} className="mb-3">
                <h3>
                  <FormattedMessage id="ADMIN.MODEL_PORTFOLIO.SHORT_IDEAS" />
                </h3>
                <hr />
                <SortableBlockContainer
                  helperClass="sorting"
                  type="shortIdeas"
                  modelIdeas={this.state.modelPortfolio.shortIdeas.modelIdeas}
                  columnNames={
                    this.state.modelPortfolio.shortIdeas.modelIdeas[0].columns.length >
                    OLD_COLUMN_NUMBER
                      ? shortTermColumns
                      : shortTermColumnsOld
                  }
                  removeRow={this.removeRow}
                  addRow={this.addRow}
                  onChangeCompanies={this.onChangeCompanies}
                  removeCompany={this.removeCompany}
                  companiesList={this.props.companiesList}
                  addCompany={this.addCompany}
                  onChange={this.onChangeIdea}
                  onDragEnd={(data) => {
                    this.onDragEnd(data, 'shortIdeas');
                  }}
                  onSortEnd={(data) => {
                    this.onDragEnd(data, 'shortIdeas');
                  }}
                  delay={100}
                  useDragHandle
                  rateOptions={this.props.rateOptions}
                />
              </Col>
            ) : null}
          </form>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(ModelIdeas);
