import React, { Component } from 'react';
import { PostViewContainer } from 'modules/shared';
import Env from 'env';

class AllResearch extends Component {
  /**
   * @returns {JSX.Element}
   */
  render() {
    return <PostViewContainer {...this.props} defaultImage={Env.ANALYST_DEFAULT_IMAGE} />;
  }
}

export default AllResearch;
