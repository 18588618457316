import request from 'modules/shared/util/request';

/**
 * @param {number} id Region id
 * @returns {Promise<*>}
 */
export const getRegions = async () => {
  return await request({
    resource: 'admin/list/regions',
  });
};
