import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './CreatePasswordForm.scss';
import { withFormikValidation } from 'common/propTypes';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { TextInput, Button } from 'modules/shared';
import qs from 'query-string';
import Env from 'env';

/**
 * @param {Object} Props Injected Form Props.
 * @returns {JSX.Element}
 */
const InnerCreatePasswordForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  tokenErrorMessage,
  isValid,
  type,
  token,
}) => {
  return !tokenErrorMessage ? (
    <div>
      <FormattedMessage id="MAIN.CREATE_PASSWORD">
        {(title) => (
          <Helmet defer={false}>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <Grid>
        <Row>
          <Col>
            <form onSubmit={handleSubmit} noValidate className="create-password-form">
              {errors.createPassword && (
                <div className="error-message">{errors.createPassword}</div>
              )}
              <div className="form-label">
                <FormattedMessage id="MAIN.CREATE_NEW_PASSWORD" />
              </div>
              {token ? (
                <div>
                  <TextInput
                    type="password"
                    name="password"
                    label={<FormattedMessage id="MAIN.PASSWORD" />}
                    className="square-style"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    touched={touched.password}
                    error={errors.password}
                    required
                    inlineError
                  />
                  <TextInput
                    type="password"
                    name="password_confirmation"
                    label={<FormattedMessage id="MAIN.CONFIRM_PASSWORD" />}
                    className="square-style"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password_confirmation}
                    touched={touched.password_confirmation}
                    required
                    error={errors.password_confirmation}
                    inlineError
                  />
                  <Button
                    type="submit"
                    moduleType={type}
                    disabled={isSubmitting || !isValid}
                    className="small">
                    <FormattedMessage id="MAIN.SUBMIT" />
                  </Button>
                </div>
              ) : (
                <Redirect to="./" />
              )}
            </form>
          </Col>
        </Row>
      </Grid>
    </div>
  ) : (
    <div className="create-password-error">
      <div className="error-title">
        <FormattedMessage id={tokenErrorMessage} />
      </div>
      <Link className="to-home" to="/" replace>
        <Button textColor="dark" moduleType="analyst">
          <FormattedMessage id="MAIN.BACK_HOME" />
        </Button>
      </Link>
    </div>
  );
};

InnerCreatePasswordForm.propTypes = {
  createPassword: PropTypes.func,
  ...withFormikValidation,
};

const CreatePasswordForm = withFormik({
  mapPropsToValues: () => ({ password: '', password_confirmation: '' }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .matches(Env.PASSWORD_PATTERN, <FormattedMessage id="MAIN.PASSWORD_REQUIREMENT" />)
      .required(<FormattedMessage id="MAIN.REQUIRED_FIELD" />),
    password_confirmation: Yup.string()
      .required(<FormattedMessage id="MAIN.REQUIRED_FIELD" />)
      .oneOf([Yup.ref('password')], <FormattedMessage id="MAIN.PASSWORDS_DONT_MATCH" />),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    if (props.token) {
      const { email, forgot } = qs.parse(props.query);
      setSubmitting(true);

      try {
        await props.createPassword({ ...values, token: props.token, email });
        props.history.replace(+forgot ? props.forgotRedirect : props.newRedirect);
      } catch (e) {
        props.setTokenErrorMessage(e.response.data.error);
        setSubmitting(false);
      }
    }
  },
})(InnerCreatePasswordForm);

export default withStyles(style)(CreatePasswordForm);
