import * as InvestmentStyleService from '../services/investment-style';

export const GET_ALL_INVESTMENT_STYLE = 'GET_ALL_INVESTMENT_STYLE';
export const GET_INVESTMENT_STYLE = 'GET_INVESTMENT_STYLE';
export const SAVE_INVESTMENT_STYLE = 'SAVE_INVESTMENT_STYLE';
export const MODIFY_INVESTMENT_STYLE = 'MODIFY_INVESTMENT_STYLE';
export const DELETE_INVESTMENT_STYLE = 'DELETE_INVESTMENT_STYLE';

/**
 * @param {Object} options of get
 * @description Gets all investmentStyle.
 * @returns {Function}
 */
export const getInvestmentStyles = (options) => async (dispatch) => {
  const investmentStyles = await InvestmentStyleService.getInvestmentStyles(options);

  dispatch({
    type: GET_ALL_INVESTMENT_STYLE,
    payload: investmentStyles,
  });

  return investmentStyles;
};

/**
 * @description Gets the investmentStyle for the ID:
 * @param {number} id investmentStyle ID.
 * @returns {Function}
 */
export const getInvestmentStyle = (id) => async (dispatch) => {
  const investmentStyle = await InvestmentStyleService.getInvestmentStyle(id);

  dispatch({ type: GET_INVESTMENT_STYLE, payload: investmentStyle });

  return investmentStyle;
};

/**
 * @param {Object} data investmentStyle data.
 * @returns {Function}
 */
export const saveInvestmentStyle = (data) => async (dispatch) => {
  const investmentStyle = await InvestmentStyleService.saveInvestmentStyle(data);

  dispatch({
    type: SAVE_INVESTMENT_STYLE,
    payload: investmentStyle,
  });

  return investmentStyle;
};

/**
 * @param {number} id investmentStyle Id
 * @param {Object} data investmentStyle data.
 * @returns {Function}
 */
export const modifyInvestmentStyle = (id, data) => async (dispatch) => {
  const investmentStyle = await InvestmentStyleService.modifyInvestmentStyle(id, data);

  dispatch({
    type: MODIFY_INVESTMENT_STYLE,
    payload: investmentStyle,
  });

  return investmentStyle;
};

/**
 * @param {number} id investmentStyle Id
 * @returns {Function}
 */
export const deleteInvestmentStyle = (id) => async (dispatch) => {
  const investmentStyle = await InvestmentStyleService.deleteInvestmentStyle(id);

  dispatch({
    type: DELETE_INVESTMENT_STYLE,
    payload: investmentStyle,
  });

  return investmentStyle;
};
