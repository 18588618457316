import React, { Component } from 'react';
import { ModelPortfolioEditor } from 'modules/admin/shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCompanies } from 'modules/analyst/actions/companies';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import {
  saveModelPortfolio,
  modifyModelPortfolio,
  getModelPortfolio,
} from 'modules/analyst/actions/model-portfolio';
import PropTypes from 'prop-types';
import { match, history } from 'common';

class AnalystModelPortfolioEditor extends Component {
  static propTypes = {
    match: match.isRequired,
    history: history.isRequired,
    companiesList: PropTypes.array.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    saveModelPortfolio: PropTypes.func.isRequired,
    modifyModelPortfolio: PropTypes.func.isRequired,
    getModelPortfolio: PropTypes.func.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <ModelPortfolioEditor
        getCompanies={this.props.getCompanies}
        showLoader={this.props.showLoader}
        hideLoader={this.props.hideLoader}
        companiesList={this.props.companiesList}
        modifyModelPortfolio={this.props.modifyModelPortfolio}
        saveModelPortfolio={this.props.saveModelPortfolio}
        getModelPortfolio={this.props.getModelPortfolio}
        hiddenFields={['summary']}
        basePath="analyst"
        showShortIdeas={true}
        viewLink="/app/model-portfolio/"
        match={this.props.match}
        history={this.props.history}
      />
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst }) => {
  return {
    companiesList: analyst.companies,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getCompanies,
      saveModelPortfolio,
      modifyModelPortfolio,
      getModelPortfolio,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalystModelPortfolioEditor);
