import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Card extends Component {
  static propTypes = {
    plain: PropTypes.bool,
    hCenter: PropTypes.bool,
    title: PropTypes.string,
    category: PropTypes.string,
    ctAllIcons: PropTypes.bool,
    ctTableFullWidth: PropTypes.bool,
    ctTableResponsive: PropTypes.bool,
    ctTableUpgrade: PropTypes.bool,
    content: PropTypes.node,
    stats: PropTypes.node,
    statsIcon: PropTypes.string,
    legend: PropTypes.node,
  };

  /**
   * @returns {JSX.Element} Card component.
   */
  render() {
    return (
      <div className={`card${this.props.plain ? ' card-plain' : ''}`}>
        <div className={`header${this.props.hCenter ? ' text-center' : ''}`}>
          <h4 className="title">{this.props.title}</h4>
          <p className="category">{this.props.category}</p>
        </div>
        <div
          className={`content${this.props.ctAllIcons ? ' all-icons' : ''}${
            this.props.ctTableFullWidth ? ' table-full-width' : ''
          }${this.props.ctTableResponsive ? ' table-responsive' : ''}${
            this.props.ctTableUpgrade ? ' table-upgrade' : ''
          }`}>
          {this.props.content}

          {this.props.legend && (
            <div className="footer">
              {this.props.legend}
              {this.props.stats !== null ? <hr /> : ''}
              <div className="stats">
                <i className={this.props.statsIcon} /> {this.props.stats}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Card;
