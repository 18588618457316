// import Env from 'env';
// import {
// Home,
// OurPeople,
// NotFound,
// Contact,
// Careers,
// Press,
// PressDetail,
// AboutUs,
// Profile,
// } from 'modules/main';
import Analyst, { routes as analystRoutes } from 'modules/analyst';
// import Edge, { routes as edgeRoutes } from 'modules/edge';
// import EdgePlus, { routes as edgePlusRoutes } from 'modules/edge-plus';
// import Admin from 'modules/admin';

// import { AdminPermission, LoginPermission } from 'modules/shared';
// import { AdminPermission } from 'modules/shared';

// This is a static route configuration. It should include all of your top level
// routes, regardless of whether they are going to server render. In fact, you
// can totally point multiple routes to the same component! This is great for
// when you only need to server render a handful of routes and not your entire
// application!

const routes = [
  // {
  //   path: '/',
  //   component: Home,
  //   name: 'Home',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: '/about',
  //   component: AboutUs,
  //   name: 'AboutUs',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: '/people',
  //   component: OurPeople,
  //   name: 'OurPeople',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: '/press',
  //   component: Press,
  //   name: 'Press',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: '/press/:id/:title?',
  //   component: PressDetail,
  //   name: 'PressDetail',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: '/careers',
  //   component: Careers,
  //   name: 'Careers',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: '/contact',
  //   component: Contact,
  //   name: 'Contact',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}`,
  //   component: Analyst,
  //   name: 'Analyst',
  //   routes: analystRoutes,
  // },
  // {
  //   path: `/${Env.EDGE_BASE_PATH}`,
  //   component: Edge,
  //   name: 'Edge',
  //   routes: edgeRoutes,
  // },
  // {
  //   path: `/${Env.EDGE_PLUS_BASE_PATH}`,
  //   component: EdgePlus,
  //   name: 'EdgePlus',
  //   routes: edgePlusRoutes,
  // },
  // TODO remove if edge available (just next object)
  {
    path: `/`,
    component: Analyst,
    name: 'Analyst',
    routes: analystRoutes,
  },
  // {
  //   path: `/${Env.ADMIN_BASE_PATH}`,
  //   component: AdminPermission()(Admin),
  //   name: 'Admin',
  // },
  // {
  //   path: `/profile`,
  //   component: LoginPermission()(Profile),
  //   name: 'Profile',
  //   strict: true,
  // },
  // {
  //   path: `/404`,
  //   component: NotFound,
  //   name: 'Not Found',
  //   status: 404,
  //   exact: true,
  // },
  // {
  //   redirect: true,
  //   to: '/404',
  //   key: '404',
  // },
];

export default routes;
