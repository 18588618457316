import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextEditor } from 'modules/admin/shared';

class SimpleTextEditor extends Component {
  static componentType = 'text-editor';
  static blockName = 'Text Editor';
  static icon = 'fa fa-font';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    data: PropTypes.object,
  };

  state = { text: '', loaded: false };

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.setState({ text: this.props.data.text, loaded: true });
  }

  handleChange = (e) => {
    const text = e.target.value;
    this.setState({ text });
    this.props.onChange({ text, valid: text && text.length > 0 });
  };

  /**
   * @returns {JSX.Element} Quill Editor component.
   */
  render() {
    return (
      <TextEditor
        handleChange={this.handleChange}
        text={this.state.text}
        loaded={this.state.loaded}
      />
    );
  }
}

export default SimpleTextEditor;
