import { useEffect, useRef, useCallback } from 'react';

type UseDisposed = () => boolean;

export function useDisposed(): UseDisposed {
  const disposed = useRef(false);

  useEffect(() => {
    return () => {
      disposed.current = true;
    };
  }, []);

  return useCallback(() => disposed.current, []);
}
