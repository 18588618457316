import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavItem, Nav } from 'react-bootstrap';
import styles from './HeaderNavLinks.scss';
import withStyles from 'isomorphic-style-loader/withStyles';

class HeaderNavLinks extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  /**
   * @returns {JSX.Element} Header links component with menu items.
   */
  render() {
    return (
      <div className={this.props.className}>
        <Nav>
          <NavItem eventKey={3} href="/">
            Log out
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default withStyles(styles)(HeaderNavLinks);
