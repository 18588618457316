import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';

/**
 * @param {Object} params filter
 * @description Returns the list of available geographies.
 * @returns {Promise}
 */
export const getGeographies = async (params) => {
  const resource = `geography${getPaginationQueryParams(params)}`;
  return await request({ resource });
};

/**
 * @param {number} id Geography id
 * @returns {Promise<*>}
 */
export const getGeography = async (id) => {
  return await request({
    resource: `geography/${id}`,
  });
};

/**
 * @param {Object} data Geography object
 * @returns {Promise<*>}
 */
export const saveGeography = async (data) => {
  return await request({
    resource: `geography`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Geography id
 * @param {Object} data Geography object
 * @returns {Promise<*>}
 */
export const modifyGeography = async (id, data) => {
  return await request({
    resource: `geography/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id Geography id
 * @returns {Promise<*>}
 */
export const deleteGeography = async (id) => {
  return await request({
    resource: `geography/${id}`,
    method: Methods.DELETE,
  });
};
