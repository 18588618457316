import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';
import Env from 'env';
import { formatUsername } from 'common';
import classNames from 'classnames';

/**
 * @param {Object} author Author object.
 * @returns {null | JSX.Element}
 * @constructor
 */
const AuthorInfo = ({ author, basePath, emails }) => {
  return author ? (
    <div className="info-section author">
      <div className="author-image">
        <div
          className="profile-image"
          style={{ backgroundImage: `url(${Env.S3_URL + author.avatar_path})` }}
        />
        <div className="author-info">
          <div className={classNames('link author-name', { 'padding-bottom': !author.phone })}>
            {formatUsername(author)}
          </div>
          {/*TODO Uncomment next line, delete previous line if analyst detail page is added*/}
          {/*<Link to={`${basePath}/analyst/${author.id}`} className="link author-name">*/}
          {/*{formatUsername(author)}*/}
          {/*</Link>*/}
          {author.phone ? (
            <div className="analyst-info-item info-item">
              <i className="fa fa-phone fa-rotate-90 info-icon" />
              <div className="info">{author.phone}</div>
            </div>
          ) : null}
          <a
            className="analyst-info-item info-item"
            href={`mailto: ${emails ? emails : author.email}`}>
            <i className="fa fa-envelope info-icon" />
            <div className="info">
              <FormattedMessage id="MAIN.EMAIL" /> {author.firstname || author.name}
            </div>
          </a>
        </div>
      </div>
      <div className="author-info">
        {author.phone ? (
          <div className="analyst-info-item">
            <i className="fa fa-phone fa-rotate-90 info-icon" />
            <div className="info">{author.phone}</div>
          </div>
        ) : null}
        <a className="analyst-info-item" href={`mailto: ${author.email}`}>
          <i className="fa fa-envelope info-icon" />
          <div className="info">
            <FormattedMessage id="MAIN.EMAIL" /> {author.firstname || author.name}
          </div>
        </a>
      </div>
    </div>
  ) : null;
};

AuthorInfo.propTypes = {
  author: PropTypes.object,
  basePath: PropTypes.string,
  emails: PropTypes.string,
};

export default AuthorInfo;
