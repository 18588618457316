import request from 'modules/shared/util/request';

const BASE_URL = 'likes';

/**
 * @description Get the list of available questions.
 * @returns {Promise}
 */
export const getQuestions = async () => {
  return await request({ resource: `${BASE_URL}/questions` });
};
