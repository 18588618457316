import React from 'react';

export enum ModelPortfolioStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
}

export interface RateModel {
  label: string;
  value: number;
}

export interface CompanyModel {
  value: number;
  company: any;
}

export interface ColumnModel {
  companies: CompanyModel[];
  columnName: React.ReactNode;
}

export interface ModelIdeaModel {
  id: number;
  rate: RateModel;
  columns: ColumnModel[];
}

export interface IdeasModel {
  modelIdeas: ModelIdeaModel[];
}

export interface ContentModel {
  longIdeas: IdeasModel;
  shortIdeas: IdeasModel;
}

export interface DownloadModel {
  id: number;
}

export interface VimeoDataModel {
  embed: string;
}

export interface ModelPortfolioLatestPostModel {
  id: number;
  title: string;
  slug: string;
  content: ContentModel;
  status: ModelPortfolioStatus;
  published_at: string;
  created_at: string;
  updated_at: string;
  description: string;
  video_url: string;
  free_text: string;
  downloads: DownloadModel[];
  vimeo_data: VimeoDataModel;
}

export interface ModelPortfolioHistoryItemModel {
  id: number;
  title: string;
}

export interface ModelPortfolioHistoryModel {
  latest_post: ModelPortfolioLatestPostModel;
  history: ModelPortfolioHistoryItemModel[];
}

export enum Ideas {
  LONG_IDEAS = 'longIdeas',
  SHORT_IDEAS = 'shortIdeas',
}
