import * as SentimentTrackerService from 'modules/analyst/services/sentiment-tracker';

export const GET_SENTIMENT_TRACKER = 'GET_SENTIMENT_TRACKER';
export const SAVE_SENTIMENT_TRACKER = 'SAVE_SENTIMENT_TRACKER';

/**
 * @description Gets sentiment tracker.
 * @returns {Function}
 */
export const getSentimentTracker = () => async (dispatch) => {
  const response = await SentimentTrackerService.getSentimentTracker();

  dispatch({
    type: GET_SENTIMENT_TRACKER,
    payload: response,
  });

  return response;
};

/**
 *  @param {Object} data sentiment tracker object
 * @description Save sentiment tracker.
 * @returns {Function}
 */
export const saveSentimentTracker = (data) => async (dispatch) => {
  const response = await SentimentTrackerService.saveSentimentTracker(data);

  dispatch({
    type: SAVE_SENTIMENT_TRACKER,
    payload: response,
  });

  return response;
};
