import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { ColorPicker, FileUpload } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';

class ImageHeader extends Component {
  static propTypes = {
    changeColor: PropTypes.func.isRequired,
    onDropImage: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <ControlLabel>
              <FormattedMessage id="ADMIN.POSTS.IMAGE_HEADER" />
            </ControlLabel>
          </Col>

          {/*image upload*/}
          <Col xs={6} className="image-header">
            <FormGroup className="image-preview-container">
              <ControlLabel>
                <FormattedMessage id="ADMIN.POSTS.BACKGROUND_IMAGE" />
              </ControlLabel>
              <FileUpload
                file={this.props.post.featured_image_path}
                fileId={
                  this.props.post.media && this.props.post.media.length
                    ? this.props.post.media[0].id
                    : null
                }
                deleteFile={
                  this.props.post.media &&
                  this.props.post.media.length &&
                  this.props.post.media[0].id
                    ? this.props.deleteFile
                    : null
                }
                onDropFile={(files) => this.props.onDropImage(files)}
              />
            </FormGroup>
          </Col>

          {/*Color pickers*/}
          <Col xs={3}>
            <ColorPicker
              color={this.props.post.gradient_start}
              handleChange={(hex) => this.props.changeColor(hex, 'gradient_start')}
              label={<FormattedMessage id="ADMIN.POSTS.GRADIENT_START" />}
            />
          </Col>
          <Col xs={3}>
            <ColorPicker
              color={this.props.post.gradient_end}
              handleChange={(hex) => this.props.changeColor(hex, 'gradient_end')}
              label={<FormattedMessage id="ADMIN.POSTS.GRADIENT_END" />}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

export default ImageHeader;
