import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import {
  CompanySection,
  Video,
  LikeQuestions,
  PostDetailDivider,
  FeedbackExplanation,
  InfoPanel,
  HtmlContent,
  PostHeader,
  PostFooter,
  Downloads,
  LikeQuestionPages,
} from 'modules/shared';
import KeyFacts from './KeyFacts';
import AuthorInfo from './AuthorInfo';
import VideoPanel from './VideoPanel';
import NextVideo from './NextVideo';

import { history } from 'common';
import styles from './PostDetail.scss';

class Post extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
    bookmark: PropTypes.func.isRequired,
    updateDataByAnswer: PropTypes.func.isRequired,
    isVideoPost: PropTypes.func.isRequired,
    bookmarking: PropTypes.bool,
    answering: PropTypes.number,
    clickedAnswer: PropTypes.number,
    otherVideos: PropTypes.array,
    otherVideosLoading: PropTypes.bool,
    basePath: PropTypes.string,
    history,
  };

  static defaultProps = {
    otherVideos: [],
  };

  videoElement = createRef();
  state = {
    nextVideoVisible: false,
  };

  /**
   * @returns {JSX.Element}
   */
  getInfoPanel() {
    const { post, basePath } = this.props;
    const emails =
      post.authors && post.authors.length > 1
        ? post.authors.map(({ email }) => email).join(',')
        : null;

    return (
      <InfoPanel
        checkFields={[
          post.authors && post.authors.length,
          post.downloads && post.downloads.length,
          post.related_companies && post.related_companies.length,
          post.article_companies && post.article_companies.length,
        ]}>
        {post.authors
          ? post.authors.map((author) => (
              <AuthorInfo author={author} basePath={basePath} key={author.id} emails={emails} />
            ))
          : null}

        <Downloads downloads={post.downloads} />
        <CompanySection
          companies={post.article_companies}
          title={<FormattedMessage id="MAIN.READ_MORE_ABOUT" />}
          basePath="/"
        />
        <CompanySection
          companies={post.related_companies}
          title={<FormattedMessage id="MAIN.RELATED_COMPANIES" />}
          basePath="/"
        />
      </InfoPanel>
    );
  }

  /**
   * @description Returns the Content for The footer message
   * @returns {JSX.Element}
   */
  getFooterContent() {
    return (
      <FormattedHTMLMessage
        id="ANALYST.POST_FOOTER"
        values={{
          privacyPolicy: `/privacy-policy`,
          termsOfUse: `/terms-of-use`,
          disclaimers: `/disclaimers-n-disclosures`,
        }}
      />
    );
  }

  onVideoEnd = () => {
    if (this.props.otherVideos && this.props.otherVideos.length) {
      this.setState({ nextVideoVisible: true });
    }
  };

  playNext = () => {
    this.props.history.push(
      `${this.props.basePath}/post/${this.props.otherVideos[0].id}/${this.props.otherVideos[0].slug}`
    );
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      post,
      bookmark,
      bookmarking,
      isVideoPost,
      updateDataByAnswer,
      otherVideos,
      otherVideosLoading,
    } = this.props;

    const noVideoAndInvestmentThesisAndContent =
      !isVideoPost(post) && !post.investment_thesis && !post.content;

    const hasVideoOrInvestmentThesisOrdContentOrKeyFacts =
      !!isVideoPost(post) ||
      !!post.investment_thesis ||
      !!post.content ||
      !!post.key_facts ||
      !!post.key_facts_2 ||
      !!post.key_facts_3;

    return (
      <div
        className={classNames('post-detail analyst-post-detail', {
          'video-post': isVideoPost(post),
        })}>
        <div className="content">
          <Video
            videContainerRef={this.videoElement}
            autoplay={!!isVideoPost(post)}
            vimeo_data={post.vimeo_data}
            onEnd={this.onVideoEnd}
          />
          <PostHeader
            post={post}
            buy_type={post.buy_type}
            bookmarked={!!(post.bookmarks && post.bookmarks.length)}
            bookmark={bookmark}
            bookmarking={bookmarking}
            bottomDividerAnalystColor={!hasVideoOrInvestmentThesisOrdContentOrKeyFacts}
            bottomDivider={
              hasVideoOrInvestmentThesisOrdContentOrKeyFacts ||
              (!!post.like_questions && !!post.like_questions.length) ||
              !post.survey_taken
            }
          />
          {post.key_facts
            ? [
                <KeyFacts key="facts" post={post} />,
                <PostDetailDivider
                  key="line"
                  thickAnalystBorder={
                    !post.key_facts_2 && !post.key_facts_3 && noVideoAndInvestmentThesisAndContent
                  }
                />,
              ]
            : null}
          {post.key_facts_2
            ? [
                <KeyFacts key="facts" post={post} keyFactsKey="_2" />,
                <PostDetailDivider
                  key="line"
                  thickAnalystBorder={!post.key_facts_3 && noVideoAndInvestmentThesisAndContent}
                />,
              ]
            : null}
          {post.key_facts_3
            ? [
                <KeyFacts key="facts" post={post} keyFactsKey="_3" />,
                <PostDetailDivider
                  key="line"
                  thickAnalystBorder={noVideoAndInvestmentThesisAndContent}
                />,
              ]
            : null}
          {post.investment_thesis ? (
            <HtmlContent
              title={<FormattedMessage id="ANALYST.INVESTMENT_THESIS" />}
              content={post.investment_thesis}
              bottomDivider={!!post.investment_thesis && (!!post.content || !!isVideoPost(post))}
              showBottomSmall={!!post.investment_thesis && !post.content && !isVideoPost(post)}
            />
          ) : null}
          {post.content ? (
            <HtmlContent
              content={post.content}
              bottomDivider={!!post.content && !!isVideoPost(post)}
              showBottomSmall={!!post.content && !isVideoPost(post)}
            />
          ) : null}
          {isVideoPost(post) ? this.getInfoPanel() : null}
          {isVideoPost(post) ? (
            <NextVideo
              videoElement={this.videoElement}
              next={otherVideos[0]}
              visible={this.state.nextVideoVisible}
              basePath={this.props.basePath}
              onTimerEnd={this.playNext}
            />
          ) : null}
          <LikeQuestions
            data={post}
            page={LikeQuestionPages.POST}
            starIconNextToVotingButtons
            updateDataByAnswer={updateDataByAnswer}
            topDivider={!!post.investment_thesis || !!post.content || !!isVideoPost(post)}
            bottomDivider={
              (!isVideoPost(post) &&
                ((!!post.authors && !!post.authors.length) ||
                  (post.downloads && !!post.downloads.length) ||
                  (post.related_companies && !!post.related_companies.length) ||
                  (post.article_companies && !!post.article_companies.length))) ||
              (!!otherVideos && !!otherVideos.length)
            }
            feedbackHorizontalBarChartProps={{
              beforeStarExplanation: 'MAIN.FEEDBACK_POST_RATIO_EXPLANATION',
              starExplanation: <FormattedMessage id="MAIN.FEEDBACK_POST_STAR_EXPLANATION" />,
            }}
            showFeedbackAreaChart
          />
          <FeedbackExplanation
            explanation={<FormattedMessage id="MAIN.FEEDBACK_EXPLANATION" />}
            small
            lightFontWeight
            topMargin
          />
          <PostFooter large message={this.getFooterContent()} />
        </div>
        {!isVideoPost(post) ? (
          this.getInfoPanel()
        ) : (
          <VideoPanel
            videos={otherVideos}
            loading={otherVideosLoading}
            basePath={this.props.basePath}
          />
        )}
        <PostFooter small message={this.getFooterContent()} />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Post));
