import { AsyncState } from '../util/asyncReducerState';

/**
 * @returns {boolean} is fetch started.
 */
export const isInvestorTypesFetchStarted = (state) =>
  state.shared.investorTypes.state === AsyncState.STARTED;

/**
 * @returns {Array} saved investorTypes.
 */
export const getInvestorTypes = (state) => state.shared.investorTypes.data;
