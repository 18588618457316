import React from 'react';
import PropTypes from 'prop-types';

import { FeedbackHorizontalBarChart, FeedbackAreaChartContainer } from 'modules/shared';

const FeedbackCharts = ({
  likeQuestion,
  showFeedbackAreaChart,
  inline,
  showOnlyButtonAndChart,
  answering,
  feedbackHorizontalBarChartProps,
  data,
}) => (
  <>
    {likeQuestion.vote_results ? (
      <FeedbackHorizontalBarChart
        noMargin={inline}
        showOnlyChart={showOnlyButtonAndChart}
        voteResults={likeQuestion.vote_results}
        loading={answering === likeQuestion.user_vote.id}
        companyName={likeQuestion.company_name}
        {...feedbackHorizontalBarChartProps}
      />
    ) : null}
    {showFeedbackAreaChart ? (
      <FeedbackAreaChartContainer
        post={data}
        company={{
          id: likeQuestion.company_id,
          name: likeQuestion.company_name,
        }}
        currentVoteResults={likeQuestion.vote_results}
      />
    ) : null}
  </>
);

FeedbackCharts.propTypes = {
  likeQuestion: PropTypes.object.isRequired,
  showFeedbackAreaChart: PropTypes.bool,
  inline: PropTypes.bool,
  showOnlyButtonAndChart: PropTypes.bool,
  answering: PropTypes.number,
  feedbackHorizontalBarChartProps: PropTypes.object,
  data: PropTypes.object,
};

export default FeedbackCharts;
