import React from 'react';

import { SvgIcon } from 'modules/shared';

/**
 * @param {Object} props Injected props
 *
 * @returns {JSX.Element}
 */
const StarIcon = (props) => <SvgIcon width={10} height={10} name="star" {...props} />;

export default StarIcon;
