import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './SectionDropdown.scss';
import { Row, Col, Grid } from 'react-bootstrap';
import { Button } from 'modules/shared';
import { FormattedMessage } from 'react-intl';
import Env from 'env';
import $ from 'jquery';
import classNames from 'classnames';

class SectionDropdown extends React.Component {
  static componentType = 'section-dropdown';
  static propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  };

  state = {
    openSectionIndex: null,
    sectionsDropdownExpand: [],
  };

  /**
   * @description Sets the focus on input on open.
   * @param {Object} prevProps Props before update.
   */
  componentDidUpdate(prevProps) {
    if (
      !this.state.sectionsDropdownExpand.length &&
      this.props.data &&
      Array.isArray(this.props.data.sectionsDropdown) &&
      this.props.data.sectionsDropdown.length > 0
    ) {
      const sectionsDropdownExpand = [];
      this.props.data.sectionsDropdown.map(() => {
        sectionsDropdownExpand.push({ openSection: false });
      });
      this.setState({ sectionsDropdownExpand });
    }
  }

  setSectionShow = (openSectionIndex) => {
    if (this.props.data.enableMultipleExpand) {
      const sectionsDropdownExpand = [...this.state.sectionsDropdownExpand];
      sectionsDropdownExpand[openSectionIndex].openSection = !sectionsDropdownExpand[
        openSectionIndex
      ].openSection;
      this.setState({ sectionsDropdownExpand });
    } else {
      if (openSectionIndex === this.state.openSectionIndex) {
        openSectionIndex = null;
      }
      this.setState({ openSectionIndex });
    }
  };

  isSectionOpen = (index) => {
    if (this.props.data.enableMultipleExpand) {
      return this.state.sectionsDropdownExpand[index]
        ? this.state.sectionsDropdownExpand[index].openSection
        : false;
    } else {
      return this.state.openSectionIndex === index;
    }
  };

  getSectionDetailHeight = (contentId) => {
    const content = $(`.${contentId}`);
    const paragraphHeight = content.find('p').length ? content.find('p').outerHeight(true) : 0;
    const buttonHeight = content.find('button').length
      ? content.find('button').outerHeight(true)
      : 0;
    return paragraphHeight + buttonHeight;
  };

  /**
   * @returns {JSX.Element} Videos component.
   */
  render() {
    return (
      <Grid className="sections-dropdown">
        <Row className="section-title main-section-title">
          <Col sm={12}>
            <h3>{this.props.data.componentTitle}</h3>
          </Col>
        </Row>
        {this.props.data.sectionsDropdown &&
          this.props.data.sectionsDropdown.map((section, index) => {
            return (
              <Row key={index} className="section">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="section-title"
                  onClick={() => this.setSectionShow(index)}>
                  <div>
                    {this.isSectionOpen(index) && <i className="fas fa-angle-down" />}
                    {!this.isSectionOpen(index) && <i className="fas fa-angle-right" />}
                    <h4>{section.title}</h4>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classNames(`section-detail section-detail-${this.props.id}-${index}`, {
                    open: this.isSectionOpen(index),
                  })}
                  style={{
                    maxHeight: this.isSectionOpen(index)
                      ? this.getSectionDetailHeight(`section-detail-${this.props.id}-${index}`)
                      : '0',
                  }}>
                  <p>{section.description}</p>
                  {section.pdf.link ? (
                    <a
                      href={Env.S3_URL + section.pdf.link}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Button moduleType="main">
                        <FormattedMessage id="MAIN.CAREERS.DOWNLOAD_TITLE" />
                      </Button>
                    </a>
                  ) : null}
                </Col>
              </Row>
            );
          })}
      </Grid>
    );
  }
}

export default withStyles(style)(SectionDropdown);
