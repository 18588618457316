import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';
import { formatDate } from 'common';

class KeyFacts extends Component {
  static propTypes = {
    post: PropTypes.object,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { post } = this.props;
    return post.key_facts ? (
      <div className="info-section key-facts">
        <div className="key-fact-item title">
          <FormattedMessage id="MAIN.KEY_FACTS" />
        </div>
        {post.published_at ? (
          <div className="key-fact-item title">
            <span>{formatDate(post.published_at)}</span>
          </div>
        ) : null}

        <div className="key-fact-item">
          <FormattedMessage id="MAIN.REGION" />:
          <span className="value">
            {post.regions && post.regions.length
              ? post.regions.map(({ short }) => short).join(', ')
              : '--'}
          </span>
        </div>
        <div className="key-fact-item">
          <FormattedMessage id="MAIN.BLOOMBERG_TICKER" />:
          <span className="value">{post.ticker ? post.ticker : '--'}</span>
        </div>
        <div className="key-fact-item">
          <FormattedMessage id="MAIN.CLOSING_PRICE" />:{' '}
          <span className="value">{post.price ? post.price : '--'}</span>
        </div>
        <div className="key-fact-item">
          <FormattedMessage id="MAIN.SHARES_OUT" />:
          <span className="value">{post.shares_out ? post.shares_out : '--'}</span>
        </div>
        <div className="key-fact-item">
          <FormattedMessage id="MAIN.MARKET_CAP" />:
          <span className="value">{post.market_cap ? post.market_cap : '--'}</span>
        </div>
        <div className="key-fact-item">
          <FormattedMessage id="MAIN.3M_AVERAGE" />:
          <span className="value">{post['3m_average'] ? post['3m_average'] : '--'}</span>
        </div>
      </div>
    ) : null;
  }
}

export default KeyFacts;
