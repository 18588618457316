import * as IdeasService from '../services/ideas';

export const GET_IDEAS = 'GET_IDEAS';

/**
 * @returns {Function} Get ideas action.
 */
export const getIdeas = () => async (dispatch) => {
  const ideas = await IdeasService.getIdeas();

  dispatch({
    type: GET_IDEAS,
    payload: ideas,
  });

  return ideas;
};

/**
 * @param {Array} ideas Ideas array to save.
 * @returns {Function} Save ideas action.
 */
export const saveIdeas = (ideas) => async () => {
  return await IdeasService.saveIdeas(ideas);
};
