import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FeedbackAreaChart } from 'modules/shared';
import Env from 'env';

const POST_DATE_COMPARE_UNIT = 'years';

/**
 * @returns {JSX.Element}
 */
const FeedbackAreaChartContainer = ({ post, ...props }) => {
  /**
   * @description is post older than a year
   * @returns {boolean} is older than a year
   */
  const isPostOlderToShow = () =>
    moment(new Date()).diff(moment(post.created_at), POST_DATE_COMPARE_UNIT) >=
    Env.POST_OLDER_FILTER_UNIT_TO_AREA_CHART;

  return <FeedbackAreaChart postId={post.id} isPostOlderToShow={isPostOlderToShow()} {...props} />;
};

FeedbackAreaChartContainer.propTypes = {
  post: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  currentVoteResults: PropTypes.object,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  mobile: PropTypes.bool,
};

export default FeedbackAreaChartContainer;
