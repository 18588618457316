import request, { Methods } from 'modules/shared/util/request';

/**
 * @description Gets sentiment tracker
 * @returns {Promise<*>}
 */
export const getSentimentTracker = async () => {
  return await request({
    resource: `singlepage/sentiment_tracker`,
  });
};

/**
 * @param {Object} data sentiment tracker object
 * @returns {Promise<*>}
 */
export const saveSentimentTracker = async (data) => {
  return await request({
    resource: `singlepage/sentiment_tracker`,
    method: Methods.PUT,
    data,
  });
};
