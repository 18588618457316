import { combineReducers } from 'redux';

import vimeo from './vimeo';
import fileUpload from './file-upload';
import analytics from './analytics';

export default combineReducers({
  vimeo,
  fileUpload,
  analytics,
});
