import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './QuestionLabel.scss';

/**
 * @returns {JSX.Element}
 */
const QuestionLabel = ({ question, noTopMargin, className }) => (
  <h4 className={classNames('question-label', className, { 'mt-0': noTopMargin })}>{question}</h4>
);

QuestionLabel.propTypes = {
  question: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noTopMargin: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(QuestionLabel);
