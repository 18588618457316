import * as BlogTagsService from '../services/blogtags';

export const GET_ALL_BLOG_TAGS = 'GET_ALL_BLOG_TAGS';
export const GET_BLOG_TAG = 'GET_BLOG_TAG';
export const SAVE_BLOG_TAG = 'SAVE_BLOG_TAG';
export const MODIFY_BLOG_TAG = 'MODIFY_BLOG_TAG';
export const DELETE_BLOG_TAG = 'DELETE_BLOG_TAG';

/**
 * @param {Object} options of API params.
 * @description Gets all tags.
 * @returns {function}
 */
export const getBlogTags = (options) => async (dispatch) => {
  const tags = await BlogTagsService.getBlogTags(options);
  dispatch({
    type: GET_ALL_BLOG_TAGS,
    payload: tags,
  });

  return tags;
};

/**
 * @description Gets the blogtags for the ID:
 * @param {number} id blogtags ID.
 * @returns {Function}
 */
export const getBlogTag = (id) => async (dispatch) => {
  const blogTag = await BlogTagsService.getBlogTag(id);

  dispatch({
    type: GET_BLOG_TAG,
    payload: blogTag,
  });

  return blogTag;
};

/**
 * @param {Object} data blogtags data.
 * @returns {Function}
 */
export const saveBlogTag = (data) => async (dispatch) => {
  const blogTag = await BlogTagsService.saveBlogTag(data);

  dispatch({
    type: SAVE_BLOG_TAG,
    payload: blogTag,
  });

  return blogTag;
};

/**
 * @param {number} id blogtags Id
 * @param {Object} data blogtags data.
 * @returns {Function}
 */
export const modifyBlogTag = (id, data) => async (dispatch) => {
  const blogTag = await BlogTagsService.modifyBlogTag(id, data);

  dispatch({
    type: MODIFY_BLOG_TAG,
    payload: blogTag,
  });

  return blogTag;
};

/**
 * @param {number} id blogtags Id
 * @returns {Function}
 */
export const deleteBlogTag = (id) => async (dispatch) => {
  const blogTag = await BlogTagsService.deleteBlogTag(id);

  dispatch({
    type: DELETE_BLOG_TAG,
    payload: blogTag,
  });

  return blogTag;
};
