import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { Author, Bookmark, Html } from 'modules/shared';
import { Buys } from 'common';

class AnalystCompanyHeader extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    bookmarkCompany: PropTypes.func.isRequired,
    bookmarking: PropTypes.bool,
    className: PropTypes.string,
    companyInfo: PropTypes.node,
    bottomBar: PropTypes.node,
    basePath: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      company,
      bookmarking,
      bookmarkCompany,
      companyInfo,
      className,
      bottomBar,
      basePath,
    } = this.props;

    const showInfo = company.buy_type !== Buys.NO_RECOMMENDATION;

    return (
      <Row className={classNames('company-header analyst-company-header', className)}>
        <div className="d-flex flex-wrap">
          <Col xs={12} md={showInfo ? 6 : 12} lg={showInfo ? 7 : 12} className="detail-section">
            <Grid fluid>
              <Row className="company-detail d-flex align-center">
                <Col xs={12} className="company-title">
                  {company.title}
                </Col>
                <Bookmark
                  bookmarked={company.bookmarks.length}
                  bookmarking={bookmarking}
                  onClick={bookmarkCompany}
                />
              </Row>
              <Row>
                {company.buy_type !== Buys.NO_RECOMMENDATION ? (
                  company.video_url ? (
                    <Html className="video-container" content={company.vimeo_data.embed} />
                  ) : company.post_investment_thesis ? (
                    <div>
                      <div className="investment-thesis">
                        <FormattedMessage id="ANALYST.INVESTMENT_THESIS" />
                      </div>
                      <Html className="text-editor-view" content={company.post_investment_thesis} />
                    </div>
                  ) : null
                ) : (
                  <div className="no-recommendation">
                    <FormattedMessage id="ANALYST.NO_CURRENT_RECOMMENDATION" />
                  </div>
                )}
              </Row>
            </Grid>
            {company.authors?.map((author) => (
              <Col key={author.id} xs={12} md={12} lg={6} className="company-header-author">
                <Author author={author} basePath={basePath} />
              </Col>
            ))}
          </Col>
          {showInfo ? (
            <Col xs={12} md={6} lg={5} className="info-section">
              {companyInfo ? companyInfo : null}
            </Col>
          ) : null}
          {bottomBar ? bottomBar : null}
        </div>
      </Row>
    );
  }
}

export default AnalystCompanyHeader;
