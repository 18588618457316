import * as contactsService from 'modules/shared/services/contacts';

export const GET_COUNTRIES_STARTED = 'GET_COUNTRIES_STARTED';
export const GET_COUNTRIES_COMPLETED = 'GET_COUNTRIES_COMPLETED';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

/**
 * @returns {Function} Async action.
 */
export const fetchCountries = () => {
  return async (dispatch) => {
    dispatch({ type: GET_COUNTRIES_STARTED });

    try {
      const data = await contactsService.fetchCountries();

      dispatch({ type: GET_COUNTRIES_COMPLETED, payload: data });

      return data;
    } catch (e) {
      dispatch({ type: GET_COUNTRIES_FAILED });
    }
  };
};
