import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as appMainComponents from 'modules/main/components/shared';
import * as appAnalystComponents from 'modules/analyst/modules/shared';
import * as appSharedComponents from 'modules/shared';
import { SignUpModal } from 'modules/edge/modules/shared';

class ComponentLoader extends Component {
  static propTypes = {
    components: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
    location: PropTypes.object,
    loading: PropTypes.bool,
  };

  /**
   * @description open sign up modal
   * */
  handleShow = () => {
    this.signUpModalRef.handleShow();
  };

  /**
   * @returns {JSX.Element} - Component Loader component
   */
  render() {
    const { components, location, loading } = this.props;

    const appComponents = Object.assign(
      appAnalystComponents,
      Object.assign(appMainComponents, appSharedComponents)
    );

    return (
      <div>
        {components && components.length && !loading
          ? components.map((component, index) => {
              const Comp = Object.values(appComponents).find((cmp) => {
                return cmp.componentType === component.componentType;
              });
              if (Comp) {
                return (
                  <div key={index}>
                    <SignUpModal
                      backdropClassName="edge"
                      onRef={(r) => (this.signUpModalRef = r)}
                    />
                    <Comp
                      data={component.data}
                      id={component.id}
                      location={location}
                      handleShow={this.handleShow}
                    />
                  </div>
                );
              } else {
                return '';
              }
            })
          : null}
      </div>
    );
  }
}

export default ComponentLoader;
