import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendMessage } from '../../actions/contact';
import Contact from './Contact';

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ sendMessage }, dispatch);
};

export default connect(null, mapDispatchToProps)(Contact);
