/**
 * @param {string} site name.
 * @param {string} slug name.
 * @param {array} files uploaded.
 * @param {function} uploadFile function.
 *
 * @returns {Object} uploaded file.
 */
const pagesFileUpload = async (site, slug, files, uploadFile) => {
  const formData = new FormData();
  formData.append('files', files[0]);
  return await uploadFile(site, slug, formData);
};

/**
 * @param {array} files uploaded.
 * @param {function} uploadFile function.
 *
 * @returns {Object} uploaded file.
 */
const commonFileUpload = async (files, uploadFile) => {
  const formData = new FormData();
  formData.append('file', files[0]);
  const response = await uploadFile(formData);
  response.path = response.path.slice(1, response.path.length);
  return response;
};

export { pagesFileUpload, commonFileUpload };
