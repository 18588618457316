import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createPassword } from 'modules/main/actions/users';
import CreatePassword from './CreatePassword';

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createPassword }, dispatch);
};

export default connect(null, mapDispatchToProps)(CreatePassword);
