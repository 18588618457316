/**
 * @param {Object} context - context object has status of editor.
 */
import $ from 'jquery';
import env from 'summernote/src/js/base/core/env';

export const insertImage = function(context) {
  // ui has renders to build ui elements.
  //  - you can create a button with `ui.button`
  const ui = $.summernote.ui;
  this.$body = $(document.body);
  this.$editor = context.layoutInfo.editor;
  this.options = context.options;
  const result = { files: '', headNote: true, footNote: true };

  // this.options.dialogsInBody ? this.$body :

  const $container = this.options.dialogsInBody ? this.$body : this.$editor;

  let imageLimitation = '';
  if (this.options.maximumImageFileSize) {
    const unit = Math.floor(Math.log(this.options.maximumImageFileSize) / Math.log(1024));
    const readableSize = `${(this.options.maximumImageFileSize / Math.pow(1024, unit)).toFixed(2) *
      1} KMGTP'[unit] B`;
    imageLimitation = `<small> file size too big: ${readableSize}</small>`;
  }

  const body = [
    '<div class="form-group note-form-group note-group-select-from-files">',
    '<label class="note-form-label"> Select from files </label>',
    '<input class="note-image-input note-form-control note-input" ',
    ' type="file" name="files" accept="image/*" multiple="multiple" />',
    imageLimitation,
    '</div>',
    '<div class="form-group note-group-image-url" style="overflow:auto;">',
    '<label class="note-form-label"> Image URL </label>',
    '<input class="note-image-url form-control note-form-control note-input ',
    ' col-md-12" type="text" />',
    '</div>',
    '<div class="form-group note-group-image-url" style="overflow:auto;">',
    '<input class="note-insert-headnote" type="checkbox" name="insertHeadnote" value="insetHeadnote" checked> Insert headnote',
    '<input class="note-insert-footnote" type="checkbox" name="insertFootnote" value="insertFootnote" style="margin-left: 15px;" checked> Insert footnote',
    '</div>',
  ].join('');
  const buttonClass = 'btn btn-primary note-btn note-btn-primary note-image-btn';
  const footer = `<button type="submit" href="#" class="${buttonClass}" disabled>
    Insert Image </button>`;

  this.$dialog = ui
    .dialog({
      title: 'Insert Image',
      fade: true,
      body,
      footer,
    })
    .render()
    .appendTo($container);

  /**
   * @description show dialog
   * */
  const show = () => {
    context.invoke('editor.saveRange');
    showImageDialog()
      .then((data) => {
        // [workaround] hide dialog before restore range for IE range focus
        ui.hideDialog(this.$dialog);
        context.invoke('editor.restoreRange');

        if (typeof data === 'string') {
          // image url
          context.invoke('editor.insertImage', data);
        } else {
          // array of files
          context.invoke('editor.insertImagesOrCallback', data);
        }
      })
      .fail(() => {
        context.invoke('editor.restoreRange');
      });
  };

  /**
   * @param {Node} input element
   * @param {Node} btn element
   * */
  const bindEnterKey = (input, btn) => {
    input.on('keypress', (event) => {
      if (event.keyCode === 13) {
        // ENTER
        event.preventDefault();
        btn.trigger('click');
      }
    });
  };

  /**
   * @description show image dialog
   *
   * @returns {Promise} dialog promise
   */
  const showImageDialog = () => {
    return $.Deferred((deferred) => {
      const $imageInput = this.$dialog.find('.note-image-input');
      const $imageUrl = this.$dialog.find('.note-image-url');
      const $imageBtn = this.$dialog.find('.note-image-btn');
      const $headNote = this.$dialog.find('.note-insert-headnote');
      const $footNote = this.$dialog.find('.note-insert-footnote');

      result.files = '';
      result.headNote = true;
      result.footNote = true;

      $headNote.prop('checked', true);
      $footNote.prop('checked', true);

      ui.onDialogShown(this.$dialog, () => {
        context.triggerEvent('dialog.shown');

        // Cloning imageInput to clear element.
        $imageInput.replaceWith(
          $imageInput
            .clone()
            .on('change', (event) => {
              // deferred.resolve(event.target.files || event.target.value);
              result.files = event.target.files || event.target.value;
              $imageBtn.removeAttr('disabled');
            })
            .val('')
        );

        $imageBtn.click((event) => {
          event.preventDefault();

          deferred.resolve(result);
        });

        $headNote.click((event) => {
          result.headNote = event.target.checked;
        });

        $footNote.click((event) => {
          result.footNote = event.target.checked;
        });

        $imageUrl
          .on('keyup paste', () => {
            const url = $imageUrl.val();
            result.files = url;
            ui.toggleBtn($imageBtn, url);
          })
          .val('');

        if (!env.isSupportTouch) {
          $imageUrl.trigger('focus');
        }
        bindEnterKey($imageUrl, $imageBtn);
      });

      ui.onDialogHidden(this.$dialog, () => {
        $imageInput.off('change');
        $imageUrl.off('keyup paste keypress');
        $imageBtn.off('click');

        if (deferred.state() === 'pending') {
          deferred.reject();
        }
      });

      ui.showDialog(this.$dialog);
    });
  };

  // add hello button
  context.memo('button.insertImage', () => {
    // create button
    const button = ui.button({
      contents: '<i class="note-icon-picture"/>',
      tooltip: 'Picture',
      container: 'body',
      click: () => {
        show();
      },
    });

    // create jQuery object from button instance.
    return button.render();
  });
};
