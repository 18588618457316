import * as PagesService from 'modules/shared/services/pages';

export const PAGE_UPDATE = 'PAGE_UPDATE';
export const GET_PAGE = 'GET_PAGE';

/**
 * @param {string} site is name of page parent.
 * @param {string} slug is page name.
 * @param {Object} page content of page.
 *
 * @returns {Function} Async action.
 */
export const updatePage = (site, slug, page) => {
  return async (dispatch) => {
    dispatch({
      type: PAGE_UPDATE,
    });

    return await PagesService.updatePage(site, slug, page);
  };
};

/**
 * @param {string} site is name of page parent.
 * @param {string} slug is page name.
 *
 * @returns {Function} Async action.
 */
export const getPage = (site, slug) => {
  return async (dispatch) => {
    const { content } = await PagesService.getPage(site, slug);

    dispatch({ type: GET_PAGE, payload: content });

    return content;
  };
};
