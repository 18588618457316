import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, Checkbox } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './FieldGroup.scss';
import { ErrorIconContainer } from 'modules/admin/shared';

/**
 * @param {value} value that validation depend on.
 * @param {otherValue} otherValue that validation depend on after value.
 * @returns {any} return validation of state.
 */
export const getErrorState = (value, otherValue) => {
  return value ? null : !otherValue ? 'error' : null;
};

/**
 * @description Gets the placeholder from translation or from raw text as fallback.
 *
 * @returns {string}
 */
const getPlaceholder = ({ placeholder, placeholderId, intl }) => {
  return placeholderId ? intl.formatMessage({ id: placeholderId }) : placeholder;
};

/**
 * @param {string} type of input element
 * @param {Object} currentCheckbox reference of this component
 * @description trigger click event on checkbox input
 * */
const clickOnCheckbox = (type, currentCheckbox) => {
  if (type === 'checkbox' && currentCheckbox) {
    currentCheckbox.click();
  }
};

/**
 *
 * @param {string} param.label Label for form item
 * @param {string} param.type type for form item
 * @param {string} param.error for form item
 *
 * @returns {React.Component} FormGroup component
 */
const FieldGroup = ({
  label,
  className,
  type,
  validationState,
  error,
  value,
  checked,
  touched,
  placeholder,
  placeholderId,
  intl,
  customCheckmark,
  noMargin,
  ...props
}) => {
  let currentCheckbox;
  return (
    <FormGroup
      validationState={validationState}
      className={classNames(className, {
        'has-error': error,
        'no-margin': noMargin,
        touched,
      })}>
      {label ? (
        <ControlLabel onClick={() => clickOnCheckbox(type, currentCheckbox)}>
          {label}
          {props.required ? '*' : null}
        </ControlLabel>
      ) : null}
      {(type === 'control' || type === 'text') && (
        <div className="form-control-container">
          <FormControl
            value={value === null ? '' : value}
            placeholder={getPlaceholder({
              placeholder,
              placeholderId,
              intl,
            })}
            {...props}
          />
          <ErrorIconContainer error={error} touched={touched} />
        </div>
      )}
      {type === 'checkbox' && (
        <Checkbox
          inputRef={(ref) => {
            currentCheckbox = ref;
          }}
          className={classNames({
            'custom-checkmark': customCheckmark,
          })}
          checked={checked || false}
          {...props}>
          {customCheckmark ? <span className="checkmark" /> : null}
        </Checkbox>
      )}
    </FormGroup>
  );
};

FieldGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.any,
  touched: PropTypes.bool,
  validationState: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderId: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.any,
  intl: PropTypes.object,
  customCheckmark: PropTypes.bool,
  required: PropTypes.bool,
  noMargin: PropTypes.bool,
};

FieldGroup.defaultProps = {
  type: 'control',
};

export default withStyles(style)(injectIntl(FieldGroup));
