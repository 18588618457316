import { connect } from 'react-redux';
import MegaMenu from './MegaMenu';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    profile: app.profile,
    settings: app.userSettings,
  };
};

export default connect(mapStateToProps)(MegaMenu);
