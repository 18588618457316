import * as ContributorService from '../services/contributors';
export const GET_CONTRIBUTORS = 'GET_CONTRIBUTORS';
export const DELETE_CONTRIBUTOR = 'DELETE_CONTRIBUTOR';
export const GET_CONTRIBUTOR = 'GET_CONTRIBUTOR';
export const SAVE_CONTRIBUTOR = 'SAVE_CONTRIBUTOR';
export const MODIFY_CONTRIBUTOR = 'MODIFY_CONTRIBUTOR';
export const SAVE_CONTRIBUTOR_AVATAR = 'SAVE_CONTRIBUTOR_AVATAR';

/**
 * @param {Object} options Query options object.
 * @returns {Function} Async contributors.
 */
export const getContributors = (options) => async (dispatch) => {
  const contributors = await ContributorService.getContributors(options);

  dispatch({
    type: GET_CONTRIBUTORS,
    payload: contributors,
  });

  return contributors;
};

/**
 * @param {number} id Contributor Id
 * @returns {Function}
 */
export const deleteContributor = (id) => async (dispatch) => {
  const contributor = await ContributorService.deleteContributor(id);

  dispatch({
    type: DELETE_CONTRIBUTOR,
    payload: contributor,
  });

  return contributor;
};

/**
 * @description Get Contributor by id
 * @param {number} id Contributor ID.
 * @returns {Function}
 */
export const getContributor = (id) => async (dispatch) => {
  const contributor = await ContributorService.getContributor(id);

  dispatch({
    type: GET_CONTRIBUTOR,
    payload: contributor,
  });

  return contributor;
};

/**
 * @param {Object} data Contributor data.
 * @returns {Function}
 */
export const saveContributor = (data) => async (dispatch) => {
  const contributor = await ContributorService.saveContributor(data);

  dispatch({
    type: SAVE_CONTRIBUTOR,
    payload: contributor,
  });

  return contributor;
};

/**
 * @param {number} id Contributor Id
 * @param {Object} data Contributor data.
 * @returns {Function}
 */
export const modifyContributor = (id, data) => async (dispatch) => {
  const contributor = await ContributorService.modifyContributor(id, data);

  dispatch({
    type: MODIFY_CONTRIBUTOR,
    payload: contributor,
  });

  return contributor;
};

/**
 * @param {number} id user Id
 * @param {Object} data user data.
 * @returns {Function}
 */
export const saveAvatar = (id, data) => async (dispatch) => {
  const avatar = await ContributorService.saveAvatar(id, data);

  dispatch({
    type: SAVE_CONTRIBUTOR_AVATAR,
    payload: avatar,
  });

  return avatar;
};
