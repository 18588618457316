import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';

/**
 * @param {Object} params filter
 * @description Gets all investmentStyle.
 * @returns {Promise<*>}
 */
export const getInvestmentStyles = async (params) => {
  const resource = `investmentstyle${getPaginationQueryParams(params)}`;
  return await request({
    resource,
    method: Methods.GET,
  });
};

/**
 * @param {number} id investmentStyle id
 * @returns {Promise<*>}
 */
export const getInvestmentStyle = async (id) => {
  return await request({
    resource: `investmentstyle/${id}`,
  });
};

/**
 * @param {Object} data investmentStyle object
 * @returns {Promise<*>}
 */
export const saveInvestmentStyle = async (data) => {
  return await request({
    resource: `investmentstyle`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id investmentStyle id
 * @param {Object} data investmentStyle object
 * @returns {Promise<*>}
 */
export const modifyInvestmentStyle = async (id, data) => {
  return await request({
    resource: `investmentstyle/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id investmentStyle id
 * @returns {Promise<*>}
 */
export const deleteInvestmentStyle = async (id) => {
  return await request({
    resource: `investmentstyle/${id}`,
    method: Methods.DELETE,
  });
};
