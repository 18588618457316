import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './FeedbackExplanation.scss';

/**
 * @returns {JSX.Element} with explanation style
 */
const FeedbackExplanation = ({ explanation, small, lightFontWeight, topMargin, mobile }) => (
  <div
    className={classNames('feedback-ratio-explanation', {
      'small-text': small,
      'light-font-weight': lightFontWeight,
      'mt-2': topMargin,
      mobile,
    })}>
    {explanation}
  </div>
);

FeedbackExplanation.propTypes = {
  explanation: PropTypes.node,
  small: PropTypes.bool,
  lightFontWeight: PropTypes.bool,
  topMargin: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default withStyles(styles)(FeedbackExplanation);
