import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './style.scss';
import { ProfileImage, Modal } from 'modules/shared/components';
import { Modal as BsModal, Col, Row, Grid } from 'react-bootstrap';

class PeopleGroups extends React.Component {
  static componentType = 'people';
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    show: false,
    modalData: {},
  };

  /**
   * @description Hides the modal.
   */
  handleClose = () => {
    this.setState({ show: false });
  };

  /**
   * @param {Object} person to show on modal
   *
   * @description Shows the modal.
   */
  handleShow = (person) => {
    this.setState({ show: true, modalData: person });
  };

  /**
   * @param {Object} data object
   *
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    return (
      <div>
        <Modal
          className="person-modal"
          show={this.state.show}
          onHide={this.handleClose}
          onSubmit={this.handleClose}>
          <BsModal.Header closeButton>
            <BsModal.Title id="contained-BsModal-title-sm">
              <Row className="person-modal-header">
                <Col sm={2} xs={8} className="v-center image-col">
                  <ProfileImage profile={this.state.modalData} showBorder={true} />
                </Col>
                <Col sm={8} xs={12} className="v-center">
                  <h3>{this.state.modalData.name}</h3>
                  <h4>{this.state.modalData.job_position}</h4>
                </Col>
              </Row>
            </BsModal.Title>
          </BsModal.Header>
          <BsModal.Body className="person-modal-body">
            {this.state.modalData.introduction}
          </BsModal.Body>
        </Modal>

        {this.props.data.groups &&
          this.props.data.groups.map((group, index) => {
            return (
              <Grid key={index}>
                <Row className="people-group">
                  <Col lg={12}>
                    <div className="group-name">{group.name}</div>
                  </Col>
                  <Col lg={12}>
                    <Row className="group-members">
                      <div className="d-flex flex-wrap">
                        {group.people &&
                          group.people.map((person, personIndex) => {
                            return (
                              <Col
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                key={personIndex}
                                className="person-detail"
                                onClick={() => this.handleShow(person)}>
                                <Row>
                                  <Col lg={12}>
                                    <ProfileImage profile={person} showBorder={true} />
                                  </Col>
                                  <Col lg={12}>
                                    <div className="person-name">{person.name}</div>
                                  </Col>
                                  <Col lg={12}>
                                    <div className="person-job"> {person.job_position}</div>
                                  </Col>
                                </Row>
                              </Col>
                            );
                          })}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Grid>
            );
          })}
      </div>
    );
  }
}

export default withStyles(style)(PeopleGroups);
