import * as ShortScreenService from 'modules/analyst/services/short-screen';

export const GET_SHORT_SCREEN = 'GET_SHORT_SCREEN';
export const SAVE_SHORT_SCREEN = 'SAVE_SHORT_SCREEN';
export const SAVE_SHORT_SCREEN_FEATURED_IMAGE = 'SAVE_SHORT_SCREEN_FEATURED_IMAGE';

/**
 * @description Gets short screen.
 * @returns {Function}
 */
export const getShortScreen = () => async (dispatch) => {
  const response = await ShortScreenService.getShortScreen();

  dispatch({
    type: GET_SHORT_SCREEN,
    payload: response,
  });

  return response;
};

/**
 *  @param {Object} data Short screen object
 * @description Save short screen.
 * @returns {Function}
 */
export const saveShortScreen = (data) => async (dispatch) => {
  const response = await ShortScreenService.saveShortScreen(data);

  dispatch({
    type: SAVE_SHORT_SCREEN,
    payload: response,
  });

  return response;
};

/**
 * @param {Object} data Short screen featured image
 * @description Save short screen.
 * @returns {Function}
 */
export const saveShortScreenFeaturedImage = (data) => async (dispatch) => {
  const response = await ShortScreenService.saveShortScreenFeaturedImage(data);

  dispatch({
    type: SAVE_SHORT_SCREEN_FEATURED_IMAGE,
    payload: response,
  });

  return response;
};
