import request from 'modules/shared/util/request';
import { Methods } from 'modules/shared/util/http';

const BASE_URL = 'send-model';

/**
 * @param {Object} data SendModel object
 *
 * @returns {Promise<*>}
 */
export const send = async (data) => {
  return await request({
    resource: `${BASE_URL}`,
    method: Methods.POST,
    data,
  });
};
