import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Env from 'env';
import { SvgIcon, Tooltip } from 'modules/shared';
import withDimensions from 'modules/shared/util/withDimensions';
import { isFeatureStoppedGlowing, stopGlowing } from 'common';

const SVG_ICON_HEIGHT = 21;

/**
 *
 * @param {string} to Path to navigate on click.
 * @param {string} iconClass class of the menu icon.
 * @param {string} svgIconClass class of the menu icon.
 * @param {string} svgIconClassHover class of the menu icon.
 * @param {string} iconPlaceholder Name of the menu icon.
 * @param {JSX.Element | string} title Title for the Link.
 * @param {JSX.Element | string} children Elements children.
 * @param {string} activeClassName Class name to apply when the route is active.
 * @param {boolean} highlighted indicate highlight style.
 * @param {boolean} noRightPaddingContent indicate no right padding for content style.
 * @param {boolean} isNewFeature indicate if the menu item is new feature.
 * @param {boolean} snowNewFeatureIcon indicate if we wan't to show the new feature icon.
 * @param {number} windowWidth current width of window.
 * @returns {JSX.Element}
 */
const SideMenuItem = ({
  to,
  iconClass,
  svgIconClass,
  svgIconClassHover,
  iconPlaceholder,
  title,
  children,
  activeClassName,
  onClick,
  isNewFeature,
  snowNewFeatureIcon,
  windowWidth,
  feature,
}) => {
  const isDesktopScreen = windowWidth > Env.BREAKPOINTS.MOBILE_SCREEN;
  const [highlighted, setHighlighted] = useState(false);

  const onStopGlowing = useCallback(() => {
    if (feature) {
      stopGlowing(feature);
      setHighlighted(false);
    }
  }, [feature, setHighlighted]);

  const handleClick = useCallback(
    (e) => {
      onStopGlowing();

      if (onClick) {
        onClick(e);
      }
    },
    [feature, onClick, onStopGlowing]
  );

  useEffect(() => {
    setHighlighted(feature && !isFeatureStoppedGlowing(feature) && isNewFeature);

    const timeout = setTimeout(() => {
      onStopGlowing();
    }, Env.STOP_GLOWING_DURATION);

    return () => {
      clearTimeout(timeout);
    };
  }, [onStopGlowing]);

  return (
    <NavLink
      className="sidebar-item"
      to={to}
      activeClassName={activeClassName}
      onClick={handleClick}>
      <div
        className={classNames('content', {
          highlighted,
          'no-right-padding-content': isNewFeature,
        })}>
        <div className="module-icon-container">
          {iconClass ? (
            <i className={classNames('module-icon', iconClass)} />
          ) : (
            <span className="icon-placeholder">{iconPlaceholder}</span>
          )}
          {svgIconClass ? (
            <SvgIcon className="svg-module-icon" height={SVG_ICON_HEIGHT} name={svgIconClass} />
          ) : null}
          {svgIconClassHover ? (
            <SvgIcon
              className="svg-module-icon-hover"
              height={SVG_ICON_HEIGHT}
              name={svgIconClassHover}
            />
          ) : null}
        </div>
        <div className="title">{title}</div>
        {children}
        {isNewFeature && snowNewFeatureIcon ? (
          <Tooltip
            overlay={
              <SvgIcon
                height={45}
                width={130}
                name={
                  isDesktopScreen
                    ? 'new-feature-tooltip-right-left'
                    : 'new-feature-tooltip-left-right'
                }
              />
            }
            placement={isDesktopScreen ? 'right' : 'left'}
            tooltipText={<FormattedMessage id="MAIN.NEW_FEATURE" />}
            icon={<SvgIcon height={19} name="analyst-icon" />}
          />
        ) : null}
      </div>
    </NavLink>
  );
};

SideMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  svgIconClass: PropTypes.string,
  svgIconClassHover: PropTypes.string,
  iconPlaceholder: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  activeClassName: PropTypes.string,
  highlighted: PropTypes.bool,
  noRightPaddingContent: PropTypes.bool,
  onClick: PropTypes.func,
  isNewFeature: PropTypes.bool,
  snowNewFeatureIcon: PropTypes.bool,
  windowWidth: PropTypes.number,
  feature: PropTypes.string,
};

export default withDimensions(SideMenuItem);
