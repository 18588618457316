import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import { Button as RBButton } from 'react-bootstrap';
import { Spinner } from 'modules/shared';

import styles from './Button.scss';

/**
 * @param {Object} props Props object
 *
 * @returns {JSX.Element}
 */
const Button = ({ children, loading, disabled, className, ...props }) => {
  return (
    <RBButton
      className={classNames('admin-button', className)}
      disabled={disabled || loading}
      {...props}>
      {loading ? <Spinner size="small" /> : null}
      {children}
    </RBButton>
  );
};
Button.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  ...RBButton.propTypes,
};

export default withStyles(styles)(Button);
