import { GET_SHORT_SCREEN } from '../actions/short-screen';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const shortScreen = (state = {}, action) => {
  switch (action.type) {
    case GET_SHORT_SCREEN: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default shortScreen;
