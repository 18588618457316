import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CompaniesList } from 'modules/admin/shared';

import { getCompanyPage, deleteCompany } from 'modules/analyst/actions/companies';
import { match } from 'common';
import { table } from 'modules/shared';

class AnalystCompaniesList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    basePath: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <CompaniesList
        match={this.props.match}
        fetchData={this.props.fetchData}
        onSearch={this.props.onSearch}
        deleteItem={this.props.deleteItem}
        data={this.props.data}
        pages={this.props.pages}
        loading={this.props.loading}
        search={this.props.search}
        basePath={this.props.basePath}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDataWs: getCompanyPage, deleteItemWs: deleteCompany }, dispatch);

export default connect(null, mapDispatchToProps)(table({ nofilter: true })(AnalystCompaniesList));
