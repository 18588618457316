import { GET_IDEAS } from '../actions/ideas';
import { sortBy } from 'common';

const sortByPosition = sortBy();

/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const ideas = (state = [], action) => {
  switch (action.type) {
    case GET_IDEAS: {
      return (action.payload || []).sort(sortByPosition);
    }
    default:
      return state;
  }
};

export default ideas;
