import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Env from 'env';

import { isBrowser } from 'modules/shared/util/isBrowser';
import { ContactModalFilledContext } from '../contactModalFilledContext';

const getcontactModalFilled = () => {
  if (isBrowser()) {
    return sessionStorage.getItem(Env.CONTACT_MODAL_FILLED) === 'true';
  }
  return false;
};

const ContactModalContainer = ({ children, page }) => {
  const [contactModalFilled, setContactModal] = useState(getcontactModalFilled());

  const setContactModalFilled = () => {
    if (isBrowser()) {
      sessionStorage.setItem(Env.CONTACT_MODAL_FILLED, 'true');
    }
    setContactModal(true);
  };

  return (
    <ContactModalFilledContext.Provider value={{ contactModalFilled, setContactModalFilled, page }}>
      {children}
    </ContactModalFilledContext.Provider>
  );
};

ContactModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
};

export default ContactModalContainer;
