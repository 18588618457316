import React, { Component } from 'react';
import { PageEditor } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';

class SampleResearch extends Component {
  /**
   * @returns {JSX.Element} Disclaimers And Disclosures component.
   */
  render() {
    return (
      <PageEditor
        site="analyst"
        slug="sample-research"
        title={<FormattedMessage id="ANALYST.HEADER.SAMPLE_RESEARCH" />}
        viewLink="/sample-research"
      />
    );
  }
}

export default SampleResearch;
