import React from 'react';
import PropTypes from 'prop-types';

import { Bookmark } from '../';

/**
 * @param {string} title - Title of the post
 * @param {string} ticker - Company ticker
 * @param {string} closing_price - Closing price
 * @param {Function} bookmark - Bookmark function
 * @param {boolean} bookmarking - Boookmarking is in progress
 * @param {boolean} bookmarked - Post is bookmarked
 * @returns {JSX.Element[]}
 * @constructor
 */
const DetailedHeader = ({ title, ticker, closing_price, bookmark, bookmarking, bookmarked }) => {
  return [
    <div key="header" className="d-flex justify-between detailed-header">
      <div className="d-flex title-container">
        <div className="title">{title}</div>
        <div className="info">
          {ticker} {closing_price ? '@' : null} {closing_price}
        </div>
      </div>
      <Bookmark bookmarking={bookmarking} onClick={bookmark} bookmarked={bookmarked} />
    </div>,
    <hr key="line" />,
  ];
};

DetailedHeader.propTypes = {
  title: PropTypes.string,
  ticker: PropTypes.string,
  closing_price: PropTypes.string,
  ...Bookmark.propTypes,
};

export default DetailedHeader;
