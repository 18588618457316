import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './BlogPostDetail.scss';
import { Grid, Row, Col } from 'react-bootstrap';
import Env from 'env';
import { formatDate, getFileIcon } from 'common';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { LinkedinShareButton, TwitterShareButton } from 'react-share';
import { LightBox, Html, Img } from '../index';

class BlogPostDetail extends Component {
  static propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    showIcons: PropTypes.bool,
    className: PropTypes.string,
    articleHubLink: PropTypes.string,
    listPageLink: PropTypes.string.isRequired,
    listPageLabel: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]),
  };

  /**
   * @returns {string} detail page full url
   * */
  getDetailFullURL = () => {
    return global.window ? global.window.location.href : '';
  };

  /**
   * @returns {JSX.Element} - Our People component
   */
  render() {
    return (
      <div>
        <Grid className={classNames('blog-posts-detail', this.props.className)}>
          <Row className="pt-4 back-link">
            <Col xs={12} sm={12} className="d-flex flex-wrap align-center">
              <ol className="mr-3 mb-3" itemScope itemType="http://schema.org/BreadcrumbList">
                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                  <NavLink to={this.props.listPageLink} itemProp="item">
                    <span itemProp="name">{this.props.listPageLabel}</span>
                  </NavLink>
                  <meta itemProp="position" content="1" />
                </li>
              </ol>

              {this.props.data.tags
                ? this.props.data.tags.map((tag, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={`${this.props.articleHubLink}/${tag.slug}`}
                        className="tag-label mr-3 mb-3">
                        {tag.name}
                      </NavLink>
                    );
                  })
                : null}
            </Col>
          </Row>
          <Row className="d-flex flex-wrap">
            <Col xs={this.props.showIcons ? 7 : 12} sm={this.props.showIcons ? 9 : 12}>
              <h1 className="blog-post-title mt-2 mb-2">{this.props.data.title}</h1>
            </Col>
            {this.props.showIcons ? (
              <Col xs={5} sm={3} className="d-flex justify-end align-center">
                <TwitterShareButton
                  url={this.getDetailFullURL()}
                  title={this.props.data.title}
                  className="icon-container ml-3">
                  <i className="fab fa-twitter" />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={this.getDetailFullURL()}
                  title={this.props.data.title}
                  className="icon-container ml-3">
                  <i className="fab fa-linkedin-in" />
                </LinkedinShareButton>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col xs={12} sm={12} className="mb-2 blog-post-created-at">
              {formatDate(this.props.data.published_at)}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12}>
              <Img
                src={
                  this.props.data.featured_image_path
                    ? Env.S3_URL + this.props.data.featured_image_path
                    : Env.ANALYST_DEFAULT_IMAGE
                }
                title={this.props.data.meta_title}
                alt={this.props.data.meta_description}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} className="mt-2 mb-2 blog-summary">
              {this.props.data.summary}
            </Col>
          </Row>
          <Row>
            <Html
              component={Col}
              xs={12}
              sm={12}
              className="mt-2 mb-2 blog-posts-detail-html-content"
              content={this.props.data.content}
            />
            {global.window ? (
              <LightBox containerClassName="blog-posts-detail-html-content" />
            ) : null}
          </Row>
          {this.props.data.downloads && this.props.data.downloads.length > 0 ? (
            <Row className="mb-3">
              <Col xs={12} sm={12} className="mt-2 mb-1 blog-downloads">
                <h4 className="pb-2">
                  <FormattedMessage id="MAIN.DOWNLOADS" />
                </h4>
              </Col>
              {this.props.data.downloads
                ? this.props.data.downloads.map((data, index) => {
                    return (
                      <Col xs={12} sm={12} key={index} className="mt-2 mb-1 blog-downloads-item">
                        <a
                          href={Env.S3_URL + data.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={data.id}>
                          <i
                            className={classNames(
                              'mr-3',
                              getFileIcon({
                                mime_type: data.mime_type,
                                file_name: data.file_name,
                              })
                            )}
                          />
                          {data.custom_properties.display_name}
                        </a>
                      </Col>
                    );
                  })
                : null}
            </Row>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(BlogPostDetail);
