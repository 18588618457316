import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';

import CompaniesCard from './CompaniesCard';
import RelatedPosts from '../company-detail/RelatedPosts';
import style from './CompanyPostResult.scss';

class CompanyPostResult extends Component {
  static propTypes = {
    companies: PropTypes.array,
    posts: PropTypes.array,
    companyTitle: PropTypes.node.isRequired,
    postsTitle: PropTypes.node.isRequired,
    companyEmptyMessage: PropTypes.node,
    postEmptyMessage: PropTypes.node,
    defaultImage: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    subPath: PropTypes.string,
    bookmarkCompany: PropTypes.func.isRequired,
    bookmarkPost: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    afterCompanyBookmark: PropTypes.func.isRequired,
    afterPostBookmark: PropTypes.func.isRequired,
    rounded: PropTypes.bool,
    fetchData: PropTypes.func,
    noDidMountFetch: PropTypes.bool,
    updateData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    companies: [],
  };

  isBookmarkedCompany = (company) => {
    return !!(company.bookmarks && company.bookmarks.length);
  };

  /**
   * @description Updates the company array on parent.
   * @param {Object} company Company to update.
   * @param {Array} bookmarks Bookmarks array.
   */
  setCompanyBookmark(company, bookmarks) {
    const companyIndex = this.props.companies.findIndex(({ id }) => id === company.id);
    const companies = this.props.companies.map((c) => ({ ...c }));
    companies[companyIndex].bookmarks = bookmarks;
    this.props.afterCompanyBookmark(companies);
  }

  bookmarkCompany = async (company) => {
    if (company.bookmarks.length) {
      await this.props.deleteBookmark(company.bookmarks[0].id);
      this.setCompanyBookmark(company, []);
    } else {
      const bookmark = await this.props.bookmarkCompany(company.id);
      this.setCompanyBookmark(company, [bookmark]);
    }
  };

  /**
   * @description Updates the posts array on parent.
   * @param {Array} posts posts array.
   */
  afterBookmark = (posts) => {
    this.props.afterPostBookmark(posts);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      companies,
      posts,
      companyTitle,
      defaultImage,
      basePath,
      subPath,
      postsTitle,
      postEmptyMessage,
      companyEmptyMessage,
      rounded,
      bookmarkPost,
      deleteBookmark,
      ...props
    } = this.props;

    return (
      <div className="company-post-result">
        <CompaniesCard
          title={companyTitle}
          companies={companies}
          basePath={basePath}
          subPath={subPath}
          rounded={rounded}
          emptyMessage={companyEmptyMessage}
          bookmark={this.bookmarkCompany}
          isBookmarked={this.isBookmarkedCompany}
        />
        <Grid fluid className="post-list">
          <Row>
            <div className="d-flex flex-wrap post-list-row container-margin justify-center">
              <RelatedPosts
                noMarginOnRow
                titleMarginLeft
                noTitleMarginRight
                posts={posts}
                title={postsTitle}
                bookmarkPost={bookmarkPost}
                deleteBookmark={deleteBookmark}
                basePath={basePath}
                defaultImage={defaultImage}
                emptyMessage={postEmptyMessage}
                {...props}
                afterBookmark={this.afterBookmark}
              />
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(CompanyPostResult);
