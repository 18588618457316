/**
 * @param {Object} context - context object has status of editor.
 */
import $ from 'jquery';

export const frame = function(context) {
  // ui has renders to build ui elements.
  //  - you can create a button with `ui.button`
  const ui = $.summernote.ui;

  // add hello button
  context.memo('button.frame', () => {
    // create button
    const button = ui.button({
      contents: '<i class="far fa-square"/>',
      tooltip: 'Insert Frame',
      container: 'body',
      click: () => {
        let $node = $(context.invoke('restoreTarget'));
        if ($node.length === 0) {
          const $parentNode = $(document.getSelection().focusNode.parentElement, '.note-editable');
          if ($parentNode && $parentNode[0].className === 'note-editable') {
            $node = $(document.getSelection().focusNode, '.note-editable');
          } else {
            $node = $parentNode;
          }
        }
        $node.toggleClass('frame');
      },
    });

    // create jQuery object from button instance.
    return button.render();
  });
};
