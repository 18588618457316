import * as FileUploadService from '../services/file-upload';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_FILE = 'DELETE_FILE';

/**
 * @description upload file data.
 *
 * @param {string} site aiming site
 * @param {string} slug aiming slug
 * @param {Object} data include file
 *
 * @returns {function}
 */
export const pagesUploadFile = (site, slug, data) => async (dispatch) => {
  const file = await FileUploadService.pagesUploadFile(site, slug, data);

  dispatch({
    type: UPLOAD_FILE,
    payload: file,
  });

  return file;
};

/**
 * @description upload file data.
 *
 * @param {Object} data include file
 *
 * @returns {function}
 */
export const commonUploadFile = (data) => async (dispatch) => {
  const file = await FileUploadService.commonUploadFile(data);

  dispatch({
    type: UPLOAD_FILE,
    payload: file,
  });

  return file;
};

/**
 * @description delete file.
 *
 * @param {Object} options aiming site
 *
 * @returns {function}
 */
export const deleteFile = (options) => async (dispatch) => {
  const file = await FileUploadService.deleteFile(options);

  dispatch({
    type: DELETE_FILE,
    payload: file,
  });

  return file;
};
