import * as MainService from '../services';
import { getAllSettings } from './system-settings';
import fileDownload from 'js-file-download';
import { formatDate, removeTokens, setToken, removeGlowing, setRefreshToken } from 'common';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const GET_PROFILE = 'GET_PROFILE';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER = 'GET_USER';
export const SAVE_USER = 'SAVE_USER';
export const MODIFY_USER = 'MODIFY_USER';
export const SAVE_AVATAR = 'SAVE_AVATAR';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';

/**
 * @description Transforms array of objects with key-value pairs to object.
 * @param {Array<{key: string, value: string}>} settings Array to transform
 * @returns {Object}
 */
const transformSettings = (settings) =>
  settings.reduce((prev, { key, value }) => {
    prev[key] = value;
    return prev;
  }, {});

/**
 * @param {Object} credentials Email - Password.
 *
 * @returns {Function} Async login action.
 */
export const login = (credentials) => async (dispatch) => {
  try {
    const token = await MainService.login(credentials);

    setToken(token);
    setRefreshToken(token);

    return await Promise.all([dispatch(getProfile()), dispatch(getAllSettings())]);
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      meta: { throwError: true },
      error,
    });

    return error;
  }
};

/**
 * @param {Object} credentials Email, Password, Confirm password Token.
 *
 * @returns {Function} Async create password action.
 */
export const createPassword = (credentials) => async (dispatch) => {
  try {
    const token = await MainService.createPassword(credentials);
    setToken(token);

    return await Promise.all([dispatch(getProfile()), dispatch(getAllSettings())]);
  } catch (error) {
    dispatch({
      type: 'GENERIC_ERROR',
      meta: { throwError: true, ignoreToast: true },
      error,
    });

    return error;
  }
};

/**
 * @param {Object} credentials Email.
 *
 * @returns {Function} Async forgot password action.
 */
export const forgotPassword = (credentials) => async (dispatch) => {
  try {
    return await MainService.forgotPassword(credentials);
  } catch (error) {
    dispatch({
      type: 'GENERIC_ERROR',
      meta: { throwError: true, ignoreToast: true },
      error,
    });

    return error;
  }
};

/**
 * @returns {Function} Async getProfile action.
 */
export const getProfile = () => async (dispatch) => {
  const profile = await MainService.getProfile();

  await Promise.all([
    dispatch(getUserSettings()),
    dispatch(getUserPreferences()),
    dispatch(getAllSettings()),
  ]);

  dispatch({
    type: GET_PROFILE,
    payload: profile,
  });

  return profile;
};

/**
 * @param {Object} data user data
 * @returns {Function} Async saveProfile action.
 */
export const saveProfile = (data) => async (dispatch) => {
  const profile = await MainService.saveProfile(data);

  dispatch({
    type: SAVE_PROFILE,
    payload: profile,
  });

  return profile;
};

/**
 * @returns {Function} Async saveProfile action.
 */
export const exportProfile = () => async () => {
  const profile = await MainService.exportProfile();

  fileDownload(
    JSON.stringify(profile),
    `profile-${profile.email}-${formatDate(new Date(), { timeFormat: true })}.json`
  );

  return profile;
};

/**
 * @returns {Function} Async saveProfile action.
 */
export const deleteProfile = () => async (dispatch) => {
  const profile = await MainService.deleteProfile();

  dispatch({
    type: DELETE_PROFILE,
    payload: profile,
  });

  return profile;
};

/**
 * @param {boolean} fromServer If the users should be logged out from the server too.
 * @returns {Function} Async login action.
 */
export const logout = (fromServer) => async (dispatch) => {
  const res = fromServer && (await MainService.logout());
  removeTokens();
  removeGlowing();

  dispatch({
    type: LOGOUT,
  });

  return res;
};

/**
 * @returns {Function} Async login action.
 */
export const getEmployees = () => async (dispatch) => {
  const employees = await MainService.getEmployees();

  dispatch({
    type: GET_EMPLOYEES,
    payload: employees,
  });

  return employees;
};

/**
 * @returns {Function} get all users.
 * @param {Object} options pagination options.
 */
export const getUsers = (options) => async (dispatch) => {
  const users = await MainService.getUsers(options);

  dispatch({
    type: GET_USERS,
    payload: users,
  });

  return users;
};

/**
 * @param {number} id user Id
 * @returns {Function}
 */
export const deleteUser = (id) => async (dispatch) => {
  const user = await MainService.deleteUser(id);

  dispatch({
    type: DELETE_USER,
    payload: user,
  });

  return user;
};

/**
 * @description Get user by id
 * @param {number} id user ID.
 * @returns {Function}
 */
export const getUser = (id) => async (dispatch) => {
  const user = await MainService.getUser(id);

  dispatch({
    type: GET_USER,
    payload: user,
  });

  return user;
};

/**
 * @param {Object} data user data.
 * @returns {Function}
 */
export const saveUser = (data) => async (dispatch) => {
  const user = await MainService.saveUser(data);

  dispatch({
    type: SAVE_USER,
    payload: user,
  });

  return user;
};

/**
 * @param {number} id user Id
 * @param {Object} data user data.
 * @returns {Function}
 */
export const modifyUser = (id, data) => async (dispatch) => {
  const user = await MainService.modifyUser(id, data);

  dispatch({
    type: MODIFY_USER,
    payload: user,
  });

  return user;
};

/**
 * @param {number} id user Id
 * @param {Object} data user data.
 * @returns {Function}
 */
export const saveAvatar = (id, data) => async (dispatch) => {
  const avatar = await MainService.saveAvatar(id, data);

  dispatch({
    type: SAVE_AVATAR,
    payload: avatar,
  });

  return avatar;
};

/**
 * @returns {Function}
 */
export const getUserSettings = () => async (dispatch) => {
  const userSettings = await MainService.getUserSettings();
  const transformed = transformSettings(userSettings);
  dispatch({
    type: GET_USER_SETTINGS,
    payload: transformed,
  });

  return transformed;
};

/**
 * @param {Object<{key: string, value: string}>} data Data to stve
 * @returns {Function}
 */
export const saveUserSettings = (data) => async (dispatch, getState) => {
  if (Object.keys(getState().app.userSettings).includes(data.key)) {
    await MainService.modifySetting(data.key, data);
  } else {
    await MainService.createSetting(data);
  }
};

/**
 * @returns {Function}
 */
export const getUserPreferences = () => async (dispatch) => {
  const userPreferences = await MainService.getUserPreferences();
  const transformed = transformSettings(userPreferences);
  dispatch({
    type: GET_USER_PREFERENCES,
    payload: transformed,
  });

  return transformed;
};

/**
 * @param {Object<{key: string, value: string}>} data Data to stve
 * @returns {Function}
 */
export const saveUserPreferences = (data) => async (dispatch, getState) => {
  if (Object.keys(getState().app.userPreferences).includes(data.key)) {
    await MainService.modifyUserPreference(data.key, data);
  } else {
    await MainService.createUserPreference(data);
  }
};
