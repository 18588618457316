import { BaseBuys, SellNoRecommendationBuys } from 'modules/shared/models';

export const Buys = {
  ...BaseBuys,
  ...SellNoRecommendationBuys,
};

export const BuyTypes = {
  [Buys.STRONG_BUY]: {
    name: 'Strong Buy',
    icon: 'arrow-top2',
  },
  [Buys.BUY]: {
    name: 'Buy',
    icon: 'arrow-blue',
  },
  [Buys.SHORT]: {
    name: 'Short',
    icon: 'arrow-red',
  },
  [Buys.SELL]: {
    name: 'Sell',
    icon: 'arrow-red',
  },
  [Buys.NO_RECOMMENDATION]: {
    name: 'No Current Recommendation',
  },
};
