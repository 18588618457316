import { FormattedMessage } from 'react-intl';
import React from 'react';

export const SurveyStatus = {
  INACTIVE: 0,
  ACTIVE: 1,
  OUTDATED: 2,
};

export const SurveyStatusLabel = {
  [SurveyStatus.INACTIVE]: {
    label: <FormattedMessage id="ADMIN.SURVEYS.INACTIVE" />,
  },
  [SurveyStatus.ACTIVE]: {
    label: <FormattedMessage id="ADMIN.SURVEYS.ACTIVE" />,
  },
  [SurveyStatus.OUTDATED]: {
    label: <FormattedMessage id="ADMIN.SURVEYS.OUTDATED" />,
  },
};
