import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './VideoPanel.scss';
import { Img, Spinner } from 'modules/shared';

class VideoPanel extends Component {
  static propTypes = {
    videos: PropTypes.array,
    basePath: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    videos: [],
  };

  /**
   * @param {Object} video object
   * @returns {string} return thumbnail image
   * */
  getThumbnail = (video) => {
    const thumbnails = video.vimeo_data.thumbnails;
    if (thumbnails && thumbnails[3]) {
      return thumbnails[3].link_with_play_button;
    } else if (thumbnails && thumbnails.length > 0 && thumbnails[thumbnails.length - 1]) {
      return thumbnails[thumbnails.length - 1].link_with_play_button;
    } else {
      return '';
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { videos, basePath, loading } = this.props;
    return (
      <div className="info video-panel">
        <div className="title">
          <h4>
            <FormattedMessage id="MAIN.OTHER_VIDEOS" />
          </h4>
          <hr />
        </div>
        {loading ? (
          <div className="loading-container d-flex justify-center">
            <Spinner size="small" className="loader" />
          </div>
        ) : (
          <Fragment>
            <div className="videos">
              {videos
                .filter((video) => video.vimeo_data)
                .map((video) => {
                  return (
                    <Link
                      to={`${basePath}/post/${video.id}/${video.slug}`}
                      key={video.id}
                      className="link video-thumbnail-container"
                      title={video.title}>
                      <div className="title">{video.title}</div>
                      <Img
                        className="video-thumbnail"
                        src={this.getThumbnail(video)}
                        alt={video.title}
                      />
                    </Link>
                  );
                })}
            </div>
            {!videos || (videos && !videos.length) ? (
              <FormattedMessage id="MAIN.OTHER_VIDEOS_EMPTY" />
            ) : null}
            {videos && videos.length ? (
              <Fragment>
                <hr />
                <Link
                  to={`${basePath}/all-research`}
                  className="link back-to-all-research d-flex justify-between align-center">
                  <i className="fa fa-caret-left" />
                  <FormattedMessage id="MAIN.BACK_TO_ALL_RESEARCH" />
                </Link>
              </Fragment>
            ) : null}
          </Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(VideoPanel);
