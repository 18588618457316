import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './AddPlaceholder.scss';

class AddPlaceholder extends Component {
  static propTypes = {
    className: PropTypes.string,
    inverse: PropTypes.bool,
  };
  /**
   * @returns {JSX.Element}
   */
  render() {
    const { className, inverse, ...props } = this.props;
    return (
      <div className={classNames(className, 'add-placeholder', { inverse })} {...props}>
        <span className="fa fa-plus add-icon" />
      </div>
    );
  }
}

export default withStyles(styles)(AddPlaceholder);
