import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './ModelPortfolioTable.scss';
import { NavLink } from 'react-router-dom';
import { BuyTypes } from 'common';
import { SectorAccess, Position } from 'modules/shared';

const OLD_COLUMNS_LENGTH = 3;
const NEW_COLUMNS_WIDTH = 3;
const OLD_COLUMNS_WIDTH = 4;

class ModelPortfolioTable extends Component {
  static propTypes = {
    ideaType: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    data: PropTypes.object.isRequired,
    transformedData: PropTypes.object.isRequired,
    basePath: PropTypes.string,
    topBorder: PropTypes.bool,
    topPadding: PropTypes.bool,
    className: PropTypes.string,
  };

  /**
   * @param {int} length columns length
   * @returns {int} col width
   */
  getColWidth(length) {
    return length === OLD_COLUMNS_LENGTH ? OLD_COLUMNS_WIDTH : NEW_COLUMNS_WIDTH;
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { ideaType, label, data, transformedData, topBorder, topPadding, className } = this.props;

    return (
      <Col xs={12} sm={12} className={classNames('p-0 model-portfolio-table', className)}>
        {label ? (
          <Col
            xs={12}
            sm={12}
            className={classNames('ideas-by-type m-0', ideaType, {
              'top-border': topBorder,
              'top-padding': topPadding,
            })}>
            {label}
          </Col>
        ) : null}

        <Col
          xs={12}
          sm={12}
          className={classNames('visible-md visible-lg pl-0 pr-0', {
            'mb-5': ideaType === 'long-ideas',
          })}>
          {data.modelIdeas.map((modelIdea, index) => {
            return (
              <Row key={index} className="m-0">
                <div className="d-flex flex-wrap">
                  {index === 0 ? (
                    <Col xs={10} sm={10} className="p-0 d-flex">
                      {modelIdea.columns.map((column, columnIndex) => {
                        const colWidth = this.getColWidth(modelIdea.columns.length);

                        return (
                          <Col
                            key={columnIndex}
                            xs={colWidth}
                            sm={colWidth}
                            className="column-title">
                            <FormattedMessage id={column.columnName.props.id} />
                          </Col>
                        );
                      })}
                    </Col>
                  ) : null}
                  {index === 0 ? (
                    <Col xs={2} sm={2} className="p-0 d-flex column-title-placeholder" />
                  ) : null}
                  <Col xs={10} sm={10} className="p-0 d-flex">
                    {modelIdea.columns.map((column, columnIndex) => {
                      const colWidth = this.getColWidth(modelIdea.columns.length);

                      return (
                        <Col key={columnIndex} xs={colWidth} sm={colWidth} className="companies">
                          {column.companies.map((company, companyIndex) => {
                            return (
                              <div key={companyIndex} className="companies-value">
                                <Col xs={10} sm={10}>
                                  <SectorAccess
                                    hasAccess={company.company.has_sector_access}
                                    position={Position.RIGHT}>
                                    <NavLink
                                      className={classNames({
                                        disabled: !company.company.has_sector_access,
                                      })}
                                      to={`${this.props.basePath}/company/${company.company.id}/${company.company.slug}`}>
                                      <span>{company.company.title}</span>
                                    </NavLink>
                                  </SectorAccess>
                                  <div className="model-portfolio-tooltip">
                                    {company.company.buy_type ? (
                                      <div>{BuyTypes[company.company.buy_type].name}</div>
                                    ) : null}
                                    <div>
                                      <FormattedMessage id="MAIN.PRICE_TARGET" />:{' '}
                                      <span className="price-target-value">
                                        {company.company.price_target}
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={2} sm={2}>
                                  {company.value}
                                </Col>
                              </div>
                            );
                          })}
                        </Col>
                      );
                    })}
                  </Col>
                  <Col xs={2} sm={2} className="idea-rate d-flex">
                    {modelIdea.rate.label}
                  </Col>
                </div>
              </Row>
            );
          })}
          <Row className="m-0">
            <div className="d-flex flex-wrap">
              <Col xs={10} sm={10} className="p-0 d-flex">
                {data.modelIdeas.length
                  ? data.modelIdeas[0].columns.map((column, columnIndex) => {
                      const colWidth = this.getColWidth(data.modelIdeas[0].columns.length);

                      return (
                        <Col
                          key={columnIndex}
                          xs={colWidth}
                          sm={colWidth}
                          className="total-companies">
                          <div className="companies-value">
                            <Col xs={10} sm={10}>
                              <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.SUB_TOTAL" />
                            </Col>
                            <Col xs={2} sm={2}>
                              {data.columnsSubTotal[columnIndex]}
                            </Col>
                          </div>
                        </Col>
                      );
                    })
                  : null}
              </Col>

              <Col xs={2} sm={2} className={classNames('total-idea-rate d-flex', ideaType)}>
                <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.TOTAL" />
                <span className="ml-1">{data.total}</span>
              </Col>
            </div>
          </Row>
        </Col>

        <Col
          xs={12}
          sm={12}
          className={classNames('hidden-md hidden-lg pl-0 pr-0', {
            'mb-5': ideaType === 'long-ideas',
          })}>
          {transformedData.columns.map((column, columnIndex) => {
            return (
              <Row key={columnIndex} className="d-flex flex-wrap m-0">
                <Col xs={12} sm={12} className="column-title no-right-border">
                  <FormattedMessage id={column.columnName.props.id} />
                </Col>
                <Col xs={12} sm={12} className="d-flex flex-wrap p-0">
                  {column.modelIdeas.map((modelIdea, modelIdeaIndex) => {
                    return (
                      <Col
                        key={modelIdeaIndex}
                        xs={12}
                        sm={12}
                        className="companies no-right-border">
                        {modelIdea.companies.map((company, companyIndex) => {
                          return (
                            <div key={companyIndex} className="companies-value">
                              <Col xs={10} sm={10}>
                                <SectorAccess
                                  hasAccess={company.company.has_sector_access}
                                  position={Position.RIGHT}>
                                  <NavLink
                                    className={classNames({
                                      disabled: !company.company.has_sector_access,
                                    })}
                                    to={`${this.props.basePath}/company/${company.company.id}/${company.company.slug}`}>
                                    <span>{company.company.title}</span>
                                  </NavLink>
                                </SectorAccess>
                                <div className="model-portfolio-tooltip">
                                  {company.company.buy_type ? (
                                    <div>{BuyTypes[company.company.buy_type].name}</div>
                                  ) : null}
                                  <div>
                                    <FormattedMessage id="MAIN.PRICE_TARGET" />:{' '}
                                    <span className="price-target-value">
                                      {company.company.price_target}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={2} sm={2}>
                                {company.value}
                              </Col>
                            </div>
                          );
                        })}
                      </Col>
                    );
                  })}
                </Col>
                <Col xs={12} sm={12} className="total-companies no-right-border">
                  <div className="companies-value">
                    <Col xs={10} sm={10}>
                      <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.SUB_TOTAL" />
                    </Col>
                    <Col xs={2} sm={2}>
                      {column.columnSubTotal}
                    </Col>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Col>
    );
  }
}

export default withStyles(styles)(ModelPortfolioTable);
