/**
 * @param {Object} ref value to equal
 * @param {string} msg message to show if test failed
 *
 * @returns {Promise} valid
 * */
export const equalTo = function(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
};
