import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { match } from 'common';
import EdgePlusArticleAnalyticsList from './EdgePlusArticleAnalyticsList';
import EdgePlusArticleAnalyticsDetail from './EdgePlusArticleAnalyticsDetail';

class EdgePlusArticleAnalytics extends Component {
  static propTypes = {
    match,
  };

  /**
   * @returns{JSX.Element}
   */
  render() {
    return (
      <Switch>
        <Redirect exact from={`${this.props.match.path}/`} to={`${this.props.match.path}/list`} />
        <Route
          path={`${this.props.match.path}/list`}
          render={(props) => (
            <EdgePlusArticleAnalyticsList {...props} basePath={this.props.match.path} />
          )}
        />
        <Route
          path={`${this.props.match.path}/article/:id`}
          render={(props) => <EdgePlusArticleAnalyticsDetail {...props} />}
        />
      </Switch>
    );
  }
}

export default EdgePlusArticleAnalytics;
