import React, { Component } from 'react';
import { getSettings, deleteSetting } from 'modules/main/actions/system-settings';
import { EditorTitle, Button, FieldGroup, Delete } from 'modules/admin/shared';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactTable from 'react-table';
import { Row, Grid, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { table } from 'modules/shared';
import PropTypes from 'prop-types';
import { match } from 'common';

class SettingsList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { data, pages, loading, search, fetchData, onSearch, deleteItem } = this.props;
    return (
      <div>
        <EditorTitle title={<FormattedMessage id="ADMIN.SYSTEM_SETTINGS.TITLE" />}>
          <div>
            <NavLink to={`${this.props.match.path}new`}>
              <Button>
                <FormattedMessage id="ADMIN.SYSTEM_SETTINGS.NEW_SYSTEM_SETTING" />
              </Button>
            </NavLink>
          </div>
        </EditorTitle>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <FieldGroup onChange={onSearch} placeholderId="MAIN.SEARCH" value={search} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.SYSTEM_SETTINGS.KEY" />,
                    accessor: 'key',
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.SYSTEM_SETTINGS.VALUE" />,
                    accessor: 'value',
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.DESCRIPTION" />,
                    accessor: 'description',
                  },
                  {
                    Header: '',
                    maxWidth: 100,
                    Cell: ({ original }) => (
                      <div className="d-flex justify-evenly">
                        <div>
                          <NavLink to={`${this.props.match.path}${original.key}/edit`}>
                            <span className="action-icon fa fa-edit" />
                          </NavLink>
                        </div>
                        <Delete onSubmit={() => deleteItem(original.key)} />
                      </div>
                    ),
                    sortable: false,
                  },
                ]}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                resizable={false}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={fetchData} // Request new data when things change
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                style={{
                  height: 'calc(100vh - 217px)',
                }}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDataWs: getSettings,
      deleteItemWs: deleteSetting,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(table()(SettingsList));
