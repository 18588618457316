import * as contactsService from 'modules/shared/services/contacts';

export const GET_INVESTOR_TYPES_STARTED = 'GET_INVESTOR_TYPES_STARTED';
export const GET_INVESTOR_TYPES_COMPLETED = 'GET_INVESTOR_TYPES_COMPLETED';
export const GET_INVESTOR_TYPES_FAILED = 'GET_INVESTOR_TYPES_FAILED';

/**
 * @returns {Function} Async action.
 */
export const fetchInvestorTypes = () => {
  return async (dispatch) => {
    dispatch({ type: GET_INVESTOR_TYPES_STARTED });

    try {
      const data = await contactsService.fetchInvestorTypes();

      dispatch({ type: GET_INVESTOR_TYPES_COMPLETED, payload: data });

      return data;
    } catch (e) {
      dispatch({ type: GET_INVESTOR_TYPES_FAILED });
    }
  };
};
