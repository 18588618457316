import AnalystModelPortfolio from '../components/content/model-portfolio/AnalystModelPortfolio';
import ShortScreen from '../components/content/short-screen';
import SentimentTracker from '../components/content/sentiment-tracker';
import LongScreen from '../components/content/long-screen/LongScreen';
import WalkThrough from '../components/content/walk-through';
import AnalystPosts from '../components/content/posts/AnalystPosts';

const contentRoutes = [
  {
    path: '/admin/content/factor-panel',
    name: 'Factor Panel',
    component: LongScreen,
  },
  {
    path: '/admin/content/model-portfolio',
    name: 'Model Portfolio',
    component: AnalystModelPortfolio,
  },
  {
    path: '/admin/content/posts',
    name: 'Posts',
    component: AnalystPosts,
  },
  {
    path: '/admin/content/sentiment-tracker',
    name: 'Sentiment Tracker',
    component: SentimentTracker,
  },
  {
    path: '/admin/content/short-screen',
    name: 'Short Screen',
    component: ShortScreen,
  },
  {
    path: '/admin/content/walk-through',
    name: 'Walk Through',
    component: WalkThrough,
  },
  {
    redirect: true,
    from: '/admin/content',
    to: '/admin/content/factor-panel',
    key: 'redirect',
  },
];

export default contentRoutes;
