import React, { Component } from 'react';
import { PageEditor } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';

class Careers extends Component {
  /**
   * @returns {JSX.Element} OurPeople component.
   */
  render() {
    return (
      <PageEditor
        site="landing"
        slug="careers"
        title={<FormattedMessage id="MAIN.HEADER.CAREERS" />}
        viewLink="/careers"
      />
    );
  }
}

export default Careers;
