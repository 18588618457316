import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * @param {boolean} show Whether the content should be visible.
 * @param {string} message Error message.
 * @returns {null}
 */
const ErrorIcon = ({ show, message }) => {
  return show && message && (typeof message === 'string' || React.isValidElement(message)) ? (
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-right">{message}</Tooltip>}>
      <i className="fas fa-exclamation error-icon" />
    </OverlayTrigger>
  ) : null;
};

ErrorIcon.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.any,
};

export default ErrorIcon;
