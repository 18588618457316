import * as MainService from '../services';

export const MEDIA_DOWNLOAD_TRACKING = 'MEDIA_DOWNLOAD_TRACKING';

/**
 * @param {number} id media.
 *
 * @returns {Promise} Async Contact action.
 */
export const trackMediaDownload = (id) => async (dispatch) => {
  const response = await MainService.trackMediaDownload(id);

  dispatch({
    type: MEDIA_DOWNLOAD_TRACKING,
    payload: response,
  });

  return response;
};
