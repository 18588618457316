import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import RecommendationDrawer from './RecommendationDrawer';

class DrawerMenu extends Component {
  static propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    toggleDrawer: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.node.isRequired,
    drawerHeight: PropTypes.number,
    companies: PropTypes.array,
    basePath: PropTypes.string.isRequired,
  };

  /**
   * @returns {JSX.Element[]}
   */
  render() {
    return [
      <div
        key="item"
        className={classNames(this.props.className, 'sidebar-item', {
          active: this.props.open,
        })}
        onClick={this.props.toggleDrawer}>
        <div className="content">
          <div className="module-icon-container">{this.props.icon}</div>
          <div className="title">{this.props.title}</div>
          <i className="expand fa fa-caret-right" />
        </div>
      </div>,
      <RecommendationDrawer
        basePath={this.props.basePath}
        key="drawer"
        style={{ maxHeight: this.props.drawerHeight }}
        title={this.props.title}
        companies={this.props.companies}
        open={this.props.open}
        toggleDrawer={this.props.toggleDrawer}
      />,
    ];
  }
}

export default DrawerMenu;
