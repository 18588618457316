import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';

const BASE_URL = 'surveys';
/**
 * @param {Object} params filter
 * @description Gets surveys
 * @returns {Promise<*>}
 */
export const getSurveys = async (params) => {
  return await request({
    resource: `${BASE_URL}${getPaginationQueryParams(params)}`,
  });
};

/**
 * @param {Object} data survey object
 * @returns {Promise<*>}
 */
export const saveSurvey = async (data) => {
  return await request({
    resource: BASE_URL,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {Object} data survey object
 * @returns {Promise<*>}
 */
export const editSurvey = async (data) => {
  return await request({
    resource: `${BASE_URL}/${data.id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id Survey id
 * @returns {Promise<*>}
 */
export const getSurvey = async (id) => {
  return await request({
    resource: `${BASE_URL}/${id}`,
  });
};

/**
 * @description get latest survey
 * @returns {Promise<*>}
 */
export const getLatestSurvey = async () => {
  return await request({
    resource: `${BASE_URL}/latest`,
  });
};

/**
 * @param {number} id Survey id
 * @description get exported survey
 * @returns {Promise<*>}
 */
export const exportSurvey = async (id) => {
  return await request({
    resource: `${BASE_URL}/${id}/export`,
  });
};

/**
 * @param {Object} data survey object
 * @description get exported survey
 * @returns {Promise<*>}
 */
export const takeSurvey = async (data) => {
  return await request({
    resource: `${BASE_URL}/take`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Survey id
 * @returns {Promise<*>}
 */
export const getSurveyAnalytics = async (id) => {
  return await request({
    resource: `${BASE_URL}/${id}/analytics`,
  });
};
