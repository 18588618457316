import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getShortScreen,
  saveShortScreen,
  saveShortScreenFeaturedImage,
} from 'modules/analyst/actions/short-screen';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import ShortScreen from './ShortScreen';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst }) => {
  return {
    shortScreen: analyst.shortScreen,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getShortScreen, saveShortScreen, saveShortScreenFeaturedImage, showLoader, hideLoader },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortScreen);
