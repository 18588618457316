import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';

/**
 * @param {Object} params filter
 * @description Gets all marketcaps.
 * @returns {Promise<*>}
 */
export const getMarketcaps = async (params) => {
  const resource = `marketcap${getPaginationQueryParams(params)}`;
  return await request({
    resource,
    method: Methods.GET,
  });
};

/**
 * @param {number} id marketcaps id
 * @returns {Promise<*>}
 */
export const getMarketcap = async (id) => {
  return await request({
    resource: `marketcap/${id}`,
  });
};

/**
 * @param {Object} data marketcaps object
 * @returns {Promise<*>}
 */
export const saveMarketcap = async (data) => {
  return await request({
    resource: `marketcap`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id marketcaps id
 * @param {Object} data marketcaps object
 * @returns {Promise<*>}
 */
export const modifyMarketcap = async (id, data) => {
  return await request({
    resource: `marketcap/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id marketcaps id
 * @returns {Promise<*>}
 */
export const deleteMarketcap = async (id) => {
  return await request({
    resource: `marketcap/${id}`,
    method: Methods.DELETE,
  });
};
