import React, { Component } from 'react';
import Env from 'env';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { match, location } from 'common/propTypes';
import { AccountManagerModal, Backdrop, ProfileDropdown } from 'modules/shared';
import withDimensions from 'modules/shared/util/withDimensions';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './AppSideMenu.scss';

class AppSideMenu extends Component {
  static propTypes = {
    open: PropTypes.bool,
    drawerOpen: PropTypes.bool,
    recommendations: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    toggleSidebar: PropTypes.func.isRequired,
    onSearchClick: PropTypes.func.isRequired,
    editItem: PropTypes.object,
    white: PropTypes.bool,
    getExtraHeight: PropTypes.func,
    onBackdropClick: PropTypes.func,
    showBackdrop: PropTypes.bool,
    hasAccess: PropTypes.bool,
    windowWidth: PropTypes.number,
    match,
    location,
  };

  static defaultProps = {
    recommendations: {},
  };

  /**
   * @description Sets the state to all drawers to be closed.
   */
  closeDrawers = () => {
    this.setState({ drawerState: this.closeDrawersState() });
  };

  /**
   * @description Sets the window width and adds event listener to window resize.
   */
  componentDidMount() {
    if (window.innerWidth < Env.BREAKPOINTS.MOBILE_SCREEN) {
      this.props.toggleSidebar(null, false);
    }
  }
  /**
   * @description Calculates the height of the header.
   *
   * @returns {Object|null}
   */
  getHeight = () => {
    if (this.props.windowWidth >= Env.BREAKPOINTS.MOBILE_SCREEN) {
      return {
        header: null,
        menu: null,
        container: null,
      };
    }
    const headerHeight = this.menuHeader ? this.menuHeader.offsetHeight : 0;
    const menuHeight = this.menu ? this.menu.offsetHeight : 0;
    const extraHeight = this.props.getExtraHeight ? this.props.getExtraHeight() : 0;

    const menu = this.props.open ? menuHeight + extraHeight : 0;
    return {
      header: headerHeight,
      container: headerHeight + menu,
      menu,
    };
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      open,
      showBackdrop,
      className,
      onSearchClick,
      editItem,
      white,
      hasAccess,
      drawerOpen,
    } = this.props;
    return (
      <div
        className={classNames('app-side-menu', className, {
          'hide-menu': !hasAccess,
          open,
          white,
          drawerOpen,
        })}
        style={{ maxHeight: this.getHeight().container }}>
        <div
          className={classNames('menu-header d-flex align-center', {
            'justify-end': !hasAccess,
            'justify-between': hasAccess,
          })}
          ref={(r) => (this.menuHeader = r)}
          onClick={this.props.toggleSidebar}>
          {hasAccess ? (
            <span className="toggle-section">
              <i
                className={classNames('fas menu-toggle', open ? 'fa-angle-up' : 'fa-angle-down')}
              />
              <FormattedMessage id="MAIN.MENU" />
            </span>
          ) : null}
          <span className="d-flex align-center actions" onClick={(e) => e.stopPropagation()}>
            <div className="visible-xs mr-3">
              <AccountManagerModal iconVariant />
            </div>
            {hasAccess ? (
              <i
                className={classNames('icon-search-outline', { light: !white })}
                onClick={onSearchClick}
              />
            ) : null}
            <ProfileDropdown editItem={editItem} darkColor={white} profilePath="/app" />
          </span>
        </div>
        {hasAccess ? (
          <div className="menu-container" style={{ maxHeight: this.getHeight().menu }}>
            <div className="menu-content" ref={(r) => (this.menu = r)}>
              {this.props.children}
            </div>
          </div>
        ) : null}
        {hasAccess && showBackdrop ? (
          <Backdrop onClick={this.props.onBackdropClick} className="analyst-sidebar-backdrop" />
        ) : null}
      </div>
    );
  }
}

export default withDimensions(withStyles(styles)(AppSideMenu));
