import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {Node} title node props.
 * @param {Array} recommendations displayed array props
 *
 * @returns {JSX.Element}
 */
const BuyTypeTitle = ({ title, recommendations }) => (
  <strong>
    {title} ({recommendations ? recommendations.length : 0})
  </strong>
);

BuyTypeTitle.propTypes = {
  title: PropTypes.node.isRequired,
  recommendations: PropTypes.array,
};

export default BuyTypeTitle;
