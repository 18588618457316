import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { match } from 'common';
import ArticleAnalyticsList from './AnalystArticleAnalyticsList';
import ArticleAnalyticsDetail from './AnalystArticleAnalyticsDetail';

class AnalystArticleAnalytics extends Component {
  static propTypes = {
    match,
  };

  /**
   * @returns{JSX.Element}
   */
  render() {
    return (
      <Switch>
        <Redirect exact from={`${this.props.match.path}/`} to={`${this.props.match.path}/list`} />
        <Route
          path={`${this.props.match.path}/list`}
          render={(props) => <ArticleAnalyticsList {...props} basePath={this.props.match.path} />}
        />
        <Route
          path={`${this.props.match.path}/article/:id`}
          render={(props) => <ArticleAnalyticsDetail {...props} />}
        />
      </Switch>
    );
  }
}

export default AnalystArticleAnalytics;
