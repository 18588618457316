import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputs } from 'modules/admin/shared/components';
import styles from './Header.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FileUpload, TextEditor } from 'modules/admin/shared';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pagesFileUpload } from 'common';
import { pagesUploadFile } from 'modules/admin/main/actions/file-upload';
import { FormattedMessage } from 'react-intl';

class Header extends Component {
  static componentType = 'header';
  static blockName = 'Header';
  static icon = 'fa fa-image';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
    pagesUploadFile: PropTypes.func.isRequired,
  };

  state = {
    image: {},
    image_id: null,
    title: '',
    description: '',
    loading: false,
    loaded: false,
  };

  onDropImage = (files) => {
    this.setState({ image: files.length > 0 ? files[0] : null, loading: true }, async () => {
      const { title } = this.state;

      const { path, id } = await pagesFileUpload(
        this.props.site,
        this.props.slug,
        files,
        this.props.pagesUploadFile
      );
      this.setState({ loading: false, image: path });
      this.props.onChange({ image: path, image_id: id, title, valid: true });
    });
  };

  deleteFile = async () => {
    this.setState(
      {
        image: null,
        image_id: null,
      },
      () => {
        const { image, image_id, title, description } = this.state;
        this.props.onChange({ image, image_id, title, description, valid: true });
      }
    );
  };

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.setState({ ...this.props.data, loaded: true });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const { image, image_id, title, description } = this.state;
      this.props.onChange({ image, image_id, title, description, valid: true });
    });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        <FormInputs
          ncols={['col-md-12']}
          properties={[
            {
              label: 'Title',
              type: 'text',
              bsClass: 'form-control',
              placeholder: 'Title',
              name: 'title',
              value: this.state.title,
              onChange: this.onChange,
            },
          ]}
        />
        <ControlLabel>
          <FormattedMessage id="ADMIN.DESCRIPTION" />
        </ControlLabel>
        <TextEditor
          name="description"
          handleChange={this.onChange}
          text={this.state.description}
          loaded={this.state.loaded}
        />
        <FormGroup className="admin-image-preview-container">
          <ControlLabel>Background Image</ControlLabel>
          <FileUpload
            file={this.state.image}
            fileId={this.state.image_id}
            onDropFile={this.onDropImage}
            deleteFile={this.deleteFile}
            loading={this.state.loading}
          />
        </FormGroup>
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pagesUploadFile,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(Header));
