import { FormattedMessage } from 'react-intl';
import React from 'react';

export const QuestionTypes = {
  radioButtons: 0,
  freeText: 1,
};

export const QuestionTypesLabel = {
  [QuestionTypes.radioButtons]: {
    label: <FormattedMessage id="ADMIN.SURVEYS.RADIO_TYPE" />,
  },
  [QuestionTypes.freeText]: {
    label: <FormattedMessage id="ADMIN.SURVEYS.TEXT_TYPE" />,
  },
};
