import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';

class LiNavLink extends Component {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    activeClassName: PropTypes.string,
    className: PropTypes.string,
    activeStyle: PropTypes.object,
    style: PropTypes.object,
    isActive: PropTypes.func,
  };

  static defaultProps = {
    activeClassName: 'active',
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      to,
      exact,
      strict,
      activeClassName,
      className,
      activeStyle,
      style,
      isActive: getIsActive,
      ...rest
    } = this.props;
    return (
      <Route
        path={typeof to === 'object' ? to.pathname : to}
        exact={exact}
        strict={strict}
        // eslint-disable-next-line
        children={({ location, match }) => {
          const isActive = !!(getIsActive ? getIsActive(match, location) : match);

          return (
            <li
              className={isActive ? [activeClassName, className].join(' ').trim() : className}
              style={isActive ? { ...style, ...activeStyle } : style}>
              <Link to={to} {...rest} />
            </li>
          );
        }}
      />
    );
  }
}

export default LiNavLink;
