import * as MainService from '../services/system-settings';

export const GET_SYSTEM_SETTINGS = 'GET_SYSTEM_SETTINGS';
export const GET_ALL_SYSTEM_SETTINGS = 'GET_ALL_SYSTEM_SETTINGS';
export const DELETE_SYSTEM_SETTING = 'DELETE_SYSTEM_SETTING';
export const GET_SYSTEM_SETTING = 'GET_SYSTEM_SETTING';
export const SAVE_SYSTEM_SETTING = 'SAVE_SYSTEM_SETTING';
export const MODIFY_SYSTEM_SETTING = 'MODIFY_SYSTEM_SETTING';

/**
 * @param {Object} options params
 *
 * @returns {Function} get all settings.
 */
export const getSettings = (options) => async (dispatch) => {
  const settings = await MainService.getSettings({ ...options, nopaginate: false });
  dispatch({
    type: GET_SYSTEM_SETTINGS,
    payload: settings,
  });

  return settings;
};

/**
 * @returns {Function} get all settings.
 */
export const getAllSettings = () => async (dispatch) => {
  const settings = await MainService.getSettings({ nopaginate: true });
  const transformed = settings.reduce((prev, { key, value }) => {
    prev[key] = value;
    return prev;
  }, {});
  dispatch({
    type: GET_ALL_SYSTEM_SETTINGS,
    payload: transformed,
  });

  return transformed;
};

/**
 * @param {number} id setting Id
 * @returns {Function}
 */
export const deleteSetting = (id) => async (dispatch) => {
  const setting = await MainService.deleteSetting(id);
  await dispatch(getAllSettings());
  dispatch({
    type: DELETE_SYSTEM_SETTING,
    payload: setting,
  });

  return setting;
};

/**
 * @description Get setting by id
 * @param {number} id user ID.
 * @returns {Function}
 */
export const getSetting = (id) => async (dispatch) => {
  const setting = await MainService.getSetting(id);
  dispatch({
    type: GET_SYSTEM_SETTING,
    payload: setting,
  });

  return setting;
};

/**
 * @param {Object} data setting data.
 * @returns {Function}
 */
export const saveSetting = (data) => async (dispatch) => {
  const setting = await MainService.saveSetting(data);
  await dispatch(getAllSettings());
  dispatch({
    type: SAVE_SYSTEM_SETTING,
    payload: setting,
  });

  return setting;
};

/**
 * @param {number} id user Id
 * @param {Object} data user data.
 * @returns {Function}
 */
export const modifySetting = (id, data) => async (dispatch) => {
  const setting = await MainService.modifySetting(id, data);
  await dispatch(getAllSettings());
  dispatch({
    type: MODIFY_SYSTEM_SETTING,
    payload: setting,
  });

  return setting;
};
