import request, { Methods } from 'modules/shared/util/request';

/**
 * @description Gets short screen
 * @returns {Promise<*>}
 */
export const getShortScreen = async () => {
  return await request({
    resource: `short-screen`,
  });
};

/**
 * @param {Object} data Short screen object
 * @returns {Promise<*>}
 */
export const saveShortScreen = async (data) => {
  return await request({
    resource: `short-screen`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {Object} data Short screen featured image
 * @returns {Promise<*>}
 */
export const saveShortScreenFeaturedImage = async (data) => {
  return await request({
    resource: `short-screen/featured_image`,
    method: Methods.POST,
    data,
  });
};
