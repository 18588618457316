import * as GeographyService from '../services/geography';

export const GET_ALL_GEOGRAPHY = 'GET_ALL_GEOGRAPHY';
export const GET_GEOGRAPHY = 'GET_GEOGRAPHY';
export const SAVE_GEOGRAPHY = 'SAVE_GEOGRAPHY';
export const MODIFY_GEOGRAPHY = 'MODIFY_GEOGRAPHY';
export const DELETE_GEOGRAPHY = 'DELETE_GEOGRAPHY';

/**
 * @param {Object} options of get
 * @returns {Function} Get Geographies action.
 */
export const getGeographies = (options) => async (dispatch) => {
  const geographies = await GeographyService.getGeographies(options);

  dispatch({
    type: GET_ALL_GEOGRAPHY,
    payload: geographies,
  });

  return geographies;
};

/**
 * @description Gets the Geography for the ID:
 * @param {number} id Geography ID.
 * @returns {Function}
 */
export const getGeography = (id) => async (dispatch) => {
  const geography = await GeographyService.getGeography(id);

  dispatch({ type: GET_GEOGRAPHY, payload: geography });

  return geography;
};

/**
 * @param {Object} data Geography data.
 * @returns {Function}
 */
export const saveGeography = (data) => async (dispatch) => {
  const geography = await GeographyService.saveGeography(data);

  dispatch({
    type: SAVE_GEOGRAPHY,
    payload: geography,
  });

  return geography;
};

/**
 * @param {number} id Geography Id
 * @param {Object} data Geography data.
 * @returns {Function}
 */
export const modifyGeography = (id, data) => async (dispatch) => {
  const geography = await GeographyService.modifyGeography(id, data);

  dispatch({
    type: MODIFY_GEOGRAPHY,
    payload: geography,
  });

  return geography;
};

/**
 * @param {number} id Geography Id
 * @returns {Function}
 */
export const deleteGeography = (id) => async (dispatch) => {
  const geography = await GeographyService.deleteGeography(id);

  dispatch({
    type: DELETE_GEOGRAPHY,
    payload: geography,
  });

  return geography;
};
