import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ModelIdeas from './ModelIdeas';
import { match, history, getRateOptions } from 'common';

class ModelPortfolioEditor extends Component {
  static propTypes = {
    match: match.isRequired,
    history: history.isRequired,
    companiesList: PropTypes.array.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    saveModelPortfolio: PropTypes.func.isRequired,
    modifyModelPortfolio: PropTypes.func.isRequired,
    getModelPortfolio: PropTypes.func.isRequired,
    hiddenFields: PropTypes.array,
    columnNames: PropTypes.array,
    showShortIdeas: PropTypes.bool,
    basePath: PropTypes.string,
    viewLink: PropTypes.string.isRequired,
    systemSettings: PropTypes.object.isRequired,
  };

  edit = !!this.props.match.params.id;
  copied = this.isCopied();
  state = {
    modelPortfolio: {},
    loading: true,
    rateOptions: [],
  };

  /**
   * @returns {boolean} is create new model portfolio by latest
   * */
  isCopied() {
    return !!this.props.match.params.id && this.props.match.path.includes('new');
  }

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    this.props.showLoader();
    this.setRateOptions();
    if (this.edit) {
      const [modelPortfolio] = await Promise.all([
        this.props.getModelPortfolio(this.props.match.params.id),
      ]);
      this.setState({ modelPortfolio });
    }

    await this.props.getCompanies();
    this.setState({ loading: false });
    this.props.hideLoader();
  }

  /**
   * @description Set rate options.
   */
  setRateOptions() {
    const rateOptions = getRateOptions(this.props.systemSettings.model_portfolio_max_number);
    this.setState({ rateOptions });
  }

  /**
   * @returns {JSX.Element} Model Portfolio component with tabs.
   */
  render() {
    return (
      <div className="model-portfolio-content">
        <ModelIdeas
          showShortIdeas={this.props.showShortIdeas}
          companiesList={this.props.companiesList}
          showLoader={this.props.showLoader}
          hideLoader={this.props.hideLoader}
          saveModelPortfolio={this.props.saveModelPortfolio}
          modifyModelPortfolio={this.props.modifyModelPortfolio}
          modelPortfolio={this.state.modelPortfolio}
          edit={this.edit}
          basePath={this.props.basePath}
          copied={this.copied}
          hiddenFields={this.props.hiddenFields}
          match={this.props.match}
          rateOptions={this.state.rateOptions}
          viewLink={
            this.props.match.params.id
              ? `${this.props.viewLink}${this.props.match.params.id}`
              : null
          }
          history={this.props.history}
          columnNames={this.props.columnNames}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    systemSettings: app.systemSettings.all,
  };
};

export default connect(mapStateToProps)(ModelPortfolioEditor);
