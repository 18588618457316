import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './InformationsList.scss';
import { Col, Row, Grid } from 'react-bootstrap';
import { VideoModal, ContactModal } from 'modules/shared';
import Env from 'env';
import classNames from 'classnames';
import { location } from 'common';
import chunk from 'lodash/chunk';
import { FormattedMessage } from 'react-intl';

class InformationsList extends React.Component {
  static componentType = 'informations-list';
  static propTypes = {
    data: PropTypes.object.isRequired,
    location,
  };

  state = {
    show: false,
    modalData: '',
  };

  /**
   * @returns {number} col value of layout
   */
  getColValue() {
    return Math.max(Env.BOOTSTRAP_MAX_WIDTH / this.props.data.informationsList.length, 3);
  }

  /**
   * @description Hides the modal.
   */
  handleClose = () => {
    this.setState({ show: false });
  };

  /**
   * @param {Object} video to show on modal
   *
   * @description Shows the modal.
   */
  handleShow = (video) => {
    this.setState({ show: true, modalData: video });
  };

  /**
   * @param {Array} informationList information list max 4
   *
   * @returns {JSX.Element} big screen information.
   **/
  getBigScreenInformationContent = (informationList) => (
    <>
      <Col sm={12} className="p-0">
        {informationList?.map((information, index) => {
          return (
            <Col
              className={classNames('d-flex', {
                'first-information-tile': index === 0,
              })}
              key={index}
              md={this.getColValue()}>
              {information.type.icon ? (
                <Col sm={12} className="d-flex justify-center align-baseline">
                  <i className={classNames('type-icon', information.type.icon)} />
                </Col>
              ) : null}
            </Col>
          );
        })}
      </Col>
      <Col sm={12} className="p-0">
        {informationList?.map((information, index) => {
          return (
            <Col
              className={classNames('d-flex', {
                'first-information-tile': index === 0,
              })}
              key={index}
              md={this.getColValue()}>
              {information.type.name ? (
                <Col sm={12}>
                  <h5 className="type-name">{information.type.name}</h5>
                </Col>
              ) : null}
            </Col>
          );
        })}
      </Col>
      <Col sm={12} className="p-0">
        {informationList?.map((information, index) => {
          return (
            <Col
              className={classNames('d-flex', {
                'first-information-tile': index === 0,
              })}
              key={index}
              md={this.getColValue()}>
              {information.sectionTitle ? (
                <Col sm={12}>
                  <h4 className="type-name">{information.sectionTitle}</h4>
                </Col>
              ) : null}
            </Col>
          );
        })}
      </Col>
      <Col sm={12} className="p-0">
        {informationList?.map((information, index) => {
          return (
            <Col
              className={classNames('d-flex', {
                'first-information-tile': index === 0,
              })}
              key={index}
              md={this.getColValue()}>
              <Col sm={12}>
                <h5 className="type-description">{information.description}</h5>
              </Col>
            </Col>
          );
        })}
      </Col>
      <Col sm={12} className="p-0">
        {informationList?.map((information, index) => {
          return (
            <Col
              className={classNames('d-flex flex-wrap', {
                'first-information-tile': index === 0,
              })}
              key={index}
              md={this.getColValue()}>
              {informationList[index].files?.map((file, fileIndex) => (
                <Col key={file.link} sm={12}>
                  <ContactModal
                    information={<FormattedMessage id="MAIN.CONTACT_MODAL.INFORMATION" />}
                    containerProtectWithContactModal={this.props.data.protectWithContactModal}
                    protectWithContactModal={file.protectWithContactModal}>
                    {information.type.name !== 'Video' && (
                      <a
                        className="type-file text-left"
                        href={Env.S3_URL + file.link}
                        target="_blank"
                        rel="noopener noreferrer">
                        <i className={classNames('type-file-icon', information.type.icon)} />
                        <div className="type-file-title">{file.title}</div>
                      </a>
                    )}
                    {information.type.name === 'Video' && (
                      <div className="type-file text-left" onClick={() => this.handleShow(file)}>
                        <i className={classNames('type-file-icon', information.type.icon)} />
                        <div className="type-file-title">{file.title}</div>
                      </div>
                    )}
                  </ContactModal>
                </Col>
              ))}
            </Col>
          );
        })}
      </Col>
    </>
  );

  /**
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    const bigScreenRows = chunk(this.props.data.informationsList, 4);

    return (
      <div
        className={classNames('informations', {
          'less-margin': this.props.data.title,
          edge: this.props.location && this.props.location.pathname.includes('edge'),
        })}>
        <VideoModal
          backdropClassName="analyst"
          handleClose={this.handleClose}
          show={this.state.show}
          video={this.state.modalData}
        />
        <Grid>
          <Row>
            <div className="d-flex flex-wrap">
              {this.props.data.title ? (
                <Col sm={12} className="mb-3">
                  <h2 className="title">{this.props.data.title}</h2>
                </Col>
              ) : null}

              <div className="big-screen-information-items">
                {bigScreenRows &&
                  bigScreenRows.map((item, index) => (
                    <Col md={12} className="information flex flex-wrap p-0" key={index}>
                      {this.getBigScreenInformationContent(item)}
                    </Col>
                  ))}
              </div>
              <div className="low-screen-information-items flex-wrap">
                {this.props.data.informationsList?.map((information, index) => {
                  return (
                    <Col
                      className={classNames('d-flex', {
                        'first-information-tile': index === 0,
                      })}
                      key={index}
                      sm={6}
                      xs={12}>
                      <div className="information">
                        {information.type.icon ? (
                          <Col sm={12} className="d-flex justify-center align-baseline">
                            <i className={classNames('type-icon', information.type.icon)} />
                          </Col>
                        ) : null}
                        {information.type.name ? (
                          <Col sm={12}>
                            <h5 className="type-name">{information.type.name}</h5>
                          </Col>
                        ) : null}
                        {information.sectionTitle ? (
                          <Col sm={12}>
                            <h4 className="type-name">{information.sectionTitle}</h4>
                          </Col>
                        ) : null}
                        <Col sm={12}>
                          <h5 className="type-description">{information.description}</h5>
                        </Col>
                        {this.props.data.informationsList[index].files?.map((file) => {
                          return (
                            <Col key={file.link} sm={12}>
                              <ContactModal
                                information={
                                  <FormattedMessage id="MAIN.CONTACT_MODAL.INFORMATION" />
                                }
                                containerProtectWithContactModal={
                                  this.props.data.protectWithContactModal
                                }
                                protectWithContactModal={file.protectWithContactModal}>
                                {information.type.name !== 'Video' && (
                                  <a
                                    className="type-file text-left"
                                    href={Env.S3_URL + file.link}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i
                                      className={classNames(
                                        'type-file-icon',
                                        information.type.icon
                                      )}
                                    />
                                    <div className="type-file-title">{file.title}</div>
                                  </a>
                                )}
                                {information.type.name === 'Video' && (
                                  <div
                                    className="type-file text-left"
                                    onClick={() => this.handleShow(file)}>
                                    <i
                                      className={classNames(
                                        'type-file-icon',
                                        information.type.icon
                                      )}
                                    />
                                    <div className="type-file-title">{file.title}</div>
                                  </div>
                                )}
                              </ContactModal>
                            </Col>
                          );
                        })}
                      </div>
                    </Col>
                  );
                })}
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(InformationsList);
