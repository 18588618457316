import { CompanyStatusRaw } from './';

/**
 * @description Creates an array sort function.
 *              Usage:
 *               // Creates the array sort function with the default settings.
 *               // The array will be sort by position ascending order;
 *               const sortByPosition = sortBy();
 *
 *               // ****
 *               arrayToSort.sort(sortByPosition);
 *
 * @param {Object} options Sorting options.
 *
 * @param {string} by Object property from the item of the array to sort.
 * @param {boolean} reverse Direction of sorting.
 *
 * @returns {Function} Array sort function.
 */
export default ({ by = 'position', reverse = false } = {}) => (a, b) => {
  const sign = reverse ? -1 : 1;

  return a[by] > b[by] ? sign * 1 : b[by] > a[by] ? sign * -1 : 0;
};

const statusOrder = [CompanyStatusRaw.active, CompanyStatusRaw.inactive];

/**
 * @param {Object} a Obect with status Property
 * @param {Object} b Obect with status Property
 *
 * @returns {number}
 */
export const sortByStatus = (a, b) => {
  return statusOrder.indexOf(a.status) > statusOrder.indexOf(b.status);
};
