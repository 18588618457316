import React, { useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal as BsModal, Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Modal, TextInputField, Button, ModalHeader, ModalGrid, SvgIcon } from 'modules/shared';
import * as managersService from 'modules/shared/services/managers';

import styles from './style.scss';

interface AccountManagerModalProps {
  iconVariant: boolean;
}

const AccountManagerModal = ({ iconVariant = false }: AccountManagerModalProps) => {
  const [open, setOpen] = useState(false);
  const formikRef = useRef(null);
  const intl = useIntl();

  const formatMessage = (id: string) => intl.formatMessage({ id });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    message: '',
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(
      <FormattedMessage id="MAIN.ACCOUNT_MANAGER.MESSAGE_VALIDATION_ERROR" />
    ),
  });

  const submitHandler = async ({ message }) => {
    try {
      await managersService.sendMessage({ message });

      toast.success(<FormattedMessage id="MAIN.ACCOUNT_MANAGER.SUCCESS" />);
      handleClose();
    } catch (e) {
      toast.error(<FormattedMessage id="MAIN.ACCOUNT_MANAGER.ERROR" />);
    }
  };

  const handleOnSubmit = () => {
    formikRef.current.submitForm();
  };

  return (
    <>
      <Modal
        dialogClassName="account-manager-modal"
        show={open}
        bsSize="large"
        onHide={handleClose}>
        <ModalHeader close={handleClose} />
        <BsModal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={validationSchema}
            innerRef={formikRef}>
            {({ isSubmitting }) => (
              <Form>
                <ModalGrid>
                  <Col className="contact-modal-information">
                    <FormattedMessage id="MAIN.ACCOUNT_MANAGER.DESCRIPTION" />
                  </Col>
                  <Col>
                    <Field
                      component={TextInputField}
                      componentClass="textarea"
                      name="message"
                      noResize
                      placeholder={formatMessage('MAIN.ACCOUNT_MANAGER.MESSAGE')}
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={handleOnSubmit}
                      loading={isSubmitting}
                      className="contact-modal-submit"
                      textColor="dark"
                      thin>
                      <FormattedMessage id="MAIN.SUBMIT" />
                    </Button>
                  </Col>
                </ModalGrid>
              </Form>
            )}
          </Formik>
        </BsModal.Body>
      </Modal>

      {iconVariant ? (
        <SvgIcon name="address-book" onClick={handleOpen} />
      ) : (
        <Button sm className="white" onClick={handleOpen}>
          <FormattedMessage id="MAIN.ACCOUNT_MANAGER.CONTACT_MANAGER" />
        </Button>
      )}
    </>
  );
};

export default withStyles(styles)(AccountManagerModal);
