import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CompaniesEditor } from 'modules/admin/shared';

import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getEmployees } from 'modules/main/actions/users';
import { getSectors, getIndustrySectors } from 'modules/analyst/actions/sectors';
import {
  getCompany,
  saveCompany,
  modifyCompany,
  refreshPercent,
  fetchCompanyCountries,
} from 'modules/analyst/actions/companies';
import { match, history } from 'common';

class AnalystCompaniesEditor extends Component {
  static propTypes = {
    employees: PropTypes.array.isRequired,
    sectors: PropTypes.array.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    getEmployees: PropTypes.func.isRequired,
    getSectors: PropTypes.func.isRequired,
    refreshPercent: PropTypes.func.isRequired,
    saveCompany: PropTypes.func.isRequired,
    modifyCompany: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    match: match.isRequired,
    fetchCompanyCountries: PropTypes.func.isRequired,
    history: history.isRequired,
    getIndustrySectors: PropTypes.func.isRequired,
    sector_industries: PropTypes.array.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <CompaniesEditor
        employees={this.props.employees}
        sectors={this.props.sectors}
        showLoader={this.props.showLoader}
        hideLoader={this.props.hideLoader}
        getCompany={this.props.getCompany}
        getEmployees={this.props.getEmployees}
        getSectors={this.props.getSectors}
        refreshPercent={this.props.refreshPercent}
        saveCompany={this.props.saveCompany}
        modifyCompany={this.props.modifyCompany}
        basePath={this.props.basePath}
        match={this.props.match}
        history={this.props.history}
        fetchCountries={this.props.fetchCompanyCountries}
        sector_industries={this.props.sector_industries}
        getIndustrySectors={this.props.getIndustrySectors}
        hiddenFields={['idea_source_id', 'recommendation', 'symbol']}
      />
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app, analyst }) => {
  return {
    employees: app.employees,
    sectors: analyst.sectors,
    sector_industries: analyst.sector_industries,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getCompany,
      saveCompany,
      fetchCompanyCountries,
      modifyCompany,
      getEmployees,
      getSectors,
      getIndustrySectors,
      refreshPercent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalystCompaniesEditor);
