import * as SurveysService from '../services/surveys';
import fileDownload from 'js-file-download';
import { formatDate } from 'common';

export const GET_ALL_SURVEYS = 'GET_ALL_SURVEYS';
export const GET_SURVEY = 'GET_SURVEY';
export const SAVE_SURVEY = 'SAVE_SURVEY';
export const EDIT_SURVEY = 'EDIT_SURVEY';
export const EXPORT_SURVEY = 'EXPORT_SURVEY';
export const GET_LATEST_SURVEY = 'GET_LATEST_SURVEY';
export const TAKE_SURVEY = 'TAKE_SURVEY';
export const GET_SURVEY_ANALYTICS = 'TAKE_SURVEY';

/**
 * @param {Object} options of get
 * @description Gets all surveys.
 * @returns {function}
 */
export const getSurveys = (options) => async (dispatch) => {
  const surveys = await SurveysService.getSurveys(options);

  dispatch({
    type: GET_ALL_SURVEYS,
    payload: surveys,
  });

  return surveys;
};

/**
 * @description Gets the survey for the ID:
 * @param {number} id survey ID.
 * @returns {Function}
 */
export const getSurvey = (id) => async (dispatch) => {
  const survey = await SurveysService.getSurvey(id);

  dispatch({ type: GET_SURVEY, payload: survey });

  return survey;
};

/**
 * @param {Object} data survey data.
 * @returns {Function}
 */
export const saveSurvey = (data) => async (dispatch) => {
  const survey = await SurveysService.saveSurvey(data);

  dispatch({
    type: SAVE_SURVEY,
    payload: survey,
  });

  return survey;
};

/**
 * @param {Object} data survey data.
 * @returns {Function}
 */
export const editSurvey = (data) => async (dispatch) => {
  const survey = await SurveysService.editSurvey(data);

  dispatch({
    type: EDIT_SURVEY,
    payload: survey,
  });

  return survey;
};

/**
 * @description Gets the latest survey for the ID:
 * @returns {Function}
 */
export const getLatestSurvey = () => async (dispatch) => {
  const survey = await SurveysService.getLatestSurvey();

  dispatch({ type: GET_LATEST_SURVEY, payload: survey });

  return survey;
};

/**
 * @param {number} id survey ID.
 * @description Gets the latest survey for the ID:
 * @returns {Function}
 */
export const exportSurvey = (id) => async (dispatch) => {
  const survey = await SurveysService.exportSurvey(id);

  dispatch({ type: EXPORT_SURVEY, payload: survey });

  fileDownload(survey, `survey-analytics-${formatDate(new Date(), { timeFormat: true })}.csv`);

  return survey;
};

/**
 * @description Gets the latest survey for the ID:
 * @param {Object} data survey data.
 * @returns {Function}
 */
export const takeSurvey = (data) => async (dispatch) => {
  const survey = await SurveysService.takeSurvey(data);

  dispatch({ type: TAKE_SURVEY, payload: survey });

  return survey;
};

/**
 * @param {number} id survey ID.
 * @description get survey analytics
 * @returns {Function}
 */
export const getSurveyAnalytics = (id) => async (dispatch) => {
  const surveyAnalytics = await SurveysService.getSurveyAnalytics(id);

  dispatch({
    type: GET_SURVEY_ANALYTICS,
    payload: surveyAnalytics,
  });

  return surveyAnalytics;
};
