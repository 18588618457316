import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, ActionIcon } from 'modules/admin/shared';
import classNames from 'classnames';

class Delete extends Component {
  static propTypes = {
    submitText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    cancelText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    className: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    submitText: <FormattedMessage id="MAIN.DELETE" />,
    cancelText: <FormattedMessage id="MAIN.CANCEL" />,
    title: <FormattedMessage id="MAIN.DELETE" />,
  };

  state = {
    show: false,
  };

  /**
   * @description Set unmounting flag to prevent unnecessary setState call.
   */
  componentWillUnmount() {
    this.unmounting = true;
  }

  /**
   * @description Hides the modal.
   */
  handleClose = () => {
    this.setState({ show: false });
  };

  /**
   * @description Shows the modal.
   */
  handleShow = () => {
    this.setState({ show: true });
  };

  /**
   * @description Invokes the cancel callback if exists and closes the modal.
   *
   * @param {Event} e Cancel event.
   */
  onCancel = async (e) => {
    if (this.props.onCancel) {
      await this.props.onCancel(e);
    }

    this.handleClose();
  };

  /**
   * @description Invokes the submit callback if exists and closes the modal.
   *
   * @param {Event} e Submit event.
   */
  onSubmit = async (e) => {
    if (this.props.onSubmit) {
      await this.props.onSubmit(e);
    }

    if (!this.unmounting) {
      this.handleClose();
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { submitText, cancelText, title, className } = this.props;
    return (
      <div className={classNames('text-right', className)}>
        <ActionIcon className="fa fa-trash" onClick={this.handleShow} />
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          onSubmit={this.onSubmit}
          onSecondary={this.onCancel}
          data={{
            modalSubmitText: submitText,
            modalSecondaryText: cancelText,
            modalHeadText: title,
          }}>
          Are you sure you want to delete this item?
        </Modal>
      </div>
    );
  }
}

export default Delete;
