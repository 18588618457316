import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { AddPlaceholder, Delete, FieldGroup, FileUpload, DragHandle } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './Downloads.scss';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableBlock = SortableElement(({ file, fileIndex, onChange, removeFile, onDropImage }) => (
  <Col lg={4} md={4} sm={6} xs={12} className="file-item">
    <Row className="m-0 d-flex align-center">
      <Col sm={6}>
        <DragHandle />
      </Col>
      <Col sm={6}>
        <Delete onSubmit={() => removeFile(fileIndex)} />
      </Col>
    </Row>
    {/*image upload*/}
    <Col xs={12}>
      <FormGroup className="image-preview-container">
        <ControlLabel>
          <FormattedMessage id="ADMIN.POSTS.FILE" />
        </ControlLabel>
        <FileUpload
          className="file-type"
          accept="application/pdf, .mp3, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
          name="file"
          file={file}
          onDropFile={(files) => {
            onDropImage(files, fileIndex, 'downloads');
          }}
        />
      </FormGroup>
    </Col>
    <Col xs={12}>
      <FieldGroup
        name="title"
        placeholderId="ADMIN.NAME"
        label={<FormattedMessage id="ADMIN.NAME" />}
        value={file.custom_properties.title}
        onChange={(e) => onChange(e, fileIndex)}
      />
    </Col>
    <Col xs={12}>
      <FieldGroup
        componentClass="textarea"
        placeholderId="ADMIN.DESCRIPTION"
        label={<FormattedMessage id="ADMIN.DESCRIPTION" />}
        name="description"
        value={file.custom_properties.description}
        onChange={(e) => onChange(e, fileIndex)}
      />
    </Col>
  </Col>
));

const SortableBlockContainer = SortableContainer(
  ({ files, onChange, removeFile, onDropImage, addFile, maxFiles }) => {
    return (
      <Row className="downloads">
        {files &&
          files.map((file, index) => {
            return (
              <SortableBlock
                key={index}
                index={index}
                fileIndex={index}
                file={file}
                onChange={onChange}
                removeFile={removeFile}
                onDropImage={onDropImage}
              />
            );
          })}
        {maxFiles && files && files.length >= maxFiles ? null : (
          <Col lg={4} md={4} sm={6} xs={12}>
            <AddPlaceholder onClick={addFile} />
          </Col>
        )}
      </Row>
    );
  }
);

class Downloads extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
    onDropImage: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    files: PropTypes.array,
    errors: PropTypes.object,
    maxFiles: PropTypes.number,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <SortableBlockContainer
        files={this.props.files}
        onChange={this.props.onChange}
        removeFile={this.props.removeFile}
        onDropImage={this.props.onDropImage}
        addFile={this.props.addFile}
        onSortEnd={this.props.onDragEnd}
        useWindowAsScrollContainer
        useDragHandle
        axis="xy"
        delay={100}
        maxFiles={this.props.maxFiles}
      />
    );
  }
}

export default withStyles(style)(Downloads);
