import React, { Component } from 'react';
import { withSSR, BlogPostDetail } from 'modules/shared';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { getPress } from 'modules/main/actions/press';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './PressDetail.scss';
// import { MainHeader } from 'modules/main';
import { Footer, Content } from 'modules/shared';
import $ from 'jquery';
import { connect } from 'react-redux';
import Env from 'env';
import { PublicHeader } from 'modules/analyst/modules/shared';
import { analystFooterLinks } from 'common';

class PressDetail extends Component {
  static propTypes = {
    press: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  /**
   * @param {Object} match url params
   * @param {Function} dispatch Action dispatcher
   * @returns {Promise}
   */
  static async getInitialData({ dispatch, match }) {
    return dispatch(getPress(match.params.id));
  }

  /**
   * @description scroll top.
   */
  componentDidMount() {
    $('html,body').animate({ scrollTop: 0 }, 0);
  }
  /**
   * @returns {JSX.Element} - Our People component
   */
  render() {
    return (
      <div className="analyst-home press white-background">
        <FormattedMessage id="MAIN.HEADER.PRESS">
          {(title) => (
            <Helmet defer={false}>
              {this.props.press.title ? (
                <title itemProp="name">{`${this.props.press.title} - ${title}`}</title>
              ) : (
                <title itemProp="name">{title}</title>
              )}
              ;
              <link
                rel="canonical"
                href={`${Env.SITE_URL}press/${this.props.press.id}/${this.props.press.slug}`}
                itemProp="url"
              />
              <meta name="title" content={this.props.press.meta_title} />
              <meta name="description" content={this.props.press.meta_description} />
              <meta name="keywords" content={this.props.press.keywords} />
            </Helmet>
          )}
        </FormattedMessage>

        {/*<MainHeader />*/}
        <PublicHeader />

        <Content>
          <BlogPostDetail
            data={this.props.press}
            isLoading={this.props.isLoading}
            listPageLabel={<FormattedMessage id="MAIN.HEADER.PRESS" />}
            listPageLink="/press"
          />
        </Content>

        {/*<Footer header={{ toTop: true }} />*/}
        <Footer
          className="analyst analyst-link-hover"
          header={{
            toTop: true,
            icons: true,
            logoAnalyst: true,
            links: analystFooterLinks,
          }}
        />
      </div>
    );
  }
}

/**
 * @param {Object} state Core state object.
 *
 * @returns {Object} Injected props from state.
 */
const mapStateToProps = (state) => {
  return {
    press: state.app.press,
  };
};

export default connect(mapStateToProps)(withSSR(withStyles(style)(PressDetail)));
