/**
 * @param {string} title string
 * @returns {string} name of file
 * */
export const getStringToUrl = (title) => {
  return title
    ? title
        .toLowerCase()
        .replace(/ /gi, () => '-')
        .replace(/:|'|"|%|\|<|>|$|@|&|#|_|~|{|}|\\+|[/]|=|[(]|[)]|,|;|!|/gi, () => '')
    : '';
};
