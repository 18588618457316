import { connect } from 'react-redux';
import AnalystAppHeader from './AnalystAppHeader';

/**
 * @param {Object} state Application State
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = (state) => {
  return {
    profile: state.app.profile || {},
  };
};

export default connect(mapStateToProps)(AnalystAppHeader);
