import React, { Component } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { EditorTitle } from 'modules/admin/shared';
import { Grid, Row, Col } from 'react-bootstrap';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  getUserHeader,
  getUserDownloads,
  getUserLogins,
  getUserViews,
  resetCurrentUser,
} from 'modules/admin/main/actions/analytics';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { formatDate, match } from 'common';

class UserAnalyticsDetail extends Component {
  static propTypes = {
    getUserHeader: PropTypes.func.isRequired,
    getUserDownloads: PropTypes.func.isRequired,
    getUserLogins: PropTypes.func.isRequired,
    getUserViews: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    resetCurrentUser: PropTypes.func.isRequired,
    detail: PropTypes.object.isRequired,
    match,
  };

  /**
   * @description Gets the User analytics data.
   */
  async componentDidMount() {
    const { id } = this.props.match.params;
    this.props.showLoader();
    await Promise.all([
      this.props.getUserHeader(id),
      this.props.getUserDownloads(id),
      this.props.getUserLogins(id),
      this.props.getUserViews(id),
    ]);
    this.props.hideLoader();
  }

  /**
   * @description Resets current user data from store.
   */
  componentWillUnmount() {
    this.props.resetCurrentUser();
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { header = {}, views = [], downloads = [], logins = [] } = this.props.detail;

    return header.email ? (
      <div>
        <EditorTitle
          title={
            <FormattedMessage
              id="ADMIN.ANALYTICS.TITLE"
              values={{ name: header.name.trim() || header.email }}
            />
          }
        />
        <Grid fluid className="analytics-header-section">
          <Row className="header">
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.NAME" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.EMAIL" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.LOGIN_COUNT" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.DOWNLOADS" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.PAGE_VIEWS" />
              </div>
            </Col>
            <Col xs={2}>
              <div className="title">
                <FormattedMessage id="ADMIN.ANALYTICS.LAST_LOGIN" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <div className="value" title={header.name}>
                {header.name}
              </div>
            </Col>
            <Col xs={2}>
              <div className="value" title={header.email}>
                {header.email}
              </div>
            </Col>
            <Col xs={2}>
              <div className="value" title={header.logins}>
                {header.logins}
              </div>
            </Col>
            <Col xs={2}>
              <div className="value" title={header.downloads}>
                {header.downloads}
              </div>
            </Col>
            <Col xs={2}>
              <div className="value" title={header.views}>
                {header.views}
              </div>
            </Col>
            <Col xs={2}>
              <div
                className="value"
                title={formatDate(header.last_login, {
                  timeFormat: true,
                })}>
                {formatDate(header.last_login, { timeFormat: true })}
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <div className="navs">
                <NavLink to={`${this.props.match.url}/views`} className="tab">
                  <FormattedMessage id="ADMIN.ANALYTICS.PAGE_VIEWS" />
                </NavLink>
                <NavLink to={`${this.props.match.url}/downloads`} className="tab">
                  <FormattedMessage id="ADMIN.ANALYTICS.DOWNLOADS" />
                </NavLink>
                <NavLink to={`${this.props.match.url}/logins`} className="tab">
                  <FormattedMessage id="ADMIN.ANALYTICS.LOGINS" />
                </NavLink>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Switch>
                <Redirect
                  exact
                  from={`${this.props.match.path}/`}
                  to={`${this.props.match.path}/views`}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/views`}
                  render={() => (
                    <ReactTable
                      data={views}
                      resizable={false}
                      minRows={3}
                      showPagination={views.length > 20}
                      defaultPageSize={20}
                      showPageSizeOptions={false}
                      className="-striped -highlight"
                      columns={[
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.TITLE" />,
                          accessor: 'post_title',
                          sortable: false,
                          Cell: ({ original }) => (
                            <Link
                              to={`/admin/analytics/article-analytics/${original.post_type}/article/${original.post_id}`}>
                              {original.post_title}
                            </Link>
                          ),
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.SOURCE" />,
                          accessor: 'mobile',
                          sortable: false,
                          Cell: ({ original }) => {
                            return original.mobile ? (
                              <FormattedMessage id="ADMIN.ANALYTICS.MOBILE" />
                            ) : (
                              <FormattedMessage id="ADMIN.ANALYTICS.WEB" />
                            );
                          },
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.DATE_TIME" />,
                          id: 'created_at',
                          sortable: false,
                          Cell: ({ original }) => {
                            return formatDate(original.created_at, { timeFormat: true });
                          },
                        },
                      ]}
                    />
                  )}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/downloads`}
                  render={() => (
                    <ReactTable
                      data={downloads}
                      resizable={false}
                      minRows={3}
                      showPagination={downloads.length > 20}
                      defaultPageSize={20}
                      showPageSizeOptions={false}
                      className="-striped -highlight"
                      columns={[
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.FILE_NAME" />,
                          accessor: 'filename',
                          sortable: false,
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.SOURCE" />,
                          accessor: 'mobile',
                          sortable: false,
                          Cell: ({ original }) => {
                            return original.mobile ? (
                              <FormattedMessage id="ADMIN.ANALYTICS.MOBILE" />
                            ) : (
                              <FormattedMessage id="ADMIN.ANALYTICS.WEB" />
                            );
                          },
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.DATE_TIME" />,
                          id: 'created_at',
                          sortable: false,
                          Cell: ({ original }) => {
                            return formatDate(original.created_at, { timeFormat: true });
                          },
                        },
                      ]}
                    />
                  )}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/logins`}
                  render={() => (
                    <ReactTable
                      data={logins}
                      resizable={false}
                      minRows={3}
                      showPagination={logins.length > 20}
                      defaultPageSize={20}
                      showPageSizeOptions={false}
                      className="-striped -highlight"
                      columns={[
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.USER" />,
                          accessor: 'user_id',
                          sortable: false,
                          Cell: () => {
                            return header.email;
                          },
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.SOURCE" />,
                          accessor: 'mobile',
                          sortable: false,
                          Cell: ({ original }) => {
                            return original.mobile ? (
                              <FormattedMessage id="ADMIN.ANALYTICS.MOBILE" />
                            ) : (
                              <FormattedMessage id="ADMIN.ANALYTICS.WEB" />
                            );
                          },
                        },
                        {
                          Header: <FormattedMessage id="ADMIN.ANALYTICS.DATE_TIME" />,
                          id: 'created_at',
                          sortable: false,
                          Cell: ({ original }) => {
                            return formatDate(original.created_at, { timeFormat: true });
                          },
                        },
                      ]}
                    />
                  )}
                />
              </Switch>
            </Col>
          </Row>
        </Grid>
      </div>
    ) : null;
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ admin }) => {
  return {
    detail: admin.analytics.currentUser || {},
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserHeader,
      getUserDownloads,
      getUserLogins,
      getUserViews,
      resetCurrentUser,
      showLoader,
      hideLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserAnalyticsDetail);
