import request, { Methods } from 'modules/shared/util/request';

/**
 * @description Gets the bookmarks for the given user.
 * @returns {Promise<*>}
 */
export const getAllBookmarks = async () => {
  return await request({
    resource: 'auth/me/bookmarks',
  });
};

/**
 * @description Gets the Edge bookmarks for the given user.
 * @returns {Promise<*>}
 */
export const getEdgeBookmarks = async () => {
  return await request({
    resource: 'auth/me/edge_bookmarks',
  });
};

/**
 * @description Gets the Edge bookmarks for the given user.
 * @returns {Promise<*>}
 */
export const getEdgePlusBookmarks = async () => {
  return await request({
    resource: 'auth/me/edge_plus_bookmarks',
  });
};

/**
 * @param {number | string} id Bookmark ID.
 * @returns {Promise<*>}
 */
export const deleteBookmark = async (id) => {
  return await request({
    resource: `bookmarks/${id}`,
    method: Methods.DELETE,
  });
};
