import 'babel-polyfill';

import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import StyleContext from 'isomorphic-style-loader/StyleContext';

import App from '../modules';
import routes from '../routes';
import configureStore from '../config/store';
import translation from '../config/translation';
import addDataToRoutes from 'common/addDataToRoutes';

const initialData = window.__INITIAL_DATA__ || {};
const preloadedState = window.__PRELOADED_STATE__ || {};

delete window.__PRELOADED_STATE__;
delete window.__INITIAL_DATA__;

const store = configureStore(preloadedState);
const { locale, messages } = translation();

const context = {
  insertCss: (...styles) => {
    const removeCss = styles.map((style) => style._insertCss());
    return () => removeCss.forEach((dispose) => dispose());
  },
};

const app = (
  <Provider store={store}>
    <IntlProvider locale={locale} messages={messages} textComponent="span">
      <BrowserRouter>
        <StyleContext.Provider value={context}>
          <App routes={addDataToRoutes(routes, initialData)} initialData={initialData} />
        </StyleContext.Provider>
      </BrowserRouter>
    </IntlProvider>
  </Provider>
);

hydrate(app, document.getElementById('root'));

if (module.hot) {
  // eslint-disable-next-line no-console
  console.info('✅  Client-side HMR Enabled!');
  module.hot.accept('../modules', () => {
    // eslint-disable-next-line no-console
    console.log('🔁  HMR Reloading client...');
    hydrate(app, document.getElementById('root'));
  });
}
