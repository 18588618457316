import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @param {string} className hr additional style
 * @param {boolean} thickAnalystBorder like question style
 * @constructor
 */
const PostDetailDivider = ({ thickAnalystBorder, className }) => (
  <hr
    className={classNames(className, {
      'thick-analyst-border-hr': thickAnalystBorder,
    })}
  />
);

PostDetailDivider.propTypes = {
  thickAnalystBorder: PropTypes.bool,
  className: PropTypes.string,
};

export default PostDetailDivider;
