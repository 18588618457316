import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { Status } from 'modules/shared/util/http';
import { logout } from 'modules/main/actions/users';
import { hideLoader } from 'modules/main/actions/loader';

/**
 *@param {Function} dispatch dispatch method
 *
 *@returns {Function} next method for middleware
 * */
const errorMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.error && action.meta && action.meta.throwError) {
    throw {
      ...action.error,
      ignoreToast: action.meta.ignoreToast === undefined ? true : action.meta.ignoreToast,
      throwError: action.meta.throwError,
    };
  }

  /**
   *@param {array} titles array
   *
   *@returns {React.element} messages
   * */
  const getErrorMessages = (titles) => {
    return (
      <div>
        {titles.map((errorTitle, index) => {
          return <FormattedMessage key={index} id={`ERRORS.${errorTitle}`} />;
        })}
      </div>
    );
  };

  return Promise.resolve(next(action)).catch((err) => {
    if ((action.meta && action.meta.hideErrorNotification) || err.ignoreToast) {
      dispatch(hideLoader());
      throw err;
    }

    const errorId =
      err.response &&
      err.response.data &&
      ((typeof err.response.data.data === 'string' && err.response.data.data) ||
        (typeof err.response.data.error && err.response.data.error));

    let errorTitles = null;
    if (
      err.response &&
      err.response.data &&
      err.response.data.title &&
      err.response.data.title.length > 0
    ) {
      errorTitles = getErrorMessages(err.response.data.title);
      toast.error(errorTitles);
    }

    if (err.response && err.response.status === Status.BAD_REQUEST) {
      throw err;
    }

    if (!errorTitles) {
      const errorMessage = err.response ? (
        errorId ? (
          <FormattedMessage id={errorId} />
        ) : (
          err.response.statusText
        )
      ) : (
        err.message
      );
      toast.error(errorMessage);
    }

    if (err.response) {
      if (
        err.response.status === Status.UNAUTHORIZIED ||
        (err.response && err.response.data && err.response.data.error === 'ERRORS.TOKEN_INVALID')
      ) {
        dispatch(logout(false));
        return;
      }
      dispatch(hideLoader());
      throw err;
    } else {
      dispatch(hideLoader());
      throw err;
    }
  });
};

export default errorMiddleware;
