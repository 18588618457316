import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Svg } from 'modules/shared';

import styles from './SentimentTrackerLogo.scss';

/**
 * @param {string} textAlign horizontal text align
 * @param {boolean} verticalMargin show vertival margins
 * @param {boolean} noTopMargin top margin to be tero
 *
 * @returns {JSX.Element}
 */
const SentimentTrackerLogo = ({ textAlign = 'center', verticalMargin, noTopMargin, className }) => (
  <div
    className={classNames('sentiment-tracker-logo-container', className, textAlign, {
      'vertical-margin': verticalMargin,
      'no-top-margin': noTopMargin,
    })}>
    <Svg name="sentiment_tracker_logo" />
  </div>
);

SentimentTrackerLogo.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'center']),
  verticalMargin: PropTypes.bool,
  noTopMargin: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(SentimentTrackerLogo);
