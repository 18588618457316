import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PostsList } from 'modules/admin/shared';

import { getPostsPage, deletePost, exportPosts } from 'modules/analyst/actions/posts';
import { table } from 'modules/shared';
import { match } from 'common';

class AnalystPostsList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    exportPosts: PropTypes.func.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    basePath: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <PostsList
        match={this.props.match}
        fetchData={this.props.fetchData}
        onSearch={this.props.onSearch}
        deleteItem={this.props.deleteItem}
        data={this.props.data}
        pages={this.props.pages}
        loading={this.props.loading}
        search={this.props.search}
        basePath={this.props.basePath}
        hiddenColumns={['summary']}
        exportPosts={this.props.exportPosts}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDataWs: getPostsPage,
      deleteItemWs: deletePost,
      exportPosts,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(table({ nofilter: true, nopaginate: false })(AnalystPostsList));
