import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Env from 'env';

import styles from './MoreFeedbackText.scss';

class MoreFeedbackText extends Component {
  /**
   * @returns {JSX.Element}
   */
  render() {
    const { noBackground, text, small, className } = this.props;
    return (
      <div
        className={classNames('more-feedback-text mt-1', className, {
          'no-background': noBackground,
          'small-text': small,
        })}>
        {text ? (
          text
        ) : (
          <FormattedHTMLMessage id="MAIN.MORE_FEEDBACK" values={{ surveyLink: Env.SURVEY_LINK }} />
        )}
      </div>
    );
  }
}

MoreFeedbackText.propTypes = {
  noBackground: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.node,
  className: PropTypes.string,
};

export default withStyles(styles)(MoreFeedbackText);
