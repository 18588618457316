import React, { Component } from 'react';
import styles from './People.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col } from 'react-bootstrap';
import PeopleList from './PeopleList';
import PropTypes from 'prop-types';
import {
  DragHandle,
  AddPlaceholder,
  Delete,
  FieldGroup,
  getErrorState,
} from 'modules/admin/shared';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getEmployees } from 'modules/main/actions/users';
import { pagesFileUpload } from 'common';
import { pagesUploadFile } from 'modules/admin/main/actions/file-upload';

const SortableBlock = SortableElement(
  ({
    block,
    removeGroup,
    onChangeGroup,
    groupIndex,
    addPerson,
    removePerson,
    onDropFile,
    onChangePerson,
    onDragEnd,
    deleteFile,
  }) => (
    <div className="people-group">
      <DragHandle />
      <div className="content">
        <Row>
          <Col sm={10} className="people-group-header">
            <FieldGroup
              type="text"
              name="name"
              error={getErrorState(block.name)}
              placeholder="Group name"
              value={block.name}
              onChange={(event) => onChangeGroup(event, groupIndex)}
            />
          </Col>
          <Col sm={2} className="people-group-header">
            <Delete onSubmit={() => removeGroup(groupIndex)} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="each-people">
              <PeopleList
                type={`second-level-groupIndex:${groupIndex}`}
                groupIndex={groupIndex}
                people={block.people}
                onDragEnd={onDragEnd}
                deleteFile={(personIndex) => {
                  deleteFile(groupIndex, personIndex);
                }}
                onChangePerson={(event, index, type) => {
                  onChangePerson(event, groupIndex, index, type);
                }}
                addPerson={() => {
                  addPerson(groupIndex);
                }}
                removePerson={(index) => {
                  removePerson(groupIndex, index);
                }}
                onDropFile={(files, index) => {
                  onDropFile(files, groupIndex, index);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
);

const SortableBlockContainer = SortableContainer(
  ({
    groups,
    removeGroup,
    onChangeGroup,
    addPerson,
    removePerson,
    onDropFile,
    onChangePerson,
    onDragEnd,
    deleteFile,
    addGroup,
  }) => {
    return (
      <div className="people-group-content">
        {[
          ...groups.map((block, groupIndex) => (
            <SortableBlock
              collection={-1}
              index={groupIndex}
              key={groupIndex}
              groupIndex={groupIndex}
              block={block}
              removeGroup={removeGroup}
              onChangeGroup={onChangeGroup}
              addPerson={addPerson}
              removePerson={removePerson}
              onDropFile={onDropFile}
              deleteFile={deleteFile}
              onChangePerson={onChangePerson}
              onDragEnd={onDragEnd}
            />
          )),
          <AddPlaceholder className="people-group" key="add-group" onClick={addGroup} />,
        ]}
      </div>
    );
  }
);

class People extends Component {
  static componentType = 'people';
  static blockName = 'People';
  static icon = 'fa fa-users';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    getEmployees: PropTypes.func.isRequired,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
    pagesUploadFile: PropTypes.func.isRequired,
  };

  state = {
    groups: [People.getGroup()],
    valid: false,
  };

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.props.getEmployees();
    this.setState(this.props.data);
  }

  /**
   * @returns {Object} Empty person object.
   */
  static getPerson() {
    return {
      name: '',
      job_position: '',
      image: '',
      fileTitle: '',
      fileAlt: '',
      edge: false,
      analyst: false,
    };
  }

  /**
   * @returns {Object} Empty group object.
   */
  static getGroup() {
    return {
      name: '',
      people: [People.getPerson()],
    };
  }

  /**
   * @param {Array} list will reordered.
   * @param {number} startIndex is start index of dragged item.
   * @param {number} endIndex is end index of dragged item.
   * @param {Object} result is dragged item.
   *
   * @returns {Array} Transformed legend.
   */
  reorder = (list, startIndex, endIndex, result) => {
    let resultList;
    if (result.type.includes('second-level')) {
      const startgroupIndex = result.draggableId.indexOf('groupIndex:') + 'groupIndex:'.length;

      const groupIndex = result.draggableId.substring(startgroupIndex, startgroupIndex + 1);
      resultList = Array.from(list);

      const [removed] = resultList[groupIndex].people.splice(startIndex, 1);
      resultList[groupIndex].people.splice(endIndex, 0, removed);
    } else {
      resultList = Array.from(list);

      const [removed] = resultList.splice(startIndex, 1);
      resultList.splice(endIndex, 0, removed);
    }
    return resultList;
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex, collection }) => {
    if (collection === -1) {
      this.setState({
        groups: arrayMove(this.state.groups, oldIndex, newIndex),
      });
    } else {
      const groups = this.state.groups.map((data) => data);
      groups[collection].people = arrayMove(groups[collection].people, oldIndex, newIndex);
      this.setState({
        groups,
      });
    }
  };

  addGroup = () => {
    this.setState(
      {
        groups: [...this.state.groups, People.getGroup()],
      },
      () => {
        this.props.onChange({ groups: this.state.groups, valid: this.isValidPeople() });
      }
    );
  };

  removeGroup = (index) => {
    const groups = this.state.groups.map((data) => data);

    groups.splice(index, 1);

    this.setState(
      {
        groups,
      },
      () => {
        this.props.onChange({ groups: this.state.groups, valid: this.isValidPeople() });
      }
    );
  };

  onDropFile = async (files, groupIndex, peopleIndex) => {
    const people = this.state.groups[groupIndex].people.map((data) => data);

    if (files.length > 0) {
      const { path } = await pagesFileUpload(
        this.props.site,
        this.props.slug,
        files,
        this.props.pagesUploadFile
      );

      people[peopleIndex].image = path;
      this.setState({ people }, () => {
        this.props.onChange({ groups: this.state.groups, valid: this.isValidPeople() });
      });
    }
  };

  deleteFile = async (groupIndex, peopleIndex) => {
    const people = this.state.groups[groupIndex].people.map((data) => data);

    people[peopleIndex].image = null;
    this.setState({ people }, () => {
      this.props.onChange({ groups: this.state.groups, valid: this.isValidPeople() });
    });
  };

  onChange = (event, index) => {
    const groups = this.state.groups.map((data) => data);
    groups[index][event.target.name] = event.target.value;
    this.setState({ groups }, () => {
      this.props.onChange({ groups: this.state.groups, valid: this.isValidPeople() });
    });
  };

  onChangePerson = (event, groupIndex, peopleIndex, type) => {
    const people = this.state.groups[groupIndex].people.map((data) => data);
    people[peopleIndex][event.target.name] =
      type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({ people }, () => {
      this.props.onChange({ groups: this.state.groups, valid: this.isValidPeople() });
    });
  };

  addPerson = (groupIndex) => {
    const groups = this.state.groups.map((data) => data);

    groups[groupIndex].people.push(People.getPerson());

    this.setState(
      {
        groups,
      },
      () => {
        this.props.onChange({ groups: this.state.groups, valid: this.isValidPeople() });
      }
    );
  };

  removePerson = (groupIndex, index) => {
    const groups = this.state.groups.map((data) => data);

    groups[groupIndex].people.splice(index, 1);

    this.setState({ groups });
    this.props.onChange({ groups, valid: this.isValidPeople() });
  };

  /**
   * @returns {boolean} return validation of people component.
   */
  isValidPeople = () => {
    let peopleGroupIndex = 0;
    let valid = true;
    while (peopleGroupIndex < this.state.groups.length && valid) {
      if (
        !this.isValidPerson(this.state.groups[peopleGroupIndex].people) ||
        !this.state.groups[peopleGroupIndex].name
      ) {
        valid = false;
      }
      peopleGroupIndex++;
    }
    return valid;
  };

  /**
   * @param {people} people that validation depend on.
   *
   * @returns {boolean} return validation of a person component.
   */
  isValidPerson = (people) => {
    let peopleGroupIndex = 0;
    let valid = true;
    while (peopleGroupIndex < people.length && valid) {
      if (
        !people[peopleGroupIndex].name ||
        !people[peopleGroupIndex].job_position ||
        !people[peopleGroupIndex].introduction
      ) {
        valid = false;
      }
      peopleGroupIndex++;
    }
    return valid;
  };

  /**
   * @returns {JSX.Element} People component to add/edit people.
   */
  render() {
    return (
      <div className="people-content">
        <SortableBlockContainer
          helperClass="people-content people-group-content sorting"
          groups={this.state.groups}
          onChangeGroup={this.onChange}
          removeGroup={this.removeGroup}
          addPerson={this.addPerson}
          addGroup={this.addGroup}
          removePerson={this.removePerson}
          onDropFile={this.onDropFile}
          deleteFile={this.deleteFile}
          onChangePerson={this.onChangePerson}
          onDragEnd={this.onDragEnd}
          onSortEnd={this.onDragEnd}
          useWindowAsScrollContainer
          useDragHandle
          delay={100}
        />
      </div>
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    employees: app.employees,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEmployees,
      pagesUploadFile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(People));
