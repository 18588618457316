import request, { Methods } from 'modules/shared/util/request';

/**
 * @description Gets vimeo data.
 *
 * @param {Object} data include url
 *
 * @returns {Promise<*>}
 */
export const getVimeoData = async (data) => {
  return await request({
    resource: 'pages/getVimeoData',
    method: Methods.POST,
    data,
  });
};
