import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Grid } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

// import { getEdgeLanding } from 'common';
import { Backdrop } from 'modules/shared';
// import Env from 'env';
import style from './MegaMenu.scss';

class MegaMenu extends Component {
  static propTypes = {
    showMenu: PropTypes.bool,
    showBorder: PropTypes.bool,
    toggleMenu: PropTypes.func,
    profile: PropTypes.object,
    settings: PropTypes.object,
    toggleAnalystLoginForm: PropTypes.func,
    toggleEdgeLoginForm: PropTypes.func,
  };

  /**
   * @returns {JSX.Element} - MegaMenu component
   */
  render() {
    return (
      <div
        className={classNames('mega-menu', {
          'show-menu': this.props.showMenu,
          'show-border': this.props.showBorder,
        })}>
        {this.props.showMenu ? (
          <Backdrop className="mega-menu-backdrop" onClick={this.props.toggleMenu} />
        ) : null}
        <Grid fluid>
          <Row>
            {/*<Col xs={12} sm={12} md={4}>*/}
            {/*<NavLink className="header-link main-link" to="/">*/}
            {/*<FormattedMessage id="MAIN.MEGA_MENU.HOME" />*/}
            {/*</NavLink>*/}
            {/*<div className="embed-links">*/}
            {/*<NavLink className="header-link" to="/about">*/}
            {/*<FormattedMessage id="MAIN.HEADER.ABOUT_US" />*/}
            {/*</NavLink>*/}
            {/*<NavLink className="header-link" to="/people">*/}
            {/*<FormattedMessage id="MAIN.HEADER.OUR_PEOPLE" />*/}
            {/*</NavLink>*/}
            {/*<NavLink className="header-link" to="/press">*/}
            {/*<FormattedMessage id="MAIN.HEADER.PRESS" />*/}
            {/*</NavLink>*/}
            {/*<NavLink className="header-link" to="/careers">*/}
            {/*<FormattedMessage id="MAIN.HEADER.CAREERS" />*/}
            {/*</NavLink>*/}
            {/*<NavLink className="header-link" to="/contact">*/}
            {/*<FormattedMessage id="MAIN.HEADER.CONTACT" />*/}
            {/*</NavLink>*/}
            {/*</div>*/}
            {/*</Col>*/}
            <Col xs={12} sm={12} md={12} className="analyst-links">
              <NavLink className="header-link main-link" to="/">
                <FormattedMessage id="MAIN.MEGA_MENU.THE_ANALYST" />
              </NavLink>
              <div className="embed-links">
                {/*<NavLink className="header-link" to={`/how-we-work`}>*/}
                {/*<FormattedMessage id="ANALYST.HEADER.HOW_WE_WORK" />*/}
                {/*</NavLink>*/}
                <NavLink className="header-link" to="/about">
                  <FormattedMessage id="MAIN.HEADER.ABOUT_US" />
                </NavLink>
                <NavLink className="header-link" to="/people">
                  <FormattedMessage id="MAIN.HEADER.OUR_PEOPLE" />
                </NavLink>
                <NavLink className="header-link" to={`/sample-research`}>
                  <FormattedMessage id="ANALYST.HEADER.SAMPLE_RESEARCH" />
                </NavLink>
                <NavLink className="header-link" to="/careers">
                  <FormattedMessage id="MAIN.HEADER.CAREERS" />
                </NavLink>
                <NavLink className="header-link" to="/press">
                  <FormattedMessage id="MAIN.HEADER.PRESS" />
                </NavLink>
                <NavLink className="header-link" to="/contact">
                  <FormattedMessage id="MAIN.HEADER.CONTACT" />
                </NavLink>

                {this.props.profile ? <hr /> : null}

                {this.props.profile && !this.props.profile.id && (
                  <div className="header-link" onClick={this.props.toggleAnalystLoginForm}>
                    <FormattedMessage id="MAIN.CLIENT_LOGIN_HEADER" />
                  </div>
                )}
                {this.props.profile && this.props.profile.id && (
                  <NavLink className="header-link" to={`/app`}>
                    <FormattedMessage id="MAIN.GO_MY_RESEARCH" />
                  </NavLink>
                )}
              </div>
            </Col>
            {/*<Col xs={12} sm={12} md={4} className="edge-links">*/}
            {/*<NavLink className="header-link main-link" to="/edge">*/}
            {/*<FormattedMessage id="MAIN.MEGA_MENU.THE_ANALYST_EDGE" />*/}
            {/*</NavLink>*/}
            {/*<div className="embed-links">*/}
            {/*<NavLink className="header-link" to={`/${Env.EDGE_BASE_PATH}/our-services`}>*/}
            {/*<FormattedMessage id="EDGE.HEADER.OUR_SERVICES" />*/}
            {/*</NavLink>*/}
            {/*<NavLink className="header-link" to={`/${Env.EDGE_BASE_PATH}/pricing`}>*/}
            {/*<FormattedMessage id="EDGE.HEADER.PRICING" />*/}
            {/*</NavLink>*/}
            {/*<NavLink className="header-link" to={`/${Env.EDGE_BASE_PATH}/edge-insights`}>*/}
            {/*<FormattedMessage id="EDGE.HEADER.EDGE_INSIGHTS" />*/}
            {/*</NavLink>*/}

            {/*<hr />*/}

            {/*{this.props.profile &&*/}
            {/*!this.props.profile.id && (*/}
            {/*<NavLink className="header-link" to={`/${Env.EDGE_BASE_PATH}/pricing`}>*/}
            {/*<FormattedMessage id="EDGE.HEADER.START_FREE_TRIAL" />*/}
            {/*</NavLink>*/}
            {/*)}*/}
            {/*{this.props.profile &&*/}
            {/*!this.props.profile.id && (*/}
            {/*<div className="header-link" onClick={this.props.toggleEdgeLoginForm}>*/}
            {/*<FormattedMessage id="MAIN.CLIENT_LOGIN_HEADER" />*/}
            {/*</div>*/}
            {/*)}*/}
            {/*{this.props.profile &&*/}
            {/*this.props.profile.id && (*/}
            {/*<NavLink*/}
            {/*className="header-link"*/}
            {/*to={`/${getEdgeLanding(this.props.settings)}/app`}>*/}
            {/*<FormattedMessage id="MAIN.GO_MY_RESEARCH" />*/}
            {/*</NavLink>*/}
            {/*)}*/}
            {/*</div>*/}
            {/*</Col>*/}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(MegaMenu);
