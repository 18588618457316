import { combineReducers } from 'redux';

import pages from './pages';
import countries from './countries';
import investorTypes from './investorTypes';

export default combineReducers({
  pages,
  countries,
  investorTypes,
});
