import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'modules/shared';
import { getFileIcon } from 'common';

/**
 * @param {Array} downloads Collection of downloadable files.
 * @param {bool} hideHeader show Header.
 * @returns {null | JSX.Element}
 * @constructor
 */
const Downloads = ({ downloads, hideHeader }) => {
  return downloads && downloads.length ? (
    <div className={classNames('downloads', { 'info-section': !hideHeader })}>
      {!hideHeader ? <div className="header">Downloads ({downloads.length})</div> : null}
      <div className="content">
        {downloads.map((download) => {
          return (
            <Link key={download.id} linkId={download.id}>
              <i className={getFileIcon(download)} />
              <span>{download.custom_properties.display_name}</span>
            </Link>
          );
        })}
      </div>
    </div>
  ) : null;
};

Downloads.propTypes = {
  downloads: PropTypes.array,
  hideHeader: PropTypes.bool,
};

export default Downloads;
