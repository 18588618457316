import request from 'modules/shared/util/request';

const basePath = 'analytics';

/**
 * @returns {Promise<*>}
 */
export const getSummaryAnalytics = async () => {
  return await request({
    resource: `${basePath}/header`,
  });
};

/**
 * @param {Object} options Query options
 *
 * @returns {Promise<*>}
 */
export const getArticleAnalytics = async ({ search, from, to, page, per_page } = {}) => {
  return await request({
    resource: `${basePath}/article`,
    params: { search, from, to, page, per_page },
  });
};

/**
 * @param {Object} options Query options
 *
 * @returns {Promise<*>}
 */
export const getEdgeArticleAnalytics = async ({ search, from, to, page, per_page } = {}) => {
  return await request({
    resource: `edge/${basePath}/article`,
    params: { search, from, to, page, per_page },
  });
};

/**
 * @param {Object} options Query options
 *
 * @returns {Promise<*>}
 */
export const getEdgePlusArticleAnalytics = async ({ search, from, to, page, per_page } = {}) => {
  return await request({
    resource: `edgeplus/${basePath}/article`,
    params: { search, from, to, page, per_page },
  });
};

/**
 * @param {Object} options Query options
 *
 * @returns {Promise<*>}
 */
export const getUserAnalytics = async ({ search, from, to, page, per_page } = {}) => {
  return await request({
    resource: `${basePath}/user`,
    params: { search, from, to, page, per_page },
  });
};

/**
 * @param {number} id User ID
 *
 * @returns {Promise<*>}
 */
export const getUserHeader = async (id) => {
  return await request({
    resource: `${basePath}/user/${id}/header`,
  });
};

/**
 * @param {number} id User ID
 *
 * @returns {Promise<*>}
 */
export const getUserViews = async (id) => {
  return await request({
    resource: `${basePath}/user/${id}/views`,
  });
};

/**
 * @param {number} id User ID
 *
 * @returns {Promise<*>}
 */
export const getUserDownloads = async (id) => {
  return await request({
    resource: `${basePath}/user/${id}/downloads`,
  });
};

/**
 * @param {number} id User ID
 *
 * @returns {Promise<*>}
 */
export const getUserLogins = async (id) => {
  return await request({
    resource: `${basePath}/user/${id}/logins`,
  });
};

/**
 * @param {number} id Article ID
 * @param {boolean} just_show_active query param
 *
 * @returns {Promise<*>}
 */
export const getArticleHeader = async (id, just_show_active) => {
  return await request({
    resource: `${basePath}/article/${id}/header`,
    params: { just_show_active },
  });
};

/**
 * @param {number} id Article ID
 *
 * @returns {Promise<*>}
 */
export const getEdgeArticleHeader = async (id) => {
  return await request({
    resource: `edge/${basePath}/article/${id}/header`,
  });
};

/**
 * @param {number} id Article ID
 *
 * @returns {Promise<*>}
 */
export const getEdgePlusArticleHeader = async (id) => {
  return await request({
    resource: `edgeplus/${basePath}/article/${id}/header`,
  });
};

/**
 * @param {number} id Article ID
 * @param {boolean} just_show_active query param
 *
 * @returns {Promise<*>}
 */
export const getArticleUsers = async (id, just_show_active) => {
  return await request({
    resource: `${basePath}/article/${id}/users`,
    params: { just_show_active },
  });
};

/**
 * @param {number} id Article ID
 *
 * @returns {Promise<*>}
 */
export const getEdgeArticleUsers = async (id) => {
  return await request({
    resource: `edge/${basePath}/article/${id}/users`,
  });
};

/**
 * @param {number} id Article ID
 *
 * @returns {Promise<*>}
 */
export const getEdgePlusArticleUsers = async (id) => {
  return await request({
    resource: `edgeplus/${basePath}/article/${id}/users`,
  });
};

/**
 * @param {number} id Article ID
 *
 * @returns {Promise<*>}
 */
export const getArticleDownloads = async (id) => {
  return await request({
    resource: `${basePath}/article/${id}/downloads`,
  });
};

/**
 * @param {number} id Article ID
 *
 * @returns {Promise<*>}
 */
export const getEdgeArticleDownloads = async (id) => {
  return await request({
    resource: `edge/${basePath}/article/${id}/downloads`,
  });
};

/**
 * @param {number} id Article ID
 *
 * @returns {Promise<*>}
 */
export const getEdgePlusArticleDownloads = async (id) => {
  return await request({
    resource: `edgeplus/${basePath}/article/${id}/downloads`,
  });
};

/**
 * @param {Object} options Query options
 *
 * @returns {Promise<*>}
 */
export const exportArticleAnalytics = async ({ search, from, to, app = '' } = {}) => {
  return await request({
    resource: `${app}${basePath}/article/export`,
    params: { search, from, to },
  });
};

/**
 * @param {Object} options Query options
 *
 * @returns {Promise<*>}
 */
export const exportUserAnalytics = async ({ search, from, to } = {}) => {
  return await request({
    resource: `${basePath}/user/export`,
    params: { search, from, to },
  });
};
