import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { answerQuestion } from 'modules/analyst/actions/posts';

import LikeQuestions from './LikeQuestions.jsx';

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      answerQuestion,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LikeQuestions);
