import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLatestSurvey, takeSurvey } from 'modules/analyst/actions/surveys';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

import Survey from './Survey';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst, app }) => {
  return {
    survey: analyst.surveys.survey,
    showCount: app.loader.showCount,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showLoader, hideLoader, getLatestSurvey, takeSurvey }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
