export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

/**
 * @description Shows the loader.
 * @returns {Object}
 */
export const showLoader = () => {
  return {
    type: SHOW_LOADER,
  };
};

/**
 * @description Hides the loader.
 * @returns {Object}
 */
export const hideLoader = () => {
  return {
    type: HIDE_LOADER,
  };
};
