import general from './general';
import local from './local';
import development from './development';
import stage from './stage';
import staging from './staging';
import production from './production';

const ENV = process.env.RAZZLE_ENV || 'development';

const ENV_CONSTANTS = {
  local,
  development,
  stage,
  staging,
  production,
};

export default {
  ...general,
  ...ENV_CONSTANTS[ENV],
};
