import { GET_ROLES } from '../actions/roles';

/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const roles = (state = [], action) => {
  switch (action.type) {
    case GET_ROLES:
      return action.payload || [];
    default:
      return state;
  }
};

export default roles;
