import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { area, line, select, extent, scaleLinear, scaleTime } from 'd3';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import isEqual from 'lodash/isEqual';

import * as companiesService from 'modules/shared/services/companies';
import FeedbackExplanation from '../feedback-explanation/FeedbackExplanation';
import Env from 'env';
import { StarIcon } from 'modules/shared';
import starIconSvg from 'assets/icons/star.svg';

import styles from './FeedbackAreaChart.scss';

const SCREEN_ADDED_PLUS_UNIT_RATIO = 8;
const DATE_COMPARE_UNIT = 'hours';
const TYPE_OF_CHART = {
  PREVIOUS: 'previous',
  CURRENT_VOTE_RESULT: 'current_vote_result',
};
const MIN_VOTE_TO_HIDE_STAR_ICON = 10;

class FeedbackAreaChart extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    currentVoteResults: PropTypes.object,
    intl: PropTypes.object,
    isPostOlderToShow: PropTypes.bool,
    showLoader: PropTypes.func,
    hideLoader: PropTypes.func,
    postId: PropTypes.number,
    mobile: PropTypes.bool,
  };

  state = {
    transitionDuration: 1000,
    data: null,
    currentVoteResultsData: null,
    showCurrentVoteResults: false,
    loading: false,
  };

  margin = {
    top: 10,
    right: 35,
    bottom: 85,
    left: 45,
  };
  verticalLineWidth = 2;
  axisLineWidth = 3;
  areaLineWidth = 3;
  dateDayMonthTextWidth = 38;
  dateYearTextWidth = 33;
  initThisPostTextWidth = 74;
  dateTextHeight = 17;
  percentTextHeight = 23;
  percentTextHeightYCompensation = this.percentTextHeight / 4;
  percentTextWidthThreeChars = 46;
  percentTextWidthTwoChars = 35;
  percentTextWidthThreeCharsXCompensation = this.percentTextWidthThreeChars / 2;
  percentTextWidthTwoCharsXCompensation = this.percentTextWidthTwoChars / 2;
  minPercentToShow = 15;
  prevPercentZeroTextAdjust = 2.1;
  prevPercentZeroTextAdjust100Percent = 2.2;
  prevPercentZeroTextAdjust100PercentInverse = 2.8;
  paddingOneZeroText = 2;
  postTypeHeight = 45;
  starTextHeight = 28;
  minYDomain = 0;
  maxYDomain = 100;
  likeColor = '#1F61A3';
  dislikeColor = '#ec6059';
  starIconColor = '#79858b';
  storeColor = '#000';
  lineColor = '#fff';
  percentageColor = '#fff';
  verticalLineColor = '#f1f1f0';
  yData = [this.maxYDomain, this.maxYDomain / 2, this.minYDomain];
  firstValue = 50;
  currentVoteResultsWidth = 125;
  minLenghtToShowCurrentVoteResults = 2;
  currentVotingTextWidth = 70;
  windowTextWidth = 50;
  starIconTopMargin = 14;
  starIconSize = 10;
  defaultFirstValueId = -1;

  /**
   * @description call init chart
   */
  async componentDidMount() {
    this.fetchData(true);
    window.addEventListener('resize', this.resize);
  }

  /**
   * @description update data
   */
  componentDidUpdate(prevProps) {
    this.updateCurrentVoteResults(prevProps);
  }

  /**
   *
   * @param {Array} data data
   * @description get index of current vote
   * @returns {number} position of current vote in data
   */
  getCurrentVotePosition(data) {
    const { postId } = this.props;

    return data.findIndex(({ id }) => id === postId);
  }

  /**
   * @description update data with current voteResults
   */
  updateCurrentVoteResults(prevProps) {
    const { currentVoteResults } = this.props;

    if (!isEqual(currentVoteResults, prevProps.currentVoteResults)) {
      this.fetchData();
    }
  }

  /**
   *
   * @param {Object} data data
   * @description get added days
   * @returns {number} added days
   */
  getAddedPlusDays(data) {
    const maxDiffOfDates =
      data &&
      Array.isArray(data.posts) &&
      Math.abs(
        this.getDiffOfDates(data.posts[0].created_at, data.posts[data.posts.length - 1].created_at)
      );

    return maxDiffOfDates / SCREEN_ADDED_PLUS_UNIT_RATIO;
  }

  /**
   *
   * @param {string} date data
   * @param {string} compareDate data
   *
   * @description get diff of days
   *
   * @returns {number} diff of days
   */
  getDiffOfDates(date, compareDate) {
    return moment(date).diff(moment(compareDate), DATE_COMPARE_UNIT);
  }

  /**
   *
   * @param {string} date data
   * @param {number} amount amount to add
   *
   * @description add days to date
   *
   * @returns {Moment} added date
   */
  addDayToDate(date, amount) {
    // eslint-disable-next-line
    return moment(date)
      .add(amount, DATE_COMPARE_UNIT)
      .format();
  }

  /**
   *
   * @param {string} field sort by this key
   *
   * @description sort items ascending by provided field as date
   *
   * @returns {Function} sort items by field
   */
  getDateSortCompare = (field) => (a, b) => new moment(a[field]) - new moment(b[field]);

  /**
   *
   * @param {Object} data data
   *
   * @description add default first value
   *
   * @returns {Object} extended data with default first value
   */
  addDefaultFirstValue(data) {
    data.posts =
      data && Array.isArray(data.posts)
        ? data.posts.sort(this.getDateSortCompare('created_at'))
        : [];

    data.posts =
      data.posts.length > 0
        ? [
            {
              likeCount: 0,
              dislikeCount: 0,
              created_at: moment(data.posts[0].created_at)
                .subtract(1, DATE_COMPARE_UNIT)
                .format(),
              id: this.defaultFirstValueId,
            },
            ...data.posts,
          ]
        : data.posts;

    return data;
  }

  /**
   * @description fetch data, map data, filter data to on a same data be only one value
   */
  async fetchData(init) {
    if (init && this.props.showLoader) {
      this.props.showLoader();
      this.setState({ loading: true });
    }

    let data = await companiesService.getCompanyStatistics(this.props.company.id);
    data = this.addDefaultFirstValue(data);
    const showCurrentVoteResults =
      data.posts && data.posts.length > this.minLenghtToShowCurrentVoteResults;

    const mappedData = this.getMappedData(
      {
        ...data,
        posts: data.posts,
      },
      showCurrentVoteResults
    );

    // reverse to get the last index of item with same date
    const reversedMappedData = mappedData.reverse();
    const reversedData = reversedMappedData.reverse();

    this.setState(
      {
        showCurrentVoteResults,
        data: showCurrentVoteResults
          ? reversedData.filter((_, index) => index < reversedData.length - 1)
          : reversedData,
        currentVoteResultsData: showCurrentVoteResults
          ? reversedData.filter((_, index) => index > reversedData.length - 3)
          : [],
        loading: false,
      },
      () => {
        this.resize();

        if (init && this.props.hideLoader) {
          this.props.hideLoader();
        }
      }
    );
  }

  /**
   *
   * @param {Object} data data
   * @param {boolean} showCurrentVoteResults show current vote results
   *
   * @description get mapped data
   *
   * @returns {Array} mapped data
   */
  getMappedData = (data, showCurrentVoteResults) => {
    const { intl, postId } = this.props;
    const addedPlusDays = this.getAddedPlusDays(data);

    let prevCreatedAtDate = null;
    let addedDays = 0;

    return data.posts.map((post, index) => {
      const denominator = post.likeCount + post.dislikeCount;
      const dataWithCurrentVoteResult =
        index + 1 <= data.posts.length - 1
          ? data.posts[index + 1].created_at
          : this.addDayToDate(post.created_at, 1);

      const dateToCalculate = showCurrentVoteResults ? dataWithCurrentVoteResult : post.created_at;
      const date = this.addDayToDate(dateToCalculate, addedDays);

      const addPlusToCreatedDate =
        index >= 1 &&
        data.posts[index - 1] &&
        this.getDiffOfDates(date, moment(prevCreatedAtDate)) < addedPlusDays;

      if (addPlusToCreatedDate) {
        addedDays += addedPlusDays;
        prevCreatedAtDate = this.addDayToDate(date, addedPlusDays);
      } else {
        prevCreatedAtDate = date;
      }

      return {
        postType: this.isVideo(post)
          ? intl.formatMessage({ id: 'MAIN.VIDEO' })
          : intl.formatMessage({ id: 'MAIN.NOTE' }),
        initNoteThisPostText: this.isInitNote(post)
          ? intl.formatMessage({ id: 'MAIN.INITIAL_NOTE' })
          : post.id === postId
          ? intl.formatMessage({ id: 'MAIN.THIS_POST' })
          : '',
        date: moment(post.created_at),
        correctedDate: moment(prevCreatedAtDate),
        value:
          index === 0 || denominator === 0
            ? this.firstValue
            : (post.likeCount / denominator) * this.maxYDomain,
        id: post.id,
        showStarIcon:
          post.id != this.defaultFirstValueId && denominator < MIN_VOTE_TO_HIDE_STAR_ICON,
      };
    });
  };

  /**
   * @param {Object} post data
   * @returns {boolean} is post video post
   */
  isVideo = (post) => post.categories && !!post.categories.find(({ slug }) => slug === 'video');

  /**
   * @param {Object} post data
   * @returns {boolean} is post init post
   */
  isInitNote = (post) =>
    post.categories && !!post.categories.find(({ slug }) => slug === 'initiation_note');

  /**
   * @description Unsubscribe from window resize event;
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  /**
   * @description Resize the chart on window resize event.
   */
  resize = () => {
    if (this.state.data && this.state.data.length > 0) {
      this.initChart();
      this.setState({ transitionDuration: 0 });
    }
  };

  /**
   * @description init chart, get width, height of container, set domain, range
   */
  initChart = () => {
    if (!this.node) {
      return;
    }

    const { company } = this.props;
    const rootNode = select(`.root-svg-${company.id}`);
    if (rootNode) {
      rootNode.remove();
    }

    const { height, width } = this.node.getBoundingClientRect();

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.selection = select(this.node);
    this.domNode = this.selection.node();
    this.svg = this.selection
      .append('svg')
      .attr('class', `root-svg-${company.id}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    const xRangeMaxCompensation = this.state.showCurrentVoteResults
      ? this.currentVoteResultsWidth
      : 0;
    this.x = scaleTime()
      .domain(extent(this.state.data, (d) => d.correctedDate))
      .range([0, this.width - xRangeMaxCompensation]);

    this.xCurrentVoteResults = scaleTime()
      .domain(extent(this.state.currentVoteResultsData, (d) => d.correctedDate))
      .range([this.width - this.currentVoteResultsWidth, this.width]);

    /* eslint-disable */
    this.y = scaleLinear()
      .domain([this.minYDomain, this.maxYDomain])
      .range([this.height, 0]);

    this.renderYAxisTexts();

    this.renderDataRelatedElements(this.state.data, this.x, TYPE_OF_CHART.PREVIOUS);
    if (this.state.showCurrentVoteResults) {
      this.renderDataRelatedElements(
        this.state.currentVoteResultsData,
        this.xCurrentVoteResults,
        TYPE_OF_CHART.CURRENT_VOTE_RESULT
      );
    }

    this.renderXAxisLine();
    this.renderYAxisLine();
    this.renderCurrentVoteResultsTexts();
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   * @param {string} type of rendering
   *
   * @description render chart with proper data
   */
  renderDataRelatedElements(data, x, type) {
    this.renderXAxisPostType(data, x, type);
    this.renderXAxisDayMonthTexts(data, x, type);
    this.renderXAxisYearTexts(data, x, type);
    this.renderXAxisInitThisPostTexts(data, x, type);

    if (data.length > 1) {
      this.renderArea(data, x);
      this.renderAreaInverse(data, x);
      this.renderAreaLine(data, x);
    }

    this.renderXAxisVerticalLine(data, x, type);
    this.renderStarIcon(data, x, type);
  }

  /**
   * @description renderYAxisTexts
   */
  renderYAxisTexts = () => {
    const bar = this.svg
      .selectAll('g')
      .data(this.yData)
      .enter()
      .append('g')
      .attr('transform', (d, i) => {
        const widthCompensation = 8 * i;
        return `translate(-${this.margin.left - this.axisLineWidth + 1 - widthCompensation}, 5)`;
      });

    bar
      .append('text')
      .attr('x', 0)
      .attr('y', (d) => this.y(d))
      .text((d) => `${d}%`);
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   * @param {string} type of rendering
   *
   * @description renderXAxisPostType
   */
  renderXAxisPostType = (data, x, type) => {
    const XAxisBarPostType = this.svg
      .selectAll(`g.x-axis-type-g-${type}`)
      .data(data)
      .enter()
      .append('g')
      .attr('class', (d, index) => (index > 0 ? `x-axis-type-g-${type}` : 'd-none'))
      .attr('transform', (d, index) =>
        index > 0 ? `translate(${x(data[index - 1].correctedDate) - 6}, ${this.height + 20})` : ''
      );

    XAxisBarPostType.append('circle')
      .attr('fill', this.storeColor)
      .attr('cx', 6)
      .attr('cy', 0)
      .attr('r', 10);

    XAxisBarPostType.append('text')
      .attr('fill', this.lineColor)
      .attr('x', 1)
      .attr('y', 5)
      .text((d) => d.postType);
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   * @param {string} type of rendering
   *
   * @description renderXAxisTexts
   */
  renderXAxisDayMonthTexts = (data, x, type) => {
    const XAxisBar = this.svg
      .selectAll(`g.x-axis-day-month-text-g-${type}`)
      .data(data)
      .enter()
      .append('g')
      .attr('class', (d, index) => (index > 0 ? `x-axis-day-month-text-g-${type}` : 'd-none'))
      .attr('transform', (d, index) =>
        index > 0
          ? `translate(${x(data[index - 1].correctedDate) - this.dateDayMonthTextWidth / 2}, ${this
              .height + this.postTypeHeight})`
          : ''
      );

    XAxisBar.append('text')
      .attr('x', 0)
      .attr('y', (d) => this.y(d))
      .text((d) => `${moment(d.date).format(Env.FEEDBACK_DATE_MONTH_DAY_FORMAT)}`);
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   * @param {string} type of rendering
   *
   * @description renderXAxisTexts
   */
  renderXAxisYearTexts = (data, x, type) => {
    const XAxisBar = this.svg
      .selectAll(`g.x-axis-year-text-g-${type}`)
      .data(data)
      .enter()
      .append('g')
      .attr('class', (d, index) => (index > 0 ? `x-axis-year-text-g-${type}` : 'd-none'))
      .attr('transform', (d, index) =>
        index > 0
          ? `translate(${x(data[index - 1].correctedDate) - this.dateYearTextWidth / 2}, ${this
              .height +
              this.dateTextHeight +
              this.postTypeHeight})`
          : ''
      );

    XAxisBar.append('text')
      .attr('x', 0)
      .attr('y', (d) => this.y(d))
      .text((d) => `${moment(d.date).format(Env.FEEDBACK_DATE_YEAR_FORMAT)}`);
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   * @param {string} type of rendering
   *
   * @description render X axis Init and This post texts
   */
  renderXAxisInitThisPostTexts = (data, x, type) => {
    const { postId } = this.props;

    const XAxisInitThisPostBar = this.svg
      .selectAll(`g.x-axis-init-this-post-text-g-${type}`)
      .data(data)
      .enter()
      .append('g')
      .attr('class', `x-axis-init-this-post-text-g-${type}`)
      .attr('transform', (d, index) => {
        if (index === 0) {
          return;
        }

        const thisPostCompensation = d.id === postId ? 4 : -8;

        return `translate(${x(data[index - 1].correctedDate) -
          (this.initThisPostTextWidth / 2 - thisPostCompensation)}, ${this.height +
          this.postTypeHeight +
          this.dateTextHeight * 2 +
          2})`;
      });

    XAxisInitThisPostBar.append('text')
      .attr('x', 0)
      .attr('y', (d) => this.y(d))
      .attr('fill', this.dislikeColor)
      .text((d, index) => (index != 0 ? d.initNoteThisPostText : ''));
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   *
   * @description renderArea
   */
  renderArea = (data, x) => {
    this.svg
      .append('path')
      .datum(data)
      .attr('fill', this.likeColor)
      .attr('stroke', 'none')
      .attr(
        'd',
        area()
          .x(0)
          .y0(this.height)
          .y1((d) => this.y(d.value))
      )
      .transition()
      .duration(this.state.transitionDuration)
      .attr(
        'd',
        area()
          .x((d) => x(d.correctedDate))
          .y0(this.height)
          .y1((d) => this.y(d.value))
      );
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   *
   * @description renderAreaInverse
   */
  renderAreaInverse = (data, x) => {
    this.svg
      .append('path')
      .datum(data)
      .attr('fill', this.dislikeColor)
      .attr('stroke', 'none')
      .attr(
        'd',
        area()
          .x(0)
          .y0(0)
          .y1((d) => this.y(d.value))
      )
      .transition()
      .duration(this.state.transitionDuration)
      .attr(
        'd',
        area()
          .x((d) => x(d.correctedDate))
          .y0(0)
          .y1((d) => this.y(d.value))
      );
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   *
   * @description renderAreaLine
   */
  renderAreaLine = (data, x) => {
    this.svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', this.lineColor)
      .attr('stroke-width', this.areaLineWidth)
      .attr(
        'd',
        line()
          .x(0)
          .y((d) => this.y(d.value))
      )
      .transition()
      .duration(this.state.transitionDuration)
      .attr(
        'd',
        line()
          .x((d) => x(d.correctedDate))
          .y((d) => this.y(d.value))
      );
  };

  /**
   * @description renderXAxisLine
   */
  renderXAxisLine = () => {
    this.svg
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.width + this.margin.right - 5)
      .attr('y2', 0)
      .attr('transform', `translate(0,${this.height + 1})`)
      .attr('fill', 'none')
      .attr('stroke', this.storeColor)
      .attr('stroke-width', this.axisLineWidth)
      .attr('stroke-linecap', 'round');
  };

  /**
   * @description renderYAxisLine
   */
  renderYAxisLine = () => {
    this.svg
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', this.height)
      .attr('transform', `translate(0,0)`)
      .attr('fill', 'none')
      .attr('stroke', this.storeColor)
      .attr('stroke-width', this.axisLineWidth)
      .attr('stroke-linecap', 'round');
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   * @param {string} type of rendering
   *
   * @description renderXAxisVerticalLine
   */
  renderXAxisVerticalLine = (data, x, type) => {
    const XAxisVerticalBar = this.svg
      .selectAll(`g.x-axis-vertical-bar-g-${type}`)
      .data(data)
      .enter()
      .append('g')
      .attr('class', `x-axis-vertical-bar-g x-axis-vertical-bar-g-${type}`)
      .attr('transform', (d) => `translate(${x(d.correctedDate)},0)`);

    XAxisVerticalBar.append('line')
      .attr('x', 0)
      .attr('y1', 0)
      .attr('y2', this.height)
      .attr('fill', 'none')
      .attr('stroke', this.verticalLineColor)
      .attr('stroke-width', this.verticalLineWidth)
      .attr('stroke-dasharray', (d, index) =>
        type === TYPE_OF_CHART.PREVIOUS || (type === TYPE_OF_CHART.CURRENT_VOTE_RESULT && index > 0)
          ? 12
          : 'none'
      );

    this.renderAreaPercentages(XAxisVerticalBar, data, x);
    this.renderAreaInversePercentages(XAxisVerticalBar, data, x);
  };

  /**
   * @param {number} value percent value
   *
   * @description add percent to end of value
   */
  renderPercent = (value) => `${Math.round(value)}%`;

  /**
   * @param {number} index index of actual in data array
   * @param {Array} data data of chart
   *
   * @description check possibility to calculate y position
   */
  isPositionCalculationImpossible = (index, data) => index === 0 || !data || (data && !data.length);

  /**
   * @param {number} value percent value
   * @param {number} value previous percent value
   *
   * @description add percent to end of value
   */
  getMinYPostition = (value, prevValue) => {
    const minValue = Math.min(value, prevValue);

    return minValue === 0 ? Math.max(value, prevValue) : minValue;
  };

  /**
   * @param {number} value percent value
   *
   * @description calculate y value from percent value
   */
  getAreaPercentagesYPostition = (value) => {
    const centerYPositionByValue = (this.height - this.y(value)) / 2;
    const heightOfAreaInverse = this.height - this.y(this.maxYDomain - value);

    return centerYPositionByValue + heightOfAreaInverse + this.percentTextHeightYCompensation;
  };

  /**
   * @param {number} value percent value
   *
   * @description calculate y value from percent value
   */
  getAreaInversePercentagesYPostition = (value) => {
    const heightOfAreaInverse = (this.height - this.y(value)) / 2;

    return heightOfAreaInverse + this.percentTextHeightYCompensation;
  };

  /**
   * @param {number} sectionWidth width of section
   * @param {number} textWidthCompensation width of compensation
   * @param {number} value percent value
   * @param {number} prevValue percent value of previous item
   * @param {boolean} inverse is data inversed
   *
   * @description calculate x position by y position
   */
  calculateXPositionByYPosition = (
    sectionWidth,
    textWidthCompensation,
    value,
    prevValue,
    inverse
  ) => {
    if (value === 0) {
      return -sectionWidth - this.paddingOneZeroText;
    } else if (prevValue === 0) {
      const fullPercentAdjust = inverse
        ? this.prevPercentZeroTextAdjust100PercentInverse
        : this.prevPercentZeroTextAdjust100Percent;
      const adjust = value === 100 ? fullPercentAdjust : this.prevPercentZeroTextAdjust;
      return -textWidthCompensation * adjust - this.paddingOneZeroText;
    }

    return null;
  };

  /**
   * @param {Object} d as data
   * @param {number} i index of current value in array
   * @param {boolean} inverse is data inversed
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   *
   * @description calculate x value from correctedDate
   */
  getAreaPercentagesXPostition = (d, i, inverse, data, x) => {
    if (this.isPositionCalculationImpossible(i, data)) {
      return;
    }
    const prevData = data[i - 1];
    const sectionWidth = x(d.correctedDate) - x(prevData.correctedDate);
    const textWidthCompensation =
      d.value === 100
        ? this.percentTextWidthThreeCharsXCompensation
        : this.percentTextWidthTwoCharsXCompensation;

    const valueByInverse = inverse ? this.maxYDomain - d.value : d.value;
    const prevValueByInverse = inverse ? this.maxYDomain - prevData.value : prevData.value;

    const xPositionByY = this.calculateXPositionByYPosition(
      sectionWidth,
      textWidthCompensation,
      valueByInverse,
      prevValueByInverse,
      inverse
    );

    return xPositionByY ? xPositionByY : -sectionWidth / 2 - textWidthCompensation;
  };

  /**
   * @param {Element} XAxisVerticalBar vertical bar element
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   *
   * @description renderAreaPercentages
   */
  renderAreaPercentages = (XAxisVerticalBar, data, x) => {
    XAxisVerticalBar.append('text')
      .attr('x', (d, i) => this.getAreaPercentagesXPostition(d, i, false, data, x))
      .attr('y', (d, i) => {
        if (this.isPositionCalculationImpossible(i, data)) {
          return;
        }

        return this.getAreaPercentagesYPostition(this.getMinYPostition(d.value, data[i - 1].value));
      })
      .attr('fill', this.percentageColor)
      .text((d, i) =>
        i > 0 && d.value >= this.minPercentToShow ? this.renderPercent(d.value) : ''
      );
  };

  /**
   * @param {Element} XAxisVerticalBar vertical bar element
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   *
   * @description renderAreaInversePercentages
   */
  renderAreaInversePercentages = (XAxisVerticalBar, data, x) => {
    XAxisVerticalBar.append('text')
      .attr('x', (d, i) => this.getAreaPercentagesXPostition(d, i, true, data, x))
      .attr('y', (d, i) => {
        if (this.isPositionCalculationImpossible(i, data)) {
          return;
        }

        return this.getAreaInversePercentagesYPostition(
          this.getMinYPostition(this.maxYDomain - d.value, this.maxYDomain - data[i - 1].value)
        );
      })
      .attr('fill', this.percentageColor)
      .text((d, i) => {
        const value = this.maxYDomain - d.value;
        return value >= this.minPercentToShow && i > 0 ? this.renderPercent(value) : '';
      });
  };

  /**
   * @description renderCurrentVoteResultsTexts
   */
  renderCurrentVoteResultsTexts = () => {
    const { intl } = this.props;
    const x = this.state.showCurrentVoteResults
      ? this.width - this.currentVoteResultsWidth + this.currentVoteResultsWidth / 2
      : this.width / 2;
    const lineHeightCompensation = 1;

    this.svg
      .append('text')
      .attr('class', 'current-vote-results-text')
      .attr('fill', this.dislikeColor)
      .attr('x', x - this.currentVotingTextWidth / 2)
      .attr('y', () => this.height + this.dateTextHeight + this.starTextHeight)
      .text(() => intl.formatMessage({ id: 'ANALYST.THIS_VOTING' }));

    this.svg
      .append('text')
      .attr('class', 'current-vote-results-text')
      .attr('fill', this.dislikeColor)
      .attr('x', x - this.windowTextWidth / 2)
      .attr(
        'y',
        () => this.height + this.starTextHeight + this.dateTextHeight * 2 - lineHeightCompensation
      )
      .text(() => intl.formatMessage({ id: 'ANALYST.WINDOW' }));
  };

  /**
   * @param {Array} data data of chart
   * @param {Function} x x axis function to align per data
   * @param {string} type of rendering
   *
   * @description renderStarIcon
   */
  renderStarIcon = (data, x, type) => {
    this.svg
      .selectAll(`g.x-axis-star-image-${type}`)
      .data(data)
      .enter()
      .append('image')
      .attr('xlink:href', (d, i) => {
        if (!d.showStarIcon || this.isPositionCalculationImpossible(i, data)) {
          return;
        }

        return starIconSvg;
      })
      .attr('class', `x-axis-star-image-${type}`)
      .attr('width', this.starIconSize)
      .attr('height', this.starIconSize)
      .attr('x', (d, i) => {
        if (!d.showStarIcon || this.isPositionCalculationImpossible(i, data)) {
          return;
        }
        const prevData = data[i - 1];
        const sectionWidth = x(d.correctedDate) - x(prevData.correctedDate);
        const typeCompensation =
          type === TYPE_OF_CHART.CURRENT_VOTE_RESULT
            ? this.width - this.currentVoteResultsWidth
            : x(prevData.correctedDate);
        const iconSizeCompensation = this.starIconSize / 2;

        return typeCompensation + sectionWidth / 2 - iconSizeCompensation;
      })
      .attr('y', this.height + this.starIconTopMargin);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { loading } = this.state;
    const { company, isPostOlderToShow, mobile } = this.props;
    const hasNoData = !this.state.data || (this.state.data && this.state.data.length === 0);

    return (
      <ReactResizeDetector
        handleWidth
        onResize={this.resize}
        render={() => (
          <Fragment>
            {loading ? null : hasNoData || (isPostOlderToShow && hasNoData) ? (
              <FormattedHTMLMessage
                id="ANALYST.NO_RELATED_POSTS_AREA_CHART"
                values={{ company: company.name }}
              />
            ) : (
              <Fragment>
                <div className="feedback-area-chart-container">
                  <div className="feedback-area-chart" ref={(r) => (this.node = r)} />
                </div>
                <FeedbackExplanation
                  mobile={mobile}
                  explanation={
                    <>
                      <FormattedMessage
                        id="MAIN.FEEDBACK_COMPANY_AREA_CHART_EXPLANATION"
                        values={{ companyName: company.name }}
                      />
                      <StarIcon className="feedback-star-icon" />
                      <FormattedMessage id="MAIN.FEEDBACK_COMPANY_AREA_CHART_STAR_EXPLANATION" />
                    </>
                  }
                />
              </Fragment>
            )}
          </Fragment>
        )}
      />
    );
  }
}

export default withStyles(styles)(injectIntl(FeedbackAreaChart));
