import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BaseDataEditor } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getCategory, saveCategory, modifyCategory } from 'modules/analyst/actions/categories';
import { match, history } from 'common';

class AnalystCategoriesEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getCategory: PropTypes.func.isRequired,
    modifyCategory: PropTypes.func.isRequired,
    saveCategory: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    match: match.isRequired,
    history: history.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <BaseDataEditor
        showLoader={this.props.showLoader}
        hideLoader={this.props.hideLoader}
        getBaseData={this.props.getCategory}
        saveBaseData={this.props.saveCategory}
        modifyBaseData={this.props.modifyCategory}
        createLabel={<FormattedMessage id="ADMIN.CATEGORY.CREATE" />}
        editLabel={<FormattedMessage id="ADMIN.CATEGORY.EDIT" />}
        basePath={`${this.props.basePath}/categories`}
        match={this.props.match}
        history={this.props.history}
        hiddenFields={['title', 'meta_title', 'meta_description', 'keywords']}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getCategory,
      saveCategory,
      modifyCategory,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AnalystCategoriesEditor);
