import { BaseQueryParams } from 'modules/shared/models';
import { stringify } from 'common/queryString';

const getPaginationQueryParams = (
  { page = 1, desc, order, nopaginate = true, nofilter, ...query }: BaseQueryParams = { page: 1 }
) => {
  let params: BaseQueryParams = { ...query, page };
  const orderDirection = desc ? 'desc' : 'asc';

  if (order) {
    params = { ...params, order: `${order}:${orderDirection}` };
  }

  if (nopaginate) {
    params = { ...params, nopaginate };
  }

  if (nofilter) {
    params = { ...params, nofilter };
  }

  return `?${stringify({ page, ...params })}`;
};

export { getPaginationQueryParams };
