import { combineReducers } from 'redux';

import bookmarks from './bookmarks';
import contributors from './contributors';
import regions from './regions';
import posts from './posts';
import categories from './categories';
import blogposts from './blogposts';
import blogtags from './blogtags';
import tags from './tags';

export default combineReducers({
  bookmarks,
  contributors,
  regions,
  posts,
  categories,
  blogposts,
  blogtags,
  tags,
});
