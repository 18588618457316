import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withFormikValidation } from 'common/propTypes';
import { FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import bowser from 'bowser';

import { TextInput, Button } from 'modules/shared';

/**
 * @param {Object} Props Injected Form Props.
 * @returns {JSX.Element}
 */
class InnerLoginForm extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    toggleForgotPasswordForm: PropTypes.func.isRequired,
    ...withFormikValidation,
  };

  state = {
    autoFilled: false,
  };

  /**
   * @description detect auto filled fields, beacuse of Chrome and Safari
   * */
  componentDidMount() {
    const { chrome } = bowser;
    if (chrome) {
      setTimeout(() => {
        const autoFilledFields = $(':-webkit-autofill');
        this.setState({ autoFilled: autoFilledFields.length >= 2 });
      }, 100);
    }
  }

  /**
   * @description set autofilled value
   * */
  onInputChange = () => {
    this.setState({ autoFilled: false });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isValid,
      type,
      toggleForgotPasswordForm,
      back,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        {errors.login && (
          <div className="error-message">
            <FormattedMessage id="MAIN.LOGIN_ERROR" />
          </div>
        )}
        <TextInput
          type="email"
          name="email"
          onChange={(e) => {
            this.onInputChange();
            handleChange(e);
          }}
          onBlur={handleBlur}
          autoComplete="on"
          value={values.email}
          placeholderId="MAIN.EMAIL_ADDRESS"
          touched={touched.email}
          error={errors.email}
        />
        <TextInput
          type="password"
          name="password"
          onChange={(e) => {
            this.onInputChange();
            handleChange(e);
          }}
          onBlur={handleBlur}
          autoComplete="on"
          value={values.password}
          placeholderId="MAIN.PASSWORD"
          touched={touched.password}
          error={errors.password}
        />
        <Button
          type="submit"
          moduleType={type}
          disabled={this.state.autoFilled ? false : isSubmitting || !isValid}>
          <FormattedMessage id="MAIN.SIGN_IN" />
        </Button>
        <div className="d-flex justify-center login-nav-container">
          <div className="redirect-to-form back-text" onClick={back}>
            <FormattedMessage id="MAIN.BACK" />
          </div>
          <div className="redirect-to-form" onClick={toggleForgotPasswordForm}>
            <FormattedMessage id="MAIN.FORGOT_PASSWORD" />
          </div>
        </div>
      </form>
    );
  }
}

const LoginForm = withFormik({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email(<FormattedMessage id="MAIN.INVALID_EMAIL" />)
      .required(<FormattedMessage id="MAIN.REQUIRED_FIELD" />),
    password: Yup.string().required(<FormattedMessage id="MAIN.REQUIRED_FIELD" />),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    try {
      await props.login(values);
      setSubmitting(false);
      document.body.classList.remove('overflow-hidden');
      props.history.push(props.loginRedirect);
    } catch (e) {
      setErrors({ login: e });
      setSubmitting(false);
    }
  },
})(InnerLoginForm);

export default withRouter(LoginForm);
