import request from 'modules/shared/util/request';
import { Methods } from 'modules/shared/util/http';

/**
 * @returns {Promise<*>}
 */
export const fetchCountries = () => {
  return request({
    resource: 'contact/countries',
  });
};

/**
 * @returns {Promise<*>}
 */
export const fetchInvestorTypes = () => {
  return request({
    resource: 'contact/investor_types',
  });
};

/**
 * @param {Object} data contact object
 *
 * @returns {Promise<*>}
 */
export const saveContact = async (data) => {
  return await request({
    resource: 'pages/contact',
    method: Methods.POST,
    data,
  });
};
