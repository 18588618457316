import React, { Component } from 'react';
import Env from 'env';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Button, MegaMenu, headerContent, ProfileDropdown, Svg } from 'modules/shared';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './EdgePublicHeader.scss';
import PropTypes from 'prop-types';
import { getEdgeLanding } from 'common';

class EdgeHeader extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    settings: PropTypes.object,
    showEdgeLoginForm: PropTypes.bool,
    onLoginToggle: PropTypes.func,
    toggleLoginForm: PropTypes.func,
    onToggle: PropTypes.func,
    showMegaMenu: PropTypes.bool,
    windowWidth: PropTypes.number,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { profile } = this.props;
    return [
      <div className="navbar-container" key="navbar-container">
        <Header>
          <div className="header-title" itemScope itemType="http://schema.org/Organization">
            <NavLink to={`/${Env.EDGE_BASE_PATH}`} itemProp="url">
              <Svg name="theanalystedge-logo-white" itemProp="logo" />
            </NavLink>
          </div>
          <i className="toggle-icon icon-hamburger light" onClick={this.props.onToggle} />
          <ProfileDropdown />
          <div className="nav-header">
            <NavLink
              className="header-link"
              to={`/${Env.EDGE_BASE_PATH}/our-services`}
              activeClassName="active">
              <FormattedMessage id="EDGE.HEADER.OUR_SERVICES" />
            </NavLink>
            <NavLink
              className="header-link"
              to={`/${Env.EDGE_BASE_PATH}/pricing`}
              activeClassName="active">
              <FormattedMessage id="EDGE.HEADER.PRICING" />
            </NavLink>
            <NavLink
              className="header-link"
              to={`/${Env.EDGE_BASE_PATH}/edge-insights`}
              activeClassName="active">
              <FormattedMessage id="EDGE.HEADER.EDGE_INSIGHTS" />
            </NavLink>
            {!profile.id && (
              <Button
                onClick={() => this.props.toggleLoginForm('showEdgeLoginForm')}
                moduleType="edge">
                <FormattedMessage id="MAIN.CLIENT_LOGIN_HEADER" />
              </Button>
            )}
            {profile.id && (
              <NavLink className="my-research" to={`/${getEdgeLanding(this.props.settings)}/app`}>
                <Button>
                  <FormattedMessage id="MAIN.GO_MY_RESEARCH" />
                </Button>
              </NavLink>
            )}
          </div>
        </Header>
        <MegaMenu
          showMenu={this.props.showMegaMenu}
          toggleMenu={this.props.onToggle}
          toggleAnalystLoginForm={() => this.props.toggleLoginForm('showAnalystLoginForm')}
          toggleEdgeLoginForm={() => this.props.toggleLoginForm('showEdgeLoginForm')}
        />
      </div>,
    ];
  }
}

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    settings: app.userSettings,
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(style)(headerContent(EdgeHeader))));
