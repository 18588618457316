import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import { BaseDataList } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';
import { deleteSector, getSectors } from 'modules/analyst/actions/sectors';
import { match } from 'common';
import { table, Status } from 'modules/shared';

class AnalystSectorsList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    basePath: PropTypes.string,
  };

  deleteSector = async (id) => {
    try {
      await this.props.deleteItem(id);
    } catch (e) {
      if (e.response && e.response.data.error && e.response.status === Status.BAD_REQUEST) {
        const sectorUseList = e.response.data?.values.map((value, index) => (
          <>
            <FormattedMessage key={index} id={`WARNING.${value}`} />
            {e.response.data.values.length !== index + 1 && ', '}
          </>
        ));
        toast.warn(
          <>
            <FormattedMessage id={e.response.data.error} />
            {sectorUseList}
          </>
        );
      }
    }
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <BaseDataList
        match={this.props.match}
        fetchData={this.props.fetchData}
        onSearch={this.props.onSearch}
        deleteItem={this.deleteSector}
        data={this.props.data}
        pages={this.props.pages}
        loading={this.props.loading}
        search={this.props.search}
        basePath={this.props.basePath}
        addNewLabel={<FormattedMessage id="ADMIN.SECTORS.NEW" />}
        pageTitle={<FormattedMessage id="ADMIN.SECTORS.TITLE" />}
        hiddenFields={['title', 'slug']}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDataWs: getSectors, deleteItemWs: deleteSector }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(table({ nofilter: true, nopaginate: false })(AnalystSectorsList));
