import { GET_ALL_BLOG_TAGS, GET_BLOG_TAG } from '../actions/blogtags';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const blogtags = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_BLOG_TAGS: {
      return action.payload || {};
    }
    case GET_BLOG_TAG: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default blogtags;
