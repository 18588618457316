import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Roles, CanAccess } from 'modules/shared';

/**
 * @param {Array} routes Router config array.
 * @param {Object} extraProps Props to be added to every Route component.
 * @param {Object} switchProps Props to be added to the wrapping Switch component.
 * @param {Object} accessCheck Props to be added to check access by role.
 * @param {Array} allowedRoles Array of roles allowed to access the route.
 *
 * @returns {JSX.Element | null} - Rendered Switch component with corresponding routes.
 */
const renderRoutes = (
  routes,
  extraProps = {},
  switchProps = {},
  accessCheck = true,
  allowedRoles = [Roles.AnalystEditor, Roles.AnalystAdmin]
) =>
  routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) =>
        !route.redirect ? (
          <Route
            sensitive
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={(props) => {
              if (props.staticContext) {
                props.staticContext.status = route.status;
              }
              return accessCheck ? (
                <CanAccess
                  allowedRoles={route.role || allowedRoles}
                  unauthenticatedComponent={<Redirect to="/app" />}>
                  <route.component {...props} {...extraProps} route={route} />
                </CanAccess>
              ) : (
                <route.component {...props} {...extraProps} route={route} />
              );
            }}
          />
        ) : (
          <Redirect from={route.from} to={route.to} key={route.key} />
        )
      )}
    </Switch>
  ) : null;

export default renderRoutes;
