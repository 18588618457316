import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useHistory, useLocation } from 'react-router-dom';

import { PostViewFilterItems, FilterItemsType } from 'modules/shared';
import * as postsService from 'modules/shared/services/posts';
import { stringify, parse } from 'common/queryString';

import styles from './PostViewFilter.scss';

export interface FilterOnClickItem {
  key: string;
  value: string[] | string;
}

interface PostViewFilterProps {
  onSelected: (item: FilterOnClickItem) => void;
}

const PostViewFilter: FC<PostViewFilterProps> = ({ onSelected }) => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    const fetchFilters = async () => {
      const res = await postsService.fetchPostsFilters();
      setFilters(res);
    };

    fetchFilters();
  }, []);

  const onClickItem = (filter: FilterOnClickItem) => {
    const queryParams = stringify({
      ...parse(location.search),
      [filter.key]: filter.value,
    });
    history.replace(`${location.pathname}?${queryParams}`);
    onSelected(filter);
  };

  return filters ? (
    <div className="post-view-filter">
      <PostViewFilterItems
        title={<FormattedMessage id="ANALYST.ALL_RESEARCH.FILTER_TOPIC" />}
        filterKey="topic"
        filterType={FilterItemsType.SINGLE}
        filters={filters.topic}
        onClickItem={onClickItem}
      />
      <PostViewFilterItems
        title={<FormattedMessage id="ANALYST.ALL_RESEARCH.FILTER_RECOMMENDATION" />}
        filterKey="recommendations"
        filterType={FilterItemsType.MULTIPLE}
        filters={filters.recommendation}
        onClickItem={onClickItem}
      />
    </div>
  ) : null;
};

export default withStyles(styles)(PostViewFilter);
