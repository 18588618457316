import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionIcon, Button, EditorTitle, FieldGroup } from 'modules/admin/shared';

import { getSurveys } from 'modules/analyst/actions/surveys';
import { table } from 'modules/shared';
import { getDSTDateString, match, SurveyStatusLabel } from 'common';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Col, Grid, Row } from 'react-bootstrap';
import ReactTable from 'react-table';

class SurveysList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    basePath: PropTypes.string,
    toLinkName: PropTypes.string,
    disabled: PropTypes.bool,
  };

  onSearch = (e) => {
    this.props.onSearch(e);
    this.setState({ search: e.target.value });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { pages, loading, search, fetchData, toLinkName, disabled } = this.props;
    const data = disabled ? this.props.data.filter(({ canModify }) => !canModify) : this.props.data;
    return (
      <div>
        <EditorTitle
          title={<FormattedMessage id="ADMIN.SURVEYS.TITLE" />}
          viewLink={!disabled ? `/survey` : null}>
          {!disabled ? (
            <div className="d-flex align-center">
              <NavLink to={`${this.props.match.path}new`}>
                <Button>
                  <FormattedMessage id="ADMIN.SURVEYS.NEW_SURVEY" />
                </Button>
              </NavLink>
            </div>
          ) : null}
        </EditorTitle>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <FieldGroup onChange={this.onSearch} placeholderId="MAIN.SEARCH" value={search} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.NAME" />,
                    accessor: 'name',
                    Cell: ({ original }) => (
                      <NavLink
                        to={`${this.props.match.path}${original.id}/${toLinkName || 'edit'}`}>
                        {original.name}
                      </NavLink>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.STATUS" />,
                    accessor: 'is_active',
                    Cell: ({ original }) =>
                      SurveyStatusLabel[original.is_active] &&
                      SurveyStatusLabel[original.is_active].label,
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.POSTS.PUBLISHED_AT" />,
                    accessor: 'published_at',
                    Cell: ({ original }) => getDSTDateString(original.published_at),
                  },
                  {
                    Header: '',
                    maxWidth: 100,
                    Cell: ({ original }) => (
                      <div className="d-flex justify-evenly">
                        <div>
                          {original.canModify ? (
                            <NavLink
                              to={`${this.props.match.path}${original.id}/${toLinkName || 'edit'}`}>
                              <ActionIcon className="fa fa-edit" />
                            </NavLink>
                          ) : null}
                        </div>
                      </div>
                    ),
                    sortable: false,
                  },
                ]}
                manual
                data={data}
                resizable={false}
                pages={pages}
                loading={loading}
                onFetchData={fetchData}
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                style={{
                  height: 'calc(100vh - 217px)',
                }}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDataWs: getSurveys,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(table({ nofilter: true, nopaginate: false })(SurveysList));
