import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { EditorTitle, FieldGroup, Delete, Button } from 'modules/admin/shared';
import { match } from 'common';

class BaseDataList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    showAddNewItem: PropTypes.bool,
    search: PropTypes.string,
    basePath: PropTypes.string,
    hiddenFields: PropTypes.array,
    pageTitle: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    addNewLabel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  };

  static defaultProps = {
    hiddenFields: ['name'],
    showAddNewItem: true,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      data,
      pages,
      loading,
      search,
      fetchData,
      deleteItem,
      onSearch,
      hiddenFields,
    } = this.props;
    return (
      <div>
        <EditorTitle title={this.props.pageTitle}>
          {this.props.showAddNewItem ? (
            <NavLink to={`${this.props.match.path}new`}>
              <Button>{this.props.addNewLabel}</Button>
            </NavLink>
          ) : null}
        </EditorTitle>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <FieldGroup onChange={onSearch} placeholderId="MAIN.SEARCH" value={search} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.NAME" />,
                    accessor: hiddenFields.includes('title') ? 'name' : 'title',
                  },
                  !this.props.hiddenFields.includes('slug')
                    ? {
                        Header: <FormattedMessage id="ADMIN.SLUG" />,
                        accessor: 'slug',
                      }
                    : null,
                  !this.props.hiddenFields.includes('description')
                    ? {
                        Header: <FormattedMessage id="ADMIN.DESCRIPTION" />,
                        accessor: 'description',
                      }
                    : null,
                  {
                    Header: '',
                    maxWidth: 100,
                    Cell: ({ original }) => (
                      <div className="d-flex justify-evenly">
                        <div>
                          <NavLink to={`${this.props.match.path}${original.id}/edit`}>
                            <span className="action-icon fa fa-edit" />
                          </NavLink>
                        </div>
                        <Delete onSubmit={() => deleteItem(original.id)} />
                      </div>
                    ),
                    sortable: false,
                  },
                ].filter((col) => col)}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                resizable={false}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={fetchData} // Request new data when things change
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                style={{
                  height: 'calc(100vh - 217px)',
                }}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default BaseDataList;
