import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';

import styles from './CheckboxField.scss';

const CheckboxField = ({ field, form, label, className, analystStyle, ...props }) => {
  const touched = get(form.touched, field.name);
  const errors = get(form.errors, field.name);

  const onChange = () => {
    form.setFieldTouched(field.name, true, false);
    form.setFieldValue(field.name, !field.value, false);
  };

  return (
    <>
      <FormGroup
        className={classNames('checkbox-container', className, { 'analyst-style': analystStyle })}>
        <input type="checkbox" onChange={onChange} checked={field.value} {...props} />
        <span className="checkmark" onClick={onChange}></span>
        {label && <ControlLabel>{label}</ControlLabel>}
      </FormGroup>
      <div className="error-message low text-left">{touched && errors ? errors : null}</div>
    </>
  );
};

CheckboxField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  analystStyle: PropTypes.bool,
};

export default withStyles(styles)(CheckboxField);
