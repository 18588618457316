import {
  GET_ARTICLE_ANALYTICS,
  GET_SUMMARY_ANALYTICS,
  GET_USER_ANALYTICS,
  GET_USER_ANALYTICS_HEADER,
  GET_USER_ANALYTICS_DOWNLOADS,
  GET_USER_ANALYTICS_VIEWS,
  GET_USER_ANALYTICS_LOGINS,
  RESET_CURRENT_USER_ANALYTICS,
  GET_ARTICLE_ANALYTICS_USERS,
  GET_ARTICLE_ANALYTICS_DOWNLOADS,
  GET_ARTICLE_ANALYTICS_HEADER,
  RESET_CURRENT_ARTICLE_ANALYTICS,
} from '../actions/analytics';

/**
 * @param {Object} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Object} Modified state.
 */
const analytics = (state = { currentUser: {}, currentArticle: {} }, action) => {
  switch (action.type) {
    case GET_ARTICLE_ANALYTICS: {
      return { ...state, article: action.payload };
    }
    case GET_SUMMARY_ANALYTICS: {
      return { ...state, summary: action.payload };
    }
    case GET_USER_ANALYTICS: {
      return { ...state, user: action.payload };
    }
    case RESET_CURRENT_USER_ANALYTICS: {
      return { ...state, currentUser: {} };
    }
    case GET_USER_ANALYTICS_HEADER: {
      return { ...state, currentUser: { ...state.currentUser, header: action.payload } };
    }
    case GET_USER_ANALYTICS_DOWNLOADS: {
      return { ...state, currentUser: { ...state.currentUser, downloads: action.payload } };
    }
    case GET_USER_ANALYTICS_VIEWS: {
      return { ...state, currentUser: { ...state.currentUser, views: action.payload } };
    }
    case GET_USER_ANALYTICS_LOGINS: {
      return { ...state, currentUser: { ...state.currentUser, logins: action.payload } };
    }
    case RESET_CURRENT_ARTICLE_ANALYTICS: {
      return { ...state, currentArticle: {} };
    }
    case GET_ARTICLE_ANALYTICS_HEADER: {
      return { ...state, currentArticle: { ...state.currentArticle, header: action.payload } };
    }
    case GET_ARTICLE_ANALYTICS_DOWNLOADS: {
      return { ...state, currentArticle: { ...state.currentArticle, downloads: action.payload } };
    }
    case GET_ARTICLE_ANALYTICS_USERS: {
      return { ...state, currentArticle: { ...state.currentArticle, users: action.payload } };
    }
    default:
      return state;
  }
};

export default analytics;
