import { GET_RECOMMENDATIONS } from '../actions/recommendations';

/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const recommendations = (state = {}, action) => {
  switch (action.type) {
    case GET_RECOMMENDATIONS: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default recommendations;
