import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';

import withStyles from 'isomorphic-style-loader/withStyles';
import style from './Header.scss';
/**
 * @param {Object} props Props object
 *
 * @returns {JSX.Element} - Header component
 */
const Header = ({ children, className, light, ...props }) => {
  return (
    <Navbar className={classNames(className, 'app-header', { light })} {...props}>
      {children}
    </Navbar>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  light: PropTypes.bool,
};

export default withStyles(style)(Header);
