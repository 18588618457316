import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';

import {
  Downloads,
  MoreFeedbackText,
  LikeQuestions,
  FeedbackExplanation,
  LikeQuestionPages,
} from 'modules/shared';
import { BuyTypes, formatPercent, VoteTypes, Buys } from 'common';
import Env from 'env';

/**
 *
 * @param {Object} company Company Object.
 * @returns {JSX.Element | null}
 */
const CompanyInfo = ({ company, updateCompany }) => {
  /**
   * @param {Object} saveCompany saveCompany data Object.
   * @returns {void}
   */
  const updateDataByAnswer = async (saveCompany) => {
    return updateCompany(saveCompany);
  };

  const showCompanyRecommendation = company.buy_type !== Buys.NO_RECOMMENDATION;
  const showCompanyFeedback = showCompanyRecommendation && company.has_posts;
  const hasAnwserOnQuestion =
    company &&
    Array.isArray(company.like_questions) &&
    company.like_questions.some(({ user_vote }) => user_vote.vote !== VoteTypes.undefined);

  return (
    showCompanyRecommendation && (
      <Row className="author">
        {showCompanyRecommendation ? (
          <Col xs={12}>
            <div className="recommendation">
              <div className="buy-type mb-2">{BuyTypes[company.buy_type].name}</div>
              <div className="d-flex flex-wrap">
                <div className="price-target d-flex mb-2">
                  <div className="title mr-1">
                    <FormattedMessage id="MAIN.PRICE_TARGET" />:
                  </div>
                  <div className="value">{company.price_target}</div>
                </div>
                {company.percent && (
                  <div className="percent d-flex">
                    <div className="title mr-1">
                      <FormattedMessage id="MAIN.FORECAST_RETURN" />:
                    </div>
                    <div className="value">{formatPercent(company.percent)}</div>
                  </div>
                )}
                <Downloads downloads={company.downloads} hideHeader />
              </div>
            </div>
          </Col>
        ) : null}
        {showCompanyFeedback ? (
          <Col xs={12}>
            <div className="feedback">
              <LikeQuestions
                data={company}
                page={LikeQuestionPages.COMPANY}
                updateDataByAnswer={updateDataByAnswer}
                topDivider={false}
                starIconAtQuestionDescription
                questionDescription={
                  <Fragment>
                    <FormattedHTMLMessage
                      id="ANALYST.SENTIMENT_TRACKER_DESCRIPTION_COMPANY"
                      values={{ company: company.title }}
                    />
                  </Fragment>
                }
                questionLabelMedium
                labelsNoTopMargin
                hideFeedbackPopup
              />
              {!company.survey_taken ? (
                <MoreFeedbackText
                  className="mt-2"
                  noBackground
                  text={
                    <FormattedHTMLMessage
                      id="MAIN.MORE_FEEDBACK_COMPANY"
                      values={{ surveyLink: Env.SURVEY_LINK }}
                    />
                  }
                  small
                />
              ) : null}
              {!hasAnwserOnQuestion ? (
                <FeedbackExplanation
                  explanation={<FormattedMessage id="MAIN.FEEDBACK_EXPLANATION" />}
                  small
                  lightFontWeight
                  topMargin
                />
              ) : null}
            </div>
          </Col>
        ) : null}
      </Row>
    )
  );
};

CompanyInfo.propTypes = {
  company: PropTypes.object,
  updateCompany: PropTypes.func,
};

export default CompanyInfo;
