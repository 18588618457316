import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputs } from 'modules/admin/shared/components';

class Text extends Component {
  static componentType = 'text';
  static blockName = 'Text';
  static icon = 'fa fa-font';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    data: PropTypes.object,
  };

  state = {
    username: '',
    email: '',
  };

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.setState(this.props.data);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const { username, email } = this.state;
      this.props.onChange({ username, email });
    });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <FormInputs
        ncols={['col-md-5', 'col-md-3', 'col-md-4']}
        properties={[
          {
            label: 'Username',
            type: 'text',
            bsClass: 'form-control',
            placeholder: 'Username',
            name: 'username',
            value: this.state.username,
            onChange: this.onChange,
          },
          {
            label: 'Email address',
            type: 'email',
            bsClass: 'form-control',
            placeholder: 'Email',
            name: 'email',
            value: this.state.email,
            onChange: this.onChange,
          },
        ]}
      />
    );
  }
}

export default Text;
