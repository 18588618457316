import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * @param {Array} companies Collection of downloadable files.
 * @param {Array} title Collection of downloadable files.
 * @param {Array} basePath Collection of downloadable files.
 * @returns {null | JSX.Element}
 * @constructor
 */
const CompanySection = ({ companies, title, basePath }) => {
  return companies && companies.length ? (
    <div className="info-section">
      <div className="header">{title}</div>
      <div className="content">
        {companies.map((company) => {
          return (
            <Link key={company.id} to={`/app/company/${company.id}/${company.slug}`}>
              <i className="fa fa-plus" />
              {company.title}
            </Link>
          );
        })}
      </div>
    </div>
  ) : null;
};

CompanySection.propTypes = {
  companies: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  basePath: PropTypes.string,
};

export default CompanySection;
