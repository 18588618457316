import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import firstBy from 'thenby';
import { toast } from 'react-toastify';
import { Grid, Row, Col } from 'react-bootstrap';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { v1 as uuid } from 'uuid';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  DragHandle,
  AddPlaceholder,
  Button,
  EditorTitle,
  Select,
  Delete,
  ErrorIcon,
} from 'modules/admin/shared';
import { CompanyStatusRaw, sortByStatus, Buys } from 'common';
import BuyTypeTitle from './BuyTypeTitle';

import styles from './CurrentRecommendations.scss';

const SortableRow = SortableElement(
  ({ recommendation, companies, onChange, onDelete, type, selectedCompanies }) => {
    const company = companies.find(({ id }) => id === recommendation.company_id) || {};

    return (
      <Row
        className={classNames('d-flex align-center sortable-row recommendation-row', {
          error: !recommendation.company_id,
        })}>
        <Col xs={1} className="p-0">
          <DragHandle />
        </Col>
        <Col xs={2} className="d-flex justify-end align-center h-100 error-icon-container">
          <ErrorIcon
            show
            message={<FormattedMessage id="ADMIN.HIGHEST_CONVICTION_IDEAS.REQUIRED_COMPANY" />}
          />
        </Col>
        <Col xs={8}>
          <Select
            className="w-100"
            name="company"
            onChange={(e) => onChange(e, recommendation)}
            value={company.id ? { label: company.title, value: company.id } : ''}
            options={companies
              .filter((c) =>
                typeof type !== 'string' && type.length > 0
                  ? type.find((typeLabel) => typeLabel === c.buy_type)
                  : c.buy_type === type
              )
              .map((c) => ({
                value: c.id,
                label: c.title,
                disabled: selectedCompanies.includes(c) || c.status !== CompanyStatusRaw.active,
              }))
              .sort(firstBy(sortByStatus).thenBy('label'))}
          />
        </Col>
        <Col xs={1}>
          <Delete onSubmit={() => onDelete(recommendation)} />
        </Col>
      </Row>
    );
  }
);

const SortableRowContainer = SortableContainer(
  ({
    recommendations,
    companies,
    addRow,
    onChange,
    onDelete,
    isInvalidCompany,
    type,
    selectedCompanies,
  }) => {
    return (
      <div className="sortable-container">
        {recommendations.map((rec, i) => (
          <SortableRow
            key={rec.id}
            recommendation={rec}
            companies={companies}
            index={i}
            type={type}
            onChange={onChange}
            onDelete={onDelete}
            selectedCompanies={selectedCompanies}
          />
        ))}
        <Row>
          <AddPlaceholder inverse onClick={addRow} />
        </Row>
      </div>
    );
  }
);

class CurrentRecommendations extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getRecommendations: PropTypes.func.isRequired,
    saveRecommendations: PropTypes.func.isRequired,
    companies: PropTypes.array,
    recommendations: PropTypes.object,
  };

  state = {
    recommendations: Object.keys(Buys).reduce((prev, key) => ({ ...prev, [key]: [] }), {}),
    loading: true,
    saving: false,
    validated: false,
  };

  /**
   * @description Get the initial data for the component.
   */
  async componentDidMount() {
    this.props.showLoader();
    await Promise.all([this.props.getCompanies(), this.props.getRecommendations()]);
    this.props.hideLoader();

    this.setState({
      loading: false,
      recommendations: { ...this.state.recommendations, ...this.props.recommendations },
    });
  }

  addRecommendation = (type) => () => {
    this.setState((prevState) => ({
      recommendations: {
        ...prevState.recommendations,
        [type]: [
          ...prevState.recommendations[type],
          { company_id: '', id: uuid(), position: prevState.recommendations[type].length + 1 },
        ],
      },
      validated: false,
    }));
  };

  onChange = (type) => (e, rec) => {
    this.setState((prevState) => {
      const recommendations = [...prevState.recommendations[type]];
      const index = recommendations.findIndex(({ id }) => id === rec.id);

      recommendations[index] = {
        ...recommendations[index],
        company_id: e.target.value ? e.target.value.value : '',
      };
      return {
        recommendations: { ...prevState.recommendations, [type]: recommendations },
        validated: false,
      };
    });
  };

  onSortEnd = (type) => ({ oldIndex, newIndex }) => {
    this.setState((prevState) => {
      const recommendations = [...prevState.recommendations[type]];
      return {
        recommendations: {
          ...prevState.recommendations,
          [type]: arrayMove(recommendations, oldIndex, newIndex).map((rec, i) => ({
            ...rec,
            position: i + 1,
          })),
        },
        validated: false,
      };
    });
  };

  deleteRecommendation = (type) => (rec) => {
    this.setState((prevState) => ({
      recommendations: {
        ...prevState.recommendations,
        [type]: prevState.recommendations[type].filter(({ id }) => id !== rec.id),
      },
      validated: false,
    }));
  };

  save = async () => {
    if (this.validate()) {
      try {
        this.setState({ saving: true });
        const recommendations = []
          .concat(...Object.values(this.state.recommendations))
          .map(({ company_id, position }) => ({ company_id, position }));

        await this.props.saveRecommendations(recommendations);
        toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
      } finally {
        this.setState({ saving: false, validated: false });
      }
    }
  };

  validate = () => {
    const res = [].concat(...Object.values(this.state.recommendations)).reduce((valid, rec) => {
      if (!rec.company_id) {
        return false;
      }

      return valid;
    }, true);
    this.setState({ validated: true });
    return res;
  };

  selectedCompanies = (type) => {
    const companies = this.props.companies.filter(({ buy_type }) => buy_type === type);

    return companies.filter((company) =>
      this.state.recommendations[type].find(({ company_id }) => company.id === company_id)
    );
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { strong_buy, buy, short } = this.state.recommendations;
    return (
      <div
        className={classNames('current-recommendations editor-error', {
          validated: this.state.validated,
        })}>
        <EditorTitle title={<FormattedMessage id="MAIN.CURRENT_RECOMMENDATIONS" />}>
          {!this.state.loading ? (
            <Button onClick={this.save} loading={this.state.saving}>
              <FormattedMessage id="ADMIN.SAVE" />
            </Button>
          ) : null}
        </EditorTitle>
        {!this.state.loading ? (
          <Grid fluid className="editor-grid">
            <Row>
              <Col md={4}>
                <Row>
                  <Col xs={12}>
                    <BuyTypeTitle
                      title={<FormattedMessage id="MAIN.BUY_TYPES.STRONG_BUY" />}
                      recommendations={strong_buy}
                    />
                  </Col>
                  <Col xs={12}>
                    <SortableRowContainer
                      recommendations={strong_buy}
                      companies={this.props.companies}
                      useWindowAsScrollContainer
                      useDragHandle
                      delay={100}
                      type={Buys.STRONG_BUY}
                      selectedCompanies={this.selectedCompanies(Buys.STRONG_BUY)}
                      onDelete={this.deleteRecommendation(Buys.STRONG_BUY)}
                      onSortEnd={this.onSortEnd(Buys.STRONG_BUY)}
                      onChange={this.onChange(Buys.STRONG_BUY)}
                      addRow={this.addRecommendation(Buys.STRONG_BUY)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col xs={12}>
                    <BuyTypeTitle
                      title={<FormattedMessage id="MAIN.BUY_TYPES.BUY" />}
                      recommendations={buy}
                    />
                  </Col>
                  <Col xs={12}>
                    <SortableRowContainer
                      recommendations={buy}
                      companies={this.props.companies}
                      useWindowAsScrollContainer
                      useDragHandle
                      delay={100}
                      type={Buys.BUY}
                      selectedCompanies={this.selectedCompanies(Buys.BUY)}
                      onDelete={this.deleteRecommendation(Buys.BUY)}
                      onSortEnd={this.onSortEnd(Buys.BUY)}
                      onChange={this.onChange(Buys.BUY)}
                      addRow={this.addRecommendation(Buys.BUY)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col xs={12}>
                    <BuyTypeTitle
                      title={<FormattedMessage id="MAIN.BUY_TYPES.SHORT" />}
                      recommendations={short}
                    />
                  </Col>
                  <Col xs={12}>
                    <SortableRowContainer
                      recommendations={short}
                      companies={this.props.companies}
                      useWindowAsScrollContainer
                      useDragHandle
                      delay={100}
                      type={[Buys.SHORT, Buys.SELL]}
                      selectedCompanies={this.selectedCompanies(Buys.SHORT)}
                      onDelete={this.deleteRecommendation(Buys.SHORT)}
                      onSortEnd={this.onSortEnd(Buys.SHORT)}
                      onChange={this.onChange(Buys.SHORT)}
                      addRow={this.addRecommendation(Buys.SHORT)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(CurrentRecommendations);
