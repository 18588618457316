import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withRouter } from 'react-router-dom';

import { RadioButton, Button, TextInput } from 'modules/shared';
import { match, history, location, QuestionTypes } from 'common';

import styles from './Survey.scss';

class Survey extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getLatestSurvey: PropTypes.func.isRequired,
    takeSurvey: PropTypes.func.isRequired,
    survey: PropTypes.object.isRequired,
    showCount: PropTypes.number,
    intl: PropTypes.object,
    loading: PropTypes.bool,
    match,
    history,
    location,
  };

  state = {
    questions: [],
    answered: false,
    freeTextQuestionsCount: 0,
  };

  /**
   * @description Gets the data for the page.
   */
  async componentDidMount() {
    this.props.showLoader();
    const survey = await this.props.getLatestSurvey();
    const freeTextQuestionsCount =
      survey && survey.questions.filter(({ type }) => type === QuestionTypes.freeText).length;
    this.setState({ freeTextQuestionsCount });
    this.props.hideLoader();
  }

  takeSurvey = async () => {
    await this.props.takeSurvey({
      id: this.props.survey.id,
      questions: this.getFullyFilledQuestions(),
    });
    this.setState({ answered: true });
  };

  /**
   * @returns {array} questions object with fully filled answers, fill empty free text with emtpy string
   * */
  getFullyFilledQuestions = () => {
    const questions = [...this.state.questions];
    if (questions.length !== this.props.survey.questions.length) {
      this.props.survey.questions.forEach(({ id }) => {
        if (!questions.find((answer) => answer.id === id)) {
          questions.push({ id, result: '' });
        }
      });
    }
    return questions;
  };

  onChange = (e, id) => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    const type = e.target.type;

    const valueItem = { id: +name, result: type === 'checkbox' ? checked : value };

    const questions = [...this.state.questions];
    const questionIndex = questions.findIndex((answer) => answer.id === id);
    if (questionIndex > -1) {
      questions[questionIndex] = valueItem;
    } else {
      questions.push(valueItem);
    }

    this.setState({ questions });
  };

  goBackToHomePage = () => {
    this.props.history.push('/');
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { survey, showCount } = this.props;
    const { questions, answered, freeTextQuestionsCount } = this.state;

    return (
      <div className="survey-container">
        <FormattedMessage id="ADMIN.SURVEYS.SURVEY">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <Grid>
          {showCount ? null : answered ? (
            <Row className="survey-card main-card text-center">
              <FormattedMessage id="ADMIN.SURVEYS.ANSWERED_SURVEY" />
              <div>
                <Button
                  moduleType="analyst mt-2"
                  textColor="dark"
                  type="button"
                  onClick={this.goBackToHomePage}
                  className="short-width small"
                  wrap>
                  <FormattedMessage id="ADMIN.SURVEYS.GO_BACK_TO_ANALYST_FROM_SURVEY" />
                </Button>
              </div>
            </Row>
          ) : survey && survey.hasResult ? (
            <Row className="survey-card main-card text-center">
              <FormattedHTMLMessage id="ADMIN.SURVEYS.DENIED_TO_TAKE_SURVEY_MORE_TIMES" />
            </Row>
          ) : survey && survey.id ? (
            <Fragment>
              <Row className="survey-card main-card">
                <Col className="survey-title">
                  <h2>{survey.name}</h2>
                </Col>
                <Col className="survey-description">
                  <h5>{survey.description}</h5>
                </Col>
              </Row>
              <form noValidate>
                {survey && survey.questions
                  ? survey.questions.map(
                      ({ question, id, min_result, max_result, min_label, max_label, type }) => {
                        const options = [];
                        for (let i = min_result; i <= max_result; i++) {
                          options.push(i);
                        }
                        const questionValue = questions.find((answer) => answer.id === id);
                        const answeredValue = questionValue && questionValue.result;

                        return (
                          <Row key={id} className="survey-card">
                            <Col className="survey-question">
                              <h5>{question}</h5>
                            </Col>
                            {type === QuestionTypes.radioButtons ? (
                              <Col className="d-flex justify-between small-screen">
                                <span className="survey-label">{min_label}</span>
                                <span className="survey-label">{max_label}</span>
                              </Col>
                            ) : null}
                            {type === QuestionTypes.radioButtons ? (
                              <Col className="survey-answer">
                                <span className="survey-label large-screen">{min_label}</span>
                                {options.map((value) => (
                                  <RadioButton
                                    className="analyst"
                                    key={value}
                                    name={id}
                                    value={value}
                                    label={value}
                                    onChange={(e) => this.onChange(e, id)}
                                    checked={+answeredValue === +value}
                                    vertical
                                  />
                                ))}
                                <span className="survey-label large-screen">{max_label}</span>
                              </Col>
                            ) : (
                              <Col>
                                <TextInput
                                  name={id}
                                  className="square-style small analyst"
                                  white
                                  inlineError
                                  onChange={(e) => this.onChange(e, id)}
                                  value={answeredValue || ''}
                                />
                              </Col>
                            )}
                          </Row>
                        );
                      }
                    )
                  : null}
              </form>
              <Row className="survey-button">
                <Col>
                  <Button
                    moduleType="analyst"
                    textColor="dark"
                    type="button"
                    onClick={this.takeSurvey}
                    className="short-width small"
                    disabled={
                      questions &&
                      survey &&
                      survey.questions &&
                      questions.length < survey.questions.length - freeTextQuestionsCount
                    }>
                    <FormattedMessage id="ADMIN.SURVEYS.SEND" />
                  </Button>
                </Col>
              </Row>
            </Fragment>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(withRouter(Survey)));
