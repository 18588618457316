import * as PressService from 'modules/main/services/press';
import { Status } from 'modules/shared/util/http';

export const GET_PRESSES = 'GET_PRESSES';
export const GET_PRESSES_SECTION_1 = 'GET_PRESSES_SECTION_1';
export const GET_PRESSES_SECTION_2 = 'GET_PRESSES_SECTION_2';
export const DELETE_PRESS = 'DELETE_PRESS';
export const GET_PRESS = 'GET_PRESS';
export const SAVE_PRESS = 'SAVE_PRESS';
export const MODIFY_PRESS = 'MODIFY_PRESS';
export const SAVE_PRESS_FEATURED_IMAGE = 'SAVE_PRESS_FEATURED_IMAGE';
export const SAVE_PRESS_CONTENT_IMAGE = 'SAVE_PRESS_CONTENT_IMAGE';

/**
 * @description Gets one page from the post.
 * @param {Object} options Search options.
 * @returns {Function}
 */
export const getPresses = (options) => async (dispatch) => {
  const presses = await PressService.getPresses(options);

  dispatch({
    type: !options.section ? GET_PRESSES : getTypeBySection(options.section),
    payload: presses,
  });

  return presses;
};

/**
 * @param {number} section id
 * @returns {string} type of press
 * */
const getTypeBySection = (section) => {
  switch (section) {
    case 1: {
      return GET_PRESSES_SECTION_1;
    }
    case 2: {
      return GET_PRESSES_SECTION_2;
    }
    default: {
      return '';
    }
  }
};

/**
 * @param {number} id press Id
 * @returns {Function}
 */
export const deletePress = (id) => async (dispatch) => {
  const press = await PressService.deletePress(id);

  dispatch({
    type: DELETE_PRESS,
    payload: press,
  });

  return press;
};

/**
 * @description Gets the press for the ID:
 * @param {number} id press ID.
 * @returns {Function}
 */
export const getPress = (id) => async (dispatch) => {
  try {
    const press = await PressService.getPress(id);

    dispatch({
      type: GET_PRESS,
      payload: press,
    });

    return press;
  } catch (e) {
    if (e.response && e.response.status === Status.NOT_FOUND) {
      e.ignoreToast = true;
    }
    throw e;
  }
};

/**
 * @param {Object} data press data.
 * @returns {Function}
 */
export const savePress = (data) => async (dispatch) => {
  const press = await PressService.savePress(data);

  dispatch({
    type: SAVE_PRESS,
    payload: press,
  });

  return press;
};

/**
 * @param {number} id press Id
 * @param {Object} data press data.
 * @returns {Function}
 */
export const modifyPress = (id, data) => async (dispatch) => {
  const press = await PressService.modifyPress(id, data);

  dispatch({
    type: MODIFY_PRESS,
    payload: press,
  });

  return press;
};

/**
 * @param {number} id press Id
 * @param {Object} data press data.
 * @returns {Function}
 */
export const saveFeaturedImage = (id, data) => async (dispatch) => {
  const press = await PressService.saveFeaturedImage(id, data);

  dispatch({
    type: SAVE_PRESS_FEATURED_IMAGE,
    payload: press,
  });

  return press;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveContentImage = (id, data) => async (dispatch) => {
  const press = await PressService.saveContentImage(id, data);

  dispatch({
    type: SAVE_PRESS_CONTENT_IMAGE,
    payload: press,
  });

  return press;
};
