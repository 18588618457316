import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './EditorTitle.scss';
import { Link as DownloadLink } from 'modules/shared';
import { getFileIcon } from 'common';

class EditorTitle extends Component {
  static propTypes = {
    children: PropTypes.node,
    viewLink: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    toolboxOpen: PropTypes.bool,
    pdf: PropTypes.object,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className={classNames('editor-title', { 'toolbox-open': this.props.toolboxOpen })}>
        <div className="content">
          <div className="d-flex align-center">
            <span className="title">{this.props.title}</span>
            {this.props.viewLink ? (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={this.props.viewLink}
                className="view-link">
                <FormattedMessage id="MAIN.VIEW" />
              </Link>
            ) : null}
            {this.props.pdf ? (
              <div className="download-link">
                <DownloadLink linkId={this.props.pdf.id}>
                  <i className={getFileIcon(this.props.pdf)} />
                  <FormattedMessage id="ADMIN.POSTS.DOWNLOAD_PDF" />
                </DownloadLink>
              </div>
            ) : null}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EditorTitle);
