import SampleResearch from '../components/public-website/sample-research/SampleResearch';
import Surveys from '../components/public-website/surveys/Surveys';
import AboutUs from '../components/public-website/about-us/AboutUs';
import OurPeople from '../components/public-website/our-people/OurPeople';
import Careers from '../components/public-website/careers/Careers';
import Press from '../components/public-website/press/Press';

const publicWebsiteRoutes = [
  {
    path: '/admin/public-website/about-us',
    name: 'About us',
    component: AboutUs,
  },
  {
    path: '/admin/public-website/careers',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/admin/public-website/our-people',
    name: 'Our People',
    component: OurPeople,
  },
  {
    path: '/admin/public-website/press',
    name: 'Press',
    component: Press,
  },
  {
    path: '/admin/public-website/sample-research',
    name: 'Sample Research',
    component: SampleResearch,
  },
  {
    path: '/admin/public-website/surveys',
    name: 'Surveys',
    component: Surveys,
  },
  {
    redirect: true,
    from: '/admin/public-website',
    to: '/admin/public-website/model-portfolio',
    key: 'redirect',
  },
];

export default publicWebsiteRoutes;
