import React, { Component } from 'react';
import complianceRoutes from 'modules/admin/main/routes/compliance.jsx';
import renderRoutes from 'common/renderRoutes';

class Compliance extends Component {
  /**
   * @returns {JSX.Element} Compliance component.
   */
  render() {
    return <div className="admin-content">{renderRoutes(complianceRoutes, { basePath: `` })}</div>;
  }
}

export default Compliance;
