import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { EditorTitle, Button } from 'modules/admin/shared';

import { getSurveyAnalytics, exportSurvey } from 'modules/analyst/actions/surveys';
import { getDSTDateString, match } from 'common';

class SurveyAnalyticsDetail extends Component {
  static propTypes = {
    exportSurvey: PropTypes.func.isRequired,
    getSurveyAnalytics: PropTypes.func.isRequired,
    analytics: PropTypes.array.isRequired,
    match,
  };

  state = {
    analytics: [],
    questions: [],
    loading: true,
  };

  /**
   * @description Initialize component data.
   */
  async componentDidMount() {
    const analytics = [];
    const questions = [];

    const response = await this.props.getSurveyAnalytics(this.props.match.params.id);

    response.forEach(({ id, question, answers }, index) => {
      questions.push({ id, label: question });
      answers.forEach((answer, answerIndex) => {
        if (index === 0) {
          analytics.push({
            [id]:
              typeof answer === 'string' || typeof answer === 'number'
                ? answer
                : answer?.result || '',
          });
        } else {
          analytics[answerIndex] = {
            ...analytics[answerIndex],
            [id]:
              typeof answer === 'string' || typeof answer === 'number'
                ? answer
                : answer?.result || '',
          };
        }
      });
    });

    this.setState({
      analytics,
      questions,
      loading: false,
    });
  }

  /**
   * @description Export survey.
   */
  export = () => {
    this.props.exportSurvey(this.props.match.params.id);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { analytics, questions, loading } = this.state;
    return (
      <div>
        <EditorTitle title={<FormattedMessage id="ADMIN.ANALYTICS.SURVEY_ANALYTICS" />}>
          <Button onClick={this.export}>
            <FormattedMessage id="ADMIN.ANALYTICS.EXPORT" />
          </Button>
        </EditorTitle>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={questions.map(({ id, label }) => ({
                  Header: (
                    <div className="text-overflow-ellipsis" title={label}>
                      {id ? label : <FormattedMessage id="ADMIN.SURVEYS.FILL_DATE" />}
                    </div>
                  ),
                  accessor: `${id}`,
                  sortable: false,
                  Cell: ({ original, column, ...rest }) => {
                    return !original[column.id]
                      ? getDSTDateString(original[column.id])
                      : original[column.id];
                  },
                }))}
                loading={loading}
                data={analytics}
                resizable={false}
                showPageSizeOptions={false}
                showPagination={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                className="UserAnalyticsTable -striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst }) => {
  return {
    analytics: analyst.surveys.analytics,
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportSurvey,
      getSurveyAnalytics,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAnalyticsDetail);
