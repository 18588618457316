/**
 * @param {Array} routes Routes array which contains all top level routes
 * @param {any} data Data from server for a given branch in the route array
 * @param {number} branchIndex Current level in the branchehs
 *
 * @returns {Array} Routes array extended with the corresponding data.
 */
const addData = (routes, data, branchIndex = 0) => {
  return routes.map((route) => {
    const match = data[branchIndex];
    const result = { ...route };
    if (match && route.path === match.match.path) {
      result.data = match.data;
    }

    if (route.routes) {
      result.routes = addData(route.routes, ++branchIndex);
    }

    return result;
  });
};

export default (routes, data) => {
  return addData(routes, data);
};
