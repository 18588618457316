import * as BlogPostsService from 'modules/edge/services/blogposts';
import { Status } from 'modules/shared/util/http';

export const GET_BLOG_POSTS = 'GET_BLOG_POSTS';
export const DELETE_BLOG_POST = 'DELETE_BLOG_POST';
export const GET_BLOG_POST = 'GET_BLOG_POST';
export const SAVE_BLOG_POST = 'SAVE_BLOG_POST';
export const MODIFY_BLOG_POST = 'MODIFY_BLOG_POST';
export const SAVE_POST_FEATURED_IMAGE = 'SAVE_POST_FEATURED_IMAGE';
export const SAVE_POST_CONTENT_IMAGE = 'SAVE_POST_CONTENT_IMAGE';

/**
 * @description Gets one page from the post.
 * @param {Object} options Search options.
 * @returns {Function}
 */
export const getBlogPosts = (options) => async (dispatch) => {
  const posts = await BlogPostsService.getBlogPosts(options);
  dispatch({
    type: GET_BLOG_POSTS,
    payload: posts,
  });

  return posts;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const deleteBlogPost = (id) => async (dispatch) => {
  const post = await BlogPostsService.deleteBlogPost(id);

  dispatch({
    type: DELETE_BLOG_POST,
    payload: post,
  });

  return post;
};

/**
 * @description Gets the post for the ID:
 * @param {number} id post ID.
 * @returns {Function}
 */
export const getBlogPost = (id) => async (dispatch) => {
  try {
    const post = await BlogPostsService.getBlogPost(id);

    dispatch({
      type: GET_BLOG_POST,
      payload: post,
    });

    return post;
  } catch (e) {
    if (e.response && e.response.status === Status.NOT_FOUND) {
      e.ignoreToast = true;
    }
    throw e;
  }
};

/**
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveBlogPost = (data) => async (dispatch) => {
  const post = await BlogPostsService.saveBlogPost(data);

  dispatch({
    type: SAVE_BLOG_POST,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const modifyBlogPost = (id, data) => async (dispatch) => {
  const post = await BlogPostsService.modifyBlogPost(id, data);

  dispatch({
    type: MODIFY_BLOG_POST,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveFeaturedImage = (id, data) => async (dispatch) => {
  const post = await BlogPostsService.saveFeaturedImage(id, data);

  dispatch({
    type: SAVE_POST_FEATURED_IMAGE,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveContentImage = (id, data) => async (dispatch) => {
  const post = await BlogPostsService.saveContentImage(id, data);

  dispatch({
    type: SAVE_POST_FEATURED_IMAGE,
    payload: post,
  });

  return post;
};
