import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './Footer.scss';
import classNames from 'classnames';
import { SvgIcon, Svg } from 'modules/shared';
import $ from 'jquery';
import Env from 'env';
import { Row, Col, Grid } from 'react-bootstrap';

class Footer extends Component {
  static propTypes = {
    className: PropTypes.string,
    header: PropTypes.object,
    textId: PropTypes.string,
  };

  scrollTop = (e, duration = 0) => {
    $('html,body').animate({ scrollTop: 0 }, duration);
  };

  getLink = () => {
    return Env.SITE_URL ? <link itemProp="url" href={Env.SITE_URL} /> : null;
  };

  /**
   * @param {string} className string
   * @param {boolean} header Props boolean
   *
   * @returns {JSX.Element} - Our People component
   */
  render() {
    return (
      <div className={classNames('footer', this.props.className)}>
        {this.props.header && (
          <div className="footer-header">
            <div
              className={classNames('footer-logo', {
                'show-section': this.props.header.logoAnalyst || this.props.header.logoEdge,
              })}>
              {this.props.header.logoAnalyst && <Svg name="the-analyst-logo-dark" />}
              {this.props.header.logoEdge && <Svg name="theanalystedge-logo-blue" />}
            </div>
            <div
              className={classNames('footer-links', {
                'show-section': this.props.header.links,
              })}>
              {this.props.header.links &&
                this.props.header.links.map((data, index) => {
                  return (
                    <NavLink
                      key={index}
                      to={data.link}
                      activeClassName="active"
                      onClick={this.scrollTop}>
                      {data.label}
                    </NavLink>
                  );
                })}
            </div>
            <div
              className={classNames('footer-icons', {
                'show-section': this.props.header.icons,
              })}>
              <div>
                {this.props.header.icons && this.props.className.includes('edge') && (
                  <div itemScope itemType="http://schema.org/Organization">
                    {this.getLink()}
                    <a
                      className="icon-container ml-3"
                      rel="noopener noreferrer"
                      target="_blank"
                      itemProp="sameAs"
                      href="https://twitter.com/NeedTheAnalyst">
                      <i className="fab fa-twitter" />
                    </a>
                    <a
                      className="icon-container ml-3"
                      rel="noopener noreferrer"
                      target="_blank"
                      itemProp="sameAs"
                      href="https://www.linkedin.com/company/3497788">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                )}
                {this.props.header.icons && this.props.className.includes('analyst') && (
                  <div itemScope itemType="http://schema.org/Organization">
                    {this.getLink()}
                    <a
                      className="icon-container"
                      rel="noopener noreferrer"
                      target="_blank"
                      itemProp="sameAs"
                      href="https://twitter.com/NeedTheAnalyst">
                      <i className="fab fa-twitter" />
                    </a>
                    <a
                      className="icon-container ml-3"
                      rel="noopener noreferrer"
                      target="_blank"
                      itemProp="sameAs"
                      href="https://www.linkedin.com/company/3497788">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                )}
              </div>
              <div className="scroll-top">
                {this.props.header.toTop && (
                  <div
                    className="icon-container"
                    onClick={(e) => this.scrollTop(e, Env.SCROLL_DURATION)}>
                    <SvgIcon name="up-icon" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="footer-content">
          <Grid>
            <Row>
              <Col>
                <FormattedMessage id={this.props.textId || 'MAIN.FOOTER'} />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Footer);
