import React, { Component } from 'react';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Delete, AddPlaceholder, DragHandle, FileUpload, FieldGroup } from 'modules/admin/shared';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FormattedMessage } from 'react-intl';

const SortableBlock = SortableElement(
  ({
    block,
    type,
    removeFile,
    onChangeFile,
    fileIndex,
    informationIndex,
    onDropFile,
    deleteFile,
    onChangeCheckbox,
  }) => (
    <Col lg={4} md={4} sm={6} xs={12} className="embed-list-drag-drop-container">
      <div className="content">
        <Col xs={12}>
          <FieldGroup
            label={<FormattedMessage id="ADMIN.TITLE" />}
            error={!block.validTitle}
            name="title"
            value={block.title}
            onChange={(e) => {
              onChangeFile(e, informationIndex, fileIndex);
            }}
          />
        </Col>

        <Col xs={12}>
          <FieldGroup
            label={<FormattedMessage id="ADMIN.PROTECT_WITH_CONTACT_MODAL" />}
            name="protectWithContactModal"
            inline
            type="checkbox"
            checked={block.protectWithContactModal}
            onChange={(e) => {
              onChangeCheckbox(e, informationIndex, fileIndex);
            }}
          />
        </Col>

        {type.name === 'Video' ? (
          <Col xs={12}>
            <FieldGroup
              label={<FormattedMessage id="ADMIN.LINK" />}
              name="videoLink"
              error={!block.validVideoLink}
              value={block.videoLink}
              onChange={(e) => {
                onChangeFile(e, informationIndex, fileIndex);
              }}
            />
          </Col>
        ) : null}

        {type.name !== 'Video' ? (
          <Col xs={12}>
            <ControlLabel>
              <FormattedMessage id="ADMIN.POSTS.FILE" />
            </ControlLabel>
            <FileUpload
              className="file-type"
              accept="application/pdf"
              name="file"
              file={block.file}
              loading={block.loading}
              onDropFile={(files) => {
                onDropFile(files, informationIndex, fileIndex);
              }}
              deleteFile={() => {
                deleteFile(informationIndex, fileIndex);
              }}
            />
          </Col>
        ) : null}

        <DragHandle />
        <Delete onSubmit={() => removeFile(informationIndex, fileIndex)} />
      </div>
    </Col>
  )
);

const SortableBlockContainer = SortableContainer(
  ({
    files,
    type,
    addFile,
    removeFile,
    onChangeFile,
    informationIndex,
    onDropFile,
    deleteFile,
    onChangeCheckbox,
  }) => {
    return (
      <Row className="list-item-row">
        {[
          ...files.map((block, fileIndex) => (
            <SortableBlock
              collection={informationIndex}
              informationIndex={informationIndex}
              index={fileIndex}
              key={fileIndex}
              fileIndex={fileIndex}
              block={block}
              type={type}
              onDropFile={onDropFile}
              deleteFile={deleteFile}
              removeFile={removeFile}
              onChangeFile={onChangeFile}
              onChangeCheckbox={onChangeCheckbox}
            />
          )),
          <Col
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="embed-list-item placeholder"
            key="add-person">
            <AddPlaceholder onClick={() => addFile(informationIndex)} />
          </Col>,
        ]}
      </Row>
    );
  }
);

class InformationFiles extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    type: PropTypes.object.isRequired,
    onChangeFile: PropTypes.func.isRequired,
    onChangeCheckbox: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    onDropFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    informationIndex: PropTypes.number.isRequired,
  };

  /**
   * @param {array} props.files - contain data of files.
   * @param {Function} props.onChangeFile - onChange event handler for file changes.
   * @param {Function} props.addFile - onChange event handler for add new file.
   * @param {Function} props.removeFile - onChange event handler for remove file.
   *
   * @returns {JSX.Element} informationTypeList component to add/edit type.
   */
  render() {
    return (
      <div className="embed-list">
        <SortableBlockContainer
          files={this.props.files}
          type={this.props.type}
          addFile={this.props.addFile}
          removeFile={this.props.removeFile}
          onChangeFile={this.props.onChangeFile}
          onDropFile={this.props.onDropFile}
          deleteFile={this.props.deleteFile}
          informationIndex={this.props.informationIndex}
          onSortEnd={this.props.onDragEnd}
          useWindowAsScrollContainer
          helperClass="sorting-embed-list-item"
          useDragHandle
          axis="xy"
          delay={100}
          {...this.props}
        />
      </div>
    );
  }
}

export default InformationFiles;
