import request from 'modules/shared/util/request';
import { Methods } from 'modules/shared/util/http';
import { ModelPortfolioHistoryModel } from '../models';

/**
 * @description Get the list of available model portfolios.
 * @returns {Promise}
 */
export const getModelPortfolios = async ({
  nofilter = true,
  order = 'published_at',
  desc = true,
  page = 0,
  per_page = 20,
  search = '',
} = {}) => {
  const orderDirection = desc ? 'desc' : 'asc';
  let resource = `modelportfolio?order=${order}:${orderDirection}&page=${page}`;
  resource += `&search=${search}`;
  resource += `&per_page=${per_page}`;
  resource += nofilter ? '&nofilter' : '';
  return await request({ resource });
};

/**
 * @param {number} id ModelPortfolio id
 * @returns {Promise<*>}
 */
export const getModelPortfolio = async (id) => {
  return await request({
    resource: `modelportfolio/${id}`,
  });
};

/**
 * @param {Object} data ModelPortfolio object
 * @returns {Promise<*>}
 */
export const saveModelPortfolio = async (data) => {
  return await request({
    resource: `modelportfolio`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id ModelPortfolio id
 * @param {Object} data ModelPortfolio object
 * @returns {Promise<*>}
 */
export const modifyModelPortfolio = async (id, data) => {
  return await request({
    resource: `modelportfolio/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id ModelPortfolio id
 * @returns {Promise<*>}
 */
export const deleteModelPortfolio = async (id) => {
  return await request({
    resource: `modelportfolio/${id}`,
    method: Methods.DELETE,
  });
};

export const getModelPortfolioHistory = () => {
  return request<ModelPortfolioHistoryModel>({
    resource: `modelportfolio/history`,
  });
};
