const EXCEL_MIME_TYPES = [
  'application/vnd.ms-office',
  'application/vnd.ms-excel',
  'application/excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const IMAGE_TYPES = ['jpeg', 'png', 'jpg'];

/**
 * @description is file image.
 * @param {string} filename File's name
 *
 * @returns {boolean} File's extension.
 */
const isFileImage = (filename = '') => {
  return IMAGE_TYPES.includes(getFileExtension(filename).toLowerCase());
};

/**
 * @description Gets the extension of the file.
 * @param {string} filename File's name
 *
 * @returns {string} File's extension.
 */
const getFileExtension = (filename = '') => {
  return typeof filename === 'string' ? filename.split('.').pop() : '';
};

/**
 * @description Gets the icon for the file type
 * @param {string} mime_type File's mime type.
 * @param {string} file_name File's name.
 *
 * @returns {string} Icon class
 */
const getFileIcon = ({ mime_type, file_name }) => {
  if (/audio/.test(mime_type)) {
    return 'file-icon far fa-file-audio';
  }

  if (/application\/pdf/.test(mime_type) || getFileExtension(file_name) === 'pdf') {
    return 'file-icon far fa-file-pdf';
  }

  if (
    EXCEL_MIME_TYPES.includes(mime_type) ||
    ['xls', 'xlsx'].includes(getFileExtension(file_name))
  ) {
    return 'file-icon far fa-file-excel';
  }

  return 'file-icon far fa-file';
};

export { getFileExtension, getFileIcon, isFileImage };
