import { GET_PRESSES, GET_PRESS } from '../actions/press';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const press = (state = [], action) => {
  switch (action.type) {
    case GET_PRESSES: {
      return action.payload || [];
    }
    case GET_PRESS: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default press;
