import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showLoader, hideLoader } from 'modules/main/actions/loader';

import { arrayify } from './arrays';
import { useDisposed } from './useDisposed';

export type UseQueryResult<Data> = {
  data: Data | null;
  setData: React.Dispatch<React.SetStateAction<Data | null>>;
  isLoading: boolean;
  reload: (useLoading?: boolean) => Promise<Data | null>;
};

export function useQuery<Data>(
  fetcher?: (() => Promise<Data | null>) | null,
  dependencies?: ReadonlyArray<any>
): UseQueryResult<Data> {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<Data | null>(null);
  const disposed = useDisposed();
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (useLoading = true) => {
      try {
        if (useLoading) {
          dispatch(showLoader());
          setLoading(true);
        }

        const result = fetcher ? await fetcher() : null;

        if (!disposed()) {
          setData(result);
        }

        return result;
      } finally {
        if (useLoading) {
          dispatch(hideLoader());
        }

        if (!disposed() && useLoading) {
          setLoading(false);
        }
      }
    },
    [disposed, setLoading, fetcher, dispatch]
  );

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    // We only want to reload data if dependencies is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...arrayify(dependencies)]);

  return { data, setData, isLoading, reload: fetchData };
}
