import { GET_POSTS } from '../actions/posts';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const posts = (state = [], action) => {
  switch (action.type) {
    case GET_POSTS: {
      return action.payload || [];
    }
    default:
      return state;
  }
};

export default posts;
