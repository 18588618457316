import { GET_USERS } from '../actions/users';

/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const users = (state = [], action) => {
  switch (action.type) {
    case GET_USERS:
      return action.payload || [];
    default:
      return state;
  }
};

export default users;
