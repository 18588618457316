import request, { Methods } from 'modules/shared/util/request';
import { LongScreenModel, LongScreenFactorModel, LongScreenAdminModel } from '../models';

export function getLongScreen() {
  return request<LongScreenModel>({
    resource: 'long_screen',
  });
}

export function getLongScreenFactors() {
  return request<LongScreenFactorModel>({
    resource: 'long_screen/factors',
  });
}

export function voteOnFactors(factors: ID[]) {
  return request<LongScreenFactorModel>({
    method: Methods.POST,
    resource: 'long-screen/vote',
    data: factors,
  });
}

export function getLongScreenSinglepage() {
  return request<LongScreenAdminModel>({
    resource: 'singlepage/long_screen',
  });
}

export function saveLongScreen(data: LongScreenAdminModel) {
  return request<LongScreenAdminModel>({
    method: Methods.PUT,
    resource: 'singlepage/long_screen',
    data,
  });
}

export function closeWindow() {
  return request({
    method: Methods.POST,
    resource: 'singlepage/long_screen/close-voting-window',
  });
}
