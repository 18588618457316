import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';

/**
 * @param {string} order filter by order
 * @param {string} base resource
 * @description Gets all categories.
 * @returns {Promise<*>}
 */
export const getCategories = async ({ base = 'categories', ...params } = {}) => {
  const resource = `${base}${getPaginationQueryParams(params)}`;
  return await request({
    resource,
    method: Methods.GET,
  });
};

/**
 * @param {number} id Category ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const getCategory = async (id, base = 'categories') => {
  return await request({
    resource: `${base}/${id}`,
  });
};

/**
 * @param {Object} data category object
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const saveCategory = async (data, base = 'categories') => {
  return await request({
    resource: `${base}`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id Category ID
 * @param {Object} data Category object
 * @param {string} base baseUrl
 * @returns {Promise}
 */
export const modifyCategory = async (id, data, base = 'categories') => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.PUT,
    data,
  });
};

/**
 * @param {number} id Category ID
 * @param {string} base baseUrl
 *
 * @returns {Promise}
 */
export const deleteCategory = async (id, base = 'categories') => {
  return await request({
    resource: `${base}/${id}`,
    method: Methods.DELETE,
  });
};
