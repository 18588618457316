import * as MainService from '../services';

export const GET_ROLES = 'GET_ROLES';

/**
 * @returns {Function}
 */
export const getRoles = () => async (dispatch) => {
  const roles = await MainService.getRoles();

  dispatch({
    type: GET_ROLES,
    payload: roles,
  });

  return roles;
};
