import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { usePrevious } from 'modules/shared/util/usePrevious';

import styles from './LongScreenBar.scss';

const TRANSITION_DELAY = 100;

interface LongScreenBarProps {
  currentValue: number;
  mostValue: number;
  label: number;
}

function LongScreenBar({ currentValue, mostValue, label }: LongScreenBarProps) {
  const [triggerTransition, setTriggerTransition] = useState(false);
  const width = useMemo(() => (currentValue / (mostValue || 1)) * 100, [currentValue, mostValue]);

  const previousWidth = usePrevious(width);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (width !== previousWidth) {
      setTriggerTransition(false);

      timeout = setTimeout(() => {
        setTriggerTransition(true);
        timeout = null;
      }, TRANSITION_DELAY);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [width, previousWidth]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTriggerTransition(true);
    }, TRANSITION_DELAY);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setTriggerTransition]);

  return (
    <div className="long-screen-factor-result-chart">
      <div
        className={classNames('long-screen-factor-result-bar', {
          'has-width': width > 0,
        })}
        style={{ width: `calc(${width}% - 40px)` }}>
        <div
          className={classNames('long-screen-factor-result-bar-inner', {
            'full-width': triggerTransition,
          })}>
          <span className="long-screen-factor-result-title">{label}%</span>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(LongScreenBar);
