import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { bookmarkCompany } from 'modules/analyst/actions/companies';
import { bookmarkPost } from 'modules/analyst/actions/posts';
import {
  getBookmarks,
  deleteAnalystBookmark as deleteBookmark,
} from 'modules/shared/actions/bookmarks';

import MyResearch from 'modules/shared/components/my-research/MyResearch';

/**
 * @param {Object} state Application state.
 *
 * @returns {Object} Bound action creators.
 */
const mapStateToProps = ({ analyst }) => {
  return {
    bookmarks: analyst.bookmarks ? analyst.bookmarks.companies : [],
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { showLoader, hideLoader, bookmarkPost, bookmarkCompany, getBookmarks, deleteBookmark },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyResearch);
