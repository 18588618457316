import { AsyncState } from '../util/asyncReducerState';
import {
  GET_COUNTRIES_STARTED,
  GET_COUNTRIES_COMPLETED,
  GET_COUNTRIES_FAILED,
} from '../actions/countries';

export default (state = { state: AsyncState.INITIAL, data: [] }, action) => {
  switch (action.type) {
    case GET_COUNTRIES_STARTED:
      return { state: AsyncState.STARTED, data: [] };
    case GET_COUNTRIES_COMPLETED:
      return { state: AsyncState.COMPLETED, data: action.payload || [] };
    case GET_COUNTRIES_FAILED:
      return { state: AsyncState.FAILED, data: [] };
    default:
      return state;
  }
};
