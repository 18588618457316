import Env from 'env';
import { isBrowser } from 'modules/shared/util/isBrowser';

/**
 * @description set glowing to cookie
 * @param {string} feature Name of the feature
 * */
export const stopGlowing = (feature) => {
  if (!isBrowser()) {
    return;
  }

  const glowing = new Set(getStoppedGlowingFeatures());

  glowing.add(feature);

  localStorage.setItem(Env.STOP_GLOWING, [...glowing].join(';'));
};

/**
 * @description get glowing from cookie
 * @returns {string} is sentiment tracker glowing stopped
 * */
export const getGlowing = () => {
  return getStoppedGlowingFeatures();
};

/**
 * @description remove glowing from cookie
 * */
export const removeGlowing = () => {
  if (!isBrowser()) {
    return;
  }

  localStorage.removeItem(Env.STOP_GLOWING);
};

/**
 * @description get list of fetures that stopped glowing
 * @returns {Array<string>} list of features that stopped globing
 * */
function getStoppedGlowingFeatures() {
  if (!isBrowser()) {
    return [];
  }

  const value = localStorage.getItem(Env.STOP_GLOWING);

  if (!value) {
    return [];
  }

  return value.split(';');
}

/**
 * @description get list of fetures that stopped glowing
 * @param {string} feature Name of the feature
 * @returns {boolean} whether the feature stopped glowing
 * */
export function isFeatureStoppedGlowing(feature) {
  const stoppedList = getStoppedGlowingFeatures();

  return Boolean(stoppedList.find((item) => item === feature));
}
