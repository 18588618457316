import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withRouter } from 'react-router-dom';

import { match, history } from 'common';
import {
  Html,
  Collapse,
  SentimentTrackerLogo,
  Spinner,
  StarDescription,
  CanAccess,
  Roles,
} from 'modules/shared';
import CollapseContent from './CollapseContent';

import styles from './SentimentTracker.scss';

class SentimentTracker extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getSentimentTracker: PropTypes.func.isRequired,
    getSentimentTrackerRecommendations: PropTypes.func.isRequired,
    sentimentTracker: PropTypes.object.isRequired,
    setEditItem: PropTypes.func.isRequired,
    systemSettings: PropTypes.object.isRequired,
    basePath: PropTypes.string,
    intl: PropTypes.object,
    loading: PropTypes.bool,
    match,
    history,
  };

  state = {
    sentimentTrackerRecommendationsInitLoading: false,
    sentimentTrackerRecommendations: null,
  };

  /**
   * @description Gets the data for the page.
   */
  async componentDidMount() {
    try {
      this.props.showLoader();
      this.props.setEditItem({
        text: this.props.intl.formatMessage({
          id: 'ADMIN.SENTIMENT_TRACKER.EDIT_SENTIMENT_TRACKER',
        }),
        link: `/admin/content/sentiment-tracker`,
      });
      await this.props.getSentimentTracker();
      this.fetchSentimentTrackerRecommendations();
    } finally {
      this.props.hideLoader();
    }
  }

  /**
   * @description fetch SentimentTrackerRecommendations.
   */
  async fetchSentimentTrackerRecommendations() {
    this.setState({ sentimentTrackerRecommendationsInitLoading: true });

    const sentimentTrackerRecommendations = await this.props.getSentimentTrackerRecommendations();

    this.setState({
      sentimentTrackerRecommendations,
      sentimentTrackerRecommendationsInitLoading: false,
    });
  }

  /**
   * @param {string} key of recommendation type.
   * @param {Object} recommendation recommended company data Object.
   * @param {number} index recommended company index in data Array.
   * @returns {void}
   */
  updateDataByAnswer = (key, recommendation, index) => {
    const editedData = JSON.parse(JSON.stringify(this.state.sentimentTrackerRecommendations));

    if (editedData && Array.isArray(editedData[key])) {
      editedData[key][index] = recommendation;
    }

    this.setState({
      sentimentTrackerRecommendations: editedData,
    });
  };

  /**
   * @param {Object} data recommended company data Object.
   * @param {number} index recommended company index in data Array.
   * @returns {void}
   */
  updateStrongBuyDataByAnswer = (data, index) => this.updateDataByAnswer('strong_buy', data, index);

  /**
   * @param {Object} data recommended company data Object.
   * @param {number} index recommended company index in data Array.
   * @returns {void}
   */
  updateBuyDataByAnswer = (data, index) => this.updateDataByAnswer('buy', data, index);

  /**
   * @param {Object} data recommended company data Object.
   * @param {number} index recommended company index in data Array.
   * @returns {void}
   */
  updateShortDataByAnswer = (data, index) => this.updateDataByAnswer('short', data, index);

  /**
   * @param {Object} data recommended company data Object.
   * @returns {string}
   */
  getCollapseTitle(data) {
    return data && data.like_question ? data.like_question.question || '' : '';
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { sentimentTracker, loading, basePath, systemSettings } = this.props;
    const {
      sentimentTrackerRecommendations,
      sentimentTrackerRecommendationsInitLoading,
    } = this.state;

    return (
      <div>
        <FormattedMessage id="ADMIN.SENTIMENT_TRACKER.TITLE">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {sentimentTracker ? (
          <Grid fluid className="sentiment-tracker">
            <div className="d-flex flex-column">
              <Row className="white-background">
                <div className="header d-flex flex-wrap">
                  <Col xs={12} sm={12} md={7} className="sentiment-tracker-detail-section">
                    <Col xs={12} sm={12} className="p-0">
                      <Col xs={12} sm={12} className="sentiment-tracker-title">
                        <SentimentTrackerLogo />
                      </Col>
                    </Col>
                    <Col xs={12} sm={12} className="p-0">
                      <p>{sentimentTracker.description}</p>
                      <StarDescription star_description={sentimentTracker.star_description} />
                      <CanAccess
                        allowedRoles={[Roles.AnalystSubscriber]}
                        unauthenticatedComponent={<h4>{systemSettings.voting_button_note}</h4>}>
                        <h4>{sentimentTracker.vote_title}</h4>
                      </CanAccess>
                    </Col>
                  </Col>
                  <Col xs={12} sm={12} md={5} className="sentiment-tracker-info-section">
                    {sentimentTracker.video_url &&
                    sentimentTracker.vimeo_data &&
                    sentimentTracker.vimeo_data.embed ? (
                      <Col xs={12} sm={12}>
                        <Html
                          className="video-container"
                          content={sentimentTracker.vimeo_data.embed}
                        />
                      </Col>
                    ) : null}
                  </Col>
                </div>

                <hr className="sentiment-tracker-divider" />

                {sentimentTrackerRecommendationsInitLoading ? (
                  <div className="loading-container d-flex justify-center mb-3">
                    <Spinner size="medium" className="loader" />
                  </div>
                ) : (
                  <div className="sentiment-tracker-recommendations">
                    {sentimentTrackerRecommendations &&
                    Array.isArray(sentimentTrackerRecommendations.strong_buy) &&
                    sentimentTrackerRecommendations.strong_buy.length > 0 ? (
                      <Collapse
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.getCollapseTitle(
                                sentimentTrackerRecommendations.strong_buy[0]
                              ),
                            }}
                          />
                        }
                        open
                        showBottomBorder
                        color="edge-secondary-color"
                        content={
                          <CollapseContent
                            titleHoverColor="hover-edge-secondary-color"
                            basePath={basePath}
                            data={sentimentTrackerRecommendations.strong_buy}
                            updateDataByAnswer={this.updateStrongBuyDataByAnswer}
                          />
                        }
                      />
                    ) : null}
                    {sentimentTrackerRecommendations &&
                    Array.isArray(sentimentTrackerRecommendations.buy) &&
                    sentimentTrackerRecommendations.buy.length > 0 ? (
                      <Collapse
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.getCollapseTitle(sentimentTrackerRecommendations.buy[0]),
                            }}
                          />
                        }
                        open
                        showBottomBorder
                        color="edge-secondary-color"
                        content={
                          <CollapseContent
                            titleHoverColor="hover-edge-secondary-color"
                            basePath={basePath}
                            data={sentimentTrackerRecommendations.buy}
                            updateDataByAnswer={this.updateBuyDataByAnswer}
                          />
                        }
                      />
                    ) : null}
                    {sentimentTrackerRecommendations &&
                    Array.isArray(sentimentTrackerRecommendations.short) &&
                    sentimentTrackerRecommendations.short.length > 0 ? (
                      <Collapse
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.getCollapseTitle(
                                sentimentTrackerRecommendations.short[0]
                              ),
                            }}
                          />
                        }
                        open
                        color="analyst-main-color"
                        content={
                          <CollapseContent
                            titleHoverColor="hover-analyst-main-color"
                            basePath={basePath}
                            data={sentimentTrackerRecommendations.short}
                            updateDataByAnswer={this.updateShortDataByAnswer}
                          />
                        }
                      />
                    ) : null}
                  </div>
                )}
              </Row>
            </div>
          </Grid>
        ) : !loading ? (
          <div className="d-flex justify-center align-center model-portfolio-empty-state">
            <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.EMPTY_STATE" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(withRouter(SentimentTracker)));
