import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Bookmark, SectorAccess, Position } from 'modules/shared';

class CompanyCardRow extends PureComponent {
  static propTypes = {
    isBookmarked: PropTypes.bool,
    bookmark: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    subPath: PropTypes.string,
    company: PropTypes.object.isRequired,
    highlighted: PropTypes.bool,
  };

  static defaultProps = {
    subPath: 'company',
  };

  state = {
    bookmarking: false,
  };

  bookmark = async (company) => {
    if (this.state.bookmarking) {
      return;
    }
    this.setState({ bookmarking: true });
    await this.props.bookmark(company);
    this.setState({ bookmarking: false });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { company, highlighted, isBookmarked, basePath, subPath } = this.props;

    return (
      <li
        className={classNames('company-list-item', {
          bookmarked: isBookmarked,
          highlighted,
        })}
        key={company.id}>
        <SectorAccess
          hasAccess={company.has_sector_access}
          position={Position.RIGHT}
          highlighted={highlighted}
          blurIcon>
          <NavLink
            className={classNames('company-link', { disabled: !company.has_sector_access })}
            title={company.title}
            to={`${basePath}/${subPath}/${company.id}${company.slug ? `/${company.slug}` : ''}`}>
            {company.title}
          </NavLink>
        </SectorAccess>
        {company.has_sector_access && (
          <Bookmark
            className="pull-right"
            bookmarked={isBookmarked}
            bookmarking={this.state.bookmarking}
            onClick={() => this.bookmark(company)}
          />
        )}
      </li>
    );
  }
}

export default CompanyCardRow;
