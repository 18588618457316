const TEXT_EDITOR_ID = 1;
const INPUT_ID = 2;
const IMAGE_UPLOAD_ID = 3;
const FILE_UPLOAD_ID = 4;

const systemSettingsTypeOptions = [
  { name: 'Text Editor', id: TEXT_EDITOR_ID },
  { name: 'Input', id: INPUT_ID },
  { name: 'Image Upload', id: IMAGE_UPLOAD_ID },
  { name: 'File Upload', id: FILE_UPLOAD_ID },
];

export { TEXT_EDITOR_ID, INPUT_ID, IMAGE_UPLOAD_ID, FILE_UPLOAD_ID, systemSettingsTypeOptions };
