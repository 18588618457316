import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Switch, Route } from 'react-router-dom';
import { match } from 'common';
import AnalystGeographyList from './AnalystGeographyList';
import AnalystGeographyEditor from './AnalystGeographyEditor';
import { BasePaths } from 'config/basePaths';

class AnalystGeography extends Component {
  static propTypes = {
    match,
    basePath: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="admin-table">
        <Switch>
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/`}
            render={(props) => <AnalystGeographyList {...props} basePath={BasePaths.METADATA} />}
          />
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/new`}
            render={(props) => <AnalystGeographyEditor {...props} basePath={BasePaths.METADATA} />}
          />
          <Route
            sensitive
            exact
            path={`${this.props.match.path}/:id/edit`}
            render={(props) => <AnalystGeographyEditor {...props} basePath={BasePaths.METADATA} />}
          />
        </Switch>
      </div>
    );
  }
}

export default AnalystGeography;
