import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getEdgeArticleHeader,
  getEdgeArticleDownloads,
  getEdgeArticleUsers,
  resetCurrentArticle,
} from 'modules/admin/main/actions/analytics';
import { hideLoader, showLoader } from 'modules/main/actions/loader';

import ArticleAnalyticsDetail from '../../analytics/ArticleAnalyticsDetail';
/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ admin }) => {
  return {
    detail: admin.analytics.currentArticle || {},
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getArticleHeader: getEdgeArticleHeader,
      getArticleUsers: getEdgeArticleUsers,
      getArticleDownloads: getEdgeArticleDownloads,
      resetCurrentArticle,
      showLoader,
      hideLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAnalyticsDetail);
