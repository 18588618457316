import React from 'react';
import { Profile } from 'modules/shared';

/**
 * @param {Object} props object
 * @returns {JSX.Element} - Not found component. Uses HttpStatus.
 */
const MainProfile = (props) => {
  return <Profile {...props} />;
};

export default MainProfile;
