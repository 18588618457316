import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPostsPage, bookmarkPost } from 'modules/analyst/actions/posts';
import { deleteAnalystBookmark as deleteBookmark } from 'modules/shared/actions/bookmarks';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

import AllResearch from './AllResearch';

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getPostsPage, showLoader, hideLoader, bookmarkPost, deleteBookmark },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AllResearch);
