import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withRouter } from 'react-router-dom';

import { Button, SvgIcon, Svg } from 'modules/shared';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPassword';
import { location, redirectLink } from 'common';
import style from './style.scss';
import Cookies from 'js-cookie';
import Env from 'env';

class Login extends Component {
  static propTypes = {
    type: PropTypes.string,
    site: PropTypes.string,
    title: PropTypes.string.isRequired,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    readMore: PropTypes.string.isRequired,
    appPath: PropTypes.string.isRequired,
    login: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    showForm: PropTypes.bool,
    showLoginForm: PropTypes.func,
    profile: PropTypes.object,
    location,
  };

  state = {
    showForm: false,
    showForgotPassword: false,
    redirectUrl: null,
  };

  /**
   * @description set redirectUrl
   * */
  componentDidMount() {
    this.setState({
      redirectUrl: redirectLink(this.props.location),
    });
  }

  toggleForm = () => {
    if (this.state.showForgotPassword) {
      this.toggleForgotPasswordForm();
    } else {
      if (this.props.showLoginForm) {
        this.props.showLoginForm();
      }
      this.setState((prevState) => ({ showForm: !prevState.showForm, showForgotPassword: false }));
    }
  };

  toggleForgotPasswordForm = () => {
    this.setState((prevState) => ({ showForgotPassword: !prevState.showForgotPassword }));
  };

  forgotPassword = async (credentials) => {
    await this.props.forgotPassword(credentials);

    toast.success(
      <FormattedMessage id="MAIN.FORGOT_PASSWORD_SUCCESS" values={{ email: credentials.email }} />,
      {
        className: 'top',
      }
    );
    this.toggleForgotPasswordForm();
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const showForm = this.props.showForm || this.state.showForm;
    return (
      <div className={classNames('login', { active: showForm }, this.props.type)}>
        <div className={classNames('circle-wrapper', { active: showForm })}>
          <div className="content">
            {showForm && (
              <div className="back large" onClick={this.toggleForm}>
                <SvgIcon name="left-arrow" width={14} />
              </div>
            )}
            <div className="container">
              <div className="title">
                <NavLink to={this.props.readMore} onClick={this.toggleForm}>
                  <Svg name={this.props.title} width={14} />
                </NavLink>
              </div>
              {!showForm && (
                <div>
                  <div className="info">{this.props.info}</div>
                  {!Cookies.get(Env.REFRESH_TOKEN_ID) && (
                    <Button moduleType={this.props.type} onClick={this.toggleForm}>
                      <FormattedMessage id="MAIN.CLIENT_LOGIN" />
                    </Button>
                  )}
                  {Cookies.get(Env.REFRESH_TOKEN_ID) && (
                    <NavLink to={this.props.appPath}>
                      <Button moduleType={this.props.type}>
                        <FormattedMessage id="MAIN.GO_MY_RESEARCH" />
                      </Button>
                    </NavLink>
                  )}
                  <div className="more">
                    <NavLink to={this.props.readMore} onClick={this.toggleForm}>
                      <FormattedMessage id="MAIN.READ_MORE" />
                    </NavLink>
                  </div>
                </div>
              )}
              {showForm && !this.state.showForgotPassword && (
                <LoginForm
                  type={this.props.type}
                  login={this.props.login}
                  back={this.toggleForm}
                  toggleForgotPasswordForm={this.toggleForgotPasswordForm}
                  loginRedirect={this.state.redirectUrl || this.props.appPath}
                />
              )}
              {this.state.showForgotPassword && (
                <ForgotPasswordForm
                  type={this.props.type}
                  site={this.props.site}
                  back={this.toggleForm}
                  forgotPassword={this.forgotPassword}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(style)(Login));
