import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Navbar } from 'react-bootstrap';
import Env from 'env';
import HeaderNavLinks from './HeaderNavLinks';
import { routeProps } from 'common/propTypes';
import homeRoutes from 'modules/admin/main/routes/home';

/**
 *
 * @param {Object} props - Properties object.
 * @param {Function} props.onClick - onClick event handler.
 *
 * @returns {React.ReactPortal} - Sidebar backdrop on the body element.
 */
const SidebarBackdrop = ({ onClick }) => {
  return createPortal(<div id="sidebar-backdrop" onClick={onClick} />, document.body);
};

class Header extends Component {
  static propTypes = {
    ...routeProps,
  };
  state = {
    sidebarOpen: false,
  };

  /**
   * @description Adds the resize event listener.
   */
  componentDidMount() {
    global.window.addEventListener('resize', this.onResize);
  }

  /**
   * @description Removes the resize event listener.
   */
  componentWillUnmount() {
    global.window.removeEventListener('resize', this.onResize);
  }

  /**
   * @description Sets the sidebar state on resize.
   *
   * @param {Event} e Event object
   */
  onResize = (e) => {
    if (e.target.innerWidth > Env.BREAKPOINTS.SMALL_SCREEN && this.state.sidebarOpen) {
      this.setState({ sidebarOpen: false });
      document.body.classList.toggle('nav-open');
    }
  };

  /**
   * @description Toggles the sidebar on small screen.
   *
   * @param {Event} e Event object
   */
  mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('nav-open');

    this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
  };

  /**
   * @returns {string} Name of the current route.
   */
  getBrand() {
    let name = '';
    homeRoutes.map((prop, key) => {
      if (this.props.location.pathname.includes(prop.path) && !prop.redirect) {
        name = prop.name;
      }
    });
    return name;
  }

  /**
   * @returns {JSX.Element} Header component.
   */
  render() {
    return (
      <Navbar fluid>
        <Navbar.Header>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
          <Navbar.Brand>
            <a>{this.getBrand()}</a>
          </Navbar.Brand>
        </Navbar.Header>
        <Navbar.Collapse>
          <HeaderNavLinks />
        </Navbar.Collapse>
        {this.state.sidebarOpen && <SidebarBackdrop onClick={this.mobileSidebarToggle} />}
      </Navbar>
    );
  }
}

export default Header;
