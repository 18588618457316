import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './ActionIcon.scss';

class ActionIcon extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { className, ...props } = this.props;
    return <span className={classNames('action-icon', className)} {...props} />;
  }
}

export default withStyles(style)(ActionIcon);
