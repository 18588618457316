import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './SignUpModal.scss';
import { Modal } from 'modules/shared/components';
import { FieldGroup } from 'modules/admin/shared';
import { Button, Svg } from 'modules/shared';
import { Modal as BsModal } from 'react-bootstrap';
import { isValidSchema, emailSchema, textSchema } from 'common/shemaValidator';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

class SignUpModal extends React.Component {
  static propTypes = {
    onRef: PropTypes.func.isRequired,
  };

  /**
   * @returns {Object} default form
   * */
  static getForm() {
    return {
      name: '',
      email: '',
      company: '',
      job_title: '',
    };
  }

  state = {
    show: false,
    form: SignUpModal.getForm(),
    errors: {},
  };

  /**
   * @description set onRef value
   * */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   *@description set onRef value to unmount
   * */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  onSubmit = () => {};

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const errors = { ...this.state.errors };
    this.setState({ form: { ...this.state.form, [name]: value } }, async () => {
      const { isValid } = await isValidSchema(this.getValidationSchema(name), {
        [name]: value,
      });
      errors[name] = !isValid;
      this.setState({ errors });
    });
  };

  /**
   * @param {string} name is field name
   * @returns {Object} validation schema
   * */
  getValidationSchema = (name) => {
    switch (name) {
      case 'name': {
        return textSchema(name, { required: true });
      }
      case 'email': {
        return emailSchema(name);
      }
      default:
        return {};
    }
  };

  /**
   * @returns {bool} disable form
   * */
  disableForm = () => {
    return (
      !!this.state.errors['name'] ||
      typeof this.state.errors['name'] === 'undefined' ||
      !!this.state.errors['email'] ||
      typeof this.state.errors['email'] === 'undefined'
    );
  };

  /**
   * @description Hides the modal.
   */
  handleClose = () => {
    this.setState({ show: false }, () => {
      this.setState({
        form: SignUpModal.getForm(),
        errors: {},
      });
    });
  };

  /**
   * @description Shows the modal.
   */
  handleShow = () => {
    this.setState({ show: true });
  };

  /**
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    const { onRef, ...props } = this.props;
    return (
      <Modal
        className="sign-up-modal"
        onHide={this.handleClose}
        onSubmit={this.handleClose}
        show={this.state.show}
        {...props}>
        <BsModal.Header closeButton>
          <BsModal.Title id="contained-BsModal-title-sm" componentClass="h4">
            {<FormattedMessage id="EDGE.SIGN_UP_MODAL.COMING_SOON" />}
            <Svg name="theanalystedge-logo-white" height={21} />
          </BsModal.Title>
        </BsModal.Header>
        <BsModal.Body>
          <div className="modal-body-content">
            <h4>
              <FormattedMessage id="EDGE.SIGN_UP_MODAL.INTEREST_LABEL" />
            </h4>
            <h5 className="launch-information">
              <FormattedMessage id="EDGE.SIGN_UP_MODAL.LAUNCH_INFORMATION" />
            </h5>
            <FieldGroup
              name="name"
              value={this.state.form.name}
              label={<FormattedMessage id="EDGE.SIGN_UP_MODAL.NAME_MANDATORY" />}
              error={
                !!this.state.errors['name'] && typeof this.state.errors['name'] !== 'undefined'
              }
              onChange={this.onChange}
            />
            <FieldGroup
              name="email"
              value={this.state.form.email}
              label={<FormattedMessage id="EDGE.SIGN_UP_MODAL.EMAIL_MANDATORY" />}
              error={
                !!this.state.errors['email'] && typeof this.state.errors['email'] !== 'undefined'
              }
              onChange={this.onChange}
            />
            <FieldGroup
              name="company"
              value={this.state.form.company}
              label={<FormattedMessage id="MAIN.COMPANY" />}
              onChange={this.onChange}
            />
            <FieldGroup
              name="job_title"
              value={this.state.form.job_title}
              label={<FormattedMessage id="MAIN.JOB_TITLE" />}
              onChange={this.onChange}
            />
          </div>
        </BsModal.Body>
        <BsModal.Footer>
          <div className="modal-footer-content">
            <Button className="small edge" onClick={this.onSubmit} disabled={this.disableForm()}>
              {<FormattedMessage id="MAIN.SUBMIT" />}
            </Button>
          </div>
        </BsModal.Footer>
      </Modal>
    );
  }
}

export default withStyles(style)(SignUpModal);
