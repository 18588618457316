import * as RegionsService from '../services/regions';

export const GET_REGIONS = 'GET_REGIONS';

/**
 * @returns {Function} Async regions.
 */
export const getRegions = () => async (dispatch) => {
  const regions = await RegionsService.getRegions();

  dispatch({
    type: GET_REGIONS,
    payload: regions,
  });

  return regions;
};
