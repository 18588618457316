import qs from 'query-string';

import Env from 'env';

export const parse = (data: string) => {
  return qs.parse(data, Env.QUERY_STRING_CONFIG);
};

export const stringify = (data: any) => {
  return qs.stringify(data, Env.QUERY_STRING_CONFIG);
};
