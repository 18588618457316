import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Spinner } from 'modules/shared';

import styles from './AnswerButton.scss';

interface AnswerButtonProps {
  isLoading?: boolean;
  type: AnswerButtonType;
  active?: boolean;
  onClick?: MouseEventHandler;
  disableHover?: boolean;
  disabled?: boolean;
}

export enum AnswerButtonType {
  LIKE = 1,
  DISLIKE = 2,
}

const iconClassMap = {
  [AnswerButtonType.LIKE]: 'icon-like',
  [AnswerButtonType.DISLIKE]: 'icon-dislike',
};

function AnswerButton({
  isLoading,
  type,
  active,
  onClick,
  disableHover,
  disabled,
}: AnswerButtonProps) {
  const iconClass = iconClassMap[type];

  return isLoading ? (
    <div className="answer-button-container">
      <Spinner size="small" blue={type === AnswerButtonType.LIKE} />
    </div>
  ) : (
    <span className="answer-button-icon">
      <i
        className={classNames({
          [`${iconClass}-active`]: active,
          [iconClass]: !active,
          'no-hover': disableHover,
          disabled,
        })}
        onClick={onClick}
      />
    </span>
  );
}

export default withStyles(styles)(AnswerButton);
