import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Env from 'env';

import { Content, Button } from 'modules/shared';

import style from './AppNotFound.scss';

const AppNotFound = () => {
  return (
    <div className="analyst-home not-found">
      <FormattedMessage id="MAIN.PAGE_NOT_FOUND">
        {(title) => (
          <Helmet defer={false}>
            <title itemProp="name">{title}</title>
            <link rel="canonical" href={`${Env.SITE_URL}404`} itemProp="url" />
          </Helmet>
        )}
      </FormattedMessage>

      <Content>
        <img
          className="not-found-background"
          src={require('assets/img/growth-line.png')}
          alt="growth-line"
        />
        <div className="title">
          <FormattedMessage id="MAIN.PAGE_NOT_FOUND_2" />
        </div>
        <div className="message">
          <FormattedMessage id="MAIN.PAGE_NOT_FOUND_MESSAGE" />
        </div>
        <Link className="to-home" to="/app" replace>
          <Button textColor="dark" moduleType="analyst">
            <FormattedMessage id="MAIN.BACK_HOME" />
          </Button>
        </Link>
      </Content>
    </div>
  );
};

export default withStyles(style)(AppNotFound);
