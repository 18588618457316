import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Spinner } from 'modules/shared';
import PostCard from './PostCard';
import styles from './PostList.scss';
import withInfiniteScroll from 'modules/shared/util/withInfiniteScroll';

class PostsList extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    fetching: PropTypes.bool,
    posts: PropTypes.array,
    defaultImage: PropTypes.string,
    basePath: PropTypes.string,
    deleteBookmark: PropTypes.func,
    bookmarkPost: PropTypes.func,
    afterBookmark: PropTypes.func,
    postsTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    emptyMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  };

  bookmarkPost = async (post) => {
    const postIndex = this.props.posts.findIndex(({ id }) => id === post.id);
    const posts = this.props.posts.map((p) => ({ ...p }));
    if (post.bookmarks && post.bookmarks.length) {
      await this.props.deleteBookmark(post.bookmarks[0].id);
      posts[postIndex].bookmarks = [];
    } else {
      const bookmark = await this.props.bookmarkPost(post.id);
      posts[postIndex].bookmarks = [bookmark];
    }
    this.props.afterBookmark(posts);
  };

  /**
   * @returns {JSX.Element[] | null}
   */
  render() {
    const {
      loading,
      fetching,
      posts,
      defaultImage,
      basePath,
      postsTitle,
      emptyMessage,
    } = this.props;

    return (
      <Grid fluid className="post-list">
        <Row>
          <div className="d-flex flex-wrap overflow-hidden post-list-row">
            {postsTitle ? (
              <Col className="title" xs={12}>
                {postsTitle}
              </Col>
            ) : null}
            {!loading
              ? posts.map((post, i) => (
                  <PostCard
                    key={post.id}
                    post={post}
                    basePath={basePath}
                    className={i === 0 ? 'first' : ''}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    bookmark={this.bookmarkPost}
                    defaultImage={defaultImage}
                  />
                ))
              : null}
            {!loading && !posts.length && emptyMessage ? <Col xs={12}>{emptyMessage}</Col> : null}
            {fetching ? (
              <Col xs={12} className="d-flex align-center justify-center">
                <Spinner size="medium" className="loader" />
              </Col>
            ) : null}
          </div>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(styles)(withInfiniteScroll(PostsList));
