import React, { MouseEventHandler, VFC } from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './SvgIcon.scss';
import { Img } from 'modules/shared';

interface SvgIconProps extends SvgProps {
  action?: boolean;
}

const SvgIcon: VFC<SvgIconProps> = ({
  className,
  name,
  width = 33,
  height = 22,
  action,
  ...props
}) => {
  if (!name) {
    return null;
  }
  return (
    <Svg
      name={name}
      className={classNames('icon svg-icon', { action: action || props.onClick }, className)}
      width={width}
      base="icons"
      height={height}
      {...props}
    />
  );
};

interface SvgProps {
  name: string;
  className?: string;
  base?: string;
  width?: number;
  height?: number;
  onClick?: MouseEventHandler;
}

export const Svg: VFC<SvgProps> = ({ name, className, base = 'img', ...props }) => {
  return (
    <Img
      src={require(`assets/${base}/${name}.svg`)}
      className={classNames('svg', name, className)}
      alt={name}
      {...props}
    />
  );
};

export default withStyles(style)(SvgIcon);
