import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputs } from 'modules/admin/shared/components';
import styles from './MultipleTextBox.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AddPlaceholder, DragHandle, Delete } from 'modules/admin/shared';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { Row, Col } from 'react-bootstrap';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { TextEditor, FieldGroup } from 'modules/admin/shared';
import { FileUpload } from 'modules/admin/shared';
import { isValidSchema, validateSchema, pagesFileUpload } from 'common';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pagesUploadFile } from 'modules/admin/main/actions/file-upload';
import { debounce } from 'common';
import { getVimeoData } from 'modules/admin/main/actions/vimeo';
import { FormattedMessage } from 'react-intl';
import Env from 'env';
import Videos from '../videos/Videos';

const Z_INDEX = 520;

const descriptionSchema = {
  description: Yup.string().nullable(),
};

const linkSchema = {
  link: Yup.string().url(),
};

const ratioSchema = {
  placeRatio: Yup.string()
    .matches(Env.NUMBER_PATTERN, <FormattedMessage id="ADMIN.NUMBER_ERROR" />)
    .nullable(),
};

const SortableBlock = SortableElement(
  ({
    block,
    placeError,
    onChange,
    handleEditorChange,
    removeWork,
    workIndex,
    workSections,
    onDropImage,
    deleteFile,
    setReady,
    loaded,
  }) => {
    /**
     * @returns {number} count of previous videos
     */
    const getCountOfPrevVideos = () => {
      const relevantItems = workSections.filter((data, index) => index < workIndex);
      return relevantItems && !!relevantItems.length
        ? relevantItems.reduce((a, b) =>
            a.carousel && a.carousel.videos
              ? +a.carousel.videos.length
              : 0 + b.carousel.videos
              ? +b.carousel.videos.length
              : 0
          )
        : 0;
    };
    return (
      <Col lg={6} md={12} sm={12} xs={12} className="work-sections">
        <DragHandle />
        <Delete onSubmit={() => removeWork(workIndex)} />
        <FormInputs
          ncols={['col-md-12', 'col-md-12', 'col-md-12', 'col-md-12', 'col-md-12']}
          properties={[
            {
              label: <FormattedMessage id="ADMIN.TITLE" />,
              type: 'text',
              bsClass: 'form-control',
              placeholderId: 'ADMIN.TITLE',
              name: 'title',
              value: block.title,
              onChange: (e) => {
                onChange(e, workIndex);
              },
            },
            {
              label: <FormattedMessage id="ADMIN.LINK" />,
              type: 'text',
              bsClass: 'form-control',
              placeholderId: 'ADMIN.LINK',
              name: 'link',
              value: block.link,
              onChange: (e) => {
                onChange(e, workIndex);
              },
            },
            {
              label: <FormattedMessage id="ADMIN.LINK_TITLE" />,
              type: 'text',
              bsClass: 'form-control',
              placeholderId: 'ADMIN.LINK_TITLE',
              name: 'linkTitle',
              value: block.linkTitle,
              onChange: (e) => {
                onChange(e, workIndex);
              },
            },
            {
              label: <FormattedMessage id="ADMIN.VIDEO_LINK" />,
              type: 'text',
              bsClass: 'form-control',
              placeholderId: 'ADMIN.VIDEO_LINK',
              name: 'videoLink',
              value: block.videoLink,
              onChange: (e) => {
                onChange(e, workIndex);
              },
            },
            {
              label: <FormattedMessage id="ADMIN.RATIO_OF_PLACE" />,
              type: 'text',
              bsClass: 'form-control',
              placeholderId: 'ADMIN.RATIO_OF_PLACE',
              name: 'placeRatio',
              value: block.placeRatio,
              touched: true,
              error: placeError,
              onChange: (e) => {
                onChange(e, workIndex);
              },
            },
          ]}
        />

        <FieldGroup
          className="checkbox-form-group"
          label={<FormattedMessage id="ADMIN.GREY_BACKGROUND" />}
          inline
          type="checkbox"
          name="greyBackground"
          checked={block.greyBackground}
          onChange={(event) => onChange(event, workIndex)}
        />

        <FieldGroup
          className="checkbox-form-group"
          label={<FormattedMessage id="ADMIN.DARK_BACKGROUND" />}
          inline
          type="checkbox"
          name="darkBackground"
          checked={block.darkBackground}
          onChange={(event) => onChange(event, workIndex)}
        />

        <FormGroup className="image-preview-container">
          <ControlLabel>Background Image</ControlLabel>
          <FileUpload
            file={block.image}
            onDropFile={(files) => onDropImage(files, workIndex)}
            deleteFile={() => {
              deleteFile(workIndex);
            }}
            loading={block.loading}
          />
        </FormGroup>

        <FieldGroup
          label={<FormattedMessage id="ADMIN.CAROUSEL_TITLE" />}
          placeholderId="ADMIN.CAROUSEL_TITLE"
          type="text"
          name="carouselTitle"
          checked={block.carouselTitle}
          onChange={(event) => onChange(event, workIndex)}
        />

        <Videos
          onChange={(event) => onChange(event, workIndex)}
          data={block.carousel}
          setReady={setReady}
          loaded={loaded}
          zIndex={Z_INDEX - getCountOfPrevVideos()}
          hiddenFields={['videoUrl', 'link', 'linkTitle']}
        />

        <TextEditor
          error={block.validDescription === undefined ? false : !block.validDescription}
          zIndex={Z_INDEX - workIndex}
          handleChange={(e) => {
            const text = e.target.value;
            handleEditorChange(text, workIndex);
          }}
          loaded={loaded}
          text={block.description}
        />
      </Col>
    );
  }
);

const SortableBlockContainer = SortableContainer(
  ({
    workSections,
    placeError,
    onChange,
    handleEditorChange,
    addWork,
    removeWork,
    onDropImage,
    deleteFile,
    setReady,
    loaded,
  }) => {
    return (
      <div>
        <Row>
          {workSections &&
            workSections.map((block, index) => (
              <SortableBlock
                index={index}
                key={index}
                workIndex={index}
                workSections={workSections}
                block={block}
                placeError={placeError}
                onChange={onChange}
                handleEditorChange={handleEditorChange}
                removeWork={removeWork}
                onDropImage={onDropImage}
                deleteFile={deleteFile}
                setReady={setReady}
                loaded={loaded}
              />
            ))}
          {workSections && workSections.length < 3 && (
            <Col lg={4} md={4} sm={6} xs={12} key="add">
              <AddPlaceholder onClick={addWork} />
            </Col>
          )}
        </Row>
      </div>
    );
  }
);

class MultipleTextBox extends Component {
  static componentType = 'multiple-text-box';
  static blockName = 'Multiple Text Box';
  static icon = 'fas fa-building';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
    pagesUploadFile: PropTypes.func.isRequired,
    getVimeoData: PropTypes.func.isRequired,
    setReady: PropTypes.func.isRequired,
  };

  state = {
    workSections: [MultipleTextBox.getWorkSection()],
    placeError: '',
    loaded: false,
    loadedDragAndDrop: true,
  };

  /**
   * @returns {Object} Empty opportunity object.
   */
  static getWorkSection() {
    return {
      title: '',
      description: '',
      link: '',
      linkTitle: '',
      videoLink: '',
      placeRatio: '',
      darkBackground: false,
      greyBackground: false,
      image: {},
      carousel: {},
      carouselTitle: '',
      loading: false,
    };
  }

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    const { valid, workSections } = this.props.data;

    this.setState({
      valid: !!valid,
      loaded: true,
      workSections: workSections || [MultipleTextBox.getWorkSection()],
    });
  }

  /**
   * @returns {boolean} header is valid.
   */
  async isValidForm() {
    const schema = Yup.array().of(
      Yup.object().shape({
        ...descriptionSchema,
        ...ratioSchema,
      })
    );

    return await schema.isValid(this.state.workSections);
  }

  onChange = async (e, workIndex) => {
    const workSections = this.state.workSections.map((data) => data);

    if (e.videos) {
      workSections[workIndex].carousel = {
        ...workSections[workIndex].carousel,
        videos: e.videos,
        showCarousel: e.showCarousel,
      };
      this.setState({ workSections }, async () => {
        const valid = await this.isValidForm();
        this.props.onChange({ workSections, valid });
      });
    } else {
      const name = e.target.name;
      const value = e.target.value;
      const checked = e.target.checked;
      const type = e.target.type;

      workSections[workIndex][name] = type === 'checkbox' ? checked : value;

      let placeError;
      this.setState({ workSections }, async () => {
        if (name === 'videoLink') {
          const { isValid } = await isValidSchema(linkSchema, {
            link: value,
          });
          if (isValid) {
            this.getVimeoData(value, workIndex);
          }
        } else if (name === 'placeRatio') {
          try {
            await validateSchema(ratioSchema, {
              placeRatio: value,
            });
            placeError = '';
          } catch (validationError) {
            placeError = validationError.errors[0];
          }

          const sumOfPlace = workSections.reduce((a, b) => +a.placeRatio + +b.placeRatio);
          placeError =
            typeof sumOfPlace === 'number' && sumOfPlace > 0 && sumOfPlace !== 100 ? (
              <FormattedMessage id="ADMIN.RATIO_OF_PLACE_ERROR" />
            ) : (
              placeError
            );
        }

        this.setState({ placeError });
        const valid = await this.isValidForm();
        this.props.onChange({ workSections, valid: valid && !placeError });
      });
    }
  };

  handleEditorChange = async (text, workIndex) => {
    const workSections = this.state.workSections.map((data) => data);
    workSections[workIndex].description = text;
    const { isValid } = await isValidSchema(descriptionSchema, {
      description: text,
    });
    workSections[workIndex].validDescription = isValid;
    this.setState({ workSections });
    const valid = await this.isValidForm();
    this.props.onChange({ workSections, valid });
  };

  onDropImage = (files, workIndex) => {
    const workSections = this.state.workSections.map((data) => data);
    workSections[workIndex].image = files[0];
    workSections[workIndex].loading = true;
    this.setState({ workSections }, async () => {
      const valid = await this.isValidForm();

      const { path } = await pagesFileUpload(
        this.props.site,
        this.props.slug,
        files,
        this.props.pagesUploadFile
      );

      workSections[workIndex].image = path;
      delete workSections[workIndex].loading;
      this.setState({ workSections });
      this.props.onChange({ workSections, valid });
    });
  };

  deleteFile = async (workIndex) => {
    const workSections = this.state.workSections.map((data) => data);
    workSections[workIndex].image = null;
    this.setState({ workSections });
  };

  addWork = () => {
    this.setState(
      (prevState) => {
        return { workSections: [...prevState.workSections, MultipleTextBox.getWorkSection()] };
      },
      async () => {
        const valid = await this.isValidForm();
        this.props.onChange({
          workSections: this.state.workSections,
          valid,
        });
      }
    );
  };

  removeWork = async (index) => {
    const workSections = this.state.workSections.map((data) => data);
    workSections.splice(index, 1);
    this.setState({ workSections });
    const valid = await this.isValidForm();
    this.props.onChange({ workSections, valid });
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        workSections: arrayMove(this.state.workSections, oldIndex, newIndex),
        loadedDragAndDrop: false,
      },
      async () => {
        const valid = await this.isValidForm();
        this.props.onChange({
          workSections: this.state.workSections,
          valid,
        });
        this.setState({ loadedDragAndDrop: true });
      }
    );
  };

  getVimeoData = debounce(async (url, workIndex) => {
    this.props.setReady(false);
    const workSections = this.state.workSections.map((data) => ({ ...data }));
    const response = await this.props.getVimeoData({ url });
    workSections[workIndex].videoEmbed = response.embed;
    workSections[workIndex].metadata = response.metadata;
    workSections[workIndex].thumbnails = response.thumbnails;
    this.setState({ workSections });
    this.props.onChange({ workSections });
    this.props.setReady(true);
  }, 1000);

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="work-sections">
        <SortableBlockContainer
          helperClass=""
          workSections={this.state.workSections}
          loaded={this.state.loaded && this.state.loadedDragAndDrop}
          placeError={this.state.placeError}
          onChange={this.onChange}
          handleEditorChange={this.handleEditorChange}
          removeWork={this.removeWork}
          addWork={this.addWork}
          onSortEnd={this.onDragEnd}
          onDropImage={this.onDropImage}
          deleteFile={this.deleteFile}
          setReady={this.props.setReady}
          useWindowAsScrollContainer
          useDragHandle
          axis="xy"
          delay={100}
        />
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pagesUploadFile,
      getVimeoData,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(MultipleTextBox));
