import { combineReducers } from 'redux';
import app from 'modules/main/reducers';
import admin from 'modules/admin/main/reducers';
import shared from 'modules/shared/reducers';
import analyst from 'modules/analyst/reducers';
import edge from 'modules/edge/reducers';
import edgePlus from 'modules/edge-plus/reducers';

export default combineReducers({
  app,
  admin,
  shared,
  analyst,
  edge,
  edgePlus,
});
