import { Component } from 'react';
import PropTypes from 'prop-types';
import renderRoutes from 'common/renderRoutes';

class AnalystMain extends Component {
  static propTypes = {
    route: PropTypes.object,
  };
  /**
   * @returns {JSX.Element}
   */
  render() {
    return renderRoutes(this.props.route.routes, null, null, false);
  }
}

export default AnalystMain;
