import React from 'react';
import { Redirect } from 'react-router-dom';

import { LoginPermission, AdminPermission } from 'modules/shared';
import { AboutUs, Careers, Contact, NotFound, OurPeople, Press, PressDetail } from 'modules/main';
import Admin from 'modules/admin';
import {
  Landing,
  SampleResearch,
  TermsOfUse,
  PrivacyPolicy,
  DisclaimersNDisclosures,
  CreatePassword,
} from 'modules/analyst/modules/main';
import { Survey, CompanyFeedbackChart, AnalystApp } from 'modules/analyst/modules/app';
import Env from 'env';

export default [
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/`,
  //   component: Landing,
  //   name: 'The Analyst',
  //   exact: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/how-we-work`,
  //   component: HowWeWork,
  //   name: 'How we work',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/sample-research`,
  //   component: SampleResearch,
  //   name: 'Sample research',
  //   exact: true,
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/terms-of-use`,
  //   component: TermsOfUse,
  //   name: 'Terms of use',
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/privacy-policy`,
  //   component: PrivacyPolicy,
  //   name: 'Privacy Policy',
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/disclaimers-n-disclosures`,
  //   component: DisclaimersNDisclosures,
  //   name: 'Disclaimers And Disclosures',
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/app`,
  //   component: LoginPermission(() => <Redirect to={`/${Env.ANALYST_BASE_PATH}`} />)(AnalystApp),
  //   name: 'Analyst',
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/create-password/:token?`,
  //   component: CreatePassword,
  //   name: 'Create Password',
  //   strict: true,
  // },
  // {
  //   path: `/${Env.ANALYST_BASE_PATH}/profile`,
  //   component: LoginPermission()(Profile),
  //   name: 'Profile',
  //   strict: true,
  // },
  {
    path: `/`,
    component: Landing,
    name: 'The Analyst',
    exact: true,
  },
  // {
  //   path: `/how-we-work`,
  //   component: HowWeWork,
  //   name: 'How we work',
  //   exact: true,
  //   strict: true,
  // },
  {
    path: `/sample-research`,
    component: SampleResearch,
    name: 'Sample research',
    exact: true,
    strict: true,
  },
  {
    path: `/terms-of-use`,
    component: TermsOfUse,
    name: 'Terms of use',
    strict: true,
  },
  {
    path: `/privacy-policy`,
    component: PrivacyPolicy,
    name: 'Privacy & Cookie Policy',
    strict: true,
  },
  {
    path: `/disclaimers-n-disclosures`,
    component: DisclaimersNDisclosures,
    name: 'Disclaimers And Disclosures',
    strict: true,
  },
  {
    path: `/app`,
    component: LoginPermission(() => <Redirect to={`/`} />)(AnalystApp),
    name: 'Analyst',
    strict: true,
  },
  {
    path: `/create-password/:token?`,
    component: CreatePassword,
    name: 'Create Password',
    strict: true,
  },
  {
    path: '/about',
    component: AboutUs,
    name: 'AboutUs',
    exact: true,
    strict: true,
  },
  {
    path: '/people',
    component: OurPeople,
    name: 'OurPeople',
    exact: true,
    strict: true,
  },
  {
    path: '/press',
    component: Press,
    name: 'Press',
    exact: true,
    strict: true,
  },
  {
    path: '/press/:id/:title?',
    component: PressDetail,
    name: 'PressDetail',
    exact: true,
    strict: true,
  },
  {
    path: '/careers',
    component: Careers,
    name: 'Careers',
    exact: true,
    strict: true,
  },
  {
    path: '/contact',
    component: Contact,
    name: 'Contact',
    exact: true,
    strict: true,
  },
  {
    path: '/survey',
    component: LoginPermission(() => <Redirect to={`/`} />, true)(Survey),
    name: 'Survey',
    exact: true,
    strict: true,
  },
  {
    path: '/post/:postId/company/:companyId/feedback-chart',
    component: LoginPermission(() => <Redirect to={`/`} />, true)(CompanyFeedbackChart),
    name: 'CompanyFeedbackChart',
    exact: true,
    strict: true,
  },
  {
    path: `/${Env.ADMIN_BASE_PATH}`,
    component: AdminPermission()(Admin),
    name: 'Admin',
  },
  {
    path: `/404`,
    component: NotFound,
    name: 'Not Found',
    status: 404,
    exact: true,
  },
  {
    redirect: true,
    to: '/404',
    key: '404',
  },
];
