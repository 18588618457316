import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './style.scss';
import $ from 'jquery';

class Content extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    mediumHeight: PropTypes.bool,
  };

  /**
   * @description scroll top.
   */
  componentDidMount() {
    $('html,body').animate({ scrollTop: 0 }, 0);
  }

  /**
   * @returns {JSX.Element} - Header component
   */
  render() {
    return (
      <div
        className={classNames('main-content', this.props.className, {
          'medium-height': this.props.mediumHeight,
        })}>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(style)(Content);
