import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

class HeaderSection extends Component {
  static propTypes = {
    summary: PropTypes.object,
  };
  /**
   * @returns {JSX.Element} Edge component.
   */
  render() {
    const { summary } = this.props;
    return (
      <Grid fluid className="analytics-header-section">
        <Row className="header">
          <Col xs={3}>
            <div className="title">
              <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_LOGINS" />
            </div>
          </Col>
          <Col xs={3}>
            <div className="title">
              <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_DOWNLOADS" />
            </div>
          </Col>
          <Col xs={3}>
            <div className="title">
              <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_PAGE_VIEWS" />
            </div>
          </Col>
          <Col xs={3}>
            <div className="title">
              <FormattedMessage id="ADMIN.ANALYTICS.TOTAL_MOBILE_PAGE_VIEWS" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <div className="value">{summary.total_logins}</div>
          </Col>
          <Col xs={3}>
            <div className="value">{summary.total_downloads}</div>
          </Col>
          <Col xs={3}>
            <div className="value">{summary.total_views_web}</div>
          </Col>
          <Col xs={3}>
            <div className="value">{summary.total_views_mobile}</div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default HeaderSection;
