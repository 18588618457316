import { GET_ALL_GEOGRAPHY } from '../actions/geography';

/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const geographies = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_GEOGRAPHY: {
      return action.payload || [];
    }
    default:
      return state;
  }
};

export default geographies;
