import * as ViemoService from '../services/vimeo';

export const GET_VIMEO_DATA = 'GET_VIMEO_DATA';

/**
 * @description Gets vimeo data.
 *
 * @param {Object} data include url
 *
 * @returns {function}
 */
export const getVimeoData = (data) => async (dispatch) => {
  const vimeo = await ViemoService.getVimeoData(data);

  dispatch({
    type: GET_VIMEO_DATA,
    payload: vimeo,
  });

  return vimeo;
};
