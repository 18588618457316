import * as PostsService from 'modules/shared/services/posts';
import * as CompaniesService from 'modules/edge/services/companies';
import { getEdgeBookmarks } from 'modules/shared/actions/bookmarks';
import { Status } from '../../shared/util/http';

export const EDGE_BOOKMARK_POST = 'EDGE_BOOKMARK_POST';
export const GET_EDGE_POSTS = 'GET_EDGE_POSTS';
export const DELETE_EDGE_POST = 'DELETE_EDGE_POST';
export const GET_EDGE_POST = 'GET_EDGE_POST';
export const SAVE_EDGE_POST = 'SAVE_EDGE_POST';
export const MODIFY_EDGE_POST = 'MODIFY_EDGE_POST';
export const SAVE_EDGE_POST_FEATURED_IMAGE = 'SAVE_POST_FEATURED_IMAGE';
export const SAVE_EDGE_POST_CONTENT_IMAGE = 'SAVE_POST_CONTENT_IMAGE';

/**
 * @description Gets one page from the post.
 * @param {Object} options Paginating options.
 * @returns {Function}
 */
export const getPosts = (options) => async (dispatch) => {
  const posts = await PostsService.getPosts(options, 'edge/posts');

  dispatch({
    type: GET_EDGE_POSTS,
    payload: posts,
  });

  return posts;
};

/**
 * @description Gets one page from the post.
 * @param {Object} options Paginating options.
 * @returns {Function}
 */
export const getPostsPage = (options) => async () => {
  return await PostsService.getPostsPage(options, 'edge/posts');
};

/**
 * @description Gets the available companies.
 * @param {Object} search Parsed query params.
 * @returns {Function}
 */
export const getAzCompanies = (search) => async () => {
  return await CompaniesService.getAZCompanies(search);
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const bookmarkPost = (id) => async (dispatch) => {
  const bookmark = await PostsService.bookmarkpost(id, 'edge/posts');
  await dispatch(getEdgeBookmarks());

  dispatch({
    type: EDGE_BOOKMARK_POST,
    payload: bookmark,
  });

  return bookmark;
};

/**
 * @param {number} id post Id
 * @returns {Function}
 */
export const deletePost = (id) => async (dispatch) => {
  const post = await PostsService.deletePost(id, 'edge/posts');

  dispatch({
    type: DELETE_EDGE_POST,
    payload: post,
  });

  return post;
};

/**
 * @description Gets the post for the ID:
 * @param {number} id post ID.
 * @returns {Function}
 */
export const getPost = (id) => async (dispatch) => {
  try {
    const post = await PostsService.getPost(id, 'edge/posts');

    dispatch({
      type: GET_EDGE_POSTS,
      payload: post,
    });

    return post;
  } catch (e) {
    if (e.response && e.response.status === Status.NOT_FOUND) {
      e.ignoreToast = true;
    }
    throw e;
  }
};

/**
 * @param {Object} data post data.
 * @returns {Function}
 */
export const savePost = (data) => async (dispatch) => {
  const post = await PostsService.savePost(data, 'edge/posts');

  dispatch({
    type: SAVE_EDGE_POST,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const modifyPost = (id, data) => async (dispatch) => {
  const post = await PostsService.modifyPost(id, data, 'edge/posts');

  dispatch({
    type: MODIFY_EDGE_POST,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveFeaturedImage = (id, data) => async (dispatch) => {
  const post = await PostsService.saveFeaturedImage(id, data, 'edge/posts');

  dispatch({
    type: SAVE_EDGE_POST_FEATURED_IMAGE,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} data post data.
 * @returns {Function}
 */
export const saveContentImage = (id, data) => async (dispatch) => {
  const post = await PostsService.saveContentImage(id, data, 'edge/posts');

  dispatch({
    type: SAVE_EDGE_POST_FEATURED_IMAGE,
    payload: post,
  });

  return post;
};

/**
 * @param {number} id post Id
 * @param {Object} mediaId download file id.
 * @returns {Function}
 */
export const deleteDownloadFile = (id, mediaId) => async () => {
  return await PostsService.deleteDownloadFile(id, mediaId, 'edge/posts');
};

/**
 * @param {number} id post Id
 * @returns {Function}
 */
export const getMoreIdeas = (id) => async () => {
  return await PostsService.getMoreIdeas(id, 'edge/posts');
};
