import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import { ContactModalContainer, ContactModal, SuccessModal } from 'modules/shared';
import { ContactModalPages } from 'modules/analyst/models';
import FindOutButton from './FindOutButton';

import style from './Landing.scss';

/**
 * @returns {JSX.Element} - FindOut component
 */
const FindOut = () => {
  const [success, setSuccess] = useState(false);

  const openSuccessDialog = () => {
    setSuccess(true);
  };

  const closeSuccessDialog = () => {
    setSuccess(false);
  };

  return (
    <div>
      <ContactModalContainer page={ContactModalPages.MAIN_PAGE}>
        <ContactModal
          showFooter={false}
          onSuccess={openSuccessDialog}
          information={
            <div className="find-out-information">
              <FormattedMessage id="MAIN.CONTACT_MODAL.INFORMATION_FIND_OUT" />
            </div>
          }>
          {({ showProtectionContactModal }) => (
            <FindOutButton
              openSuccessDialog={openSuccessDialog}
              openContentDialog={showProtectionContactModal}
            />
          )}
        </ContactModal>
      </ContactModalContainer>
      <SuccessModal show={success} hide={closeSuccessDialog} />
    </div>
  );
};

export default withStyles(style)(FindOut);
