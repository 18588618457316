import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/withStyles';
import style from './ProfileImage.scss';
import classNames from 'classnames';
import Env from 'env';
import { Img } from 'modules/shared';

/**
 * @param {Object} data Props
 * @param {boolean} showBorder Props boolean
 *
 * @returns {JSX.Element} - Profile Image component
 */
const ProfileImage = ({ profile, showBorder }) => {
  return (
    <div>
      <div
        className={classNames('profile-image-border', {
          'is-border': showBorder,
          analyst: profile.analyst && !profile.edge,
          edge: profile.edge && !profile.analyst,
          'analyst-edge': profile.analyst && profile.edge,
        })}
      />
      <div className="profile-image">
        <Img src={Env.S3_URL + profile.image} alt={profile.fileAlt} title={profile.fileTitle} />
      </div>
    </div>
  );
};

ProfileImage.propTypes = {
  profile: PropTypes.object,
  showBorder: PropTypes.bool,
};

export default withStyles(style)(ProfileImage);
