import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getWalkThrough, saveWalkThrough } from 'modules/analyst/actions/walk-through';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import WalkThrough from './WalkThrough';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst }) => {
  return {
    walkThrough: analyst.walkThrough,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getWalkThrough, saveWalkThrough, showLoader, hideLoader }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WalkThrough);
