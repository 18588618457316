import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getGeographies } from 'modules/analyst/actions/geography';
import { getInvestmentStyles } from 'modules/analyst/actions/investment-style';
import { getMarketcaps } from 'modules/analyst/actions/marketcap';

import AnalystPreferences from './AnalystPreferences';

class AnalystPreferencesContainer extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getGeographies: PropTypes.func.isRequired,
    getInvestmentStyles: PropTypes.func.isRequired,
    getMarketcaps: PropTypes.func.isRequired,
  };

  /**
   * @description Gets data preferences
   */
  async componentDidMount() {
    this.props.showLoader();
    await Promise.all([
      this.props.getGeographies(),
      this.props.getInvestmentStyles(),
      this.props.getMarketcaps(),
    ]);
    this.props.hideLoader();
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    return <AnalystPreferences {...this.props} />;
  }
}

/**
 * @param {Object} state Application State
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = (state) => {
  return {
    investmentStyles: state.analyst.investmentStyles || [],
    geographies: state.analyst.geographies || [],
    marketcap: state.analyst.marketcap || [],
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getGeographies,
      getInvestmentStyles,
      getMarketcaps,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalystPreferencesContainer);
