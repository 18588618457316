import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './style.scss';

/**
 * @returns {JSX.Element}
 */
const ModalGrid = ({ children }) => {
  return (
    <Grid className="modal-grid">
      <Row>{children}</Row>
    </Grid>
  );
};

ModalGrid.propTypes = {
  children: PropTypes.node,
};

export default withStyles(styles)(ModalGrid);
