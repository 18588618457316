import request, { Methods } from 'modules/shared/util/request';
import { getPaginationQueryParams } from 'common';

/**
 * @description Get the list of all settings.
 * @param {Object} options Pagination param
 * @returns {Promise}
 */
export const getSettings = async (options) => {
  return await request({
    resource: `systemsetting${getPaginationQueryParams({ ...options })}`,
  });
};

/**
 * @param {number} id setting ID
 *
 * @returns {Promise}
 */
export const deleteSetting = async (id) => {
  return await request({
    resource: `systemsetting/${id}`,
    method: Methods.DELETE,
  });
};

/**
 * @param {number} id setting ID
 *
 * @returns {Promise}
 */
export const getSetting = async (id) => {
  return await request({
    resource: `systemsetting/${id}`,
  });
};

/**
 * @param {Object} data setting object
 *
 * @returns {Promise}
 */
export const saveSetting = async (data) => {
  return await request({
    resource: `systemsetting`,
    method: Methods.POST,
    data,
  });
};

/**
 * @param {number} id setting ID
 * @param {Object} data setting object
 * @returns {Promise}
 */
export const modifySetting = async (id, data) => {
  return await request({
    resource: `systemsetting/${id}`,
    method: Methods.PUT,
    data,
  });
};
