import * as LongScreenService from 'modules/analyst/services/long-screen';

/**
 * @description Gets Long screen data.
 * @returns {any}
 */
export const getLongScreen = () => async () => {
  const response = await LongScreenService.getLongScreen();

  return response;
};

/**
 * @description Gets Long screen factors.
 * @returns {any}
 */
export const getLongScreenFactors = () => async () => {
  const response = await LongScreenService.getLongScreenFactors();

  return response;
};

/**
 * @description Gets Long screen singlepage data.
 * @returns {any}
 */
export const getLongScreenSinglepage = () => async () => {
  const response = await LongScreenService.getLongScreenSinglepage();

  return response;
};

/**
 * @param {Array<ID>} factors list of factors
 * @description Votes on long screen
 * @returns {void}
 */
export const voteOnFactors = (factors) => async () => {
  await LongScreenService.voteOnFactors(factors);
};

/**
 * @description Closes voting window
 * @returns {void}
 */
export const closeWindow = () => async () => {
  await LongScreenService.closeWindow();
};

/**
 * @param {Object} data Long screen data to save
 * @description Saves long screen data
 * @returns {void}
 */
export const saveLongScreen = (data) => async () => {
  await LongScreenService.saveLongScreen(data);
};
