import request, { Methods } from 'modules/shared/util/request';

/**
 * @description Returns the list of available ideas.
 * @returns {Promise}
 */
export const getRecommendations = () => {
  return request({ resource: 'recommendations' });
};

/**
 * @description Returns the list of sentiment tracker ideas.
 * @returns {Promise}
 */
export const getSentimentTrackerRecommendations = async () => {
  const response = await request({ resource: 'sentiment-tracker/recommendations' });
  Object.keys(response).forEach((key) => {
    response[key] = response[key]
      ? response[key].map((item) => ({
          ...item,
          like_questions: [{ ...item.like_question, vote_results: item.vote_results }],
        }))
      : response[key];
  });

  return response;
};

/**
 * @param {Array} recommendations Recommendation list to save
 *
 * @returns {Promise}
 */
export const saveRecommendations = (recommendations) => {
  return request({
    resource: 'recommendations',
    method: Methods.POST,
    data: recommendations,
  });
};
