import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history, intersection, location, match, setToken } from 'common';
import qs from 'query-string';
import jwtDecode from 'jwt-decode';
import { bindActionCreators } from 'redux';
import { getProfile } from 'modules/main/actions/users';
import { SpinnerOverlay } from 'modules/shared';

/**
 * @description Generates the merged string array of roles and subscriptions
 * @param {Object} profile Profile object.
 * @returns {any[]}
 */
export const getRawSubscriptions = (profile = {}) => {
  return [...(profile.subscriptions || []), ...(profile.roles || [])].map(
    ({ chargebee_plan, role_slug }) => chargebee_plan || role_slug
  );
};

/**
 * @param {Array} allowedRoles Allowed roles array.
 * @param {Array} neededSubscriptions User's subscription array.
 * @param {Object} profile User profile object.
 *
 * @returns {boolean}
 */
export const checkPermission = (allowedRoles = [], neededSubscriptions = [], profile) => {
  if (!allowedRoles.length && !neededSubscriptions.length) {
    return true;
  }
  const rawSubscriptions = getRawSubscriptions(profile);
  const rawRoles = profile.roles ? profile.roles.map(({ role_slug }) => role_slug) : [];

  const roleIntersection = intersection(rawRoles, allowedRoles);
  const subscriptionIntersection = intersection(rawSubscriptions, neededSubscriptions);

  return !!roleIntersection.length || !!subscriptionIntersection.length;
};

/**
 * @param {Object} state - state object.
 *
 * @returns {Object} Profile.
 */
const mapProfile = ({ app }) => {
  return { profile: app.profile };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getProfile }, dispatch);
};

/**
 * @returns {JSX.Element} 404 redirect.
 */
export const RedirectNotFound = () => <Redirect to="/" />;

/**
 * @param {Array} allowedRoles Allowed roles for the specified component.
 * @param {Array} neededSubscriptions Needed subscriptions for the specified component.
 *
 * @returns {Function}
 */
const Authorization = (allowedRoles = [], neededSubscriptions) => (
  UnauthenticatedComponent = RedirectNotFound,
  getTokenParam = false
) => (WrappedComponent) => {
  return connect(
    mapProfile,
    mapDispatchToProps
  )(
    class WithAuthorization extends Component {
      static propTypes = {
        getProfile: PropTypes.func.isRequired,
        profile: PropTypes.object,
        match,
        history,
        location,
      };

      state = {
        loadingToken: true,
      };

      /**
       * @description Gets token if needed.
       */
      async componentDidMount() {
        const { profile } = this.props;
        const loadingToken = !profile || getTokenParam;

        this.setState({ loadingToken });

        if (getTokenParam) {
          const searchParams = qs.parse(this.props.location.search);
          if (searchParams.token) {
            const { exp } = jwtDecode(searchParams.token);
            setToken({ access_token: searchParams.token, exp });
            await this.props.getProfile();

            this.props.history.replace({
              path: this.props.match.path,
              search: '',
            });
          }
          this.setState({ loadingToken: false });
        } else if (!profile) {
          try {
            await this.props.getProfile();
          } finally {
            this.setState({ loadingToken: false });
          }
        }
      }

      /**
       * @returns {JSX.Element}
       */
      render() {
        const { profile } = this.props;

        if (this.state.loadingToken) {
          return <SpinnerOverlay show={1} />;
        } else if (profile) {
          const hasRoles = profile.roles && profile.roles.length;
          const hasSubscriptions = profile.subscriptions && profile.subscriptions.length;

          if (
            ((hasRoles || hasSubscriptions) &&
              checkPermission(allowedRoles, neededSubscriptions, profile)) ||
            (typeof profile.id !== 'undefined' && !hasRoles)
          ) {
            return <WrappedComponent {...this.props} />;
          } else {
            return UnauthenticatedComponent ? <UnauthenticatedComponent /> : null;
          }
        } else {
          return UnauthenticatedComponent ? <UnauthenticatedComponent /> : null;
        }
      }
    }
  );
};

class AuthWrapper extends Component {
  static propTypes = {
    profile: PropTypes.object,
    unauthenticatedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    children: PropTypes.node,
    allowedRoles: PropTypes.array,
    neededSubscriptions: PropTypes.array,
    anySubscription: PropTypes.bool,
  };

  static defaultProps = {
    allowedRoles: [],
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      profile,
      unauthenticatedComponent,
      children,
      allowedRoles,
      neededSubscriptions,
      anySubscription,
      ...props
    } = this.props;
    const { roles, subscriptions } = profile;
    const hasRoles = roles && roles.length;
    const hasSubscriptions = subscriptions && subscriptions.length;
    if (
      (hasRoles || hasSubscriptions) &&
      (checkPermission(allowedRoles, neededSubscriptions, profile) ||
        (anySubscription && subscriptions.length))
    ) {
      return children;
    } else {
      return unauthenticatedComponent
        ? typeof unauthenticatedComponent === 'function'
          ? unauthenticatedComponent(props)
          : unauthenticatedComponent
        : null;
    }
  }
}

export const Roles = {
  AnalystAdmin: 'analyst-admin',
  AnalystEditor: 'analyst-editor',
  AnalystSubscriber: 'analyst-subscriber',
  EdgeAdmin: 'edge-admin',
  EdgeEditor: 'edge-editor',
  EdgeSubscriber: 'edge-subscriber',
};

export const Subscriptions = {
  US: 'us',
  UK: 'uk',
  Europe: 'europe',
  EmergingMarkets: 'emerging-markets',
  Global: 'global',
  EdgePlus: 'edge-plus',
};

export const EdgeAllSubscription = [
  Roles.EdgeEditor,
  Roles.EdgeAdmin,
  Roles.EdgeSubscriber,
  Subscriptions.EdgePlus,
  Subscriptions.Global,
];

export const CanAccess = connect(mapProfile, null, null, { pure: false })(AuthWrapper);

export const AdminRoles = [
  Roles.AnalystAdmin,
  Roles.AnalystEditor,
  Roles.EdgeAdmin,
  Roles.EdgeEditor,
];

export const LoginPermission = Authorization();
export const AdminPermission = Authorization(AdminRoles);

export default Authorization;
