import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';

/**
 * @param {Object} post Post object
 * @param {string} keyFactsKey Post key facts key
 * @returns {*}
 * @constructor
 */
const KeyFacts = ({ post, keyFactsKey }) => {
  return (
    <div className="key-facts">
      <div className="hidden-md hidden-lg">
        <Row>
          <div className="d-flex flex-wrap">
            <Col xs={6} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.RECOMMENDATION" />:
                <div className="value">
                  {post[`recommendation${keyFactsKey}`]
                    ? post[`recommendation${keyFactsKey}`]
                    : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.PRICE" />:
                <div className="value">
                  {post[`price${keyFactsKey}`] ? post[`price${keyFactsKey}`] : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.MARKET_CAPITALIZATION" />:
                <div className="value">
                  {post[`market_cap${keyFactsKey}`] ? post[`market_cap${keyFactsKey}`] : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="MAIN.3M_AVERAGE" />:
                <div className="value">
                  {post[`3m_average${keyFactsKey}`] ? post[`3m_average${keyFactsKey}`] : '--'}
                </div>
              </div>
              {post[`current_multiple${keyFactsKey}`] && (
                <div className="key-fact-item">
                  <FormattedMessage id="ANALYST.POST_DETAIL.CURRENT_MULTIPLE" />:
                  <div className="value">{post[`current_multiple${keyFactsKey}`]}</div>
                </div>
              )}
            </Col>
            <Col xs={6} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.FORECAST_RETURN" />:
                <div className="value">
                  {post[`forecast_return${keyFactsKey}`]
                    ? post[`forecast_return${keyFactsKey}`]
                    : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.PRICE_TARGET" />:
                <div className="value">
                  {post[`price_target${keyFactsKey}`] ? post[`price_target${keyFactsKey}`] : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.TICKER" />:
                <div className="value">
                  {post[`ticker${keyFactsKey}`] ? post[`ticker${keyFactsKey}`] : '--'}
                </div>
              </div>
              {post[`valuation_metric${keyFactsKey}`] && (
                <div className="key-fact-item">
                  <FormattedMessage id="ANALYST.POST_DETAIL.VALUATION_METRIC" />:
                  <div className="value">{post[`valuation_metric${keyFactsKey}`]}</div>
                </div>
              )}
              {post[`target_multiple${keyFactsKey}`] && (
                <div className="key-fact-item">
                  <FormattedMessage id="ANALYST.POST_DETAIL.TARGET_MULTIPLE" />:
                  <div className="value">{post[`target_multiple${keyFactsKey}`]}</div>
                </div>
              )}
            </Col>
          </div>
        </Row>
      </div>
      <div className="visible-md visible-lg">
        <Row>
          <Col xs={12} className="key-fact-item d-flex flex-wrap">
            <FormattedMessage id="ANALYST.POST_DETAIL.RECOMMENDATION" />:{' '}
            <span className="value ml-2">
              {post[`recommendation${keyFactsKey}`] ? post[`recommendation${keyFactsKey}`] : '--'}
            </span>
          </Col>
        </Row>
        <Row>
          <div className="d-flex flex-wrap">
            <Col md={4} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.PRICE" />:
                <div className="value">
                  {post[`price${keyFactsKey}`] ? post[`price${keyFactsKey}`] : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="MAIN.3M_AVERAGE" />:
                <div className="value">
                  {post[`3m_average${keyFactsKey}`] ? post[`3m_average${keyFactsKey}`] : '--'}
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.PRICE_TARGET" />:
                <div className="value">
                  {post[`price_target${keyFactsKey}`] ? post[`price_target${keyFactsKey}`] : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.MARKET_CAPITALIZATION" />:
                <div className="value">
                  {post[`market_cap${keyFactsKey}`] ? post[`market_cap${keyFactsKey}`] : '--'}
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.FORECAST_RETURN" />:
                <div className="value">
                  {post[`forecast_return${keyFactsKey}`]
                    ? post[`forecast_return${keyFactsKey}`]
                    : '--'}
                </div>
              </div>
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.TICKER" />:
                <div className="value">
                  {post[`ticker${keyFactsKey}`] ? post[`ticker${keyFactsKey}`] : '--'}
                </div>
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          {post[`valuation_metric${keyFactsKey}`] && (
            <Col md={4} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.VALUATION_METRIC" />:
                <div className="value">{post[`valuation_metric${keyFactsKey}`]}</div>
              </div>
            </Col>
          )}
          {post[`current_multiple${keyFactsKey}`] && (
            <Col md={4} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.CURRENT_MULTIPLE" />:
                <div className="value">{post[`current_multiple${keyFactsKey}`]}</div>
              </div>
            </Col>
          )}
          {post[`target_multiple${keyFactsKey}`] && (
            <Col md={4} className="d-flex flex-column fact-column">
              <div className="key-fact-item">
                <FormattedMessage id="ANALYST.POST_DETAIL.TARGET_MULTIPLE" />:
                <div className="value">{post[`target_multiple${keyFactsKey}`]}</div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

KeyFacts.propTypes = {
  post: PropTypes.object.isRequired,
  keyFactsKey: PropTypes.string,
};

KeyFacts.defaultProps = {
  keyFactsKey: '',
};

export default KeyFacts;
