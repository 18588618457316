import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer';
import errorMiddleware from 'common/errorMiddleware';
import { isBrowser } from 'modules/shared/util/isBrowser';

/**
 * @description Store configuration from the preloaded state.
 *              Adds redux-thunk middleware and redux devtools extension on dev environment.
 * @param {Object} preloadedState State from server to initialize store.
 *
 * @returns {Object} Store object with preloaded data.
 */
const configureStore = (preloadedState) => {
  const enhancers = [];
  const middleware = [errorMiddleware, thunk];

  if (process.env.NODE_ENV === 'development' && global.window) {
    if (isBrowser()) {
      const devToolsExtension = window && window.devToolsExtension;

      if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
      }
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducer', () => {
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
