import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routeProps } from 'common/propTypes';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

export const withSSRProps = {
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

/**
 * @description Higher Order Component that abstracts duplicated data fetching on the server and client.
 * @param {React.Component} Page Component which needs prelodaded data from server.
 * @returns {React.Component} Wrapped component
 */
export default function withSSR(Page) {
  class SSR extends React.Component {
    static propTypes = {
      initialData: PropTypes.object,
      dispatch: PropTypes.func,
      ...routeProps,
    };

    /**
     * @param {Object} ctx Context object from the server, see server rendering.
     *
     * @returns {Promise} Returns the promise of the initial data fetching.
     */
    static getInitialData(ctx) {
      // Need to call the wrapped components getInitialData if it exists
      return Page.getInitialData ? Page.getInitialData(ctx) : Promise.resolve(null);
    }

    state = {
      data: this.props.initialData,
      isLoading: this.isLoading(true),
    };

    ignoreLastFetch = false;

    /**
     * @description Determines the loading state, on the server the loading state for the components
     *              doesn't makes sense and brakes SSR.
     * @param {boolean} loading Computed state should be loading.
     * @returns {boolean}
     */
    isLoading(loading) {
      const isServer = !!this.props.staticContext;

      return !isServer && loading;
    }

    /**
     * @description Fetch the data after the component mounts.
     */
    componentDidMount() {
      this.mounted = true;
      if (!this.state.data) {
        this.fetchData();
      }
    }

    /**
     * @description Sets the mounted to false to prevent unnecessary setState on component.
     *              And ignoreLastFetch to true to prevent unnecessary fetching.
     */
    componentWillUnmount() {
      this.ignoreLastFetch = true;
      this.mounted = false;
    }

    /**
     * @param {Object} options of get data
     * @description if this.state.data is null, that means that the we are on the client.
     *              To get the data we need, we just call getInitialData again on mount.
     */
    fetchData = async (options) => {
      if (!this.ignoreLastFetch) {
        if (!this.props.staticContext && this.props.dispatch) {
          this.props.dispatch(showLoader());
        }
        this.setState({ isLoading: this.isLoading(true) });
        try {
          const data = await SSR.getInitialData({
            match: this.props.match,
            location: this.props.location,
            dispatch: this.props.dispatch,
            options,
          });
          if (this.mounted) {
            this.setState({ data, isLoading: this.isLoading(false) });
          }
        } catch (error) {
          if (this.mounted) {
            this.setState(() => ({
              data: { error },
              isLoading: this.isLoading(false),
            }));
          }
        }
        if (!this.props.staticContext && this.props.dispatch) {
          this.props.dispatch(hideLoader());
        }
      }
    };

    /**
     * @returns {JSX.Element} Wrapped Page with extra props.
     */
    render() {
      const { initialData, ...rest } = this.props;
      const data = { ...this.props.route.data, ...this.state.data };
      return (
        <Page {...rest} refetch={this.fetchData} isLoading={this.state.isLoading} data={data} />
      );
    }
  }

  SSR.displayName = `SSR(${getDisplayName(Page)})`;
  return connect()(SSR);
}

//
/**
 * @description This make debugging easier. Components will show as SSR(MyComponent) inreact-dev-tools.
 *
 * @param {React.Component} component Wrapped component.
 *
 * @returns {string} Displayed name
 */
const getDisplayName = (component) => {
  return component.displayName || component.name || 'Component';
};
