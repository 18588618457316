import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from '../progress-timer/ProgressTimer.scss';

class ProgressTimer extends Component {
  static propTypes = {
    className: PropTypes.string,
    play: PropTypes.bool,
    pause: PropTypes.bool,
    stop: PropTypes.bool,
    maxTime: PropTypes.number,
    updateProgress: PropTypes.func,
  };

  static defaultProps = {
    maxTime: 10000,
    play: false,
    pause: true,
    stop: false,
  };

  lastTime = null;
  startTime = null;
  progress = React.createRef();

  state = {
    elapsed: this.props.maxTime, // in ms
    play: false,
    pause: true,
    stop: false,
  };

  /**
   * @param {function} callback is function that call
   * @description create progress timer
   * */
  requestAnimFrame(callback) {
    global.window.requestAnimationFrame(callback) ||
      global.window.webkitRequestAnimationFrame(callback) ||
      global.window.mozRequestAnimationFrame(callback) ||
      global.window.setTimeout(callback, 1000 / 60);
  }

  /**
   * @description call checkProgressStatus func
   * */
  componentDidMount() {
    this.checkProgressStatus();
  }

  /**
   * @description call checkProgressStatus func
   * */
  componentDidUpdate() {
    this.checkProgressStatus();
  }

  /**
   * @description check progress status changes
   * */
  checkProgressStatus() {
    const { play, pause, stop } = this.props;
    if (play !== this.state.play || pause !== this.state.pause || stop !== this.state.stop) {
      this.setState({ play, pause, stop }, () => {
        if (play) {
          this.startTimer();
        }
        if (pause) {
          this.pause();
        }
        if (stop) {
          this.stop();
        }
      });
    }
  }

  /**
   * @description execute progress timer with animation
   * */
  timer = () => {
    if (this.state.pause || this.state.elapsed <= 0) {
      return;
    }

    this.requestAnimFrame(this.timer);

    const currentTime = new Date().getTime();
    const ms = currentTime - this.lastTime;
    this.lastTime = currentTime;
    const portion = this.state.elapsed / this.props.maxTime;

    this.setWidth(portion);
    this.setState({ elapsed: this.state.elapsed - ms }, () => {
      if (this.props.updateProgress) {
        this.props.updateProgress(this.state.elapsed);
      }
    });
  };

  /**
   * @param {number} portion is the portion of full width, depend on left time
   * @description set with of progress bar
   * */
  setWidth(portion) {
    const maxWidth = this.progress.current.offsetParent.clientWidth;
    const width = maxWidth * portion;

    this.progress.current.style.width = `${width}px`;
  }

  /**
   * @description pause progress
   * */
  pause() {
    this.setState({ pause: true });
  }

  /**
   * @description stop progress, set progress to start point
   * */
  stop() {
    this.setState({ pause: true, stop: true, elapsed: this.props.maxTime });
  }

  /**
   * @description start progress bar animation
   * */
  startTimer() {
    if (this.state.stop) {
      this.startTime = this.lastTime;
    }
    this.lastTime = new Date().getTime();

    this.setState({ pause: false, stop: false });

    this.timer();
  }

  /**
   * @returns {JSX.Element} - Progress Timer component
   */
  render() {
    const { className } = this.props;
    return (
      <div className={classNames('progress-timer-container', className)}>
        <div className="progress-timer" ref={this.progress} />
      </div>
    );
  }
}

export default withStyles(style)(ProgressTimer);
