import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldGroup, Select } from 'modules/admin/shared/components';
import styles from './InformationsList.scss';
import InformationFiles from './InformationFiles';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AddPlaceholder, DragHandle, Delete } from 'modules/admin/shared';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { Row, Col, Well } from 'react-bootstrap';
import { isValidSchema, informationTypes, pagesFileUpload } from 'common';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getVimeoData } from 'modules/admin/main/actions/vimeo';
import { debounce } from 'common';
import { pagesUploadFile } from 'modules/admin/main/actions/file-upload';
import { FormattedMessage } from 'react-intl';

const typeSchema = {
  type: Yup.object().nullable(),
};

const descriptionSchema = {
  description: Yup.string().nullable(),
};

const titleSchema = {
  title: Yup.string().required(),
};

const linkSchema = {
  link: Yup.string().nullable(),
};

const videoLinkSchema = {
  videoLink: Yup.string()
    .url()
    .nullable(),
};

const SortableBlock = SortableElement(
  ({
    block,
    onChange,
    removeInformationType,
    informationTypeIndex,
    flattenSelectEvent,
    onDragEnd,
    addFile,
    removeFile,
    onDropFile,
    deleteFile,
    onChangeFile,
    onChangeCheckbox,
  }) => (
    <Col sm={12} className="information-section">
      <DragHandle />
      <Delete onSubmit={() => removeInformationType(informationTypeIndex)} />

      <FieldGroup
        label={<FormattedMessage id="ADMIN.TITLE" />}
        name="sectionTitle"
        value={block.sectionTitle}
        onChange={(event) => onChange(event, informationTypeIndex)}
      />

      <Select
        name="type"
        label="Type"
        value={block.type}
        valueRenderer={() => {
          return block.type.name;
        }}
        bottomMargin
        onChange={(e) => onChange(flattenSelectEvent(e), informationTypeIndex)}
        options={informationTypes.map((type) => ({
          label: type.name,
          value: type,
        }))}
      />

      <FieldGroup
        componentClass="textarea"
        label="Description"
        name="description"
        value={block.description}
        onChange={(event) => onChange(event, informationTypeIndex)}
      />

      <InformationFiles
        informationIndex={informationTypeIndex}
        files={block.files}
        type={block.type}
        onDropFile={onDropFile}
        deleteFile={deleteFile}
        addFile={addFile}
        onChangeFile={onChangeFile}
        onChangeCheckbox={onChangeCheckbox}
        removeFile={removeFile}
        onDragEnd={onDragEnd}
      />
    </Col>
  )
);

const SortableBlockContainer = SortableContainer(
  ({
    title,
    protectWithContactModal,
    informationsList,
    onChange,
    flattenSelectEvent,
    addInformationType,
    removeInformationType,
    onDragEnd,
    addFile,
    removeFile,
    onChangeFile,
    onDropFile,
    deleteFile,
    onChangeCheckbox,
  }) => {
    return (
      <div>
        <Row>
          <Col sm={12}>
            <FieldGroup
              label={<FormattedMessage id="ADMIN.TITLE" />}
              name="title"
              value={title}
              onChange={onChange}
            />
          </Col>
          <Col sm={12}>
            <FieldGroup
              label={<FormattedMessage id="ADMIN.PROTECT_ALL_WITH_CONTACT_MODAL" />}
              name="protectWithContactModal"
              inline
              type="checkbox"
              checked={protectWithContactModal}
              onChange={onChange}
            />
            <Well bsStyle="warning">
              <FormattedMessage id="ADMIN.PROTECT_ALL_WITH_CONTACT_MODAL_EXPLAIN" />
            </Well>
          </Col>
          {informationsList &&
            informationsList.map((block, index) => (
              <SortableBlock
                index={index}
                key={index}
                onDropFile={onDropFile}
                deleteFile={deleteFile}
                informationTypeIndex={index}
                block={block}
                onChange={onChange}
                flattenSelectEvent={flattenSelectEvent}
                removeInformationType={removeInformationType}
                addFile={addFile}
                removeFile={removeFile}
                onChangeFile={onChangeFile}
                onChangeCheckbox={onChangeCheckbox}
                onDragEnd={onDragEnd}
              />
            ))}
          {informationsList && informationsList.length < informationTypes.length && (
            <Col sm={12} key="add">
              <AddPlaceholder onClick={addInformationType} />
            </Col>
          )}
        </Row>
      </div>
    );
  }
);

class InformationsList extends Component {
  static componentType = 'informations-list';
  static blockName = 'Informations List';
  static icon = 'far fa-registered';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    setReady: PropTypes.func.isRequired,
    pagesUploadFile: PropTypes.func.isRequired,
    getVimeoData: PropTypes.func.isRequired,
    site: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
  };

  state = {
    title: '',
    protectWithContactModal: false,
    informationsList: [InformationsList.getInformationSection()],
  };

  /**
   * @returns {Object} Empty information object.
   */
  static getInformationSection() {
    return {
      type: {},
      sectionTitle: '',
      description: '',
      files: [InformationsList.getInformationFile()],
    };
  }

  /**
   * @returns {Object} Empty information file object.
   */
  static getInformationFile() {
    return {
      title: '',
      link: '',
      videoLink: '',
      file: {},
    };
  }

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    const { valid, informationsList, title, protectWithContactModal } = this.props.data;
    this.setState({
      valid: !!valid,
      informationsList: informationsList || [InformationsList.getInformationSection()],
      title: title || '',
      protectWithContactModal: protectWithContactModal || '',
    });
  }

  /**
   * @returns {boolean} header is valid.
   */
  async isValidForm() {
    const schema = Yup.array().of(
      Yup.object().shape({
        ...typeSchema,
        ...descriptionSchema,
        files: Yup.array().of(
          Yup.object().shape({
            ...titleSchema,
            ...linkSchema,
            ...videoLinkSchema,
          })
        ),
      })
    );

    return await schema.isValid(this.state.informationsList);
  }

  onChange = async (e, informationTypeIndex) => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    const type = e.target.type;

    if (informationTypeIndex || informationTypeIndex === 0) {
      const informationsList = this.state.informationsList.map((data) => ({ ...data }));
      informationsList[informationTypeIndex][name] = !value && name === 'type' ? {} : value;

      this.setState(
        {
          informationsList,
        },
        async () => {
          informationsList[informationTypeIndex] = await this.validateField(
            name,
            informationsList[informationTypeIndex],
            value
          );

          this.setState({
            informationsList,
          });

          const valid = await this.isValidForm();
          this.props.onChange({ informationsList, valid });
        }
      );
    } else {
      this.setState(
        {
          [name]: type === 'checkbox' ? checked : value,
        },
        async () => {
          const valid = await this.isValidForm();
          this.props.onChange({ ...this.state, valid });
        }
      );
    }
  };

  validateField = async (name, information, validateData) => {
    switch (name) {
      case 'type': {
        const { isValid } = await isValidSchema(typeSchema, {
          type: validateData,
        });
        information.validType = isValid;
        return information;
      }
      case 'title': {
        const { isValid } = await isValidSchema(titleSchema, {
          title: validateData,
        });
        information.validTitle = isValid;
        return information;
      }
      case 'link': {
        const { isValid } = await isValidSchema(linkSchema, {
          link: validateData,
        });
        information.validLink = isValid;
        return information;
      }
      case 'videoLink': {
        const { isValid } = await isValidSchema(videoLinkSchema, {
          videoLink: validateData,
        });
        information.validVideoLink = isValid;
        return information;
      }
      default:
        return information;
    }
  };

  /**
   * @param {Event} e Event object
   * @returns {Object} Fake Event object
   */
  flattenSelectEvent = (e) => {
    return {
      ...e,
      target: { ...e.target, value: e.target.value ? e.target.value.value : '', type: 'select' },
    };
  };

  addInformationType = () => {
    this.setState(
      (prevState) => {
        return {
          informationsList: [
            ...prevState.informationsList,
            InformationsList.getInformationSection(),
          ],
        };
      },
      async () => {
        const valid = await this.isValidForm();
        this.props.onChange({
          informationsList: this.state.informationsList,
          valid,
        });
      }
    );
  };

  removeInformationType = async (index) => {
    const informationsList = this.state.informationsList.map((data) => data);
    informationsList.splice(index, 1);
    this.setState({ informationsList });
    const valid = await this.isValidForm();
    this.props.onChange({ informationsList, valid });
  };

  getEditedInformationsList = async (name, value, informationIndex, fileIndex) => {
    const informationsList = this.state.informationsList.map((data) => ({ ...data }));
    informationsList[informationIndex].files[fileIndex][name] = value;
    informationsList[informationIndex].files[fileIndex] = await this.validateField(
      name,
      informationsList[informationIndex].files[fileIndex],
      value
    );

    return informationsList;
  };

  saveEditedInformationsList = async () => {
    const valid = await this.isValidForm();
    this.props.onChange({
      informationsList: this.state.informationsList,
      valid,
    });
  };

  onChangeFile = async (event, informationIndex, fileIndex) => {
    const informationsList = await this.getEditedInformationsList(
      event.target.name,
      event.target.value,
      informationIndex,
      fileIndex
    );

    this.setState({ informationsList }, async () => {
      const file = informationsList[informationIndex].files[fileIndex];
      if (
        this.state.informationsList[informationIndex].type.name === 'Video' &&
        file.validVideoLink
      ) {
        this.getVimeoData(file.videoLink, informationIndex, fileIndex);
      }
      this.saveEditedInformationsList();
    });
  };

  onChangeCheckbox = async (event, informationIndex, fileIndex) => {
    const informationsList = await this.getEditedInformationsList(
      event.target.name,
      event.target.checked,
      informationIndex,
      fileIndex
    );

    this.setState({ informationsList }, async () => {
      this.saveEditedInformationsList();
    });
  };

  getVimeoData = debounce(async (url, informationIndex, fileIndex) => {
    this.props.setReady(false);
    const informationsList = this.state.informationsList.map((data) => ({ ...data }));
    const response = await this.props.getVimeoData({ url });
    informationsList[informationIndex].files[fileIndex].videoEmbed = response.embed;
    informationsList[informationIndex].files[fileIndex].metadata = response.metadata;
    informationsList[informationIndex].files[fileIndex].thumbnails = response.thumbnails;
    this.setState({ informationsList });
    this.props.onChange({ informationsList });
    this.props.setReady(true);
  }, 1000);

  addFile = (informationIndex) => {
    const informationsList = this.state.informationsList.map((data) => data);

    informationsList[informationIndex].files.push(InformationsList.getInformationFile());

    this.setState({ informationsList }, async () => {
      const valid = await this.isValidForm();
      this.props.onChange({
        informationsList: this.state.informationsList,
        valid,
      });
    });
  };

  removeFile = (informationIndex, fileIndex) => {
    const informationsList = this.state.informationsList.map((data) => data);
    informationsList[informationIndex].files.splice(fileIndex, 1);

    this.setState({ informationsList }, async () => {
      const valid = await this.isValidForm();
      this.props.onChange({
        informationsList: this.state.informationsList,
        valid,
      });
    });
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        informationsList: arrayMove(this.state.informationsList, oldIndex, newIndex),
      },
      async () => {
        const valid = await this.isValidForm();
        this.props.onChange({
          informationsList: this.state.informationsList,
          valid,
        });
      }
    );
  };

  onDropFile = async (files, informationIndex, fileIndex) => {
    const informationsList = this.state.informationsList.map((data) => ({ ...data }));
    informationsList[informationIndex].files[fileIndex].loading = true;

    this.setState({ informationsList });

    try {
      const { path } = await pagesFileUpload(
        this.props.site,
        this.props.slug,
        files,
        this.props.pagesUploadFile
      );

      informationsList[informationIndex].files[fileIndex].link = path;
      informationsList[informationIndex].files[fileIndex].file = {};
      informationsList[informationIndex].files[fileIndex].file.file_name = files[0].name;
      delete informationsList[informationIndex].files[fileIndex].loading;

      this.setState({ informationsList }, async () => {
        const valid = await this.isValidForm();
        this.props.onChange({
          informationsList: this.state.informationsList,
          valid,
        });
      });
    } catch (e) {
      delete informationsList[informationIndex].files[fileIndex].loading;

      this.setState({ informationsList }, async () => {
        const valid = await this.isValidForm();
        this.props.onChange({
          informationsList: this.state.informationsList,
          valid,
        });
      });
    }
  };

  deleteFile = async (informationIndex, fileIndex) => {
    const informationsList = this.state.informationsList.map((data) => ({ ...data }));

    informationsList[informationIndex].files[fileIndex].link = null;
    informationsList[informationIndex].files[fileIndex].file = {};
    informationsList[informationIndex].files[fileIndex].file.file_name = '';

    this.setState({ informationsList });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="information-sections">
        <SortableBlockContainer
          helperClass=""
          onDropFile={this.onDropFile}
          deleteFile={this.deleteFile}
          title={this.state.title}
          protectWithContactModal={this.state.protectWithContactModal}
          informationsList={this.state.informationsList}
          flattenSelectEvent={this.flattenSelectEvent}
          onChange={this.onChange}
          removeInformationType={this.removeInformationType}
          addInformationType={this.addInformationType}
          addFile={this.addFile}
          removeFile={this.removeFile}
          onChangeFile={this.onChangeFile}
          onChangeCheckbox={this.onChangeCheckbox}
          onSortEnd={this.onDragEnd}
          onDragEnd={this.onDragEnd}
          useWindowAsScrollContainer
          useDragHandle
          axis="xy"
          delay={100}
        />
      </div>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVimeoData,
      pagesUploadFile,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(InformationsList));
