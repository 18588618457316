import { connect } from 'react-redux';
import { getPost } from 'modules/analyst/actions/posts';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

import CompanyFeedbackChart from './CompanyFeedbackChart';

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  getPost,
};

export default connect(null, mapDispatchToProps)(CompanyFeedbackChart);
