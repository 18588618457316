import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './ImageComponent.scss';
import { Grid, Row, Col } from 'react-bootstrap';
import Env from 'env';
import { Img } from 'modules/shared';

/**
 * @returns {JSX.Element} - ImageComponent component
 */
const ImageComponent = ({ data }) => {
  return (
    <Grid fluid className="image-component">
      <Row>
        <Col sm={12} className="image-title">
          <h3>{data.title}</h3>
        </Col>
        <Col sm={12} className="image-content">
          <Img alt={data.fileAlt} title={data.fileTitle} src={Env.S3_URL + data.image} />
        </Col>
      </Row>
    </Grid>
  );
};

ImageComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

ImageComponent.componentType = 'image-component';

export default withStyles(style)(ImageComponent);
