import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './MultipleTextBox.scss';
import { Col, Row, Grid } from 'react-bootstrap';
import classNames from 'classnames';
import { Button, Html } from 'modules/shared';
import { NavLink } from 'react-router-dom';
import Env from 'env';
import { Videos } from 'modules/shared';
import { FormattedMessage } from 'react-intl';

class MultipleTextBox extends React.Component {
  static componentType = 'multiple-text-box';
  static propTypes = {
    data: PropTypes.object.isRequired,
    handleShow: PropTypes.func.isRequired,
  };

  /**
   * @param {Object} workSection is one item
   * @returns {number} col value of layout
   */
  getColValue(workSection) {
    return workSection.placeRatio
      ? Math.round((workSection.placeRatio / 100) * Env.BOOTSTRAP_MAX_WIDTH)
      : Math.max(Env.BOOTSTRAP_MAX_WIDTH / this.props.data.workSections.length, 2);
  }

  /**
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    return (
      <div>
        <Grid fluid>
          <Row className="multiple-text-box">
            {this.props.data.workSections &&
              this.props.data.workSections.map((workSection, index) => {
                return (
                  <Col
                    key={index}
                    md={this.getColValue(workSection)}
                    sm={this.getColValue(workSection)}
                    xs={12}
                    className={classNames('section-container', {
                      'dark-bg': workSection.darkBackground,
                      'grey-bg': workSection.greyBackground,
                      'image-bg': workSection.image && !!workSection.image.length,
                      'video-bg': workSection.videoEmbed,
                      'blur-image':
                        workSection.image &&
                        !!workSection.image.length &&
                        (workSection.title || workSection.description),
                      'last-list': !workSection.link && !workSection.title,
                      'first-list': !workSection.link && !!workSection.title,
                      'right-padding left-padding':
                        workSection.placeRatio && workSection.placeRatio >= 50,
                      'border-right': +workSection.placeRatio === 50 && index === 0,
                      'no-vertical-padding-editor': +workSection.placeRatio === 50,
                    })}
                    style={{
                      backgroundImage:
                        !workSection.videoEmbed &&
                        typeof workSection.image === 'string' &&
                        workSection.image.length > 0
                          ? `url(${Env.S3_URL + workSection.image})`
                          : null,
                    }}>
                    <Col
                      sm={12}
                      className={classNames('box-title', {
                        'no-title': !workSection.title,
                      })}>
                      <h3>{workSection.title}</h3>
                    </Col>

                    {workSection.videoEmbed ? (
                      <Col
                        sm={12}
                        className="p-0"
                        itemProp="video"
                        itemScope
                        itemType="http://schema.org/VideoObject">
                        <h2 style={{ display: 'none' }}>
                          Video:{' '}
                          <span itemProp="name">
                            {workSection.metadata && workSection.metadata.name}
                          </span>
                        </h2>
                        <meta
                          itemProp="uploadDate"
                          content={workSection.metadata && workSection.metadata.release_time}
                        />
                        <meta
                          itemProp="thumbnailURL"
                          content={
                            workSection.thumbnails &&
                            workSection.thumbnails.length &&
                            workSection.thumbnails[0].link
                          }
                        />
                        <meta itemProp="embedURL" content={workSection.videoUrl} />
                        <Html
                          id="schema-videoobject"
                          className="video-container"
                          content={workSection.videoEmbed}
                        />
                        <span itemProp="description" style={{ display: 'none' }}>
                          {workSection.metadata && workSection.metadata.description}
                        </span>
                      </Col>
                    ) : null}

                    {workSection.carousel && workSection.carousel.showCarousel ? (
                      <Col sm={12} className="carousel">
                        <h4>{workSection.carouselTitle}</h4>
                        <Videos data={workSection.carousel} hideGrid={true} />
                      </Col>
                    ) : null}

                    {workSection.description ? (
                      <Html
                        component={Col}
                        sm={12}
                        className="text-editor-view"
                        content={workSection.description}
                      />
                    ) : null}
                    {workSection.link && workSection.linkTitle ? (
                      <Col sm={12} className="navLink">
                        <FormattedMessage id="EDGE.SIGN_UP_NOW">
                          {(title) =>
                            Env.EDGE_FREE_TRIAL_UNDER_CONSTRUCTION &&
                            workSection.linkTitle === title ? (
                              <Button onClick={this.props.handleShow}>
                                {workSection.linkTitle}
                              </Button>
                            ) : (
                              <NavLink to={workSection.link}>
                                <Button>{workSection.linkTitle}</Button>
                              </NavLink>
                            )
                          }
                        </FormattedMessage>
                      </Col>
                    ) : null}
                  </Col>
                );
              })}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(MultipleTextBox);
