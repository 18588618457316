import { FormattedMessage } from 'react-intl';
import React from 'react';

export const VoteTypes = {
  undefined: 0,
  like: 1,
  dislike: 2,
};

export const VoteTypesLabel = {
  [VoteTypes.like]: {
    label: <FormattedMessage id="ADMIN.POSTS.AGREES" />,
    icon: 'icon-like',
  },
  [VoteTypes.dislike]: {
    label: <FormattedMessage id="ADMIN.POSTS.DIS_AGREES" />,
    icon: 'icon-dislike',
  },
  [VoteTypes.undefined]: {
    label: <FormattedMessage id="ADMIN.POSTS.NOT_ANSWERED" />,
  },
};
