import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';

const IFRAME_PARSER = /<iframe.+?(?=<\/iframe>)/g;
const ATTRIBUTE_PARSER = /(\w+)\s*=\s*((["'])(.*?)\3|([^>\s]*)(?=\s|\/>))(?=[^<]*>)/g;

class Video extends Component {
  static propTypes = {
    autoplay: PropTypes.bool,
    videContainerRef: PropTypes.object,
    vimeo_data: PropTypes.object,
    video_description: PropTypes.string,
    onEnd: PropTypes.func,
  };

  /**
   * @description Play video on component mount
   */
  componentDidMount() {
    this.setPlayer();

    if (this.player) {
      this.player.on('ended', (...args) => {
        this.props.onEnd && this.props.onEnd(...args);
      });
    }
  }

  /**
   * @param {string} html HTML String
   * @returns {Object}
   */
  getHTMLAttributes(html) {
    const iframes = html.match(IFRAME_PARSER);

    if (!iframes.length) {
      return null;
    }

    return iframes[0].match(ATTRIBUTE_PARSER).reduce((prev, cur) => {
      const [key, value] = cur.split(/=(.+)/);
      prev[key] = value.replace(/"/g, '');
      return prev;
    }, {});
  }

  /**
   * @description Get the player object from the DOM.
   */
  setPlayer() {
    if (this.contentRef) {
      const iframe = this.contentRef.querySelector('iframe');
      iframe.setAttribute('allow', 'autoplay');
      this.player = new Player(iframe);
    }
  }

  /**
   * @param {string} embed Video Embed code
   * @returns {string}
   */
  getVideoEmbed(embed) {
    const attributes = this.getHTMLAttributes(embed);

    if (!attributes) {
      return embed;
    }

    const { src } = attributes;
    const newSrc = this.props.autoplay ? `src="${src}&muted=1&autoplay=1"` : `src="${src}"`;
    return embed.replace(/src="[^"]*"/g, newSrc);
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { vimeo_data, video_description, videContainerRef } = this.props;

    return vimeo_data ? (
      <Fragment>
        <div className="video" ref={(r) => (this.contentRef = r)}>
          <div
            className="video-container"
            ref={videContainerRef}
            dangerouslySetInnerHTML={{ __html: this.getVideoEmbed(vimeo_data.embed) }}
          />
        </div>
        {video_description ? <div className="video-description">{video_description}</div> : null}
        <hr />
      </Fragment>
    ) : null;
  }
}

export default Video;
