import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Well } from 'react-bootstrap';
import { TextEditor, AddPlaceholder, DragHandle, Delete, FieldGroup } from 'modules/admin/shared';
import { isValidSchema } from 'common/shemaValidator';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import styles from './Videos.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getVimeoData } from 'modules/admin/main/actions/vimeo';
import { debounce } from 'common';
import { FormattedMessage } from 'react-intl';

const Z_INDEX = 501;

const videoUrlSchema = {
  videoUrl: Yup.string()
    .required()
    .url(),
};

const textSchema = {
  text: Yup.string().nullable(),
};

const SortableBlock = SortableElement(
  ({
    block,
    onChange,
    removeVideo,
    handleEditorChange,
    videoIndex,
    hiddenFields,
    zIndex,
    loaded,
  }) => {
    return (
      <Col lg={6} md={6} sm={12} xs={12} className="video">
        <DragHandle />
        <Delete onSubmit={() => removeVideo(videoIndex)} />
        <Row>
          {!hiddenFields.includes('videoUrl') ? (
            <Col sm={12}>
              <FieldGroup
                label={<FormattedMessage id="ADMIN.VIDEO_LINK" />}
                type="text"
                error={block.validVideoUrl === undefined ? false : !block.validVideoUrl}
                placeholderId="ADMIN.VIDEO_LINK"
                name="videoUrl"
                value={block.videoUrl || null}
                onChange={(e) => {
                  onChange(e, videoIndex);
                }}
              />
            </Col>
          ) : null}
          {!hiddenFields.includes('link') ? (
            <Col sm={12}>
              <FieldGroup
                label={<FormattedMessage id="ADMIN.LINK" />}
                type="text"
                placeholderId="ADMIN.LINK"
                name="link"
                value={block.link || null}
                onChange={(e) => {
                  onChange(e, videoIndex);
                }}
              />
            </Col>
          ) : null}
          {!hiddenFields.includes('videoUrl') ? (
            <Col sm={12}>
              <FieldGroup
                label={<FormattedMessage id="ADMIN.LINK_TITLE" />}
                type="text"
                placeholderId="ADMIN.LINK_TITLE"
                name="linkTitle"
                value={block.linkTitle || null}
                onChange={(e) => {
                  onChange(e, videoIndex);
                }}
              />
            </Col>
          ) : null}
          <Col sm={12}>
            <FieldGroup
              label={<FormattedMessage id="ADMIN.PROTECT_WITH_CONTACT_MODAL" />}
              name="protectWithContactModal"
              inline
              type="checkbox"
              checked={block.protectWithContactModal}
              onChange={(e) => {
                onChange(e, videoIndex);
              }}
            />
          </Col>
        </Row>
        <TextEditor
          error={block.validText === undefined ? false : !block.validText}
          zIndex={zIndex ? zIndex - videoIndex : Z_INDEX - videoIndex}
          handleChange={(e) => {
            const text = e.target.value;
            handleEditorChange(text, videoIndex);
          }}
          text={block.text}
          loaded={loaded}
        />
      </Col>
    );
  }
);

const SortableBlockContainer = SortableContainer(
  ({
    videos,
    onChange,
    onChangeVideo,
    handleEditorChange,
    addVideo,
    removeVideo,
    showCarousel,
    protectWithContactModal,
    hiddenFields,
    zIndex,
    loaded,
  }) => {
    return (
      <Row className="videos">
        <Col sm={12}>
          <FieldGroup
            className="checkbox-form-group"
            label="Show Carousel"
            inline
            type="checkbox"
            name="showCarousel"
            checked={showCarousel}
            onChange={onChange}
          />
        </Col>
        <Col sm={12}>
          <FieldGroup
            label={<FormattedMessage id="ADMIN.PROTECT_ALL_WITH_CONTACT_MODAL" />}
            name="protectWithContactModal"
            inline
            type="checkbox"
            checked={protectWithContactModal}
            onChange={onChange}
          />
          <Well bsStyle="warning">
            <FormattedMessage id="ADMIN.PROTECT_ALL_WITH_CONTACT_MODAL_EXPLAIN" />
          </Well>
        </Col>
        {videos.map((block, videoIndex) => (
          <SortableBlock
            index={videoIndex}
            key={videoIndex}
            videoIndex={videoIndex}
            block={block}
            onChange={onChangeVideo}
            loaded={loaded}
            hiddenFields={hiddenFields}
            zIndex={zIndex}
            removeVideo={removeVideo}
            handleEditorChange={handleEditorChange}
          />
        ))}
        {(showCarousel || (!showCarousel && videos.length < 1)) && (
          <Col lg={6} md={6} sm={12} xs={12} className="video" key="add-person">
            <AddPlaceholder onClick={addVideo} />
          </Col>
        )}
      </Row>
    );
  }
);

class Videos extends Component {
  static componentType = 'videos';
  static blockName = 'Videos';
  static icon = 'fa fa-video';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    setReady: PropTypes.func.isRequired,
    getVimeoData: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    hiddenFields: PropTypes.array,
    zIndex: PropTypes.number,
    loaded: PropTypes.bool,
  };

  static defaultProps = {
    hiddenFields: [],
    loaded: true,
  };

  state = {
    videos: [Videos.getVideo()],
    loaded: false,
    showCarousel: true,
    protectWithContactModal: false,
    loadedDragAndDrop: true,
  };

  /**
   * @description Component loaded
   * */
  componentDidMount() {
    this.setState({
      ...this.props.data,
      loaded: this.props.data && this.props.data.videos ? this.props.data.videos.length > 0 : false,
    });
  }

  /**
   * @description Component loaded
   * */
  componentDidUpdate() {
    if (
      this.props.data &&
      this.props.data.videos &&
      this.props.data.videos.length &&
      Array.isArray(this.state.videos) &&
      this.state.videos.length > 0 &&
      this.state.videos[0].text !== this.props.data.videos[0].text
    ) {
      this.setState({
        ...this.props.data,
        loaded:
          this.props.data && this.props.data.videos ? this.props.data.videos.length > 0 : false,
      });
    } else if (!this.props.data && !this.state.loaded) {
      this.setState({
        loaded: true,
      });
    }
  }

  /**
   * @returns {Object} Empty opportunity object.
   */
  static getVideo() {
    return { videoUrl: '', videoEmbed: '', link: '', linkTitle: '', text: '' };
  }

  /**
   * @returns {boolean} editor is valid.
   */
  async isValidForm() {
    const schema = Yup.array().of(
      Yup.object().shape({
        ...videoUrlSchema,
        ...textSchema,
      })
    );

    return await schema.isValid(this.state.videos);
  }

  onChangeVideo = async (e, videoIndex) => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    const type = e.target.type;

    const videos = this.state.videos.map((data) => data);

    if (name === 'videoUrl') {
      videos[videoIndex][name] = value;
      const { isValid } = await isValidSchema(videoUrlSchema, {
        videoUrl: value,
      });
      videos[videoIndex].validVideoUrl = isValid;
    } else {
      videos[videoIndex][name] = type === 'checkbox' ? checked : value;
    }

    this.setState({ videos }, async () => {
      if (
        typeof videoIndex !== 'undefined' &&
        videos[videoIndex].validVideoUrl &&
        type !== 'checkbox' &&
        name === 'videoUrl'
      ) {
        this.getVimeoData(value, videoIndex);
      }
    });
    const { showCarousel, protectWithContactModal } = this.state;
    const valid = await this.isValidForm();

    this.props.onChange({
      videos,
      showCarousel,
      protectWithContactModal,
      valid,
    });
  };

  onChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    this.setState({ [name]: checked }, async () => {
      const { videos, showCarousel, protectWithContactModal } = this.state;
      const valid = await this.isValidForm();

      this.props.onChange({
        videos,
        showCarousel,
        protectWithContactModal,
        valid,
      });
    });
  };

  getVimeoData = debounce(async (url, videoIndex) => {
    this.props.setReady(false);
    const videos = this.state.videos.map((data) => data);
    const response = await this.props.getVimeoData({ url });
    videos[videoIndex].videoEmbed = response.embed;
    videos[videoIndex].metadata = response.metadata;
    videos[videoIndex].thumbnails = response.thumbnails;
    this.setState({ videos });
    this.props.onChange({ videos });
    this.props.setReady(true);
  }, 1000);

  handleEditorChange = async (text, videoIndex) => {
    const videos = this.state.videos.map((data) => data);
    videos[videoIndex].text = text;
    const { isValid } = await isValidSchema(textSchema, {
      text,
    });
    videos[videoIndex].validText = isValid;
    this.setState({ videos });
    const valid = await this.isValidForm();
    this.props.onChange({ videos, showCarousel: this.state.showCarousel, valid });
  };

  addVideo = () => {
    this.setState(
      (prevState) => ({ videos: [...prevState.videos, Videos.getVideo()] }),
      async () => {
        const valid = await this.isValidForm();
        this.props.onChange({ videos: this.state.videos, valid });
      }
    );
  };

  removeVideo = async (videoIndex) => {
    const videos = this.state.videos.map((data) => data);
    videos.splice(videoIndex, 1);
    this.setState({ videos });
    const valid = await this.isValidForm();
    this.props.onChange({ videos, valid });
  };

  /**
   * @param {Object} dragResult Result object after drag.
   */
  onDragEnd = ({ oldIndex, newIndex }) => {
    const videos = arrayMove(this.state.videos, oldIndex, newIndex);
    this.setState(
      {
        videos,
        loadedDragAndDrop: false,
      },
      async () => {
        const valid = await this.isValidForm();
        this.props.onChange({ videos, valid });
        this.setState({ loadedDragAndDrop: true });
      }
    );
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <SortableBlockContainer
        helperClass=""
        videos={this.state.videos}
        showCarousel={this.state.showCarousel}
        protectWithContactModal={this.state.protectWithContactModal}
        onChange={this.onChange}
        onChangeVideo={this.onChangeVideo}
        removeVideo={this.removeVideo}
        loaded={this.state.loaded && this.props.loaded && this.state.loadedDragAndDrop}
        handleEditorChange={this.handleEditorChange}
        addVideo={this.addVideo}
        onSortEnd={this.onDragEnd}
        hiddenFields={this.props.hiddenFields}
        zIndex={this.props.zIndex}
        useWindowAsScrollContainer
        useDragHandle
        axis="xy"
        delay={100}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVimeoData,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(Videos));
