import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

/**
 *
 * @param {number} latest_id ID of the latest Model Portfolio
 * @param {Object} modelPortfolio Model Portfolio Object
 * @param {Object} props Injected Props
 * @returns {JSX.Element}
 *
 * @constructor
 */
const ModelPortfolioTitle = ({ latest_id, modelPortfolio, ...props }) => {
  return (
    <Col {...props} className="model-portfolio-title">
      {latest_id !== modelPortfolio.id ? (
        <span>
          <span>{modelPortfolio.title}</span>
          {' - '}
          <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.MODEL_PORTFOLIO" />
        </span>
      ) : (
        <span>
          <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.LATEST" />{' '}
          <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.MODEL_PORTFOLIO" />
        </span>
      )}
    </Col>
  );
};

ModelPortfolioTitle.propTypes = {
  latest_id: PropTypes.number,
  modelPortfolio: PropTypes.object,
};

export default ModelPortfolioTitle;
