import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { RadioButton, Button } from 'modules/shared';
import * as Yup from 'yup';
import { withFormik } from 'formik';

/**
 * @param {Object} values Form values
 * @param {Object} errors Form errors
 * @param {Object} touched Form touched fields
 * @param {Function} handleChange Change handler
 * @param {Function} handle Blur handler
 * @param {Function} handleSubmit Submit function
 * @param {boolean} isSubmitting Form is submitting
 *
 * @returns {JSX.Element}
 */
const EdgePreferencesForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="tab-detail">
        <div className="title">
          <FormattedMessage id="MAIN.EDGE_PREFERENCES" />
        </div>
        <div className="section">
          <div className="title">
            <FormattedMessage id="MAIN.PROFILE_MENU.HOME_SCREEN" />
          </div>
          <div>
            <RadioButton
              name="web_edge_home_screen"
              value="edge"
              label={'Edge'}
              onChange={(e) => setFieldValue(e.target.name, e.target.checked && e.target.value)}
              checked={values.web_edge_home_screen === 'edge'}
            />
          </div>
          <div>
            <RadioButton
              name="web_edge_home_screen"
              value="edgeplus"
              label={'Edge+'}
              onChange={(e) => setFieldValue(e.target.name, e.target.checked && e.target.value)}
              checked={values.web_edge_home_screen === 'edgeplus'}
            />
          </div>
          <Button
            moduleType="main"
            textColor="dark"
            type="submit"
            className="short-width small"
            disabled={isSubmitting}>
            <FormattedMessage id="MAIN.UPDATE" />
          </Button>
        </div>
      </div>
    </form>
  );
};

EdgePreferencesForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

const EdgePreferences = withFormik({
  mapPropsToValues: ({ settings }) => {
    return { web_edge_home_screen: settings.web_edge_home_screen || 'edge' };
  },
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { web_edge_home_screen } = values;

    setSubmitting(true);

    try {
      await props.saveUserSettings({ key: 'web_edge_home_screen', value: web_edge_home_screen });
      await props.getUserSettings();
    } finally {
      setSubmitting(false);
    }
  },
})(EdgePreferencesForm);

export default EdgePreferences;
