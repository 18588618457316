import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { FieldGroup } from 'modules/admin/shared';

export class FormInputs extends Component {
  static propTypes = {
    ncols: PropTypes.array,
    properties: PropTypes.array,
  };

  /**
   * @returns {JSX.Element} FromInput component
   */
  render() {
    return (
      <Row>
        {this.props.ncols.map((ncol, i) => {
          return (
            <div key={i} className={ncol}>
              <FieldGroup {...this.props.properties[i]} />
            </div>
          );
        })}
      </Row>
    );
  }
}

export default FormInputs;
