import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'modules/shared';

/**
 * @param {Object} modelPortfolio ModelPortfolio Object
 * @param {Object} props Injected Props object
 * @returns {JSX.Element}
 * @constructor
 */
const ModelPortfolioDownloads = ({ modelPortfolio, ...props }) => {
  return (
    <Col {...props} className="downloads">
      {modelPortfolio.downloads && modelPortfolio.downloads.length ? (
        <Link className="link mt-1" linkId={modelPortfolio.downloads[0].id}>
          <span>{<FormattedMessage id="ANALYST.MODEL_PORTFOLIO.DOWNLOAD_CURRENT_MONTH" />}</span>
          <i className="far fa-file-pdf ml-2" />
        </Link>
      ) : null}
    </Col>
  );
};

ModelPortfolioDownloads.propTypes = {
  modelPortfolio: PropTypes.object,
};

export default ModelPortfolioDownloads;
