import TranslationManager from 'common/TranslationManager';
import Env from 'env';

const defaultLocale = 'en';
const translationManager = new TranslationManager(defaultLocale);

/**
 * @returns {string} Default locale setting from .env.
 */
const getLocale = () => {
  return Env.LOCALE;
};

export default () => {
  const locale = getLocale();

  translationManager.addLanguage(
    'en',
    require.context('../assets/translations/en', true, /.json$/)
  );

  return {
    messages: translationManager.getTranslation(locale),
    locale,
  };
};
