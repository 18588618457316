import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Env from 'env';
import { formatUsername } from 'common';
import { Img } from 'modules/shared';

class IdeaSource extends Component {
  static propTypes = {
    contributor: PropTypes.object,
    placeholder: PropTypes.oneOf(['edge', 'edge-plus']),
  };

  static defaultProps = {
    placeholder: 'edge',
  };

  /**
   * @param {string} url Url
   *
   * @returns {string}
   */
  getUrl(url) {
    const httpRegex = /^(http(s?)):\/\//gi;
    return httpRegex.test(url) ? url : `//${url}`;
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { contributor, placeholder } = this.props;
    return (
      <div className="info-section idea-source">
        <div className="header">
          <FormattedMessage id="EDGE.IDEA_SOURCE" />
        </div>
        {contributor ? (
          <div className="content d-flex">
            {contributor.avatar_path ? (
              <div className="author-image">
                <div
                  className="profile-image"
                  style={{ backgroundImage: `url(${Env.S3_URL + contributor.avatar_path})` }}
                />
              </div>
            ) : null}
            <div className="contributor-info d-flex flex-column">
              <div className="bold">{formatUsername(contributor)}</div>
              <div>{contributor.company}</div>
              <a href={this.getUrl(contributor.url)} target="_blank" rel="noopener noreferrer">
                {contributor.url}
              </a>
            </div>
          </div>
        ) : (
          <Img
            src={
              placeholder === 'edge'
                ? require('assets/img/theanalystedge-logo-blue.svg')
                : require('assets/img/theanalystedgeplus-logo-gold.svg')
            }
            alt="the-analyst-edge"
          />
        )}
      </div>
    );
  }
}

export default IdeaSource;
