import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSentimentTracker } from 'modules/analyst/actions/sentiment-tracker';
import { getSentimentTrackerRecommendations } from 'modules/analyst/actions/recommendations';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

import SentimentTracker from './SentimentTracker';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst, app }) => {
  return {
    sentimentTracker: analyst.sentimentTracker,
    loading: !!app.loader.showCount,
    systemSettings: app.systemSettings.all,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showLoader, hideLoader, getSentimentTracker, getSentimentTrackerRecommendations },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SentimentTracker);
