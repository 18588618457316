import React, { Component } from 'react';
import $ from 'jquery';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './LightBox.scss';
import { isFileImage } from 'common';
import { disableContextMenuOnImg } from 'modules/shared';
import PropTypes from 'prop-types';

class LightBox extends Component {
  static propTypes = {
    containerClassName: PropTypes.string.isRequired,
  };

  state = {
    showImagePopup: false,
    photoIndex: 0,
    images: '',
  };

  /**
   * @param {Object} props object
   * */
  constructor(props) {
    super(props);
    this.reactLightBox = require('react-image-lightbox');
  }

  /**
   * @description detect images, put it to images array
   * */
  componentDidMount() {
    const aTags = $(`.${this.props.containerClassName} a[target='_blank']`);
    const images = [];

    aTags.each((index, item) => {
      const aTagHref = $(item).attr('href');
      if (!!aTagHref && isFileImage(aTagHref)) {
        images.push(aTagHref);
        $(item).removeAttr('href');

        $(item).click((e) => {
          const currentImageSrc = $(e.currentTarget.children[0]).attr('src');
          const currentIndex = this.state.images.findIndex((src) => src === currentImageSrc);
          this.setState({ showImagePopup: true, photoIndex: currentIndex });
        });
      }
    });

    this.setState({ images });
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    if (this.reactLightBox) {
      const { images, photoIndex, showImagePopup } = this.state;
      const ReactLightBox = this.reactLightBox.default;

      return showImagePopup ? (
        <ReactLightBox
          mainSrc={images[photoIndex]}
          nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length] : undefined}
          prevSrc={
            images.length > 1 ? images[(photoIndex + images.length - 1) % images.length] : undefined
          }
          onImageLoad={() => {
            disableContextMenuOnImg('.analyst-lightbox');
          }}
          wrapperClassName="analyst-lightbox"
          onCloseRequest={() => this.setState({ showImagePopup: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      ) : null;
    } else {
      return null;
    }
  }
}

export default withStyles(style)(LightBox);
