import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from 'modules/main/actions/users';
import ProfileDropdown from './ProfileDropdown';

/**
 * @param {Object} state Application State
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = (state) => {
  return {
    profile: state.app.profile || {},
  };
};

/**
 * @param {Function} dispatch Action dispatcher.
 *
 * @returns {Object} Injected action creators.
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
