import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getCompanies, bookmarkCompany } from 'modules/analyst/actions/companies';
import { deleteAnalystBookmark as deleteBookmark } from 'modules/shared/actions/bookmarks';
import { bookmarkPost, getPosts } from 'modules/analyst/actions/posts';

import { SearchResults } from 'modules/shared';

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPosts,
      showLoader,
      hideLoader,
      bookmarkCompany,
      deleteBookmark,
      getCompanies,
      bookmarkPost,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SearchResults);
