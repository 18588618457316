import PrivacyPolicy from '../components/compliance/privacy-policy/PrivacyPolicy';
import DisclaimersNDisclosures from '../components/compliance/disclaimers-n-disclosures/DisclaimersNDisclosures';
import TermsOfUse from '../components/compliance/terms-of-use/TermsOfUse';

const complianceRoutes = [
  {
    path: '/admin/compliance/disclaimers-n-disclosures',
    name: 'Disclaimers & disclosures',
    component: DisclaimersNDisclosures,
  },
  {
    path: '/admin/compliance/privacy-policy',
    name: 'Privacy & Cookie Policy',
    component: PrivacyPolicy,
  },
  {
    path: '/admin/compliance/terms-of-use',
    name: 'Terms of use',
    component: TermsOfUse,
  },
  {
    redirect: true,
    from: '/admin/compliance',
    to: '/admin/compliance/model-portfolio',
    key: 'redirect',
  },
];

export default complianceRoutes;
