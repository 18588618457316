import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updatePage, getPage } from 'modules/shared/actions/pages';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import PageEditor from './PageEditor';

/**
 * @param {Object} state Core state object.
 *
 * @returns {Object} Injected props from state.
 */
const mapStateToProps = (state) => {
  return {
    content: state.shared.pages || [],
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updatePage, getPage, showLoader, hideLoader }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditor);
