import React from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import style from './TextInputField.scss';

const TextInputField = ({ field, form, label, className, noResize, ...props }) => {
  const touched = get(form.touched, field.name);
  const errors = get(form.errors, field.name);

  const onChange = (e) => {
    form.setFieldTouched(field.name, true, false);
    form.setFieldValue(field.name, e.target.value, false);
  };

  return (
    <FormGroup className={classNames('text-input-field', className, { 'no-resize': noResize })}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl onChange={onChange} {...props} />
      <div className="error-message low text-left">{touched && errors ? errors : null}</div>
    </FormGroup>
  );
};

TextInputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  noResize: PropTypes.bool,
};

export default withStyles(style)(injectIntl(TextInputField));
