import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './style.scss';

/**
 * @param {string} label Radio Button Label.
 * @param {string} className Class Name
 * @param {boolean} disabled If the radio button is disabled
 * @param {boolean} vertical If the radio button is in vertical layout
 * @param {Object} props Other injected props.
 *
 * @returns {JSX.Element}
 */
const RadioButton = ({
  label = 'Label',
  className,
  disabled = false,
  vertical = false,
  ...props
}) => {
  return (
    <label className={classNames('RadioButton', className, { disabled, vertical })}>
      <input className="form-check-input" type="radio" disabled={disabled} {...props} />

      <span className="box">
        <span className="tick" />
      </span>
      <span className="label">{label}</span>
    </label>
  );
};

RadioButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(RadioButton);
