import { Buys } from './buyTypes';

export enum DesktopType {
  RECOMMENDATIONS = 'recommendations',
}

export const SidebarTypes = {
  ...Buys,
  ...DesktopType,
};
