import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getSummaryAnalytics,
  getEdgeArticleAnalytics,
  exportArticleAnalytics,
} from 'modules/admin/main/actions/analytics';

import ArticleAnalyticsList from '../../analytics/ArticleAnalyticsList';

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ admin }) => {
  return {
    analytics: admin.analytics || {},
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSummaryAnalytics,
      exportArticleAnalytics,
      fetchDataWs: getEdgeArticleAnalytics,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAnalyticsList);
