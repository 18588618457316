import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { location } from 'common';

import { CompanyPostResult } from 'modules/shared';
import Env from 'env';

class SearchResults extends Component {
  static propTypes = {
    toggleSearchBar: PropTypes.func.isRequired,
    bookmarkCompany: PropTypes.func.isRequired,
    bookmarkPost: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    getPosts: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    location,
  };

  state = {
    companies: [],
    loadingCompanies: true,
    search: '',
    updatePostList: false,
  };

  /**
   * @description Fetch the data on component load.
   */
  async componentDidMount() {
    const search = qs.parse(this.props.location.search);
    this.props.toggleSearchBar(null, true);
    await this.fetchData(search.s);
  }

  /**
   * @description Sets the state after bookmark action.
   * @param {Object} companies Part of state to be updated.
   */
  afterCompanyBookmark = (companies) => {
    this.setState({ companies });
  };

  /**
   * @description Fetch the data if the query string is updated.
   */
  async componentDidUpdate() {
    const search = qs.parse(this.props.location.search);
    if (this.state.search !== search.s) {
      await this.fetchData(search.s);
    }
  }

  /**
   * @description trigger post list update
   */
  async triggerPostListUpdate() {
    this.setState({ updatePostList: true }, () => {
      this.setState({ updatePostList: false });
    });
  }

  /**
   * @param {string} search Search string to get the posts and companies.
   */
  async fetchData(search) {
    if (search) {
      this.setState({ search }, async () => {
        try {
          this.setState({ loadingCompanies: true });
          this.props.onSearchChange(search);
          const companies = await this.props.getCompanies({
            nopaginate: true,
            nofilter: false,
            search,
          });

          this.setState({ companies });
        } finally {
          this.setState({ loadingCompanies: false }, () => {
            this.triggerPostListUpdate();
          });
        }
      });
    }
  }

  /**
   * @param {Object} params Search string to get the posts.
   *
   * @returns {Promise<Object>}
   */
  fetchPosts = async (params) => {
    return this.props.getPosts({
      nopaginate: false,
      search: this.state.search || '',
      ...params,
    });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { loadingCompanies, companies, updatePostList } = this.state;

    return (
      <div>
        <FormattedMessage id="MAIN.SEARCH_RESULTS_TITLE" values={{ text: this.state.search }}>
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {!loadingCompanies ? (
          <CompanyPostResult
            fetchData={this.fetchPosts}
            companyTitle={<FormattedMessage id={'MAIN.COMPANIES_RESULT'} />}
            postsTitle={<FormattedMessage id={'MAIN.POSTS_RESULT'} />}
            companyEmptyMessage={<FormattedMessage id={'MAIN.SEARCH_COMPANY_EMPTY'} />}
            postEmptyMessage={<FormattedMessage id={'MAIN.SEARCH_POST_EMPTY'} />}
            companies={companies}
            bookmarkCompany={this.props.bookmarkCompany}
            bookmarkPost={this.props.bookmarkPost}
            deleteBookmark={this.props.deleteBookmark}
            afterCompanyBookmark={this.afterCompanyBookmark}
            basePath={this.props.basePath}
            defaultImage={Env.ANALYST_DEFAULT_IMAGE}
            noDidMountFetch
            updateData={updatePostList}
          />
        ) : null}
      </div>
    );
  }
}

export default SearchResults;
