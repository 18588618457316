import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';

import style from './SearchInput.scss';

class SearchInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    intl: PropTypes.object,
    placeholder: PropTypes.string,
    placeholderId: PropTypes.string,
  };

  /**
   * @description Gets the placeholder from translation or from raw text as fallback.
   *
   * @returns {string}
   */
  getPlaceholder() {
    const { placeholder, placeholderId, intl } = this.props;
    return placeholderId ? intl.formatMessage({ id: placeholderId }) : placeholder || null;
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { className, placeholderId, ...props } = this.props;
    return (
      <FormGroup className={classNames('search-input', className)}>
        <InputGroup>
          <FormControl placeholder={this.getPlaceholder()} {...props} />
          <InputGroup.Addon>
            <i className="module-icon icon-search-outline" />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    );
  }
}

export default withStyles(style)(injectIntl(SearchInput));
