import React, { Component } from 'react';
import qs from 'query-string';

import { match, location, history } from 'common';

/**
 * @param {Function} WrappedComponent Component to wrap
 *
 * @returns {Function}
 */
const withAzFilter = (WrappedComponent) => {
  return class WithAzFilter extends Component {
    static propTypes = {
      match,
      location,
      history,
    };

    state = {
      query: {
        search: '',
      },
    };

    /**
     * @description Gets the company and bookmark data.
     */
    componentDidMount() {
      const query = this.getQueryObject();
      this.setState({ query });
    }

    getQueryObject = () => {
      return { ...this.state.query, ...qs.parse(this.props.location.search) };
    };

    /**
     * @description Appends or removes the letter from the current filter.
     * @param {string} filterLetter Letter to remove or append.
     * @returns {Object}
     */
    onFilter = (filterLetter) => {
      const { query } = this.state;
      const newQuery = { ...query };

      if (!filterLetter) {
        delete newQuery.letters;
      } else if (!query.letters) {
        newQuery.letters = filterLetter;
      } else {
        const letters = query.letters.split(',');
        const letterIndex = letters.findIndex((letter) => letter === filterLetter);

        if (letterIndex >= 0) {
          letters.splice(letterIndex, 1);
        } else {
          letters.push(filterLetter);
        }

        if (!letters.length) {
          delete newQuery.letters;
        } else {
          newQuery.letters = letters.join(',');
        }
      }
      this.setState({ query: newQuery });

      return newQuery;
    };

    onSearch = (e) => {
      const query = { ...this.state.query, search: e.target.value };
      this.setState({ query });
      return query;
    };

    /**
     * @description Determines the currently selected letters.
     * @returns {string[]}
     */
    activeLetters = () => {
      return this.state.query.letters ? this.state.query.letters.split(',') : [];
    };

    setQueryParams = (query) => {
      this.props.history.replace({
        path: this.props.match.path,
        search: qs.stringify(query),
      });
    };

    /**
     * @returns {JSX.Element}
     */
    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onFilter={this.onFilter}
          onSearch={this.onSearch}
          activeLetters={this.activeLetters}
          setQueryParams={this.setQueryParams}
          getQueryObject={this.getQueryObject}
        />
      );
    }
  };
};

export default withAzFilter;
