import { GET_HOME } from '../actions/home';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const home = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default home;
