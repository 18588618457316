import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { Col } from 'react-bootstrap';
import Dotdotdot from 'react-dotdotdot';
import withStyles from 'isomorphic-style-loader/withStyles';

import ReactResizeDetector from 'react-resize-detector';

import { Bookmark, Img } from 'modules/shared';
import VideoThumbnail from './VideoThumbnail';
import { formatDate, debounce, formatUsername } from 'common';

import styles from './PostCard.scss';
import Env from 'env';

class PostCard extends Component {
  static propTypes = {
    post: PropTypes.shape({
      title: PropTypes.string,
      gradient_start: PropTypes.string,
      gradient_end: PropTypes.string,
      featured_image_thumbnail: PropTypes.string,
      updated_at: PropTypes.string,
      show_author_info: PropTypes.number,
      authors: PropTypes.array,
    }),
    className: PropTypes.string,
    bookmark: PropTypes.func.isRequired,
    defaultImage: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    bonusContent: PropTypes.bool,
    ...Col.propTypes,
  };

  state = {
    bookmarking: false,
  };

  getBackground = (post) => {
    let background = '';
    if (!post.featured_image_path) {
      background += `url("${this.props.defaultImage}") top 25% center / 80% no-repeat`;
    }
    if (post.gradient_start && post.gradient_end) {
      background += background ? ', ' : '';
      background += `linear-gradient(${post.gradient_start}, ${post.gradient_end})`;
    }
    return { background };
  };

  bookmark = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.bookmarking) {
      return;
    }
    this.setState({ bookmarking: true });
    await this.props.bookmark(this.props.post);
    this.setState({ bookmarking: false });
  };

  isVideo = (post) => {
    return post.categories && post.categories.find(({ slug }) => slug === 'video');
  };

  isVisualisation = (post) => {
    return post.categories && post.categories.find(({ slug }) => slug === 'visualisation');
  };

  onResize = () => {
    if (this.header) {
      const { height, width } = this.header.getBoundingClientRect();

      if (height === 0) {
        this.delayedOnResize();
      } else {
        this.setState({
          imgWidth: height * 0.335,
          marginRight: width * 0.05,
          titleFontSize: height * 0.07,
          authorFontSize: height * 0.1,
        });
      }
    }
  };

  delayedOnResize = debounce(this.onResize, 0);

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { post, className, bookmark, defaultImage, basePath, ...props } = this.props;

    return (
      <ReactResizeDetector
        handleWidth
        onResize={this.delayedOnResize}
        render={() => (
          <Col
            {...props}
            className={classNames(className, 'post-card d-flex', {
              bonus: post.bonus,
            })}>
            <Link to={`${basePath}/post/${post.id}/${post.slug}`} className="post-card-inner">
              <div
                className="header"
                style={this.getBackground(post)}
                ref={(r) => (this.header = r)}>
                {post.bonus ? (
                  <div className="corner-ribbon">
                    <FormattedMessage id="MAIN.BONUS" />
                  </div>
                ) : null}
                {post.featured_image_path ? (
                  <Img
                    className="featured-image"
                    src={`${Env.S3_URL}${post.featured_image_path}`}
                    alt="alt"
                  />
                ) : (
                  <div className="default-image" />
                )}
                <div className="featured-image-placeholder" />
                {post.authors && post.authors[0] && post.show_author_info ? (
                  <div className="author-info">
                    {post.authors[0].avatar_url ? (
                      <div
                        className="profile-image"
                        style={{
                          ...(post.authors[0].avatar_path
                            ? {
                                backgroundImage: `url(${Env.S3_URL + post.authors[0].avatar_path})`,
                              }
                            : {}),
                        }}
                      />
                    ) : null}
                    <div>
                      <div
                        className="title"
                        style={{
                          ...(this.state.imgWidth
                            ? {
                                fontSize: this.state.titleFontSize,
                              }
                            : {}),
                        }}>
                        {post.title}
                      </div>
                      <div
                        className="name"
                        style={{
                          ...(this.state.imgWidth
                            ? {
                                fontSize: this.state.authorFontSize,
                              }
                            : {}),
                        }}>
                        {formatUsername(post.authors[0])}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="card">
                <div className="info d-flex justify-between align-center">
                  <div className="date">{formatDate(post.published_at)}</div>
                  <div className="icons d-flex">
                    {this.isVisualisation(post) && !this.isVideo(post) ? (
                      <i className="icon-presentation-outline" />
                    ) : null}
                    {this.isVideo(post) ? <VideoThumbnail video={post.vimeo_data} /> : null}
                    <Bookmark
                      bookmarking={this.state.bookmarking}
                      bookmarked={post.bookmarks && post.bookmarks.length}
                      onClick={this.bookmark}
                    />
                  </div>
                </div>
                <div className="title">{post.title}</div>
                <div className="summary">
                  <Dotdotdot clamp={5}>{post.summary}</Dotdotdot>
                </div>
              </div>
            </Link>
          </Col>
        )}
      />
    );
  }
}

export default withStyles(styles)(PostCard);
