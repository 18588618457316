import { combineReducers } from 'redux';

import bookmarks from './bookmarks';
import categories from './categories';
import companies from './companies';
import tags from './tags';
import home from './home';

export default combineReducers({
  bookmarks,
  categories,
  companies,
  tags,
  home,
});
