import { GET_USER_PREFERENCES } from '../actions/users';

/**
 * @param {Object} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Object} Modified state.
 */
const userPreferences = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_PREFERENCES:
      return action.payload;
    default:
      return state;
  }
};

export default userPreferences;
