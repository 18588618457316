import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button } from 'modules/shared';
import { ContactModalFilledContext } from 'modules/shared/components/contact-modal-protect/contactModalFilledContext';

/**
 * @returns {JSX.Element} - FindOut component
 */
const FindOutButton = ({ openSuccessDialog, openContentDialog }) => {
  const { contactModalFilled } = useContext(ContactModalFilledContext);

  const openDialog = () => (contactModalFilled ? openSuccessDialog() : openContentDialog());

  return (
    <div>
      <Button className="white" onClick={openDialog}>
        <FormattedMessage id="MAIN.FIND_OUT" />
      </Button>
    </div>
  );
};

FindOutButton.propTypes = {
  openSuccessDialog: PropTypes.func.isRequired,
  openContentDialog: PropTypes.func.isRequired,
};

export default FindOutButton;
