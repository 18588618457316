import * as TagsService from 'modules/shared/services/tags';

export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const GET_TAG = 'GET_TAG';
export const SAVE_TAG = 'SAVE_TAG';
export const MODIFY_TAG = 'MODIFY_TAG';
export const DELETE_TAG = 'DELETE_TAG';

const BASE_URL = 'edge/tags';

/**
 * @param {Object} options of get
 * @description Gets all tags.
 * @returns {function}
 */
export const getTags = (options) => async (dispatch) => {
  const tags = await TagsService.getTags({ ...options, base: BASE_URL });

  dispatch({
    type: GET_ALL_TAGS,
    payload: tags,
  });

  return tags;
};

/**
 * @description Gets the tag for the ID:
 * @param {number} id tag ID.
 * @returns {Function}
 */
export const getTag = (id) => async (dispatch) => {
  const tag = await TagsService.getTag(id, BASE_URL);

  dispatch({
    type: GET_TAG,
    payload: tag,
  });

  return tag;
};

/**
 * @param {Object} data tag data.
 * @returns {Function}
 */
export const saveTag = (data) => async (dispatch) => {
  const tag = await TagsService.saveTag(data, BASE_URL);

  dispatch({
    type: SAVE_TAG,
    payload: tag,
  });

  return tag;
};

/**
 * @param {number} id tag Id
 * @param {Object} data tag data.
 * @returns {Function}
 */
export const modifyTag = (id, data) => async (dispatch) => {
  const tag = await TagsService.modifyTag(id, data, BASE_URL);

  dispatch({
    type: MODIFY_TAG,
    payload: tag,
  });

  return tag;
};

/**
 * @param {number} id tag Id
 * @returns {Function}
 */
export const deleteTag = (id) => async (dispatch) => {
  const tag = await TagsService.deleteTag(id, BASE_URL);

  dispatch({
    type: DELETE_TAG,
    payload: tag,
  });

  return tag;
};
