import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { PageEditor } from 'modules/admin/shared';

class AboutUs extends Component {
  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <PageEditor
        site="landing"
        slug="about-us"
        title={<FormattedMessage id="MAIN.HEADER.ABOUT_US" />}
        viewLink="/about"
      />
    );
  }
}

export default AboutUs;
