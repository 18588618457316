import React, { Component } from 'react';
import contentRoutes from 'modules/admin/main/routes/content.jsx';
import renderRoutes from 'common/renderRoutes';

class Content extends Component {
  /**
   * @returns {JSX.Element} Content component.
   */
  render() {
    return <div className="admin-content">{renderRoutes(contentRoutes, { basePath: `` })}</div>;
  }
}

export default Content;
