import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { match, history } from 'common';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import {
  getPress,
  savePress,
  modifyPress,
  saveFeaturedImage,
  saveContentImage,
} from 'modules/main/actions/press';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BlogPostsEditor } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';
import { deleteFile } from 'modules/admin/main/actions/file-upload';

class PressEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getPress: PropTypes.func.isRequired,
    savePress: PropTypes.func.isRequired,
    modifyPress: PropTypes.func.isRequired,
    saveFeaturedImage: PropTypes.func.isRequired,
    saveContentImage: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    commonUploadFile: PropTypes.func,
    basePath: PropTypes.string,
    match,
    history,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <BlogPostsEditor
        hideLoader={this.props.hideLoader}
        history={this.props.history}
        basePath={this.props.basePath}
        hiddenFields={['regions', 'blogtags']}
        match={this.props.match}
        showLoader={this.props.showLoader}
        saveContentImage={this.props.saveContentImage}
        deleteFile={this.props.deleteFile}
        getBlogPost={this.props.getPress}
        modifyBlogPost={this.props.modifyPress}
        saveBlogPost={this.props.savePress}
        listViewPath="analyst/press"
        viewLink="/press"
        saveFeaturedImage={this.props.saveFeaturedImage}
        createTitle={<FormattedMessage id="ADMIN.PRESS_BLOG_POSTS.CREATE_BLOG_POST" />}
        editTitle={<FormattedMessage id="ADMIN.PRESS_BLOG_POSTS.EDIT_BLOG_POST" />}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getPress,
      savePress,
      modifyPress,
      saveFeaturedImage,
      saveContentImage,
      deleteFile,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PressEditor);
