import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import get from 'lodash/get';
import { Formik, Form, FieldArray, useFormikContext, FieldArrayRenderProps } from 'formik';
import { Grid, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';

import Env from 'env';
import {
  Button,
  EditorTitle,
  FieldGroup,
  DragHandle,
  AddPlaceholder,
  Delete,
} from 'modules/admin/shared';
import { useQuery } from 'modules/shared/util/useQuery';
import { getLongScreenSinglepage, saveLongScreen } from 'modules/analyst/actions/long-screen';
import { LongScreenAdminModel } from 'modules/analyst/models';
import { textSchema, urlSchema } from 'common';

const POSITIVE_TRAIT_COLOR = '#1F61A3';
const NEGATIVE_TRAIT_COLOR = '#ec6059';

export default function LongScreenEditor() {
  const dispatch = useDispatch();
  const factorHelpersRef = useRef<FieldArrayRenderProps | null>(null);
  const longScreen = useQuery<LongScreenAdminModel>(() => dispatch(getLongScreenSinglepage()));

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...textSchema('title', { required: true }),
        ...urlSchema('video_url', { required: true }),
        ...textSchema('description', { required: true, max: Env.MAX_TEXT_EDITOR_LENGTH }),
        ...textSchema('star_description', { required: true }),
        ...textSchema('vote_title', { required: true }),
        factors: Yup.array().of(
          Yup.object().shape({
            ...textSchema('factor_name', { required: true }),
            ...textSchema('positive_trait_name', { required: true }),
            ...textSchema('negative_trait_name', { required: true }),
          })
        ),
      }),
    []
  );

  const getRawFactorName = useCallback((name: string) => {
    const match = /<font [^>]+>(.*?)<\/font>/g.exec(name);

    return match[1] || '';
  }, []);

  const getFormattedFactorName = useCallback((name: string, color: string) => {
    // eslint-disable-next-line no-useless-escape
    return `<font color=\"${color}\">${name}</font>`;
  }, []);

  const onSave = useCallback(
    async (values: Partial<LongScreenAdminModel>) => {
      await dispatch(
        saveLongScreen({
          ...values,
          factors: values.factors?.map((item, i) => ({
            ...item,
            positive_trait_name: getFormattedFactorName(
              item.positive_trait_name,
              POSITIVE_TRAIT_COLOR
            ),
            negative_trait_name: getFormattedFactorName(
              item.negative_trait_name,
              NEGATIVE_TRAIT_COLOR
            ),
            order: i * 2 + 1,
          })),
        })
      );

      toast.success(<FormattedMessage id="MAIN.SAVE_SUCCESS" />);
    },
    [dispatch, getFormattedFactorName]
  );

  const onSortEnd = useCallback(async (sort: SortEnd) => {
    if (!factorHelpersRef.current) {
      return;
    }

    factorHelpersRef.current.move(sort.oldIndex, sort.newIndex);
  }, []);

  const onAddRow = useCallback(async () => {
    if (!factorHelpersRef.current) {
      return;
    }

    factorHelpersRef.current.push({
      group_id: uuid(),
      factor_name: '',
      positive_trait_name: '',
      negative_trait_name: '',
      order: factorHelpersRef.current.form.values.factors?.length,
    });
  }, []);

  return (
    <div>
      {!longScreen.isLoading ? (
        <Formik
          initialValues={{
            ...longScreen.data,
            factors: (longScreen.data?.factors || []).map((factor) => ({
              ...factor,
              positive_trait_name: getRawFactorName(factor.positive_trait_name),
              negative_trait_name: getRawFactorName(factor.negative_trait_name),
            })),
          }}
          validationSchema={validationSchema}
          onSubmit={onSave}
          enableReinitialize>
          {({ values, errors, handleChange, handleBlur, isSubmitting, isValid }) => (
            <Form>
              <EditorTitle
                title={<FormattedMessage id="ADMIN.LONG_SCREEN.TITLE" />}
                viewLink="/app/factor-panel">
                <div className="d-flex">
                  <Button loading={isSubmitting} type="submit" disabled={!isValid || isSubmitting}>
                    <FormattedMessage id="ADMIN.SAVE" />
                  </Button>
                </div>
              </EditorTitle>
              <Grid fluid className="editor-content">
                <Row>
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="title"
                      placeholderId="ADMIN.NAME"
                      label={<FormattedMessage id="ADMIN.NAME" />}
                      value={values.title}
                      error={errors.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      name="video_url"
                      placeholderId="ADMIN.VIDEO_LINK"
                      label={<FormattedMessage id="ADMIN.VIDEO_LINK" />}
                      value={values.video_url}
                      error={errors.video_url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      componentClass="textarea"
                      placeholderId="ADMIN.DESCRIPTION"
                      label={<FormattedMessage id="ADMIN.DESCRIPTION" />}
                      name="description"
                      value={values.description}
                      error={errors.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      componentClass="textarea"
                      placeholderId="ADMIN.STAR_DESCRIPTION"
                      label={<FormattedMessage id="ADMIN.STAR_DESCRIPTION" />}
                      name="star_description"
                      value={values.star_description}
                      error={errors.star_description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <FieldGroup
                      componentClass="textarea"
                      placeholderId="ADMIN.VOTE_TITLE"
                      label={<FormattedMessage id="ADMIN.VOTE_TITLE" />}
                      name="vote_title"
                      value={values.vote_title}
                      error={errors.vote_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </Grid>
              <Grid fluid className="editor-content">
                <Row>
                  <Col xs={12}>
                    <strong>
                      <FormattedMessage id="ADMIN.LONG_SCREEN.FACTORS" />
                    </strong>
                  </Col>
                </Row>
              </Grid>

              <LongScreenSortableContainer
                values={values}
                useWindowAsScrollContainer
                useDragHandle
                onSortEnd={onSortEnd}
                onAddRow={onAddRow}
                helpersRef={factorHelpersRef}
              />
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
}

interface LongScreenSortableContainerProps {
  values: Partial<LongScreenAdminModel>;
  helpersRef: MutableRefObject<FieldArrayRenderProps>;
  onAddRow: () => void;
}

const LongScreenSortableContainer = SortableContainer<LongScreenSortableContainerProps>(
  ({ values, onAddRow, helpersRef }) => {
    return (
      <Grid fluid className="editor-grid mb-3">
        <div className="d-flex">
          <span className="drag-handle-placeholder" />
          <Row className="d-flex w-100">
            <Col xs={12} sm={4}>
              <strong>
                <FormattedMessage id="ADMIN.NAME" />
              </strong>
            </Col>
            <Col xs={12} sm={4}>
              <strong>
                <FormattedMessage id="ADMIN.LONG_SCREEN.POSITIVE_TRAIT_NAME" />
              </strong>
            </Col>
            <Col xs={12} sm={4}>
              <strong>
                <FormattedMessage id="ADMIN.LONG_SCREEN.NEGATIVE_TRAIT_NAME" />
              </strong>
            </Col>
          </Row>
          <span className="delete-placeholder" />
        </div>

        <FieldArray
          name="factors"
          render={(helpers) => {
            helpersRef.current = helpers;

            return (
              <>
                {values.factors?.map((row, i) => (
                  <LongScreenSortableElement
                    index={i}
                    key={row.group_id}
                    i={i}
                    onDelete={() => helpers.remove(i)}
                  />
                ))}
              </>
            );
          }}
        />

        <AddPlaceholder inverse className="add" onClick={onAddRow} />
      </Grid>
    );
  }
);

interface LongScreenSortableElementProps {
  i: number;
  onDelete: () => void;
}

const LongScreenSortableElement = SortableElement<LongScreenSortableElementProps>(
  ({ i, onDelete }) => {
    const { values, errors, touched, handleChange, handleBlur } = useFormikContext<
      LongScreenAdminModel
    >();

    return (
      <div className="w-100 sortable-row d-flex">
        <DragHandle />
        <Row className="flex-grow-1">
          <Col xs={12} sm={4}>
            <FieldGroup
              name={`factors.${i}.factor_name`}
              placeholderId="ADMIN.NAME"
              className="mb-0"
              value={values.factors?.[i]?.factor_name}
              error={
                get(touched, `factors.${i}.factor_name`) && get(errors, `factors.${i}.factor_name`)
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col xs={12} sm={4}>
            <FieldGroup
              name={`factors.${i}.positive_trait_name`}
              placeholderId="ADMIN.LONG_SCREEN.POSITIVE_TRAIT_NAME"
              className="mb-0"
              value={values.factors?.[i]?.positive_trait_name}
              error={
                get(touched, `factors.${i}.positive_trait_name`) &&
                get(errors, `factors.${i}.positive_trait_name`)
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col xs={12} sm={4}>
            <FieldGroup
              name={`factors.${i}.negative_trait_name`}
              placeholderId="ADMIN.LONG_SCREEN.NEGATIVE_TRAIT_NAME"
              className="mb-0"
              value={values.factors?.[i]?.negative_trait_name}
              error={
                get(touched, `factors.${i}.negative_trait_name`) &&
                get(errors, `factors.${i}.negative_trait_name`)
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>
        <div className="ml-2 d-flex align-center">
          <Delete onSubmit={onDelete} />
        </div>
      </div>
    );
  }
);
