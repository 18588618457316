import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './ConfirmModal.scss';
import { Modal, Button } from 'modules/shared/components';
import { Modal as BsModal } from 'react-bootstrap';
import classNames from 'classnames';

class ConfirmModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    content: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    submitText: PropTypes.object.isRequired,
    cancelText: PropTypes.object.isRequired,
    title: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    moduleType: PropTypes.string,
  };

  static defaultProps = {
    moduleType: 'main',
  };

  state = {
    loading: false,
  };

  /**
   * @returns {JSX.Element} People Groups component.
   */
  render() {
    const {
      handleClose,
      content,
      submitText,
      cancelText,
      title,
      loading,
      onSubmit,
      moduleType,
      ...props
    } = this.props;
    return (
      <Modal
        className={classNames('confirm-modal', moduleType)}
        onHide={handleClose}
        onSubmit={onSubmit}
        {...props}>
        <BsModal.Header closeButton>
          <BsModal.Title id="contained-BsModal-title-sm">{title}</BsModal.Title>
        </BsModal.Header>
        <BsModal.Body>{content}</BsModal.Body>
        <BsModal.Footer>
          <Button className="small" onClick={handleClose} moduleType={moduleType}>
            {cancelText}
          </Button>
          <Button
            className="small"
            disabled={loading || this.state.loading}
            moduleType={moduleType}
            onClick={async () => {
              this.setState({ loading: true });
              await onSubmit();
              this.setState({ loading: false });
            }}>
            {submitText}
          </Button>
        </BsModal.Footer>
      </Modal>
    );
  }
}

export default withStyles(style)(ConfirmModal);
