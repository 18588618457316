/**
 * @param {number} max max value
 * @returns {Object} rate options.
 */
export const getRateOptions = (max = 19) => {
  const result = [];
  for (let index = max; index >= 1; index--) {
    result.push({ value: index, label: index.toString() });
  }

  return result;
};
