import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import FeedbackBar from './FeedbackBar';
import FeedbackExplanation from '../feedback-explanation/FeedbackExplanation';
import { StarIcon } from 'modules/shared';

import styles from './FeedbackHorizontalBarChart.scss';

const MAX_RATIO = 100;
const MIN_RATIO = 0;

class FeedbackHorizontalBarChart extends Component {
  static propTypes = {
    voteResults: PropTypes.object,
    companyName: PropTypes.string,
    loading: PropTypes.bool,
    explanation: PropTypes.node,
    beforeStarExplanation: PropTypes.string,
    starExplanation: PropTypes.node,
    small: PropTypes.bool,
    showOnlyChart: PropTypes.bool,
    noMargin: PropTypes.bool,
  };

  /**
   * @returns {Object} like dislike ratio
   */
  getLikeDislikeRatio = () => {
    const { voteResults } = this.props;

    const maxCount = voteResults.likeCount + voteResults.dislikeCount;
    const likeRatio = maxCount ? Math.round((voteResults.likeCount / maxCount) * 100) : MIN_RATIO;

    return {
      likeRatio,
      dislikeRatio: maxCount ? MAX_RATIO - likeRatio : MIN_RATIO,
    };
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const {
      loading,
      explanation,
      small,
      showOnlyChart,
      noMargin,
      companyName,
      beforeStarExplanation,
      starExplanation,
    } = this.props;
    const { likeRatio, dislikeRatio } = this.getLikeDislikeRatio();

    return likeRatio || dislikeRatio ? (
      <Fragment>
        <div
          className={classNames('feedback-horizontal-bar-chart w-100 d-flex', {
            small,
            'mt-3 mb-2': !noMargin,
          })}>
          <FeedbackBar
            containerClassName="like-bar"
            iconClassName="icon-like"
            percent={likeRatio}
            maxRatio={MAX_RATIO}
            minRatio={MIN_RATIO}
            loading={loading}
          />
          <FeedbackBar
            containerClassName="dislike-bar"
            iconClassName="icon-dislike"
            percent={dislikeRatio}
            maxRatio={MAX_RATIO}
            minRatio={MIN_RATIO}
            loading={loading}
          />
        </div>
        {!showOnlyChart ? (
          <FeedbackExplanation
            explanation={
              explanation || (
                <Fragment>
                  <FormattedMessage
                    id={beforeStarExplanation || 'MAIN.FEEDBACK_COMPANY_RATIO_EXPLANATION'}
                    values={{ companyName: companyName || '' }}
                  />
                  <StarIcon className="feedback-star-icon" />
                  {starExplanation || (
                    <FormattedMessage id="MAIN.FEEDBACK_COMPANY_STAR_EXPLANATION" />
                  )}
                </Fragment>
              )
            }
            small={small}
          />
        ) : null}
      </Fragment>
    ) : null;
  }
}

export default withStyles(styles)(FeedbackHorizontalBarChart);
