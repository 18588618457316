import { GET_ALL_SURVEYS, GET_LATEST_SURVEY, GET_SURVEY_ANALYTICS } from '../actions/surveys';

const defaultState = {
  surveys: [],
  survey: {},
  analytics: [],
};

/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const surveys = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_SURVEYS: {
      return { ...state, surveys: action.payload || [] };
    }
    case GET_LATEST_SURVEY: {
      return { ...state, survey: action.payload || {} };
    }
    case GET_SURVEY_ANALYTICS: {
      return { ...state, analytics: action.payload || [] };
    }
    default:
      return state;
  }
};

export default surveys;
