import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Button, Img } from 'modules/shared';
import { getStringToUrl } from 'common';
import Env from 'env';
import classNames from 'classnames';

class PressList extends Component {
  static propTypes = {
    blogPosts: PropTypes.object.isRequired,
    titleId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    keyId: PropTypes.string.isRequired,
    loadMoreData: PropTypes.func.isRequired,
    showLoadMoreData: PropTypes.func.isRequired,
  };

  /**
   * @returns {JSX.Element} - Our People component
   */
  render() {
    const { titleId, blogPosts, id, keyId, loadMoreData, showLoadMoreData } = this.props;
    return (
      <Row>
        <Col xs={12} sm={12} className="blog-section-title">
          <h4 className="mt-3 mb-3">
            <FormattedMessage id={titleId} />
          </h4>
          <hr />
        </Col>
        <Col
          xs={12}
          sm={12}
          className={classNames('pl-0 pr-0 mb-3 d-flex flex-wrap', {
            'justify-center': blogPosts.data && !blogPosts.data.length,
          })}>
          {blogPosts.data && !!blogPosts.data.length ? (
            blogPosts.data.map((data, blogPostIndex) => {
              return (
                <Col xs={12} sm={6} md={4} key={blogPostIndex} className="pt-3 blog-posts-card">
                  <div>
                    <NavLink to={`/press/${data.id}/${getStringToUrl(data.title)}`}>
                      <Col className="blog-posts-card-img p-0">
                        <Img
                          src={
                            data.featured_image_thumbnail_path
                              ? Env.S3_URL + data.featured_image_thumbnail_path
                              : Env.ANALYST_DEFAULT_IMAGE
                          }
                          alt={data.meta_description}
                          title={data.meta_title}
                        />
                      </Col>
                      <Col className="blog-posts-card-summary">{data.title}</Col>
                    </NavLink>
                  </div>
                </Col>
              );
            })
          ) : (
            <div>
              <h5>
                <FormattedMessage id="MAIN.BLOG_SECTIONS.PRESS_NO_DATA" />
              </h5>
            </div>
          )}
        </Col>
        {showLoadMoreData(blogPosts) ? (
          <Col xs={12} sm={12} className="load-more-btn-container d-flex justify-center">
            <Button
              type="button"
              moduleType="analyst"
              className="small"
              onClick={() => loadMoreData(blogPosts, id, keyId)}>
              <FormattedMessage id="MAIN.LOAD_MORE" />
            </Button>
          </Col>
        ) : null}
      </Row>
    );
  }
}

export default PressList;
