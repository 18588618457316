import React from 'react';
import { Modal as BsModal } from 'react-bootstrap';
import { Button } from '../';
import PropTypes from 'prop-types';
import styles from './Modal.scss';
import withStyles from 'isomorphic-style-loader/withStyles';

class Modal extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onSecondary: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    size: PropTypes.string,
  };

  static defaultProps = {
    data: {},
  };

  state = {
    loading: false,
  };

  onSubmit = async (e) => {
    if (this.props.onSubmit) {
      this.setState((prevState) => ({ loading: !prevState.loading }));
      await this.props.onSubmit(e);
      this.setState((prevState) => ({ loading: !prevState.loading }));
    }
  };

  /**
   * @param {Object} props.data object for modal informations, like heade text.
   * @param {Function} props.onSecondary - onClick event handler for secondary button.
   * @param {Function} props.onHide - onClick event handler for close modal.
   * @param {Function} props.onSubmit - onClick event handler for submit then close modal.
   * @param {node} props.children - node for children.
   *
   * @returns {JSX.Element} Modal component.
   */
  render() {
    const { onSecondary, onSubmit, data, loading, size, ...props } = this.props;
    return (
      <BsModal
        {...props}
        className={`model-dialog-${size}`}
        bsSize={size ? undefined : 'small'}
        aria-labelledby="contained-BsModal-title-sm">
        <BsModal.Header closeButton>
          <BsModal.Title id="contained-BsModal-title-sm">{data.modalHeadText}</BsModal.Title>
        </BsModal.Header>
        <BsModal.Body>{this.props.children}</BsModal.Body>
        <BsModal.Footer>
          <Button onClick={onSecondary}>{data.modalSecondaryText}</Button>
          <Button loading={loading || this.state.loading} onClick={this.onSubmit}>
            {data.modalSubmitText}
          </Button>
        </BsModal.Footer>
      </BsModal>
    );
  }
}

export default withStyles(styles)(Modal);
