import { UPLOAD_FILE } from '../actions/file-upload';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const fileUpload = (state = [], action) => {
  switch (action.type) {
    case UPLOAD_FILE: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default fileUpload;
