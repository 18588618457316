import * as MainService from '../services';

export const SEND_MESSAGE = 'SEND_MESSAGE';

/**
 * @param {Object} message contact message.
 *
 * @returns {Promise} Async Contact action.
 */
export const sendMessage = (message) => async (dispatch) => {
  const response = await MainService.sendMessage(message);

  dispatch({
    type: SEND_MESSAGE,
    payload: response,
  });

  return response;
};
