import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Env from 'env';
import { CompanyPostResult } from 'modules/shared';

class MyResearch extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    bookmarkPost: PropTypes.func.isRequired,
    bookmarkCompany: PropTypes.func.isRequired,
    getBookmarks: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    rounded: PropTypes.bool,
  };

  state = {
    companies: [],
    posts: [],
    loading: true,
  };

  /**
   * @description Sets the state after bookmark action.
   * @param {Object} companies Part of state to be updated.
   */
  afterCompanyBookmark = (companies) => {
    this.setState({ companies });
  };

  /**
   * @description Sets the state after bookmark action.
   * @param {Object} posts Part of state to be updated.
   */
  afterPostBookmark = (posts) => {
    this.setState({ posts });
  };

  /**
   * @description Gets all of the bookmarked posts and companies.
   */
  async componentDidMount() {
    this.props.showLoader();
    const { companies, posts } = await this.props.getBookmarks();
    this.setState({ companies, posts, loading: false });
    this.props.hideLoader();
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { companies, posts, loading } = this.state;
    return (
      <div>
        <FormattedMessage id="MAIN.BOOKMARKS">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {!loading ? (
          <CompanyPostResult
            companyTitle={<FormattedMessage id={'MAIN.MY_BOOKMARKED_COMPANIES'} />}
            postsTitle={<FormattedMessage id={'MAIN.MY_BOOKMARKED_POST'} />}
            companyEmptyMessage={<FormattedMessage id={'MAIN.BOOKMARKS_EMPTY'} />}
            postEmptyMessage={<FormattedMessage id={'MAIN.BOOKMARKS_EMPTY'} />}
            posts={posts}
            rounded={this.props.rounded}
            companies={companies}
            bookmarkCompany={this.props.bookmarkCompany}
            bookmarkPost={this.props.bookmarkPost}
            deleteBookmark={this.props.deleteBookmark}
            afterCompanyBookmark={this.afterCompanyBookmark}
            afterPostBookmark={this.afterPostBookmark}
            basePath={this.props.basePath}
            defaultImage={Env.ANALYST_DEFAULT_IMAGE}
          />
        ) : null}
      </div>
    );
  }
}

export default MyResearch;
