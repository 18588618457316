import request, { Methods } from 'modules/shared/util/request';

/**
 * @description Gets vimeo data.
 *
 * @param {string} site aiming site
 * @param {string} slug aiming slug
 * @param {Object} data include file
 *
 * @returns {Promise<*>}
 */
export const pagesUploadFile = async (site, slug, data) => {
  return await request({
    resource: `pages/${site}/${slug}/file`,
    method: Methods.POST,
    data,
  });
};

/**
 * @description Gets vimeo data.
 *
 * @param {Object} data include file
 *
 * @returns {Promise<*>}
 */
export const commonUploadFile = async (data) => {
  return await request({
    resource: `files/upload`,
    method: Methods.POST,
    data,
  });
};

/**
 * @description delete file
 *
 * @param {number} media_id id of uploaded file
 * @param {string} path of uploaded file
 *
 * @returns {Promise<*>}
 */
export const deleteFile = async ({ media_id, path }) => {
  return await request({
    resource: media_id ? `media/${media_id}` : `media?path=${path}`,
    method: Methods.DELETE,
  });
};
