import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, Redirect, withRouter, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import withStyles from 'isomorphic-style-loader/withStyles';

import { showLoader, hideLoader } from 'modules/main/actions/loader';
import {
  saveUserSettings,
  saveUserPreferences,
  getUserPreferences,
  getUserSettings,
  getProfile,
  saveProfile,
  exportProfile,
  deleteProfile,
  logout,
} from 'modules/main/actions/users';
import { CanAccess, Roles, ConfirmModal } from 'modules/shared';
import EditProfile from './EditProfile';
import AnalystPreferencesContainer from './AnalystPreferencesContainer';
import EdgePreferences from './EdgePreferences';
import styles from './Profile.scss';
import { match } from 'common';
import classNames from 'classnames';

class Profile extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    preferences: PropTypes.object.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    exportProfile: PropTypes.func.isRequired,
    saveProfile: PropTypes.func.isRequired,
    deleteProfile: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    saveUserSettings: PropTypes.func.isRequired,
    saveUserPreferences: PropTypes.func.isRequired,
    getUserSettings: PropTypes.func.isRequired,
    getUserPreferences: PropTypes.func.isRequired,
    className: PropTypes.string,
    moduleType: PropTypes.string,
    match,
  };

  state = {
    showConfirmModal: false,
    loading: true,
  };

  /**
   * @description Hides the modal.
   */
  handleClose = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @description Shows the modal.
   */
  handleShow = () => {
    this.setState({ showConfirmModal: true });
  };

  /**
   * @description Gets data for personal information
   */
  async componentDidMount() {
    this.props.showLoader();
    await this.props.getProfile();
    this.props.hideLoader();

    this.setState({ loading: false });
  }

  deleteProfile = async () => {
    await this.props.deleteProfile();
    this.props.logout();
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className={classNames('profile', this.props.className)}>
        <FormattedMessage id="MAIN.PROFILE">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {!this.state.loading ? (
          <div className="profile-tabs">
            <div className="tab-list">
              <NavLink className="tab" to={`${this.props.match.path}/edit`}>
                <FormattedMessage id="MAIN.PROFILE" />
              </NavLink>
              <CanAccess allowedRoles={[Roles.AnalystSubscriber]}>
                <NavLink className="tab" to={`${this.props.match.path}/analyst-preferences`}>
                  <FormattedMessage id="MAIN.ANALYST_PREFERENCES" />
                </NavLink>
              </CanAccess>
              <CanAccess allowedRoles={[Roles.EdgeSubscriber]} anySubscription>
                <NavLink className="tab" to={`${this.props.match.path}/edge-preferences`}>
                  <FormattedMessage id="MAIN.EDGE_PREFERENCES" />
                </NavLink>
              </CanAccess>
            </div>
            <div className="tab-content">
              <Switch>
                <Route
                  sensitive
                  exact
                  path={`${this.props.match.path}`}
                  render={() => <Redirect replace to={`${this.props.match.path}/edit`} />}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/edit`}
                  render={(props) => (
                    <EditProfile
                      {...props}
                      handleSubmit={this.props.saveProfile}
                      profile={this.props.profile}
                      exportProfile={this.props.exportProfile}
                      deleteProfile={this.handleShow}
                    />
                  )}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/analyst-preferences`}
                  render={(props) => (
                    <CanAccess
                      allowedRoles={[Roles.AnalystSubscriber]}
                      unauthenticatedComponent={
                        <Redirect replace to={`${this.props.match.path}`} />
                      }>
                      <AnalystPreferencesContainer
                        {...props}
                        settings={this.props.settings}
                        preferences={this.props.preferences}
                        saveUserSettings={this.props.saveUserSettings}
                        saveUserPreferences={this.props.saveUserPreferences}
                        getUserPreferences={this.props.getUserPreferences}
                        getUserSettings={this.props.getUserSettings}
                      />
                    </CanAccess>
                  )}
                />
                <Route
                  sensitive
                  path={`${this.props.match.path}/edge-preferences`}
                  render={(props) => (
                    <CanAccess
                      allowedRoles={[Roles.EdgeSubscriber]}
                      anySubscription
                      unauthenticatedComponent={
                        <Redirect replace to={`${this.props.match.path}`} />
                      }>
                      <EdgePreferences
                        {...props}
                        settings={this.props.settings}
                        saveUserSettings={this.props.saveUserSettings}
                        getUserSettings={this.props.getUserSettings}
                      />
                    </CanAccess>
                  )}
                />
              </Switch>
            </div>
          </div>
        ) : null}
        <ConfirmModal
          show={this.state.showConfirmModal}
          handleClose={this.handleClose}
          onSubmit={this.deleteProfile}
          moduleType={this.props.moduleType}
          content={<FormattedMessage id="MAIN.CONFIRM_DELETE_PROFILE" />}
          title={<FormattedMessage id="MAIN.PROFILE_MENU.DELETE_PROFILE" />}
          submitText={<FormattedMessage id="MAIN.DELETE" />}
          cancelText={<FormattedMessage id="MAIN.CANCEL" />}
        />
      </div>
    );
  }
}

/**
 * @param {Object} state Application State
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = (state) => {
  return {
    profile: state.app.profile || {},
    settings: state.app.userSettings || {},
    preferences: state.app.userPreferences || [],
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      saveUserSettings,
      saveUserPreferences,
      getUserSettings,
      getUserPreferences,
      getProfile,
      saveProfile,
      exportProfile,
      deleteProfile,
      logout,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile))
);
