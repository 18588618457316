import React from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './style.scss';

/**
 * @param {Object | string} label Checkbox label
 * @param {boolean} disabled Disabled state
 * @param {Object} props Injected Props
 * @returns {JSX.Element}
 */
const CheckBox = ({ label, disabled = false, ...props }) => {
  return (
    <label className={classNames('CheckBox', { disabled, 'has-label': !!label })}>
      <input className="form-check-input" type="checkbox" disabled={disabled} {...props} />

      <span className="box">
        <span className="tick">
          <i className="fas fa-check" />
        </span>
      </span>
      <span className="label">{label}</span>
    </label>
  );
};

CheckBox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(CheckBox);
