import React from 'react';
import PropTypes from 'prop-types';
import styles from './MegaMenuDropdown.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Dropdown, MenuItem } from 'modules/shared';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { match } from 'common';
import classNames from 'classnames';

class MegaMenuDropdown extends React.Component {
  static propTypes = {
    match,
    profile: PropTypes.object.isRequired,
    darkColor: PropTypes.bool,
    toggleAnalystLoginForm: PropTypes.func.isRequired,
    className: PropTypes.string,
    location: PropTypes.object,
  };

  /**
   * @returns {JSX.Element} ProfileDropdown component.
   */
  render() {
    return (
      <div
        className={classNames(
          'profile-dropdown-container mega-menu-dropdown-container',
          this.props.className
        )}>
        <Dropdown toggle={<i className="icon-hamburger" />} id="profile-dropodown">
          <MenuItem href="/">
            <FormattedMessage id="MAIN.MEGA_MENU.THE_ANALYST" />
          </MenuItem>
          <MenuItem href="/about">
            <FormattedMessage id="MAIN.HEADER.ABOUT_US" />
          </MenuItem>
          <MenuItem href="/people">
            <FormattedMessage id="MAIN.HEADER.OUR_PEOPLE" />
          </MenuItem>
          <MenuItem href="/sample-research">
            <FormattedMessage id="ANALYST.HEADER.SAMPLE_RESEARCH" />
          </MenuItem>
          <MenuItem href="/careers">
            <FormattedMessage id="MAIN.HEADER.CAREERS" />
          </MenuItem>
          <MenuItem href="/press">
            <FormattedMessage id="MAIN.HEADER.PRESS" />
          </MenuItem>
          <MenuItem href="/contact">
            <FormattedMessage id="MAIN.HEADER.CONTACT" />
          </MenuItem>

          {this.props.profile && !this.props.profile.id && (
            <MenuItem onClick={this.props.toggleAnalystLoginForm}>
              <FormattedMessage id="MAIN.CLIENT_LOGIN_HEADER" />
            </MenuItem>
          )}
          {this.props.profile &&
            this.props.profile.id &&
            !this.props.location.pathname.includes('app') && (
              <MenuItem href="/app">
                <FormattedMessage id="MAIN.RESEARCH" />
              </MenuItem>
            )}
        </Dropdown>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MegaMenuDropdown));
