import { AsyncState } from '../util/asyncReducerState';
import {
  GET_INVESTOR_TYPES_STARTED,
  GET_INVESTOR_TYPES_COMPLETED,
  GET_INVESTOR_TYPES_FAILED,
} from '../actions/investorTypes';

export default (state = { state: AsyncState.INITIAL, data: [] }, action) => {
  switch (action.type) {
    case GET_INVESTOR_TYPES_STARTED:
      return { state: AsyncState.STARTED, data: [] };
    case GET_INVESTOR_TYPES_COMPLETED:
      return { state: AsyncState.COMPLETED, data: action.payload || [] };
    case GET_INVESTOR_TYPES_FAILED:
      return { state: AsyncState.FAILED, data: [] };
    default:
      return state;
  }
};
