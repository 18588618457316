import { SHOW_LOADER, HIDE_LOADER } from '../actions/loader';

/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Object} Modified state.
 */
const loader = (state = { showCount: 0 }, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { showCount: state.showCount + 1 };
    case HIDE_LOADER:
      return { showCount: state.showCount > 0 ? state.showCount - 1 : 0 };
    default:
      return state;
  }
};

export default loader;
