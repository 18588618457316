import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { bookmarkPost, getPost, getOtherVideos, getMoreIdeas } from 'modules/analyst/actions/posts';
import { deleteAnalystBookmark as deleteBookmark } from 'modules/shared/actions/bookmarks';

import PostDetail from './PostDetail';

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getPost,
      deleteBookmark,
      bookmarkPost,
      getOtherVideos,
      getMoreIdeas,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PostDetail);
