import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Spinner from './Spinner';
import styles from './SpinnerOverlay.scss';

/**
 * @description Full screen spinner.
 *
 * @returns {JSX.Element}
 */
export const AppLoader = () => {
  return (
    <div className="spinner-overlay">
      <Spinner />
    </div>
  );
};

/**
 * @returns {JSX.Element}
 */
const SpinnerOverlay = ({ show }) => {
  return show > 0 ? <AppLoader /> : null;
};

SpinnerOverlay.propTypes = {
  show: PropTypes.number,
};

export default withStyles(styles)(SpinnerOverlay);
