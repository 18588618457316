import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './PeopleList.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import {
  FieldGroup,
  Delete,
  FileUpload,
  AddPlaceholder,
  DragHandle,
  getErrorState,
} from 'modules/admin/shared';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableBlock = SortableElement(
  ({ block, removePerson, onDropFile, onChangePerson, personIndex, deleteFile }) => (
    <Col lg={4} md={4} sm={6} xs={12} className="people-list-item embed-list-drag-drop-container">
      <div className="content">
        <FileUpload
          file={block.image}
          fileTitle={block.fileTitle}
          fileAlt={block.fileAlt}
          deleteFile={() => deleteFile(personIndex)}
          onChange={(event) => onChangePerson(event, personIndex)}
          onDropFile={(files) => {
            onDropFile(files, personIndex);
          }}
        />
        <hr className="mt-0" />
        <div>
          <FieldGroup
            label="Name"
            error={getErrorState(block.name)}
            type="text"
            name="name"
            value={block.name}
            onChange={(event) => onChangePerson(event, personIndex)}
          />
        </div>
        <div>
          <FieldGroup
            type="text"
            error={getErrorState(block.job_position)}
            label="Job Position"
            name="job_position"
            value={block.job_position}
            onChange={(event) => onChangePerson(event, personIndex)}
          />
        </div>
        <div>
          <FieldGroup
            componentClass="textarea"
            error={getErrorState(block.introduction)}
            label="Introduction"
            name="introduction"
            value={block.introduction}
            onChange={(event) => onChangePerson(event, personIndex)}
          />
        </div>
        <div>
          <FieldGroup
            className="checkbox-form-group"
            label="Analyst"
            inline
            type="checkbox"
            name="analyst"
            checked={block.analyst}
            onChange={(event) => onChangePerson(event, personIndex, 'checkbox')}
          />
        </div>
        <div>
          <FieldGroup
            className="checkbox-form-group"
            label="Edge"
            inline
            type="checkbox"
            name="edge"
            checked={block.edge}
            onChange={(event) => onChangePerson(event, personIndex, 'checkbox')}
          />
        </div>
        <DragHandle />
        <Delete onSubmit={() => removePerson(personIndex)} />
      </div>
    </Col>
  )
);

const SortableBlockContainer = SortableContainer(
  ({ people, addPerson, removePerson, onDropFile, onChangePerson, groupIndex, deleteFile }) => {
    return (
      <Row className="list-item-row">
        {[
          ...people.map((block, personIndex) => (
            <SortableBlock
              collection={groupIndex}
              index={personIndex}
              key={personIndex}
              personIndex={personIndex}
              deleteFile={deleteFile}
              block={block}
              addPerson={addPerson}
              removePerson={removePerson}
              onDropFile={onDropFile}
              onChangePerson={onChangePerson}
            />
          )),
          <Col
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="people-list-item embed-list-item placeholder"
            key="add-person">
            <AddPlaceholder onClick={addPerson} />
          </Col>,
        ]}
      </Row>
    );
  }
);

class PeopleList extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    people: PropTypes.array.isRequired,
    onChangePerson: PropTypes.func.isRequired,
    addPerson: PropTypes.func.isRequired,
    removePerson: PropTypes.func.isRequired,
    onDropFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    groupIndex: PropTypes.number.isRequired,
  };

  /**
   * @param {string} props.type - type of draggable table.
   * @param {array} props.people - contain data of people.
   * @param {Function} props.onChangePerson - onChange event handler for person changes.
   * @param {Function} props.addPerson - onChange event handler for add new person.
   * @param {Function} props.removePerson - onChange event handler for remove person.
   *
   * @returns {JSX.Element} PeopleList component to add/edit people.
   */
  render() {
    return (
      <div className="people-list">
        <SortableBlockContainer
          people={this.props.people}
          addPerson={this.props.addPerson}
          removePerson={this.props.removePerson}
          onDropFile={this.props.onDropFile}
          deleteFile={this.props.deleteFile}
          groupIndex={this.props.groupIndex}
          onChangePerson={this.props.onChangePerson}
          onSortEnd={this.props.onDragEnd}
          useWindowAsScrollContainer
          helperClass="sorting-embed-list-item"
          useDragHandle
          axis="xy"
          delay={100}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PeopleList);
