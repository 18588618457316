import * as CompaniesService from 'modules/shared/services/companies';
import { Status } from '../../shared/util/http';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_PAGE = 'GET_COMPANIES_PAGE';
export const GET_COMPANY = 'GET_COMPANY';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const MODIFY_COMPANY = 'MODIFY_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const BOOKMARK_COMPANY = 'BOOKMARK_COMPANY';
export const GET_ADMIN_COMPANIES = 'GET_ADMIN_COMPANIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';

/**
 * @description Gets the available companies.
 * @param {Object} options Query options.
 * @returns {Function}
 */
export const getCompanies = (options) => async (dispatch) => {
  const companies = await CompaniesService.getCompanies(options);

  dispatch({
    type: GET_COMPANIES,
    payload: companies,
  });

  return companies;
};

/**
 * @description Gets the available companies.
 * @param {Object} search Parsed query params.
 * @returns {Function}
 */
export const getAzCompanies = (search) => async () => {
  return await CompaniesService.getAZCompanies(search);
};

/**
 * @description Gets the company page for the given options.
 * @param {Object} options Get page options.
 * @returns {Function}
 */
export const getCompanyPage = (options) => async (dispatch) => {
  const page = await CompaniesService.getCompanyPage(options);

  dispatch({
    type: GET_COMPANIES_PAGE,
    payload: page,
  });

  return page;
};

/**
 * @description Gets the company for the ID.
 * @param {number} id Company ID.
 * @returns {Function}
 */
export const getCompany = (id) => async (dispatch) => {
  try {
    const company = await CompaniesService.getCompany(id);

    dispatch({
      type: GET_COMPANY,
      payload: company,
    });

    return company;
  } catch (e) {
    if (e.response && e.response.status === Status.NOT_FOUND) {
      e.ignoreToast = true;
    }
    throw e;
  }
};

/**
 * @param {number} id Company Id.
 * @param {Object} options Paging options.
 *
 * @returns {Function}
 */
export const getCompanyPostPage = (id, options) => async () => {
  return await CompaniesService.getCompanyPostPage(id, options);
};

/**
 * @param {Object} data Company data.
 * @returns {Function}
 */
export const saveCompany = (data) => async (dispatch) => {
  const company = await CompaniesService.saveCompany(data);

  dispatch({
    type: SAVE_COMPANY,
    payload: company,
  });

  return company;
};

/**
 * @param {number} id Company Id
 * @param {Object} data Company data.
 * @returns {Function}
 */
export const modifyCompany = (id, data) => async (dispatch) => {
  const company = await CompaniesService.modifyCompany(id, data);

  dispatch({
    type: MODIFY_COMPANY,
    payload: company,
  });

  return company;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const deleteCompany = (id) => async (dispatch) => {
  const company = await CompaniesService.deleteCompany(id);

  dispatch({
    type: DELETE_COMPANY,
    payload: company,
  });

  return company;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const bookmarkCompany = (id) => async (dispatch) => {
  const bookmark = await CompaniesService.bookmarkCompany(id);

  dispatch({
    type: BOOKMARK_COMPANY,
    payload: bookmark,
  });

  return bookmark;
};

/**
 * @param {number} id Company Id
 * @returns {Function}
 */
export const refreshPercent = (id) => async () => {
  return await CompaniesService.refreshPercent(id);
};

/**
 * @description Gets the available companies.
 * @param {Object} options Query options.
 * @returns {Function}
 */
export const getAdminCompanies = () => async (dispatch) => {
  const companies = await CompaniesService.getAdminCompanies();

  dispatch({
    type: GET_ADMIN_COMPANIES,
    payload: companies,
  });

  return companies;
};

/**
 * @returns {Function} Async action.
 */
export const fetchCompanyCountries = () => async (dispatch) => {
  const countries = await CompaniesService.fetchCompanyCountries();

  dispatch({ type: GET_COUNTRIES, payload: countries });

  return countries;
};
