import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { handleDisableContextMenuEvent } from 'modules/shared';

class Img extends Component {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    enableContextMenu: PropTypes.bool,
    onContextMenu: PropTypes.func,
  };

  static defaultProps = {
    enableContextMenu: false,
  };

  handleContextMenu = (e) => {
    const { enableContextMenu, onContextMenu } = this.props;

    if (onContextMenu) {
      onContextMenu(e);
    }

    if (!enableContextMenu) {
      handleDisableContextMenuEvent(e);
    }
  };

  /**
   * @returns {JSX.Element | null}
   */
  render() {
    const { src, alt, enableContextMenu, ...props } = this.props;

    return <img src={src} alt={alt} {...props} onContextMenu={this.handleContextMenu} />;
  }
}

export default Img;
