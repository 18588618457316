import React from 'react';
import { Modal as BsModal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './style.scss';
import withStyles from 'isomorphic-style-loader/withStyles';

class Index extends React.Component {
  static propTypes = {
    onHide: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func,
    bsSize: PropTypes.string,
  };

  static defaultProps = {
    bsSize: 'large',
  };

  /**
   * @param {Function} props.onHide - onClick event handler for close modal.
   * @param {Function} props.onSubmit - onClick event handler for submit then close modal.
   * @param {node} props.children - node for children.
   *
   * @returns {JSX.Element} Modal component.
   */
  render() {
    const { bsSize, ...props } = this.props;
    return (
      <BsModal {...props} bsSize={bsSize} aria-labelledby="contained-BsModal-title-sm">
        {this.props.children}
      </BsModal>
    );
  }
}

export default withStyles(styles)(Index);
