import { GET_SYSTEM_SETTINGS, GET_ALL_SYSTEM_SETTINGS } from '../actions/system-settings';

/**
 * @param {Object} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Object} Modified state.
 */
const systemSettings = (state = {}, action) => {
  switch (action.type) {
    case GET_SYSTEM_SETTINGS:
      return { ...state, page: action.payload || {} };
    case GET_ALL_SYSTEM_SETTINGS:
      return { ...state, all: action.payload || [] };
    default:
      return state;
  }
};

export default systemSettings;
