import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Modal as BsModal, Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Modal, TextInputField, SelectField, CheckboxField, Button } from 'modules/shared';
import { fetchCountries } from 'modules/shared/actions/countries';
import { fetchInvestorTypes } from 'modules/shared/actions/investorTypes';
import {
  isCountriesFetchStarted,
  isInvestorTypesFetchStarted,
  getCountries,
  getInvestorTypes,
} from 'modules/shared/states';
import * as contactsService from 'modules/shared/services/contacts';
import { ContactModalFilledContext } from '../contactModalFilledContext';
import { ModalHeader, ModalGrid } from 'modules/shared';

import styles from './style.scss';

const ContactModal = ({
  children,
  containerProtectWithContactModal,
  protectWithContactModal,
  information,
  onSuccess,
  showFooter = true,
  intl,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const [openContactModal, setOpenContactModal] = useState(false);
  const { contactModalFilled, setContactModalFilled, page } = useContext(ContactModalFilledContext);
  const protectionActive =
    (containerProtectWithContactModal || protectWithContactModal) && !contactModalFilled;

  const isCountriesFetchingInProgress = useSelector(isCountriesFetchStarted);
  const isInvestorTypesFetchingInProgress = useSelector(isInvestorTypesFetchStarted);
  const countries = useSelector(getCountries);
  const investorTypes = useSelector(getInvestorTypes);

  /**
   * @param {string} id message id
   *
   * @description format message.
   *
   * @returns {string} formatted message by intl
   */
  const formatMessage = (id) => intl.formatMessage({ id });

  /**
   * @description fetch data.
   */
  const fetchData = () => {
    if (!isCountriesFetchingInProgress && !countries.length) {
      dispatch(fetchCountries());
    }

    if (!isInvestorTypesFetchingInProgress && !investorTypes.length) {
      dispatch(fetchInvestorTypes());
    }
  };

  /**
   * @description check contact protection.
   *
   * @returns {boolean} show contact Modal
   */
  const showProtectionContactModal = () => {
    if (protectionActive || typeof children === 'function') {
      fetchData();
      setOpenContactModal(true);
    }
  };

  const hide = () => {
    setOpenContactModal(false);
  };

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    type_of_investor: '',
    country_of_residence: '',
    message: null,
    consent: false,
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(formatMessage('MAIN.CONTACT_MODAL.ERRORS.FIRST_NAME')),
    last_name: Yup.string().required(formatMessage('MAIN.CONTACT_MODAL.ERRORS.LAST_NAME')),
    email: Yup.string()
      .email()
      .required(formatMessage('MAIN.CONTACT_MODAL.ERRORS.EMAIL')),
    type_of_investor: Yup.object()
      .required(formatMessage('MAIN.CONTACT_MODAL.ERRORS.TYPE_OF_INVESTOR'))
      .nullable(),
    country_of_residence: Yup.object()
      .required(formatMessage('MAIN.CONTACT_MODAL.ERRORS.COUNTRY_OF_RESIDENCE'))
      .nullable(),
    message: Yup.string().nullable(),
    consent: Yup.boolean().oneOf([true], formatMessage('MAIN.CONTACT_MODAL.ERRORS.CONSENT')),
  });

  const submitHandler = async ({
    first_name,
    last_name,
    email,
    type_of_investor,
    country_of_residence,
    message,
    consent,
  }) => {
    try {
      await contactsService.saveContact({
        first_name,
        last_name,
        email,
        type_of_investor: type_of_investor.value,
        country_of_residence: country_of_residence.value,
        message,
        consent,
        page,
      });

      if (onSuccess) {
        onSuccess();
      } else {
        toast.success(<FormattedMessage id="MAIN.CONTACT_MODAL.SUCCESS" />);
      }
      setContactModalFilled();
      hide();
    } catch (e) {
      toast.error(<FormattedMessage id="MAIN.CONTACT_MODAL.ERROR" />);
    }
  };

  const handleOnSubmit = () => {
    formikRef.current.submitForm();
  };

  return (
    <>
      {protectionActive ? (
        <div className="contact-modal-cover" onClick={showProtectionContactModal} />
      ) : null}
      {showProtectionContactModal}
      {typeof children === 'function' ? children({ showProtectionContactModal }) : children}
      <Modal dialogClassName={page} show={openContactModal} bsSize="large" onHide={hide}>
        <ModalHeader close={hide} />
        <BsModal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={validationSchema}
            innerRef={formikRef}>
            {({ isSubmitting }) => (
              <Form>
                <ModalGrid>
                  <Col className="contact-modal-information">{information}</Col>
                  <Col xs={12} sm={6} className="pl-0 first-name">
                    <Field
                      component={TextInputField}
                      name="first_name"
                      placeholder={formatMessage('MAIN.CONTACT_MODAL.FIRST_NAME')}
                    />
                  </Col>
                  <Col xs={12} sm={6} className="pr-0 last-name">
                    <Field
                      component={TextInputField}
                      name="last_name"
                      placeholder={formatMessage('MAIN.CONTACT_MODAL.LAST_NAME')}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={TextInputField}
                      name="email"
                      placeholder={formatMessage('MAIN.CONTACT_MODAL.EMAIL')}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={SelectField}
                      name="type_of_investor"
                      placeholder={formatMessage('MAIN.CONTACT_MODAL.TYPE_OF_INVESTOR')}
                      options={investorTypes.map((value) => ({
                        value,
                        label: value,
                      }))}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={SelectField}
                      name="country_of_residence"
                      placeholder={formatMessage('MAIN.CONTACT_MODAL.COUNTRY_OF_RESIDENCE')}
                      options={countries.map((value) => ({
                        value,
                        label: value,
                      }))}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={TextInputField}
                      componentClass="textarea"
                      name="message"
                      noResize
                      placeholder={formatMessage('MAIN.CONTACT_MODAL.MESSAGE')}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={CheckboxField}
                      name="consent"
                      analystStyle
                      label={<FormattedMessage id="MAIN.CONTACT_MODAL.CONSENT" />}
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={handleOnSubmit}
                      loading={isSubmitting}
                      className="contact-modal-submit"
                      textColor="dark"
                      thin>
                      <FormattedMessage id="MAIN.SUBMIT" />
                    </Button>
                  </Col>
                </ModalGrid>
              </Form>
            )}
          </Formik>
        </BsModal.Body>
        {showFooter ? (
          <BsModal.Footer>
            <FormattedHTMLMessage id="MAIN.CONTACT_MODAL.GET_IN_TOUCH" />
          </BsModal.Footer>
        ) : null}
      </Modal>
    </>
  );
};

ContactModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  containerProtectWithContactModal: PropTypes.bool,
  protectWithContactModal: PropTypes.bool,
  information: PropTypes.node,
  showFooter: PropTypes.bool,
  onSuccess: PropTypes.func,
  intl: PropTypes.object,
};

export default withStyles(styles)(injectIntl(ContactModal));
