import React from 'react';
import Env from 'env';
import { FormattedMessage } from 'react-intl';

export const analystFooterLinks = [
  {
    link: `/privacy-policy`,
    label: <FormattedMessage id="ANALYST.PRIVACY_POLICY" />,
  },
  {
    link: `/terms-of-use`,
    label: <FormattedMessage id="ANALYST.TERMS_OF_USE" />,
  },
  {
    link: `/disclaimers-n-disclosures`,
    label: <FormattedMessage id="ANALYST.DISCLAIMERS_AND_DISCLOSURES" />,
  },
];

export const edgeFooterLinks = [
  {
    link: `/${Env.EDGE_BASE_PATH}/faq`,
    label: <FormattedMessage id="EDGE.FAQ" />,
  },
  {
    link: `/${Env.EDGE_BASE_PATH}/privacy-policy`,
    label: <FormattedMessage id="ANALYST.PRIVACY_POLICY" />,
  },
  {
    link: `/${Env.EDGE_BASE_PATH}/terms-of-use`,
    label: <FormattedMessage id="ANALYST.TERMS_OF_USE" />,
  },
  {
    link: `/${Env.EDGE_BASE_PATH}/disclaimers-n-disclosures`,
    label: <FormattedMessage id="ANALYST.DISCLAIMERS_AND_DISCLOSURES" />,
  },
];
