import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import UserAnalyticsList from './UserAnalyticsList';
import UserAnalyticsDetail from './UserAnalyticsDetail';
import { match } from 'common';

class UserAnalytics extends Component {
  static propTypes = {
    match,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Switch>
        <Redirect exact from={`${this.props.match.path}/`} to={`${this.props.match.path}/list`} />
        <Route
          sensitive
          path={`${this.props.match.path}/list`}
          render={(props) => <UserAnalyticsList {...props} basePath={this.props.match.path} />}
        />
        <Route
          sensitive
          path={`${this.props.match.path}/user/:id`}
          render={(props) => <UserAnalyticsDetail {...props} />}
        />
      </Switch>
    );
  }
}

export default UserAnalytics;
