import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Header, MegaMenu, headerContent, ProfileDropdown, Svg } from 'modules/shared';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import style from './MainHeader.scss';
import withStyles from 'isomorphic-style-loader/withStyles';

class MainHeader extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    toggleLoginForm: PropTypes.func,
    onToggle: PropTypes.func,
    showMegaMenu: PropTypes.bool,
  };

  /**
   * @returns {JSX.Element} - Header component
   */
  render() {
    return (
      <div className="navbar-container">
        <Header light>
          <div className="d-inline-flex" itemScope itemType="http://schema.org/Organization">
            <NavLink to="/" className="main-logo" itemProp="url">
              <Svg name="the-analyst-logo-dark" itemProp="logo" />
            </NavLink>
          </div>
          <i className="icon-hamburger toggle-icon" onClick={this.props.onToggle} />
          <ProfileDropdown darkColor={true} />
          <div className="nav-header">
            <NavLink className="header-link" to="/about" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.ABOUT_US" />
            </NavLink>
            <NavLink className="header-link" to="/people" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.OUR_PEOPLE" />
            </NavLink>
            <NavLink className="header-link" to="/press" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.PRESS" />
            </NavLink>
            <NavLink className="header-link" to="/careers" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.CAREERS" />
            </NavLink>
            <NavLink className="header-link" to="/contact" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.CONTACT" />
            </NavLink>
          </div>
        </Header>
        <MegaMenu
          showMenu={this.props.showMegaMenu}
          showBorder={true}
          toggleMenu={this.props.onToggle}
          toggleAnalystLoginForm={() => this.props.toggleLoginForm('showAnalystLoginForm')}
          toggleEdgeLoginForm={() => this.props.toggleLoginForm('showEdgeLoginForm')}
        />
      </div>
    );
  }
}

export default withStyles(style)(headerContent(MainHeader));
