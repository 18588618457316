import React, { Component } from 'react';
import Env from 'env';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { Footer, Content, Button } from 'modules/shared';
import { PublicHeader } from 'modules/analyst/modules/shared';
import { Row, Col, Grid } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './Landing.scss';
import analystBg from 'assets/video/analyst_background_homepage.mp4';
import PropTypes from 'prop-types';
import { analystFooterLinks, location, redirectLink } from 'common';
import FindOut from './FindOut';
import Cookies from 'js-cookie';

class Landing extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    location,
  };

  /**
   * @description start video playing and set showLoginForm popup display
   * */
  componentDidMount() {
    this.ref.play();

    this.setState({ showLoginForm: !!redirectLink(this.props.location) });
  }

  state = {
    showLoginForm: false,
  };

  onLoginToggle = (state) => {
    this.setState({ showLoginForm: state });
  };

  toggleLoginForm = () => {
    this.setState((prevState) => ({ showLoginForm: !prevState.showLoginForm }));
  };

  /**
   * @param {Object} props Analyst App Props
   *
   * @returns {JSX.Element} Analyst App Components.
   */
  render() {
    return (
      <div className="home analyst-home">
        <Helmet defer={false}>
          <title itemProp="name">Home</title>
          <meta name="description" content="Home" />
          <link rel="canonical" href={`${Env.SITE_URL}analyst`} itemProp="url" />
          <script type="application/ld+json">
            {`{
              "@context": "http://schema.org",
              "@type": "WebSite",
              "url": "${Env.SITE_URL}",
              "potentialAction": {
              "@type": "SearchAction",
              "target": "${Env.SITE_URL}search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
            }`}
          </script>

          {/*Google Search Console verification*/}
          <meta
            name="google-site-verification"
            content="4orSTBuv7xwtmD6vWaN3RUjhPdEW3knlU64fxH4-SMs"
          />

          {/*Bing verification*/}
          <meta name="msvalidate.01" content="1320AEB00209B64A8889702061022841" />

          {/*Yandex verification*/}
          <meta name="yandex-verification" content="b687c94e93d65f00" />

          {/*Twitter metas*/}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content={Env.SITE_URL} />
          <meta name="twitter:title" content="Home" />
          <meta name="twitter:description" content="Home" />
          {/*Facebook metas*/}
          <meta property="og:title" content="Home" />
          <meta property="og:url" content={Env.SITE_URL} />
          <meta property="og:description" content="Home" />
          {/*Google+*/}
          <meta itemProp="name" content="Home" />
          <meta itemProp="description" content="Home" />
        </Helmet>

        <PublicHeader
          onLoginToggle={this.onLoginToggle}
          showAnalystLoginForm={this.state.showLoginForm}
        />

        <div className="landing-page-bg">
          <div className="video-bg-container">
            <video autoPlay muted loop playsInline ref={(ref) => (this.ref = ref)}>
              <source src={analystBg} type="video/mp4" />
            </video>
          </div>

          <Content className="d-flex align-center">
            <Grid>
              <Row>
                <Col md={12} className="analyst-introduce">
                  <h1>
                    <FormattedHTMLMessage id="ANALYST.INTRODUCE" />
                  </h1>
                </Col>
                <Col md={12} className="analyst-introduce">
                  <FormattedMessage id="ANALYST.INTRODUCE_PART_TWO" />
                </Col>

                <Col md={12} className="analyst-btn">
                  {!Cookies.get(Env.REFRESH_TOKEN_ID) && (
                    <Button onClick={this.toggleLoginForm}>
                      <FormattedMessage id="MAIN.CLIENT_LOGIN" />
                    </Button>
                  )}

                  {Cookies.get(Env.REFRESH_TOKEN_ID) && (
                    <NavLink to={`/app`}>
                      <Button>
                        <FormattedMessage id="MAIN.RESEARCH" />
                      </Button>
                    </NavLink>
                  )}
                </Col>
                <Col md={12} className="find_out-btn">
                  <FindOut />
                </Col>
              </Row>
            </Grid>
          </Content>
          <Footer
            className="analyst blur"
            header={{
              links: analystFooterLinks,
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Landing);
