import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './LaunchToast.scss';
/**
 * @param {Object} props Props object
 *
 * @returns {JSX.Element} - Header component
 */
const LaunchToast = ({ textClick }) => (
  <div className="edge-launch-toast" onClick={textClick}>
    <div className="launch-info">
      <FormattedMessage id="EDGE.PRICING.EDGE_LAUNCHING_INFO" />
    </div>
  </div>
);

LaunchToast.propTypes = {
  textClick: PropTypes.func,
};

export default withStyles(style)(LaunchToast);
