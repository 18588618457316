import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './EmptyState.scss';

class EmptyState extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  };
  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className="empty-state">
        <div className="title">{this.props.title}</div>
        <div className="sub-title">{this.props.subTitle}</div>
      </div>
    );
  }
}

export default withStyles(styles)(EmptyState);
