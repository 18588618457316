import React, { Component } from 'react';
import analyticsRoutes from 'modules/admin/main/routes/analytics';
import renderRoutes from 'common/renderRoutes';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './Analytics.scss';

class Analytics extends Component {
  /**
   * @returns {JSX.Element} Edge component.
   */
  render() {
    return (
      <div className="admin-content analytics">
        {renderRoutes(analyticsRoutes, { basePath: `/analytics` })}
      </div>
    );
  }
}

export default withStyles(styles)(Analytics);
