import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ProgressTimer } from 'modules/shared/components';

class NextVideo extends Component {
  static propTypes = {
    onTimerEnd: PropTypes.func,
    onPause: PropTypes.func,
    time: PropTypes.number,
    visible: PropTypes.bool,
    next: PropTypes.object,
    videoElement: PropTypes.object,
  };

  static defaultProps = {
    time: 10,
  };

  state = {
    remainingTime: this.props.time,
    counting: false,
    cancelled: false,
  };

  /**
   * @description Adds onScroll event listener
   */
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  /**
   * @description Invokes the start function when the visible flag becomes true.
   * @param {Object} prevProps Previous props.
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible && this.isVideoElementInViewport()) {
      this.start();
    }
  }

  /**
   * @description Removes onScroll event listener
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  /**
   * @description Tests if video element is visible in viewport.
   *
   * @returns {boolean}
   */
  isVideoElementInViewport() {
    const { videoElement } = this.props;

    if (videoElement && videoElement.current) {
      const bounding = videoElement.current.getBoundingClientRect();

      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      );
    }

    return false;
  }

  /**
   * @description Starts or stops the counter if video gets in or out the viewport
   */
  onScroll = () => {
    if (
      this.props.visible &&
      !this.state.counting &&
      this.isVideoElementInViewport() &&
      !this.state.cancelled
    ) {
      this.start();
    }

    if (this.props.visible && this.state.counting && !this.isVideoElementInViewport()) {
      this.pause();
    }
  };

  /**
   * @description Starts the countdown.
   */
  start = () => {
    this.setState({ counting: true });
  };

  /**
   * @description Pauses the countdown.
   */
  pause = () => {
    this.setState({ counting: false });
  };

  /**
   * @param {number} remainingTimeMs remaining time in ms
   * @description update remaining time in second
   * */
  updateProgress = (remainingTimeMs) => {
    // +1 because of for example 7999 also would be 7, but it need to be 7 if 7000 or lower
    if (remainingTimeMs / 1000 + 1 < this.state.remainingTime) {
      this.setState(
        (prevState) => ({ remainingTime: prevState.remainingTime - 1 }),
        () => {
          if (this.state.remainingTime <= 0) {
            setTimeout(() => {
              this.playNext();
            }, 500);
          }
        }
      );
    }
  };

  /**
   * @description stop counting.
   */
  stop() {
    this.setState({ counting: false });
  }

  /**
   * @description Invokes the countdown end listener.
   */
  playNext = async () => {
    this.stop();

    setTimeout(() => {
      this.props.onTimerEnd && this.props.onTimerEnd();
    });
  };

  /**
   * @description Play event handler.
   */
  onPlay = () => {
    this.setState({ cancelled: false });
    this.start();
  };

  /**
   * @description Pause event handler.
   */
  onCancel = () => {
    this.setState({ cancelled: true });
    this.stop();
  };

  /**
   * @returns {JSX.Element | null}
   */
  render() {
    return this.props.visible ? (
      <div className="next-video d-flex align-center">
        <div className="d-block h-100">
          <ProgressTimer
            play={this.state.counting}
            pause={!this.state.counting}
            maxTime={this.props.time * 1000}
            updateProgress={this.updateProgress}
          />
          <div className="next-video-info d-flex align-center">
            <div>
              <div className="cursor-pointer" onClick={this.playNext}>
                {this.props.next.title}
              </div>
              <div className="will-play">
                <FormattedMessage id="MAIN.NEXT_VIDEO" />
              </div>
            </div>
            <div className="d-inline-flex align-center">
              <span className={classNames('time font-x-large')}>{this.state.remainingTime}</span>
            </div>
          </div>
        </div>
        <div className="next-video-control d-flex align-center justify-center h-100">
          {this.state.counting ? (
            <i className={classNames('fas fa-pause fa-xlg cursor-pointer')} onClick={this.onCancel}>
              <FormattedMessage id="MAIN.NEXT_VIDEO_STAY" />
            </i>
          ) : (
            <i className="fas fa-play fa-xlg cursor-pointer" onClick={this.onPlay} />
          )}
        </div>
      </div>
    ) : null;
  }
}

export default NextVideo;
