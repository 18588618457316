import { GET_EDGE_PLUS_AZ_COMPANIES, GET_EDGE_PLUS_COMPANIES } from '../actions/companies';
/**
 * @param {Object} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Object} Modified state.
 */
const companies = (state = {}, action) => {
  switch (action.type) {
    case GET_EDGE_PLUS_AZ_COMPANIES: {
      return { ...state, categorized: action.payload || {} };
    }
    case GET_EDGE_PLUS_COMPANIES: {
      return { ...state, all: action.payload || {} };
    }
    default:
      return state;
  }
};

export default companies;
