import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { match } from 'common';
import { Grid } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import styles from './CompanyDetail.scss';

/**
 * @param {Object} defaultParams Default parameters for the HOC.
 * @returns {Function}
 */
const withCompanyDetail = (defaultParams = { editItem: {} }) => (WrappedComponent) => {
  return withStyles(styles)(
    injectIntl(
      class WithCompanyDetail extends Component {
        static propTypes = {
          getCompany: PropTypes.func.isRequired,
          bookmarkCompany: PropTypes.func.isRequired,
          deleteBookmark: PropTypes.func.isRequired,
          getCompanyPostPage: PropTypes.func.isRequired,
          bookmarkPost: PropTypes.func.isRequired,
          showLoader: PropTypes.func.isRequired,
          hideLoader: PropTypes.func.isRequired,
          basePath: PropTypes.string.isRequired,
          setEditItem: PropTypes.func.isRequired,
          getRecommendationHistory: PropTypes.func,
          intl: PropTypes.object,
          match,
        };

        state = {
          company: null,
          posts: [],
          pagingParams: {},
          fetching: false,
          companyId: null,
          loading: false,
          bookmarking: false,
        };

        /**
         * @description Saves the company id if it is changed.
         *              It happens on route param change -> the component won't remount.
         * @param {Object} nextProps Incoming props.
         * @param {Object} prevState Previous state.
         * @returns {Object | null}
         */
        static getDerivedStateFromProps(nextProps, prevState) {
          if (nextProps.match.params.id !== prevState.companyId) {
            return {
              companyId: +nextProps.match.params.id,
            };
          }

          return null;
        }

        /**
         * @description Updates the company data if the id is changed.
         * @returns {Promise<void>}
         */
        componentDidUpdate() {
          const needsCompany = this.state.company && this.state.company.id !== this.state.companyId;
          if (needsCompany && !this.state.loading) {
            return this.fetchData();
          }

          return null;
        }

        /**
         * @description Calls fetch on mount.
         * @returns {Promise<void>}
         */
        componentDidMount() {
          return this.fetchData();
        }

        /**
         * @description Removes the edit option from the header dropdown.
         */
        componentWillUnmount() {
          this.props.setEditItem(null);
        }

        /**
         * @description Fetches the company data by id.
         */
        async fetchData() {
          this.props.showLoader();
          this.setState({ loading: true });
          const {
            editItem: { textId, link },
          } = defaultParams;

          try {
            const [company, recommendationHistory] = await Promise.all([
              this.props.getCompany(this.props.match.params.id),
              this.props.getRecommendationHistory &&
                this.props.getRecommendationHistory(this.props.match.params.id),
            ]);
            this.setState({ posts: [] }, () => {
              this.fetchPosts(true);
            });

            this.props.setEditItem &&
              this.props.setEditItem({
                text: this.props.intl.formatMessage({
                  id: textId ? textId : 'ANALYST.EDIT_COMPANY',
                }),
                link: link ? link.replace(':id', this.props.match.params.id) : '',
              });

            this.setState({
              company,
              recommendationHistory,
              error: false,
            });
          } catch (e) {
            this.setState({ error: true });
          } finally {
            this.setState({ loading: false });
            this.props.hideLoader();
          }
        }

        /**
         * @description Toggles the company bookmarked state.
         */
        bookmarkCompany = async () => {
          if (this.state.bookmarking) {
            return;
          }
          const { company } = this.state;
          this.setState({ bookmarking: true });
          if (company.bookmarks.length) {
            await this.props.deleteBookmark(company.bookmarks[0].id);
            this.setState({ company: { ...company, bookmarks: [] } });
          } else {
            const bookmark = await this.props.bookmarkCompany(company.id);
            this.setState({ company: { ...company, bookmarks: [bookmark] } });
          }

          this.setState({ bookmarking: false });
        };

        fetchPosts = async (init) => {
          this.setState({ fetching: true });

          const { data: posts = [], ...pagingParams } = await this.props.getCompanyPostPage(
            this.props.match.params.id,
            {
              page: init ? 1 : this.state.pagingParams.current_page + 1 || 1,
            }
          );
          this.setState({
            fetching: false,
            posts: init ? posts : [...this.state.posts, ...posts],
            pagingParams,
          });
        };

        afterBookmark = (posts) => {
          this.setState({ posts });
        };

        /**
         * @param {Object} company data
         * @description Updates company data.
         */
        updateCompany = (company) => {
          this.setState({ company });
        };

        /**
         * @returns {JSX.Element}
         */
        render() {
          return (
            <Grid fluid className="company-detail-container">
              <Helmet defer={false}>
                <title>{this.state.company ? this.state.company.title : ''}</title>
              </Helmet>
              {!this.state.loading && this.state.company ? (
                <WrappedComponent
                  {...this.props}
                  {...this.state}
                  afterBookmark={this.afterBookmark}
                  bookmarkCompany={this.bookmarkCompany}
                  fetchPosts={this.fetchPosts}
                  updateCompany={this.updateCompany}
                />
              ) : null}
              {this.state.error ? <FormattedMessage id="MAIN.COMPANY_NOT_FOUND" /> : null}
            </Grid>
          );
        }
      }
    )
  );
};

export default withCompanyDetail;
