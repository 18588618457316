export const informationTypes = [
  {
    name: 'Note',
    icon: 'icon-document-outline',
  },
  {
    name: 'Visualisation',
    icon: 'icon-presentation-outline',
  },
  {
    name: 'Video',
    icon: 'icon-video-outline',
  },
  {
    name: 'Bespoke',
    icon: 'icon-edit-outline',
  },
  {
    name: 'Authorised',
    icon: 'icon-mallet',
  },
  {
    name: 'Experienced',
    icon: 'icon-ribbon',
  },
  {
    name: 'Secure',
    icon: 'icon-lock',
  },
];
