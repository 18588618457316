import request, { Methods } from 'modules/shared/util/request';

/**
 * @param {Object} message Email Password.
 *
 * @returns {Promise}
 */
export const sendMessage = async (message) => {
  return await request({ resource: 'contact', method: Methods.POST, data: message });
};
