import React, { Component } from 'react';
import websiteStructureRoutes from 'modules/admin/main/routes/website-structure';
import renderRoutes from 'common/renderRoutes';

class WebsiteStructure extends Component {
  /**
   * @returns {JSX.Element} WebsiteStructure component.
   */
  render() {
    return (
      <div className="admin-content">{renderRoutes(websiteStructureRoutes, { basePath: `` })}</div>
    );
  }
}

export default WebsiteStructure;
