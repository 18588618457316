import request, { Methods } from 'modules/shared/util/request';

/**
 * @param {number} id media
 *
 * @returns {Promise}
 */
export const trackMediaDownload = async (id) => {
  return await request({ resource: `media/${id}`, method: Methods.GET });
};
