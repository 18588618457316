import React from 'react';
import { FormattedMessage } from 'react-intl';

export const longTermColumnsOld = [
  <FormattedMessage key={0} id="MAIN.MODEL_PORTFOLIO.QUALITY_LONG_TERM" />,
  <FormattedMessage key={1} id="MAIN.MODEL_PORTFOLIO.GROWTH" />,
  <FormattedMessage key={2} id="MAIN.MODEL_PORTFOLIO.POSITIVE_CHANGE" />,
];
export const shortTermColumnsOld = [
  <FormattedMessage key={0} id="MAIN.MODEL_PORTFOLIO.ACCOUNTING" />,
  <FormattedMessage key={1} id="MAIN.MODEL_PORTFOLIO.STRUCTURAL_DECLINE" />,
  <FormattedMessage key={2} id="MAIN.MODEL_PORTFOLIO.OVERHYPED" />,
];

export const longTermColumns = [
  <FormattedMessage key={0} id="MAIN.MODEL_PORTFOLIO.VALUE_CATALYST" />,
  <FormattedMessage key={1} id="MAIN.MODEL_PORTFOLIO.STRUCTURAL_GROWTH" />,
  <FormattedMessage key={2} id="MAIN.MODEL_PORTFOLIO.FALLEN_ANGEL" />,
  <FormattedMessage key={3} id="MAIN.MODEL_PORTFOLIO.LONG_CYCLICALS" />,
];
export const shortTermColumns = [
  <FormattedMessage key={0} id="MAIN.MODEL_PORTFOLIO.ACCOUNTING" />,
  <FormattedMessage key={1} id="MAIN.MODEL_PORTFOLIO.OVERHYPED" />,
  <FormattedMessage key={2} id="MAIN.MODEL_PORTFOLIO.STRUCTURAL_DECLINE" />,
  <FormattedMessage key={3} id="MAIN.MODEL_PORTFOLIO.SHORT_CYCLICALS" />,
];
