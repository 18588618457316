import React, { useState } from 'react';
import classNames from 'classnames';

interface MobileRecommendationsDrawerProps {
  className: string;
  icon: HTMLElement;
  title: string;
  children: React.ReactNode;
}

const MobileRecommendationsDrawer = ({
  className,
  icon,
  title,
  children,
}: MobileRecommendationsDrawerProps) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        className={classNames(className, 'sidebar-item', 'hide-on-desktop', {
          active: open,
        })}
        onClick={toggleDrawer}>
        <div className="content">
          <div className="module-icon-container">{icon}</div>
          <div className="title">{title}</div>
          <i className="expand fa fa-caret-right" />
        </div>
      </div>
      <div
        className={classNames('drawer-children', {
          hideChildren: !open,
        })}>
        {children}
      </div>
    </>
  );
};

export default MobileRecommendationsDrawer;
