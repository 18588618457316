import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { disableContextMenuOnImg } from 'modules/shared';

class Html extends Component {
  static propTypes = {
    content: PropTypes.string,
    component: PropTypes.any,
  };

  contentRef = createRef();

  /**
   * @description Removes the context menu from images
   */
  componentDidMount() {
    disableContextMenuOnImg(this.contentRef.current);
  }

  /**
   * @description Removes the context menu from images
   */
  componentDidUpdate() {
    disableContextMenuOnImg(this.contentRef.current);
  }

  /**
   * @returns {JSX.Element | null}
   */
  render() {
    const { content, component, ...props } = this.props;

    const Root = component || 'div';

    return <Root dangerouslySetInnerHTML={{ __html: content }} ref={this.contentRef} {...props} />;
  }
}

export default Html;
