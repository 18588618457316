import React, { Component } from 'react';
import systemRoutes from 'modules/admin/main/routes/system.jsx';
import renderRoutes from 'common/renderRoutes';

class System extends Component {
  /**
   * @returns {JSX.Element} Main component.
   */
  render() {
    return <div className="admin-content">{renderRoutes(systemRoutes)}</div>;
  }
}

export default System;
