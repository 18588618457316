import { combineReducers } from 'redux';
import profile from './profile';
import loader from './loader';
import contact from './contact';
import employees from './employees';
import users from './users';
import userSettings from './userSettings';
import userPreferences from './userPreferences';
import roles from './roles';
import systemSettings from './system-settings';
import press from './press';
import pressSectionFirst from './press-section-first';
import pressSectionSecond from './press-section-second';

const rootReducer = combineReducers({
  profile,
  userSettings,
  userPreferences,
  loader,
  contact,
  employees,
  users,
  roles,
  systemSettings,
  press,
  pressSectionFirst,
  pressSectionSecond,
});

export default rootReducer;
