import { matchPath, Router } from 'react-router';

// ensure we're using the exact code for default root match
const { computeMatch } = Router.prototype;

/**
 * @param {Array} routes Routes config array.
 * @param {string} pathname Path string to match the routes config array.
 * @param {Array} branch Internal array to generate route branch.
 *
 * @returns {Array} Branch of mathed components.
 */
const matchRoutes = (routes, pathname, branch = []) => {
  routes.some((route) => {
    const match = route.path
      ? matchPath(pathname, route)
      : branch.length
      ? branch[branch.length - 1].match // use parent match
      : computeMatch(pathname); // use default "root" match

    if (match) {
      branch.push({ route, match });

      if (route.routes) {
        matchRoutes(route.routes, pathname, branch);
      }
    }

    return match;
  });

  return branch;
};

export default matchRoutes;
