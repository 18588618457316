import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { match } from 'common/propTypes';
import { Switch, Route, Redirect } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Roles, CanAccess, checkPermission } from 'modules/shared';
import { appRoutes } from 'modules/analyst/appRoutes';
import AnalystAppHeader from '../analyst-app-header';
import AnalystSidebar from '../analyst-sidebar/AnalystSidebar';
import CompanyDetail from '../company-detail';

// TODO Uncomment if analyst detail page is added again
// import AnalystDetail from '../analyst-detail';

import SearchResults from '../search-results';
import PostDetail from '../post-detail';
import { history, location } from 'common';

import { HeaderSearch, appWrapper, injectedAppProps } from 'modules/shared';

import { getRecommendations } from 'modules/analyst/actions/recommendations';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

import styles from './AnalystApp.scss';

class AnalystApp extends Component {
  static propTypes = {
    match,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getRecommendations: PropTypes.func.isRequired,
    recommendations: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    systemSettings: PropTypes.object.isRequired,
    history,
    location,
    ...injectedAppProps,
  };

  /**
   * @description Get data for the component.
   */
  async componentDidMount() {
    if (this.hasAccess()) {
      this.props.showLoader();
      await this.props.getRecommendations();
      this.props.hideLoader();
    }
  }

  hasAccess = () => {
    return checkPermission(
      [Roles.AnalystSubscriber, Roles.AnalystEditor, Roles.AnalystAdmin],
      [],
      this.props.profile
    );
  };

  openSidebarDrawer = (e) => {
    this.props.toggleSearch(e, false);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const hasAccess = this.hasAccess();

    return (
      <div className="analyst-home analyst-app">
        <AnalystAppHeader
          onSidebarMenuClick={this.props.toggleSidebar}
          onSearchClick={this.props.toggleSearch}
          profileUrl={`${this.props.match.path}/profile`}
          sideMenuOpen={this.props.sidebarOpen}
          editItem={this.props.editItem}
        />
        <HeaderSearch
          className={classNames('search', {
            open: this.props.searchOpen,
            'sidebar-open': this.props.sidebarOpen,
          })}
          open={this.props.searchOpen}
          onSearch={this.props.onSearch}
          value={this.props.searchValue}
          onClose={this.props.toggleSearch}
          onSearchChange={this.props.onSearchChange}
        />
        <AnalystSidebar
          open={this.props.sidebarOpen}
          hasAccess={hasAccess}
          toggleSidebar={this.props.toggleSidebar}
          onSearchClick={this.props.toggleSearch}
          editItem={this.props.editItem}
          basePath={this.props.match.path}
          recommendations={this.props.recommendations}
          openSidebarDrawer={this.openSidebarDrawer}
          closeDrawers={this.props.searchOpen}
          systemSettings={this.props.systemSettings}
        />
        <div
          className={classNames('app-content', {
            'sidebar-open': hasAccess ? this.props.sidebarOpen : false,
            'search-open': this.props.searchOpen,
          })}>
          <Switch>
            {appRoutes.map((route) => {
              return (
                <Route
                  sensitive
                  key={route.path}
                  path={`${this.props.match.path}/${route.path}`}
                  render={(props) => {
                    return (
                      <div>
                        {!route.path.includes('profile') ? (
                          <CanAccess
                            allowedRoles={[
                              Roles.AnalystSubscriber,
                              Roles.AnalystEditor,
                              Roles.AnalystAdmin,
                            ]}
                            unauthenticatedComponent={
                              <Redirect
                                from={`${this.props.match.path}/${route.path}`}
                                exact
                                to={`${this.props.match.path}/profile`}
                              />
                            }>
                            <route.component
                              {...props}
                              basePath={this.props.match.path}
                              setEditItem={this.props.setEditItem}
                              moduleType="analyst"
                              className={classNames({
                                'sidebar-open': this.props.sidebarOpen,
                              })}
                            />
                          </CanAccess>
                        ) : (
                          <route.component
                            {...props}
                            basePath={this.props.match.path}
                            setEditItem={this.props.setEditItem}
                            moduleType="analyst"
                            className={classNames({
                              'sidebar-open': this.props.sidebarOpen,
                            })}
                          />
                        )}
                      </div>
                    );
                  }}
                />
              );
            })}
            <Route
              sensitive
              path={`${this.props.match.path}/search`}
              render={(props) => (
                <CanAccess
                  allowedRoles={[Roles.AnalystSubscriber, Roles.AnalystEditor, Roles.AnalystAdmin]}
                  unauthenticatedComponent={<Redirect to={`${this.props.match.path}`} />}>
                  <SearchResults
                    {...props}
                    onSearchChange={this.props.onSearchChange}
                    toggleSearchBar={this.props.toggleSearch}
                    basePath={this.props.match.path}
                  />
                </CanAccess>
              )}
            />
            <Route
              sensitive
              path={`${this.props.match.path}/company/:id/:slug?`}
              render={(props) => (
                <CanAccess
                  allowedRoles={[Roles.AnalystSubscriber, Roles.AnalystEditor, Roles.AnalystAdmin]}
                  unauthenticatedComponent={<Redirect to={`${this.props.match.path}`} />}>
                  <CompanyDetail
                    {...props}
                    basePath={this.props.match.path}
                    setEditItem={this.props.setEditItem}
                  />
                </CanAccess>
              )}
            />
            {/*TODO Uncomment if analyst detail page is added again*/}
            {/*<Route*/}
            {/*sensitive*/}
            {/*path={`${this.props.match.path}/analyst/:id`}*/}
            {/*render={(props) => <AnalystDetail {...props} basePath={this.props.match.path} />}*/}
            {/*/>*/}
            <Route
              sensitive
              path={`${this.props.match.path}/post/:id/:slug?`}
              render={(props) => (
                <CanAccess
                  allowedRoles={[Roles.AnalystSubscriber, Roles.AnalystEditor, Roles.AnalystAdmin]}
                  unauthenticatedComponent={<Redirect to={`${this.props.match.path}`} />}>
                  <PostDetail
                    {...props}
                    setEditItem={this.props.setEditItem}
                    basePath={this.props.match.path}
                  />
                </CanAccess>
              )}
            />
            <Redirect
              from={`${this.props.match.path}/my-research`}
              exact
              to={`${this.props.match.path}/bookmarks`}
            />
            <Redirect
              from={`${this.props.match.path}/long-screen`}
              exact
              to={`${this.props.match.path}/factor-panel`}
            />
            <Redirect
              from={this.props.match.path}
              exact
              to={`${this.props.match.path}/${
                hasAccess
                  ? this.props.settings.web_analyst_home_screen || appRoutes[0].path
                  : 'profile'
              }`}
            />
            <Redirect to={`${this.props.match.path}/404`} />
          </Switch>
        </div>
      </div>
    );
  }
}

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst, app }) => {
  return {
    recommendations: analyst.recommendations,
    settings: app.userSettings,
    profile: app.profile,
    systemSettings: app.systemSettings.all,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getRecommendations, showLoader, hideLoader }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(appWrapper(withStyles(styles)(AnalystApp)));
