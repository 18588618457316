import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCompanies } from 'modules/analyst/actions/companies';
import { getRecommendations, saveRecommendations } from 'modules/analyst/actions/recommendations';
import CurrentRecommendations from './CurrentRecommendations';
import { showLoader, hideLoader } from 'modules/main/actions/loader';

/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ analyst }) => {
  return {
    companies: analyst.companies,
    recommendations: analyst.recommendations,
  };
};

/**
 * @param {Function} dispatch Action dispatcher,
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCompanies, showLoader, hideLoader, getRecommendations, saveRecommendations },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentRecommendations);
