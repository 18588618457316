import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import Env from 'env';
import { Footer, Content, withSSR, ContactModalContainer } from 'modules/shared';
import { PublicHeader } from 'modules/analyst/modules/shared';
import { ComponentLoader } from 'modules/main';
import { getPage } from 'modules/shared/actions/pages';
import { analystFooterLinks } from 'common';
import { ContactModalPages } from 'modules/analyst/models';

import style from './SampleResearch.scss';

class SampleResearch extends Component {
  static propTypes = {
    content: PropTypes.array,
    isLoading: PropTypes.bool,
  };

  /**
   * @param {Function} param.dispatch Action dispatcher
   * @returns {Promise}
   */
  static async getInitialData({ dispatch }) {
    return await dispatch(getPage('analyst', 'sample-research'));
  }

  /**
   * @returns {JSX.Element} Analyst App Components.
   */
  render() {
    return (
      <div className="analyst-home white-background sample-research">
        <FormattedMessage id="ANALYST.HEADER.SAMPLE_RESEARCH">
          {(title) => (
            <Helmet defer={false}>
              <title itemProp="name">{title}</title>
              <link rel="canonical" href={`${Env.SITE_URL}sample-research`} itemProp="url" />
            </Helmet>
          )}
        </FormattedMessage>

        <PublicHeader />

        <ContactModalContainer page={ContactModalPages.SAMPLE_RESEARCH}>
          <Content>
            <ComponentLoader components={this.props.content} loading={this.props.isLoading} />
          </Content>
        </ContactModalContainer>

        <Footer
          className="analyst analyst-link-hover"
          header={{
            toTop: true,
            icons: true,
            logoAnalyst: true,
            links: analystFooterLinks,
          }}
        />
      </div>
    );
  }
}

export default withSSR(withStyles(style)(SampleResearch));
