import React from 'react';
import PropTypes from 'prop-types';

import { LikeQuestionPages, LikeQuestions } from 'modules/shared';

/**
 * @description Collapse title to sentiment tracker page
 * @returns {JSX.Element}
 */
const CollapseContent = ({ data, basePath, updateDataByAnswer, ...props }) =>
  data.map((item, index) => {
    /**
     * @param {Object} savedData recommended company data Object.
     * @returns {void}
     */
    const updateData = (savedData) => updateDataByAnswer(savedData, index);

    return (
      <LikeQuestions
        key={item.id}
        data={item}
        page={LikeQuestionPages.SENTIMENT_TRACKER}
        showOnlyButtonAndChart
        showOnlyFirstQuestion
        topDivider={false}
        questionLabelMedium
        labelsNoTopMargin
        hideFeedbackPopup
        inline
        redirectTo={`${basePath}/company/${item.id}`}
        bottomDivider={index < data.length - 1}
        updateDataByAnswer={updateData}
        {...props}
      />
    );
  });

CollapseContent.propTypes = {
  data: PropTypes.array.isRequired,
  basePath: PropTypes.string,
  titleHoverColor: PropTypes.string,
  updateDataByAnswer: PropTypes.func,
};

export default CollapseContent;
