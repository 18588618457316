import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { BaseDataEditor } from 'modules/admin/shared';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getSector, saveSector, modifySector } from 'modules/analyst/actions/sectors';
import { match, history } from 'common';

class AnalystSectorsEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getSector: PropTypes.func.isRequired,
    modifySector: PropTypes.func.isRequired,
    saveSector: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    match: match.isRequired,
    history: history.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <BaseDataEditor
        {...this.props}
        getBaseData={this.props.getSector}
        saveBaseData={this.props.saveSector}
        modifyBaseData={this.props.modifySector}
        createLabel={<FormattedMessage id="ADMIN.SECTORS.CREATE" />}
        editLabel={<FormattedMessage id="ADMIN.SECTORS.EDIT" />}
        basePath={`${this.props.basePath}/sectors`}
        hiddenFields={['title', 'meta_title', 'meta_description', 'keywords', 'slug']}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getSector,
      saveSector,
      modifySector,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AnalystSectorsEditor);
