import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';

import style from './HeaderSearch.scss';

class HeaderSearch extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    value: PropTypes.string,
    open: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSearch();
  };

  onSearch = () => {
    this.props.onSearch();
  };

  onChange = (e) => {
    this.props.onSearchChange(e.target.value);
  };

  /**
   * @description Sets the focus on input on open.
   * @param {Object} prevProps Props before update.
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.input.focus();
    }
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div className={classNames('header-search', this.props.className)}>
        <form noValidate onSubmit={this.onSubmit}>
          <div className="input">
            <input
              type="text"
              ref={(r) => (this.input = r)}
              placeholder={'Search...'}
              value={this.props.value}
              onChange={this.onChange}
            />
          </div>
          <div className="icons">
            <i className="icon icon-search-outline" onClick={this.props.onSearch} />
            <i className="icon close-icon icon-close-outline" onClick={this.props.onClose} />
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(style)(HeaderSearch);
