import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Grid } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AZCompanies from 'modules/shared/components/companies/AZCompanies';
import withAzFilter from 'modules/shared/components/companies/withAzFilter';
import { debounce } from 'common';
import Env from 'env';

class Companies extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getAzCompanies: PropTypes.func.isRequired,
    bookmarkCompany: PropTypes.func.isRequired,
    deleteBookmark: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    bookmarks: PropTypes.array,
    query: PropTypes.object.isRequired,
    onFilter: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    activeLetters: PropTypes.func.isRequired,
    setQueryParams: PropTypes.func.isRequired,
    getQueryObject: PropTypes.func.isRequired,
    setEditItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    bookmarks: [],
  };

  state = {
    companies: null,
  };

  /**
   * @description Gets the company and bookmark data.
   */
  async componentDidMount() {
    this.props.showLoader();
    const query = this.props.getQueryObject();
    const companies = await this.props.getAzCompanies(query);
    this.setState({ companies });
    this.props.hideLoader();
  }

  /**
   * @description Appends or removes the letter from the current filter.
   * @param {string} filterLetter Letter to remove or append.
   * @returns {Promise<*>}
   */
  onFilter = async (filterLetter) => {
    const query = this.props.onFilter(filterLetter);

    return this.getCompanies(query);
  };

  getCompanies = debounce(async (query) => {
    const companies = await this.props.getAzCompanies(query);

    this.setState({ companies });
    this.props.setQueryParams(query);
  }, 500);

  /**
   * @description Toggles the company bookmarked state.
   * @param {Object} company Company ID.
   */
  toggleBookmark = async (company) => {
    let bookmarkId = null;

    if (company.bookmark_id !== Env.NO_COMPANY_BOOKMARK) {
      await this.props.deleteBookmark(company.bookmark_id);
    } else {
      const bookmarkResponse = await this.props.bookmarkCompany(company.id);
      bookmarkId = bookmarkResponse.id;
    }

    this.setState((prevState) => {
      const companiesByFirstCharIndex = prevState.companies.findIndex(
        ({ key }) => key === company.first_char
      );
      const prevCompanyItem = prevState.companies[companiesByFirstCharIndex];
      const companies = [...prevState.companies];

      companies[companiesByFirstCharIndex] = {
        key: prevCompanyItem.key,
        items: prevCompanyItem.items.map((companyItem) => {
          return companyItem.id === company.id
            ? { ...company, bookmark_id: bookmarkId }
            : companyItem;
        }),
      };

      return {
        companies,
      };
    });
  };

  onSearch = (e) => {
    const query = this.props.onSearch(e);
    return this.getCompanies(query);
  };

  isHighlighted = (company) => {
    return company && company.has_position_sizing;
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Grid fluid>
        <FormattedMessage id="MAIN.A_Z_COMPANIES">
          {(title) => (
            <Helmet defer={false}>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {this.state.companies && (
          <AZCompanies
            companies={this.state.companies}
            onFilter={this.onFilter}
            active={this.props.activeLetters()}
            bookmark={this.toggleBookmark}
            onSearch={this.onSearch}
            searchValue={this.props.query.search}
            bookmarks={this.props.bookmarks}
            isHighlighted={this.isHighlighted}
            basePath={this.props.basePath}
            setEditItem={this.props.setEditItem}
          />
        )}
      </Grid>
    );
  }
}

export default withAzFilter(Companies);
