import * as RecommendationsService from '../services/recommendations';

export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';

/**
 * @returns {Promise} Get ideas action.
 */
export const getRecommendations = () => async (dispatch) => {
  const recommendations = await RecommendationsService.getRecommendations();

  dispatch({
    type: GET_RECOMMENDATIONS,
    payload: recommendations,
  });

  return recommendations;
};

/**
 * @returns {Promise} Get sentiment tracker ideas action.
 */
export const getSentimentTrackerRecommendations = () => async () => {
  return RecommendationsService.getSentimentTrackerRecommendations();
};

/**
 * @param {Array} recommendations Recommendations array to save.
 * @returns {Promise} Save recommendations action.
 */
export const saveRecommendations = (recommendations) => async () => {
  return await RecommendationsService.saveRecommendations(recommendations);
};
