import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import $ from 'jquery';

import { LikeDislikeButtons, QuestionLabel, SentimentTrackerLogo, SvgIcon } from 'modules/shared';
import Env from 'env';

import styles from './FeedbackPopup.scss';
import { VoteTypes } from 'common';

class FeedbackPopup extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    answering: PropTypes.number,
    clickedAnswer: PropTypes.number,
    hasAccessToVote: PropTypes.bool.isRequired,
    answerQuestion: PropTypes.func.isRequired,
  };

  state = {
    isVoted: false,
  };

  /**
   * @description Component loaded
   */
  componentDidMount() {
    if (this.getFirstLikeQuestion()?.user_vote.vote !== VoteTypes.undefined) {
      this.setState({ isVoted: true });
    }
  }

  /**
   * @returns {Object} first like questions object
   */
  getFirstLikeQuestion() {
    const { data } = this.props;

    return data?.like_questions?.find((item) => item.type_id === Env.SHOW_FEEDBACK_CHARTS_ID);
  }

  /**
   * @description Scroll to feedback link
   */
  scrollToFeedbackLink = () => {
    this.props.onClose();
    $('html,body').animate({ scrollTop: $(`#${Env.FEEDBACK_LINK}`).offset().top }, 0);
  };

  /**
   * @param {Object} e is event object
   * @description Close popup
   */
  onClose = (e) => {
    e.stopPropagation();
    this.props.onClose(e);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { data, answering, clickedAnswer, hasAccessToVote, answerQuestion, open } = this.props;
    const { isVoted } = this.state;
    const likeQuestion = this.getFirstLikeQuestion();

    if (!likeQuestion) {
      return null;
    }

    return (
      <div className={classNames('feedback-popup', { open, hide: isVoted })}>
        <div className="feedback-close-icon" onClick={this.onClose}>
          <SvgIcon name="analyst-close-medium" />
          <SvgIcon name="analyst-hover-close-medium" />
        </div>

        <div className="feedback-line" />

        <div className="feedback-popup-content">
          <div className="sentiment-tracker-popup">
            <SentimentTrackerLogo textAlign="left" noTopMargin />
          </div>
          <div className="feedback-vertical-line" />
          <div className="feedback-vote">
            <QuestionLabel question={likeQuestion.question} className="feedback-question" />
            <div className="feedback-container-answer">
              <div className="feedback-answer-buttons">
                <LikeDislikeButtons
                  question={{
                    user_vote: likeQuestion.user_vote,
                    type_id: likeQuestion.type_id,
                  }}
                  iconClass={'icon-like'}
                  answerQuestion={answerQuestion}
                  answer={VoteTypes.like}
                  answering={answering}
                  clickedAnswer={clickedAnswer}
                  disabled={!hasAccessToVote || !data.has_sector_access}
                  hideAnswer={!data.has_sector_access}
                />
                <LikeDislikeButtons
                  question={{
                    user_vote: likeQuestion.user_vote,
                    type_id: likeQuestion.type_id,
                  }}
                  iconClass={'icon-dislike'}
                  answerQuestion={answerQuestion}
                  answer={VoteTypes.dislike}
                  answering={answering}
                  clickedAnswer={clickedAnswer}
                  disabled={!hasAccessToVote || !data.has_sector_access}
                  hideAnswer={!data.has_sector_access}
                />
              </div>
              {likeQuestion.user_vote.vote > VoteTypes.undefined ? (
                <div className="feedback-thank-message" onClick={this.scrollToFeedbackLink}>
                  <FormattedMessage id="MAIN.FEEDBACK_POPUP" />
                  <div className="feedback-scroll-icon">
                    <SvgIcon width={30} height={30} name="down-arrow" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FeedbackPopup);
