import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import { ErrorIcon } from 'modules/admin/shared';

import styles from './ErrorIconContainer.scss';

/**
 * @param {Object} error Error message.
 * @param {boolean} touched Is field touched.
 * @returns {React.Component} ErrorIconContainer component
 */
const ErrorIconContainer = ({ error, touched }) => {
  return (
    <div className="error-icon-container">
      {typeof error === 'string' || typeof error === 'object' ? (
        <ErrorIcon show={!!touched || !!error} message={error} />
      ) : null}
    </div>
  );
};

ErrorIconContainer.propTypes = {
  error: PropTypes.any,
  touched: PropTypes.bool,
};

export default withStyles(styles)(ErrorIconContainer);
