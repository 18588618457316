import moment from 'moment';
import Env from '../env';

/**
 * @param {Object} date data
 * @returns {Object} converted date
 * */
export const getDSTDate = (date) => {
  const isDST = moment(new Date()).isDST();
  return date && isDST ? moment(date).add(1, 'hours') : date && !isDST ? moment(date) : null;
};

/**
 * @param {Object} date data
 * @param {string} format result format
 * @returns {string} converted date
 * */
export const getDSTDateString = (date, format = Env.DATE_TIME_FORMAT) => {
  const convertedDate = getDSTDate(date);
  return convertedDate ? convertedDate.format(format) : '';
};

/**
 * @param {Object} date data
 * @returns {Object} converted date
 * */
export const setDSTDate = (date) => {
  const isDST = moment(new Date()).isDST();
  return date && isDST ? moment(date).subtract(1, 'hours') : date && !isDST ? moment(date) : null;
};

/**
 * @param {Object} date data
 * @returns {string} converted date
 * */
export const setDSTDateString = (date) => {
  const convertedDate = setDSTDate(date);
  return convertedDate ? convertedDate.format(Env.API_DATE_TIME_FORMAT) : '';
};
