/**
 * @param {Object} context - context object has status of editor.
 */
import $ from 'jquery';

export const embedVideo = function(context) {
  // ui has renders to build ui elements.
  //  - you can create a button with `ui.button`
  const ui = $.summernote.ui;
  this.$body = $(document.body);
  this.$editor = context.layoutInfo.editor;
  this.options = context.options;

  // this.options.dialogsInBody ? this.$body :

  const $container = this.options.dialogsInBody ? this.$body : this.$editor;

  const body = [
    '<div class="form-group note-form-group row-fluid">',
    `<label class="note-form-label">Embed Video</label>`,
    '<input class="note-video-url form-control note-form-control note-input" type="text" />',
    '</div>',
  ].join('');
  const buttonClass = 'btn btn-primary note-btn note-btn-primary note-video-btn';
  const footer = `<button type="submit" href="#" class="${buttonClass}">Insert</button>`;

  this.$dialog = ui
    .dialog({
      title: 'Insert Video',
      fade: true,
      body,
      footer,
    })
    .render()
    .appendTo($container);

  /**
   * @description show dialog
   * */
  const show = () => {
    context.invoke('editor.saveRange');
    showVideoDialog()
      .then((embedVideoContent) => {
        // [workaround] hide dialog before restore range for IE range focus
        ui.hideDialog(this.$dialog);
        context.invoke('editor.restoreRange');

        // build node
        const $node = $(
          `<div class="video"><div class="video-container">${embedVideoContent}</div></div>`
        );

        if ($node) {
          // insert video node
          context.invoke('editor.insertNode', $node[0]);
        }
      })
      .fail(() => {
        context.invoke('editor.restoreRange');
      });
  };

  /**
   * @returns {Promise}
   */
  const showVideoDialog = () => {
    return $.Deferred((deferred) => {
      const $videoUrl = this.$dialog.find('.note-video-url');
      const $videoBtn = this.$dialog.find('.note-video-btn');

      ui.onDialogShown(this.$dialog, () => {
        context.triggerEvent('dialog.shown');

        $videoBtn.click((event) => {
          event.preventDefault();

          deferred.resolve($videoUrl.val());
        });
      });

      ui.onDialogHidden(this.$dialog, () => {
        $videoUrl.off('input');
        $videoBtn.off('click');

        if (deferred.state() === 'pending') {
          deferred.reject();
        }
      });

      ui.showDialog(this.$dialog);
    });
  };

  // add hello button
  context.memo('button.embedVideo', () => {
    // create button
    const button = ui.button({
      contents: '<i class="fab fa-vimeo-v"/>',
      tooltip: 'Insert Video',
      container: 'body',
      click: () => {
        show();
      },
    });

    // create jQuery object from button instance.
    return button.render();
  });
};
