import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './style.scss';
import Env from 'env';
import classNames from 'classnames';
import { TextEditorView } from '../index';

/**
 * @returns {JSX.Element} - ContentSubTitle component
 */
const ContentSubTitle = ({ data }) => {
  return (
    <div
      className={classNames('content-subtitle', { blur: !data.description })}
      style={{
        backgroundImage: data.image ? `url(${Env.S3_URL + data.image})` : `url(${data.background})`,
      }}>
      {data.title ? <span className="content-subtitle-label">{data.title}</span> : null}
      {data.description ? (
        <div className="content-description">
          <TextEditorView width={10} data={{ text: data.description }} />
        </div>
      ) : null}
    </div>
  );
};

ContentSubTitle.propTypes = {
  data: PropTypes.object.isRequired,
};

ContentSubTitle.componentType = 'header';

export default withStyles(style)(ContentSubTitle);
