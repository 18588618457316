import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import renderRoutes from 'common/renderRoutes';
import { ToastContainer, cssTransition } from 'react-toastify';
import bowser from 'bowser';
import classNames from 'classnames';
import { SpinnerOverlay } from 'modules/shared';

import withStyles from 'isomorphic-style-loader/withStyles';
import { getProfile } from '../../actions/users';
import { getAllSettings } from '../../actions/system-settings';
import { location, equalTo } from 'common';
import * as Yup from 'yup';
import Env from 'env';

import style from 'styles/index.scss';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const Slide = cssTransition({
  enter: 'slideInDown',
  exit: 'slideOutUp',
  duration: [500, 250],
});

class MainApp extends Component {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    showLoader: PropTypes.number,
    location,
  };

  state = {
    safari: false,
    mac: false,
  };

  /**
   * @param {Object} param Params for get the initalData.
   * @param {Function} param.dispatch Action dispatcher
   * @returns {Promise}
   */
  static async getInitialData({ dispatch }) {
    return await Promise.all([dispatch(getProfile()), dispatch(getAllSettings())]);
  }

  /**
   * @returns {string}
   */
  getLinkBasePath() {
    // if (this.props.location.pathname.startsWith(`/${Env.EDGE_BASE_PATH}`)) {
    //   return '/edge';
    // }

    // if (this.props.location.pathname.startsWith(`/${Env.ANALYST_BASE_PATH}`)) {
    return '/analyst';
    // }

    // return '';
  }

  /**
   * @description Set the browser environment to state.
   */
  componentDidMount() {
    const { safari, mac, msie } = bowser;

    this.setState({ safari, mac, msie });
  }

  /**
   * @returns {JSX.Element} MainApp component.
   */
  render() {
    const { safari, mac, msie } = this.state;

    return (
      <div>
        <Helmet defer={false} titleTemplate="%s - The Analyst">
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href={`${this.getLinkBasePath()}/apple-icon-57x57.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href={`${this.getLinkBasePath()}/apple-icon-60x60.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href={`${this.getLinkBasePath()}/apple-icon-72x72.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href={`${this.getLinkBasePath()}/apple-icon-76x76.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href={`${this.getLinkBasePath()}/apple-icon-114x114.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href={`${this.getLinkBasePath()}/apple-icon-120x120.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href={`${this.getLinkBasePath()}/apple-icon-144x144.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href={`${this.getLinkBasePath()}/apple-icon-152x152.png`}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${this.getLinkBasePath()}/apple-icon-180x180.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href={`${this.getLinkBasePath()}/android-icon-192x192.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${this.getLinkBasePath()}/favicon-32x32.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href={`${this.getLinkBasePath()}/favicon-96x96.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="144x144"
            href={`${this.getLinkBasePath()}/favicon-144x144.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${this.getLinkBasePath()}/favicon-16x16.png`}
          />
          <link rel="manifest" href={`${this.getLinkBasePath()}/manifest.json`} />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta
            name="msapplication-TileImage"
            content={`${this.getLinkBasePath()}/ms-icon-144x144.png`}
          />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <ToastContainer
          position="top-center"
          hideProgressBar
          newestOnTop
          closeOnClick
          autoClose={2500}
          closeButton={false}
          transition={Slide}
          pauseOnVisibilityChange
          pauseOnHover
        />
        <SpinnerOverlay show={this.props.showLoader} />
        {this.props.routes ? (
          <div
            className={classNames({
              safari,
              mac,
              msie,
              'disable-select': !this.props.location.pathname.includes(Env.ADMIN_BASE_PATH),
            })}>
            {renderRoutes(this.props.routes, null, null, false)}
          </div>
        ) : null}
      </div>
    );
  }
}

/**
 * @param {Object} state Application state object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    showLoader: app.loader.showCount,
  };
};

export default withRouter(withStyles(style)(connect(mapStateToProps)(MainApp)));
