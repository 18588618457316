import * as HomeService from '../services/home';

export const GET_HOME = 'GET_HOME';

/**
 * @description Gets home data for edge plus.
 * @returns {function}
 */
export const getHome = () => async (dispatch) => {
  const home = await HomeService.getHome();

  dispatch({
    type: GET_HOME,
    payload: home,
  });

  return home;
};
