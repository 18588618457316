import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Grid } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Env from 'env';

import { Footer, Content } from 'modules/shared';
import { MainHeader } from 'modules/main/components';
import { withSSR, withSSRProps } from 'modules/shared';
import Login from '../login';
import { getEdgeLanding } from 'common';

class Home extends React.Component {
  static propTypes = {
    ...withSSRProps,
  };

  /**
   * @returns {JSX.Element} Home component.
   */
  render() {
    return (
      <div className="home main-home">
        <Helmet>
          <title itemProp="name">Home</title>
          <meta name="description" content="Home" />
          <link rel="canonical" href={Env.SITE_URL} itemProp="url" />
          <script type="application/ld+json">
            {`{
              "@context": "http://schema.org",
              "@type": "WebSite",
              "url": "${Env.SITE_URL}",
              "potentialAction": {
              "@type": "SearchAction",
              "target": "${Env.SITE_URL}search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
            }`}
          </script>

          {/*Twitter metas*/}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content={Env.SITE_URL} />
          <meta name="twitter:title" content="Home" />
          <meta name="twitter:description" content="Home" />
          {/*Facebook metas*/}
          <meta property="og:title" content="Home" />
          <meta property="og:url" content={Env.SITE_URL} />
          <meta property="og:description" content="Home" />
          {/*Google+*/}
          <meta itemProp="name" content="Home" />
          <meta itemProp="description" content="Home" />
        </Helmet>

        <MainHeader />

        <div className="landing-page-bg">
          <Content>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12} md={6} className="login-container">
                    <Login
                      title="the-analyst-logo-red"
                      site={Env.ANALYST_BASE_PATH}
                      appPath={`${Env.ANALYST_BASE_PATH}/app`}
                      readMore={Env.ANALYST_BASE_PATH}
                      info={<FormattedMessage id="MAIN.ANALYST_TAGLINE" />}
                    />
                  </Col>
                  <Col xs={12} md={6} className="login-container">
                    <Login
                      title="theanalystedge-logo-blue"
                      type="edge"
                      site={Env.EDGE_BASE_PATH}
                      appPath={`${getEdgeLanding(this.props.settings)}/app`}
                      readMore={Env.EDGE_BASE_PATH}
                      info={<FormattedMessage id="MAIN.EDGE_TAGLINE" />}
                    />
                  </Col>
                </Row>
              </Grid>
            </div>
          </Content>

          <Footer className="blur" />
        </div>
      </div>
    );
  }
}
/**
 * @param {Object} state Core State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ app }) => {
  return {
    settings: app.userSettings,
  };
};

export default connect(mapStateToProps)(withSSR(Home));
