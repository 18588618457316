import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import {
  Header,
  Button,
  MegaMenu,
  headerContent,
  Svg,
  ProfileDropdown,
  MegaMenuDropdown,
} from 'modules/shared';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './AnalystPublicHeader.scss';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Env from 'env';

class AnalystHeader extends Component {
  static propTypes = {
    showAnalystLoginForm: PropTypes.bool,
    onLoginToggle: PropTypes.func,
    onToggle: PropTypes.func,
    toggleLoginForm: PropTypes.func,
    showMegaMenu: PropTypes.bool,
    windowWidth: PropTypes.number,
  };

  /**
   * @returns {JSX.Element[]}
   */
  render() {
    return [
      <div className="navbar-container" key="navbar-container">
        <Header>
          <div className="header-title" itemScope itemType="http://schema.org/Organization">
            <NavLink to="/" itemProp="url">
              <Svg
                name="the-analyst-logo-light"
                className="the-analyst-logo-light"
                itemProp="logo"
              />
            </NavLink>
          </div>
          {/*<i className="toggle-icon icon-hamburger light" onClick={this.props.onToggle} />*/}
          <MegaMenuDropdown
            toggleAnalystLoginForm={() => this.props.toggleLoginForm('showAnalystLoginForm')}
          />
          <ProfileDropdown profilePath="/app" />
          <div className="nav-header">
            {/*<NavLink className="header-link" to={`/how-we-work`} activeClassName="active">*/}
            {/*<FormattedMessage id="ANALYST.HEADER.HOW_WE_WORK" />*/}
            {/*</NavLink>*/}
            <NavLink className="header-link" to="/about" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.ABOUT_US" />
            </NavLink>
            <NavLink className="header-link" to="/people" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.OUR_PEOPLE" />
            </NavLink>
            <NavLink className="header-link" to={`/sample-research`} activeClassName="active">
              <FormattedMessage id="ANALYST.HEADER.SAMPLE_RESEARCH" />
            </NavLink>
            <NavLink className="header-link" to="/careers" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.CAREERS" />
            </NavLink>
            <NavLink className="header-link" to="/press" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.PRESS" />
            </NavLink>
            <NavLink className="header-link" to="/contact" activeClassName="active">
              <FormattedMessage id="MAIN.HEADER.CONTACT" />
            </NavLink>
            {!Cookies.get(Env.REFRESH_TOKEN_ID) && (
              <Button onClick={() => this.props.toggleLoginForm('showAnalystLoginForm')}>
                <FormattedMessage id="MAIN.CLIENT_LOGIN_HEADER" />
              </Button>
            )}
            {Cookies.get(Env.REFRESH_TOKEN_ID) && (
              <NavLink className="my-research" to={`/app`}>
                <Button>
                  <FormattedMessage id="MAIN.RESEARCH" />
                </Button>
              </NavLink>
            )}
          </div>
        </Header>
        <MegaMenu
          showMenu={this.props.showMegaMenu}
          toggleMenu={this.props.onToggle}
          toggleAnalystLoginForm={() => this.props.toggleLoginForm('showAnalystLoginForm')}
          toggleEdgeLoginForm={() => this.props.toggleLoginForm('showEdgeLoginForm')}
        />
      </div>,
    ];
  }
}
export default withRouter(withStyles(style)(headerContent(AnalystHeader)));
