import * as MarketcapService from '../services/marketcap';

export const GET_ALL_MARKETCAPS = 'GET_ALL_MARKETCAPS';
export const GET_MARKETCAP = 'GET_MARKETCAP';
export const SAVE_MARKETCAP = 'SAVE_MARKETCAP';
export const MODIFY_MARKETCAP = 'MODIFY_MARKETCAP';
export const DELETE_MARKETCAP = 'DELETE_MARKETCAP';

/**
 * @param {Object} options of get
 * @description Gets all marketcaps.
 * @returns {function}
 */
export const getMarketcaps = (options) => async (dispatch) => {
  const marketcaps = await MarketcapService.getMarketcaps(options);

  dispatch({
    type: GET_ALL_MARKETCAPS,
    payload: marketcaps,
  });

  return marketcaps;
};

/**
 * @description Gets the marketcap for the ID:
 * @param {number} id marketcap ID.
 * @returns {Function}
 */
export const getMarketcap = (id) => async (dispatch) => {
  const marketcap = await MarketcapService.getMarketcap(id);

  dispatch({ type: GET_MARKETCAP, payload: marketcap });

  return marketcap;
};

/**
 * @param {Object} data marketcap data.
 * @returns {Function}
 */
export const saveMarketcap = (data) => async (dispatch) => {
  const marketcap = await MarketcapService.saveMarketcap(data);

  dispatch({
    type: SAVE_MARKETCAP,
    payload: marketcap,
  });

  return marketcap;
};

/**
 * @param {number} id marketcap Id
 * @param {Object} data marketcap data.
 * @returns {Function}
 */
export const modifyMarketcap = (id, data) => async (dispatch) => {
  const marketcap = await MarketcapService.modifyMarketcap(id, data);

  dispatch({
    type: MODIFY_MARKETCAP,
    payload: marketcap,
  });

  return marketcap;
};

/**
 * @param {number} id marketcap Id
 * @returns {Function}
 */
export const deleteMarketcap = (id) => async (dispatch) => {
  const marketcap = await MarketcapService.deleteMarketcap(id);

  dispatch({
    type: DELETE_MARKETCAP,
    payload: marketcap,
  });

  return marketcap;
};
