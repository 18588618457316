import { GET_BLOG_POSTS, GET_BLOG_POST } from '../actions/blogposts';
/**
 * @param {Array} state Current state.
 * @param {Object} action Dispatched action.
 *
 * @returns {Array} Modified state.
 */
const blogposts = (state = [], action) => {
  switch (action.type) {
    case GET_BLOG_POSTS: {
      return action.payload || [];
    }
    case GET_BLOG_POST: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default blogposts;
