import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Grid, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import { EditorTitle, Button } from 'modules/admin/shared';
import { table } from 'modules/shared';
import FilterSection from '../analytics/FilterSection';
import HeaderSection from '../analytics/HeaderSection';

import {
  getSummaryAnalytics,
  getUserAnalytics,
  exportUserAnalytics,
} from 'modules/admin/main/actions/analytics';
import { formatDate, formatUsername } from 'common';
import style from './UserAnalyticsList.scss';

class UserAnalytics extends Component {
  static propTypes = {
    getSummaryAnalytics: PropTypes.func.isRequired,
    exportUserAnalytics: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string,
    analytics: PropTypes.object,
    basePath: PropTypes.string,
  };

  /**
   * @description Get summary analytics for header.
   */
  componentDidMount() {
    this.props.getSummaryAnalytics();
  }

  export = () => {
    const { search, from, to } = this.props;
    this.props.exportUserAnalytics({ search, from, to });
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { fetchData, data, loading, pages, onSearch, search, from, to } = this.props;
    const { summary = {} } = this.props.analytics;
    return (
      <div>
        <EditorTitle title={<FormattedMessage id="ADMIN.ANALYTICS.USER_ANALYTICS" />}>
          <Button onClick={this.export} disabled={(data && !data.length) || !data}>
            <FormattedMessage id="ADMIN.ANALYTICS.EXPORT" />
          </Button>
        </EditorTitle>
        <HeaderSection summary={summary} />
        <FilterSection onSearch={onSearch} from={from} to={to} search={search} />
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.NAME" />,
                    accessor: 'name',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    width: 225,
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        <Link to={`${this.props.basePath}/user/${original.id}`}>
                          {formatUsername(original)}
                        </Link>
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.EMAIL" />,
                    accessor: 'email',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    width: 225,
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.email}
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.LOGIN_WEB" />,
                    accessor: 'logins_web_count',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.analytics_exclude ? '' : original.logins_web_count}
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.LOGIN_MOBILE" />,
                    accessor: 'logins_mobile_count',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.analytics_exclude ? '' : original.logins_mobile_count}
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.DOWNLOADS_WEB" />,
                    accessor: 'downloads_web_count',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.analytics_exclude ? '' : original.downloads_web_count}
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.DOWNLOADS_MOBILE" />,
                    accessor: 'downloads_mobile_count',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.analytics_exclude ? '' : original.downloads_mobile_count}
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.PAGE_VIEW_WEB" />,
                    accessor: 'views_web_count',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.analytics_exclude ? '' : original.views_web_count}
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.PAGE_VIEW_MOBILE" />,
                    accessor: 'views_mobile_count',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.analytics_exclude ? '' : original.views_mobile_count}
                      </div>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ANALYTICS.LAST_LOGIN" />,
                    id: 'last_login_at',
                    sortable: false,
                    headerClassName: 'white-space-normal',
                    accessor: (d) => {
                      return formatDate(d.last_login_at, {
                        timeFormat: true,
                      });
                    },
                    Cell: ({ original }) => (
                      <div
                        className={classNames('rt-td-inner', {
                          excluded: original.analytics_exclude,
                        })}>
                        {original.analytics_exclude
                          ? ''
                          : formatDate(original.last_login_at, {
                              timeFormat: true,
                            })}
                      </div>
                    ),
                    width: 180,
                  },
                ]}
                manual
                data={data}
                resizable={false}
                pages={pages}
                loading={loading}
                onFetchData={fetchData}
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                className="UserAnalyticsTable -striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

/**
 * @param {Object} state Root State object.
 *
 * @returns {Object} Injected props.
 */
const mapStateToProps = ({ admin }) => {
  return {
    analytics: admin.analytics || {},
  };
};

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSummaryAnalytics,
      exportUserAnalytics,
      fetchDataWs: getUserAnalytics,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(table()(withStyles(style)(UserAnalytics)));
