import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { EditorTitle, FieldGroup, Delete, Button } from 'modules/admin/shared';
import { match, formatUsername, formatPercent } from 'common';

class CompaniesList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
    basePath: PropTypes.string,
    hiddenColumns: PropTypes.array,
  };

  static defaultProps = {
    hiddenColumns: [],
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { data, pages, loading, search, fetchData, deleteItem, onSearch } = this.props;
    return (
      <div>
        <EditorTitle title={<FormattedMessage id="ADMIN.COMPANIES.TITLE" />}>
          <NavLink to={`${this.props.match.path}new`}>
            <Button>
              <FormattedMessage id="ADMIN.COMPANIES.NEW_COMPANY" />
            </Button>
          </NavLink>
        </EditorTitle>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <FieldGroup onChange={onSearch} placeholderId="MAIN.SEARCH" value={search} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.NAME" />,
                    accessor: 'title',
                    Cell: ({ original }) => (
                      <NavLink to={`${this.props.match.path}${original.id}/edit`}>
                        {original.title}
                      </NavLink>
                    ),
                  },
                  {
                    id: 'authors',
                    Header: <FormattedMessage id="ADMIN.AUTHORS" />,
                    accessor: (d) =>
                      d.authors && d.authors.map((author) => formatUsername(author)).join(', '),
                    sortable: false,
                    show: !this.props.hiddenColumns.includes('author'),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.COMPANIES.STOCK_ID" />,
                    accessor: 'symbol',
                    show: !this.props.hiddenColumns.includes('symbol'),
                  },
                  {
                    id: 'percent',
                    Header: <FormattedMessage id="ADMIN.COMPANIES.PERCENT" />,
                    accessor: ({ percent }) => percent && formatPercent(percent),
                    show: !this.props.hiddenColumns.includes('percent'),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.STATUS" />,
                    accessor: 'status',
                  },
                  {
                    id: 'sectors',
                    Header: <FormattedMessage id="ADMIN.SECTORS.TITLE" />,
                    sortable: false,
                    accessor: ({ sectors }) =>
                      sectors && sectors.map((sector) => sector.name).join(', '),
                    show: !this.props.hiddenColumns.includes('sectors'),
                  },
                  {
                    Header: '',
                    maxWidth: 100,
                    Cell: ({ original }) => (
                      <div className="d-flex justify-evenly">
                        <div>
                          <NavLink to={`${this.props.match.path}${original.id}/edit`}>
                            <span className="action-icon fa fa-edit" />
                          </NavLink>
                        </div>
                        <Delete onSubmit={() => deleteItem(original.id)} />
                      </div>
                    ),
                    sortable: false,
                  },
                ]}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                resizable={false}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={fetchData} // Request new data when things change
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                style={{
                  height: 'calc(100vh - 217px)',
                }}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CompaniesList;
