import React, { Component } from 'react';
import { getUsers, deleteUser } from 'modules/main/actions/users';
import { EditorTitle, Button, FieldGroup, Delete } from 'modules/admin/shared';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactTable from 'react-table';
import { Row, Grid, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { table } from 'modules/shared';
import PropTypes from 'prop-types';
import { match, formatUsername } from 'common';

class UsersList extends Component {
  static propTypes = {
    match,
    fetchData: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number,
    loading: PropTypes.bool,
    search: PropTypes.string,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { data, pages, loading, search, fetchData, onSearch, deleteItem } = this.props;
    return (
      <div>
        <EditorTitle title={<FormattedMessage id="ADMIN.USERS.TITLE" />}>
          <div>
            <NavLink to={`${this.props.match.path}new`}>
              <Button>
                <FormattedMessage id="ADMIN.USERS.NEW_USER" />
              </Button>
            </NavLink>
          </div>
        </EditorTitle>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <FieldGroup onChange={onSearch} placeholderId="MAIN.SEARCH" value={search} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: <FormattedMessage id="ADMIN.USERS.USER_NAME" />,
                    id: 'name',
                    accessor: (d) => {
                      return formatUsername(d);
                    },
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.USERS.EMAIL" />,
                    accessor: 'email',
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.USERS.CREATED_AT" />,
                    accessor: 'created_at',
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.USERS.UPDATED_AT" />,
                    accessor: 'updated_at',
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.ROLES" />,
                    accessor: 'roles',
                    Cell: ({ original }) =>
                      original && original.roles
                        ? original.roles.map((d, index) => {
                            return (
                              <span key={index}>
                                {index !== 0 ? ', ' : null}
                                {d.role_title}
                              </span>
                            );
                          })
                        : '',
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.USERS.LEAVE_FROM_ANALYTICS" />,
                    accessor: 'analytics_exclude',
                    Cell: ({ original }) => (
                      <FieldGroup
                        className="checkbox-form-group d-flex justify-center"
                        inline
                        disabled
                        type="checkbox"
                        checked={original.analytics_exclude}
                      />
                    ),
                  },
                  {
                    id: 'sectors',
                    Header: <FormattedMessage id="ADMIN.SECTORS.TITLE" />,
                    sortable: false,
                    accessor: ({ sectors, full_access }) =>
                      !full_access && sectors && sectors.map((sector) => sector.name).join(', '),
                  },
                  {
                    Header: <FormattedMessage id="ADMIN.SECTORS.FULL_ACCESS" />,
                    accessor: 'full_access',
                    Cell: ({ original }) => (
                      <FieldGroup
                        className="checkbox-form-group d-flex justify-center"
                        inline
                        disabled
                        type="checkbox"
                        checked={original.full_access}
                      />
                    ),
                  },
                  {
                    Header: '',
                    maxWidth: 100,
                    Cell: ({ original }) => (
                      <div className="d-flex justify-evenly">
                        <div>
                          <NavLink to={`${this.props.match.path}${original.id}/edit`}>
                            <span className="action-icon fa fa-edit" />
                          </NavLink>
                        </div>
                        <Delete onSubmit={() => deleteItem(original.id)} />
                      </div>
                    ),
                    sortable: false,
                  },
                ]}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                data={data}
                resizable={false}
                pages={pages} // Display the total number of pages
                loading={loading} // Display the loading overlay when we need it
                onFetchData={fetchData} // Request new data when things change
                defaultPageSize={20}
                showPageSizeOptions={false}
                noDataText={<FormattedMessage id="ADMIN.NO_DATA_FOUND" />}
                style={{
                  height: 'calc(100vh - 217px)',
                }}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDataWs: getUsers, deleteItemWs: deleteUser }, dispatch);

export default connect(null, mapDispatchToProps)(table()(UsersList));
