export enum BaseBuys {
  STRONG_BUY = 'strong_buy',
  BUY = 'buy',
  SHORT = 'short',
}

export enum SellNoRecommendationBuys {
  SELL = 'sell',
  NO_RECOMMENDATION = 'no_recommendation',
}
