import React, { Component } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

class InfoPanel extends Component {
  static propTypes = {
    post: PropTypes.object,
    children: PropTypes.node,
    checkFields: PropTypes.array,
  };

  /**
   * @description Determines if the content is empty
   * @returns {boolean}
   */
  isEmpty() {
    if (!this.props.checkFields) {
      return false;
    }
    return !this.props.checkFields.some((i) => i);
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { children } = this.props;
    return (
      <div className={classNames('info', { empty: this.isEmpty() })}>
        <div className="info-panel">{children}</div>
      </div>
    );
  }
}

export default InfoPanel;
