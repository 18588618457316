import * as SectorsService from 'modules/shared/services/sectors';

export const GET_ALL_SECTORS = 'GET_ALL_SECTORS';
export const GET_SECTOR = 'GET_SECTOR';
export const SAVE_SECTOR = 'SAVE_SECTOR';
export const MODIFY_SECTOR = 'MODIFY_SECTOR';
export const DELETE_SECTOR = 'DELETE_SECTOR';
export const GET_INDUSTRY_SECTORS = 'GET_INDUSTRY_SECTORS';

/**
 * @param {Object} options of get
 * @description Gets all sectors.
 * @returns {function}
 */
export const getSectors = (options) => async (dispatch) => {
  const sectors = await SectorsService.getSectors({ ...options });

  dispatch({
    type: GET_ALL_SECTORS,
    payload: sectors,
  });

  return sectors;
};

/**
 * @description Gets the sector for the ID:
 * @param {number} id sector ID.
 * @returns {Function}
 */
export const getSector = (id) => async (dispatch) => {
  const sector = await SectorsService.getSector(id);

  dispatch({
    type: GET_SECTOR,
    payload: sector,
  });

  return sector;
};

/**
 * @param {Object} data sector data.
 * @returns {Function}
 */
export const saveSector = (data) => async (dispatch) => {
  const sector = await SectorsService.saveSector(data);

  dispatch({
    type: SAVE_SECTOR,
    payload: sector,
  });

  return sector;
};

/**
 * @param {number} id sector Id
 * @param {Object} data sector data.
 * @returns {Function}
 */
export const modifySector = (id, data) => async (dispatch) => {
  const sector = await SectorsService.modifySector(id, data);

  dispatch({
    type: MODIFY_SECTOR,
    payload: sector,
  });

  return sector;
};

/**
 * @param {number} id sector Id
 * @returns {Function}
 */
export const deleteSector = (id) => async (dispatch) => {
  const sector = await SectorsService.deleteSector(id);

  dispatch({
    type: DELETE_SECTOR,
    payload: sector,
  });

  return sector;
};

/**
 * @description Gets all sectors.
 * @returns {function}
 */
export const getIndustrySectors = () => async (dispatch) => {
  const industrySectors = await SectorsService.getIndustrySectors();

  dispatch({
    type: GET_INDUSTRY_SECTORS,
    payload: industrySectors,
  });

  return industrySectors;
};
