import Cookies from 'js-cookie';
import Env from 'env';

/**
 * @param {Object} token object
 * @description set token to cookie
 * */
export const setToken = (token) => {
  Cookies.set(Env.AUTH_TOKEN_ID, token.access_token, {
    expires: token.exp ? new Date(token.exp * 1000) : token.expires_in / 60 / 60 / 24,
    path: '/',
  });
};

/**
 * @param {Object} token object
 * @description set refresh token to cookie
 * */
export const setRefreshToken = (token) => {
  Cookies.set(Env.REFRESH_TOKEN_ID, token.refresh_token, {
    expires: token.refresh_expires_in / 60 / 60 / 24,
    path: '/',
  });
};

/**
 * @description remove tokens from cookie
 * */
export const removeTokens = () => {
  Cookies.remove(Env.AUTH_TOKEN_ID, { path: '/' });
  Cookies.remove(Env.REFRESH_TOKEN_ID, { path: '/' });
};
