import React from 'react';
import { FormattedMessage } from 'react-intl';

import Companies from 'modules/analyst/modules/app/components/companies';
import ShortScreen from 'modules/analyst/modules/app/components/short-screen';
import LongScreen from 'modules/analyst/modules/app/components/long-screen/LongScreen';
import SentimentTracker from 'modules/analyst/modules/app/components/sentiment-tracker';
import WalkThrough from 'modules/analyst/modules/app/components/walk-through';
import AllResearch from 'modules/analyst/modules/app/components/all-research';
import ModelPortfolio from 'modules/analyst/modules/app/components/model-portfolio';
import MyResearch from 'modules/analyst/modules/app/components/my-research';
import Profile from 'modules/analyst/modules/app/components/profile/Profile';
import AppNotFound from 'modules/analyst/modules/app/components/not-found/AppNotFound';

export const appRoutes = [
  {
    path: 'all-research',
    component: AllResearch,
    title: <FormattedMessage id="MAIN.RESEARCH" />,
  },
  {
    path: 'walk-through',
    component: WalkThrough,
    title: <FormattedMessage id="ADMIN.WALK_THROUGH.TITLE" />,
  },
  {
    path: 'sentiment-tracker',
    component: SentimentTracker,
    title: <FormattedMessage id="ADMIN.SENTIMENT_TRACKER.TITLE" />,
  },
  {
    path: 'model-portfolio/:id?',
    component: ModelPortfolio,
    title: <FormattedMessage id="ANALYST.MODEL_PORTFOLIO.TITLE" />,
  },
  {
    path: 'short-screen',
    component: ShortScreen,
    title: <FormattedMessage id="ADMIN.SHORT_SCREEN.TITLE" />,
  },
  {
    path: 'factor-panel',
    component: LongScreen,
    title: <FormattedMessage id="ADMIN.LONG_SCREEN.TITLE" />,
  },
  { path: 'companies', component: Companies, title: <FormattedMessage id="MAIN.A_Z_COMPANIES" /> },
  {
    path: 'bookmarks',
    component: MyResearch,
    title: <FormattedMessage id="MAIN.BOOKMARKS" />,
  },
  {
    path: `profile`,
    component: Profile,
    title: <FormattedMessage id="MAIN.PROFILE" />,
  },
  {
    path: `404`,
    component: AppNotFound,
    title: <FormattedMessage id="MAIN.NOT_FOUND" />,
  },
];
