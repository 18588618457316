import { AsyncState } from '../util/asyncReducerState';

/**
 * @returns {boolean} is fetch started.
 */
export const isCountriesFetchStarted = (state) =>
  state.shared.countries.state === AsyncState.STARTED;

/**
 * @returns {Array} saved countries.
 */
export const getCountries = (state) => state.shared.countries.data;
