import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BaseDataEditor } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';
import { showLoader, hideLoader } from 'modules/main/actions/loader';
import { getTag, saveTag, modifyTag } from 'modules/analyst/actions/tags';
import { match, history } from 'common';

class AnalystTagsEditor extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    getTag: PropTypes.func.isRequired,
    modifyTag: PropTypes.func.isRequired,
    saveTag: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    match: match.isRequired,
    history: history.isRequired,
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    return (
      <BaseDataEditor
        showLoader={this.props.showLoader}
        hideLoader={this.props.hideLoader}
        getBaseData={this.props.getTag}
        saveBaseData={this.props.saveTag}
        modifyBaseData={this.props.modifyTag}
        createLabel={<FormattedMessage id="ADMIN.TAGS.CREATE" />}
        editLabel={<FormattedMessage id="ADMIN.TAGS.EDIT" />}
        basePath={`${this.props.basePath}/tags`}
        match={this.props.match}
        history={this.props.history}
        hiddenFields={['title', 'description', 'meta_title', 'meta_description', 'keywords']}
      />
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoader,
      hideLoader,
      getTag,
      saveTag,
      modifyTag,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AnalystTagsEditor);
