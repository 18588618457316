import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { FieldGroup } from 'modules/admin/shared';
import { FormattedMessage } from 'react-intl';

class KeyFacts extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    hiddenFields: PropTypes.array,
    edge: PropTypes.bool,
    keyFactsKey: PropTypes.string,
  };

  static defaultProps = {
    errors: {},
    keyFactsKey: '',
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { keyFactsKey } = this.props;

    return (
      <div>
        <Col xs={12}>
          <FieldGroup
            className="checkbox-form-group"
            label={<FormattedMessage id="ADMIN.POSTS.KEY_FACTS" />}
            inline
            type="checkbox"
            name={`key_facts${keyFactsKey}`}
            checked={this.props.post[`key_facts${keyFactsKey}`]}
            value={this.props.post[`key_facts${keyFactsKey}`]}
            onChange={this.props.onChange}
          />
        </Col>
        {this.props.post[`key_facts${keyFactsKey}`] ? (
          <div className="d-inline-block mb-4">
            {!this.props.hiddenFields.includes('recommendation') ? (
              <Col xs={12}>
                <FieldGroup
                  name={`recommendation${keyFactsKey}`}
                  placeholderId="ADMIN.POSTS.RECOMMENDATION"
                  label={<FormattedMessage id="ADMIN.POSTS.RECOMMENDATION" />}
                  value={this.props.post[`recommendation${keyFactsKey}`]}
                  error={
                    this.props.errors[`recommendation${keyFactsKey}`]
                      ? this.props.errors[`recommendation${keyFactsKey}`].message
                      : null
                  }
                  onChange={this.props.onChange}
                />
              </Col>
            ) : null}

            <Col xs={12} md={4}>
              <FieldGroup
                name={`price${keyFactsKey}`}
                placeholderId="ADMIN.POSTS.PRICE"
                label={<FormattedMessage id="ADMIN.POSTS.PRICE" />}
                value={this.props.post[`price${keyFactsKey}`]}
                error={
                  this.props.errors[`price${keyFactsKey}`]
                    ? this.props.errors[`price${keyFactsKey}`].message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>
            {!this.props.hiddenFields.includes('price_target') ? (
              <Col xs={12} md={4}>
                <FieldGroup
                  name={`price_target${keyFactsKey}`}
                  placeholderId="ADMIN.PRICE_TARGET"
                  label={<FormattedMessage id="ADMIN.PRICE_TARGET" />}
                  value={this.props.post[`price_target${keyFactsKey}`]}
                  error={
                    this.props.errors[`price_target${keyFactsKey}`]
                      ? this.props.errors[`price_target${keyFactsKey}`].message
                      : null
                  }
                  onChange={this.props.onChange}
                />
              </Col>
            ) : null}
            {!this.props.hiddenFields.includes('forecast_return') ? (
              <Col xs={12} md={4}>
                <FieldGroup
                  name={`forecast_return${keyFactsKey}`}
                  placeholderId="ADMIN.POSTS.FORECAST_RETURN"
                  label={<FormattedMessage id="ADMIN.POSTS.FORECAST_RETURN" />}
                  value={this.props.post[`forecast_return${keyFactsKey}`]}
                  error={
                    this.props.errors[`forecast_return${keyFactsKey}`]
                      ? this.props.errors[`forecast_return${keyFactsKey}`].message
                      : null
                  }
                  onChange={this.props.onChange}
                />
              </Col>
            ) : null}

            <Col xs={12} md={4}>
              <FieldGroup
                name={`3m_average${keyFactsKey}`}
                placeholderId="MAIN.3M_AVERAGE"
                label={<FormattedMessage id="MAIN.3M_AVERAGE" />}
                value={this.props.post[`3m_average${keyFactsKey}`]}
                error={
                  this.props.errors[`3m_average${keyFactsKey}`]
                    ? this.props.errors[`3m_average${keyFactsKey}`].message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FieldGroup
                name={`market_cap${keyFactsKey}`}
                placeholderId="MAIN.MARKET_CAP"
                label={<FormattedMessage id="MAIN.MARKET_CAP" />}
                value={this.props.post[`market_cap${keyFactsKey}`]}
                error={
                  this.props.errors[`market_cap${keyFactsKey}`]
                    ? this.props.errors[`market_cap${keyFactsKey}`].message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FieldGroup
                name={`ticker${keyFactsKey}`}
                placeholderId={this.props.edge ? 'MAIN.BLOOMBERG_TICKER' : 'ADMIN.POSTS.TICKER'}
                label={
                  <FormattedMessage
                    id={this.props.edge ? 'MAIN.BLOOMBERG_TICKER' : 'ADMIN.POSTS.TICKER'}
                  />
                }
                value={this.props.post[`ticker${keyFactsKey}`]}
                error={
                  this.props.errors[`ticker${keyFactsKey}`]
                    ? this.props.errors[`ticker${keyFactsKey}`].message
                    : null
                }
                onChange={this.props.onChange}
              />
            </Col>

            {!this.props.hiddenFields.includes('shares_out') ? (
              <Col xs={12} md={4}>
                <FieldGroup
                  name={`shares_out${keyFactsKey}`}
                  placeholderId="MAIN.SHARES_OUT"
                  label={<FormattedMessage id="MAIN.SHARES_OUT" />}
                  value={this.props.post[`shares_out${keyFactsKey}`]}
                  error={
                    this.props.errors[`shares_out${keyFactsKey}`]
                      ? this.props.errors[`shares_out${keyFactsKey}`].message
                      : null
                  }
                  onChange={this.props.onChange}
                />
              </Col>
            ) : null}
            {!this.props.hiddenFields.includes('valuation_metric') ? (
              <Col xs={12} md={4}>
                <FieldGroup
                  name={`valuation_metric${keyFactsKey}`}
                  placeholderId="ADMIN.POSTS.VALUATION_METRIC"
                  label={<FormattedMessage id="ADMIN.POSTS.VALUATION_METRIC" />}
                  value={this.props.post[`valuation_metric${keyFactsKey}`]}
                  error={
                    this.props.errors[`valuation_metric${keyFactsKey}`]
                      ? this.props.errors[`valuation_metric${keyFactsKey}`].message
                      : null
                  }
                  onChange={this.props.onChange}
                />
              </Col>
            ) : null}
            {!this.props.hiddenFields.includes('current_multiple') ? (
              <Col xs={12} md={4}>
                <FieldGroup
                  name={`current_multiple${keyFactsKey}`}
                  placeholderId="ADMIN.POSTS.CURRENT_MULTIPLE"
                  label={<FormattedMessage id="ADMIN.POSTS.CURRENT_MULTIPLE" />}
                  value={this.props.post[`current_multiple${keyFactsKey}`]}
                  error={
                    this.props.errors[`current_multiple${keyFactsKey}`]
                      ? this.props.errors[`current_multiple${keyFactsKey}`].message
                      : null
                  }
                  onChange={this.props.onChange}
                />
              </Col>
            ) : null}
            {!this.props.hiddenFields.includes('target_multiple') ? (
              <Col xs={12} md={4}>
                <FieldGroup
                  name={`target_multiple${keyFactsKey}`}
                  placeholderId="ADMIN.POSTS.TARGET_MULTIPLE"
                  label={<FormattedMessage id="ADMIN.POSTS.TARGET_MULTIPLE" />}
                  value={this.props.post[`target_multiple${keyFactsKey}`]}
                  error={
                    this.props.errors[`target_multiple${keyFactsKey}`]
                      ? this.props.errors[`target_multiple${keyFactsKey}`].message
                      : null
                  }
                  onChange={this.props.onChange}
                />
              </Col>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default KeyFacts;
