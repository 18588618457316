import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trackMediaDownload } from 'modules/main/actions/analytics';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import Env from 'env';

/**
 * @param {string} linkLabel label of link
 * @param {string} linkId id of link
 * @param {string} linkIcon icon of link
 * @param {Object} props Injected Props object
 * @returns {JSX.Element}
 * @constructor
 */
class Link extends Component {
  static propTypes = {
    trackMediaDownload: PropTypes.func.isRequired,
    linkId: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  state = {
    token: Cookies.get(Env.AUTH_TOKEN_ID),
  };

  /**
   * @description tracking media download
   * */
  trackMediaDownload = async () => {
    this.props.trackMediaDownload(this.props.linkId);
  };

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { linkId, className } = this.props;

    return (
      <a
        className={className}
        rel="noopener noreferrer"
        target="_blank"
        href={`${Env.API_URL}files/${linkId}?token=${this.state.token}`}
        onClick={this.trackMediaDownload}>
        {this.props.children}
      </a>
    );
  }
}

/**
 * @param {Function} dispatch Dispatcher
 *
 * @returns {Object} Bound action creators.
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackMediaDownload,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(Link));
