import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import style from './Collapse.scss';
import $ from 'jquery';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';

class Collapse extends Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    open: PropTypes.bool,
    showBottomBorder: PropTypes.bool,
    color: PropTypes.string,
  };

  state = {
    open: false,
  };

  /**
   * @description Sets open of collapse.
   */
  componentDidMount() {
    this.setOpen();
  }

  /**
   * @description Sets open of collapse.
   * @param {Object} prevProps Props before update.
   */
  componentDidUpdate(prevProps) {
    this.setOpen(prevProps);
  }

  setOpen = (prevProps) => {
    const stateChange = this.state.open !== this.props.open;
    if (prevProps ? prevProps.open !== this.props.open && stateChange : stateChange) {
      this.setState({ open: this.props.open });
    }
  };

  toggleCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  getCollapseContentHeight = () => {
    return this.state.open && this.collapseContent
      ? $(this.collapseContent).outerHeight(true)
      : '0';
  };

  setCollapseContentRef = (r) => (this.collapseContent = r);

  /**
   * @returns {JSX.Element} Collapse component.
   */
  render() {
    const { title, content, color, showBottomBorder } = this.props;
    return (
      <ReactResizeDetector
        handleWidth
        render={() => (
          <div className="collapse-container">
            <div className={classNames('collapse', { 'show-bottom-border': showBottomBorder })}>
              <div
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="collapse-title"
                onClick={this.toggleCollapse}>
                <h4>{title}</h4>
                <i
                  className={classNames(
                    'fa',
                    this.state.open ? `fa-caret-down open ${color}` : 'fa-caret-right'
                  )}
                />
              </div>
              <div
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classNames('collapse-detail', {
                  open: this.state.open,
                })}
                style={{
                  maxHeight: this.getCollapseContentHeight(),
                }}>
                <div ref={this.setCollapseContentRef}>{content}</div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default withStyles(style)(Collapse);
