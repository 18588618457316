import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';

import { formatUsername } from 'common';
import styles from './Author.scss';
import Env from 'env';

class Author extends React.Component {
  static INFO_POSITION = { right: 'right', bottom: 'bottom' };
  static propTypes = {
    infoPosition: PropTypes.oneOf(Object.keys(Author.INFO_POSITION)),
    basePath: PropTypes.string,
    linkToAnalyst: PropTypes.bool,
    author: PropTypes.object,
  };

  static defaultProps = {
    infoPosition: Author.INFO_POSITION.bottom,
  };

  /**
   * @returns {JSX.Element}
   */
  getContact() {
    const { author } = this.props;
    return (
      <React.Fragment>
        {author.phone ? (
          <div className="analyst-info-item mb-4">
            <i className="fa fa-phone fa-rotate-90 info-icon" />
            <div className="info">{author.phone}</div>
          </div>
        ) : null}
        <a className="analyst-info-item mb-4" href={`mailto: ${author.email}`}>
          <i className="fa fa-envelope info-icon" />
          <div className="info">
            <FormattedMessage id="MAIN.EMAIL" /> {author.firstname || author.name}
          </div>
        </a>
      </React.Fragment>
    );
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { author, basePath, infoPosition, linkToAnalyst } = this.props;
    return (
      <div className="analyst-author-info">
        <div className="d-flex align-center mb-4">
          <div
            className="profile-image"
            style={{ backgroundImage: `url(${Env.S3_URL + author.avatar_path})` }}
          />
          <div className={classNames('pl-4 info-container', infoPosition)}>
            <div className="analyst">
              <FormattedMessage id="MAIN.ANALYST" />:
            </div>
            {linkToAnalyst ? (
              <Link to={`${basePath}/analyst/${author.id}`} className="link author-name">
                {formatUsername(author)}
              </Link>
            ) : (
              <div className="author-name">{formatUsername(author)}</div>
            )}
            {infoPosition === Author.INFO_POSITION.right ? this.getContact() : null}
          </div>
        </div>
        {infoPosition === Author.INFO_POSITION.bottom ? this.getContact() : null}
      </div>
    );
  }
}

export default withStyles(styles)(Author);
