import * as RegionsService from '../services/regions';

export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGION = 'GET_REGION';
export const SAVE_REGION = 'SAVE_REGION';
export const MODIFY_REGION = 'MODIFY_REGION';
export const DELETE_REGION = 'DELETE_REGION';

/**
 * @param {Object} options of get
 * @returns {Function} Async regions.
 */
export const getRegions = (options) => async (dispatch) => {
  const regions = await RegionsService.getRegions(options);

  dispatch({
    type: GET_REGIONS,
    payload: regions,
  });

  return regions;
};

/**
 * @description Gets the regions for the ID:
 * @param {number} id regions ID.
 * @returns {Function}
 */
export const getRegion = (id) => async (dispatch) => {
  const region = await RegionsService.getRegion(id);

  dispatch({
    type: GET_REGION,
    payload: region,
  });

  return region;
};

/**
 * @param {Object} data regions data.
 * @returns {Function}
 */
export const saveRegion = (data) => async (dispatch) => {
  const region = await RegionsService.saveRegion(data);

  dispatch({
    type: SAVE_REGION,
    payload: region,
  });

  return region;
};

/**
 * @param {number} id regions Id
 * @param {Object} data regions data.
 * @returns {Function}
 */
export const modifyRegion = (id, data) => async (dispatch) => {
  const region = await RegionsService.modifyRegion(id, data);

  dispatch({
    type: MODIFY_REGION,
    payload: region,
  });

  return region;
};

/**
 * @param {number} id regions Id
 * @returns {Function}
 */
export const deleteRegion = (id) => async (dispatch) => {
  const region = await RegionsService.deleteRegion(id);

  dispatch({
    type: DELETE_REGION,
    payload: region,
  });

  return region;
};
